import wurd from 'wurd-react';

import * as actions from '../../../../actions';
import { date, confirm } from '../../../../helpers/ui';

import JobStep from '../../job-step2';
import ApiButton from '../../../api-button';
import LinkButtonGroup, { LinkButton } from '../../../link-button-group';
import ConfirmFutureStep from '../../confirm-future-step';

const cms = wurd.block('unitJobs.transfer.steps.waitForTransferDate');


export default function UniveTransferSteps_waitForTransferDate({
  job,
  step,
  onChange,
  modal,
}) {
  const { transferDate } = job.result;

  async function completeStep() {
    await actions.jobs.updateStep(job.id, step.id, {
      state: 'completed'
    });

    onChange();
  }

  async function cancelJob() {
    const confirmed = confirm(cms.text('cancelOrder.confirm'));
    if (!confirmed) return;

    await actions.jobs.cancel(job.id);

    onChange();
  }

  function render_ready() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="ready.title" vars={{ transferDate: date(transferDate) }} />}
      >
        <ConfirmFutureStep date={transferDate}>
          <ApiButton
            block
            bsType="success"
            onClick={completeStep}
          >
            <i className="fas fa-check" />
            &nbsp;
            <cms.Text id="ready.completeBtn" />
          </ApiButton>
        </ConfirmFutureStep>

        <cms.Object id="cancelOrder" keys="btn,confirm">
          <LinkButtonGroup align="center">
            <LinkButton
              faIcon="far fa-times"
              text={cms.text('cancelOrder.btn')}
              onClick={cancelJob}
            />
          </LinkButtonGroup>
        </cms.Object>
      </JobStep>
    );
  }

  function render_completed() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" />}
      />
    );
  }

  if (step.state === 'ready') return render_ready();
  if (step.state === 'completed') return render_completed();

  return null;
}
