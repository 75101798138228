import { TYPES } from './Constants';


export function isUnit(item) {
  return item.type !== TYPES.object;
};


export function isMarker(item) {
  return item.type === TYPES.object;
};
