import Activity from '../activity';


export default function ValetOrderHistory({ valetOrder }) {
  return (
    <Activity
      context="valetOrder"
      endpoint={`/valet-orders/${valetOrder.id}/actions`}
    />
  );
}
