/**
 * A simple Bootstrap form field.  Instead of a label there is an 'add-on' icon and optional placeholder
 */
export default ({ name, value, onChange, error, help, icon, ...inputProps }) => (
  <div className={`form-group ${error && 'has-error'}`}>
    <div className="input-group">
      <span className="input-group-addon">
        <i className={`fa fa-${icon}`} />
      </span>
      <input
        className="form-control"
        name={name}
        value={value}
        onChange={onChange}
        {...inputProps}
      />
    </div>
    {error &&
      <div className="help-block">{error}</div>
    }
  </div>
);
