import { useItemLoader } from '../../hooks';


export default function ItemLoader({
  id,
  include,
  maxAge,
  loading = <i className="fas fa-spinner fa-spin" />,
  fallback = <i className="fas fa-question-circle" title="Not found" />,
  children: renderFn,
}) {
  const { item, isLoading, refetch } = useItemLoader('items', id, {
    altIdKey: 'sid',
    include,
    maxAge,
  });

  if (!item) {
    return isLoading ? loading : fallback;
  }

  return renderFn(item, { isLoading, refetch });
}
