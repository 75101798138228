import wurd from 'wurd-react';
import { useQuery } from 'react-query';

import * as actions from 'actions';

import Section from 'components/section';
import { Field, RadioList, Select, InputGroup } from 'components/form2';
import SettingsForm2 from '../form2';
import Input from '../input';

const cms = wurd.block('settings.invoicing');
const inputCms = wurd.block('settings.inputs');

export default function InvoicingSettings({ settings }) {
  const { data: currencies } = useQuery('currencies', () => import('currency-codes').then(m => m.default));

  return (
    <Section cms={cms}>
      <SettingsForm2
        wurdSection="settings.inputs"
        initialValue={settings}
        onSubmit={(data, dataPatches) => actions.business.update(dataPatches)}
      >
        {({ fieldProps }) => {
          return (
            <>
              <Field {...fieldProps('currencyCode')}>
                <Select>
                  {[
                    { value: null, label: wurd.text('common.phrases.none') },
                    ...currencies?.data.map(({ code, currency }) => ({ value: code, label: <>{code}&nbsp;&nbsp;({currency})</> })) || [],
                  ]}
                </Select>
              </Field>

              <Input {...fieldProps('currency')} />

              <Field {...fieldProps('billingTrigger')} className={null}>
                <RadioList>
                  {['manual', 'auto'].map(v => ({
                    value: v,
                    title: <inputCms.Text id={`billingTrigger.radio.${v}.title`} />,
                    description: <inputCms.Text id={`billingTrigger.radio.${v}.desc`} type="p" className="text-muted" />
                  }))}
                </RadioList>
              </Field>

              <Field {...fieldProps('invoiceOverdueDays')}>
                <InputGroup
                  type="number"
                  min="0"
                  before={<inputCms.Text id="invoiceOverdueDays.days" />}
                />
              </Field>
            </>
          );
        }}
      </SettingsForm2>
    </Section>
  );
}
