import wurd from 'wurd-react';
import { pick } from 'lodash';

import ModalForm from 'components/form2/modal-form';
import { Field, MultiLangText } from 'components/form2';
import AccountingCodeSelect from 'components/form2/accounting-code-select';

const fields = [
  'type',
  'title',
  'amount',
  'accountingCodeId',
];

const typeOptions = ['movein', 'recurring'];

const wurdSection = 'business.unitCharge';
const cms = wurd.block(wurdSection);


export default function UnitTypeChargesForm({ initialValue, ...rest }) {
  return (
    <ModalForm
      {...rest}
      initialValue={pick(initialValue, fields)}
      wurdSection={wurdSection}
    >
      {(fieldProps) => (
        <>
          <Field {...fieldProps('type')} required>
            <select data-wurd-obj={cms.id('type')} data-wurd-obj-props={typeOptions.join(',')}>
              <option value="">{cms.text('type.placeholder')}</option>

              {typeOptions.map((type) => (
                <option key={type} value={type}>{cms.text(`type.${type}`)}</option>
              ))}
            </select>
          </Field>

          <Field {...fieldProps('title')} required>
            <MultiLangText />
          </Field>

          <Field {...fieldProps('amount')} type="number" required />

          <Field {...fieldProps('accountingCodeId')}>
            <AccountingCodeSelect />
          </Field>
        </>
      )}
    </ModalForm>
  );
}
