import wurd from 'wurd-react';

import * as actions from '../../../../actions';
import * as helpers from '../../../../helpers';

import Section from '../../../section';
import CrudList from '../../../form/crud-list';
import ItemType from '../../item-type';
import MarketItemForm from '../../../form/market/item';


function MarketItem({ item }) {
  const mainItemConfig = helpers.item.get(item.type) || {};
  const marketItemConfig = { ...mainItemConfig, ...item };

  return (
    <ItemType item={marketItemConfig} />
  );
}

function CustomMarketItemForm(props) {
  /**
   * If the type changes, get the price from the main settings
   */
  function preChange(data) {
    const result = { ...data };

    if (data.type) {
      const itemConfig = helpers.item.get(data.type);

      result.price = itemConfig.price;
      result.displayPrice = itemConfig.displayPrice;
    }

    return result;
  }

  return (
    <MarketItemForm {...props} preChange={preChange} />
  );
}


export default function MarketItemsSettings({ market }) {
  return (
    <Section cms={wurd.block('marketSettings.items')}>
      <CrudList
        items={market.items.sort((a, b) => ((a.order || 0) - (b.order || 0)) || (a.price - b.price) || a.type.localeCompare(b.type))}
        Form={CustomMarketItemForm}
        actions={actions.markets.items(market.id)}
        onChange={actions.markets.fetch}
      >
        <MarketItem />
      </CrudList>
    </Section>
  );
}
