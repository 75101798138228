/**
 * A button which wraps displays a spinner and sets itself to disabled while submitting an API
 * request.
 *
 * To use it, pass an onClick function as prop which returns a promise; This usually would be the
 * result of an action to the API.
 */
import { useRef } from 'react';
import { useMutation } from 'react-query';


export default function ApiButton({
  bsType = 'primary',
  block = false,
  disabled,
  onClick,
  children,
  className = '',
  loadingMsg,
  ...rest
}) {
  const buttonRef = useRef();
  const clickMutation = useMutation(onClick);

  const innerClassName = disabled ? 'btn btn-default' : `btn btn-${bsType}`;
  const handleClick = e => {
    buttonRef.current.disabled = true; // using DOM drectly to avoid async race conditions
    clickMutation.mutateAsync()
      .finally(() => {
        if (buttonRef.current) buttonRef.current.disabled = disabled || false;
      });
  };

  return (
    <button
      type="button"
      ref={buttonRef}
      className={`${innerClassName} ${className} ${block ? 'btn-block' : ''}`}
      disabled={disabled || clickMutation.isLoading}
      tabIndex={0}
      onClick={handleClick}
      {...rest}
    >
      {clickMutation.isLoading
        ? (
          <>
            <i className="far fa-spin fa-spinner mr-1" />
            {loadingMsg}
          </>
        ) : children}
    </button>
  );
}
