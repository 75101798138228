import { Modal, DropdownButton, MenuItem, Button } from 'react-bootstrap';
import wurd from 'wurd-react';
import PapaCsv from 'papaparse';

import * as helpers from '../helpers';
import { useModal } from '../hooks';

import Section from './section';
import { ModalDialog } from './modal';
import ExportButton from './export-button2';
import ImportButton from './import-button2';
import ClickableDiv from './clickable-div';

const pointerStyle = {
  cursor: 'pointer',
};


export default function SectionCrudList({
  cms,
  items,
  Form,
  actions,
  onChange,
  initialValue = {},
  formProps,
  canCreate = true,
  canDelete = true,
  canImport = true,
  canEdit,
  children: renderItem,
  modalProps,
}) {
  const modal = useModal(modalProps);

  function onChangeClose() {
    onChange();
    modal.close();
  }

  function add() {
    modal.open(
      <Form
        initialValue={initialValue}
        onSubmit={actions.create}
        onSuccess={onChangeClose}
        closeModal={modal.close}
        mode="create"
        {...formProps}
      />,
    );
  }

  function handleDelete(item) {
    if (!helpers.ui.confirm(cms.text('confirmDelete') || wurd.text('common.confirmDelete'))) return;

    return actions.delete(item.id)
      .then(onChangeClose);
  }

  function edit(event) {
    //console.log(items)
    //console.log(event.currentTarget)
    const item = items.find(({ id }) => id === event.currentTarget.id);
    if (!item) throw new Error(`CrudList item ${event.currentTarget.id} not found`);

    if (canEdit && !canEdit(item)) return;

    modal.open(
      <Form
        initialValue={item}
        onSubmit={data => actions.update(item.id, data)}
        onSuccess={onChangeClose}
        closeModal={modal.close}
        onDelete={canDelete && (() => handleDelete(item))}
        mode="update"
        {...formProps}
      />,
    );
  }

  function openExportModal() {
    modal.open(
      <ModalDialog cms={cms.block('exportModal')}>
        <ExportButton items={items} filename={`${cms.id().split('.').slice(-1)[0]}.csv`} onComplete={modal.close} />
      </ModalDialog>
    );
  }

  function openImportModal() {
    modal.open(
      <ModalDialog cms={cms.block('importModal')}>
        <ImportButton actions={actions} onComplete={onChangeClose} />
      </ModalDialog>
    );
  }

  return (
    <>
      <Section
        cms={cms}
        onClickAdd={canCreate && add}
        extra={canImport && (items.length > 0 || helpers.auth.hasRole('manager')) && (
          <cms.Object id="menu" keys="export,import" className="pull-right">
            <DropdownButton
              title={<i className="fa fa-ellipsis-v" />}
              id="menu"
              bsStyle="link"
              pullRight
              noCaret
            >
              {items.length > 0 && (
                <MenuItem key="export" onSelect={openExportModal}>
                  <i className="fas fa-fw fa-download" /> {cms.text('menu.export') || 'Export'}
                </MenuItem>
              )}
              {helpers.auth.hasRole('manager') && (
                <MenuItem key="import" onSelect={openImportModal}>
                  <i className="fas fa-fw fa-upload" /> {cms.text('menu.import') || 'Import'}
                </MenuItem>
              )}
            </DropdownButton>
          </cms.Object>
        )}
      >
        {items.length > 0 && (
          <div className="list-group">
            {items.map(item => {
              const editable = canEdit ? canEdit(item) : true;

              return (
                <ClickableDiv
                  key={item.id}
                  id={item.id}
                  className={`list-group-item clearfix ${!editable && 'disabled'}`}
                  style={editable && pointerStyle}
                  onClick={edit}
                >
                  {renderItem(item)}
                </ClickableDiv>
              );
            })}
          </div>
        )}
      </Section>

      <Modal show={!!modal.content} onHide={modal.close} {...modal.options}>
        {modal.content}
      </Modal>
    </>
  );
}
