import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import * as actions from '../actions';
import usePrevious from './previous';

/**
 * @param {String} endpoint                           e.g. invoices, jobs, units etc.
 * @param {Object|Null|Undefined} [queryParams]      Can be undefined or null, but a query won't run until an object is passed
 * @param {Object} [options]
 */
export default function useListLoader(endpoint, queryParams, { maxAge = 60000, sortItems } = {}) {
  const prevQueryParams = usePrevious(queryParams);

  const [isLoading, setIsLoading] = useState(false);

  const cachedResult = queryParams && actions.fetchCached(endpoint, queryParams);
  const cachedItems = cachedResult ? cachedResult.data : [];

  async function runFetch() {
    if (!queryParams) return;

    setIsLoading(true);
    try {
      return await actions.fetch(endpoint, queryParams, { sortItems });
    }
    finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!queryParams) return;

    // Check query has changed before updating
    // (built-in useEffect comparison does not work here)
    if (isEqual(queryParams, prevQueryParams)) return;

    async function update() {
      if (!cachedResult) {
        // Load for the first time
        runFetch();
      } else {
        // Update in the background if cached version has expired
        const age = Date.now() - (cachedResult.fetched || 0);
        if (age >= maxAge) runFetch();
      }
    }

    update();
  }, [queryParams]);


  return {
    items: cachedItems,
    isLoading,
    refetch: runFetch,
  };
}
