import SidebarSummary from './sidebar-summary';


export default function UnitDetailModalSummary({ unit, rental, modal, onChange }) {
  return (
    <SidebarSummary
      unit={unit}
      rental={rental}
      modal={modal}
      onChange={onChange}
    />
  );
}
