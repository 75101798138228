import loadImage from 'blueimp-load-image';
import api from '../utils/api';


function resizeImage(file) {
  return new Promise((resolve, reject) => {
    //Load and resize images before sending
    var loadImageOptions = {
      canvas: true,
      maxWidth: 1200,
      maxHeight: 1200
    };

    loadImage(file, canvas => {
      //if (canvas.type == 'error') { return alert('Error loading image'); }
      if (canvas.type === 'error') {
        return reject('Error uploading image');
      }

      //NOTE: Requires the canvas.toBlob polyfill (https://github.com/blueimp/JavaScript-Canvas-to-Blob)
      canvas.toBlob(resolve, file.type);
    }, loadImageOptions);
  });
}

var actions = {};

/**
 * @param {File} file
 * @returns {Promise<{url: string}>}
 */
actions.uploadFile = async function (file) {
  let blob = file;

  if (file.type.startsWith('image/') && file.type !== 'image/svg+xml' || file.type === 'image/svg+xml' && file.size > 1e6) {
    // resize all images, except small svgs
    blob = await resizeImage(file);
  }

  var formData = new FormData();

  formData.append('file', blob, file.name);

  var xhrConfig = {
    transformRequest: function (data) { return data; },
  };

  //Upload
  return api.post('/v1/admin/uploads', formData, xhrConfig)
    .then(res => {
      return res.data;
    });
};

/**
 * @param {String} path
 * @returns {Promise<string>}
 */
actions.getSignedUrl = async function (path) {
  return api.get(`/v1/admin/uploads?` + new URLSearchParams({ path }))
    .then(res => res.data.url);
};

actions.deleteFile = async function (path) {
  return api.delete(`/v1/admin/uploads?` + new URLSearchParams({ path }));
};

export default actions;
