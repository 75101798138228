import Select from 'react-select';

function getSelectedOptions(value, options) {
  if (Array.isArray(value)) {
    // Create a new array with the selected options in the correct order
    return value
      .map(val => options.find(option => option.value === val))
      .filter(Boolean);
  }

  const selectedOption = options.find(option => option.value === value);

  if (selectedOption) {
    return selectedOption;
  }
}

export default function SearchableSelect({
  value,
  onChange,
  name,
  children,
  options = children,
  ...props
}) {

  const selectedOptions = getSelectedOptions(value, options);

  const handleChange = (selectedOption) => {
    const selectedValue = Array.isArray(selectedOption)
      ? selectedOption.map(option => option.value)
      : selectedOption?.value || null;

    onChange({
      target: {
        name,
        value: selectedValue,
      },
    });
  };

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
      padding: 0,
      border: 0,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 2,
    }),
  };

  return (
    <Select
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      isMulti={Array.isArray(value)}
      styles={selectStyles}
      isClearable
      {...props}
    />
  );
};
