import moment from 'moment-timezone';
import wurd from 'wurd-react';
import Avatar from '../avatar';
import StateLabel from '../state-label';
import LabelsListCell from 'plugins/labels/list';
import { emailConfirmedProps } from 'helpers/user';
import { currentLang } from 'utils/language';


export default function UserListItem({
  user,
  hasMultipleMarkets,
  marketTitles,
  planTitles = {},
}) {
  const joined = moment(user.created).locale(currentLang).fromNow();
  const market = hasMultipleMarkets && marketTitles[user.market.id];
  const plan = planTitles[user.planId];

  return (
    <>
      <div role="cell" style={{ width: 64 }}>
        <Avatar user={user} />
      </div>
      <div role="cell">
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <strong>
            {user.firstName} {user.lastName}
            {user.companyName && ` (${user.companyName})`}
          </strong>
          <div>
            {user.roles?.map(role => (
              <StateLabel type="warning" key={role}>{wurd.text(`user._roles.${role}`, user)}</StateLabel>
            ))}
            {user.permissions?.map(permission => (
              <StateLabel type="warning" key={permission} style={{ filter: 'sepia(1)' }}>
                {permission}
              </StateLabel>
            ))}
            {user.isActive && (
              <StateLabel type="success">{wurd.text('userList.filters.state.active')}</StateLabel>
            )}
            {user.isDisabled && (
              <StateLabel type="danger">{wurd.text('userList.filters.state.disabled')}</StateLabel>
            )}
            {user.overdue && (
              <StateLabel type="danger">{wurd.text('userList.filters.state.overdue')}</StateLabel>
            )}
          </div>
        </div>
        <div><span {...emailConfirmedProps(user)}>{user.email}</span></div>
      </div>
      <div role="cell" title="Market &amp; Plan" className="hidden-xs">
        {market}<br />
        {plan}
      </div>
      <div role="cell" title="Joined / Promo code" className="hidden-xs">
        {joined}<br />
        {user.promoCode}
      </div>
      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={user.labels} />
      </div>
    </>
  );
}
