import * as helpers from '../../../helpers';
import schema2form from '../schema2form';

import Checklist from '../checklist';
import ItemType from '../../settings/item-type';


const wurdSection = 'market.products';

const schema = [
  {
    name: 'products',
    custom: props => {
      var productConfigs = helpers.product.list();

      return (
        <Checklist {...props}>
          {productConfigs.map(product =>
            <ItemType key={product.id} id={product.id} item={product} />
          )}
        </Checklist>
      )
    }
  }
];


export default schema2form(schema, { wurdSection });
