import { useItemLoader } from '../../hooks';


export default function UnitLoader({
  id,
  include,
  maxAge,
  fetch,
  loading = <i className="fas fa-spinner fa-spin" />,
  fallback = <i className="fas fa-question-circle" title="Not found" />,
  children: renderFn,
}) {
  const { item: valetOrder, isLoading, refetch } = useItemLoader('valet-orders', id, {
    include,
    maxAge,
    fetch,
  });

  if (!valetOrder) {
    return isLoading ? loading : fallback;
  }

  return renderFn(valetOrder, { isLoading, refetch });
}
