const MAX_POLLERS = 3;
const activeIds = new Set();

function log() {
  if (process.env.NODE_ENV !== 'development') return;
  
  console.log.apply(console, arguments);
}


export function start({
  id,
  fn,
  interval = 5000,
  timeout = 60000,
}) {
  if (!id) throw new Error('Polling ID is required to prevent overpolling.  Use a job ID or similar.')
  if (typeof fn !== 'function') throw new Error('Missing required callback function for polling');

  // Prevent creating runaway pollers from React re-renders etc.
  if (activeIds.size > MAX_POLLERS) throw new Error('Reached maximum number of pollers.');

  // Check if polling is already active for this ID
  // This prevent re-creating polls and ending up with too many timers/requests from re-renders etc.
  if (activeIds.has(id)) return;

  // Start
  activeIds.add(id);
  let startedAt = new Date();
  log(`poller ${id}: starting`);

  function stop() {
    activeIds.delete(id);
  log(`poller ${id}: stopping`);
  }

  async function run() {
    const shouldContinue = await fn();
    if (shouldContinue !== true) return stop();

    // Exit if the timeout has been hit
    const totalTime = Date.now() - startedAt.getTime();
    if (totalTime >= timeout) return stop();
      
    // Set up the next run
  log(`poller ${id}: totalTime ${totalTime}`);
    setTimeout(run, interval);
  }

  run();
}
