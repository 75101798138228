import { WurdText } from 'wurd-react';
import { getLangText } from 'helpers/ui';
import { currentLang } from 'utils/language';
import LangText from 'components/lang-text';
import { Editable } from 'components/markdown';
import { Field, MultiLangText, FormObject, ModalForm2 } from 'components/form2';
import Logo from 'components/logo';
import UrlPicker from '../url-picker';

  
export function edit({ block, setBlock, modal }) {
  modal.open(
    <ModalForm2
      wurdSection="website.page.block"
      mode="update"
      initialValue={block}
      onSubmit={async data => setBlock(data)}
      onSuccess={modal.close}
      closeModal={modal.close}
    >
      {({ fieldProps }) => (
        <>
          <Field {...fieldProps('text')} autoFocus><MultiLangText multiline /></Field>
        </>
      )}
    </ModalForm2>
  );
}


export default ({ block, setBlock, modal }) => {
  function editItem(k, i) {
    modal.open(
      <ModalForm2
        wurdSection="website.page.block_item"
        mode={i !== undefined ? 'update' : 'create'}
        initialValue={block.cols?.[k]?.links?.[i]}
        onSubmit={async data => {
          return i !== undefined
            ? setBlock({ cols: { [k]: { links: { [i]: data } } } })
            : setBlock({ cols: { [k]: { links: [...block.cols?.[k]?.links || [], data] } } });
        }}
        onDelete={i !== undefined && (async () => setBlock({ cols: { [k]: { links: block.cols?.[k]?.links.filter((_, j) => i !== j) } } }))}
        onSuccess={modal.close}
        closeModal={modal.close}
      >
        {({ fieldProps }) => (
          <>
            <Field {...fieldProps('text')} autoFocus><MultiLangText /></Field>
            <Field {...fieldProps('url')}><UrlPicker /></Field>
          </>
        )}
      </ModalForm2>
    )
  }

  function editColTitle(k, value) {
    if (k === undefined) {
      return setBlock({ cols: [...block.cols || [], { title: { [currentLang]: value } }] });
    }
    if (!value && !block.cols?.[k]?.links?.length) {
      return setBlock({ cols: block.cols.filter((_, j) => k !== j) });
    }
    setBlock({ cols: { [k]: { title: { [currentLang]: value } } } });
  }

  return (
    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', padding: '1rem 1rem 0' }}>
      <div style={{ flex: '1 1 200px' }}>
        <Logo style={{ maxWidth: '100%', height: 20, objectFit: 'contain', opacity: .75, display: 'block' }} />

        <Editable className="mt-2" placeholder="[Text]" onChange={e => setBlock({ text: { [currentLang]: e.target.value } })}>{getLangText(block.text)}</Editable>
      </div>

      <div style={{ flex: '1 1 60%', display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
        {block.cols?.map((col, k) => col && (
          <div key={k} style={{ flex: '1 1 150px' }}>
            <Editable
              inline
              placeholder="Column"
              onChange={e => editColTitle(k, e.target.value)}
            >
              {getLangText(col.title)}
            </Editable>
            <ul className="list-unstyled">
              {col.links?.map((item, i) => item && (
                <li key={i} onClick={e => editItem(k, i)}>
                  <a
                    href={item.url}
                    onClick={e => e.preventDefault()}
                    target="_blank"
                    className="btn btn-link btn-sm mb-0"
                  >
                    <LangText>{item.text}</LangText>
                  </a>
                </li>
              ))}
              <li>
                <button className="btn btn-link btn-sm mb-0" onClick={() => editItem(k)} style={{ outline: '1px dashed var(--bs-primary)', outlineOffset: -1 }}>
                  <i className="far fa-plus" /> <WurdText id="website.blocks.addLink" />
                </button>
              </li>
            </ul>
          </div>
        ))}

        <div style={{ flex: '1 1 150px', filter: 'opacity(.5)' }}>
          <Editable
            inline
            placeholder="New column?"
            onChange={e => editColTitle(undefined, e.target.value)}
          />
          <ul className="list-unstyled">
            <li>
              <button className="btn btn-link btn-sm mb-0" onClick={() => editItem(block.cols?.length)} style={{ outline: '1px dashed var(--bs-primary)', outlineOffset: -1 }}>
                <i className="far fa-plus" /> <WurdText id="website.blocks.addLink" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
