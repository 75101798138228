import _ from 'lodash';
import wurd from 'wurd-react';

import store from '../store';
import * as actions from '../actions';
import * as helpers from '.';
import * as language from '../utils/language';
import { getLangText } from './ui';
import processCustomFields from '../plugins/custom-fields/actions';


function doTranslate(itemConfig, lang) {
  var obj = Object.assign({}, itemConfig);

  ['title', 'displayPrice', 'info'].forEach(prop => {
    obj[prop] = getLangText(obj[prop], lang);
  });

  return obj;
}

export const states = [
  'storage',
  'transit-storage',
  'transit-user',
  'user',
  'unassigned',
];

export const stateLabelTypes = {
  storage: 'success',
  'transit-storage': 'warning',
  'transit-user': 'warning',
  user: 'danger',
  unassigned: 'info',
};

export function getStateTitles() {
  return wurd.get('item._states') || {};
}

export const stateIcons = {
  storage: 'far fa-box-check',
  'transit-storage': 'far fa-truck',
  'transit-user': 'far fa-truck',
  user: 'far fa-home',
  unassigned: 'far fa-box-open',
};


/**
 * Returns the list of item types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const list = function (translate) {
  const { settings } = store.get();

  const itemConfigs = settings.items || [];

  if (translate) {
    const lang = language.get();

    return itemConfigs.map(itemConfig => doTranslate(itemConfig, lang));
  } else {
    return itemConfigs;
  }
};


/**
 * Returns the list of item types, but only bulky types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const listBulky = function (translate) {
  return list(translate).filter(item => item.type.substr(0, 5) === 'bulky')
};


/**
 * Returns the list of item types, but only empty box types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const listEmpty = function (translate) {
  return list(translate).filter(item => item.type.substr(0, 5) !== 'bulky')
};


/**
 * Returns an object of item types, keyed by type
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object}
 */
export const byType = function (translate) {
  return _.keyBy(list(translate), 'type');
};


/**
 * Returns titles for item types, keyed by type and in the user's language
 *
 * @return {Object}
 */
export const titlesByType = function () {
  var lang = language.get();

  return list().reduce((memo, item) => {
    memo[item.type] = getLangText(item.title, lang);
    return memo;
  }, {});
};


/**
 * Returns a specific item type
 *
 * @param {String} type
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const get = function (type, translate) {
  //var itemConfig = findWhere(list(), {type: type});
  let itemConfig = list().find(item => item.type === type);

  if (!itemConfig) {
    let err = new Error('itemConfig not found for ' + type);
    //_errs.push(err);
    console.error(err);
    return {};
  }

  if (translate) {
    return doTranslate(itemConfig, language.get());
  } else {
    return itemConfig;
  }
};


/**
 * Gets the title of an item given it's type
 *
 * @param {String} type
 */
export const getTitle = function (type) {
  var itemConfig = get(type, true) || {};

  return itemConfig.title;
};


export const getImage = function (type) {
  var itemConfig = get(type) || {};

  return itemConfig.image;
};


export const isBulky = function (type) {
  return type && type.substr(0, 5) === 'bulky';
};


/**
 * Formats item as CSV
 *
 * @param {Object} item
 * @return {String} csv
 */
export function toCsv(item) {
  const settings = store.get('settings');

  return {
    id: item.id,
    sid: item.sid?.toUpperCase(),
    type: item.type,
    // _type: titles[item.type],
    state: item.state,
    // _state: states[item.state],
    location: item.location,
    ownerId: item.owner?.id,
    ..._.mapKeys(helpers.user.toCsv(item.owner || {}), (v, k) => `owner.${k}`),
    opsTitle: item.opsTitle,
    opsImages: item.opsImages?.map(img => img.url), // todo support opsImages, for more than 1
    labels: item.labels?.join(','),
    ...Object.fromEntries(
      settings.itemCustomFields?.sort((a,b) => a.code.localeCompare(b.code)).map(({ code, title }) => {
        const value = item.customFields?.[code];
        return [`customFields.${code}`, value?.url || value];
      })
    ),
    created: item.created,
    updated: item.updated,
  };
}


export async function fetchDetailed(params) {
  const items = await actions.fetch('items', params, { skipCache: true });

  const users = await actions.fetch('users', { ids: items.map(item => item.owner?.id).filter(Boolean), include: 'billing,customFields' }).then(users => new Map(users.map(o => [o.id, o])));


  return items.map(item => {
    const owner = users.get(item.owner?.id);

    return { ...item, owner };
  });
}


export async function fromCsv({ ownerId: _ownerId, _owner_email, ...item }) {
  if (item.customFields) {
    item.customFields = await processCustomFields(item.customFields, 'items');
  }

  const ownerId = _ownerId || _owner_email && (await actions.fetchOneWithCache('users', _owner_email, { altIdKey: 'email' }))?.id;
  if (!ownerId && _owner_email) throw new Error(`owner ${_owner_email} not found`);

  return {
    ...item,
    ownerId: ownerId,
    labels: (item.labels || undefined)?.split(','),
  };
}