import api from '../utils/api';
import errHandler from './_err-handler';


var actions = {};


/**
 * Fetches activity items (actions)
 * 
 * @param {String} endpoint
 */
actions.fetch = function (endpoint, params) {
  return api.get('/v1/admin' + endpoint, { params })
    .then(res => res.data)
    .catch(errHandler)
};


/**
 * Fetches activity items (actions) by targetId
 * 
 * @param {String} targetId
 */
actions.fetchByTarget = function (targetId, params) {
  return api.get(`/v1/admin/actions/${targetId}`, { params })
    .then(res => res.data)
    .catch(errHandler)
};


/**
 * Creates a new comment activity item
 *
 * @param {String} endpoint
 * @param {String} text
 */
actions.createComment = function (endpoint, text) {
  var data = {
    type: 'comment',
    text: text
  };

  return api.post('/v1/admin' + endpoint, data)
    .then(res => res.data)
    .catch(errHandler)
};


export default actions;
