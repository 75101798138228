import { pick } from 'lodash';

import ModalForm from '../../form2/modal-form';
import { Field, MultiLangText } from '../../form2';


const fields = [
  'title',
  'subtitle',
  'address',
  'phone',
  'email',
];

export default function SiteForm({ site, ...rest }) {
  return (
    <ModalForm
      {...rest}
      initialValue={pick(site, fields)}
      wurdSection="site"
    >
      {fieldProps =>
        <div>
          <Field {...fieldProps('title')}>
            <MultiLangText autoFocus />
          </Field>

          <Field {...fieldProps('subtitle')}>
            <MultiLangText />
          </Field>

          <Field {...fieldProps('address')}>
            <MultiLangText multiline />
          </Field>

          <Field {...fieldProps('phone')} />

          <Field {...fieldProps('email')} />
        </div>

      }
    </ModalForm>
  );
}
