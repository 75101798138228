import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useModal, useItemLoader } from '../hooks';
import * as actions from '../actions';

import NotFound from '../components/not-found';
import Spinner from '../components/spinner';
import UnitDetailInnerPage from '../components/unit-detail/page';


export default function UnitDetailPage() {
  const { id, section } = useParams();
  const navigate = useNavigate();

  const modal = useModal();

  const { item: unit, isLoading, refetch } = useItemLoader('units', id, {
    include: ['customFields'], // no need to include rental because it is fetched in the redirect below if it exists
    maxAge: 0,
  });

  useEffect(() => {
    // If rented out, forward to the current UnitRental page
    if (isLoading === false && unit?.rentalId) {
      navigate(`/unit-rentals/${unit.rentalId}${section ? '/' + section : ''}`, { replace: true });
    }
  }, [isLoading]);

  if (!unit) {
    return isLoading ? <Spinner /> : <NotFound />;
  }
  
  // Otherwise the unit is available so show the empty unit page
  return (
    <UnitDetailInnerPage
      unit={unit}
      fetchUnit={refetch}
      modal={modal}
      section={section}
    />
  );
}
