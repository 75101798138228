import { useState, useEffect } from 'react';

import * as actions from '../actions';

const activeRequests = new Set();


export default function useItemLoader(endpoint, id, { altIdKey, include, maxAge = 60000, fetch } = {}) {
  // Initialise with the item from cache if available
  const cachedResult = actions.fetchOneCached(endpoint, id, { altIdKey });
  const cachedItem = cachedResult ? cachedResult.data : null;

  const [isLoading, setIsLoading] = useState(cachedResult ? null : true); // isLoading=false is kept for the end of loading

  const requestId = `${endpoint}/${id}?include=${include}`;

  // Fetching will update the item in the store and trigger a re-render
  // When this runs again it will have access to the cached item
  async function runFetch(fetch) {
    activeRequests.add(requestId);
    setIsLoading(true);
    try {
      return await (typeof fetch === 'function' ? fetch : actions.fetchOne)(endpoint, id, { include });
    } finally {
      activeRequests.delete(requestId);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!id) return;
    if (!cachedItem) {
      // Check if there is already a request sent for this id
      // This can happen when the app re-renders
      if (activeRequests.has(requestId)) return;

      // Load for the first time
      runFetch(fetch);
    } else {
      // Update in the background if cached version has expired
      const age = Date.now() - (cachedResult.fetched || 0);
      if (age >= maxAge) runFetch();
    }
  }, [id]);

  return {
    item: cachedItem,
    isLoading,
    refetch: runFetch,
  };
}
