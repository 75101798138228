import { Component } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import * as helpers from '../../../../helpers';

import JobStep from '../../job-step2';

const cms = wurd.block('unitOrder.steps.startDate_change');


export default class UniveMoveInJobSteps_startDate_change extends Component {

  static propTypes = {
    step: PropTypes.object.isRequired,
  };

  render() {
    const { step } = this.props;

    const renderFn = this[`render_${step.state}`];
    if (!renderFn) return null;

    return renderFn.call(this);
  };

  render_completed() {
    const { step } = this.props;
    const { startDate } = step.result;

    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" vars={{ startDate: helpers.ui.date(startDate) }} />}
      />
    );
  };

};
