import wurd from 'wurd-react';
import kebabCase from 'lodash/kebabCase';

import * as helpers from '../../helpers';
import crudList from '../../actions/plugins/crud-list';


export default function (endpoint) {
  const handlers = crudList(endpoint);

  return {
    ...handlers,

    delete(itemId) {
      return handlers.delete(itemId, err => {
        if (err.status !== 400) return; // only handle case where label has docs using it

        const vars = {
          endpoints: err?.errors?.endpoint,
          ids: err?.errors?.ids,
          count: err?.errors?.ids.length
        }
        if (!window.confirm(wurd.text('common.labels.errors.delete', vars) || `${err.message}\nWant to see them?`)) return;

        helpers.ui.redirect(`/${kebabCase(err?.errors?.endpoint)}s?labels=${itemId}`);
      });
    }
  }
}
