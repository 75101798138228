/**
 * An Export button that downloads a CSV file to the user's browser
 */
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import { Button } from 'react-bootstrap';
import { Toggle, Field } from 'components/form2';
import Markdown from 'components/markdown';
import PapaCsv from 'papaparse';
import _ from 'lodash';
import * as actions from 'actions';
import * as helpers from 'helpers';


const cms = wurd.block('common.exportBtn');

const Columns = styled.div({
  marginBottom: '2rem',

  details: {
    textAlign: 'left',
    marginBottom: '1rem',
  },

  label: {
    marginBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  summary: {
    marginBottom: '1rem',
    button: {
      marginBottom: '0 !important'
    },
  },

  '.title': {
    display: 'flex',
    '> :first-child': {
      flex: 1,
    }
  },

  '.grid': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    }
  }
});


export default function ExportButton({
  filename,
  fetch,
  toCsv,
  sections: defaultSections,
  children = <cms.Text id="export" />,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [_filter, setFilter] = useState('');
  const runFetchRef = useRef(); // used to stop the async fetch loop if user click download before the end
  const [usingCursor, setUsingCursor] = useState(false);

  // get a fake item row to list all possible columns, toCsv return an object or an array of objects
  const testRow = toCsv({ owner: {}, unit: {}, site: { unitTypes: [{}] }, invoice: {}, type: { charges: [{}] }, rental: { charges: [{}] }, charges: [{}], payments: [{}], items: [{}], entries: [{}] }, props);
  const allColumns = Object.keys(Array.isArray(testRow) ? testRow[0] : testRow);

  // group columns by sections, main is columns of the current model, other sections are related data
  const sections = allColumns.reduce((acc, name) => {
    const prefix = ['owner', 'unit', 'type', 'site', 'rental', 'invoice'].find(k => name.startsWith(k + '.'));

    return {
      ...acc,
      ...prefix ? { [prefix]: [...acc[prefix] || [], name] } : { main: [...acc.main, name] }
    };
  }, { main: [] });

  // load selected columns from localStorage
  const [columns, setColumns] = useState(
    localStorage.getItem(`export_${filename.split(/[ .]/)[0]}`)?.split(',').filter(Boolean) || (
      defaultSections
        ? Object.entries(defaultSections).flatMap(([k, v]) => v === '*' ? sections[k] : v.map(x => k === 'main' ? x : `${k}.${x}`))
        : sections.main
      )
  );

  async function startExport() {
    setLoading(true);
    runFetchRef.current = true;
    try {
      let lastId = '';
      let i = 0;
      while (runFetchRef.current) {
        const chunk = await fetch({
          ...usingCursor
            ? { cursor: lastId }
            : { offset: i * 1000 },
          limit: 1000,
        });
        i++;
        lastId = chunk[chunk.length - 1]?.id;
        setData(existingData => [...existingData, ...chunk]);
        if (chunk.length < 1000) break; // we're done since chunk.length < limit
        if (!usingCursor && i >= 10) {
          helpers.ui.notify({
            bsType: 'warning',
            duration: 7000,
            text: wurd.text('common.errMsg.offset_max'),
          });
          break;
        }
      }
    }
    catch (err) {
      if (err.type === 'Validation' && err.errors?.offset.type === 'maximum') {
        err.message = wurd.text('common.errMsg.offset_too_high');
      }
      helpers.ui.notify({
        bsType: 'danger',
        duration: 7000,
        text: `${err.message} ${Object.entries(err.errors).map(([k, v]) => `${k}: ${v.message}`).join(', ')}`,
      });
      throw err;
    }
    finally {
      setLoading(false);
    }
  }

  function stop() {
    runFetchRef.current = false;
    setLoading(false);
  }

  function download(e) {
    const rows = data
      .flatMap(row => {
        const v = toCsv(row, props);
        return Array.isArray(v) ? v : [v];
      })
      .map(row => _.pickBy(row, (v,k) => columns.includes(k.replace(/\.\d+\./, '.0.')))) // keep only selected columns
      .map(row => _.mapValues(row, v => helpers.csv.formatValue(v)));

    // we need to make sure the first row will have all possible columns used in rows array, else papaparse will trim down all other rows from this first row columns
    const maxColsRow = rows.reduce((current, row, i) => Object.keys(row).length > Object.keys(rows[current]).length ? i : current, 0);
    if (maxColsRow > 0) {
      rows[0] = {
        ...Object.fromEntries(Object.keys(rows[maxColsRow]).map(k => [k, ''])),
        ...rows[0]
      };
    }

    const csvText = PapaCsv.unparse(rows, { escapeFormulae: true });
    const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    e.currentTarget.href = url;
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 0);
    setData([]);
    localStorage.setItem(`export_${filename.split(/[ .]/)[0]}`, columns.join(','));
  }

  if (loading) {
    return (
      <Button bsStyle="warning" onClick={stop}>
        <i className="fas fa-sync fa-spin" /> <cms.Text id="loading" vars={{ count: data.length || '' }} />
      </Button>
    );
  }

  if (data.length > 0) {
    return (
      <Button href="blob:" target="_blank" download={filename} bsStyle="success" onClick={download}>
        <i className="fas fa-check" /> <cms.Text id="download" />
      </Button>
    )
  }

  const toggle = name => setColumns(columns.includes(name) ? columns.filter(c => c !== name) : [...columns, name]);

  const filter = _filter.toLowerCase();
  const filteredSections = Object.fromEntries(
    Object.entries(sections).map(([k, v]) => [
      k,
      v.filter(x => k === 'main' ? x.toLowerCase().startsWith(filter) : x.split('.').slice(1).join('.').toLowerCase().startsWith(filter))
    ])
  );

  return (
    <>
      <input
        value={filter}
        type="search"
        onChange={e => setFilter(e.target.value)}
        className="input-sm"
        style={{ border: '1px solid #0004', marginBottom: '.5rem' }}
        placeholder={cms.text('filter.placeholder')}
      />
      <Columns>
        {Object.entries(filteredSections).map(([key, cols]) => {
          if (cols.length === 0) return null;
          const others = columns.filter(c => !cols.includes(c));
          const total = cols.length;
          const selected = cols.filter(c => columns.includes(c)).length;
          const toggleAll = e => setColumns(e.target.checked ? [...others, ...cols] : others)
          return (
            <details key={key} open={selected > 0}>
              <summary>
                <label>
                  <input type="checkbox" checked={selected === total} onChange={toggleAll} /> <cms.Text type="strong" id={key === 'main' ? filename.split(/[ .]/)[0] : key} vars={{ total, selected }} />
                </label>
              </summary>

              <div className="grid">
                {cols.map(name => (
                  <label key={name}>
                    <input type="checkbox" checked={columns.includes(name)} onChange={() => toggle(name)} /> <span>{key !== 'main' ? name.slice(key.length + 1) : name}</span>
                  </label>
                ))}
              </div>
            </details>
          );
        })}
      </Columns>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <label htmlFor="toggle-full-export">
          <Toggle value={usingCursor} onChange={e => setUsingCursor(e.target.value)} className="btn btn-link mb-0" id="toggle-full-export" />
          {wurd.editMode ? <cms.Markdown inline id="cursor.label" /> : <Markdown inline>{cms.text('cursor.label')}</Markdown>}
        </label>
        
        <Button onClick={startExport} style={{ marginRight: '2rem' }}><i className="fas fa-download" /> {children}</Button>
      </div>
    </>
  );
}


ExportButton.propTypes = {
  fetch: PropTypes.func.isRequired,
  toCsv: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired
};
