import { useParams } from 'react-router-dom';
import wurd from 'wurd-react';

import * as helpers from '../helpers';
import * as actions from '../actions';
import { useModal, useItemLoader } from '../hooks';

import DetailPage from '../components/detail-page';
import NotFound from '../components/not-found';
import Spinner from '../components/spinner';
import Alert from '../components/alert';
import Activity from '../components/activity';
import Menu from '../components/item-detail/menu';
import SidebarSummary from '../components/item-detail/sidebar-summary';
import LabelsList from 'plugins/labels/detail';
import CustomFieldsSection from 'plugins/custom-fields/section';
import ApiButton from 'components/api-button';

const cms = wurd.block('itemView');
const { sid, href } = helpers.ui;


export default function ItemDetailPage() {
  const { idOrSid } = useParams();

  const { item, isLoading, refetch } = useItemLoader('items', idOrSid, { altIdKey: 'sid', maxAge: 0, include: actions.includeKeysByEndpoint.items });
  const modal = useModal();
  const { item: valetOrder, isLoading: isValetOrderLoading } = useItemLoader('valet-orders', item && item.valetOrderId, { maxAge: 0 });

  if (!item) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  async function removeFromValetOrder() {
    if (!window.confirm(cms.text('removeFromOrder.confirm'))) return;

    await actions.items.update(item.id, { valetOrderId: null });
  }

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="operations"
      documentTitle={`Item ${sid(item)}`}
      title={sid(item)}
      ancestors={[
        { text: wurd.text('itemList.title'), url: '/items' },
      ]}
      extra={(
        <>
          <LabelsList
            labels={item.labels}
            settingsLabels={helpers.settings.get().itemLabels}
            onChange={labels => actions.items.labels.update(item.id, labels)}
          />

          <Menu
            item={item}
            valetOrder={valetOrder}
            modal={modal}
          />
        </>
      )}
      sidebar={(
        <SidebarSummary
          item={item}
          modal={modal}
        />
      )}
    >
      {item.visit && (
        <Alert type='warning'>
          <cms.Markdown
            id="warning.hasVisit"
            vars={{
              visitId: sid(item),
              visitUrl: href(`/visits/${sid(item)}`),
            }}
          />
        </Alert>

      )}

      {valetOrder && (
        <Alert type="warning">
          {valetOrder.state === "completed" && (
            <cms.Object id="removeFromOrder" keys="button,confirm">
              <ApiButton
                bsType="default"
                onClick={removeFromValetOrder}
                className="pull-right"
              >
                <i className="far fa-unlink" /> {cms.text('removeFromOrder.button')}
              </ApiButton>
            </cms.Object>
          )}
          
          <cms.Markdown
            id="warning.hasValetOrder"
            vars={{
              orderId: sid(valetOrder),
              orderUrl: href(`/valet-orders/${sid(valetOrder)}`),
            }}
          />
        </Alert>
      )}

      <CustomFieldsSection
        cms={cms.block('customFields')}
        modelName="item"
        model={item}
        onChange={refetch}
        modal={modal}
      />

      <Activity context="item" endpoint={`/items/${item.id}/actions`} />
    </DetailPage>
  );
}
