import _ from 'lodash';
import { useState } from 'react';
import wurd from 'wurd-react';

const cms = wurd.block('common.weekDays');

export default function DayTimeslots({ name, value = [], onChange }) {
  const [text, setText] = useState(value.join('\n'));

  function handleChange(event) {
    setText(event.target.value);

    onChange(_.compact(event.target.value.split('\n')));
  }

  return (
    <div style={{ display: 'inline-block', width: 90, marginRight: 10 }}>
      <cms.Text id={name} type="div" style={{ fontWeight: 600 }} />
      <textarea
        name={name}
        value={text}
        style={{ height: 150, width: '100%', margin: 0, padding: 0, borderColor: '#eee' }}
        onChange={handleChange}
      />
    </div>
  );
}
