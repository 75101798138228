import { WurdText } from 'wurd-react';

import { hasRole } from 'helpers/auth';
import { currency, datePeriod, getLangText } from '../../helpers/ui';
import { stateBsTypes, isCreditNote } from '../../helpers/invoice';

import UnitRentalLoader from '../loaders/unit-rental';
import UserLoader from '../loaders/user';
import SiteLoader from '../loaders/site';
import StateLabel from '../state-label';

import LabelsListCell from 'plugins/labels/list';

const styles = {
  secondary: {
    color: '#bbb',
  },
  tertiary: {
    color: '#bbb',
    fontSize: '.8em',
    lineHeight: '2.1em',
  },
};


export default function InvoiceListItem({
  invoice,
  stateTitles,
  hideSite = false,
  hideOwner = false,
  hideUnit = false,
}) {
  const [startDate, endDate] = datePeriod(invoice.startDate, invoice.endDate);
  const isCredit = isCreditNote(invoice);
  const Span = location.hash.includes(invoice.sid) || location.hash.includes(invoice.id) ? 'mark' : 'span';

  const ownerName = !hideOwner && (
    <strong>
      <UserLoader id={invoice.owner.id} loading={invoice.owner.name}>
        {user => user.name}
      </UserLoader>
      &nbsp;&nbsp;
    </strong>
  );

  const unitName = !hideUnit && invoice.unitRentalId && (
    <UnitRentalLoader id={invoice.unitRentalId} include="unit" maxAge={300000}>
      {(unitRental) => (
        <span style={styles.secondary} className="text-nowrap">
          <i className="far fa-building" /> {unitRental.unit?.name}
        </span>
      )}
    </UnitRentalLoader>
  );

  return (
    <>
      <div role="cell">
        <div>
          {ownerName}
          <small className="visible-xs-inline">{unitName}</small>
          <Span style={styles.secondary} className="hidden-xs">
            {invoice.sid.toUpperCase()}
          </Span>
        </div>

        <div style={styles.secondary} className="hidden-xs">
          {unitName}
          {!hideSite && invoice.siteId && (
            <span className="hidden-xs">, <SiteLoader id={invoice.siteId}>{site => getLangText(site.title)}</SiteLoader></span>
          )}
          {hasRole('_support') && Boolean(invoice.tags?.length) && (
            <small title="System tags" className="hiddex-sm" style={{ marginLeft: 10 }}>
              <i className="fa-fw far fa-sm fa-tag" />{invoice.tags?.join(',')}
            </small>
          )}
        </div>

        <div style={styles.secondary} className="visible-xs">
          {startDate} &nbsp; {invoice.sid.toUpperCase()}
        </div>
      </div>

      <div role="cell" className="hidden-xs">
        <div>{startDate}</div>
        {endDate !== startDate && (
          <div style={styles.tertiary}> → {endDate}</div>
        )}
      </div>

      <div role="cell" className="text-right">
        <div className={invoice.overdue ? 'text-danger' : ''}>
          {currency(invoice.total)}{invoice.balance > 0 && invoice.balance < invoice.total && <WurdText id="invoiceList.item.balance" vars={{ balance: currency(invoice.balance) }} style={{ filter: 'opacity(.6)' }} />}
        </div>

        <div>
          {isCredit && <StateLabel type={stateBsTypes.credit}>{stateTitles.credit}</StateLabel>}
          <StateLabel type={stateBsTypes[invoice.state]}>{stateTitles[invoice.state]}</StateLabel>
          {invoice.overdue && <StateLabel type={stateBsTypes.overdue}>{stateTitles.overdue}</StateLabel>}
          {invoice.deleted && <StateLabel type={stateBsTypes.deleted}>{stateTitles.deleted}</StateLabel>}
        </div>
      </div>

      <div role="cell" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={invoice.labels} />
      </div>
    </>
  );
}
