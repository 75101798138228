import wurd from 'wurd-react';

import * as helpers from '../../../helpers';

import { ModalForm, Field, MultiLangText, ImageInput } from '../../form2';

const cms = wurd.block('business.item');


export default function ItemForm(props) {
  const { mode } = props;

  return (
    <ModalForm
      wurdSection="business.item"
      {...props}
    >
      {(getProps, formValue, { setFormValue }) => (
        <>
          <Field {...getProps('systemType')}
            disabled={mode !== 'create'}
            value={helpers.item.isBulky(formValue.type) ? 'bulky' : 'box'}
            onChange={(event) => {
              const systemType = event.target.value;

              if (systemType === 'bulky' && !helpers.item.isBulky(formValue.type)) {
                setFormValue({ type: `bulky_${formValue.type || ''}` });
              }
            }}
          >
            <select data-wurd-obj={cms.id('systemType')} data-wurd-obj-props="box,bulky">
              <option value="box">{cms.text('systemType.box')}</option>
              <option value="bulky">{cms.text('systemType.bulky')}</option>
            </select>
          </Field>

          <Field {...getProps('type')}
            pattern="^[a-z0-9_-\d\.]{2,20}$"
            required
            autoFocus
            disabled={mode !== 'create'}
          />

          <hr />

          <Field {...getProps('image')}>
            <ImageInput
              imageFitProps={getProps('imageFit')}
            />
          </Field>

          <Field {...getProps('title')} required>
            <MultiLangText />
          </Field>

          <Field {...getProps('info')}>
            <MultiLangText />
          </Field>

          <Field {...getProps('price')}
            type="number"
            pattern="^\d+(\.\d{2})?"
            required
            autoComplete="off"
          />

          <Field {...getProps('displayPrice')}>
            <MultiLangText />
          </Field>

          <Field {...getProps('quantity')}
            type="number"
            min="0"
            step="1"
          />

          <Field {...getProps('maxQuantity')}
            type="number"
            min="0"
            step="1"
          />
        </>
      )}
    </ModalForm>
  );
}
