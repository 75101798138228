import Activity from 'components/activity';


export default function AddonHistoryOverview({
  cms,
  modal,
  refetch,
  addonConfig,
  addon,
}) {
  if (!addon) return null;
  
  return (
    <>
      <Activity
        context="addon"
        endpoint={`/addons/${addon.id}/actions`}
      />
    </>
  );
}
