import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  color: var(--bs-accent-red);

  &.danger-alert {
    border: 1px solid var(--bs-gray-light);
    border-left: 5px solid var(--bs-accent-red);

    .icon-alert {
      background: var(--bs-accent-red);
    }
  }

  .icon {
    margin-right: 15px;
    font-size: 20px;

    i {
      color: #fff;
      border-radius: 190px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }

  .main {
    flex-grow: 2;

    p:last-child {
      margin-bottom: 0;
    }
  }


  .actions {
    display: flex;
    align-items: center;
    margin-right: -10px;

    .btn {
      margin-bottom: 0 !important;
    }
  }

  .close-button {
    color: #979CA1;
    border-radius: 190px;
    border: 1px solid #979CA1;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    background: var(--bs-gray-bg);

    i {
      font-size: 16px;
    }
  }

`

export default function ErrorMessage({
  children,
  actions,
  helpUrl,
  canClose,
  icon = 'fas fa-exclamation',
}){
  return (
    <Wrapper className="alert danger-alert">
      <div className="icon">
        <i className={`${icon} icon-alert`} />
      </div>
      <div className={`main`}>
        {children}
      </div>
      <div className="actions">
        {actions}

        {helpUrl && (
          <a
            className="btn btn-link"
            href={helpUrl}
            target="_blank"
            rel="noopener noreferrer">
            <i className="far fa-question-circle" />
          </a>
        )}
        {canClose && (
          <button className="close-button">
            <i className="fal fa-times" />
          </button>
        )}
      </div>
    </Wrapper>
  )
}