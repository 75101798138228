import * as actions from 'actions';
import * as helpers from 'helpers';

import Alert from 'components/alert';
import Markdown from 'components/markdown';


export default function TaskJobList({
  listLoader,
}) {
  const taskJobs = listLoader.items;

  async function completeTask(job) {
    const fullJob = await actions.fetchOne('jobs', job.id);

    await actions.jobs.updateCurrentStep(fullJob, {
      state: 'completed',
    });

    listLoader.refetch();
  }


  return (
    <>
      {taskJobs.map(job => {
        return (
          <div key={job.id}>
            <Alert
              type="warning"
              icon="far fa-clipboard-check"
              onClickClose={() => completeTask(job)}
              actions={(
                <div style={{ marginRight: 10 }}>
                  {helpers.ui.date(job.data.dueDate)}
                </div>
              )}
            >
              <h5><strong>{job.data.title}</strong></h5>
              <Markdown>{job.data.desc}</Markdown>
            </Alert>
          </div>
        );
      })}
    </>
  );
}
