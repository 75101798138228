import { useEffect } from 'react';
import wurd, { WurdText } from 'wurd-react';
import { Modal } from 'react-bootstrap';
import { pick } from 'lodash';
import styled from '@emotion/styled';
import { useModal } from 'hooks';

import store from 'store';
import * as actions from 'actions';
import * as helpers from 'helpers';
import { useApiForm } from 'hooks';

import Section from 'components/section';
import Field from 'components/form2/horizontal-field';
import CommaList from 'components/form2/comma-list';
import InvoiceReminders from './invoice-reminders';
import EmailTemplateForm from './email-template-form';
import EmailEditor from './email-editor';


const cms = wurd.block('settings.emails');

const EmailGrid = styled.div({
  display: 'grid',
  gap: '2rem',
  // justifyContent: 'space-around',
  '@media (min-width: 576px)': {
    gridTemplateColumns: '1fr 1fr',
    '> :last-child': {
      gridColumnStart: 2
    }
  }
})

export default function BusinessEmailsSettings({ settings }) {
  const initialValue = pick(settings, [
    'emailFromName',
    'emailFromAddress',
    'emailInvoiceBCC',
  ]);

  const modal = useModal();

  const langKeys = settings.languages || ['en'];

  useEffect(() => {
    actions.business.emails.fetch(); // this will populate/refresh store.get('emailSettings')
  }, []);

  const emailSettings = store.get('emailSettings');

  const userEmails = Object.keys(emailSettings?.variables || {});

  const { fieldProps, submit, loading, dirty, errMsg } = useApiForm({
    initialValue,
    wurdSection: 'settings.inputs',
    onSubmit: actions.business.update,
  });

  /**
   * @param  {string} k templateKey
   */
  function renderEmail(k, emailOpts) {
    const noDisable = ['general_user_resetPassword', 'general_user_confirmEmail'].includes(k);

    return (
      <li key={k}>
        <button
          className="btn-link text-left"
          style={{ outline: 'none' }}
          onClick={(e) => {
            const emailTemplate = emailSettings.templates[k] || emailSettings.defaultTemplates[k] || {};

            async function updateEmailTemplate({ subject, text, enabled }) {
              const langKeys = Object.keys(subject);
              const changedKeys = langKeys.filter(lang => emailTemplate[lang]?.subject !== subject[lang] || emailTemplate[lang]?.text !== text[lang]);

              // validate
              const errs = await Promise.all(
                changedKeys.map(lang => actions.emails.previewChanges(k, lang, { subject: subject[lang], text: text[lang] }, true))
              );

              if (errs.some(Boolean)) {
                const msg = changedKeys.map((lang, i) => errs[i] ? `${lang}: ${errs[i]}` : '').join('\n\n');
                throw new Error(`Validation error: ${msg}`);
              }

              const newTemplates = {
                [k]: Object.fromEntries(langKeys.map(lang => [lang, { subject: subject[lang], text: text[lang] }]))
              };

              await actions.business.update({
                emailTemplates: newTemplates,
                ...enabled !== !emailSettings?.mutes?.[k] && { emailMutes: { [k]: enabled ? null : new Date() } }, // only send if value changed
              });
            }

            modal.open(
              <EmailTemplateForm
                wurdSection="settings.emailTemplates"
                locals={emailSettings.variables[k]}
                {...emailOpts}
                mode="create"
                initialValue={{
                  name: cms.text(`templates.${k}`),
                  templateKey: k,
                  enabled: !emailSettings.mutes?.[k],
                  subject: Object.fromEntries(langKeys.map(lang => [lang, emailTemplate[lang]?.subject || emailTemplate.en?.subject || ''])),
                  text: Object.fromEntries(langKeys.map(lang => [lang, emailTemplate[lang]?.text || emailTemplate.en?.text || ''])),
                }}
                onSubmit={updateEmailTemplate}
                onSuccess={modal.close}
                closeModal={modal.close}
              >
                {({ getProps, formValue, ...editorProps }) => (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                      {noDisable
                        ? <cms.Text id="enabled.always" />
                        : (
                          <>
                            <cms.Text type="label" htmlFor="email-notification-enabled" id="enabled.label" />
                            <input
                              name="enabled"
                              onChange={e => getProps('enabled').onChange({ target: { name: 'enabled', value: e.target.checked } })}
                              checked={formValue?.enabled ?? false}
                              id="email-notification-enabled"
                              className="form-control"
                              type="checkbox"
                              style={{ width: 14, height: 14, display: 'inline-block', margin: '0 0 8px 6px' }}
                            />
                          </>
                        )}
                    </div>
                    <EmailEditor 
                      {...editorProps}
                      formValue={formValue}
                      getProps={getProps}
                      defaultTemplate={emailSettings.defaultTemplates[k]}
                    />
                  </>
                )}
              </EmailTemplateForm>
            );
          }}
        >
          <i className={`far ${emailSettings?.mutes?.[k] ? 'fa-square' : 'fa-check-square'}`} />
          <cms.Text id={`templates.${k}`} style={{ marginLeft: 8 }} />
        </button>
      </li>
    );
  }

  return (
    <>
      <Section cms={wurd.block('settings.emailSending')}>
        <Field {...fieldProps('emailFromName')} required />
        <Field {...fieldProps('emailFromAddress')} type="email" required />
        <Field {...fieldProps('emailInvoiceBCC')}>
          <CommaList />
        </Field>

        <button
          className="btn btn-primary pull-right"
          type="button"
          disabled={loading || !dirty}
          onClick={submit}
        >
          {loading
            ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <WurdText id="common.saveBtn" />
            )}
        </button>
      </Section>

      {helpers.auth.hasRole('manager') && !emailSettings && <center><i className="fa fa-spinner fa-spin fa-2x" /></center>}
      {helpers.auth.hasRole('manager') && emailSettings && (
        <Section cms={wurd.block('settings.emailTemplates')}>
          <EmailGrid>
            <section style={{ borderBottom: '1px solid #eee' }}>
              <h5><cms.Text id="templates.invoicing" /></h5>
              <ul className="list-unstyled">
                {['general_user_invoice', 'general_user_invoiceFailed', 'general_user_receipt'].map(renderEmail)}
              </ul>
            </section>

            <section style={{ borderBottom: '1px solid #eee' }}>
              <h5><cms.Text id="templates.invoiceReminders" /></h5>
              <InvoiceReminders settings={settings} emailSettings={emailSettings} />
            </section>

            <section>
              <h5><cms.Text id="templates.general" /></h5>
              <ul className="list-unstyled">
                {['general_user_welcome', 'general_user_resetPassword', 'general_user_confirmEmail'].map(renderEmail)}
              </ul>
            </section>

            {settings.modes.includes('selfStorage') && (
              <section>
                <h5><cms.Text id="templates.unitMoveIn" /></h5>
                <ul className="list-unstyled">
                  {userEmails.filter(k => k.startsWith('unit')).map(renderEmail)}
                </ul>
              </section>
            )}

            {settings.modes.includes('valetStorage') && (
              <section>
                <h5><cms.Text id="templates.valetOrder" /></h5>
                <ul className="list-unstyled">
                  {userEmails.filter(k => k.startsWith('valetOrder_')).map(renderEmail)}
                </ul>
              </section>
            )}
          </EmailGrid>
        </Section>
      )}

      <Modal bsSize="lg" show={!!modal.content} keyboard onHide={modal.close} backdrop="static">
        {modal.content}
      </Modal>
    </>
  );
}
