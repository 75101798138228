import moment from 'moment-timezone';
import wurd from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';

import { MenuItem } from 'react-bootstrap';
import LangText from 'components/lang-text';
import MenuButton from 'components/menu-button';
import InvoiceEntryForm from './forms/entry';
import PrepayPeriodsForm from './forms/prepay-periods';

const cms = wurd.block('invoiceView.entries.add');

export default function InvoiceEntries_AddButton({
  invoice,
  unitRental,
  site,
  modal,
  onChange,
}) {
  const availableCharges = helpers.settings.get().userCharges;

  function addEntry(data = {}) {
    const initialValue = {
      type: 'revenue',
      date: moment().format('YYYY-MM-DD'),
      qty: 1,
      ...data,
    };

    modal.open(
      <InvoiceEntryForm
        initialValue={initialValue}
        onSubmit={(data) => actions.invoices.entries(invoice.id).create(data)}
        onSuccess={modal.close}
        closeModal={modal.close}
      />,
    );
  }

  function handlePresetCharge(chargeType) {
    addEntry({
      desc: helpers.ui.getLangText(chargeType.title),
      amount: chargeType.amount,
      accountingCodeId: chargeType.accountingCodeId,
    });
  }

  function handleCollectPrepayment() {
    modal.open(
      <PrepayPeriodsForm
        siteId={site.id}
        onSubmit={async data => {
          const job = await actions.jobs.create(unitRental.ownerId, 'unitRental_prepayPeriods', {
            ...data,
            unitRentalId: unitRental.id,
            invoiceId: invoice.id,
          });
  
          modal.close();

          onChange(job);
  
          /* helpers.ui.notify({
            bsType: 'success',
            text: 'Added prepayment items',
          }); */
        }}
        closeModal={modal.close}
      />
    );
  }

  return (
    <MenuButton
      title={null}
      faIcon="far fa-plus"
      cms={cms}
      cmsKeys="charges,addCustom,prepayment,collectPrepay"
    >
      <MenuItem header>{cms.text('charges')}</MenuItem>

      {availableCharges.map(chargeType => (
        <MenuItem
          key={chargeType.id}
          onSelect={() => handlePresetCharge(chargeType)}
        >
          <LangText obj={chargeType.title} />
        </MenuItem>
      ))}

      <MenuItem onSelect={addEntry}>{cms.text('addCustom')}</MenuItem>

      <MenuItem divider />

      <MenuItem header>{cms.text('prepayment')}</MenuItem>
      <MenuItem onSelect={handleCollectPrepayment}>{cms.text('collectPrepay')}</MenuItem>
    </MenuButton>
  );
}
