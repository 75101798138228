import ModalForm from '../../components/form2/modal-form';
import Field from '../../components/form2/horizontal-field';
import MultiLangText from '../../components/form2/multi-lang-text';

import { getLuminance } from '../../helpers/ui';
import ColorPicker, { labelColors } from './color-picker';
import { WurdText } from 'wurd-react';
import LangText from 'components/lang-text';

// HOC
export function getLabelForm(propName) {
  return function LabelForm({ initialValue, mode, ...props }) {
    return (
      <ModalForm
        wurdSection={`business.${propName}`}
        initialValue={{ color: labelColors[Math.floor(Math.random() * labelColors.length)], ...initialValue }}
        mode={mode}
        {...props}
        submitButton={<WurdText id={`common.modalForm.${mode}`} />}
      >
        {getProps => (
          <div>
            <Field {...getProps('title')} required autoFocus>
              <MultiLangText />
            </Field>

            <Field {...getProps('color')} required>
              <ColorPicker />
            </Field>
          </div>
        )}
      </ModalForm>
    );
  }
}

export function LabelItem({ item }) {
  return (
    <span style={{
      backgroundColor: item.color,
      color: getLuminance(item.color) > 0.5 ? '#000b' : '#fffe',
      borderRadius: 2,
      fontSize: '1.2em',
      padding: '2px 8px',
      fontWeight: 600,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }}>
      <LangText obj={item.title} />
    </span>
  );
}
