import { CurrencyInput } from 'components/form2';
import Field from '../field';

export default function SiteOnboardingUnitPricingForm({ getProps }) {
  return (
    <>
      <Field {...getProps('deposit')} type="number" step="any" required>
        <CurrencyInput min="0" required autoFocus />
      </Field>

      <Field {...getProps('price')} type="number" step="any" required>
        <CurrencyInput min="0" required />
      </Field>
    </>
  )
}
