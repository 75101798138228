import { isValidElement, Component } from 'react';
import PropTypes from 'prop-types';
import wurd, { WurdText } from 'wurd-react';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled';

import * as helpers from '../helpers';

import NotAuthorized from './not-authorized';
import Spinner from './spinner';

const { hasRole } = helpers.auth;

const Container = styled.div({
  marginBottom: 110, // Leave space for chat widget

  // Class for semi-opacity to indicate archived/deleted units/items or completed jobs etc.
  '.faded': {
    opacity: 0.75,
  },

  '@media (max-width: 768px)': {
    marginBottom: 190
  }

  /* '@media (max-width: 768px)': {
    '.container-fluid': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }, */
});

export default class Page extends Component {
  static propTypes = {
    title: PropTypes.node,
    documentTitle: PropTypes.string,  //Specific override for the HTML document title
    titleKey: PropTypes.string,       //Wurd key eg 'VisitList.title'
    titleVars: PropTypes.object,      //Optional vars to pass to wurd title
    userRole: PropTypes.oneOfType([   //Required role for vieing the page, otherwise content is not shown
      PropTypes.string,
      PropTypes.array
    ]),
    extra: PropTypes.node,      //Right hand content e.g. dropdowns, action buttons etc
    modal: PropTypes.object,      // Modal content @deprecated, or PropTypes.shape({content: PropTypes.string, close: PropTypes.func, open: PropTypes.func}) [new way]
    closeModal: PropTypes.func,   // @deprecated, use modal.close
  };

  componentDidMount() {
    document.title = this.getDocumentTitle();
  }

  componentDidUpdate(prevProps) {
    const newTitle = this.getDocumentTitle();

    if (document.title !== newTitle) {
      document.title = newTitle;
    }
  }

  componentWillUnmount() {
    document.title = 'Storeganise';
  }

  hasAccess() {
    const { userRole } = this.props;

    // If no role specified then available to all
    if (!userRole) return true;

    // If multiple roles are ok check each
    if (Array.isArray(userRole)) {
      return userRole.find((role) => hasRole(role));
    }

    // Otherwise do check for a single role
    return hasRole(userRole);
  }

  getDocumentTitle() {
    const { documentTitle, title, titleKey, titleVars } = this.props;

    const newTitle = documentTitle || title || wurd.text(titleKey, titleVars);

    return typeof newTitle === 'string' ? `${newTitle} | Storeganise` : 'Storeganise';
  }

  renderMain() {
    const { title: _title, titleKey, titleVars, subtitle, children, extra, modal: _modal, closeModal, innerClassName = '', className, style } = this.props;

    // compat with the @deprecated way to pass modal+closeModal
    const modal = isValidElement(_modal) || typeof _modal === 'string' ? { content: _modal, close: closeModal } : _modal;

    const title = titleKey ? <WurdText id={titleKey} vars={titleVars} /> : _title;

    return (
      <Container className={className} style={style}>
        {title && (
          <div className="page-head page-head-small">
            {extra && (
              <div className="pull-right">
                {extra}
              </div>
            )}

            <h3 style={{ display: 'flex', alignItems: 'center' }}>
              {title}
              {subtitle && <small>&nbsp;&nbsp;&nbsp; {subtitle}</small>}
            </h3>
          </div>
        )}

        <div className={`cl-mcont ${innerClassName}`}>
          {children}
        </div>

        {modal && (
          <Modal show={!!modal.content} onHide={modal.close} {...modal.options}>
            {modal.content}
          </Modal>
        )}
      </Container>
    );
  }

  render() {
    const { children } = this.props;

    if (!this.hasAccess()) return <NotAuthorized />;

    return children ? this.renderMain() : <Spinner />;
  }
}
