import wurd, { WurdText } from 'wurd-react';

import * as actions from '../../actions';
import * as helpers from '../../helpers';
import { currentLang } from 'utils/language';

import Section from 'components/section';
import SectionCrudList from 'components/section_crud-list';
import DetailList from 'components/detail-list';
import LangText from 'components/lang-text';
import MarkdownEditor from 'components/markdown-editor';
import DetailsForm from './forms/details';
import CoordinatesForm from './forms/coordinates';
import UnitTypeGroupForm from 'components/form2/site/unit-type-group-form';


const Label = ({ id }) => (
  <WurdText type="dt" id={`site.${id}.label`} />
);


export default function SiteDetails({
  cms,
  modal,
  site,
  fetchSite,
}) {
  function editDetails() {
    modal.open(
      <DetailsForm
        site={site}
        onSubmit={formData => actions.sites.update(site.id, formData)}
        onSuccess={modal.close}
        closeModal={modal.close}
        mode="update"
      />,
    );
  }

  function editMap() {
    modal.open(
      <CoordinatesForm
        site={site}
        onSubmit={formData => actions.sites.update(site.id, formData)}
        onSuccess={modal.close}
        closeModal={modal.close}
        mode="update"
      />,
    );
  }


  const siteText = helpers.site.doTranslate(site, currentLang);

  return (
    <>
      <Section
        cms={cms.block('details')}
        onClickEdit={editDetails}
      >
        <DetailList>
          <Label id="title" />
          <dd>{siteText.title}</dd>

          <Label id="subtitle" />
          <dd>{siteText.subtitle}</dd>

          <Label id="address" />
          <dd>{siteText.address}</dd>

          <Label id="phone" />
          <dd>{siteText.phone}</dd>

          <Label id="email" />
          <dd>{siteText.email}</dd>
        </DetailList>
      </Section>

      <MarkdownEditor
        cms={wurd.block('siteView.info')}
        content={site.info}
        onChange={(info) => actions.sites.update(site.id, { info })}
      />

      <MarkdownEditor
        cms={wurd.block('siteView.hours')}
        content={site.hours}
        onChange={(hours) => actions.sites.update(site.id, { hours })}
      />

      <Section
        cms={cms.block('map')}
        onClickEdit={editMap}
      >
        {(site.lat && site.lng) && (
          <iframe
            title="Map"
            src={helpers.ui.getGoogleMapsUrlForLatLng(site.lat, site.lng)}
            width="100%"
            height="300"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
          />
        )}
      </Section>

      <SectionCrudList
        cms={cms.block('unitTypeGroups')}
        items={site.unitTypeGroups}
        Form={UnitTypeGroupForm}
        actions={actions.sites.unitTypeGroups(site.id)}
        onChange={fetchSite}
      >
        {unitTypeGroup => (
          <>
            <div><LangText obj={unitTypeGroup.title} /></div>
          </>
        )}
      </SectionCrudList>
    </>
  );
}
