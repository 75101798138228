import wurd from 'wurd-react';

import { useListLoader } from '../../hooks';
import * as helpers from '../../helpers';
import TableList from '../table-list';
import Filters from './filters';
import Item from './item';
import Menu from './menu';

const cms = wurd.block('userList');

function viewUser(user) {
  return `/users/${user.id}`;
}

export default function UserList({
  listQuery,
  modal,
  getItemLink,
  onClickAdd,
}) {
  const listLoader = useListLoader('users', listQuery.params, { maxAge: 0 });

  const markets = helpers.market.list(true);

  const hasMultipleMarkets = markets.length > 1;
  const marketTitles = helpers.market.titlesById();
  const planTitles = helpers.plan.titlesById();

  const renderItem = (user) => (
    <Item
      user={user}
      hasMultipleMarkets={hasMultipleMarkets}
      marketTitles={marketTitles}
      planTitles={planTitles}
    />
  );


  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={(
        <Filters
          listQuery={listQuery}
          markets={markets}
        />
      )}
      menu={(
        <Menu
          modal={modal}
          cms={cms}
          listQuery={listQuery}
          refetch={listLoader.refetch}
        />
      )}
      renderItem={renderItem}
      getItemLink={getItemLink || viewUser}
      onClickAdd={onClickAdd}
    />
  );
}
