import { pickBy } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { paramsToSearch } from '../utils/api';

const defaultParams = {
  limit: 50,
  offset: 0,
};


export default function useListQuery({ initialParams = {}, implicitParams = {} } = {}) {
  const navigate = useNavigate();
  const location = useLocation();

  function getAllParams() {
    const currentParams = Object.fromEntries(new URLSearchParams(location.search));

    return {
      // Set defaults
      ...defaultParams,
      ...initialParams,

      // Allow override with the querystring
      ...currentParams,

      // Always override with implicit (invisible) params
      ...implicitParams,
    };
  }

  /**
   * Filters out implicit query parameters, returning only that that won't be added
   * to the querystring
   *
   * @param {Object} newParams
   *
   * @return {Object}
   */
  function getVisibleParams(params) {
    return Object.entries(params).reduce((memo, [key, val]) => {
      // don't show keys that are implicit
      if (implicitParams[key] === undefined) {
        return {
          ...memo,
          [key]: val || '',
        };
      }

      return memo;
    }, {});
  }

  /**
   * Resets all query params
   *
   * @param {Object} params
   */
  function setQuery(params) {
    const visibleParams = getVisibleParams(params);

    navigate({
      pathname: location.pathname,
      search: `?${paramsToSearch(visibleParams)}`,
    });
  }

  /**
   * Updates the given query params, keeping other existing params
   *
   * @param {Object} params
   */
  function updateQuery(params, opts) {
    const allParams = pickBy({
      ...getAllParams(),
      ...params,
    }, (v) => v !== undefined);

    const visibleParams = getVisibleParams(allParams);

    navigate({
      pathname: location.pathname,
      search: `?${paramsToSearch(visibleParams)}`
    }, opts);
  }

  return {
    params: getAllParams(),
    set: setQuery,
    update: updateQuery,
  };
}
