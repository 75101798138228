import { Button } from 'react-bootstrap';

import add from '../Images/add.png';
import remove from '../Images/remove.png';
import { SCALE_BY } from '../Constants';

const zoomIconStyle = { width: 10, height: 10, marginTop: -2 };


export default function SitemapZoomControls({ style, handleZoom }) {

  return (
    <div className="btn-group-vertical" style={style}>
      <Button onClick={() => handleZoom(SCALE_BY)}>
        <img alt="Zoom in" src={add} style={zoomIconStyle} />
      </Button>
      <Button onClick={() => handleZoom(-SCALE_BY)}>
        <img alt="Zoom out" src={remove} style={zoomIconStyle} />
      </Button>
    </div>
  );

}
