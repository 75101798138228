import wurd, { WurdText } from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';

import Section from 'components/section';
import DetailList from 'components/detail-list';
import MarkdownEditor from 'components/markdown-editor';
import LangText from 'components/lang-text';
import StateLabel from 'components/state-label';
import CurrentOccupancyChart from 'components/site-detail/charts/current-occupancy';
import CustomFieldsSection from 'plugins/custom-fields/section';
import Features from '../features';
import OccupancyPrices from '../occupancy-prices';
import DetailsForm from './form';


const Label = ({ id }) => (
  <WurdText type="dt" id={`unitType.${id}.label`} />
);


export default function SiteUnitTypeDetail({
  cms,
  site,
  unitType,
  modal,
  onChange,
}) {
  function editDetails() {
    modal.open(
      <DetailsForm
        site={site}
        unitType={unitType}
        siteId={unitType.siteId}
        onSubmit={data => actions.update('unit-types', unitType.id, data)}
        onSuccess={onChange}
        closeModal={modal.close}
        mode="update"
      />,
    );
  }

  return (
    <>
      {Object.values(unitType.occupancy || {}).reduce((a, b) => a + b, 0) > 0 && (
        <Section cms={cms.block('overview')}>
          <div className="text-center">
            <CurrentOccupancyChart
              site={unitType}
              getStateLink={state => `/unit-types/${unitType.id}/units?state=${state}`}
            />
          </div>
        </Section>
      )}

      <Section
        cms={cms.block('details')}
        onClickEdit={editDetails}
      >
        {site.unitTypeGroups.length > 0 && (
          <DetailList>
            <Label id="groupId" />
            <dd>
              {helpers.ui.getLangText(
                site.unitTypeGroups.find(utg => utg.id === unitType.groupId)?.title
              ) || wurd.text('common.phrases.none')}
            </dd>
          </DetailList>
        )}

        <DetailList>
          <Label id="title" />
          <dd><LangText obj={unitType.title} /></dd>

          <Label id="subtitle" />
          <dd><LangText obj={unitType.subtitle} /></dd>
        </DetailList>

        <DetailList>
          <Label id="deposit" />
          <dd>{helpers.ui.priceChange(unitType.deposit_display || unitType.deposit, unitType.deposit)}</dd>

          <Label id="price" />
          <dd>
            {helpers.ui.priceChange(helpers.unitType.currentPrice(unitType), unitType.price, helpers.ui.rentPeriod())}
            {helpers.unitType.currentPrice(unitType) != unitType.price && unitType.price_dynamic != null && (
              <StateLabel type="info" style={{ marginLeft: '.5rem' }}><cms.Text id="dynamicPriceApplied" /></StateLabel>
            )}
          </dd>
        </DetailList>

        <DetailList>
          <Label id="code" />
          <dd>{unitType.code}</dd>

          <Label id="hidden" />
          <dd>
            {unitType.hidden 
              ? <StateLabel type="warning"><WurdText id="unitType.hidden.true" /></StateLabel>
              : <StateLabel type="success"><WurdText id="unitType.hidden.false" /></StateLabel>}
          </dd>
        </DetailList>
      </Section>

      <CustomFieldsSection
        cms={cms.block('customFields')}
        modelName="unitType"
        model={unitType}
        modal={modal}
      />

      <OccupancyPrices
        modal={modal}
        cms={cms.block('occupancyPrices')}
        unitType={unitType}
        onChange={onChange}
      />

      <MarkdownEditor
        cms={cms.block('info')}
        content={unitType.info}
        onChange={async (info) => {
          await actions.update('unit-types', unitType.id, { info });
          onChange();
        }}
      />

      <Features
        modal={modal}
        cms={cms.block('features')}
        unitType={unitType}
        onChange={onChange}
      />
    </>
  );
}
