import schema2form from '../schema2form';


const wurdSection = 'visit.charge';

const schema = [
  {
    name: 'note',
    required: true
  },
  {
    name: 'amount',
    type: 'number',
    required: true
  }
];


export default schema2form(schema, { wurdSection });
