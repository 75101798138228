import createClass from 'create-react-class';

import * as helpers from '../../../helpers';
import schema2form from '../schema2form';

import Input from '../horizontal-input';
import UserSelect from '../user-select';

const { t } = helpers.cms.getters('item');


const wurdSection = 'item';

const schema = [
  {
    name: 'type',
    type: 'select',
    options: function () {
      return helpers.item.listBulky(true).map(item => ({
        value: item.type,
        text: item.title
      }));
    },
    required: true
  },
  {
    name: 'state',
    type: 'select',
    options: (props, formProps, data) => {
      if (formProps.stateOptions) return formProps.stateOptions;

      let options = [
        { value: 'storage', text: t('_states.storage') },
        { value: 'user', text: t('_states.user') }
      ];

      if (!helpers.item.isBulky(data.type)) {
        options.push({ value: 'unassigned', text: t('_states.unassigned') });
      }

      return options;
    },
    required: true
  },
  {
    name: 'ownerId',
    custom: (props, formProps, data) => {
      if (data.state === 'unassigned') return null;

      return <UserSelect {...props} />
    }
  },
  {
    name: 'location',
    custom: (props, formProps, data) => {
      if (data.state === 'user') return null;

      return <Input {...props} />
    }
  },
  {
    name: 'opsTitle',
    custom: (props, formProps, data) => {
      if (data.state === 'unassigned') return null;

      return <Input {...props} />
    }
  }
];

const ItemSchemaForm = schema2form(schema, { wurdSection });

const ItemForm = createClass({

  render() {
    return <ItemSchemaForm {...this.props} preChange={this.preChange} />
  },

  preChange(data) {
    //Remove owner when unassigning an item
    if (data.state === 'unassigned') {
      data.ownerId = null;
    }

    return data;
  }

});

export default ItemForm;
