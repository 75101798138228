import wurd from 'wurd-react';
import * as helpers from '../../helpers';
import LabelsListCell from 'plugins/labels/list';
import UserLoader from '../loaders/user';

const { date: formatDate, sid } = helpers.ui;
const { formatTimeslot } = helpers.valetOrder;

const cms = wurd.block('valetOrderList.item');


function renderTimeColumn(valetOrder) {
  const appointments = helpers.valetOrder.getAppointments(valetOrder);

  return (
    <div role="cell">
      {appointments.map((appointment, index) => (
        <div
          key={index}
          title={appointment.title}
          style={{ color: appointment.isNext ? null : '#bbb' }}
        >
          {(appointment.text
            ? <small>{appointment.text}</small>
            : <span>{formatDate(appointment.date)} <small>{formatTimeslot(appointment.timeslot)}</small></span>
          )}
        </div>
      ))}
    </div>
  );
}


export default function ValetOrderListItem({
  valetOrder,
  hideOwner = false,
  hideArea = false,
}) {
  const orderState = valetOrder.state;

  const emptyBoxCount = helpers.valetOrder.countEmpties(valetOrder);
  const deliverCount = valetOrder.deliverItems?.length ?? 0;
  const collectCount = valetOrder.collectItems?.length ?? 0;

  return (
    <>
      <div role="cell">
        <div><strong>{sid(valetOrder)}</strong></div>
        <div>
          {!hideOwner && (
            <UserLoader id={valetOrder.ownerId} loading={valetOrder.ownerName}>
              {user => user.name}
            </UserLoader>
          )}
        </div>
      </div>

      {renderTimeColumn(valetOrder)}

      {!hideArea && (
        <div role="cell">
          <div>{helpers.area.getTitle(valetOrder.area)}</div>

          {valetOrder.driverId && (
            <div>{valetOrder.driverName}</div>
          )}
        </div>
      )}

      <div role="cell" className="hidden-xs hidden-sm">
        {!!emptyBoxCount && (
          <div>{cms.text('emptyBoxes')}: {emptyBoxCount}</div>
        )}
        {!!deliverCount && (
          <div>{cms.text('deliver')}: {deliverCount}</div>
        )}
        {!!collectCount && (
          <div>{cms.text('collect')}: {collectCount}</div>
        )}
      </div>

      <div role="cell" width="40">
        {orderState === 'submitted' && (
          <i
            className="fas fa-question"
            style={{ color: '#f0ad4e' }}
            title="Submitted"
          />
        )}
        {orderState === 'started' && (
          <i
            className="fas fa-tasks"
            style={{ color: '#999' }}
            title="In progress"
          />
        )}
        {orderState === 'failed' && (
          <i
            className="fas fa-exclamation-circle"
            style={{ color: '#d9534f' }}
            title="Failed"
          />
        )}
        {orderState === 'cancelled' && (
          <i
            className="fas fa-times"
            style={{ color: '#d9534f' }}
            title="Cancelled"
          />
        )}
        {orderState === 'failedDelivery' && (
          <i
            className="fas fa-exclamation-circle"
            style={{ color: '#d9534f' }}
            title="Failed delivery"
          />
        )}
        {orderState === 'failedCollection' && (
          <i
            className="fas fa-exclamation-circle"
            style={{ color: '#d9534f' }}
            title="Failed collection"
          />
        )}
        {orderState === 'completed' && (
          <i
            className="far fa-check-circle"
            style={{ color: '#60C060' }}
            title="Completed"
          />
        )}
      </div>

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={valetOrder.labels} />
      </div>
    </>
  );
}
