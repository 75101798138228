import wurd, { WurdText } from 'wurd-react';
import { useParams } from 'react-router-dom';

import * as helpers from '../../helpers';
import { getLangText } from 'helpers/ui';
import * as actions from '../../actions';
import { useListLoader } from '../../hooks';

import StateLabel from 'components/state-label';
import DetailPage from '../detail-page';
import NotFound from '../not-found';
import SidebarSummary from './sidebar-summary';
import Overview from './overview';
import Details from './details';
import UnitTypes from './unit-types';
import UnitProducts from './products';
import UnitJobs from './unit-jobs';
import Units from './units';
import Invoices from './invoices';
import History from './history';
import Menu from './menu';
import AdvancedSettings from './advanced-settings';
import Addons from './addons';
import LabelsList from 'plugins/labels/detail';
import Alert from 'components/alert';

const cms = wurd.block('siteView');

const sectionComponents = {
  'overview': Overview,
  'details': Details,
  'unit-types': UnitTypes,
  'products': UnitProducts,
  'jobs': UnitJobs,
  'units': Units,
  'invoices': Invoices,
  'history': History,
  'advanced-settings': AdvancedSettings,
  'addons': Addons,
};

export default function SiteDetailInnerPage({
  site,
  fetchSite,
  modal,
}) {
  const { section = 'overview', sectionId } = useParams();
  const occupancy = helpers.site.getOccupancy(site);

  const { items: jobs } = useListLoader('jobs', {
    limit: 50,
    siteId: site.id,
    type: 'unit_moveIn,unit_moveOut,unit_transfer',
    state: 'active',
  });

  const jobsBadge = jobs?.length >= 50 ? '50+' : jobs.length;

  const sections = [
    { name: 'overview', icon: 'far fa-chart-line' },
    { name: 'details', icon: 'far fa-info-circle' },
    { name: 'jobs', icon: 'far fa-tasks', badge: jobsBadge },
    { name: 'units', icon: 'far fa-building', badge: occupancy.total },
    { name: 'invoices', icon: 'far fa-file-invoice-dollar' },
    { name: 'sitemap', icon: 'far fa-layer-group' },
    { name: 'unit-types', icon: 'far fa-list' },
    { name: 'products', icon: 'far fa-boxes' },
    ...helpers.auth.hasRole('manager') ? [
      { name: 'advanced-settings', icon: 'far fa-cog' },
      { name: 'addons', icon: 'far fa-cubes' },
    ] : [],
    { name: 'history', icon: 'far fa-history' },
  ];

  const CurrentSection = sectionComponents[section];
  if (!CurrentSection) return <NotFound />;

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="admin"
      documentTitle={getLangText(site.title)}
      title={getLangText(site.title)}
      subtitle={site.hidden && <StateLabel type="warning" as="small"><WurdText id="site.hidden.true" /></StateLabel>}
      ancestors={[
        { text: wurd.text('siteList.title'), url: '/sites' },
      ]}
      sections={sections}
      currentSection={section}
      baseUrl={`/sites/${site.code}`}
      extra={(
        <>
          <LabelsList
            labels={site.labels}
            settingsLabels={helpers.settings.get().siteLabels}
            onChange={labels => actions.sites.labels.update(site.id, labels)}
          />

          <Menu
            site={site}
            modal={modal}
            section={section}
            sectionId={sectionId}
            onChange={fetchSite}
          />
        </>
      )}
      sidebar={(
        <SidebarSummary
          site={site}
          modal={modal}
        />
      )}
    >
      {site.occupancy.overdue > 0 && (
        <Alert type='danger'>
          <cms.Markdown
            id="alert.siteHasOverdue"
            vars={{
              overdueCount: site.occupancy.overdue,
              siteCode: site.code,
            }}
          />
        </Alert>

      )}

      <CurrentSection
        site={site}
        fetchSite={fetchSite}
        cms={cms}
        modal={modal}
      />
    </DetailPage>
  );
}
