import * as actions from '../../../actions';

import LinkButtonGroup, { LinkButton } from '../../link-button-group';
import AddBulkyForm from './add-bulky-form';


export default function AddItemButton({
  valetOrder,
  modal,
  fetchItems,
  newItemState = 'transit-storage',
}) {
  function addBulkyItem() {
    modal.open(
      <AddBulkyForm
        onSubmit={(formData) => {
          const data = {
            ...formData,
            state: newItemState,
            valetOrderId: valetOrder.id,
          };

          return actions.items.createForUser(valetOrder.ownerId, data);
        }}
        onSuccess={() => {
          fetchItems();
          modal.close();
        }}
        closeModal={modal.close}
      />,
    );
  }


  return (
    <LinkButtonGroup align="right" style={{ marginTop: 8 }}>
      <LinkButton
        faIcon="far fa-plus"
        text="Add bulky item"
        onClick={addBulkyItem}
      />
    </LinkButtonGroup>
  );
}