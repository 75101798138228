import { useQuery } from 'react-query';
import { pick } from 'lodash';
import { Button } from 'react-bootstrap';
import wurd, { WurdMarkdown, WurdText } from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';

import Section from 'components/section';
import Alert from 'components/alert';
import UploadButton from 'components/uploader';
import { Field } from 'components/form2';
import RadioList from 'components/form2/radiolist';
import HtmlEditor from 'components/form2/esign/html';
import PdfEditor from 'components/form2/esign/pdf';
import { useApiForm } from 'hooks';


export default function SiteEsignSettings({ site, fetchSite, modal, ...rest }) {
  const { data: esignSettings, refetch } = useQuery('esign-' + site.id, () => actions.business.esign.fetch({ siteId: site.id }));

  const { fieldProps, submit, loading, dirty, formValue, setFormValue } = useApiForm({
    wurdSection: 'site',
    initialValue: {
      ...esignSettings?.site,
      enableMoveInAgreement: esignSettings?.site.enableMoveInAgreement === true ? 'html' : !esignSettings?.site.enableMoveInAgreement ? '' : esignSettings?.site.enableMoveInAgreement
    },
    onSubmit: formData => actions.sites.update(site.id, formData),
    onSuccess: () => fetchSite().then(refetch),
    onError: window.alert,
    ...rest
  });

  function customizeHtml() {
    setFormValue({
      moveInAgreementTemplate: Object.fromEntries( // deep merge multilang objects
        helpers.settings.langs().map(lang => [
          lang,
          esignSettings?.site.moveInAgreementTemplate?.[lang] ||
            esignSettings.moveInAgreementTemplate?.[lang] ||
            esignSettings.defaultMoveInAgreementTemplate.en
        ])
      )
    });
  }

  function clearHtml() {
    setFormValue({
      moveInAgreementTemplate: null
    });
  }

  return (
    <Section cms={wurd.block('siteView.esign')}>
      <RadioList value={formValue.enableMoveInAgreement} onChange={e => setFormValue({ enableMoveInAgreement: e.target.value })}>
        {['', 'html', 'pdf'].map(v => ({
          value: v,
          title: <WurdText id={`siteView.esign.${v || 'none'}.title`} />,
          description: (
            <WurdMarkdown
              id={`siteView.esign.${v || 'none'}.desc`}
              type="div"
              className="text-muted"
              onClick={e => {
                console.log(e.target);
                if (e.target.matches('[href="#add-html"]')) {
                  customizeHtml();
                }
                if (e.target.matches('[href="#add-pdf"]')) {
                  document.getElementById('add-pdf').click();
                }
              }}
            />
          )
        }))}
      </RadioList>

      {formValue.enableMoveInAgreement === 'html' && (
        Object.values(formValue.moveInAgreementTemplate || {}).filter(Boolean).length
          ? <HtmlEditor value={formValue} onChange={setFormValue} reset={clearHtml} resetText={<WurdText id="site.clearMoveInAgreementTemplate" />} />
          : (
            <Alert
              actions={(
                <Button onClick={customizeHtml}>
                  <WurdText id="site.customizeMoveInAgreementTemplate" />
                </Button>
              )}
            >
              <WurdMarkdown id="site.defaultMoveInAgreementTemplate" />
            </Alert>
          )
      )}
      {formValue.enableMoveInAgreement === 'pdf' && (
        Object.values(formValue.moveInAgreementPdf || {}).filter(Boolean).length
          ? <PdfEditor value={formValue} onChange={setFormValue} initialValue={esignSettings?.site} modal={modal} />
          : (
            <Alert
              actions={(
                <UploadButton
                  id="add-pdf"
                  text={<WurdText id="site.esign.addPdf" />}
                  icon={null} accept="application/pdf"
                  onChange={e => setFormValue({ moveInAgreementPdf: { ...formValue.moveInAgreementPdf, [helpers.settings.langs()[0]]: e.target.value } })}
                />
              )}
            >
              <WurdMarkdown id="site.esign.defaultPdf" />
            </Alert>
          )
      )}

      <button
        className="btn btn-primary pull-right"
        type="button"
        disabled={loading || !dirty}
        onClick={submit}
      >
        {loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>
    </Section>
  );
}
