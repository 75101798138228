import { ModalForm, Field, MultiLangText, CurrencyInput } from 'components/form2';
import AccountingCodeSelect from 'components/form2/accounting-code-select';



export default function UserChargeForm(props) {
  return (
    <ModalForm
      wurdSection="business.userCharge"
      {...props}
      initialValue={{ ...props.initialValue, type: props.initialValue?.type || 'recurringCharge' }}
    >
      {getProps => (
        <>
          <Field {...getProps('title')} required autoFocus>
            <MultiLangText />
          </Field>

          <Field {...getProps('amount')} required type="number">
            <CurrencyInput min={null} required />
          </Field>

          <Field {...getProps('accountingCodeId')}>
            <AccountingCodeSelect />
          </Field>

          <Field {...getProps('code')} required />
        </>
      )}
    </ModalForm>
  );
}
