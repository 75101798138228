import wurd from 'wurd-react';
import _ from 'lodash';

import * as helpers from 'helpers';

import ModalForm from '../modal-form';
import Field from '../horizontal-field';

import { apiPermissions } from 'components/settings/developer/api-key-form';


export const adminPermissions = [
  'invoice:markDraft'
];


const cms = wurd.block('user')

const { hasRole } = helpers.auth;

export default function UserForm({ mode = 'create', onSubmit, children, ...rest }) {
  return (
    <ModalForm
      wurdSection={cms.id()}
      mode={mode}
      onSubmit={(fullData, data) => {
        if (data.planId === '') data.planId = null;
        return onSubmit(data);
      }}
      {...rest}
    >
      {(getProps, data) => {
        const langs = helpers.settings.langs();

        return (
          <div>
            <Field {...getProps('firstName')} required />

            <Field {...getProps('lastName')} required />

            <Field {...getProps('email')} type="email" required />

            <Field {...getProps('language')}>
              <select>
                {langs.length === 1 && <option />}
                {langs.map(lang =>
                  <option key={lang} value={lang}>{wurd.text(`common.languages.${lang}`)}</option>
                )}
              </select>
            </Field>

            <Field {...getProps('address')}>
              <textarea></textarea>
            </Field>

            <Field {...getProps('marketId')}>
              <select>
                {[
                  { value: '', text: 'Select a market...' },
                  ...helpers.market.list(true).map(market => (
                    { value: market.id, text: market.title }
                  ))
                ].map(({ value, text }) =>
                  <option key={value} value={value}>{text}</option>
                )}
              </select>
            </Field>

            <Field {...getProps('area')}>
              <select>
                {[
                  { value: '', text: 'Select an area...' },
                  ..._.map(helpers.area.titlesById(), (title, id) => (
                    { value: id, text: title })
                  )
                ].map(({ value, text }) =>
                  <option key={value} value={value}>{text}</option>
                )}
              </select>
            </Field>

            <Field {...getProps('phone')} />

            <Field {...getProps('planId')}>
              <select>
                {[
                  { value: '', text: 'None' },
                  ...helpers.plan.list(true).map(plan => (
                    { value: plan.id, text: `${plan.title}: ${helpers.ui.currency(plan.price)}` }
                  ))
                ].map(({ value, text }) =>
                  <option key={value} value={value}>{text}</option>
                )}
              </select>
            </Field>


            <Field {...getProps('invoiceDate')} type="number" min="1" max="31" />
            <Field {...getProps('billingMethod')}>
              <select>
                {[
                  { value: 'default', text: cms.text('billingMethod.paymentGateway') },
                  { value: 'invoice', text: cms.text('billingMethod.paymentInvoice') }
                ].map(({ value, text }) =>
                  <option key={value} value={value}>{text}</option>
                )}
              </select>
            </Field>
            <Field {...getProps('billingTrigger')}>
              <select>
                {[
                  { value: 'default', text: cms.text('billingTrigger.default') },
                  { value: 'auto', text: cms.text('billingTrigger.automatic') },
                  { value: 'manual', text: cms.text('billingTrigger.manual') }
                ].map(({ value, text }) =>
                  <option key={value} value={value}>{text}</option>
                )}
              </select>
            </Field>

            <Field {...getProps('personalId')} />
            <Field {...getProps('phone2')} />
            <Field {...getProps('companyName')} />
            <Field {...getProps('companyId')} />
            <Field {...getProps('externalId')} />

            <Field {...getProps('altContactName')} />
            <Field {...getProps('altContactAddress')}>
              <textarea></textarea>
            </Field>
            <Field {...getProps('altContactPhone')} />
            <Field {...getProps('altContactEmail')} type="email" />

            {hasRole('manager') && (
              <Field {...getProps('roles')}>
                <select multiple>
                  {['driver', 'operations', 'admin', 'manager'].map(role =>
                    <option key={role} value={role}>{cms.text(`_roles.${role}`, { permissions: '' })}</option>
                  )}
                </select>
              </Field>
            )}

            {hasRole('manager') && data.roles?.includes('api') && (
              <Field {...getProps('permissions')}>
                <select multiple>
                  {apiPermissions.map(permission =>
                    <option key={permission} value={permission}>{permission}</option>
                  )}
                </select>
              </Field>
            )}

            {hasRole('manager') && helpers.auth.hasRole('admin', data) && (
              <Field {...getProps('permissions')}>
                <select multiple>
                  {adminPermissions.map(permission =>
                    <option key={permission} value={permission}>{permission}</option>
                  )}
                </select>
              </Field>
            )}

            {typeof children === 'function' && children(getProps)}
          </div>
        );
      }}
    </ModalForm>
  );
}
