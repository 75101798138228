import { useEffect } from 'react';

/**
 * @param  {string} comboKey   e.g. 'x' or 'alt+x'
 * @param  {function} callbackFn
 */
export default function useShortcut(comboKey, callbackFn) {
  useEffect(() => {
    if (!comboKey) return;

    const [key, specialKey] = comboKey.split('+').reverse();

    const handleShortcut = event => {
      if (event.key === key && (!specialKey || event[specialKey + 'Key'] || specialKey === 'alt' && event.metaKey)) {
        event.preventDefault();
        callbackFn(event);
      }
    };

    document.addEventListener('keydown', handleShortcut);

    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, [comboKey]);
}
