export default function SelectionBar({ count, onCancel, onAssign, assignClassName = '', isEmpty, cms }) {
  return (
    <div className="selection-bar navbar navbar-default navbar-fixed-top">
      <cms.Object keys="count,assign">
        <div className="container-fluid">
          <div className="pull-left">
            <button type="button" className="btn btn-success btn-flat" onClick={onCancel} style={{ border: 0 }}>
              <h4 className="text single-line">
                <i className="fa fa-arrow-left"></i> {cms.text('count', { count })}
              </h4>
            </button>
          </div>

          <div className="btn-group pull-right" title={isEmpty ? cms.text('no-selected-items') : undefined}>
            <button type="button" disabled={isEmpty} className="btn btn-success btn-flat" onClick={onAssign}>
              <i className={`fa ${assignClassName}`}></i> {cms.text('assign')}
            </button>
          </div>
        </div>
      </cms.Object>
    </div>
  );
}
