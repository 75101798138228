import wurd from 'wurd-react';

import { useListLoader } from '../../hooks';
import * as helpers from '../../helpers';

import TableList from '../table-list';
import Filters from './filters';
import Item from './item';
import Menu from './menu';

const cms = wurd.block('unitList');

function viewUnit(unit) {
  return unit.rentalId ? `/unit-rentals/${unit.rentalId}` : `/units/${unit.id}`;
}


export default function UnitList({
  listQuery,
  modal,
  site,
  fetchSite,
  hideSite = false,
  hideOwner = false,
  hideState = false,
  hidePrice = false,
  hideFilters = false,
  getItemLink = viewUnit,
  onClickItem,
  onClickAdd,
}) {
  const listLoader = useListLoader('units', listQuery.params, { maxAge: 0 });

  const stateTitles = helpers.unit.getStateTitles();

  const renderItem = (unit) => (
    <Item
      unit={unit}
      stateTitles={stateTitles}
      hideSite={hideSite}
      hideOwner={hideOwner}
      hideState={hideState}
      hidePrice={hidePrice}
    />
  );

  const getTableRowClassName = ({ state }) => {
    if (state === 'archived') return 'fade';
  };

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={!hideFilters && (
        <Filters
          listQuery={listQuery}
          hideSite={hideSite}
          hideOwner={hideOwner}
          hideState={hideState}
          hidePrice={hidePrice}
        />
      )}
      menu={(
        <Menu
          modal={modal}
          cms={cms}
          listQuery={listQuery}
          site={site}
          fetchUnits={listLoader.refetch}
          fetchSite={fetchSite}
        />
      )}
      renderItem={renderItem}
      getItemLink={getItemLink}
      onClickItem={onClickItem}
      onClickAdd={onClickAdd}
      getTableRowClassName={getTableRowClassName}
    />
  );
}
