import wurd, { WurdText } from 'wurd-react';
import { pick } from 'lodash';

import * as actions from 'actions';
import Section from 'components/section';
import SectionCrudList from 'components/section_crud-list';
import LangText from 'components/lang-text';
import RadioList from 'components/form2/radiolist';
import SettingsForm2 from '../form2';
import Input from '../input';
import { Field, MultiLangText } from 'components/form2';
import AccountingCodeForm from './accounting-code-form';
import SystemCharges from './system-charges';
import AccountingMethodForm from './accounting-method-form';


export default function AccountingSettings({
  settings,
}) {
  return (
    <>{settings.modes.includes('valetStorage') && (
        <Section cms={wurd.block('settings.valetTax')}>
          <SettingsForm2
            wurdSection="settings.inputs"
            initialValue={settings}
            onSubmit={(data, dataPatches) => actions.business.update(dataPatches)}
          >
            {({ fieldProps }) => (
              <>
                <Input {...fieldProps('tax.type')} required wurdKey="tax.type" />
                <Input {...fieldProps('tax.percent')} type="number" required min="0" max="100" step="any"
      wurdKey="tax.percent" />
              </>
            )}
          </SettingsForm2>
        </Section>
      )}

      {settings.modes.includes('selfStorage') && (
        <>
          <Section cms={wurd.block('settings.tax')}>
            <SettingsForm2
              wurdSection="settings.tax"
              initialValue={pick(settings, ['taxMode'])}
              onSubmit={(data, dataPatches) => actions.business.update(dataPatches)}
            >
              {({ fieldProps }) => (
                <Field {...fieldProps('taxMode')} className={null}>
                  <RadioList>
                    {['exclusive', 'inclusive'].map(v => ({
                      value: v,
                      title: <WurdText id={`settings.inputs.taxMode.${v}.title`} />,
                      description: <WurdText id={`settings.inputs.taxMode.${v}.desc`} type="p" className="text-muted" />
                    }))}
                  </RadioList>
                </Field>
              )}
            </SettingsForm2>
          </Section>

          <SectionCrudList
            cms={wurd.block('settings.accountingCodes')}
            items={settings.accountingCodes.sort((a,b) => a.code.localeCompare(b.code))}
            Form={AccountingCodeForm}
            actions={actions.business.accountingCodes}
            onChange={actions.business.fetch}
          >
            {accountingCode => (
              <>
                {accountingCode.taxPct && (
                  <div className="pull-right text-right">
                    <div>{accountingCode.taxPct}%</div>
                    <div>{accountingCode.taxDesc}</div>
                  </div>
                )}

                <div><strong>{accountingCode.code}</strong></div>
                <div><LangText obj={accountingCode.title} /></div>
              </>
            )}
          </SectionCrudList>
        
          <Section cms={wurd.block('settings.systemCharges')}>
            <SystemCharges settings={settings} />
          </Section>

          <SectionCrudList
            cms={wurd.block('settings.manualPaymentMethods')}
            items={settings.manualPaymentMethods}
            Form={AccountingMethodForm}
            actions={actions.business.manualPaymentMethods}
            onChange={actions.business.fetch}
          >
            {item => (
              <>
                <div><LangText obj={item.title} /></div>
              </>
            )}
          </SectionCrudList>

          <Section cms={wurd.block('settings.terms')}>
            <SettingsForm2
              wurdSection="settings.terms"
              initialValue={pick(settings, ['terms'])}
              onSubmit={(data, dataPatches) => actions.business.update(dataPatches)}
            >
              {({ fieldProps }) => (
                Object.entries(settings.defaultTerms).map(([term, multilang]) => (
                  <Field
                    key={term}
                    {...fieldProps(`terms.${term}`)}
                    placeholder={settings.languages.length === 1
                      ? settings.defaultTerms[term][settings.languages[0]]
                      : settings.defaultTerms[term]
                    }
                  >
                    <MultiLangText />
                  </Field>
                ))
              )}
            </SettingsForm2>
          </Section>
        </>
      )}
    </>
  );
}
