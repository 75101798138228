import { useCallback } from 'react';
import PropTypes from 'prop-types';

import store from '../store';
import * as actions from '../actions';
import * as helpers from '../helpers';
import SiteLoader from './loaders/site';
import debounce from '../utils/debounce';

import AsyncSelect from 'react-select/async';


const selectStyles = {
  container: (base) => ({
    ...base,
    zIndex: 1,
    flex: 1,
    position: 'relative',
  }),
};

export default function FormUnitSelect({ value: unitId, onChange, siteId, ...props }) {
  const { units_byId, settings } = store.get();

  const unitToOption = unit => unit && {
    value: unit.id,
    label: (
      <>
        <strong>{unit.name}</strong> <span className="text-muted">{!siteId && <SiteLoader id={unit.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader>}</span>
      </>
    ),
  };

  const defaultOptions = Object.values(units_byId).filter(u => !siteId || u.siteId === siteId).map(unitToOption);
  const debouncedLoadOptions = useCallback(
    debounce(
      (inputValue) => {
        //Search and format options
        return actions.units.fetch({ siteId, search: inputValue, limit: 100 })
          .then(units => units.map(unitToOption));
      },
      400
    ),
    [siteId]
  );

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={debouncedLoadOptions}
      {...props}
      value={unitToOption(units_byId[unitId]) || ''}
      isOptionSelected={option => option.value === unitId}
      onChange={(option) => {
        if (!option) return;

        onChange(option.value);
      }}
      styles={selectStyles}
    />
  );
}