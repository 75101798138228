import wurd from 'wurd-react';

import { useListLoader } from '../../hooks';

import TableList from '../table-list';
import Filters from './filters';
import JobListItem from './item';

const cms = wurd.block('jobList');


export default function JobList({ listQuery }) {
  const listLoader = useListLoader('jobs', listQuery.params, { maxAge: 0 });

  const renderItem = (job) => (
    <JobListItem
      key={job.id}
      job={job}
      refetch={listLoader.refetch}
    />
  );

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={(
        <Filters
          listQuery={listQuery}
        />
      )}
      renderItem={renderItem}
      getItemLink={job => `/jobs/${job.id}`}
    />
  );
}
