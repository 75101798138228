import wurd, { WurdText, WurdMarkdown, WurdImage, WurdObject } from 'wurd-react';


/**
 * A shortcut for creating a full Wurd key; returns a getter scoped to the given section key
 *
 * @param {String} sectionKey
 *
 * @return {Function}
 */
export const key = (sectionKey) => {
  return (subKey) => {
    if (!subKey) return sectionKey;

    return sectionKey ? `${sectionKey}.${subKey}` : subKey;
  };
};

/**
 * A shortcut for wurd.get(); returns a getter scoped to the given section key
 *
 * @param {String} sectionKey
 *
 * @return {Function}
 */
export const text = (sectionKey) => {
  return (subKey, vars) => {
    const fullKey = sectionKey ? `${sectionKey}.${subKey}` : subKey;

    return wurd.text(fullKey, vars);
  };
};

/**
* Returns both shortcut helpers
*
 * @param {String} sectionKey
 *
 * @return {Object}
 */
export const getters = (sectionKey) => {
  return {
    k: key(sectionKey),
    t: text(sectionKey)
  };
};


export const editors = (sectionKey) => {
  return {
    CmsText: ({ id, ...rest }) => <WurdText id={`${sectionKey}.${id}`} {...rest} />,
    CmsMarkdown: ({ id, ...rest }) => <WurdMarkdown id={`${sectionKey}.${id}`} {...rest} />,
    CmsImage: ({ id, ...rest }) => <WurdImage id={`${sectionKey}.${id}`} {...rest} />,
    CmsObject: ({ id, ...rest }) => <WurdObject id={id ? `${sectionKey}.${id}` : sectionKey} {...rest} />
  };
};
