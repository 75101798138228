import PropTypes from 'prop-types';
import wurd from 'wurd-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import Modal from '../modal';
import UserLoader from '../loaders/user';
import ValetOrderForm from '../form2/valet-order';

const { hasRole } = helpers.auth;

const cms = wurd.block('valetOrder.menu')


export default function ValetOrderMenu(props) {
  const {
    valetOrder,
    job,
    modal,
    fetchValetOrder,
  } = props;

  function canEdit() {
    return hasRole('admin');
  }

  function edit() {
    modal.open(
      <UserLoader id={valetOrder.ownerId}>
        {(user) => (
          <ValetOrderForm
            user={user}
            initialValue={valetOrder}
            onSubmit={data => actions.valetOrders.update(valetOrder.id, data)}
            onSuccess={() => {
              modal.close();
              fetchValetOrder();
            }}
            closeModal={modal.close}
            mode="update"
          />
        )}
      </UserLoader>
    );
  }

  function canCancel() {
    return hasRole('admin') && valetOrder.state === 'submitted';
  }

  async function cancel() {
    const confirmed = window.confirm(cms.text('confirmCancel'));
    if (!confirmed) return;

    await actions.valetOrders.update(valetOrder.id, { state: 'cancelled' });
    fetchValetOrder();
  }

  function canViewJobData() {
    return helpers.auth.hasRole('_support');
  }

  function viewJobData() {
    modal.open(
      <Modal title={job.type} subtitle={job.id}>
        <textarea
          style={{
            display: 'block',
            width: '100%',
            height: 400,
            fontFamily: 'monospace',
          }}
          value={JSON.stringify(job, null, 2)}
          readOnly
        />
      </Modal>,
    );
  }

  function getMenuItems() {
    const items = [];

    if (canEdit(valetOrder)) {
      items.push(
        <MenuItem key="edit" onSelect={edit}><i className="fas fa-fw fa-edit" /> {cms.text('editOrder')}</MenuItem>,
      );
    }

    if (canCancel(valetOrder)) {
      items.push(
        <MenuItem key="cancel" onSelect={cancel}><i className="fas fa-fw fa-times" /> {cms.text('cancelOrder')}</MenuItem>,
      );
    }

    if (canViewJobData()) {
      items.push(
        <MenuItem key="viewJobData" onSelect={viewJobData}>
          <em className="text-muted">
            <i className="fas fa-fw fa-code" /> View job data
          </em>
        </MenuItem>,
      );
    }

    return items;
  }

  //if (!job) return null;

  const menuItems = getMenuItems(valetOrder);

  if (!menuItems.length) return null;

  return (
    <cms.Object keys="editOrder,cancelOrder,confirmCancel">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}
