import { WurdText } from 'wurd-react';
import { getLangText } from 'helpers/ui';
import { currentLang } from 'utils/language';
import LangText from 'components/lang-text';
import { Editable } from 'components/markdown';
import { Field, MultiLangText, FormObject, Toggle, RadioList, ModalForm2 } from 'components/form2';
import ImageUploader from 'components/settings/image-uploader';
import UploadImageButton from 'components/uploader';
import UrlPicker from '../url-picker';
import store from 'store';


const variants = {
  white: '#fff',
  light: '#f1f1f1',
  dark: '#0008',
};
export function VariantField({ options = ['light', 'white'], ...props }) {
  return (
    <Field {...props} wurdKeys={`label,help,${options}`} defaultValue={options[0]} className={null}>
      <RadioList>
        {options.map(value => ({
          value,
          title: <WurdText id={`website.page.block.variant.${value}`} style={{ margin: '5px' }} />,
          style: { backgroundColor: variants[value], color: value === 'dark' ? '#fff' : null },
        }))}
      </RadioList>
    </Field>
  )
}


export function edit({ block, setBlock, modal }) {
  modal.open(
    <ModalForm2
      wurdSection="website.page.block"
      mode="update"
      initialValue={block}
      onSubmit={async data => setBlock(data)}
      onSuccess={modal.close}
      closeModal={modal.close}
    >
      {({ fieldProps, formValue }) => (
        <>
          <Field {...fieldProps('title')} autoFocus><MultiLangText /></Field>
          <Field {...fieldProps('text')}><MultiLangText multiline /></Field>
          <ImageUploader {...fieldProps('img.url')} showUrlButton style={{ maxWidth: '100%', height: 50 }} />
          <Field {...fieldProps('img.alt')} />
          <VariantField {...fieldProps('variant')} options={block.type === 'hero1' ? ['light', 'white', 'dark'] : undefined} />
          <Field {...fieldProps('text_start')} type="checkbox" className="btn btn-link mb-0"><Toggle /></Field>
          {block.type !== 'hero2' && <Field {...fieldProps('highlight')} type="checkbox" className="btn btn-link mb-0"><Toggle /></Field>}
          <Field {...fieldProps('id')} />
        </>
      )}
    </ModalForm2>
  );
}


export default function ({ block, setBlock, modal }) {
  const brand = store.get('settings')?.brand?.linkColor;

  function editItem(i) {
    modal.open(
      <ModalForm2
        wurdSection="website.page.block_item"
        mode={i !== undefined ? 'update' : 'create'}
        initialValue={block.links?.[i]}
        onSubmit={i !== undefined
          ? async data => setBlock({ links: block.links.map((b, j) => i === j ? { ...b, ...data } : b) })
          : async data => setBlock({ links: [...block.links || [], data] })
        }
        onDelete={i !== undefined && (async () => setBlock({ links: block.links.map((b, j) => i === j ? null : b) }))}
        onSuccess={modal.close}
        closeModal={modal.close}
      >
        {({ fieldProps }) => (
          <>
            <Field {...fieldProps('text')} autoFocus><MultiLangText /></Field>
            <Field {...fieldProps('url')}><UrlPicker /></Field>
            <Field {...fieldProps('primary')} type="checkbox" className="btn btn-link mb-0"><Toggle color={brand} /></Field>
          </>
        )}
      </ModalForm2>
    );
  }

  const _brand = brand?.length === 4 ? brand + '4' : brand?.length === 7 ? brand + '44' : brand;
  const textWhite = block.type === 'hero1' && (block.variant === 'dark' || block.variant !== 'white' && block.img?.url);

  return (
    <div
      style={{
        background: block.type !== 'hero2' && [
          block.variant === 'dark' && `linear-gradient(#0004, #0004), linear-gradient(${_brand}, ${_brand})`,
          block.img?.url && `url("${block.img?.url}") no-repeat 50%/cover`,
        ].filter(Boolean).join(', '),
        padding: block.type === 'hero1' ? `0 0 0 ${block.text_start ? '12.5%' : '25%'}` : '0 12.5%',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.5rem' }}>
        <div style={{ flex: 1, maxWidth: '60%', margin: 'auto', order: 1, padding: '1rem', backgroundColor: block.type === 'hero1' && block.highlight ? (textWhite ? '#0004' : '#fffb') : null }}>
          <h4 className={block.type === 'hero1' && !block.text_start ? 'text-center' : null} style={{ color: textWhite ? '#fff' : null, fontWeight: 400 }}>
            <Editable inline placeholder="[Title]" onChange={e => setBlock({ title: { [currentLang]:e.target.value } })}>{getLangText(block.title)}</Editable>
          </h4>
        
          <Editable
            className={block.type === 'hero1' && !block.text_start ? 'text-center' : null}
            placeholder="[Text]"
            style={textWhite ? { color: '#fff', fontWeight: 400 } : null}
            onChange={e => setBlock({ text: { [currentLang]: e.target.value } })}
          >
            {getLangText(block.text)}
          </Editable>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: block.type === 'hero1' && !block.text_start ? 'center' : 'start', gap: '1rem', marginTop: '2.5rem', marginBottom: '.5rem' }}>
            {block.links?.map((item, i) => item && (
              <a
                key={i}
                href={item.url}
                onClick={e => { e.preventDefault(); editItem(i); }}
                target="_blank"
                className="btn btn-link btn-sm"
                style={item.primary 
                  ? {
                    backgroundColor: brand,
                    color: '#fff',
                    fontWeight: 500,
                    borderRadius: 2,
                    marginTop: '-1rem',
                  } : {
                    backgroundColor: '#fffd',
                    color: brand,
                    borderRadius: 2,
                    marginTop: '-1rem',
                  }
                }
              >
                <LangText>{item.text}</LangText>
              </a>
            ))}
            <button
              className="btn btn-link btn-sm"
              onClick={() => editItem()}
              style={{ marginTop: '-1rem', outline: '1px dashed var(--bs-primary)', outlineOffset: -1, backgroundColor: '#fffd' }}
            >
              <i className="far fa-plus" /> <WurdText id="website.blocks.addCta" />
            </button>
          </div>
        </div>
        <div
          style={{ flex: block.type === 'hero1' && block.text_start ? 3 : 1, order: block.type === 'hero2' && !block.text_start ? 0 : 2 }}
          className="text-center"
        >
          {block.type === 'hero2' && block.img?.url && <img src={block.img.url} style={{ height: 120, width: '100%', objectFit: 'contain', margin: '1rem 0' }} alt={block.img.alt} />}
          <UploadImageButton
            value={block.img?.url}
            onChange={e => setBlock({ img: { url: e.target.value } })}
            showUrlButton
            style={{ opacity: .8 }}
          />
        </div>
      </div>
    </div>
  );
}
