import wurd from 'wurd-react';
import { getAccountingCodes } from '../../helpers/business';
import Select from './select'


const extractIdFromAccountingCode = (accountingValue, accountingCodes) => {
  const accountingCode = accountingCodes.find(
    (code) =>
      code.id === accountingValue || code.code === accountingValue
  );
  return accountingCode?.id;
}

export default function FormAccountingCodeSelect({ nullText, ...props }) {
  const accountingCodes = getAccountingCodes();
  const options = [
    { value: null, label: nullText || wurd.text('common.phrases.none') },
    ...accountingCodes
      .map((accountingCode) => ({
        value: accountingCode.id,
        label: `${accountingCode.code}: ${accountingCode.desc}`,
      }))
  ];


  return (
    <Select
      {...props}
      options={options}
      value={ extractIdFromAccountingCode(props?.value || nullText, accountingCodes)  || wurd.text('common.phrases.none')}
    />
  );
}
