import wurd from 'wurd-react';
import * as helpers from 'helpers';
import ListFilter from 'components/filter/list';
import LabelsFilter from 'plugins/labels/filter';
import SearchFilter from 'components/filter/search';


const cms = wurd.block('userList.filters');

const states = [
  'enabled',
  'disabled',
];

export default function TeamListFilter({ listQuery }) {
  const { params } = listQuery;

  function onChange(obj) {
    listQuery.update({
      offset: 0,
      ...obj,
    });
  }
  const valetStorage = helpers.settings.get().modes?.includes('valetStorage');

  return (
    <>
      <cms.Object id="state" keys={['title', 'all'].concat(states)}>
        <ListFilter
          title={cms.text('state.title')}
          active={params.state}
          onSelect={val => onChange({ state: val })}
        >
          <li value={null}>{cms.text('state.all')}</li>
          {states.map((state) => (
            <li key={state} value={state}>
              {cms.text(`state.${state}`)}
            </li>
          ))}
        </ListFilter>
      </cms.Object>

      <LabelsFilter
        active={params.labels}
        onSelect={(val) => onChange({ labels: val })}
        labels={helpers.settings.get().userLabels}
      />

      <SearchFilter
        name="team-search"
        cms={cms.block('search')}
        active={params.search}
        onSelect={(val) => onChange({ search: val })}
        modelName="user"
        searchables={[
          'address',
          ...(valetStorage ? ['area'] : []),
          'companyName',
          'phone',
          'phone2',
        ]}
      />
    </>
  );
}
