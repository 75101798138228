import * as helpers from '../../../helpers';

import ModalForm from '../modal-form';
import Field from '../horizontal-field';

const wurdSection = 'item';
const { t } = helpers.cms.getters(wurdSection);


const ItemForm = ({ mode, stateOptions = ['storage', 'user'], ...rest }) => {

  return (
    <ModalForm
      wurdSection={wurdSection}
      mode={mode}
      {...rest}
    >
      {(getProps, data) => {

        return (
          <div>
            <Field {...getProps('type')} required>
              <select>
                {helpers.item.listBulky(true).map(item =>
                  <option key={item.type} value={item.type}>{item.title}</option>
                )}
              </select>
            </Field>

            <Field {...getProps('state')} required>
              <select>
                {stateOptions.map(state =>
                  <option key={state} value={state}>{t(`_states.${state}`)}</option>
                )}
              </select>
            </Field>

            {/*<Field {...getProps('ownerId')} />*/}

            {data.state !== 'user' &&
              <Field {...getProps('location')} />
            }

            {data.state !== 'unassigned' &&
              <Field {...getProps('opsTitle')} />
            }
          </div>
        );
      }}
    </ModalForm>
  );

};


export default ItemForm;
