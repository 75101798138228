import moment from 'moment-timezone';
import styled from '@emotion/styled';


const Small = styled('small')({
  marginLeft: 6,
  '&:not(:empty)::before': {
    content: '"["'
  },
  '&:not(:empty)::after': {
    content: '"]"'
  }
});

export default ({ job }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <i className="far fa-level-up" style={{ width: 15 }} />
      <span style={{ whiteSpace: 'nowrap', marginLeft: 5 }}>{moment(job.data && job.data.updatedAfter || 0).format('YYYY-MM-DD HH:mm')}</span>
      <Small>{Object.entries(job.result).map(([k, v]) => `${k}:${v}`).join(', ')}</Small>
    </div>
  );
};
