import wurd, { WurdText } from 'wurd-react';
import moment from 'moment-timezone';
import { MenuItem } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { alert, prompt, confirm } from '../../helpers/ui';
import { hasRole } from '../../helpers/auth';
import * as helpers from '../../helpers';
import * as actions from '../../actions';
import { useItemLoader } from 'hooks';
import * as pageHelpers from './helpers';
import store from 'store'
import * as secsys from 'plugins/security-systems';

import MenuButton from '../menu-button';
import UnitMoveOutForm from './forms/move-out';
import UpdatePriceForm, { getAmountForPercentIncrease } from './forms/update-price';
import UnitPromoForm from '../form2/business/unit-promo';


const cms = wurd.block('unitView.menu');

const wurdIds = [
  'block',
  'confirmBlock',
  'unblock',
  'confirmUnblock',
  'archive',
  'confirmArchive',
  'enterArchivedReason',
  'scheduleMoveIn',
  'scheduleMoveOut',
  'prepayPeriods',
  'transferUnit',
  'createCustomPromo',
  // 'addLockout',
  // 'confirmAddLockout',
  // 'removeLockout',
  // 'confirmRemoveLockout',
  'schedulePriceChange',
  'regenerateAgreement',
  'confirmRegenerateAgreement',
];


export default function UnitDetailMenu({
  modal,
  unit,
  rental,
  onChange,
  tasksPlugin,
}) {
  const navigate = useNavigate();
  const params = useParams();
  const { item: site } = useItemLoader('sites', unit?.siteId);

  function handleChange() {
    modal.close();
    onChange();
    // clear unit cache
    store.set({
      units_byId: { ...store.get('units_byId'), [unit.id]: undefined }
    });
  }

  function canBlock() {
    return unit?.state === 'available';
  }

  async function block() {
    const blockedReason = prompt(cms.text('confirmBlock'));

    // Handle cancel
    if (blockedReason === null) return;

    // Handle OK with blank text
    if (!blockedReason) {
      alert('A reason is required');
      return;
    }

    await actions.jobs.create(unit?.ownerId, 'unit_block', {
      unitId: unit.id,
      blockedReason,
    });

    handleChange();
  }

  function canUnblock() {
    return unit?.state === 'blocked';
  }

  async function unblock() {
    if (!confirm(cms.text('confirmUnblock'))) return;

    await actions.jobs.create(unit.ownerId, 'unit_unblock', {
      unitId: unit.id,
    });

    handleChange();
  }

  function canArchive() {
    return hasRole('manager') && unit?.state === 'blocked';
  }

  async function archive() {
    if (!confirm(cms.text('confirmArchive'))) return;

    const archivedReason = prompt(cms.text('enterArchivedReason'));

    // Handle cancel
    if (archivedReason === null) return;

    // Handle OK with blank text
    if (!archivedReason) {
      alert('A reason is required');
      return;
    }

    await actions.jobs.create(unit?.ownerId, 'unit_archive', {
      unitId: unit.id,
      archivedReason,
    });

    handleChange();
  }

  function canScheduleMoveIn() {
    if (unit?.state !== 'available') return false;

    if (unit?.jobs && unit?.jobs.find(job => job.type === 'unit_moveIn')) return false;

    return true;
  }

  function scheduleMoveIn() {
    const handleChange = job => {
      onChange();
      if (job.result.unitRentalId !== params.id) {
        navigate(`/unit-rentals/${job.result.unitRentalId}`);
      }
    };

    pageHelpers.scheduleMoveIn({ unit, modal, onChange: handleChange });
  }

  function canPrepayPeriods() {
    if (unit?.state !== 'occupied') return false;

    return true;
  }

  function prepayPeriods() {
    const handleChange = job => {
      onChange();
      navigate(`/invoices/${job.result.invoiceId}`);
    };
    pageHelpers.prepayPeriods({ unit, rental, modal, onChange: handleChange });
  }

  function canTransferUnit() {
    if (unit?.state !== 'occupied') return false;

    return true;
  }

  function transferUnit() {
    const handleChange = job => {
      onChange();
      if (job.data.newUnitId !== params.id) {
        navigate(`/units/${job.data.newUnitId}`);
      }
    };

    pageHelpers.transferUnit({ unit, rental, modal, onChange: handleChange });
  }

  function canSchedulePriceChange() {
    if (unit?.state !== 'occupied') return false;

    return true;
  }

  function schedulePriceChange() {
    const initialValue = {
      oldPrice: rental?.price,
      _pctIncrease: 10,
      newPrice: getAmountForPercentIncrease(rental?.price, 10),
    };

    modal.open(
      <UpdatePriceForm
        initialValue={initialValue}
        onSubmit={async data => {
          await actions.jobs.create(rental.ownerId, 'unitRental_updatePrice', {
            ...data,
            unitRentalId: rental.id,
          });
        }}
        onSuccess={handleChange}
        closeModal={modal.close}
      />,
    );
  }

  function canScheduleMoveOut() {
    if (unit?.state !== 'occupied') return false;

    if (unit?.jobs && unit?.jobs.find(job => job.type === 'unit_moveOut')) return false;

    return true;
  }

  function scheduleMoveOut() {
    const initialValue = {
      date: moment().format('YYYY-MM-DD'),
    };

    modal.open(
      <UnitMoveOutForm
        initialValue={initialValue}
        onSubmit={async data => {
          await actions.jobs.create(unit.ownerId, 'unit_moveOut', {
            unitId: unit.id,
            date: data.date,
          });
        }}
        onSuccess={handleChange}
        closeModal={modal.close}
      />,
    );
  }

  function createCustomPromo() {
    modal.open(
      <UnitPromoForm
        onSubmit={(data) => actions.unitRentals.createCustomPromo(unit.rentalId, data)}
        onSuccess={handleChange}
        closeModal={modal.close}
        mode="create"
        title={<WurdText id="unit.customPromo.modal.title_create" type="h4" className="modal-title" />}
        extraFields={[]}
        settings={store.get('settings')}
      />,
    );
  }

  function setOverlock() {
    if (!confirm(cms.text(rental.overlocked ? 'confirmRemoveLockout' : 'confirmAddLockout'))) return;

    actions.unitRentals.update(rental.id, {
      overlocked: rental.overlocked ? null : new Date(),
    }, { include: 'customFields' })
      .then(handleChange);
  }

  function canRegenerateAgreement() {
    return hasRole('manager') && rental?.agreementUrl;
  }

  function regenerateAgreement() {
    if (!confirm(cms.text('confirmRegenerateAgreement'))) return;

    actions.unitRentals.regenerateAgreement(rental.id)
      .then(handleChange);
  }

  function canCreateUnitSystemInvoice() {
    return hasRole('__superadmin') && unit?.rentalId;
  }

  async function fetchSecuritySystem() {
    const accesses = await actions.sites.securitySystem.fetch(site.id, { unitName: unit.name });
    window.alert(JSON.stringify(accesses, null, 2));
    console.log(accesses);
    modal.close();
  }

  return (
    <MenuButton
      cms={cms}
      cmsKeys={wurdIds.join(',')}
    >
      {canScheduleMoveIn() && (
        <MenuItem key="scheduleMoveIn" onSelect={scheduleMoveIn}>
          <i className="far fa-fw fa-sign-in-alt" />&nbsp;&nbsp;&nbsp;{cms.text('scheduleMoveIn')}
        </MenuItem>
      )}

      {canPrepayPeriods() && (
        <MenuItem key="prepayPeriods" onSelect={prepayPeriods}>
          <i className="far fa-fw fa-calendar-plus" />&nbsp;&nbsp;&nbsp;{cms.text('prepayPeriods')}
        </MenuItem>
      )}

      {canTransferUnit() && (
        <MenuItem key="transferUnit" onSelect={transferUnit}>
          <i className="far fa-fw fa-exchange-alt" />&nbsp;&nbsp;&nbsp;{cms.text('transferUnit')}
        </MenuItem>
      )}

      {canSchedulePriceChange() && (
        <MenuItem key="schedulePriceChange" onSelect={schedulePriceChange}>
          <i className="far fa-fw fa-dollar-sign" />&nbsp;&nbsp;&nbsp;{cms.text('schedulePriceChange')}
        </MenuItem>
      )}

      {canScheduleMoveOut() && (
        <>
          <MenuItem key="scheduleMoveOut" onSelect={scheduleMoveOut}>
            <i className="far fa-fw fa-sign-out-alt" />&nbsp;&nbsp;&nbsp;{cms.text('scheduleMoveOut')}
          </MenuItem>
          <MenuItem key="createCustomPromo" onSelect={createCustomPromo}>
            <i className="far fa-fw fa-star" />&nbsp;&nbsp;&nbsp;{cms.text('createCustomPromo')}
          </MenuItem>
          {unit?.customFields?.[secsys.getCfKey(site)] && rental && (
            <MenuItem key="setLocked" onSelect={setOverlock}>
              <i className={`far fa-fw ${rental.overlocked ? 'fa-unlock-alt' : 'fa-lock-alt'}`} />&nbsp;&nbsp;&nbsp;{cms.text(rental.overlocked ? 'removeLockout' : 'addLockout')}
            </MenuItem>
          )}
        </>
      )}

      {canBlock() && (
        <MenuItem key="block" onSelect={block}>
          <i className="far fa-fw fa-ban" />&nbsp;&nbsp;&nbsp;{cms.text('block')}
        </MenuItem>
      )}

      {canUnblock() && (
        <MenuItem key="unblock" onSelect={unblock}>
          <i className="far fa-fw fa-ban" />&nbsp;&nbsp;&nbsp;{cms.text('unblock')}
        </MenuItem>
      )}

      {canArchive() && (
        <MenuItem key="archive" onSelect={archive}>
          <i className="far fa-fw fa-trash" />&nbsp;&nbsp;&nbsp;{cms.text('archive')}
        </MenuItem>
      )}

      {canRegenerateAgreement() && (
        <MenuItem key="regenerateAgreement" onSelect={regenerateAgreement}>
          <i className="far fa-fw fa-signature" />&nbsp;&nbsp;&nbsp;{cms.text('regenerateAgreement')}
        </MenuItem>
      )}

      {hasRole('__superadmin') && unit?.customFields?.[secsys.getCfKey(site)] && (
        <MenuItem key="setAccessCode" onSelect={fetchSecuritySystem}>
          <em className="text-muted"><i className="far fa-fw fa-wand-magic" />&nbsp;&nbsp;&nbsp;Fetch security system accesses</em>
        </MenuItem>
      )}

      <tasksPlugin.AddTaskMenuItem targetId={rental ? rental.id : unit.id} />
    </MenuButton>
  );
}
