import wurd, { WurdText } from 'wurd-react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { useListLoader, useHistoryListQuery } from 'hooks';

import TableList from 'components/table-list';
import Filters from './filters';
import Item from './item';
import AdminForm from 'components/form2/user/admin';
import Menu from 'components/user-list/menu';

const cms = wurd.block('settings.team');

export default function Team({ modal, getItemLink }) {
  const listQuery = useHistoryListQuery({
    initialParams: {
      state: 'enabled',
    },
    implicitParams: {
      roles: '', // show only users with a role
    }
  });

  const fields = ['firstName', 'lastName', 'email', 'roles', 'permissions'];

  const { params } = listQuery;
  const listLoader = useListLoader('users', params, { maxAge: 0 });

  function onClickItem(user) {
    modal.open(
      <AdminForm
        initialValue={{
          ..._.pick(user, fields),
          id: user.id,
          sendInvite: !user.emailConfirmed,
        }}
        extraButton={
          <Link className="btn btn-link pull-left" to={`/users/${user.email}`}>
            <WurdText id="user.profileLink" />
          </Link>
        }
        onSuccess={() => {
          modal.close();
          listLoader.refetch();
        }}
        closeModal={modal.close}
        mode="update"
      />
    );
  }

  const renderItem = (team) => <Item team={team} />;

  function onClickAdd() {
    modal.open(
      <AdminForm
        initialValue={{ roles: ['admin'], sendInvite: true }}
        onSuccess={() => {
          modal.close();
          listLoader.refetch();
        }}
        closeModal={modal.close}
        mode="create"
      />
    );
  }

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={<Filters listQuery={listQuery} />}
      renderItem={renderItem}
      getItemLink={getItemLink}
      onClickAdd={onClickAdd}
      onClickItem={(item) => onClickItem(item)}
      menu={
        <Menu
          modal={modal}
          cms={cms}
          listQuery={listQuery}
          refetch={listLoader.refetch}
        />
      }
    />
  );
}
