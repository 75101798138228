import wurd from 'wurd-react';
import styled from '@emotion/styled';
import TransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { uniqBy } from 'lodash';

import { useStore } from '../hooks';

import { hideNotification, iconColors } from '../helpers/ui';


const Wrapper = styled.div({
  position: 'fixed',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 99999,

  // Mobile
  '@media (max-width: 767px)': {
    top: 8,
    width: '80%',
  },

  // Desktop
  '@media (min-width: 768px)': {
    bottom: 20,
    width: '600px',
  },

  '.notification': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
    padding: '6px 12px',
    background: '#333',
    color: 'white',
    borderRadius: 2,
    fontSize: 14,
    boxShadow: '0 4px 10px 4px #ddd',

    '&.success': {
      backgroundColor: iconColors.success.color,
    },

    '&.warning': {
      backgroundColor: iconColors.warning.color,
    },

    '&.danger': {
      backgroundColor: iconColors.danger.color,
    },

    '.text': {
      marginRight: 12,
    },

    '.undoBtn': {
      display: 'block',
      marginLeft: 'auto',
      marginRight: -4,
      color: 'white',
    },

    '.close': {
      display: 'block',
      marginLeft: 'auto',
      marginRight: -4,
    },

    '&.example-enter': {
      opacity: 0,
    },

    '&.example-enter.example-enter-active': {
      opacity: 0.95,
      transition: 'opacity 200ms ease-in-out',
    },

    '&.example-leave': {
      opacity: 0.95,
    },

    '&.example-leave.example-leave-active': {
      opacity: 0,
      transition: 'opacity 200ms ease-in-out',
    },
  },
});


function Notification({
  id,
  text,
  bsType = 'default',
  undo = null, // If passed, an undo button will be added with this as onClick
}) {
  function close() {
    hideNotification(id);
  }

  return (
    <div
      className={`notification ${bsType}`}
    >
      <div className="text">{text}</div>

      {undo && (
        <button
          type="button"
          className="undoBtn btn-link"
          onClick={undo}
        >
          {wurd.text('common.notifications.undoBtn')}
        </button>
      )}

      {!undo && (
        <button
          type="button"
          className="close"
          onClick={close}
        >
          &times;
        </button>
      )}
    </div>
  );
}


export default function Notifications() {
  const [_notifications, setNotifications] = useStore('notifications', []);
  const notifications = uniqBy(_notifications, 'text');

  return (
    <Wrapper>
      <TransitionGroup
        transitionName="example"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
      >
        {notifications.map((notification, i) => (
          <Notification
            key={notification.text} // Using index prevents a visual glitch when notifications disappear
            {...notification}
          />
        ))}
      </TransitionGroup>
    </Wrapper>
  );
}
