/**
 * Creates endpoint for managing documents labels
 */

import { update } from '../../actions/general';


/**
 * @param {'invoices'|'items'|'jobs'|'sites'|'units'|'users'|'valet-orders'} endpoint
 */
export default function (endpoint) {
  return {
    /**
     * Updates doc labels
     *
     * @param {String} itemId
     * @param {Object} labels
     */
    update(id, labels) {
      return update(endpoint, id, { labels });
    },
  }
}
