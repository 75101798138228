import { useState } from 'react';
import wurd from 'wurd-react';
import _ from 'lodash';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';
import useSelector from '../hooks/selector';
import { useListLoader } from '../../../hooks';

import NoResults from '../../no-results';
import JobStep from '../../jobs/job-step2';
import LargeButtonGroup from '../../large-button-group';
import LinkButtonGroup, { LinkButton } from '../../link-button-group';
import Tabs from '../shared/tabs';
import ResultsItemList from '../shared/results-item-list';
import Item2List from '../shared/item2-list';
import OwnerLocation from '../shared/owner-location';
import ScanReceiver from '../shared/scan-receiver';
import SelectionButton from '../shared/selection-button';
import AddItemButton from '../shared/add-item-button';
import ScanItemsButton from '../shared/scan-items-button';
import CompleteStepButton from '../shared/complete-step-button';
import ItemDetailsForm from '../shared/item-details-form';
import ErrorMessage from 'components/error-message';

const cms = wurd.block('valetOrder.steps.items_collect');


export default function Step_items_collect(props) {
  const {
    valetOrder,
    step,
    items,
    updateItems,
    modal,
    fetchValetOrder,
    fetchItems,
  } = props;

  function itemHasBeenCollected(item) {
    return (
      item.state === 'transit-storage'
      && item.valetOrderId === valetOrder.id
    );
  }

  const unpickedItemIds = valetOrder.collectItems.filter((collectItemId) => !items.some(({ id }) => id === collectItemId));

  // TODO: Why is it still querying even when unpickedItemIds is empty?
  const { items: unpickedItems } = useListLoader('items', unpickedItemIds.length > 0 && {
    ids: unpickedItemIds.join(','),
    limit: 1000,
  });

  const allItems = _.uniqBy(items.concat(unpickedItems), 'id');
  const hasCollectedAll = allItems.every(itemHasBeenCollected);

  const collectedItems = allItems.filter(itemHasBeenCollected);
  const uncollectedItems = allItems.filter(item => !itemHasBeenCollected(item));

  const selector = useSelector();

  const [updateErrs, setUpdateErrs] = useState(null);

  const [activeTab, setActiveTab] = useState('todo');
  const [lastAction, setLastAction] = useState();

  function onTabChange(tabId) {
    selector.clearSelection();

    setActiveTab(tabId);
  }

  async function collectItems(itemSids, itemData = {}) {
    const { errors, updatedSids } = await updateItems('valetOrder_collect', itemSids, itemData);

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Collected ${updatedSids.length} items`,
        bsType: 'success',
        undo: () => uncollectItems(updatedSids, itemData),
      });
    }

    setLastAction('collect');
    selector.clearSelection();
    modal.close();
  }

  async function uncollectItems(itemSids, itemData = {}) {
    const { errors, updatedSids } = await updateItems('valetOrder_uncollect', itemSids, itemData);

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Uncollected ${updatedSids.length} items`,
        undo: () => collectItems(updatedSids, itemData),
      });
    }

    setLastAction('uncollect');
    selector.clearSelection();
    modal.close();
  }

  function onClickItem(item) {
    modal.open(
      <ItemDetailsForm
        itemId={item.id}
        initialValue={item}
        modal={modal}
        fields={['opsImage', 'opsTitle', 'state']}
        stateOptions={[
          ['user', wurd.text('item._states.user')],
          ['transit-storage', cms.text('doneTab')],
        ]}
        onSubmit={async ({ state, ...itemData }) => {
          const fn = (state === 'transit-storage') ? collectItems : uncollectItems;
          fn([item.sid], itemData);
        }}
      />,
    );
  }

  async function markFailed() {
    const failureReason = helpers.ui.prompt(cms.text('failedReason'));
    if (!failureReason) return;

    const data = {
      state: 'failed',
      error: failureReason,
    };

    await actions.valetOrders.updateStep(valetOrder.id, step.id, data);
  }

  function renderActions_failed() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="requiresInput.title" />}
      >
        <div style={{ marginTop: 12, marginBottom: 0 }}>
          <ErrorMessage>
            <strong>COLLECTION FAILED</strong><br />{step.error}
          </ErrorMessage>
        </div>
      </JobStep>
    );
  }

  function renderSelectionButtons() {
    return (
      <cms.Object keys="collectBtn,uncollectBtn">
        {activeTab === 'todo' && (
          <SelectionButton
            bsType="success"
            faIcon="fas fa-check-circle"
            text={cms.text('collectBtn')}
            onClick={() => collectItems(selector.selectedSids)}
          />
        )}
        {activeTab === 'done' && (
          <SelectionButton
            bsType="danger"
            faIcon="fas fa-times-circle"
            text={cms.text('uncollectBtn')}
            onClick={() => uncollectItems(selector.selectedSids)}
          />
        )}
      </cms.Object>
    );
  }

  function renderAddItemButton() {
    return (
      <AddItemButton
        valetOrder={valetOrder}
        modal={modal}
        fetchItems={fetchItems}
        newItemState="user"
      />
    );
  }

  function renderActions_ready() {
    return (
      <JobStep
        step={step}
        faIcon="fas fa-truck"
        title={<cms.Text id="requiresInput.title" vars={{ numItems: uncollectedItems.length }} />}
        helpWurdId={cms.id('instructions.helpUrl')}
      >
        <ScanReceiver onScan={collectItems} />

        <OwnerLocation
          valetOrder={valetOrder}
          step={step}
          modal={modal}
          appointmentType="collect"
          fetchValetOrder={fetchValetOrder}
        />

        <Tabs
          onChange={onTabChange}
          items={[
            {
              id: 'todo',
              faIcon: 'fa-tasks',
              title: <cms.Text id="todoTab" />,
              badge: uncollectedItems.length,
              animateBadge: lastAction === 'uncollect',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={uncollectedItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  successState="transit-storage"
                  fallback={(
                    <>
                      {renderAddItemButton()}

                      <NoResults faIcon="fa-check-circle" faColor="success">
                        <cms.Markdown id="todo.noResults" />
                      </NoResults>
                    </>
                  )}
                  extra={selector.isSelecting ? renderSelectionButtons() : renderAddItemButton()}
                  onClickItem={onClickItem}
                />
              ),
            },
            {
              id: 'done',
              faIcon: 'fa-check-circle',
              title: <cms.Text id="doneTab" />,
              badge: collectedItems.length,
              animateBadge: lastAction === 'collect',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={collectedItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  successState="transit-storage"
                  fallback={(
                    <NoResults faIcon="fa-truck">
                      <cms.Markdown id="done.noResults" />
                    </NoResults>
                  )}
                  extra={selector.isSelecting && renderSelectionButtons()}
                  onClickItem={onClickItem}
                />
              ),
            },
          ]}
        />

        <div style={{ marginTop: 20 }}>
          <cms.Object keys="markFailedBtn,failedReason,completeBtn,notFinished_confirm">
            <LargeButtonGroup>
              <ScanItemsButton
                modal={modal}
                receiveScannedSids={collectItems}
              />
              <CompleteStepButton
                valetOrder={valetOrder}
                step={step}
                cms={cms}
                hasProcessedAll={hasCollectedAll}
              />
            </LargeButtonGroup>

            {collectedItems.length === 0 && (
              <LinkButtonGroup align="center" style={{ marginTop: 18 }}>
                <LinkButton
                  faIcon="far fa-times"
                  text={cms.text('markFailedBtn')}
                  onClick={markFailed}
                />
              </LinkButtonGroup>
            )}
          </cms.Object>
        </div>
      </JobStep>
    );
  }

  function renderActions() {
    return (step.state === 'failed')
      ? renderActions_failed()
      : renderActions_ready();
  }

  function renderResults() {
    const collectedItemIds = step.result.collected;
    const uncollectedItemIds = step.result.uncollected;

    if (!collectedItemIds) return null;

    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" vars={{ numItems: collectedItemIds.length }} />}
      >
        <h4>Collected items</h4>
        <ResultsItemList itemIds={collectedItemIds} />

        <h4>Uncollected items</h4>
        <ResultsItemList itemIds={uncollectedItemIds} />
      </JobStep>
    );
  }


  return (step.state === 'completed') ? renderResults() : renderActions();
}
