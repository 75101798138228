import AdvancedDetails from './advanced-details';
import InvoiceDetails from './invoice-details';
import BookingDetails from './booking-details';
import LateFees from './late-fees';
import SecPlugin from './sec-plugin';
import SecuritySystemOld from './security-system_old';
import Esign from './esign';
import Automation from './automation';
import DangerZone from './danger-zone';
import CustomFieldsSection from 'plugins/custom-fields/section';


export default function SiteAdvancedSettings(props) {
  const {
    cms,
    modal,
    site,
  } = props;

  return (
    <>
      <AdvancedDetails {...props} />

      <CustomFieldsSection
        cms={cms.block('customFields')}
        modelName="site"
        model={site}
        modal={modal}
      />

      <Automation {...props} />

      <InvoiceDetails {...props} />

      <BookingDetails {...props} />

      <LateFees {...props} />

      <Esign {...props} />

      {site.sec_plugin && (
        <SecPlugin {...props} />
      )}

      {site.securitySystem?.name && (
        <SecuritySystemOld {...props} />
      )}

      <DangerZone {...props} />
    </>
  );
}
