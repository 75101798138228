import wurd, { WurdText } from 'wurd-react';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import PopupMenu from '../popup-menu';
import SidebarItem from '../sidebar-items';
import SidebarState from '../sidebar-items/state';
import SidebarUser from '../sidebar-items/user';
import Images from './images';
import AssignForm from './forms/assign';

const cms = wurd.block('itemView.summary');
const { sid } = helpers.ui;
const { stateLabelTypes } = helpers.item;


export default function ItemDetailSidebarSummary({
  item,
  hideOwner = false,
  onImageUpdated,
  modal,
  children,
}) {
  function setLocation() {
    const location = window.prompt(cms.text('enterLocation', { itemId: sid(item) }));
    if (location === null || location === item.location) return;

    actions.items.updateState([item.sid], 'setLocation', { location });
  }

  function setOpsTitle() {
    const opsTitle = window.prompt(cms.text('enterOpsTitle', { itemId: sid(item) }));
    if (opsTitle === null || opsTitle === item.opsTitle) return;

    actions.update('items', item.id, { opsTitle });
  }

  function assign() {
    modal.open(
      <AssignForm
        onSubmit={(data) => actions.items.updateState([item.sid], 'assign', data)}
        onSuccess={modal.close}
        closeModal={modal.close}
      />,
    );
  }

  function setStateStorage() {
    const location = window.prompt(cms.text('enterLocation', { itemId: sid(item) }));
    if (!location) return;

    actions.items.updateState([item.sid], 'setState', { state: 'storage', location });
  }

  function setStateUser() {
    actions.items.updateState([item.sid], 'setState', { state: 'user' });
  }

  const { state } = item;
  const itemConfig = helpers.item.get(item.type, true) || {};

  return (
    <div className="list-group">
      <SidebarState type={stateLabelTypes[state]}>
        <div className="pull-right">
          {item.state === 'unassigned' && (
            <WurdText id={`item._states.${state}`} />
          )}

          {item.state !== 'unassigned' && (
            <PopupMenu
              button={<><WurdText id={`item._states.${state}`} /> ▾</>}
              items={[
                { faIcon: 'far fa-building', text: 'In storage', onClick: setStateStorage, bsType: 'success' },
                { faIcon: 'far fa-house', text: 'With customer', onClick: setStateUser, bsType: 'danger' },
              ]}
            />
          )}
        </div>

        <cms.Text id="title" vars={{ sid: item.sid, SID: item.sid }} />
      </SidebarState>

      <SidebarItem
        image={itemConfig.image}
        typeTitle={itemConfig.title}
        itemTitle={item.opsTitle ? item.opsTitle : <cms.Text id="opsTitle.missing" style={{ color: '#999' }} />}
        onClickEdit={setOpsTitle}
      />

      {!hideOwner && item.ownerId && (
        <SidebarUser id={item.ownerId} />
      )}

      <SidebarItem
        typeTitle={<WurdText id="item.location.label" />}
        icon="far fa-search-location"
        itemTitle={item.location || '-'}
        onClickEdit={setLocation}
      />

      <div className="list-group-item">
        <Images
          item={item}
          itemConfig={itemConfig}
          onImageUpdated={onImageUpdated}
        />
      </div>

      {item.state === 'unassigned' && modal && (
        <div className="list-group-item">
          <cms.Object keys="assignBtn">
            <button type="button" onClick={assign} className="btn btn-success btn-block">
              <i className="far fa-fw fa-user-plus" /> {cms.text('assignBtn')}
            </button>
          </cms.Object>
        </div>
      )}

      {children}
    </div>
  );
}
