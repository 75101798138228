import wurd, { WurdText, WurdMarkdown } from 'wurd-react';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import * as helpers from '../helpers';
import * as actions from '../actions';
import { useModal, useItemLoader } from '../hooks';
import { useTasksPlugin } from 'plugins/tasks';

import StateLabel from '../components/state-label';
import DetailPage from '../components/detail-page';
import NotFound from '../components/not-found';
import Spinner from '../components/spinner';
import Menu from '../components/user-detail/menu';
import SidebarSummary from '../components/user-detail/sidebar-summary';
import UserDetails from '../components/user-detail/details';
import UserUnits from '../components/user-detail/units';
import UserItems from '../components/user-detail/items';
import UserValetOrders from '../components/user-detail/valet-orders';
import UserInvoices from '../components/user-detail/invoices';
import UserCharges from '../components/user-detail/charges';
import UserCredits from '../components/user-detail/credits';
import UserFiles from '../components/user-detail/files';
import UserSubscriptionStates from '../components/user-detail/subscription-states';
import History from '../components/user-detail/history';
import Alert from 'components/alert';
import LabelsList from 'plugins/labels/detail';


const cms = wurd.block('userView');

const sectionComponents = {
  details: UserDetails,
  units: UserUnits,
  items: UserItems,
  valetOrders: UserValetOrders,
  invoices: UserInvoices,
  charges: UserCharges,
  credits: UserCredits,
  files: UserFiles,
  subscriptionStates: UserSubscriptionStates,
  history: History,
};


export default function UserDetailPage() {
  const { id, section = 'details' } = useParams();
  const navigate = useNavigate();

  const modal = useModal();

  const { item: user, isLoading, refetch } = useItemLoader('users', id, {
    altIdKey: 'email',
    include: 'customFields,billing,creditsDebits,stats',
    maxAge: 0,
  });
  
  const tasksPlugin = useTasksPlugin({
    modal,
    targetType: 'user',
    targetId: user?.id,
  });

  useEffect(() => {
    // If page was loaded using the email in the URL, redirect using the user ID version to avoid issues when updating user email address etc
    if (isLoading === false && id.includes('@')) {
      navigate(`/users/${user.id}${section ? '/' + section : ''}`, { replace: true });
    }
  }, [isLoading]);

  if (!user) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  function onChange() {
    modal.close();
    return refetch();
  }

  const settings = helpers.settings.get();

  const badges = {
    units: user.stats?.units,
    items: user.stats?.items,
    files: user.files?.length,
  };

  const chargesTotal = helpers.user.charges.getActiveTotal(user);
  if (chargesTotal) {
    badges.charges = helpers.ui.currency(chargesTotal);
  }

  if (user.creditsDebits && user.creditsDebits.total !== 0) {
    badges.credits = helpers.ui.currency(user.creditsDebits.total);
  }

  const sections = [
    ['details', 'far fa-info-circle'],
    ['valetOrders', 'far fa-truck', badges.valetOrders, 'valetStorage'],
    ['items', 'far fa-archive', badges.items, 'valetStorage'],
    ['units', 'far fa-building', badges.units, 'selfStorage'],
    ['invoices', 'far fa-file-invoice-dollar'],
    ['charges', 'far fa-money-bill-alt', badges.charges, 'valetStorage'],
    ['credits', 'far fa-money-bill-alt', badges.credits, 'valetStorage'],
    //['files', 'far fa-file-alt', badges.files],
    ['subscriptionStates', 'far fa-th', undefined, 'valetStorage'],
    ['history', 'far fa-history'],
  ].map(([name, icon, badge, mode]) => {
    if (mode && !settings.modes.includes(mode)) return null;

    return {
      name,
      icon,
      badge,
      mode,
    };
  }).filter(Boolean);

  const SectionComponent = sectionComponents[section];
  if (!SectionComponent) return <NotFound />;

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="admin"
      documentTitle={cms.text('title', { firstName: user.firstName, lastName: user.lastName })}
      title={<cms.Text id="title" vars={user} />}
      subtitle={user.roles.map(role => (
        <StateLabel type="warning" key={role}><WurdText id={`user._roles.${role}`} vars={user} /></StateLabel>
      ))}
      ancestors={[
        { text: wurd.text('userList.title'), url: '/users' },
      ]}
      sections={sections}
      currentSection={section}
      baseUrl={`/users/${user.id}`}
      extra={(
        <>
          <LabelsList
            labels={user.labels}
            settingsLabels={helpers.settings.get().userLabels}
            onChange={labels => actions.users.labels.update(user.id, labels)}
          />

          <Menu
            user={user}
            onChange={onChange}
            wurdKey={cms.id('menu')}
            tasksPlugin={tasksPlugin}
          />
        </>
      )}
      sidebar={(
        <SidebarSummary
          user={user}
        />
      )}
    >
      {user.isDisabled && (
        <Alert type="danger">
          <WurdMarkdown
            id="userView.warning.disabled"
            vars={{ reason: user.disabledReason }}
          />
        </Alert>
      )}

      {user.overdue && (
        <Alert type="danger">
          <WurdMarkdown
            id="userView.warning.overdue"
          />
        </Alert>
      )}

      {!user.emailConfirmed && (
        <Alert type="danger">
          <WurdMarkdown
            id="userView.warning.emailNotConfirmed"
          />
        </Alert>
      )}
      
      <tasksPlugin.TaskList
        listLoader={tasksPlugin.listLoader}
      />

      <SectionComponent
        settings={settings}
        user={user}
        onChange={onChange}
        openModal={modal.open}
        closeModal={modal.close}
        wurdKey={cms.id(section)}
        modal={modal}
      />
    </DetailPage>
  );
}
