import { useEffect } from 'react';
import store from 'store';
import * as actions from 'actions';
import * as helpers from 'helpers';


export const useVars = () => {
  const { emailSettings } = store.get();

  useEffect(() => {
    actions.business.emails.fetch(); // this will populate/refresh store.get('emailSettings')
  }, []);

  const variables = emailSettings?.variables?.unitMoveIn_owner_unitOccupied || [];

  return {
    user: [
      ...variables.filter(k => k.startsWith('user.')),
    ],


    site: [
      'companyName',
      'brand.logo',
      ...variables.filter(k => k.startsWith('site.') || k.startsWith('type.')),
    ],

    rental: [
      ...variables.filter(k => k.startsWith('rental.') || k.startsWith('unit.')),

      'signatureDate',
      'signatureUrl',
      // 'startDate',

      ...variables.filter(k => k.startsWith('invoicePreview.')),
    ],
  };
};


export const getPlaceholder = name => {
  const cfSetting = helpers.settings.getCf(name);
  return cfSetting?.type === 'file' ? `[${helpers.ui.getLangText(cfSetting.title)}]({{${name}.url}})` : `{{${name}}}`;
};
