import { cloneElement } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';


const Wrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',

  label: {
    padding: '0 1rem',
    flex: '1 1 130px',
    display: 'grid',
    gridTemplateColumns: '18px auto',
    gridTemplateRows: 'auto 1fr',
    gap: '0 1rem',
    marginBottom: '1rem',
    fontWeight: 400,
    breakInside: 'avoid-column',

    borderRadius: '4px',
    backgroundColor: '#fff',
    border: '2px solid var(--bs-gray-light)',

    h5: {
      fontWeight: 400,
    },

    '&.active': {
      borderColor: 'var(--bs-primary)',
    },

    '&:hover': {
      filter: 'brightness(.99)',
    },

    input: {
      margin: 4,
      width: '12px',
      '&:not(:checked)': {
        //appearance: 'none'
      }
    },
    '>:not(:first-child,:nth-child(2))': {
      gridColumnStart: 2,
    },
  },
});

export default function FormRadiolist({
  children,
  defaultValue,
  value = defaultValue,
  name,
  onChange,
  disabled = false,
  ...props
}) {
  return (
    <Wrapper {...props}>
      {children.map(item => {
        return (
          <label key={item.value} className={value === item.value ? 'active' : null} style={item.style}>
            <input
              type="radio"
              checked={value === item.value}
              onChange={() => onChange({ target: { name, value: item.value } })}
              disabled={disabled}
            />
            {cloneElement(item.title, { type: 'h5' })}
            {item.description}
          </label>
        );
      })}
    </Wrapper>
  );
}

FormRadiolist.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    title: PropTypes.node,
    description: PropTypes.node,
  })),
}