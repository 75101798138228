import { useHistoryListQuery } from '../../hooks';

import UnitJobList from '../unit-job-list';


export default function SiteUnitJobList({ modal, site }) {
  const listQuery = useHistoryListQuery({
    implicitParams: {
      siteId: site.id,
    },
    initialParams: {
      state: 'active',
      type: 'unit_moveIn,unit_moveOut,unit_transfer,task',
    },
  });

  return (
    <UnitJobList
      listQuery={listQuery}
      modal={modal}
      hideSite
    />
  );
}
