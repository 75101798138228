import wurd from 'wurd-react';
import ModalForm from 'components/form2/modal-form';
import { Field, MultiLangText, CurrencyInput } from 'components/form2';


export default function UnitTypeForm_create(props) {
  return (
    <ModalForm
      {...props}
      wurdSection="unitType"
    >
      {(fieldProps) => (
        <>
          <Field {...fieldProps('title')} required>
            <MultiLangText />
          </Field>

          <Field {...fieldProps('deposit')} type="number" required>
            <CurrencyInput min="0" />
          </Field>

          <Field {...fieldProps('price')} type="number" required>
            <CurrencyInput min="0" />
          </Field>

          <Field {...fieldProps('code')} />
        </>
      )}
    </ModalForm>
  );
}