import wurd from 'wurd-react';

import * as actions from 'actions';
import { currency } from '../../../helpers/ui';

import SectionCrudList from 'components/section_crud-list';
import LangText from 'components/lang-text';
import ItemType from '../item-type';
import ItemForm from './item-form';
import ProductForm from './product-form';
import AreaForm from './area-form';
import PlanForm from './plan-form';


const cms = wurd.block('settings');

export default function AreaSettings({ settings }) {
  return (
    <>
      <SectionCrudList
        cms={cms.block('areas')}
        items={settings.areas}
        Form={AreaForm}
        actions={actions.business.areas}
        onChange={actions.business.fetch}
      >
        {area => (
          <div>
            {area.surcharge > 0 && (
              <div className="pull-right"><cms.Text id="surcharge" vars={{ price: currency(area.surcharge) }} /></div>
            )}
            <LangText obj={area.title} />
            {area.note && (
              <div style={{ color: '#aaa' }}><LangText obj={area.note} /></div>
            )}
          </div>
        )}
      </SectionCrudList>

      <SectionCrudList
        cms={cms.block('plans')}
        items={settings.plans.sort((a, b) => ((a.order || 0) - (b.order || 0)) || a.price - b.price)}
        Form={PlanForm}
        actions={actions.business.plans}
        onChange={actions.business.fetch}
      >
        {plan => (
          <ItemType item={plan} />
        )}
      </SectionCrudList>

      <SectionCrudList
        cms={cms.block('items')}
        items={settings.items.sort((a, b) => (a.price - b.price) || a.type.localeCompare(b.type))}
        Form={ItemForm}
        actions={actions.business.items}
        onChange={actions.business.fetch}
      >
        {itemType => (
          <ItemType item={itemType} />
        )}
      </SectionCrudList>

      <SectionCrudList
        cms={cms.block('products')}
        items={settings.products.sort((a, b) => ((a.order || 0) - (b.order || 0)) || a.slug?.localeCompare(b.slug))}
        Form={ProductForm}
        actions={actions.business.products}
        onChange={actions.business.fetch}
      >
        {productType => (
          <ItemType item={productType} />
        )}
      </SectionCrudList>
    </>
  );
}
