import PropTypes from 'prop-types';
import wurd from 'wurd-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';
import * as jobHelpers from './helpers';

import Modal from '../../modal';

const cms = wurd.block('unitJobs.moveIn.menu');


export default function UnitMoveInJobMenu({ job, modal }) {

  function cancelJob() {
    const confirmed = window.confirm('Are you sure you want to cancel this order?');
    if (!confirmed) return;

    actions.jobs.runOperation(job.id, 'cancel');
  }

  async function downloadAgreement() {
    jobHelpers.downloadAgreement({ job });
  }

  function viewJobData() {
    modal.open(
      <Modal title={job.type} subtitle={job.id}>
        <textarea
          style={{
            display: 'block',
            width: '100%',
            height: 400,
            fontFamily: 'monospace'
          }}
          value={JSON.stringify(job, null, 2)}
          readOnly
        />
      </Modal>,
    );
  }

  const canCancelJob = job.result.orderState === 'submitted';
  const canViewJobData = helpers.auth.hasRole('_support');

  const menuItems = [];

  if (canCancelJob) {
    menuItems.push(
      <MenuItem key="cancelJob" onSelect={cancelJob}>
        <i className="fas fa-fw fa-times" /> {cms.text('cancelJob')}
      </MenuItem>
    );
  }

  menuItems.push(
    <MenuItem key="downloadAgreement" onSelect={downloadAgreement}>
      <i className="fas fa-fw fa-file-contract" /> {cms.text('downloadAgreement')}
    </MenuItem>
  );

  if (canViewJobData) {
    menuItems.push(
      <MenuItem key="viewJobData" onSelect={viewJobData}>
        <em className="text-muted">
          <i className="fas fa-fw fa-code" /> View job data
        </em>
      </MenuItem>
    );
  }

  if (!menuItems.length) return null;

  return (
    <cms.Object keys="editJob,cancelJob,downloadAgreement">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}

UnitMoveInJobMenu.propTypes = {
  job: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};
