import wurd from 'wurd-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as actions from '../../actions';
import * as helpers from '../../helpers';

const cms = wurd.block('siteView.menu');


export default function SiteDetailsMenu({ unitType, modal, onChange }) {
  const navigate = useNavigate();

  function deleteUnitType() {
    if (!window.confirm(cms.text('deleteUnitType.confirm', unitType))) return;

    return actions.del('unit-types', unitType.id)
      .then(() => {
        navigate(`/sites/${unitType.siteId}/unit-types`);
        onChange();
      });
  }

  return (
    <cms.Object keys="deleteUnitType.title,deleteUnitType.confirm">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="siteMenu"
        bsStyle="link"
        pullRight
        noCaret
      >
        <MenuItem key="delete" onSelect={deleteUnitType}>
          <i className="fas fa-fw fa-times" /> {cms.text('deleteUnitType.title', { title: helpers.ui.getLangText(unitType.title) })}
        </MenuItem>
      </DropdownButton>
    </cms.Object>
  );
}
