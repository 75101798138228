function toBase64(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  })
}


export default async function processData(customFields, cfSettings) {
  const processedEntries = await Promise.all(
    Object.entries(customFields).map(async ([k, v]) => {
      const cfSetting = cfSettings.find(o => o.code === k);

      if (v?.url?.startsWith('blob:') && v.file) { // blob file needing to be uploaded
        const data = await toBase64(v.file);
        return [k, { name: v.file.name, data }];
      }

      if (v === '') {
        return [k, null];
      }

      if (cfSetting?.type === 'number' || cfSetting?.type === 'integer') {
        return [k, +v];
      }

      return [k, v];
    })
  );
  return Object.fromEntries(processedEntries);
}
