import { useState } from 'react';
import { WurdText } from 'wurd-react';

import * as helpers from '../helpers';
import { flag } from '../utils/language';

import Section from './section';
import Tabs from './tabs';
import Markdown from './markdown';
import DynamicLoader from 'components/dynamic-loader';


const CodeEditor = DynamicLoader(() => import('components/code-editor'));


export default function MultiLangMarkdownEditor({
  cms,
  content: initialContent,
  onChange,
  canEdit = true,
}) {
  const [editMode, setEditMode] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [content, setContent] = useState(initialContent);
  const langs = helpers.settings.langs();

  function toggleEditMode() {
    setEditMode(!editMode);
  }

  function updateContent(obj) {
    setContent({ ...content, ...obj });

    setHasChanged(true);
  }

  async function save() {
    if (!hasChanged) {
      toggleEditMode();
      return;
    }

    await onChange(content);

    toggleEditMode();
  }


  return (
    <Section
      cms={cms}
      onClickEdit={canEdit && !editMode && toggleEditMode}
      extra={editMode && (
        <button
          type="button"
          className="btn btn-link"
          onClick={save}
        >
          <i className="far fa-fw fa-check" title="Save" />
        </button>
      )}
    >
      <Tabs
        items={langs.map((lang) => ({
          id: lang,
          title: <span>{flag(lang)} <WurdText id={`common.languages.${lang}`} /></span>,
          renderContent: () => {
            if (editMode) {
              return (
                <CodeEditor
                  value={content[lang]}
                  onChange={e => updateContent({ [lang]: e.target.value })}
                />
              );
            }

            return (
              <div style={{ marginTop: 8 }}>
                {content[lang] ? (
                  <Markdown className="markdown-editor__viewer">{content[lang]}</Markdown>
                ) : (
                  <cms.Markdown id="placeholder" style={{ color: '#999', textAlign: 'center', margin: '20px 0' }} />
                )}
              </div>
            );
          },
        }))}
      />
    </Section>
  );
}
