import { useEffect } from 'react';
import { useQuery } from 'react-query';
import wurd from 'wurd-react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import * as actions from 'actions';
import * as helpers from 'helpers';
import Section from 'components/section';
import Page from './page';
import SimpleNavList from 'components/simple-nav-list';
import Empty from 'components/empty';
import StateLabel from 'components/state-label';
import Spinner from 'components/spinner';
import MetaForm from './meta';


const cms = wurd.block('website');


export default function ({ modal }) {
  useEffect(() => {
    wurd.load(['website']);
  }, []);

  const { sectionId } = useParams();

  const {
    data: pages,
    isLoading,
    refetch,
  } = useQuery('website', () => actions.business.website.fetch());

  const navigate = useNavigate();

  if (sectionId) return <Page path={sectionId === '-' ? '' : sectionId} modal={modal} pages={pages} fetchPages={refetch} />;

  function addPage() {
    modal.open(
      <MetaForm
        onSubmit={async ({ path: newPath, meta }) => {
          if (pages.some(p => p.path === newPath)) return navigate(`/settings/website/${newPath || '-'}`);

          return actions.business.website.update(newPath, { enabled: false, meta })
            .then(() => navigate(`/settings/website/${newPath || '-'}`))
            .then(refetch);
        }}
        closeModal={modal.close}
        mode="create"
      />
    );
  }

  const pagesWithDefault = pages?.length === 0
    ? [{ path: '', meta: { title: cms.text('page.meta.title.placeholder', { companyName: helpers.settings.get()?.companyName }) } }]
    : pages;

  return (
    <Section cms={cms} onClickAdd={addPage}>
      <SimpleNavList>
        {pagesWithDefault?.map(page => {
          return (
            <Link key={page.path} role="row" to={`/settings/website/${encodeURIComponent(page.path || '-')}`}>
              <div role="cell">
                <strong>/{page.path ? 'p/' + page.path : page.path}</strong>
                {(sessionStorage.getItem('website-' + page.path) || !pages?.length) ? <sup style={{ opacity: .7 }}>*</sup> : null}
              </div>
              <div role="cell" className="hidden-xs">
                {helpers.ui.getLangText(page.meta?.title) || <cms.Text id="page.meta.title.empty" className="text-muted" />}
              </div>
              <div role="cell" className="text-right" style={{ paddingTop: '1rem', paddingBottom: '1.5rem' }}>
                <a
                  href={page.enabled ? `${location.origin}/${page.path ? 'p/' + page.path : page.path}` : null}
                  target="_blank"
                  className="btn-link"
                  onClick={e => e.stopPropagation()}
                >
                  <StateLabel type={page.enabled ? 'success' : 'secondary'} style={{ marginBottom: 0 }}>
                    <i className={`fal ${page.enabled ? 'fa-external-link' : 'fa-ban'}`} style={{ marginRight: '.6rem' }} />
                    <cms.Text id={`page.enabled.${!!page.enabled}`} />
                  </StateLabel>
                </a>
              </div>
            </Link>
          );
        })}
      </SimpleNavList>

      {isLoading ? <Spinner /> : !pages?.length && <Empty cms={cms.block('empty')} onClickAdd={addPage} />}
    </Section>
  )
}
