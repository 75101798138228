import LangText from 'components/lang-text';
import { Fragment } from 'react';


export default function SiteProductsField({ products, name, value = {}/*{[productId]: qty}*/, onChange }) {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr 50px', gridGap: '6px 12px', alignItems: 'center', marginLeft: '34%', marginTop: 6 }}>
      {products.map(product => {
        const max = Math.min(product.maxQuantity, product.type === 'movein' && product.trackInventory ? product.inventory : Infinity);

        return (
          <Fragment key={product.id}>
            <img src={product.image} style={{ width: 50, height: 50, objectFit: 'cover' }} />
            <strong><LangText obj={product.title} /></strong>
            <span className="text-muted"><LangText obj={product.displayPrice} /></span>
            <input
              type={max <= 0 ? 'text' : 'number'} step="1" min="0" max={Number.isFinite(max) ? max : null}
              style={{ fontSize: 16, paddingLeft: 4, height: 32 }}
              readOnly={max <= 0}
              disabled={max <= 0}
              value={max <= 0 ? 'N/A' : value[product.id] || 0}
              onChange={e => {
                onChange({
                  target: {
                    name,
                    value: { ...value, [product.id]: +e.target.value || 0 }
                  }
                });
              }}
            />
          </Fragment>
        );
      })}
    </div>
  );
}
