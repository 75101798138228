import { WurdText } from 'wurd-react';

import { flag } from '../../utils/language';
import * as helpers from '../../helpers';

import Tabs from '../tabs';
import DynamicLoader from '../dynamic-loader';


const CodeEditor = DynamicLoader(() => import('components/code-editor'));


export default function MultiLangEditor({ name, value = {}, onChange, langs = helpers.settings.langs() }) {
  if (langs.length === 1) {
    return (
      <CodeEditor
        value={value[langs[0]]}
        onChange={e => onChange({ target: { name, value: { ...value, [langs[0]]: e.target.value } } })}
      />
    );
  }

  return (
    <Tabs
      style={{ position: 'static' }}
      items={langs.map((lang) => ({
        id: lang,
        title: <span>{flag(lang)} <WurdText id={`common.languages.${lang}`} /></span>,
        renderContent: () => (
          <CodeEditor
            value={value[lang]}
            onChange={e => onChange({ target: { name, value: { ...value, [lang]: e.target.value } } })}
          />
        )
      }))}
    />
  );
}
