import wurd from 'wurd-react';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';
import { useSimpleListQuery } from '../../../hooks';

import ItemsLoader from '../../loaders/items';
import Section from '../../section';
import ItemListPickerModal from '../../item-list/picker-modal';
import StaticItemList from '../../item-list/static-list';
import ItemType from '../../item-type';
import ProductType from '../../product-type';
import OwnerLocation from './owner-location';
import EditButton from '../../edit-button';
import CustomFieldsList from 'plugins/custom-fields/list';
import CustomFieldsEditModal from 'plugins/custom-fields/modal';


const formatDate = helpers.ui.date;
const { formatTimeslot } = helpers.valetOrder;

const cms = wurd.block('valetOrder.summary');


export default function ValetOrderSummary({
  valetOrder,
  modal,
  fetchValetOrder,
}) {
  const itemListQuery_deliver = useSimpleListQuery({
    initialParams: {
      state: 'storage',
      ownerId: valetOrder.ownerId,
    },
  });

  const itemListQuery_collect = useSimpleListQuery({
    initialParams: {
      state: 'user',
      ownerId: valetOrder.ownerId,
    },
  });

  function renderHeader() {
    const appointments = helpers.valetOrder.getAppointments(valetOrder);
  
    return (
      <h3>
        {appointments.map((appointment, index) => (
          <div key={index}>
            {appointment.title && (
              <small>{appointment.title}: </small>
            )}
            {(appointment.text
              ? <small>{appointment.text}</small>
              : <span>{formatDate(appointment.date)} <small>{formatTimeslot(appointment.timeslot)}</small></span>
            )}
          </div>
        ))}
      </h3>
    );
  }

  function renderEmptyBoxes() {
    if (!valetOrder.boxCounts) return null;
  
    const boxOrders = Object.entries(valetOrder.boxCounts).reduce((memo, [type, quantity]) => (
      quantity ? memo.concat({ type, quantity }) : memo
    ), []);
  
    if (!boxOrders || !boxOrders.length) return null;
  
    return (
      <div className="block-flat">
        <div className="header">
          <h3><cms.Text id="boxes.title" /></h3>
        </div>
  
        <div className="content clearfix">
          <ul className="list-group">
            {boxOrders.map(({ type, quantity }) => (
              <ItemType
                key={type}
                type={type}
                quantity={quantity}
                className="list-group-item"
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }
  
  function renderProducts() {
    if (!valetOrder.productCounts) return null;
  
    const productOrders = Object.entries(valetOrder.productCounts).reduce((memo, [id, quantity]) => (
      quantity ? memo.concat({ id, quantity }) : memo
    ), []);
  
    if (!productOrders || !productOrders.length) return null;
  
    return (
      <div className="block-flat">
        <div className="header">
          <h3><cms.Text id="products.title" /></h3>
        </div>
  
        <div className="content clearfix">
          {productOrders.map(({ id, quantity }) => (
            <ProductType
              key={id}
              id={id}
              quantity={quantity}
              className="list-group-item"
            />
          ))}
        </div>
      </div>
    );
  }
  
  function renderDeliverItems() {
    const itemIds = valetOrder.deliverItems;
    
    return (
      <Section
        cms={cms.block('deliverItems')}
        onClickEdit={pickItemsToDeliver}
      >
        {itemIds && itemIds.length && (
          <ItemsLoader ids={itemIds}>
            {(items) => (
              <StaticItemList items={items} hideOwner hideValetOrder />
            )}
          </ItemsLoader>
        )}
      </Section>
    );
  }
  
  function renderCollectItems() {
    const itemIds = valetOrder.collectItems;
  
    return (
      <Section
        cms={cms.block('collectItems')}
        onClickEdit={pickItemsToCollect}
      >
        {itemIds && itemIds.length && (
          <ItemsLoader ids={itemIds}>
            {(items) => (
              <StaticItemList items={items} hideOwner hideValetOrder />
            )}
          </ItemsLoader>
        )}
      </Section>
    );
  }

  function pickItems(listName) {
    const selectedIds = valetOrder[listName];

    modal.open(
      <ItemListPickerModal
        selectedIds={selectedIds}
        initialParams={{
          limit: 1000,
          ownerId: valetOrder.ownerId,
          state: (listName === 'deliverItems') ? 'storage' : 'user',
        }}
        hideOwner
        onCancelSelection={modal.close}
        renderBulkActions={function ({ selectedItems }) {
          function updateItems() {
            actions.valetOrders.update(valetOrder.id, {
              [listName]: selectedItems.map(item => item.id),
            });

            modal.close();
          }

          return (
            <button
              type="button"
              className="btn btn-flat btn-primary pull-right"
              onClick={updateItems}
            >
              <cms.Text id={`${listName}.pickSelectedBtn`} />
            </button>
          );
        }}
      />
    );
  }
  
  function pickItemsToDeliver() {
    pickItems('deliverItems');
  }
  
  function pickItemsToCollect() {
    pickItems('collectItems');
  }

  function editCustomFields() {
    modal.open(
      <CustomFieldsEditModal
        modelName="valetOrder"
        model={valetOrder}
        modal={modal}
        onChange={fetchValetOrder}
      />
    );
  }

  return (
    <div style={{ opacity: valetOrder.state === 'cancelled' && 0.5 }}>
      <div className="block-flat">
        <div className="header">
          {renderHeader(valetOrder)}
        </div>

        <div className="content clearfix">
          <OwnerLocation valetOrder={valetOrder} />

          <hr style={{ marginBottom: 15 }} />
          {/* <h4 id="customFields"><cms.Text id="customFields.title" /></h4> */}
          <CustomFieldsList modelName="valetOrder" model={valetOrder}>
            <EditButton onClick={editCustomFields} />
          </CustomFieldsList>

          {valetOrder.promoCode && (
            <div><strong>Promo code:</strong> {valetOrder.promoCode}</div>
          )}
        </div>
      </div>

      {renderEmptyBoxes()}

      {renderProducts()}

      {renderDeliverItems()}

      {renderCollectItems()}
    </div>
  );
}
