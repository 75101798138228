/**
 * A wrapper around react-bootstraps' Input, which highlights errors on change and on blur
 *
 * TODO replace by form2/field
 */
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import Secret from './form2/secret';
import Markdown from './markdown';
import styled from '@emotion/styled';


const CheckboxContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '0 1rem ',
  'input[type="checkbox"]': {
    width: 14,
    height: 14,
    marginTop: 8,
  },
  '.help-block': {
    gridColumnStart: 2,
    marginBottom: 0,
  }
});

export default function AppInput({
  include = true, label, help, error, wrapperClassName = '', labelClassName = '',
  type, name, defaultValue, value = defaultValue, onChange, children, ...props
}) {
  if (!include) return null;

  function handleChange(evt) {
    // if the evt.target is a select[multiple], patch evt.value to be an array of selected values
    // by default select[multiple].value is a string with the first selected value
    if (evt.target?.selectedOptions && evt.target.multiple) {
      onChange({
        target: {
          name: evt.target.name,
          value: [...evt.target.selectedOptions].map(o => o.value),
          selectedOptions: evt.target.selectedOptions
        }
      });
    } else {
      onChange({
        target: {
          name: evt.target.name,
          value: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value,
        }
      });
    }
  }

  const Wrapper = type === 'checkbox' ? CheckboxContainer : 'div';

  return (
    <FormGroup controlId={type === 'secret' ? undefined : name}>
      <ControlLabel className={labelClassName} {...type === 'secret' && { htmlFor: null }}>{label || name}</ControlLabel>
      <Wrapper className={wrapperClassName}>
        <FormControl
          componentClass={type === 'select' || type === 'textarea' || typeof type !== 'string' ? type : type === 'secret' ? Secret : 'input'}
          type={type === 'select' || type === 'textarea' ? undefined : type || 'text'}
          name={name}
          {...type === 'checkbox' ? { checked: value ?? false } : { value }}
          onChange={handleChange}
          {...props}
        >
          {children}
        </FormControl>
        {help && <Markdown as={HelpBlock} inline>{help}</Markdown>}
        {error && <HelpBlock className="text-danger">{error}</HelpBlock>}
      </Wrapper>
    </FormGroup>
  );
}
