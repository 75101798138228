import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import wurd from 'wurd-react';
import styled from '@emotion/styled';

import Alert from './alert';
import Empty from './empty';

const Container = styled.div`
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 40px;

  .header {
    border-bottom: 1px solid #DFDFDF;
    .title {
      flex: 1;
      margin-top: 0;
      color: var(--bs-black);

      a {
        color: inherit;
      }
    }

    .extra {
      display: flex;
      margin-right: -10px;
      align-items: center;

      .btn {
        margin-bottom: 0!important;
      }
      .pull-right {
        order: 1;
      }
    }

  }

  .header-content {
    display: flex;
    align-items: center;
    padding: 12px 24px;
  }

  .content {
    padding: 24px 24px 16px;

    ::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .title {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 18px;
  }

  .btn i {
    margin-right: 0px;
  }
`

const ContainerExtra = styled.div`
  button, a {
    border-radius: 2px;
    background: var(--White, #FFF);
  }
`

export default function Section({
  cms,
  onClickEdit = null, // Pass a function to add an edit button
  onClickAdd = null, // Pass a function to add an add button
  extra = null, // Extra content, like custom btn-link buttons
  children = null, // If no children are passed, 'empty state' text will be displayed
  link,
  title,
}) {
  const helpUrl = cms.text('helpUrl');
  const info = cms.text('info');
  const warning = cms.text('warning');
  const id = cms.id().split('.').slice(-1)[0]; 

  useEffect(() => {
    if (location.hash === '#' + id) {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Container>
      <div className="header">
        <div className="header-content">
          <h3 className="title" id={id}>
            {title || <cms.Text id="title" type={link !== null ? Link : 'span'} to={link !== undefined ? link : `#${id}`} />}
          </h3>

          <div className="extra">
            {extra}

            {onClickEdit && (
              <button
                type="button"
                className="btn btn-link"
                onClick={onClickEdit}
              >
                <i className="far fa-fw fa-edit" />
              </button>
            )}

            {onClickAdd && (
              <button
                type="button"
                className="btn btn-link"
                onClick={onClickAdd}
              >
                <i className="far fa-fw fa-plus" />
              </button>
            )}

            {helpUrl && (
              <cms.Object keys="helpUrl">
                <a
                  className="btn btn-link hidden-print"
                  href={helpUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="far fa-fw fa-question-circle" title={wurd.text('common.phrases.help')} />
                </a>
              </cms.Object>
            )}
          </div>
        </div>
      </div>
      <div className="content">
        {children ? (
          <>
            {info && (
              <Alert type="info"><cms.Markdown id="info" /></Alert>
            )}

            {warning && (
              <Alert type="warning"><cms.Markdown id="warning" /></Alert>
            )}

            {children}
          </>
        ) : (
          cms.text('empty.text') && ( // todo maybe reuse components/no-results
            <Empty
              cms={cms.block('empty')}
              onClickAdd={onClickAdd}
            />
          )
        )}
      </div>
    </Container>
  );
}
