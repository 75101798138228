/**
 * A simple form component
 * 
 * Takes inputs  as children and form value as a prop object.
 * 
 * Works with 'native' HTML inputs, React Bootstrap input and custom inputs
 * Custom inputs should have a "name" prop and use the passed "onChange" prop callback, passing the new value
 *
 * Forms can be nested to enable 'objects' in forms.
 */
import { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'


export default class AppForm extends Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    onChange: PropTypes.func,
    validate: PropTypes.object, // name: function
  };

  state = this.getFormState(this.props.defaultValue || this.props.value);

  getFormState(values = {}) {
    const state = {};

    // initialize values in state from children
    Children.forEach(this.props.children, child => {
      if (!child || !child.props) return;

      const { name, defaultValue } = child.props;

      if (!name) return;

      state[name] = values[name] || defaultValue;
    });

    return state;
  }

  /**
 * Updates the form's value for an input/form control when it is updated.
 * Supports 'native' inputs, selects etc. via the event.
 * For custom form controls, expects the value directly.
 *
 * @param {String} name
 * @param {SyntheticEvent|Mixed} eventOrValue
 * @param {Boolean} [isValid]
 */
  onChange(name, eventOrValue, isValid) {
    let value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;

    if (eventOrValue.currentTarget && eventOrValue.currentTarget.multiple) {
      value = [...eventOrValue.currentTarget.selectedOptions].map(o => o.value);
    }

    this.setState({
      [name]: value
    }, () => {
      //Output form value if there is an onChange callback
      //This also enables nested forms to work
      if (this.props.onChange) {
        this.props.onChange(this.getValue(), this.isValid());
      }
    });
  }

  // @public exposed to parent components
  isValid() {
    const { validate = {} } = this.props;

    var foundInvalid = _.find(this.state, (value, name) => validate[name] && validate[name](value));

    return !foundInvalid;
  }

  // @public  exposed to parent components
  getValue() {
    const { children } = this.props;
    let result = {};

    //Gather only values which there is an input for
    Children.forEach(children, child => {
      var name = child && child.props.name;
      if (!name) return;

      result[name] = this.state[name];
    });

    return result;
  }

  render() {
    const { children, onChange, validate, defaultValue, ...props } = this.props;

    //NOTE: A <div> is used instead of a <form> because form elements cannot be nested.
    return (
      <div {...props}>
        {Children.map(children, child => {
          if (!child || !child.props || !child.props.name) return child;

          const value = this.state[child.props.name] || child.props.defaultValue || '';

          return cloneElement(child, {
            onChange: (...args) => {
              this.onChange(child.props.name, ...args);
              if (onChange) onChange(...args);
            },
            value,
            defaultValue: undefined,
          });
        })}
      </div>
    );
  }
}


export function Fieldset({ children, name, value, defaultValue, onChange, component: Component = 'fieldset', ...props }) {
  return (
    <Component {...props}>
      {Children.map(children, child => {
        return cloneElement(child, {
          onChange(eventOrValue) {
            let v = eventOrValue.target ? eventOrValue.target.value : eventOrValue;

            if (eventOrValue.currentTarget && eventOrValue.currentTarget.multiple) {
              v = [...eventOrValue.currentTarget.selectedOptions].map(o => o.value);
            }

            onChange({ ...value, [child.props.name]: v });
          },
          value: value[child.props.name],
        });
      })}
    </Component>
  )
}
