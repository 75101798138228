import { useParams } from 'react-router-dom';
import { useModal, useItemLoader, useListLoader } from '../hooks';

import ValetOrderDetail from '../components/valet-order-detail';
import Spinner from '../components/spinner';
import NotFound from '../components/not-found';


export default function ValetOrderDetailPage() {
  const { id, section = 'steps' } = useParams();

  const modal = useModal();

  const { item: valetOrder, isLoading, refetch: fetchValetOrder } = useItemLoader('valet-orders', id, {
    altIdKey: 'sid',
    include: 'job,customFields',
    maxAge: 0,
  });

  const { items, refetch: fetchItems } = useListLoader('items', valetOrder && {
    valetOrderId: valetOrder.id,
    limit: 500,
  }, { maxAge: 0 });

  if (!valetOrder) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  return (
    <ValetOrderDetail
      modal={modal}
      valetOrder={valetOrder}
      section={section}
      items={items}
      fetchValetOrder={fetchValetOrder}
      fetchItems={fetchItems}
    />
  );
}
