/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import classnames from 'classnames';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import wurd, { WurdObject } from 'wurd-react';
import OrderedItems from './pick_ordered';
import PickedItems from './pick_added';

const cms = wurd.block('visitView.pick')
const { hasRole } = helpers.auth;
const { k, t } = helpers.cms.getters('visitView.pick');


const PickStep = createClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired
  },

  render() {
    const { visit } = this.props;

    if (!helpers.visit.needsPickStep(visit)) return null;

    let step = 'wait';

    return (
      <section className="panel">
        <div
          className={classnames('panel-heading', {
            success: helpers.visit.isStepComplete(visit, step)
          })}
        >
          <h4 className="panel-title">
            <a className={classnames({ collapsed: helpers.visit.isFutureStep(visit, step) })}>
              <WurdObject id={k()} keys="title,addBtn,enterBoxId,confirmRemoveStoreItem,nextBtn">
                {t('title')}
              </WurdObject>
            </a>
          </h4>
        </div>
        {helpers.visit.isActiveStep(visit, step) && hasRole('operations') && this.renderMain()}
      </section>
    );
  },

  renderMain() {
    const { visit } = this.props;

    const childProps = Object.assign({ k, t }, this.props);

    const hasAddedAllEmptyBoxes = helpers.visit.hasAddedAllEmptyBoxes(visit);

    return (
      <div className="panel-body">
        <h5>Ordered items</h5>
        <OrderedItems {...childProps} />

        <h5>{cms.text('pickedItems')}</h5>
        <PickedItems {...childProps} />

        <button
          type="button"
          className={classnames('btn btn-block', {
            'btn-warning': !hasAddedAllEmptyBoxes,
            'btn-success': hasAddedAllEmptyBoxes
          })}
          disabled={!hasAddedAllEmptyBoxes}
          onClick={this.completeStep}
        >
          <i className="fa fa-check" />
          {t('nextBtn')}
        </button>
      </div>
    );
  },

  /*isActive() {
    return helpers.visit.isActiveStep(this.props.visit, 'wait');
  },

  isComplete() {
    return helpers.visit.isStepComplete(this.props.visit, 'wait');
  },*/

  completeStep() {
    var { visit, onChange } = this.props;

    var ok;
    if (!helpers.visit.hasAddedAllEmptyBoxes(visit) || !helpers.visit.hasAddedAllProducts(visit)) {
      ok = window.confirm(t('confirmIncompleteOrder'));
    } else {
      ok = true;
    }

    if (!ok) return;

    actions.visits.update(visit.id, { state: 'picked' })
      .then(onChange);
  }

});

export default PickStep;
