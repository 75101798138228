import { Component } from 'react';
import PropTypes from 'prop-types';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import { DropdownButton, MenuItem } from 'react-bootstrap';
import { WurdObject } from 'wurd-react';

const wurdKey = 'visitView.menu';
const { t } = helpers.cms.getters(wurdKey);
const { hasRole } = helpers.auth;


export default class VisitDetailMenu extends Component {

  constructor(props) {
    super(props);

    [
      'canCancel',
      'cancel'
    ].forEach(fnName => this[fnName] = this[fnName].bind(this));
  }

  render() {
    const { visit } = this.props;

    if (!this.canCancel(visit)) return null;

    return (
      <WurdObject id={wurdKey} keys="cancel,confirmCancel">
        <DropdownButton
          title={<i className="fa fa-ellipsis-v" />}
          id="itemMenu"
          bsStyle="link"
          pullRight
          noCaret
        >
          <MenuItem onSelect={this.cancel}><i className="fa fa-trash fa-fw" /> {t('cancel')}</MenuItem>
        </DropdownButton>
      </WurdObject>
    );
  }

  canCancel(visit) {
    if (!visit) return false;

    if (!hasRole('admin')) return false;

    return visit.state === 'wait';
  }

  cancel() {
    const { visit, onChange } = this.props;

    if (!window.confirm(t('confirmCancel'))) return;

    actions.visits.update(visit.id, { state: 'cancelled' })
      .then(onChange)
  }

};

VisitDetailMenu.propTypes = {
  visit: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
