import styled from '@emotion/styled';


const Textarea = styled('textarea')({
  display: 'block',
  width: '100%',
  height: 'calc(100vh - 100px)',
  fontFamily: 'monospace',
});


export default function JobDetail(job) {
  return (
    <Textarea
      value={JSON.stringify(job, null, 2)}
      readOnly
    />
  )
}
