import moment from 'moment-timezone';

import ModalForm from 'components/form2/modal-form';
import { Field, MultiLangText, DatePicker, CurrencyInput } from 'components/form2';
import AccountingCodeSelect from 'components/form2/accounting-code-select';

const wurdSection = 'chargesPlugin.form';


export default function UnitChargeForm({ fields, ...rest }) {
  const initialValue = {
    startDate: moment().format('YYYY-MM-DD'),
    ...rest.initialValue,
  };

  return (
    <ModalForm
      wurdSection={wurdSection}
      mode="create"
      initialValue={initialValue}
      {...rest}
    >
      {(getProps) => (
        <>
          <Field {...getProps('title')} required>
            <MultiLangText autoFocus />
          </Field>

          <Field {...getProps('amount')} type="number" required>
            <CurrencyInput min={null} />
          </Field>

          <Field {...getProps('qty')} type="number" min="1" required defaultValue={1} />

          <Field {...getProps('startDate')} required>
            <DatePicker />
          </Field>

          <Field {...getProps('endDate')}>
            <DatePicker />
          </Field>

          <Field {...getProps('accountingCodeId')}>
            <AccountingCodeSelect />
          </Field>
        </>
      )}
    </ModalForm>
  );
}
