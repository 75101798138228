import wurd from 'wurd-react';

import * as helpers from '../helpers';
import { useModal, useHistoryListQuery } from '../hooks';

import Page from '../components/page';
import ItemList from '../components/item-list';

const cms = wurd.block('itemList');

const initialParams = {
  sort: 'updated',
};

export default function ItemsPage() {
  const modal = useModal();
  const listQuery = useHistoryListQuery({ initialParams });

  return (
    <Page
      titleKey={cms.id('title')}
      userRole="operations"
      modal={modal}
    >
      <ItemList
        listQuery={listQuery}
        modal={modal}
      />
    </Page>
  );
}
