import { Link } from 'react-router-dom';

import * as helpers from '../../helpers';

import SimpleNavList from '../simple-nav-list';
import Item from './item';

const styles = {
  tr: {
    cursor: 'pointer',
  },
};


export default function StaticItemList({ items = [], hideOwner, hideValetOrder }) {
  const itemConfigs = helpers.item.byType(true);
  const stateTitles = helpers.item.getStateTitles();

  return (
    <SimpleNavList>
      {items.map((item) => (
        <Link
          role="row"
          key={item.id}
          to={`/items/${item.sid.toUpperCase()}`}
          style={styles.tr}
        >
          <Item
            item={item}
            itemConfigs={itemConfigs}
            stateTitles={stateTitles}
            hideOwner={hideOwner}
            hideValetOrder={hideValetOrder}
          />
        </Link>
      ))}
    </SimpleNavList>
  );
}
