import wurd from 'wurd-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as actions from '../../actions';
import * as helpers from '../../helpers';

import AssignForm from './forms/assign';
import UpdateTypeForm from './forms/update-type';

const cms = wurd.block('itemView');
const { hasRole } = helpers.auth;


export default function ItemDetailMenu({
  item,
  valetOrder,
  modal,
}) {
  const navigate = useNavigate();
  
  function canAssign() {
    return item.state === 'unassigned';
  }

  function assign() {
    modal.open(
      <AssignForm
        onSubmit={(data) => actions.items.updateState([item.sid], 'assign', data)}
        onSuccess={modal.close}
        closeModal={modal.close}
      />,
    );
  }

  function canUnassign() {
    if (item.state === 'unassigned') return false;

    if (helpers.item.isBulky(item.type)) return false;

    return true;
  }

  function unassign() {
    if (!window.confirm(cms.text('menu.confirmUnassign'))) return;

    actions.items.updateState([item.sid], 'unassign');
  }

  function canDeleteItem() {
    if (!hasRole('admin')) return false;

    return helpers.item.isBulky(item.type) && item.state === 'user';
  }

  function deleteItem() {
    if (!window.confirm(cms.text('menu.confirmDelete'))) return;

    actions.items.delete(item.id)
      .then(() => {
        navigate('/items');
      });
  }

  function canUpdateType() {
    return hasRole('manager') && item.state === 'unassigned';
  }

  function updateType() {
    modal.open(
      <UpdateTypeForm
        onSubmit={(data) => actions.items.update(item.id, data)}
        initialValue={{ type: item.type }}
        onSuccess={modal.close}
        closeModal={modal.close}
      />
    )
  }

  function getMenuItems() {
    const menuItems = [];

    if (canAssign()) {
      menuItems.push(<MenuItem key="assign" onSelect={assign}><i className="far fa-fw fa-user-plus" /> {cms.text('menu.assign')}</MenuItem>);
    }

    if (canUnassign()) {
      menuItems.push(<MenuItem key="unassign" onSelect={unassign}><i className="far fa-fw fa-user-times" /> {cms.text('menu.unassign')}</MenuItem>);
    }

    if (canUpdateType()) {
      menuItems.push(<MenuItem key="update-type" onSelect={updateType}><i className="far fa-fw fa-pencil" /> {cms.text('menu.updateType')}</MenuItem>);
    }

    if (canDeleteItem(item)) {
      menuItems.push(<MenuItem key="delete" onSelect={deleteItem}><i className="far fa-fw fa-trash" /> {cms.text('menu.delete')}</MenuItem>);
    }

    return menuItems;
  }

  const menuItems = getMenuItems();
  if (!menuItems.length) return null;

  return (
    <cms.Object id="menu" keys="store,assign,updateType,unassign,confirmUnassig,delete,confirmDelete">
      <DropdownButton
        title={<i className="fas fa-ellipsis-v" />}
        id="itemMenu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}
