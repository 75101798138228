import { Component } from 'react';
import PropTypes from 'prop-types';

import Alert from '../alert';
import ApiButton from '../api-button';


export default class ConfirmAction extends Component {

  static propTypes = {
    mustConfirm: PropTypes.bool.isRequired,
    cms: PropTypes.object.isRequired,
    alertBtn: PropTypes.shape({ onClick: PropTypes.func, text: PropTypes.node }),
    children: PropTypes.node.isRequired,
  };

  state = {
    hasConfirmed: false
  };

  render() {
    const { cms, mustConfirm, alertBtn, children } = this.props;
    const { hasConfirmed } = this.state;

    if (!mustConfirm) {
      return children;
    }

    return (
      <div className="panel-body">
        <Alert type="warning">
          <cms.Text id="message" />
          {alertBtn && (
            <ApiButton
              onClick={alertBtn.onClick}
              bsType="link"
              className="btn-sm mb-0 pull-right"
              style={{ width: 'auto' }}
            >
              {alertBtn.text}
            </ApiButton>
          )}
        </Alert>

        {hasConfirmed
          ? children
          : (
            <button
              type="button"
              className="btn btn-warning btn-block"
              onClick={this.confirm}
              tabIndex={0}
            >
              <cms.Text id="button" />
            </button>
          )
        }
      </div>
    );
  }

  confirm = () => {
    this.setState({ hasConfirmed: true });
  }

}
