import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import wurd from 'wurd-react';

import * as actions from '../../actions';
import { currency, datePeriod, getLangText } from '../../helpers/ui';

import Section from '../section';
import ChargesMenuButton from '../menu-button_charges';
import InvoiceItemForm from '../form/invoice/item';

const cms = wurd.block('invoiceView.items');


const Label = ({ id, hide }) => (
  <cms.Text
    type="th"
    id={id}
    className={hide ? 'hidden-xs hidden-sm' : ''}
    style={{ color: hide ? '#bbb' : '' }}
  />
);


// extract patterns like invoice#sid from desc and render them as links
export function ItemDescription({ children: desc }) {
  const matches = [...desc.matchAll(/(invoice|item|unit|unit-rental|user)#([A-Z0-9]+)/g)];

  const newChildren = [desc.slice(0, matches[0]?.index)];

  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    newChildren.push(<Link to={`/${match[1]}s/${match[2]}`} onClick={e => e.stopPropagation()}>{match[2]}</Link>);
    newChildren.push(desc.slice(match.index + match[0].length, i < matches.length - 1 ? matches[i + 1].index : undefined));
  }

  return newChildren;
}


export default function InvoiceItems({
  modal,
  invoice,
  onChange,
  showAddButton = !invoice.deleted,
}) {
  const canEdit = invoice.state === 'draft';

  function handleChange() {
    modal.close();
    onChange();
  }

  function addItem(chargeType = null) {
    const initialValue = {
      startDate: moment().format('YYYY-MM-DD'),
      quantity: 1,
      desc: getLangText(chargeType?.title),
      price: chargeType?.amount,
    };

    const onSubmit = actions.invoices.items(invoice.id).create;

    modal.open(
      <InvoiceItemForm
        defaultValue={initialValue}
        onSubmit={onSubmit}
        onSuccess={handleChange}
        title={<cms.Text id="addModal.title" vars={{ invoiceId: invoice.sid.toUpperCase() }} />}
        submitButton={<cms.Text id="addModal.ok" />}
        modal
        onHideModal={modal.close}
      />,
    );
  }

  function editItem(item) {
    modal.open(
      <InvoiceItemForm
        defaultValue={item}
        onSubmit={(formData) => actions.invoices.items(invoice.id).update(item.id, formData)}
        onSuccess={handleChange}
        title={<cms.Text id="editModal.title" vars={{ invoiceId: invoice.sid.toUpperCase() }} />}
        submitButton={<cms.Text id="editModal.ok" />}
        modal
        onHideModal={modal.close}
        onDelete={() => deleteItem(item)}
        deleteButton={<cms.Object id="editModal" keys="delete,confirmDelete">{cms.text('editModal.delete')}</cms.Object>}
      />,
    );
  }

  function deleteItem(item) {
    if (!window.confirm(cms.text('editModal.confirmDelete'))) return;

    actions.invoices.items(invoice.id).delete(item.id)
      .then(handleChange);
  }

  if (invoice.type !== 'valet' && !invoice.items.length) return null;

  return (
    <Section
      cms={cms}
      extra={canEdit && showAddButton && (
        <ChargesMenuButton
          onSelect={addItem}
        />
      )}
    >
      {invoice.items.length > 0 && (
        <>
          <table className="table no-border" style={{ fontSize: 10 }}>
            <thead>
              <tr>
                <Label id="date" />
                <Label id="desc" />
                <Label id="accountingCode" hide />
                <Label id="quantity" hide />
                <Label id="price" hide />
                <Label id="subtotal" />
              </tr>
            </thead>
            <tbody>
              {invoice.items.map((item) => (
                <tr
                  key={item.id}
                  onClick={() => editItem(item)}
                  style={{ cursor: canEdit ? 'pointer' : '' }}
                >
                  <td>{datePeriod(item.startDate, item.endDate).join(' - ')}</td>
                  <td><ItemDescription>{item.desc}</ItemDescription></td>
                  <td className="hidden-xs hidden-sm" style={{ color: '#bbb' }}>{item.accountingCode}</td>
                  <td className="hidden-xs hidden-sm text-right" style={{ color: '#bbb' }}>{item.quantity}</td>
                  <td className="hidden-xs hidden-sm text-right" style={{ color: '#bbb' }}>{currency(item.price)}</td>
                  <td className="text-right">{currency(item.total)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ fontSize: 16, textAlign: 'right' }}>
            <cms.Text id="subtotal" />: {currency(invoice.subtotal)}
          </div>

          {!!invoice.taxPercent && invoice.taxPercent > 0 && (
            <div style={{ fontSize: 16, textAlign: 'right' }}>
              <cms.Text id="tax" /> ({invoice.taxPercent}%): {currency(invoice.tax)}
            </div>
          )}

          <hr />
          <div style={{ fontSize: 18, textAlign: 'right', fontWeight: 600 }}>
            <cms.Text id="total" />: {currency(invoice.total)}
          </div>
        </>
      )}
    </Section>
  );
}
