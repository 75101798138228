import wurd from 'wurd-react';
import styled from '@emotion/styled';
import _ from 'lodash';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import ApiButton from '../../api-button';
import ModalForm2 from '../../form2/modal-form2';
import { Field } from '../../form2';
import UploadImageButton from '../../uploader';

const cms = wurd.block('valetOrder.itemDetails');

const Image = styled.img({
  width: '100%',
  marginBottom: 10,
});


export default function ItemDetailsForm({
  modal,
  itemId,
  fields = ['opsImage', 'state', 'opsTitle', 'location'],
  stateOptions = [
    ['unassigned', wurd.text('item._states.unassigned')],
    ['user', wurd.text('item._states.user')],
    ['storage', wurd.text('item._states.storage')],
  ],
  onSubmit,
}) {
  // Get from the store each time so that we always have the latest up to date info
  const item = actions.items.get(itemId);
  if (!item) throw new Error(`Item ${itemId} not found in store`);

  async function onUploadImage(event) {
    await actions.update('items', item.id, {
      opsImage: event.target.value,
    });
  }

  async function deleteImage() {
    await actions.update('items', item.id, {
      opsImage: null,
    });
  }

  return (
    <ModalForm2
      wurdSection={cms.id()}
      title={<>{item.sid.toUpperCase()} <small>{helpers.item.getTitle(item.type)}</small></>}
      initialValue={_.pick(item, fields)}
      mode="update"
      onSubmit={onSubmit}
      onSuccess={modal.close}
      closeModal={modal.close}
    >
      {({ fieldProps }) => (
        <>
          <Image src={item.image} />
          {fields.includes('opsImage') && (
            <div className="text-center">
              <UploadImageButton
                name="image"
                onChange={onUploadImage}
              />
              {item.opsImages.length > 0 && (
                <ApiButton
                  bsType="danger"
                  onClick={deleteImage}
                >
                  <i className="far fa-trash" /> <cms.Text id="deleteImage" />
                </ApiButton>
              )}
            </div>
          )}

          <hr />

          {fields.includes('state') && (
            <Field {...fieldProps('state')} required>
              <select>
                {stateOptions.map(([value, text]) => (
                  <option key={value} value={value}>{text}</option>
                ))}
              </select>
            </Field>
          )}

          {fields.includes('opsTitle') && (
            <Field {...fieldProps('opsTitle')} />
          )}

          {fields.includes('location') && (
            <Field {...fieldProps('location')} />
          )}
        </>
      )}
    </ModalForm2>
  );
}
