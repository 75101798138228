import PropTypes from 'prop-types';

import Spinner from './spinner';


export default function AssignModal({ drivers, onHide, onSelect }) {
  function renderMain() {
    return (
      <div className="list-group">
        <li
          className="list-group-item"
          style={{ cursor: 'pointer' }}
          onClick={() => onSelect(null)}
        >
          <h4 className="list-group-item-heading"><strong>None</strong> (unassign)</h4>
        </li>
        {drivers.map(renderDriver)}
      </div>
    );
  }

  function renderDriver(user) {
    return (
      <li
        key={user.id}
        className="list-group-item"
        style={{ cursor: 'pointer' }}
        onClick={() => onSelect(user)}
      >
        <h4 className="list-group-item-heading">{user.firstName} {user.lastName}</h4>
      </li>
    );
  }

  return (
    <div>
      <div className="modal-header">
        <h4 className="modal-title">Select a driver</h4>
      </div>
      <div className="modal-body">
        {drivers === null ? <Spinner /> : renderMain()}
      </div>
    </div>
  );
}


AssignModal.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};
