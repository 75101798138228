import * as helpers from '../../../helpers';

export default ({ visit }) => (
  <div className="well well-sm" style={{ fontSize: '1.3em' }}>
    <div style={{ marginBottom: 20 }}>
      <i className="fa fa-map-marker pull-left" style={{ marginTop: 2 }} />
      <div style={{ marginLeft: 25, whiteSpace: 'pre-line' }}>
        <a href={helpers.visit.getAddressMapURL(visit)} style={{ whiteSpace: 'pre-line' }} target="_blank" rel="noopener noreferrer">{visit.address}</a>
      </div>
    </div>

    <div style={{ marginBottom: 10 }}>
      <i className="fa fa-user pull-left" style={{ marginTop: 2 }}></i>
      <div style={{ marginLeft: 25 }}>{visit.owner.name}</div>
    </div>

    <div>
      <i className="fa fa-phone pull-left" style={{ marginTop: 2 }}></i>
      <div style={{ marginLeft: 25 }}>
        {visit.phone &&
          <a href={`tel:${visit.phone}`}>{visit.phone}</a>
        }

        {visit.altPhone &&
          <div>
            / <a href={`tel:${visit.altPhone}`}>{visit.altPhone}</a>
          </div>
        }
      </div>
    </div>
  </div>
);
