import PropTypes from 'prop-types';
import { useState } from 'react';
import wurd from 'wurd-react';

import UploadImageButton from '../uploader';

const cms = wurd.block('activity.commentForm');


export default function CommentForm({ onSubmit }) {
  const [text, setText] = useState('');


  function handleSubmit() {
    onSubmit(text);

    setText('');
  }

  function onUploadImage(event) {
    setText(`${text}\r\n![image](${event.target.value.replace(/ /g, '+')})`);
  }

  return (
    <form className="comment-form" style={{ marginBottom: 20 }}>
      <cms.Object
        type="textarea"
        keys="placeholder"
        className="form-control"
        placeholder={cms.text('placeholder')}
        required
        style={{ height: 100 }}
        value={text}
        onChange={e => setText(e.target.value)}
      />
      <div className="clearfix">
        <div className="btn-group pull-right" role="group" style={{ marginTop: 5, marginRight: 0 }}>
          <UploadImageButton onChange={onUploadImage} />

          <button
            type="button"
            className="btn btn-success btn-flat"
            style={{ marginLeft: 5 }}
            disabled={!text.length}
            onClick={handleSubmit}
          >
            <cms.Text id="submitBtn" />
          </button>
        </div>
      </div>
    </form>
  );
}


CommentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};