import wurd from 'wurd-react';

import * as actions from '../../actions';

import UploadImageButton from '../uploader';

const cms = wurd.block('itemView.images');


const styles = {
  images: {
    overflow: 'auto',
    maxHeight: 400,
  },
  img: {
    width: '100%',
    position: 'relative',
  },
  deleteImageButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'rgba(255,255,255,.5)',
    width: 40,
    height: 40,
    border: '1px #fff',
  },
};


export default function ItemImages({
  item,
  itemConfig,
  onImageUpdated = null,
}) {
  async function onUploadImage(event) {
    const data = { url: event.target.value };

    const image = await actions.items.opsImages(item.id).create(data);

    actions.fetchOne('items', item.id);

    if (onImageUpdated) onImageUpdated(image);
  }

  async function deleteImage(event) {
    const { currentTarget } = event;

    event.preventDefault();

    if (!window.confirm(cms.text('confirmDelete'))) return;

    await actions.items.opsImages(item.id).delete(currentTarget.id);

    actions.fetchOne('items', item.id);

    if (onImageUpdated) onImageUpdated(null);
  }


  return (
    <>
      <div style={styles.images}>
        {!item.opsImages.length && (
          <img src={itemConfig.image} style={styles.img} alt="" />
        )}

        {item.opsImages.map((image) => (
          <a
            key={image.id}
            className="thumbnail clearfix"
            href={image.url}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.img}
          >
            <button
              type="button"
              id={image.id}
              onClick={deleteImage}
              style={styles.deleteImageButton}
            >
              <cms.Object keys="confirmDelete">
                <i className="fa fa-trash" />
              </cms.Object>
            </button>

            <img src={image.url} alt="" />
          </a>
        ))}
      </div>

      <UploadImageButton onChange={onUploadImage} className="btn-block" />
    </>
  );
}
