import { Button } from 'react-bootstrap';

const buttonStyle = { width: 34 };


export default function SitemapFloorSelector({ style, floors, active, onChange }) {

  if (floors.length < 2) return null;

  return (
    <div className="btn-group-vertical" style={style}>
      {floors.slice().reverse().map(floor => (
        <Button
          key={floor}
          onClick={() => onChange(floor)}
          bsStyle={(floor === active) ? 'primary' : 'default' }
          style={buttonStyle}
        >
          {floor}
        </Button>
      ))}
    </div>
  );

}
