import wurd from 'wurd-react';
import { Link } from 'react-router-dom';

import * as actions from 'actions';
import * as helpers from 'helpers';
import * as secsys from 'plugins/security-systems';

import JobStep from '../../job-step2';
import ApiButton from '../../../api-button';
import UnitLoader from '../../../loaders/unit';
import InvoiceLoader from '../../../loaders/invoice';
import SidebarSummaryRow from '../../../sidebar-summary-row';
import { Section, Heading } from '../../components';
import ConfirmFutureStep from '../../confirm-future-step';
import OrderSummary from '../summary';
import OperationsBar from '../operations-bar';

const cms = wurd.block('unitOrder.steps.unit_occupy');


export default function UnitMoveInJobSteps_UnitOccupy({
  site,
  job,
  step,
  owner,
  onChange,
  modal,
}) {
  async function completeStep() {
    await actions.jobs.updateStep(job.id, step.id, {
      state: 'completed'
    });

    onChange();
  }

  function render_ready() {
    const { unitId, invoiceId, startDate } = job.result;
    const stepError = step.error && (
      <InvoiceLoader id={invoiceId}>
        {invoice => (
          <cms.Markdown
            id={'error.' + step.error}
            vars={{ ...job.result, invoiceSid: helpers.ui.sid(invoice) }}
          />
        )}
      </InvoiceLoader>
    );

    return (
      <UnitLoader id={unitId} include={['rental', 'customFields']}>
        {unit => (
          <JobStep
            step={{ ...step, error: stepError }}
            title={<cms.Text id="ready.title" />}
          >
            <Section>
              <Heading><cms.Text id="summary" /></Heading>
      
              <OrderSummary
                job={job}
                owner={owner}
                unit={unit}
                rental={unit.rental}
                modal={modal}
                onChange={onChange}
              />
              
              <OperationsBar
                job={job}
                modal={modal}
                onChange={onChange}
              />
            </Section>

            <ConfirmFutureStep date={startDate}>
              <div className="btn-group btn-group-justified">
                <div className="btn-group">
                  <ApiButton
                    bsType="success"
                    onClick={completeStep}
                    disabled={job.state === 'failed'}
                    loadingMsg={unit.customFields?.[secsys.getCfKey(site)] && <cms.Text type="span" id="syncingSecuritySystem" vars={{ securitySystem: secsys.getCfKey(site).replace(/_id$/, '') }} />}
                  >
                    <i className="fas fa-check" />
                    &nbsp;
                    <cms.Text id="requiresInput.completeBtn" />
                  </ApiButton>
                </div>
              </div>
            </ConfirmFutureStep>
          </JobStep>
        )}
      </UnitLoader>
    );
  }

  function render_completed() {
    const { unitId } = job.result;

    return (
      <UnitLoader id={unitId}>
        {unit =>
          <JobStep
            step={step}
            title={<cms.Text id="completed.title" vars={{ unitName: unit.name }} />}
          >
            <Link to={`/units/${unit.id}`}>
              <SidebarSummaryRow
                className="list-group-item list-group-item-success"
                image={helpers.unitType.get(site, unit.typeId)?.image}
                typeTitle={helpers.unitType.get(site, unit.typeId, true)?.title}
                itemTitle={`Unit ${unit.name} - ${helpers.ui.getLangText(site.title)}`}
              />
            </Link>
          </JobStep>
        }
      </UnitLoader>
    );
  }

  if (step.state === 'ready') return render_ready();
  if (step.state === 'completed') return render_completed();

  return null;
}
