import { Component } from 'react';
import { Transformer } from 'react-konva'
import { ROTATION_SNAPS, TYPES, BLOCK_SNAP_SIZE } from '../Constants'

/**
 * @params {{selectedShapeName: string, selectedShapeType: 'object'|undefined, editMode: boolean}} props
 */
export default class TransformerComponent extends Component {
  componentDidMount() {
    this.checkNode()
  }

  componentDidUpdate() {
    this.checkNode()
  }

  checkNode() {
    const stage = this.transformer.getStage()
    const { selectedShapeName, editMode } = this.props
    if (!editMode) return
    const selectedNode = stage.findOne('.' + selectedShapeName)
    if (selectedNode === this.transformer.node()) {
      return
    }
    if (selectedNode) {
      this.transformer.attachTo(selectedNode)
    } else {
      this.transformer.detach()
    }
    this.transformer.getLayer().batchDraw()
    this.transformer.moveUp()
  }

  limitResizing = (oldBoundBox, newBoundBox) => {
    const scale = BLOCK_SNAP_SIZE * this.props.scale
    if (newBoundBox.rotation || newBoundBox.rotation === 0) return newBoundBox

    newBoundBox.height = Math.round(newBoundBox.height / scale) * scale
    newBoundBox.width = Math.round(newBoundBox.width / scale) * scale
    newBoundBox.x = Math.round(newBoundBox.x / scale) * scale
    newBoundBox.y = Math.round(newBoundBox.y / scale) * scale
    return newBoundBox
  }

  render() {
    return (
      <Transformer
        resizeEnabled={this.props.selectedShapeType === TYPES.object}
        rotationSnaps={ROTATION_SNAPS}
        ref={node => {
          this.transformer = node
        }}
      />
    )
  }
}
