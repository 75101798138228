import styled from '@emotion/styled';

import store from '../store';
import { currentLang } from 'utils/language';


const Secondary = styled.span({
  filter: 'opacity(.75)',
});


export default function LangText({
  children, obj = children || { en: '' },
}) {
  if (!obj || typeof obj === 'string') return obj;

  const settings = store.get('settings') || {};
  const defaultLang = settings.defaultLang /* deprecated */ || settings.languages?.[0];

  return obj[currentLang] || <Secondary>{obj[defaultLang] || Object.values(obj).find(Boolean)}</Secondary>;
}
