import wurd from 'wurd-react';

import * as helpers from '../../helpers';

import ListFilter from '../filter/list';
import UserFilter from '../filter/user';
import StateLabel from '../state-label';
import SiteFilter from '../filter/site';
import UnitTypeFilter from '../filter/unit-type';
import LabelsFilter from 'plugins/labels/filter';
import SearchFilter from '../filter/search';

const cms = wurd.block('unitList.filters');

const { stateLabelTypes } = helpers.unit;


export default function UnitListFilters({
  listQuery,
  hideSite,
  hideType,
  hideState,
  hideOwner,
}) {
  const { params } = listQuery;

  function onChange(key, value) {
    listQuery.update({
      offset: 0,
      [key]: value,
    });
  }

  const setSiteId = (val) => onChange('siteId', val);
  const setTypeId = (val) => onChange('typeId', val);
  const setState = (val) => onChange('state', val || '');
  const setOwnerId = (val) => onChange('ownerId', val);

  return (
    <>
      {!hideSite && (
        <SiteFilter
          active={params.siteId}
          onSelect={setSiteId}
        />
      )}

      {params.siteId && !hideType && (
        <UnitTypeFilter
          active={params.typeId}
          onSelect={setTypeId}
          params={{ siteId: params.siteId }}
        />
      )}

      {!hideState && (
        <cms.Object id="state" keys={`title,all,${helpers.unit.states.join(',')},archived`}>
          <ListFilter
            title={cms.text('state.title')}
            active={params.state}
            onSelect={setState}
            multi
          >
            {[...helpers.unit.states, 'archived'].map((state) => (
              <li key={state} value={state}>
                <StateLabel type={stateLabelTypes[state]} style={state === 'archived' && { opacity: 0.5 }}>{cms.text(`state.${state}`)}</StateLabel>
              </li>
            ))}
          </ListFilter>
        </cms.Object>
      )}

      {!hideOwner && (
        <UserFilter
          active={params.ownerId}
          onSelect={setOwnerId}
        />
      )}

      <LabelsFilter
        active={params.labels}
        onSelect={(val) => onChange('labels', val)}
        labels={helpers.settings.get().unitLabels}
      />

      <SearchFilter
        name="unit-search"
        cms={wurd.block('unitList.search')}
        active={params.search}
        onSelect={(val) => onChange('search', val)}
        modelName="unit"
      />
    </>
  );
}
