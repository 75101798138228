import wurd from 'wurd-react';
import fastDiff from 'fast-diff';
import { currency, getLangText, href } from './ui';
import { hasRole } from './auth';


const cms = wurd.block('actions');
const t = cms.text;

const defaultFaIcons = {
  valetOrder: 'fa-truck',
  item: 'fa-archive',
};

const stringify = v => typeof v === 'string' ? v : `${JSON.stringify(v) || ''}`;
const nodify = v => {
  if (Array.isArray(v)) {
    return v.map(([type, value], i) => {
      switch (type) {
        case 1: return <ins key={i}>{value.replace(/\n/g, '\n\u202F')/* to make linereturn visible */}</ins>;
        case -1: return <del key={i}>{value.replace(/\n/g, '\n\u202F')}</del>;
        default: return <cite key={i}>{value.length > 50 ? `${value.slice(0, 10)}…${value.slice(-20)}` : value}</cite>;
      }
    });
  }
  if (v && typeof v === 'object') {
    return (
      <ol className="inline object">
        {Object.entries(v).map(([k, x], i) => <li key={i}>&quot;{k}&quot;:{nodify(x)}</li>)}
      </ol>
    );
  }
  return v;
};

export function diff(oldVal = '', newVal = '') {
  if (oldVal === newVal) {
    if (newVal === '****') return newVal;
    return undefined;
  }

  if (Array.isArray(oldVal)) oldVal = oldVal.map(stringify).join(', ');
  if (Array.isArray(newVal)) newVal = newVal.map(stringify).join(', ');

  if (typeof newVal === 'object' && newVal && oldVal != undefined) {
    const diffObj = Object.fromEntries(
      Object.keys(newVal)
        .map(key => [key, diff(oldVal[key] ?? '', newVal[key])])
        .filter(([k, v]) => v !== undefined)
    );
    return Object.keys(diffObj).length > 0 ? diffObj : undefined;
  }

  const oldText = stringify(oldVal);
  const newText = stringify(newVal);

  const smallDiff = oldText.length < 30 || newText.length < 30 || !/\s/.test(oldText) || !/\s/.test(newText);

  // if the text is small or consist in one word, just render 1 change
  if (smallDiff) {
    return [[-1, oldText], [1, newText]].filter(([t, s]) => s);
  }

  return fastDiff(oldText, newText);
}


// ======= helper formatting functions
export function formatChanges(data, prefix) {
  if (!data.newAttrs && !data.diffs) return null;

  return (
    <ul className="list-unstyled" style={{ whiteSpace: 'pre-wrap' }}>
      {Object.keys(data.newAttrs || data.diffs)
        .map((key) => {
          const label = prefix && wurd.get(`${prefix}.${key}.label`) || key;

          const d = data.diffs ? data.diffs[key] : diff(data.oldAttrs?.[key] ?? '', data.newAttrs[key]);

          return d ? <li key={key}>{label}: {nodify(d)}</li> : null;
        })
      }
    </ul>
  );
}

export function formatUnitType(data) {
  if (data.newAttrs) return formatChanges(data);
  if (!data.unitType) return null;
  return getLangText(data.unitType.title);
}

export function formatSiteProduct(data) {
  if (data.newAttrs) return formatChanges(data);
  if (!data.product) return null;
  return getLangText(data.product.title);
}


export function makeUserChargeExtra(data) {
  const charge = data.charge;

  return `<b>${getLangText(charge.title)}</b> (${currency(charge.amount)})`;
}

export function makeUserCreditExtra(data) {
  const { amount, note } = data.credit;

  return `<b>${note}</b>: ${currency(amount)}`;
}

export function makeUserFileExtra(data) {
  const { title } = data.file;

  return getLangText(title);
}

export function formatInvoicePayment(data) {
  const { type, method, amount } = data.payment || {};

  const description = method || type;

  return `${description}: ${currency(amount)}`;
}

export function formatUnitCharge(data) {
  const { charge } = data;
  if (!charge) return null;

  return `${getLangText(charge.title)}: ${currency(charge.amount)}`;
}

export function getIcon(actionConfig, data, context) {
  if (typeof actionConfig === 'string') return actionConfig;

  if (typeof actionConfig.icon === 'function') return actionConfig.icon(data);

  if (typeof actionConfig.icon === 'string') return actionConfig.icon;

  return defaultFaIcons[context];
}


// ====== helper link functions
const getSid = (obj) => obj?.sid?.toUpperCase() || '';

export function makeMarketLink(market, context) {
  if (!market) return null;
  return `market '${market.name}'`;
}

export function makePlanLink(plan, context) {
  if (!plan) return null;
  return `plan ${getLangText(plan.title)}`;
}

export function makeUserLink(user) {
  if (!user) return t('_links.user_userContext');
  const text = user.name;
  const url = href(`/users/${user.id}`);

  if (hasRole('admin')) {
    return `[${text}](${url})`;
  } else {
    return text;
  }
}

export function makeVisitLink(visit, context) {
  if (!visit || context === 'visit') {
    return t('_links.visit_visitContext');
  } else {
    return `[${t('_links.visit_otherContext', { id: getSid(visit) })}](${href(`/visits/${visit.id}`)})`;
  }
}

export function makeItemLink(item, context) {
  if (!item || context === 'item') {
    return t('_links.item_itemContext');
  } else {
    const text = t('_links.item_otherContext', { id: getSid(item) });
    const url = href(`/items/${item.id}`);

    return `[${text}](${url})`;
  }
}

export function makeSiteLink(site, context) {
  if (!site || context === 'site') {
    return t('_links.site_siteContext');
  } else {
    const text = t('_links.site_otherContext', { id: getLangText(site.title) });
    const url = href(`/sites/${site.id}`);

    return `[${text}](${url})`;
  }
}


export function makeUnitLink(unit, context) {
  if (!unit || context === 'unit') {
    return t('_links.unit_unitContext');
  } else {
    const text = t('_links.unit_otherContext', { id: unit.name });
    const url = href(`/units/${unit.id}`);

    return `[${text}](${url})`;
  }
}


export function makeUnitTypeLink(unitType, context) {
  if (!unitType || context === 'unitType') {
    return t('_links.unitType_unitTypeContext');
  } else {
    const text = t('_links.unitType_otherContext', { id: getLangText(unitType.title) });
    const url = href(`/unit-types/${unitType.id}`);

    return `[${text}](${url})`;
  }
}


export function makeUnitRentalLink(unitRental, context) {
  if (!unitRental || context === 'unit') {
    return t('_links.unitRental_unitRentalContext');
  } else {
    const text = t('_links.unitRental_otherContext', { id: unitRental.id, unitName: unitRental.unitName });
    const url = href(`/unit-rentals/${unitRental.id}`);

    return `[${text}](${url})`;
  }
}

export function makeInvoiceLink(invoice, context) {
  if (!invoice || context === 'invoice') {
    return t('_links.invoice_invoiceContext');
  } else {
    const text = t('_links.invoice_otherContext', { id: getSid(invoice) });
    const url = href(`/invoices/${getSid(invoice)}`);

    if (hasRole('admin')) {
      return `[${text}](${url})`;
    } else {
      return text;
    }
  }
}

export function makeJobLink(job, context) {
  if (!job || context === 'job') {
    return t('_links.job_jobContext');
  } else {
    const text = t('_links.job_otherContext', { id: job.sid });

    if (hasRole('__superadmin')) {
      return `[${text}](${href(`/jobs/${job.id}`)})`;
    } else if (hasRole('admin')) {
      return `[${text}](${href(`/sites/-/jobs/${job.id}`)})`;
    } else {
      return text;
    }
  }
}

export function makeValetOrderLink(valetOrder, context) {
  if (!valetOrder || context === 'valetOrder') {
    return t('_links.valetOrder_valetOrderContext');
  } else {
    const text = t('_links.valetOrder_otherContext', { id: getSid(valetOrder) });
    const url = href(`/valet-orders/${getSid(valetOrder)}`);

    if (hasRole('admin')) {
      return `[${text}](${url})`;
    } else {
      return text;
    }
  }
}

export function makeUnitOrderLink(unitOrder, context) {
  if (!unitOrder || context === 'unitOrder') {
    return t('_links.unitOrder_unitOrderContext');
  } else {
    const text = t('_links.unitOrder_otherContext', { id: getSid(unitOrder) });
    const url = href(`/unit-orders/${getSid(unitOrder)}`);

    if (hasRole('admin')) {
      return `[${text}](${url})`;
    } else {
      return text;
    }
  }
}

export function makeAddonLink(addon, context) {
  if (!addon || context === 'addon') {
    return t('_links.addon_addonContext');
  } else {
    const text = t('_links.addon_otherContext', { id: addon.id, title: addon.title });

    if (hasRole('manager')) {
      return `[${text}](${href(`/addons/${addon.id}`)})`;
    } else {
      return text;
    }
  }
}
