import { Component } from 'react';
import wurd from 'wurd-react';
import PropTypes from 'prop-types';

import { flag } from '../../utils/language';
import * as helpers from '../../helpers';

import InputGroup from './input-group';


export default class MultiLangText extends Component {
  static propTypes = {
    //langs: PropTypes.arrayOf(React.PropTypes.string).isRequired,
    autoFocus: PropTypes.bool,
    multiline: PropTypes.bool,
    onChange: PropTypes.func, // not isRequired because if it's parent is a Field, onChange is passed through React.cloneElement
    langs: PropTypes.arrayOf(PropTypes.string), // if it's passed, it overrides settings.languages
  };

  render() {
    const langs = this.props.langs || helpers.settings.langs();

    return (langs.length === 1)
      ? this.renderSingleLangInput(langs[0])
      : this.renderMultiLangInput(langs);
  }

  renderSingleLangInput(lang) {
    const { value, multiline, onChange, ...props } = this.props;

    const childProps = {
      className: 'form-control',
      value: value?.[lang] || '',
      onChange: (evt) => this.onChange(evt, lang),
      ...props,
    };

    return multiline
      ? <textarea {...childProps} />
      : <input {...childProps} />
  }

  renderMultiLangInput(langs) {
    const { value, autoFocus, multiline, onChange, required, placeholder, ...props } = this.props;

    const hasNonEmpty = langs.some(lang => value?.[lang]);

    return (
      <div>
        {langs.map((lang, i) => {
          const childProps = {
            className: "form-control",
            placeholder: placeholder?.[lang] || placeholder || `${flag(lang) || ''}  ${wurd.get(`common.languages.${lang}`)}`,
            value: value?.[lang] || '',
            onChange: (evt) => this.onChange(evt, lang),
            autoFocus: (autoFocus && i === 0),
            required: required && !hasNonEmpty && i === 0 ? true : null,
            ...props,
          };

          return (
            <div key={lang} style={{ marginBottom: 2 }}>
              {multiline
                ? <textarea {...childProps} />
                : <input {...childProps} /> //<InputGroup before={<span style={{ fontSize: 12 }}>{flag(lang) || lang}</span>} {...childProps} />
              }
            </div>
          );
        })}
      </div>
    );
  }

  onChange(event, lang) {
    const { name, value, onChange } = this.props;

    onChange({
      target: {
        name: name,
        value: { ...value, [lang]: event.target.value }
      }
    });
  }
}
