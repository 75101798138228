import PropTypes from 'prop-types';
import wurd, { WurdObject } from 'wurd-react';

import store from '../../store';

import FilterButton from './button';
import UnitSelect from '../unit-select';
import ClearIndicator from './clear-indicator';


const cms = wurd.block('common.filters.unit');


export default function UnitFilter({ active, onSelect, siteId, ...props }) {
  let title = cms.text('title') || 'Unit';

  const unit = active && store.get().units_byId[active];

  if (unit) {
    title += `: ${unit.name}`;
  }

  return (
    <WurdObject id={cms.id()} keys="title,placeholder,clear">
      <FilterButton active={active} onSelect={onSelect} title={title} {...props}>
        <div style={{ padding: 10, width: 250 }}>
          <UnitSelect
            placeholder={cms.text('placeholder')}
            onChange={onSelect}
            value={active}
            autoFocus
            isClearable
            components={{ ClearIndicator: props => <ClearIndicator {...props} onSelect={onSelect} /> }}
            siteId={siteId}
          />
        </div>
      </FilterButton>
    </WurdObject>
  );
}


UnitFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.any, // The current filter value (null if not set)
};
