import wurd from 'wurd-react';

import * as actions from '../../actions';

import SelectDriverModal from './select-driver-modal';

const cms = wurd.block('itemList.bulkActions');


export default function ValetOrderListBulkActions({ modal, selectedItems, onActionComplete }) {
  const count = selectedItems.length;

  async function assignToDriver(user) {
    await actions.jobs.create(null, 'valet_assignOrders', {
      driverId: user ? user.id : null,
      valetOrderIds: selectedItems.map((order) => order.id),
    });

    onActionComplete();
    modal.close();
  }

  function selectDriver() {
    modal.open(
      <SelectDriverModal
        onSelect={assignToDriver}
      />,
    );
  }


  return (
    <>
      <button
        type="button"
        className="btn btn-flat btn-primary"
        disabled={!count}
        onClick={selectDriver}
      >
        <cms.Text id="assign.btn" />
      </button>
    </>
  );
}
