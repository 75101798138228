import wurd, { WurdText, WurdMarkdown } from 'wurd-react';
import { Button, Dropdown, MenuItem } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useBeforeunload } from 'react-beforeunload';
import { previewAgreement } from 'components/jobs/unit_move-in/helpers';
import * as helpers from 'helpers';
import * as actions from 'actions';

import Tabs from 'components/tabs';
import UploadButton from 'components/uploader';
import Modal from 'components/modal';
import { useVars } from './variables';

const cms = wurd.block('settings.moveInAgreement');


/**
 * moveInAgreementTemplate editor
 */
export default function EsignPdfEditor({ value, onChange, initialValue, modal }) {
  const langKeys = helpers.settings.langs();
  const previewMutation = useMutation(previewAgreement);

  const vars = useVars();

  useBeforeunload(e => {
    const changedUrls = Object.entries(value.moveInAgreementPdf).filter(([k, v]) => v && v !== initialValue.moveInAgreementPdf[k]).map(([k, v]) => v);
    if (changedUrls.length) { // if new pdf url added, delete on refresh
      e.preventDefault();
      sessionStorage.setItem('sg-unsaved-pdf', changedUrls);
      // maybe delete the files after refresh?
      // actions.uploads.deleteFile(value.moveInAgreementPdf?.[lang]);
    }
  });

  return (
    <div className="form-group">
      <div className="col-sm-12" style={{ float: 'none' }}>
        <Tabs
          items={langKeys.map((lang) => ({
            id: lang,
            title: <WurdText id={`common.languages.${lang}`} />,
            renderContent: () => {
              return (
                value.moveInAgreementPdf?.[lang]
                  ? (
                    <iframe src={value.moveInAgreementPdf?.[lang]} style={{ width: '100%', height: 400 }} />
                  ) : (
                    <UploadButton
                      id="add-pdf"
                      text={<cms.Text id="addPdf" />}
                      icon={<i className="far fa-file-pdf" />}
                      bsStyle="primary"
                      accept="application/pdf"
                      value={value.moveInAgreementPdf?.[lang]}
                      onChange={e => onChange({ moveInAgreementPdf: { ...value.moveInAgreementPdf, [lang]: e.target.value } })}
                      style={{ display: 'block', transform: 'translateY(.5rem)' }}
                    />
                  )
              );
            }
          }))}
          renderExtra={lang => {
            const allFields = [
              ...vars.site,
              ...vars.user,
              ...vars.rental,
            ].map(n => n.replace(/\./g, '_'));

            return (
              <div style={{ position: 'absolute', right: 10, top: 5, zIndex: 400, display: 'flex' }}>
                {value.moveInAgreementPdf?.[lang] && (
                  <Button
                    bsSize="sm"
                    onClick={() => onChange({ moveInAgreementPdf: { ...value.moveInAgreementPdf, [lang]: null } })}
                    disabled={value.moveInAgreementPdf?.[lang] !== initialValue.moveInAgreementPdf?.[lang]}
                  >
                    <cms.Text id="deletePdf" />
                  </Button>
                )}
                <Button
                  bsSize="sm"
                  style={{ marginLeft: 4 }}
                  disabled={previewMutation.isLoading || !value.moveInAgreementPdf?.[lang]}
                  onClick={e => previewMutation.mutate({
                    pdf: value.moveInAgreementPdf?.[lang],
                    mode: 'pdf',
                    lang,
                  })}
                >
                  <WurdText id="settings.emails.preview" />
                </Button>
                
                <Button
                  bsSize="sm"
                  style={{ marginLeft: 4 }}
                  onClick={() => modal.open((
                    <Modal
                      title={<cms.Text id="varsModal.title" />}
                      subtitle={<cms.Markdown id="varsModal.subtitle" />}
                      closeModal={modal.close}
                    >
                      <ul className="list-unstyled" style={{ outline: '1px auto #0002', padding: 4 }}>{allFields.map(n => <li key={n}>{n}</li>)}</ul>
                    </Modal>
                  ))}
                >
                  <cms.Text id="insertVar" />
                </Button>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
