import { fetch, fetchOne, create, update, updateCachedData, includeKeysByEndpoint } from './general';
import errHandler from './_err-handler';
import labelsPlugin from '../plugins/labels/actions';

const endpoint = 'units';
const url = `/v1/admin/${endpoint}`;

const actions = {};

function handleResponse(res) {
  updateCachedData(endpoint, res.data);

  return res.data;
}


/**
 * Fetches units
 *
 * @param {Object} [params]          Query parameters
 * @param {String} [params.state]
 */
actions.fetch = function (params = {}) {
  return fetch(endpoint, params);
};


/**
 * Fetches a single unit
 *
 * @param {Object} [params]          Query parameters
 * @param {String} [params.include]
 */
actions.fetchOne = function (id, params = {}) {
  return fetchOne(endpoint, id, params);
};


/**
 * Creates an unit for a given user
 *
 * @param {String} userId
 * @param {Object} props
 */
actions.create = function (data) {
  return create(endpoint, data);
};


actions.update = async function (id, data) {
  return update(endpoint, id, data, { include: includeKeysByEndpoint.units });
};

actions.labels = labelsPlugin('units');

export default actions;
