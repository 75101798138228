import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import wurd from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';
import { useSimpleListQuery } from 'hooks';

import TableList from 'components/table-list';
import Item from './subscription-states_item';

const cms = wurd.block('userView.subscriptionStates');


export default function UserSubscriptionStates({ user }) {
  const listQuery = useSimpleListQuery({});
  const { data: items = [], isLoading, refetch } = useQuery(`sub-states-${user.id}-${JSON.stringify(listQuery.params)}`, () => actions.users.fetchSubscriptionStates(user.id, listQuery.params));

  const renderItem = (item) => <Item item={item} />;

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={{ items, isLoading, refetch }}
      renderItem={renderItem}
      className="compact align-top"
      getTableRowClassName={() => 'position-relative'}
    />
  );
}

UserSubscriptionStates.propTypes = {
  user: PropTypes.object.isRequired
};
