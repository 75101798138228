import styled from '@emotion/styled';

import { iconColors } from '../helpers/ui';

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',

  '.sgc-icon': {
    flex: '0 0 34px',
    fontSize: '18px',
    color: '#999',
  },
  
  '.sgc-main': {
    flex: '1 1',
    fontSize: '15px',
  },

  '.sgc-title': {
    fontSize: '12px',
    lineHeight: '1em',
    color: '#999',
  },

  '.sgc-subtext': {
    color: '#999',
  },

  '.sgc-action': {
    flex: '0 0',
    marginBottom: -5,
    marginRight: -10,
  }
});


export default function ListGroupItem({
  faIcon,
  bsType = 'default',
  title,
  text,
  subtext,
  items = [],
  action,
  onClick,
  onClickEdit,
  style,
}) {
  return (
    <Container
      className="list-group-item clearfix"
      style={{
        ...style,
        cursor: onClick ? 'pointer' : 'normal',
      }}
      onClick={onClick}
    >
      {faIcon && (
        <div className="sgc-icon" style={iconColors[bsType]}>
          <i className={`fa-fw ${faIcon}`} />
        </div>
      )}
      {(title || text) && (
        <div className="sgc-main">
          {title && (
            <div className="sgc-title">{title}</div>
          )}
          {text && (
            <div className="sgc-text">
              {text}
            </div>
          )}
          {subtext && (
            <div className="sgc-subtext">
              {subtext}
            </div>
          )}
        </div>
      )}      
      {items.map(({ id, title, text, subtext }) => (
        <div key={id} className="sgc-main">
          {title && (
            <div className="sgc-title">{title}</div>
          )}
          {text && (
            <div className="sgc-text">
              {text}
            </div>
          )}
          {subtext && (
            <div className="sgc-subtext">
              {subtext}
            </div>
          )}
        </div>
      ))}
      {action && (
        <div className="sgc-action">
          {action}
        </div>
      )}
      {onClickEdit && (
        <div className="sgc-action">
          <a className="btn btn-link" href="#" onClick={onClickEdit}><i className="far fa-fw fa-pencil" /></a>
        </div>
      )}
    </Container>
  );
}
