import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import wurd from 'wurd-react';
import styled from '@emotion/styled';

const DateCell = styled.td({
  width: 120,

  '@media (min-width: 992px)': {
    width: 180
  }
});

const Details = styled.details({
  summary: {
    position: 'absolute',
    right: 10,
    top: 4,
  },
});

const cms = wurd.block('userView.subscriptionStates');

export default function UserSubscriptionState({ item }) {
  const inStorage = item.items.filter(item => item.state === 'storage');
  const notInStorage = item.items.filter(item => item.state !== 'storage');

  return (
    <>
      <DateCell>
        <strong>{item.date}</strong>
      </DateCell>
      <td>
        <span>
          {Object.entries(item.storageCounts || {})
            .map(([itemType, count]) => `${itemType}: ${count}`)
            .join(', ')
              || '–'}
        </span>

        <Details>
          <summary className="btn btn-link"><cms.Text id="details" style={{ marginRight: 8 }} /></summary>
          <table className="table table-condensed" style={{ fontSize: 10, marginTop: 4, marginBottom: 4 }}>
            <tbody>
              {inStorage.map(item =>
                <tr key={item.id}>
                  <td><Link to={`/items/${item.sid}`}>{item.sid.toUpperCase()}</Link></td>
                  <td>{item.type}</td>
                  <td>{item.state}</td>
                </tr>
              )}

              {notInStorage.map(item =>
                <tr key={item.id}>
                  <td><Link to={`/items/${item.sid}`}>{item.sid}</Link></td>
                  <td>{item.type}</td>
                  <td>{item.state}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Details>
      </td>
    </>
  );
}


UserSubscriptionState.propTypes = {
  item: PropTypes.object.isRequired,
};