import { pick } from 'lodash';

import ModalForm from '../../form2/modal-form';
import { Field } from '../../form2';

const fields = [
  'lat',
  'lng',
];


export default function SiteCoordinatesForm({ site, ...rest }) {

  return (
    <ModalForm
      {...rest}
      initialValue={pick(site, fields)}
      wurdSection="site"
    >
      {fieldProps =>
        <div>

          <Field {...fieldProps('lat')} autoFocus />

          <Field {...fieldProps('lng')} />

        </div>

      }
    </ModalForm>
  );
}
