import wurd from 'wurd-react';

import ScanItemsForm from './scan-items-form';
import { LargeButton } from '../../large-button-group';

const cms = wurd.block('valetOrder.itemActions');


export default function ScanItemsButton({
  modal,
  receiveScannedSids,
}) {
  function scanItems() {
    modal.open(
      <ScanItemsForm
        closeModal={modal.close}
        receiveScannedSids={receiveScannedSids}
        cms={cms}
      />,
    );
  }

  return (
    <LargeButton
      faIcon="far fa-qrcode"
      text={<cms.Text id="scanItems" />}
      onClick={scanItems}
    />
  );
}
