import moment from 'moment-timezone';
import _ from 'lodash';


/**
 * Returns charges that are active (i.e. within date range)
 *
 * @param {Object[]} charges
 * 
 * @return {Object[]} currentCharges
 */
export const getCurrent = function (charges) {
  const today = moment().format('YYYY-MM-DD');

  const currentCharges = charges.filter(charge => !charge.endDate || charge.endDate >= today);

  return _.sortBy(currentCharges, ['startDate', 'endDate']);
};


/**
 * Returns charges that are inactive (i.e. oustide of valid date range)
 *
 * @param {Object[]} charges
 * 
 * @return {Object[]} expiredcharges
 */
export const getExpired = function (charges) {
  const today = moment().format('YYYY-MM-DD');

  const expiredCharges = charges.filter(charge => charge.endDate && charge.endDate < today);

  return _.sortBy(expiredCharges, ['startDate', 'endDate']);
};


/**
 * Returns the total amount of active charges
 *
 * @param {Object[]} charges
 * 
 * @return {Number} total
 */
export const getTotal = function (charges) {
  if (!charges || !charges.length) return 0;

  const total = getCurrent(charges).reduce((memo, charge) => {
    return memo + charge.amount * (charge.qty || 1);
  }, 0);

  return total;
};
