import ModalForm from '../../form2/modal-form';
import { Field, DatePicker } from '../../form2';

const wurdSection = 'unitJobs.moveOut.create';


export default function UnitMoveOutForm({ fields, ...rest }) {

  return (
    <ModalForm
      wurdSection={wurdSection}
      mode="create"
      {...rest}
    >
      {(getProps) => {
        return (
          <div>
            <Field {...getProps('date')}>
              <DatePicker
                minDate={null}
              />
            </Field>
          </div>
        );
      }}
    </ModalForm>
  );

}
