import wurd from 'wurd-react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as actions from 'actions';
import * as helpers from 'helpers';
import Section from 'components/section';
import ApiButton from 'components/api-button';
import Alert from 'components/alert';


const cms = wurd.block('siteView');

export default ({ site }) => {
  const navigate = useNavigate();

  const deleteSite = () => {
    const siteCode = window.prompt(cms.text('menu.deleteSite.confirm', site));
    if (siteCode !== site.code) return;

    return actions.sites.delete(site.id)
      .then(() => {
        navigate('/sites');
        helpers.ui.notify({
          bsType: 'success',
          text: <cms.Text id="menu.deleteSite.notify" vars={{ title: helpers.ui.getLangText(site.title), code: site.code }} />,
        });
      })
      .catch(e => {
        helpers.ui.notify({ bsType: 'error', text: e.message });
      });
  };

  return (
    <Section cms={cms.block('dangerZone')}>
      <Alert type='danger'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <cms.Markdown id="menu.deleteSite.desc" />
          <ApiButton bsType="danger" onClick={deleteSite}><cms.Text id="menu.deleteSite.title" /></ApiButton>
        </div>
      </Alert>
    </Section>
  );
}