import wurd from 'wurd-react';

import * as actions from '../../../../actions';

import Section from '../../../section';
import MarketPlansForm from '../../../form/market/plans';
import MarketProductsForm from '../../../form/market/products';


export default function MarketPlansProductsSettings({ market }) {
  return (
    <>
      <Section cms={wurd.block('marketSettings.plans')}>
        <MarketPlansForm
          defaultValue={market}
          onSubmit={(data) => actions.markets.update(market.id, data)}
        />
      </Section>

      <Section cms={wurd.block('marketSettings.products')}>
        <MarketProductsForm
          defaultValue={market}
          onSubmit={(data) => actions.markets.update(market.id, data)}
        />
      </Section>
    </>
  );
}
