import _ from 'lodash';
import store from '../store';
import { getAccountingCodeByCode } from './business';


export function getLangKeys(obj) {
  const languages = store.get('settings')?.languages;

  return Object.fromEntries(
    Object.entries(obj)
      .flatMap(([key, value]) => languages.map(lang => [`${key}.${lang}`, value?.[lang]]))
  );
}

export function isBlank(item) {
  if (item === '' || item == null) return true;
  if (item && typeof item === 'object') return Object.values(item).every(isBlank);
  return false;
}

// remove blank items in arrays
export function compact(obj) {
  if (!obj || typeof obj !== 'object') return obj;

  return _.mapValues(
    obj,
    v => Array.isArray(v)
      ? v.filter(o => !isBlank(o))
      : compact(v)
  );
}


export function parseKeys(o) {
  const row = Object.entries(o).reduce((acc, [k, v]) => {
    // dotted keys are nested
    if (typeof v !== 'string') return acc; // ignore __parsed_extra added by csv lib when there are extra cols
    const dots = k.split('.');
    let value = v, lowValue = value.toLowerCase();
    if (dots[dots.length - 1] === 'accountingCodeId') value = getAccountingCodeByCode(value)?.id || value;
    if (lowValue === 'true' || lowValue === 'false') value = lowValue === 'true';
    if (value === 'null') value = null;
    const obj = dots.reduceRight((a, d) => isFinite(d) ? Array.from({ length: +d + 1, [d]: a }) : { [d]: a }, value);
    return _.merge({ ...acc }, obj);
  }, {});

  // return  Object.fromEntries(
  //   Object.entries(row).map(([k, v]) => [k, Array.isArray(v) ? v.filter(item => !isBlank(item)) : v])
  // );
  return compact(row);
}

export function formatValue(v) {
  if (/^0\d+$/.test(v)) return `'${v}`; // prevent spreadsheet softwares to auto convert to numbers

  return v;
}

export function formatRows(items) {
  if (items.length === 0) return [];

  // fill missing values for arrays subfields in the first item for papaparse columns to work
  const first = _.mapValues(
    _.mergeWith({}, ...items, (oldValue, newValue) => newValue == null ? oldValue : undefined),
    (v, k) => Array.isArray(v) && v.every(_.isPlainObject)
      ? v.map((o, i) => items[0][k]?.[i] || _.mergeWith({}, o, (x, y) => Array.isArray(y) && y.every(_.isPlainObject) || _.isPlainObject(y) ? undefined : ''))
      : _.isPlainObject(v)
        ? _.mapValues(v, (vi , ki) => items[0][k]?.[ki])
        : items[0][k]
  );

  return [first, ...items.slice(1)].map(item => Object.fromEntries(Object.entries(item).flatMap(([k, v]) => {
    // multilang object (plain objects)
    if (_.isPlainObject(v)) return Object.entries(v).map(([ki, vi]) => [`${k}.${ki}`, vi]);
    // array
    if (Array.isArray(v) && v.every(_.isPlainObject)) return v.flatMap((o, i) => Object.entries(o).flatMap(([ki, vi]) => {
      if (_.isPlainObject(vi)) return Object.entries(vi).map(([kx, vx]) => [`${k}.${i}.${ki}.${kx}`, formatValue(vx)]);
      return [[`${k}.${i}.${ki}`, formatValue(vi)]];
    }));
    
    return [[k, formatValue(v)]];
  })));
}