import wurd from 'wurd-react';

import { useModal, useHistoryListQuery } from '../hooks';

import Page from '../components/page';
import PaymentList from 'components/payment-list';
import Link from 'components/nested-link';

const cms = wurd.block('paymentList');


export default function PaymentListPage() {
  const modal = useModal();
  const listQuery = useHistoryListQuery();

  return (
    <Page
      titleKey={cms.id('title')}
      userRole="admin"
      modal={modal}
      extra={(
        <Link to="/invoices" className="btn btn-link">
          <cms.Text id="invoicesLink" />
        </Link>
      )}
    >
      <PaymentList
        modal={modal}
        listQuery={listQuery}
      />
    </Page>
  );
}