import wurd from 'wurd-react';

export default function Modal({
  cms,
  title = cms && <cms.Text id="title" />,
  subtitle,
  footer,
  closeModal,
  noPadding,
  children,
}) {
  return (
    <div>
      {title && (
        <div className="modal-header">
          {closeModal && (
            <button type="button" className="close" onClick={closeModal}><span aria-hidden="true">×</span></button>
          )}

          <h4 className="modal-title">
            {title}
            {subtitle && (
              <small className="text-muted">&nbsp;{subtitle}</small>
            )}
            {cms?.text('helpUrl') && (
              <cms.Object keys="helpUrl" className="pull-right">
                <a
                  className="btn btn-link btn-sm hidden-print"
                  href={cms.text('helpUrl')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="far fa-fw fa-question-circle" title={wurd.text('common.phrases.help')} />
                </a>
              </cms.Object>
            )}
          </h4>
        </div>
      )}
      <div className="modal-body clearfix" style={noPadding && { padding: 0 }}>
        {children}
      </div>
      {footer && (
        <div className="modal-footer">
          {footer}
        </div>
      )}
    </div>
  );
}

export function ModalDialog({ cms, children }) {
  return (
    <Modal cms={cms}>
      <cms.Markdown id="intro" className="text-muted" />

      <div className="text-center" style={{ marginTop: 40 }}>
        {children}
      </div>
    </Modal>
  );
}
