// @flow
import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Beforeunload } from 'react-beforeunload';
import { Button } from 'react-bootstrap';
import { hasRole } from 'helpers/auth';

import MapBuilder from './MapBuilder';
import SidePanel from './SidePanel';
import FloorSelector from './FloorSelector';
import ZoomControls from './ZoomControls';

import {
  SIDE_BAR_WIDTH,
  BLOCK_SNAP_SIZE,
  SCALE,
} from './Constants';


export default class Sitemap extends Component {

  static propTypes = {
    units: PropTypes.array.isRequired,
    objects: PropTypes.array.isRequired,
    positions: PropTypes.array.isRequired,
    floor: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    onClickUnit: PropTypes.func,
  };

  state = {
    editMode: this.props.editMode || false,
    selectedItem: null, // ItemData & {shapeName: string} Konva shape name
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);

    const { zoom = 1, position = { x: 0, y: 0 } } = this.props;
    if (this.mapBuilder) {
      // this.mapBuilder.setZoom(zoom); // sets initial zoom
      this.mapBuilder.stage.scale({ x: zoom, y: zoom });
      this.mapBuilder.stage.position(position);
      this.mapBuilder.stage.batchDraw();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);

    this.saveToLocalStorage();
  }

  componentDidUpdate(prevProps) {
    if (this.mapBuilder && (prevProps.zoom !== this.props.zoom || prevProps.position !== this.props.position)) {
      const { zoom = 1, position = { x: 0, y: 0 } } = this.props;
      this.mapBuilder.stage.scale({ x: zoom, y: zoom });
      this.mapBuilder.stage.position(position);
      this.mapBuilder.stage.batchDraw();
    }
    if (this.props.units !== prevProps.units) { // units got loaded, check that our floor is in the existingFloors
      const existingFloors = this.getExistingFloors();
      if (!existingFloors.includes(this.props.floor)) {
        this.props.onChangeFloor(existingFloors[0]);
      }
    }
  }

  enableEditMode = () => {
    this.setState({ editMode: true });
  };

  saveToLocalStorage = () => {
    if (!this.mapBuilder) return;
    const { x, y } = this.mapBuilder.stage.attrs;
    const zoom = this.mapBuilder.stage.scaleX();
    const floorData = {
      position: { x, y },
      zoom,
    };

    const { floor, onSaveToLocalStorage } = this.props;

    onSaveToLocalStorage(floor, floorData);
  };

  changeFloor = (floor) => {
    this.saveToLocalStorage(); // save to localStorage the floors positions first

    this.props.onChangeFloor(floor)
  };

  cancelSitemapChanges = () => {
    this.props.onReset();

    this.setState({ editMode: false, selectedItem: null });
  };

  saveSitemapChanges = () => {
    this.props.onSave();

    this.setState({ editMode: false, selectedItem: null });
  };

  removeSelectedItem = () => {
    const { selectedItem } = this.state;

    this.props.onRemovePosition(selectedItem);

    this.setState({
      selectedItem: null,
    });
  }

  handleKeyDown = (e) => {
    const { editMode, selectedItem } = this.state;

    switch (e.key) {
      case 'Delete':
        if (editMode && selectedItem) {
          this.removeSelectedItem();
        }
        break;
      case 'Escape':
        this.saveSitemapChanges();
        break;
      case 'o':
        if (e.altKey) {
          window.localStorage.removeItem('sitemap');
          this.props.onChangeFloor();
        }
    }
  }

  onClickUnit = (unit) => {
    const { onClickUnit } = this.props;

    if (!onClickUnit || !unit) return;

    onClickUnit(unit);
  }

  onSelectItem = (item) => {
    this.setState({ selectedItem: item });
  }

  getExistingFloors = () => {
    const { units, positions } = this.props;

    const existingFloors = [
      ...units.map(unit => unit.floor),
      ...positions.map(position => position.floor)
    ];

    return _.uniqBy(existingFloors).sort((a, b) => a.localeCompare(b));
  }

  // Returns units that have not been given a position yet
  getUnpositionedUnits = () => {
    const { units, positions } = this.props;

    return units.filter(unit => !positions.find(position => position.id === unit.id));
  }

  handleZoom = (scaleBy) => {
    if (this.mapBuilder) this.mapBuilder.handleZoom(scaleBy);
  }


  render() {
    const { objects, positions, getSitemapData, floor, width, height, onUpdatePosition, onAddPosition, highlightUnitIds } = this.props;

    const {
      editMode,
      selectedItem,
    } = this.state;

    const containerStyle = {
      flexDirection: 'row',
      display: 'flex',
      width,
      height,
    };
    const sidePanelStyle = {
      position: 'absolute',
      top: 0,
      width: SIDE_BAR_WIDTH,
    };
    const floorSelectorStyle = {
      position: 'absolute',
      bottom: 90,
      right: 10,
    };
    const zoomContainerStyle = {
      position: 'absolute',
      bottom: 10,
      right: 10,
    };
    const editButtonStyle = {
      position: 'absolute',
      top: 10,
      right: 10,
      width: 40,
      height: 40,
    };

    return (
      <div style={containerStyle}>
        <MapBuilder
          ref={ref => (this.mapBuilder = ref)}
          positions={positions.filter(position => position.floor === floor)}
          getSitemapData={getSitemapData}
          updateItemPosition={onUpdatePosition}
          onClickUnit={this.onClickUnit}
          height={height}
          width={width}
          blockSnapSize={BLOCK_SNAP_SIZE}
          scale={SCALE}
          editMode={editMode}
          selectedItem={selectedItem}
          onSelectItem={this.onSelectItem}
          highlightUnitIds={highlightUnitIds}
        />

        {!editMode && hasRole('manager') && (
          <Button onClick={this.enableEditMode} style={editButtonStyle} bsStyle="default"><i className="fas fa-fw fa-edit" /></Button>
        )}
        {editMode && selectedItem && (
          <Button onClick={this.removeSelectedItem} style={editButtonStyle} bsStyle="danger"><i className="fas fa-fw fa-trash" /></Button>
        )}

        {editMode && (
          <SidePanel
            style={sidePanelStyle}
            units={this.getUnpositionedUnits().filter(i => i.floor === floor)}
            objects={objects}
            handleAddItem={onAddPosition}
            handleCancel={this.cancelSitemapChanges}
            handleSave={this.saveSitemapChanges}
          />
        )}

        <FloorSelector
          style={floorSelectorStyle}
          floors={this.getExistingFloors()}
          active={floor}
          onChange={this.changeFloor}
        />

        <ZoomControls
          style={zoomContainerStyle}
          handleZoom={this.handleZoom}
        />

        <Beforeunload onBeforeunload={this.saveToLocalStorage} />
      </div>
    );
  }
}
