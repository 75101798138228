import api from '../utils/api';
import { update, updateCachedData } from './general';
import errHandler from './_err-handler';
import crudList from './plugins/crud-list';
import labelsPlugin from '../plugins/labels/actions';


const endpoint = 'invoices';
const url = `/v1/admin/${endpoint}`;

const actions = {};

function handleResponse(res) {
  updateCachedData(endpoint, res.data);

  return res.data;
}


/**
 * Creates an invoice for a given user
 *
 * @param {String} userId
 * @param {Object} props
 */
actions.create = function (userId, props) {
  return api.post(`/v1/admin/users/${userId}/invoices`, props)
    .then(handleResponse)
    .catch(errHandler);
};


actions.update = async function (id, data) {
  return update(endpoint ,id, data);
};

actions.delete = function (id) {
  return api.delete(`${url}/${id}`)
    .then(res => {
      updateCachedData(endpoint, { id, deleted: true });

      return res.data;
    })
    .catch(errHandler);
};


actions.send = function (id) {
  return api.post(`${url}/${id}/send`)
    .then(handleResponse)
    .catch(errHandler);
};


/* actions.charge = function (id) {
  return api.post(`${url}/${id}/charge`)
    .then(handleResponse)
    .catch(errHandler);
}; */


actions.process = function (id) {
  return api.post(`${url}/${id}/process`)
    .then(handleResponse)
    .catch(errHandler);
};


actions.enableSharing = function (id) {
  return api.post(`${url}/${id}/share`)
    .then(handleResponse)
    .catch(errHandler);
};


/**
 * Returns CRUD actions
 */
actions.items = function (invoiceId) {
  return crudList(`/v1/admin/invoices/${invoiceId}/items`);
};

actions.payments = function (invoiceId) {
  return crudList(`/v1/admin/invoices/${invoiceId}/payments`);
};


actions.entries = function (invoiceId) {
  return {
    create(data) {
      return api.post(`${url}/${invoiceId}/entries`, data)
        .then(handleResponse)
        .catch(errHandler);
    },

    update(entryId, data) {
      return api.put(`${url}/${invoiceId}/entries/${entryId}`, data)
        .then(handleResponse)
        .catch(errHandler);
    },

    delete(entryId) {
      return api.delete(`${url}/${invoiceId}/entries/${entryId}`)
        .then(handleResponse)
        .catch(errHandler);
    },
  };
};

actions.labels = labelsPlugin('invoices');


export default actions;
