import { useParams } from 'react-router-dom';
import wurd from 'wurd-react';

import { useModal, useItemLoader } from '../../hooks';
import * as helpers from '../../helpers';
import * as actions from '../../actions';

import DetailPage from '../../components/detail-page';
import NotFound from '../../components/not-found';
import Alert from '../../components/alert';
import Menu from '../../components/jobs/unit_move-in/menu';
import SidebarSummary from '../../components/jobs/unit_move-in/sidebar-summary';
import StepList from '../../components/jobs/unit_move-in/step-list';
import Details from '../../components/jobs/unit_move-in/details';
import Invoices from '../../components/jobs/unit_move-in/invoices';
import History from '../../components/jobs/unit_move-in/history';
import LabelsList from 'plugins/labels/detail';

const cms = wurd.block('unitJobs.moveIn');

const sectionComponents = {
  steps: StepList,
  details: Details,
  invoices: Invoices,
  history: History,
};


export default function UnitMoveInPage({
  site,
  job,
  fetchJob,
}) {
  const { section = 'steps' } = useParams();
  const modal = useModal();
  const { item: owner } = useItemLoader('users', job.result.ownerId, { include: ['customFields'] });

  const sid = helpers.ui.sid(job);
  const sections = [
    { name: 'steps', title: 'Progress', icon: 'far fa-tasks' },
    { name: 'details', title: 'Summary', icon: 'far fa-info-circle' },
    { name: 'invoices', title: 'Invoices', icon: 'far fa-file-invoice-dollar' },
    { name: 'history', title: 'History', icon: 'far fa-history' },
  ];

  const CurrentSection = sectionComponents[section];
  if (!CurrentSection) return <NotFound />;

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="admin"
      documentTitle={cms.text('title', { sid })}
      title={<cms.Text id="title" vars={{ sid }} />}
      ancestors={[
        { text: helpers.ui.getLangText(site.title), url: `/sites/${site.code}` },
        { text: 'Jobs', url: `/sites/${site.code}/jobs` },
      ]}
      sections={sections}
      currentSection={section}
      baseUrl={`/sites/${site.code}/jobs/${sid}`}
      extra={(
        <>
          <LabelsList
            labels={job.labels}
            settingsLabels={helpers.settings.get().jobLabels}
            onChange={labels => actions.jobs.labels.update(job.id, labels)}
          />

          <Menu
            job={job}
            site={site}
            owner={owner}
            modal={modal}
          />
        </>
      )}
      sidebar={(
        <SidebarSummary
          job={job}
          owner={owner}
          modal={modal}
        />
      )}
    >
      <CurrentSection
        site={site}
        job={job}
        owner={owner}
        cms={cms}
        onChange={fetchJob}
        modal={modal}
      />
    </DetailPage>
  );
}
