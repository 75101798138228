import ModalForm from 'components/form2/modal-form';
import Field from 'components/form2/horizontal-field';
import MultiLangText from 'components/form2/multi-lang-text';


export default function CreateAddonForm(props) {
  return (
    <ModalForm
      wurdSection="addons.create"
      {...props}
    >
      {(getProps) => {
        return (
          <>
            <Field {...getProps('title')} required>
              <MultiLangText />
            </Field>
          </>
        );
      }}
    </ModalForm>
  );
}
