import MoveInJob from './jobs/move-in';
import MoveOutJob from './jobs/move-out';
import TransferJob from 'components/jobs/unit_transfer/step-list';

const jobFlows = {
  'unit_moveIn': MoveInJob,
  'unit_moveOut': MoveOutJob,
  'unit_transfer': TransferJob,
};


export default function UnitActiveJob({
  cms,
  site,
  unit,
  rental,
  fetchUnit,
  modal,
}) {
  // See if there's an activeJob of the type that has the built-in job flow UI
  const job = rental?.activeJobs?.find(job => jobFlows[job.type]);
  if (!job) return null;

  const JobFlow = jobFlows[job.type];
  
  return (
    <JobFlow
      cms={cms}
      job={job}
      site={site}
      unit={unit}
      rental={rental}
      onChange={fetchUnit}
      modal={modal}
    />
  );
}
