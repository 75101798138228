import Activity from '../activity';


export default function SiteHistory({ site }) {
  return (
    <Activity
      context="site"
      endpoint={`/sites/${site.id}/actions`}
    />
  );
}
