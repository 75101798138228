import wurd from 'wurd-react';
import moment from 'moment-timezone';
import styled from '@emotion/styled';

import * as helpers from 'helpers';

import ModalForm from '../modal-form';
import Field from '../horizontal-field';
import DatePicker from '../datepicker';
import FormObject from '../object';
import store from 'store';
import CustomField from '../../../plugins/custom-fields/field';


const cms = wurd.block('valetOrder.form');


const Details = styled.details({
  borderBottom: '1px solid #eee',

  '& > summary': {
    fontSize: 14,
    fontWeight: 600,
    padding: '10px 5px 10px 20px',

    '&:focus, &:active': {
      outline: 'none'
    }
  }
})


export default function ValetOrderForm({ user, mode, ...rest }) {
  const { settings } = store.get();

  const emptyBoxTypes = helpers.item.listEmpty(true);
  const bulkyTypes = helpers.item.listBulky(true);

  return (
    <ModalForm
      wurdSection={cms.id()}
      mode={mode}
      {...rest}
    >
      {(getProps, valetOrder) => {
        if (!valetOrder.collectDate || valetOrder.collectDate < valetOrder.date) {
          valetOrder.collectDate = valetOrder.date;
        }

        if (valetOrder.collectDate && valetOrder.collectTimeslot) {
          valetOrder.collect = 'later';
        }

        if (!valetOrder.collectTimeslot && valetOrder.collect !== 'later') {
          valetOrder.collectTimeslot = valetOrder.timeslot;
        }

        const deliverTimeslots = helpers.valetOrder.getTimeslotsForDate(user, valetOrder.date);
        const collectTimeslots = helpers.valetOrder.getTimeslotsForDate(user, valetOrder.collectDate);

        const showCollectDate = helpers.valetOrder.formShouldHaveCollectDate(valetOrder);

        return (
          <div>
            <Details open>
              <summary><cms.Text id="boxCounts.label" vars={{ count: emptyBoxTypes.length }} /></summary>
              <Field {...getProps('boxCounts')} label={null}>
                <FormObject>
                  {({ getProps }) => (
                    <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
                      {emptyBoxTypes.map(container => (
                        <Field
                          {...getProps(container.type)}
                          key={container.type}
                          type="number"
                          min="0"
                          label={container.title}
                        />
                      ))}
                    </div>
                  )}
                </FormObject>
              </Field>
            </Details>


            {mode === 'create' && (
              <Details>
                <summary><cms.Text id="bulkyCounts.label" vars={{ count: bulkyTypes.length }} /></summary>
                <Field {...getProps('bulkyCounts')} label={null}>
                  <FormObject>
                    {({ getProps }) => (
                      <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
                        {bulkyTypes.map(bulky => (
                          <Field
                            {...getProps(bulky.type)}
                            key={bulky.type}
                            type="number"
                            min="0"
                            label={bulky.title}
                          />
                        ))}
                      </div>
                    )}
                  </FormObject>
                </Field>
              </Details>
            )}

            {settings.products.length > 0 && (
              <Details>
                <summary><cms.Text id="productCounts.label" vars={{ count: settings.products.length }} /></summary>
                <Field {...getProps('productCounts')} label={null}>
                  <FormObject>
                    {({ getProps }) => (
                      <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
                        {settings.products.map(product => (
                          <Field
                            {...getProps(product.id)}
                            key={product.slug}
                            type="number"
                            min="0"
                            label={helpers.ui.getLangText(product.title)}
                          />
                        ))}
                      </div>
                    )}
                  </FormObject>
                </Field>
              </Details>
            )}

            <Details>
              <summary><cms.Text id="deliverItems.label" /></summary>
              <div className="well"><cms.Text id="deliverItems.movedInfo" /></div>
            </Details>

            <Details>
              <summary><cms.Text id="collectItems.label" /></summary>
              <div className="well"><cms.Text id="collectItems.movedInfo" /></div>
            </Details>


            <Field {...getProps('address')} required>
              <textarea />
            </Field>

            <Field {...getProps('area')} required>
              <select>
                <option value="">{cms.text('selectArea')}</option>
                {helpers.area.list(true).map(area => (
                  <option key={area.id} value={area.id}>{area.title}</option>
                ))}
              </select>
            </Field>

            <Field {...getProps('phone')} required />

            <Field {...getProps('instructions')}>
              <textarea />
            </Field>

            <Field {...getProps('altPhone')} />

            {settings.valetOrderCustomFields.length > 0 && (
              <>
                <hr />
                {settings.valetOrderCustomFields.sort(helpers.ui.sortByCode).map(({ code, ...cfProps }) => (
                  <CustomField
                    key={code}
                    {...getProps(`customFields.${code}`)}
                    {...cfProps}
                  />
                ))}
              </>
            )}

            <hr />

            <Field {...getProps('date')} required>
              <DatePicker />
            </Field>

            <Field {...getProps('timeslot')} required>
              <select>
                <option value="">
                  {cms.text(deliverTimeslots.length === 0 ? 'noTimeslot' : 'selectTimeslot')}
                </option>
                {deliverTimeslots.map(timeslot => (
                  <option key={timeslot} value={timeslot}>{timeslot}</option>
                ))}
              </select>
            </Field>

            {!!showCollectDate && (
              <div>
                <Field {...getProps('collectDate')}>
                  <DatePicker minDate={moment(valetOrder.date)} />
                </Field>

                <Field {...getProps('collectTimeslot')}>
                  <select>
                    <option value="">
                      {(collectTimeslots.length
                        ? 'Select a timeslot or leave it empty to collect immediately...'
                        : 'No timeslots available for this date'
                      )}
                    </option>
                    {collectTimeslots.map(timeslot => (
                      <option key={timeslot} value={timeslot}>{timeslot}</option>
                    ))}
                  </select>
                </Field>
              </div>
            )}
          </div>
        );
      }}
    </ModalForm>
  );
}