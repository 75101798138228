import _ from 'lodash';

import * as settingsHelpers from './settings';
import { getLangText } from './ui';
import { currentLang } from 'utils/language';


function doTranslate(original, lang) {
  const translated = Object.assign({}, original);

  ['title', 'note'].forEach(prop => {
    translated[prop] = getLangText(translated[prop], lang);
  });

  return translated;
}


/**
 * Returns the list of areas
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const list = function (translate) {
  const settings = settingsHelpers.get();
  if (!settings) return [];

  const areas = settings.areas || [];

  if (translate) {
    return areas.map(area => doTranslate(area, currentLang));
  } else {
    return areas;
  }
};


/**
 * Returns a specific area
 *
 * @param {String} id
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const get = function (id, translate) {
  if (!id) return {};

  const area = _.find(list(), { id: id });

  if (!area) {
    const err = new Error('area config not found for ' + id);
    //_errs.push(err);
    console.error(err);
    return {};
  }

  if (translate) {
    return doTranslate(area, currentLang);
  } else {
    return area;
  }
};


/**
 * Gets the name of an area given it's id
 *
 * @param {String} id
 */
export const getTitle = function (id) {
  const area = get(id, true) || {};

  return area.title;
};


/**
 * Returns titles for item types, keyed by type and in the user's language
 *
 * @return {Object}
 */
export const titlesById = function () {
  return list().reduce((memo, area) => {
    memo[area.id] = getLangText(area.title, currentLang);
    return memo;
  }, {});
};
