import { WurdText } from 'wurd-react';
import store from 'store';
import { getLangText } from 'helpers/ui';
import { currentLang } from 'utils/language';
import LangText from 'components/lang-text';
import { Editable } from 'components/markdown';
import { Field, MultiLangText, ModalForm2 } from 'components/form2';


export default ({ block, setBlock, modal }) => {
  const { settings } = store.get();

  return (
    <div style={{ padding: '.5rem 1rem' }}>
      <h4 className="text-center" style={{ fontWeight: 400, marginBottom: '.5rem' }}>
        <Editable inline placeholder="[Title]" onChange={e => setBlock({ title: { [currentLang]: e.target.value } })}>{getLangText(block.title)}</Editable>
      </h4>

      {block.items?.map((item, i) => item && (
        <details key={i}>
          <summary>
            <Editable
              placeholder="[Question]"
              onChange={e => {
                if (!e.target.value && !getLangText(item.text).trim()) return setBlock({ items: block.items.filter((_, j) => i !== j) });
                setBlock({ items: block.items.map((b, j) => i === j ? { ...b, title: { ...b.title, [currentLang]: e.target.value } } : b) });
              }}
            >
              {getLangText(item.title)}
            </Editable>
          </summary>

          <div>
            <Editable
              placeholder="[Answer]"
              onChange={e => setBlock({ items: block.items.map((b, j) => i === j ? { ...b, text: { ...b.text, [currentLang]: e.target.value } } : b) })}
            >
              {getLangText(item.text)}
            </Editable>
          </div>
        </details>
      ))}
      <button type="button" className="btn btn-link" onClick={() => setBlock({ items: [...block.items || [], { title: { [currentLang]: 'Question' }, text: { [currentLang]: 'Answer' } }] })}><i className="far fa-plus" /></button>
    </div>
  );
}
