import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import wurd from 'wurd-react';
import moment from 'moment-timezone';
import _ from 'lodash';

import api from '../../../utils/api';
import * as config from '../../../config';
import * as actions from '../../../actions';
import { hasRole } from 'helpers/auth';
import errHandler from '../../../actions/_err-handler';

import Section from '../../section';
import SelectMultiple from '../../select-multiple';
import SettingsForm2 from '../form2';
import Input from '../input';
import FormInput from '../../form2/input';


const cms = wurd.block('settings');

export default function PluginsSettings({ settings }) {
  const { data: zohoDashboards = [], isLoading, error } = useQuery(`zd-${settings.zoho?.workspace}`, () => api.get('/v1/admin/analytics/dashboards').then(r => r.data), { enabled: !!settings.zoho?.workspace });

  const resetZoho = useMutation(async e => {
    const timeRange = window.prompt(`Reset zoho_export job?\n\nOptional time range comma-separated - leave this empty to reset from scratch`);
    if (timeRange === null) return;

    const [activeJob] = await actions.fetch('jobs', { type: 'zoho_export', state: 'ready', limit: 1 });
    if (!activeJob) return window.alert('Could not find an active zoho_export job, please restart the server');

    await actions.jobs.runOperation(activeJob.id, 'reset', {
      updatedAfter: timeRange.split(',')[0],
      updatedBefore: timeRange.split(',')[1],
    });
  });

  return (
    <Section cms={cms.block('plugins')}>
      <SettingsForm2
        wurdSection="settings.inputs"
        initialValue={settings}
        onSubmit={(data, dataPatches) => actions.business.update(dataPatches)}
      >
        {({ fieldProps, formValue }) => (
          <div>
            {hasRole('__superadmin') && (
              <>
                <cms.Text type="h4" id="plugins.billing" />
                <Input {...fieldProps('billing')} type="select" wurdObjectKeys="label,help,none">
                  <option value="">{cms.text('inputs.billing.none')}</option>
                  <option value="braintree">Braintree</option>
                  <option value="stripe">Stripe</option>
                  <option value="gmopg">GMO-PG</option>
                  <option value="cybersource">Cybersource</option>
                </Input>

                {(formValue.billing === 'braintree') && (
                  <>
                    <Input {...fieldProps('_braintree.environment')} type="select" required>
                      <option value=""></option>
                      <option value="Sandbox">Sandbox</option>
                      <option value="Production">Production</option>
                    </Input>
                    <Input {...fieldProps('_braintree.merchantId')} required type="secret" />
                    <Input {...fieldProps('_braintree.publicKey')} required type="secret" />
                    <Input {...fieldProps('_braintree.privateKey')} required type="secret" />
                  </>
                )}

                {(formValue.billing === 'stripe') && (
                  <>
                    <Input {...fieldProps('_stripe.currency')} required defaultValue="USD" />
                    <Input {...fieldProps('_stripe.secretKey')} required type="secret" />
                    <Input {...fieldProps('_stripe.publishableKey')} required type="secret" />

                    <div className="text-center">
                      <a href={`${config.apiUrl}/v1/admin/billing/stripe/connect`} target="_blank" rel="noopener noreferrer" className="btn-link">Start Stripe Connect</a>
                    </div>
                  </>
                )}

                {(formValue.billing === 'gmopg') && (
                  <>
                    <Input {...fieldProps('_gmopg.baseUrl')} placeholder={(!settings._gmopg || !settings._gmopg.baseUrl || !settings._gmopg.SiteID) ? `https://kt01.mul-pay.jp (${wurd.text('settings.inputs._gmopg._testWarning')})` : undefined} type="secret" />
                    <Input {...fieldProps('_gmopg.SiteID')} type="secret" />
                    <Input {...fieldProps('_gmopg.SitePass')} type="secret" />
                    <Input {...fieldProps('_gmopg.ShopID')} type="secret" />
                    <Input {...fieldProps('_gmopg.ShopPass')} type="secret" />
                    <Input {...fieldProps('_gmopg.directDebitTargetDate')} defaultValue="27" type="select">
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="23">23</option>
                      <option value="27">27</option>
                    </Input>
                  </>
                )}

                {(formValue.billing === 'cybersource') && (
                  <>
                    <Input {...fieldProps('_cybersource.environment')} type="select">
                      <option value="Test">Test</option>
                      <option value="Production">Production</option>
                      <option value="ProductionIndia">ProductionIndia</option>
                    </Input>
                    <Input {...fieldProps('_cybersource.merchantId')} />
                    <Input {...fieldProps('_cybersource.sa_profileId')} type="secret" />
                    <Input {...fieldProps('_cybersource.sa_accessKey')} type="secret" />
                    <Input {...fieldProps('_cybersource.sa_secretKey')} type="secret" />
                    <Input {...fieldProps('_cybersource.api_keyId')} type="secret" />
                    <Input {...fieldProps('_cybersource.api_secretKey')} type="secret" />
                    <Input {...fieldProps('_cybersource.currency')} defaultValue="USD" />
                  </>
                )}

                <hr />
              </>
            )}

            {hasRole('__superadmin') && (
              <>
                <cms.Text type="h4" id="plugins.zoho" />

                <details open={Object.values(settings._zoho || {}).some(Boolean)}>
                  <summary style={{ width: '66%', marginLeft: 'auto' }}><cms.Text id="plugins._zoho" /></summary>
                  <Input {...fieldProps('_zoho.orgId')} />
                  <Input {...fieldProps('_zoho.clientId')} />
                  <Input {...fieldProps('_zoho.clientSecret')} />
                  <Input {...fieldProps('_zoho.refreshToken')} />
                  {!formValue.zoho?.exportJobEnabled && <Input {...fieldProps('_zoho.trialBusinessId')} />}
                </details>
                <Input {...fieldProps('zoho.exportJobEnabled')} type="checkbox" />
                <Input {...fieldProps('zoho.showTo')} type="select" wurdObjectKeys="label,help,none,admin,manager">
                  <option value="">{cms.text('inputs.zoho.showTo.none')}</option>
                  <option value="manager">{cms.text('inputs.zoho.showTo.manager')}</option>
                  <option value="admin">{cms.text('inputs.zoho.showTo.admin')}</option>
                </Input>
                <Input {...fieldProps('zoho.workspace')} />
                <SelectMultiple as={Input} type="select" multiple {...fieldProps('zoho.dashboards')}>
                  {zohoDashboards.map(o => <option key={o.id} value={`${o.id}#${o.name}`}>{o.name}</option>)}
                </SelectMultiple>

                <div className="text-center">
                  {resetZoho.isSuccess
                    ? <Link to="/jobs?state&type=zoho_export" className="btn-link">See jobs</Link>
                    : <button disabled={resetZoho.isLoading} type="button" className="btn-link" onClick={resetZoho.mutate}>{resetZoho.isLoading && <i className="far fa-spin fa-spinner" />} Reset job</button>}
                </div>

                <hr />
              </>
            )}

            {hasRole('__superadmin') && (
              <>
                <cms.Text type="h4" id="plugins.azureAD" />
                <Input {...fieldProps('_azureAD.clientId')} required type="secret" />
                <Input {...fieldProps('_azureAD.tenantId')} type="secret" />
                <Input {...fieldProps('_azureAD.enabled')} type="select" wurdObjectKeys="label,help,no,sso,sso_password">
                  <option value="">{cms.text('inputs._azureAD.enabled.no')}</option>
                  <option value="sso">{cms.text('inputs._azureAD.enabled.sso')}</option>
                  <option value="sso+password">{cms.text('inputs._azureAD.enabled.sso_password')}</option>
                </Input>

                <hr />
              </>
            )}

            <cms.Text type="h4" id="plugins.google" />
            <Input {...fieldProps('_google.clientId')} required type="secret" />
            <Input {...fieldProps('_google.enabled')} type="select" wurdObjectKeys="label,help,no,adminApp,userApp,all">
              <option value="">{cms.text('inputs._google.enabled.no')}</option>
              <option value="adminApp">{cms.text('inputs._google.enabled.adminApp')}</option>
              <option value="userApp">{cms.text('inputs._google.enabled.userApp')}</option>
              <option value="*">{cms.text('inputs._google.enabled.all')}</option>
            </Input>

            <hr />

            <cms.Text type="h4" id="plugins.sendgrid" />
            <Input {...fieldProps('_sendgrid.apiKey')} type="secret" />
          </div>
        )}
      </SettingsForm2>
    </Section>
  );
}
