import { WurdText } from 'wurd-react';
import { Tabs, Tab, Button } from 'react-bootstrap'

import colors from '../colors'

import Unit from './Unit';
import Marker from './Marker';

const styles = {
  container: {
    height: '100%',
    width: '100%',
    background: colors.sidePanel,
    display: 'flex',
    flexDirection: 'column',
    borderRight: '2px solid rgba(0,0,0,.1)'
  },
  button: {
    margin: '4px 4px 0',
    height: 40,
    maxWidth: 116,
  }
};


export default function SidePanel({ units, objects, style, handleAddItem, handleCancel, handleSave }) {
  return (
    <div style={{ ...styles.container, ...style }}>
      <Tabs defaultActiveKey="units" id="tabs" style={{ flex: 1, overflowY: 'scroll' }}>
        <Tab eventKey="units" title="Units">
          <ul className="list-unstyled">
            {units.length === 0 && (
              <div>All units for this floor have already been placed.</div>
            )}

            {units.length > 0 && units.map(unit => (
              <Unit
                key={unit.id}
                unit={unit}
                onClick={() => handleAddItem(unit)}
              />
            ))}
          </ul>
        </Tab>
        <Tab eventKey="objects" title="Objects">
          <ul className="list-unstyled">
            {objects && objects.map((item, index) => (
              <Marker
                key={index}
                item={item}
                onClick={() => handleAddItem(item)}
              />
            ))}
          </ul>
        </Tab>
      </Tabs>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <Button onClick={handleCancel} style={{ flex: 1, ...styles.button }}>
          <WurdText id="common.cancelBtn" />
        </Button>
        <Button onClick={handleSave} style={{ flex: 1, ...styles.button }} bsStyle="info">
          <WurdText id="common.saveBtn" />
        </Button>
      </div>
    </div>
  )
}
