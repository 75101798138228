import api from '../utils/api';
import { fetch, fetchOne, create, update, updateCachedData } from './general';
import errHandler from './_err-handler';
import crudList from './plugins/crud-list';
import labelsPlugin from '../plugins/labels/actions';

const endpoint = 'valet-orders';
const url = `/v1/admin/${endpoint}`;
const include = 'customFields';

function handleResponse(res) {
  updateCachedData(endpoint, res.data);

  return res.data;
}

const actions = {};


/**
 * List
 *
 * @param {Object} [params]          Query parameters
 */
actions.fetch = function (params = {}) {
  return fetch(endpoint, params);
};


/**
 * Fetches a single valetOrder
 * 
 * @param {Object} [params]          Query parameters
 * @param {String} [params.include]
 */
actions.fetchOne = function (id, params = {}) {
  return fetchOne(endpoint, id, { include, ...params });
};


actions.create = async function (ownerId, data) {
  const fullData = { ...data, ownerId };

  return create(endpoint, fullData);
};


actions.update = async function (orderId, data) {
  return update(endpoint, orderId, data, { include });
};


actions.updateStep = function (orderId, stepId, props) {
  return api.put(`${url}/${orderId}/steps/${stepId}`, props)
    .then(handleResponse)
    .catch(errHandler);
};


actions.charges = function (orderId) {
  return crudList(`${url}/${orderId}/charges`);
};

actions.labels = labelsPlugin('valet-orders');

export default actions;
