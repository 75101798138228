import * as actions from '../../../actions';


async function searchOrder(e, job) {
  e.stopPropagation();

  const _job = await actions.jobs.runOperation(job.id, 'search');
}

async function cancelOrder(e, job) {
  e.stopPropagation();
  const sure = window.confirm(`Want to ${job.state === 'ready' ? 'cancel' : 'clear'} GMOPG order: ${job.data.order.OrderID} on job ${job.sid}?`);

  if (!sure) return;

  actions.jobs.runOperation(job.id, 'cancel');
}

const statusClassnames = {
  'Unknown': 'text-warning',
  'UNPROCESSED': 'text-danger', // invoice unpaid
  'SEND': 'text-warning', // invoice processing
  'REQSUCCESS': 'text-warning', // invoice registration
  'PAYSUCCESS': 'text-success', // invoice success
  'PAYFAIL': 'text-danger', // invoice failed
  'CANCEL': 'text-danger', // invoice cancelled
};

// remove Japanese from error message
const cleanupErrMsg = (msg) => msg.replace(/^(\[.*\]) .* \/ (.+)$/, '$1 $2');

export default ({ job, refetch }) => {
  const { searchRes = {}, searchErr } = job.result || {};
  const status = searchRes.Status || 'Unknown';

  if (job.state !== 'ready') return null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span className={statusClassnames[status]}>{status}</span>
      {searchErr && <span style={{ marginLeft: 8 }} className="text-warning">{cleanupErrMsg(searchErr.message)}</span>}
      <button className="btn btn-link" onClick={e => searchOrder(e, job).then(refetch)}><i className="fas fa-undo" /></button>
      {job.result && job.result.searchRes && job.result.searchRes.Status === 'REQSUCCESS' && <button className="btn btn-link" onClick={e => cancelOrder(e, job).then(refetch)}><i className="fas fa-times" />Cancel</button>}
    </div>
  );
};
