import moment from 'moment-timezone';
import wurd from 'wurd-react';

import DateFilter from '../filter/date';
import UserFilter from '../filter/user';
import SiteFilter from '../filter/site';
import ListFilter from '../filter/list';
import store from 'store';
import * as helpers from 'helpers';

const cms = wurd.block('paymentList.filters');

export default function PaymentListFilters({
  listQuery,
  hideSite,
  hideOwner,
}) {
  const { params } = listQuery;
  const { settings } = store.get();

  function onChange(key, value) {
    const newParams = {};

    switch (key) {
      case 'daterange':
        newParams.start = value ? moment(value.start).format('YYYY-MM-DD') : null;
        newParams.end = value ? moment(value.end).format('YYYY-MM-DD') : null;
        break;

      default:
        newParams[key] = value;
    }

    listQuery.update({
      offset: 0,
      ...newParams,
    });
  }

  const setSiteId = (val) => onChange('siteId', val);
  const setDateRange = (val) => onChange('daterange', val);
  const setOwner = (val) => onChange('ownerId', val);
  const setMethod = (val) => onChange('method', val);

  const daterange = (params.start && params.end)
    ? { start: params.start, end: params.end }
    : null;

  return (
    <>
      {!hideSite && (
        <SiteFilter
          active={params.siteId}
          onSelect={setSiteId}
        />
      )}

      <cms.Object id="method" keys={['title', 'all', settings.billing, 'manual'].filter(Boolean)}>
        <ListFilter
          title={cms.text('method.title')}
          active={params.method}
          onSelect={setMethod}
        >
          <li value={null}>{cms.text('method.all')}</li>
          {settings.billing && <li value={settings.billing}>{cms.text(`method.${settings.billing}`)}</li>}
          <li value="manual">{cms.text('method.manual')}</li>
          {helpers.payment.list(true).map(item => (
            <li key={item.code} value={item.code}>{item.title}</li>
          ))}
        </ListFilter>
      </cms.Object>

      <cms.Object id="daterange" keys="title">
        <DateFilter
          title={cms.text('daterange.title')}
          range={daterange}
          onSelect={setDateRange}
        />
      </cms.Object>

      {!hideOwner && (
        <UserFilter
          active={params.ownerId}
          onSelect={setOwner}
        />
      )}
    </>
  );
}