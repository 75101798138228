import api from '../utils/api';
import { updateCachedData } from './general';
import errHandler from './_err-handler';

import labelsPlugin from '../plugins/labels/actions';

const endpoint = 'jobs';
const url = `/v1/admin/${endpoint}`;

const actions = {};

function handleResponse(res) {
  updateCachedData(endpoint, res.data);

  return res.data;
}


actions.updateStep = function (jobId, stepId, props) {
  return api.put(`${url}/${jobId}/steps/${stepId}`, props)
    .then(handleResponse)
    .catch(errHandler);
};


actions.updateCurrentStep = function (job, props) {
  const step = job.steps.find(step => step.state === 'ready');
  if (!step) throw new Error('No step to update');

  return actions.updateStep(job.id, step.id, props);
};


actions.updateStepResult = function (jobId, stepId, result) {
  const data = {
    result
  };

  return api.put(`${url}/${jobId}/steps/${stepId}`, data)
    .then(handleResponse)
    .catch(errHandler);
};


actions.create = function (ownerId, type, data) {
  const fullData = {
    ownerId,
    type,
    data
  };

  return api.post(url, fullData)
    .then(handleResponse)
    .catch(errHandler);
};


actions.updateData = function (jobId, data) {
  return api.put(`${url}/${jobId}/data`, data)
    .then(handleResponse)
    .catch(errHandler);
};


actions.runOperation = function (jobId, operationName, data) {
  return api.post(`${url}/${jobId}/operations/${operationName}`, data)
    .then(handleResponse)
    .catch(errHandler);
};


actions.cancel = function (jobId, data) {
  return api.post(`${url}/${jobId}/operations/cancel`, data)
    .then(handleResponse)
    .catch(errHandler);
};


actions.retry = function (jobId) {
  return api.post(`${url}/${jobId}/retry`)
    .then(handleResponse)
    .catch(errHandler);
};


actions.skipStep = function (jobId, stepId) {
  return api.post(`${url}/${jobId}/steps/${stepId}/skip`)
    .then(handleResponse)
    .catch(errHandler);
};


actions.labels = labelsPlugin('jobs');

// Actions specific to valetOrder
actions.valetOrder = {
  addProduct(job, step, productId, quantity) {
    const charge = {};

    actions.addCharge(job, step, charge, quantity);
  },
};

export default actions;
