import wurd, { WurdMarkdown } from 'wurd-react';

import * as helpers from 'helpers';

import ModalForm2 from 'components/form2/modal-form2';
import PrepayPeriodsField from 'components/form2/unit/prepay-periods-field';
import PromoField from 'components/form2/unit/promo-field';

const cms = wurd.block('invoiceView.prepayPeriods');


export default function UnitRentalPrepayPeriodsForm({
  siteId,
  ...rest
}) {
  const settings = helpers.settings.get();

  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode="create"
      className="form2"
      {...rest}
    >
      {({ fieldProps, formValue }) => {
        return (
          <>
            <PrepayPeriodsField {...fieldProps('prepayPeriods')} />

            <PromoField
              {...fieldProps('promoCode')}
              cms={cms}
              promos={settings.unitPromos.filter(promo => helpers.business.isPromoValid(promo, siteId, formValue.startDate))}
              help={!settings.unitPromos.length && <WurdMarkdown id="common.forms.noUnitPromos" />}
            />
          </>
        );
      }}
    </ModalForm2>
  );
}
