import { useState, useEffect } from 'react';
import wurd from 'wurd-react';
import { Link } from 'react-router-dom';

import crudList from '../../actions/plugins/crud-list';
import { hasRole } from 'helpers/auth';
import { currency, datePeriod } from 'helpers/ui';
import * as accountHelpers from './helpers';

import Section from '../../components/section';
import Spinner from '../../components/spinner';
import AccountEntryForm from './form';
import SimpleNavList from 'components/simple-nav-list';


const totalStyles = {
  padding: 8,
  lineHeight: 1.42857143,
  borderTop: '1px solid #ddd',
  fontSize: '1.2em',
};

export default function AccountPlugin({
  modal,
  cms = wurd.block('accountPlugin'),
  endpoint,
  onChange,
  canAdd,
}) {
  const [accountEntries, setAccountEntries] = useState(null);

  const actions = crudList(endpoint);

  function fetch() {
    return actions.list()
      .then(setAccountEntries);
  }

  function addEntry() {
    modal.open(
      <AccountEntryForm
        onSubmit={(data) => actions.create(data)}
        onSuccess={() => {
          modal.close();
          fetch();
          onChange();
        }}
        closeModal={modal.close}
      />,
    );
  }

  useEffect(() => {
    fetch();
  }, []);

  if (!accountEntries) return <Spinner />;

  const balance = accountHelpers.getBalance(accountEntries);

  return (
    <Section
      cms={cms}
      onClickAdd={canAdd && addEntry}
      extra={wurd.editMode && (
        <cms.Object
          keys="confirmDelete"
          title="Edit off-page CMS content"
        >
          <button
            type="button"
            className="btn btn-link"
            disabled
          >
            <i className="fas fa-font" />
          </button>
        </cms.Object>
      )}
    >
      {accountEntries.length > 0 && (
        <>
          <SimpleNavList>
            {accountEntries.map((entry) => {
              const [startDate, endDate] = datePeriod(entry.date, entry.endDate);

              return (
                <Link
                  key={entry.id}
                  role="row"
                  to={`/invoices/${entry.invoiceId}`}
                  style={{ height: '4.5em' }}
                >
                  <div role="cell" className="text-muted" style={{ width: 98 }}>
                    <div className="secondary text-nowrap">{startDate}</div>
                    {endDate && (
                      <div className="tertiary text-nowrap">→ {endDate}</div>
                    )}
                  </div>
                  <div role="cell">
                    {entry.desc}
                    {entry.tax !== 0 && (
                      <span className="tertiary">&nbsp;&nbsp;{wurd.text('common.phrases.incTax', { tax: currency(entry.tax), taxPct: entry.taxPct })}</span>
                    )}
                    {entry.qty && entry.qty > 1 && (
                      <div className="tertiary"> {entry.qty} &times; {currency(entry.amount)}</div>
                    )}

                    <div className="tertiary">
                      {entry.code && (
                        <span title="Accounting code" style={{ marginRight: 10 }}>
                          <i className="fa-fw far fa-sm fa-calculator" />{entry.code}
                        </span>
                      )}
                      {hasRole('_support') && Boolean(entry.tags?.length) && (
                        <span title="System tags">
                          <i className="fa-fw far fa-sm fa-tag" />{entry.tags.join(',')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div role="cell" className="text-right text-nowrap">
                    {currency(entry.total)}
                  </div>
                </Link>
              );
            })}
          </SimpleNavList>
          <div className="text-right" style={totalStyles}>
            <strong>{wurd.text('common.phrases.total')}: {currency(balance)}</strong>
          </div>
        </>
      )}
    </Section>
  );
}
