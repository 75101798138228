import classnames from 'classnames';

import wurd, { WurdObject } from 'wurd-react';
import UserSelect from '../user-select';


const FormUserSelect = props => {
  const { wurdId, wurdKeys, error } = props;

  const { label, help } = wurd.get(wurdId) || {};

  return (
    <WurdObject
      id={wurdId}
      keys={wurdKeys || 'label,help'}
      type="div"
      className={classnames('form-group', { 'has-error': props.error })}
    >
      <label className="control-label col-sm-3">{label}</label>
      <div className="col-sm-9">
        <UserSelect {...props} />

        {help && <span className="help-block">{help}</span>}
        {error && <span className="help-block">{error[0].message}</span>}
      </div>
    </WurdObject>
  );
};


export default FormUserSelect;
