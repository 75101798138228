import wurd from 'wurd-react';

import store from 'store';

import { Field } from '../';


export default function PrepayPeriodsField({ cms = wurd.block('jobs.unit_moveIn.create.prepayPeriods'), ...props }) {
  const { invoicePeriod } = store.get('settings');

  return (
    <Field {...props}>
      <select>
        <option value={0}>{cms.text('0')}</option>
        <option value={1}>{cms.text(`1_${invoicePeriod}`)}</option>
        {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 18, 24, 36].map((num) => (
          <option key={num} value={num}>
            {cms.text(`multiple_${invoicePeriod}`, { num })}
          </option>
        ))}
      </select>
    </Field>
  );
}
