import { useEffect } from 'react';
import { WurdText } from 'wurd-react';
import { useInfiniteQuery } from 'react-query';
import store from '../../store';
import { fetch } from '../../actions';
import * as helpers from '../../helpers';
import debounce from '../../utils/debounce';
import { useListLoader } from 'hooks';

import AsyncSelect from 'react-select/async';

const limit = 10; // must be at least 10, otherwise menulist isn't scrollable and impossible to load more

const selectStyles = {
  container: (base) => ({
    ...base,
    zIndex: 1,
    flex: 1,
    position: 'relative',
  }),
};

export default function FormAsyncSelect({ value, onChange, name, endpoint, params, ...props }) {
  const { settings } = store.get();

  const itemToOption = item => item && {
    value: item.id,
    label: (
      <span className="fw-normal">{helpers.ui.getLangText(item.title)}</span>
    ),
  };

  const { items: activeItems } = useListLoader(endpoint, { ids: [].concat(value) });

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
  } = useInfiniteQuery(`${endpoint}-sel`, ({ pageParam: offset = 0 }) => fetch(endpoint, { ...params, offset, limit }), {
    getNextPageParam: (lastPage, allPages) => lastPage.length < limit ? undefined : allPages.reduce((a, p) => a + p.length, 0),
  });

  const defaultItems = data?.pages.flat(1) || [];

  const itemsById = [
    ...activeItems,
    ...defaultItems
  ].reduce((o, v) => ({ ...o, [v.id]: v }), {});

  const defaultOptions = defaultItems.map(itemToOption);

  const debouncedLoadOptions = debounce(
    (inputValue) => {
      //Search and format options
      return fetch(endpoint, { search: inputValue, limit: 25 })
        .then(items => items.map(itemToOption));
    },
    400
  );

  useEffect(() => {
    const loadMore = async (e) => {
      if (!hasNextPage || isFetchingNextPage) return;
      if (e.target.role !== 'listbox') return;
      if (e.target.scrollTop + e.target.offsetHeight < e.target.scrollHeight - 4) return;
      fetchNextPage();
    };

    document.body.addEventListener('scroll', loadMore, true);
    return () => document.body.removeEventListener('scroll', loadMore, true);
  }, [hasNextPage, isFetchingNextPage]);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={debouncedLoadOptions}
      closeMenuOnSelect={Array.isArray(value)}
      isMulti={Array.isArray(value)}
      isLoading={isFetchingNextPage}
      name={name}
      {...props}
      value={Array.isArray(value) ? value.map(v => itemToOption(itemsById[v])) : itemToOption(itemsById[value]) || ''}
      isOptionSelected={option => Array.isArray(value) ? value.includes(option.value) : option.value === value}
      onChange={(option) => {
        onChange({
          target: {
            name,
            value: Array.isArray(option) ? option.map(o => o.value) : (option?.value || null)
          }
        });
      }}
      styles={selectStyles}
      isClearable
      openMenuOnFocus
      // menuIsOpen // this would make menu always visible
      {...props}
    />
  );
}