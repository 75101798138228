import wurd from 'wurd-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as actions from '../../actions';
import * as helpers from '../../helpers';

const cms = wurd.block('siteView.menu');


export default function SiteDetailsMenu({ site, modal, section, sectionId, onChange }) {
  const navigate = useNavigate();
  let menuItems;

  if (section === 'unit-types' && sectionId) {
    const unitType = site.unitTypes.find(t => t.id === sectionId);
    const deleteUnitType = () => {
      if (!window.confirm(cms.text('deleteUnitType.confirm', unitType))) return;

      return actions.del('unit-types', sectionId)
        .then(() => {
          navigate(`/sites/${site.code}/unit-types`);
          onChange();
        });
    };

    menuItems = (
      <MenuItem key="delete" onSelect={deleteUnitType}>
        <i className="fas fa-fw fa-times" /> {cms.text('deleteUnitType.title', { title: helpers.ui.getLangText(unitType.title) })}
      </MenuItem>
    )
  }

  if (!menuItems) return null;

  return (
    <cms.Object keys="deleteUnitType.title,deleteUnitType.confirm">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="siteMenu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}
