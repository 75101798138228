import PropTypes from 'prop-types';

import PickStep from './pick';
import DeliverStep from './deliver';
import CollectStep from './collect';
import StoreStep from './store';


export default function VisitSteps(props) {

  return (
    <div className="panel-group accordion accordion-semi">
      <PickStep {...props} />
      <DeliverStep {...props} />
      <CollectStep {...props} />
      <StoreStep {...props} />
    </div>
  )

}

VisitSteps.propTypes = {
  onChange: PropTypes.func.isRequired,
  visit: PropTypes.object.isRequired
}
