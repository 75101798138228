import schema2form from '../schema2form';


const wurdSection = 'market.charges';

const schema = [
  {
    name: 'returnDelivery',
    label: 'Return delivery',
    type: 'number',
    required: true
  },
  {
    name: 'failedDelivery',
    label: 'Failed delivery',
    type: 'number',
    required: true
  },
  {
    name: 'failedCollection',
    label: 'Failed collection',
    type: 'number',
    required: true
  }
];


export default schema2form(schema, { wurdSection });
