import wurd from 'wurd-react';
import moment from 'moment';

import ModalForm2 from 'components/form2/modal-form2';
import { Field, DatePicker, CurrencyInput } from 'components/form2';
import * as helpers from 'helpers';

const cms = wurd.block('invoiceList.forms.bulkPay');


export default function UnitBulkPayForm(props) {
  const initialValue = {
    date: moment().format('YYYY-MM-DD'),
  };

  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode="create"
      className="form2"
      initialValue={initialValue}
      {...props}
      onSubmit={data => props.onSubmit({
        ...data,
        notes: (data.notes || cms.text('notes.default')).replace('{{amount}}', helpers.ui.currency(data.amount)),
      })}
    >
      {({ fieldProps }) => {
        return (
          <div>
            <Field {...fieldProps('amount')} required>
              <CurrencyInput min="0" autoFocus />
            </Field>

            <Field {...fieldProps('date')} required>
              <DatePicker minDate={null} required />
            </Field>

            <Field {...fieldProps('method')} required>
              <select required>
                <option value="">{wurd.text('invoice.payment.selectPayment')}</option>
                {helpers.payment.list(true).map(item => (
                  <option key={item.code} value={item.code}>{item.title}</option>
                ))}
              </select>
            </Field>

            <Field
              {...fieldProps('notes')}
              wurdKeys="label,help,default"
              defaultValue={cms.text('notes.default')}
            />
          </div>
        );
      }}
    </ModalForm2>
  );
}
