import { useMutation } from "react-query";
import styled from "@emotion/styled";

const ButtonGroup = styled.div({
  display: "flex",
  gap: "15px",
});

const DangerButton = styled.button`
  border: 1px solid red;
  color: red;
  background: none;
  &:hover {
    color: red;
    border: 1px solid red;
    background-color: #ff00000a;
  }
`;

const SecondaryButton = styled.button`
  border: 1px solid rgb(77 144 253);
  color: rgb(77 144 253);
  background: none;
  &:hover {
    color: rgb(77 144 253);
    background-color: rgb(77 144 253 / 8%);
  }
  ,
  .btn i,
  .btn-default i {
    color: rgb(77 144 253) !important;
  }
`;

export function DangerOutlineLink({
  faIcon,
  className,
  text,
  onClick,
  disabled = false,
}) {
  const clickMutation = useMutation(onClick);
  return (
    <DangerButton
      type="button"
      className={className}
      onClick={clickMutation.mutate}
      disabled={disabled || clickMutation.isLoading}
    >
      <i
        className={
          clickMutation.isLoading
            ? "icon far fa-spin fa-spinner"
            : `icon ${faIcon}`
        }
      />&nbsp;
      {text && <span className="text ms-3">{text}</span>}
    </DangerButton>
  );
}

export function SecondaryOutlineLink({
  faIcon,
  text,
  onClick,
  className = "",
  disabled = false,
}) {
  const clickMutation = useMutation(onClick);
  return (
    <SecondaryButton
      type="button"
      className={className}
      onClick={clickMutation.mutate}
      disabled={disabled || clickMutation.isLoading}
    >
      <i
        className={
          clickMutation.isLoading
            ? "icon far fa-spin fa-spinner"
            : `icon ${faIcon}`
        }
      />&nbsp;
      {text && <span className="text ms-3">{text}</span>}
    </SecondaryButton>
  );
}

export default function ActionButtonGroup({
  align = "left", // left|center|right
  children,
  ...rest
}) {
  return (
    <ButtonGroup className={`buttonGroup ${align}`} {...rest}>
      {children}
    </ButtonGroup>
  );
}
