export default ({ bsType = 'warning', width = 140, className, children: content }) => (
  <span
    className={`label label-${bsType} sg-short-label ${className}`}
    style={{
      maxWidth: width,
    }}
    title={content}
  >
    {content}
  </span>
);
