import _ from 'lodash';
import wurd from 'wurd-react'

import schema2form from '../schema2form';

const cms = wurd.block('market')
const wurdSection = 'market';

const schema = [
  {
    name: 'collectionWaitTime',
    type: 'select',
    options: function () {
      return [
        { value: 0, text: cms.text('defaultOption') },
        ..._.range(5, 65, 5).map(n => (
          { value: n, text: `${n} ${cms.text('minLabels')}` }
        ))
      ];
    },
    required: true
  },
  {
    name: 'maxVisitsPerTimeslot',
    type: 'number',
    required: true
  },
  {
    name: 'visitBookingCutoffTime',
    type: 'select',
    options: function () {
      return _.range(24).map(n => {
        const text = new Date(2000, 0, 1, n, 0, 0).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' });

        return { value: n, text };
      })
    },
    required: true
  },
  {
    name: 'bookingDaysAhead',
    type: 'select',
    options: function () {
      var items = [
        [31, '1 month'],
        [62, '2 months'],
        [93, '3 months'],
        [124, '4 months'],
        [155, '5 months'],
        [186, '6 months'],
      ];

      return items.map(([days, text]) => (
        { value: days, text: text }
      ))
    },
    required: true
  }
];


export default schema2form(schema, { wurdSection });
