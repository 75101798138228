
import classnames from 'classnames';
import wurd, { WurdObject } from 'wurd-react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

import { currentLang } from 'utils/language';
import { settings } from '../../helpers';


export default function FormDatePicker({ value, onChange, wurdId, wurdKeys, error, ...rest }) {
  const { label, help } = wurd.get(wurdId) || {};

  const placeholder = wurd.get('common.form.datePicker.placeholder');

  return (
    <WurdObject
      id={wurdId}
      keys={wurdKeys || 'label,help'}
      type="div"
      className={classnames('form-group', { 'has-error': error })}
    >
      <label className="control-label col-sm-3">{label}</label>
      <div className="col-sm-9">
        <DatePicker
          className="form-control"
          dateFormat={settings.get().dateFormats.short}
          placeholderText={placeholder}
          locale={currentLang}
          {...rest}
          selected={value && moment(value)}
          onChange={date => onChange(date.format('YYYY-MM-DD'))}
        />

        {help && <span className="help-block">{help}</span>}
        {error && <span className="help-block">{error[0].message}</span>}
      </div>
    </WurdObject>
  );
}
