import wurd, { WurdText } from 'wurd-react';

import * as actions from '../../../../actions';

import Section from '../../../section';
import MarketForm from '../form';
import DayTimeslots from './controls/day-timeslots';
import ExcludedDates from 'components/excluded-dates';
import VisitsGeneralForm from '../../../form/market/visits_general';

const cms = wurd.block('marketSettings');


export default function VisitsSettings({ market }) {
  function onSubmit(data) {
    return actions.markets.update(market.id, data);
  }

  return (
    <>
      <Section cms={cms.block('blockedDates')}>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <MarketForm marketId={market.id} defaultValue={market}>
              <ExcludedDates name="excludedVisitDates" />
              <WurdText id="market.excludedVisitDates.help" className="help-block" />
            </MarketForm>
          </div>
        </div>
      </Section>

      <Section cms={cms.block('timeslots')}>
        <MarketForm name="timeslots" marketId={market.id} defaultValue={market.timeslots} className="clearfix" >
          <DayTimeslots name="monday" />
          <DayTimeslots name="tuesday" />
          <DayTimeslots name="wednesday" />
          <DayTimeslots name="thursday" />
          <DayTimeslots name="friday" />
          <DayTimeslots name="saturday" />
          <DayTimeslots name="sunday" />
          <div />
        </MarketForm>
      </Section>

      <Section cms={cms.block('booking')}>
        <VisitsGeneralForm
          defaultValue={market}
          onSubmit={onSubmit}
        />
      </Section>
    </>
  );
}
