import * as helpers from '../../../helpers';
import { WurdText } from 'wurd-react';

import UnitLoader from '../../loaders/unit';
import UserLoader from '../../loaders/user';
import ShortLabel from '../../short-label';
import JobStateLabel from './job-state-label';
import LabelsListCell from 'plugins/labels/list';

const { date } = helpers.ui;
const { stateColors } = helpers.unit;

const styles = {
  jobIcon: { color: stateColors.occupied },
  unitIcon: { color: '#aaa', fontSize: 18, marginTop: 6 },
};


export default function UnitTransferJobListItem({
  job,
  hideOwner,
}) {
  const { ownerId } = job;
  const { oldUnitId, newUnitId, transferDate } = job.result;

  return (
    <>
      <div role="cell" style={{ width: 30 }}>
        <div><i className="far fa-fw fa-exchange-alt" style={styles.jobIcon} /></div>
      </div>

      <div role="cell">
        <div>
          <strong>
            <WurdText id="unitJobList.filters.type.unit_transfer" />:&nbsp;
            {oldUnitId && newUnitId && (
              <>
                <UnitLoader id={oldUnitId}>
                  {(unit) => (
                    <>{unit.name}</>
                  )}
                </UnitLoader>
                &nbsp;→&nbsp;
                <UnitLoader id={newUnitId}>
                  {(unit) => (
                    <>{unit.name}</>
                  )}
                </UnitLoader>
              </>
            )}
          </strong>
        </div>

        <div>
          {!hideOwner && (
            <UserLoader id={ownerId}>
              {(user) => user.name}
            </UserLoader>
          )}
        </div>
      </div>

      <div role="cell">
        <div title="Transfer date">
          {date(transferDate)}
        </div>
      </div>

      <div role="cell" style={{ textAlign: 'right' }}>
        <div><JobStateLabel job={job} /></div>
        {job.error && (
          <div>
            <ShortLabel bsType="danger" width="min(200px, 25vw)">{job.error}</ShortLabel>
          </div>
        )}
      </div>

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={job.labels} />
      </div>
    </>
  );
}
