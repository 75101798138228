import wurd from 'wurd-react';
import moment from 'moment-timezone';

import * as helpers from 'helpers';

import DayPicker from 'react-day-picker';


const cms = wurd.block('market.excludedVisitDates');

export default function ExcludedDates({ name, value: excludedDates = [], onChange }) {
  function handleDayClick(day, modifiers) {
    const date = moment(day).format('YYYY-MM-DD');

    const newDates = excludedDates.includes(date)
      ? excludedDates.filter(d => d !== date) // Already excluded; remove it from the list
      : [...excludedDates, date]; // Not excluded yet; add it to the list

    // Let the form know of the change
    onChange({ target: { name, value: newDates } });
  }

  const modifiers = {
    today(day) {
      return moment(day).isSame(new Date(), 'day');
    },

    disabled(day) {
      const date = moment(day).format('YYYY-MM-DD');

      return excludedDates.includes(date);
    },
  };

  return (
    <DayPicker
      style={{ width: 190 }}
      modifiers={modifiers}
      onDayClick={handleDayClick}
      enableOutsideDays={true}
      fromMonth={new Date()}
      value={excludedDates}
      name={name}
    />
  );
}
