import InvoicingSettings from './invoicing';
import InvoiceGenerationSettings from './invoice-generation';


export default function BillingSettings({ settings }) {
  return (
    <>
      <InvoicingSettings settings={settings} />

      {settings.modes.includes('selfStorage') && (
        <InvoiceGenerationSettings settings={settings}  />
      )}
    </>
  );
}
