import { DropdownButton, MenuItem } from 'react-bootstrap';
import moment from 'moment-timezone';

import * as helpers from 'helpers';
import * as actions from 'actions';

import Modal, { ModalDialog } from 'components/modal';
import ApiButton from 'components/api-button';
import ExportButton from 'components/export-button';
import UnitRentalLoader from 'components/loaders/unit-rental';
import UnitBulkPayForm from './forms/bulk-pay';


export default function InvoiceListMenu({
  modal,
  cms,
  listQuery,
  fetchInvoices,
}) {
  if (!modal) return null;

  function exportCsv(format) {
    modal.open(
      <ModalDialog cms={cms.block(format ? `exportModal_${format}` : 'exportModal')}>
        <ExportButton
          filename="invoices.csv"
          fetch={params => helpers.invoice.fetchDetailed({ ...listQuery.params, ...params, include: 'customFields' })}
          toCsv={format === 'xero' ? helpers.invoice.toCsvForXero : helpers.invoice.toCsv}
          sections={{
            main: ['sid', 'startDate', 'state', 'subtotal', 'tax', 'total'],
            owner: ['firstName', 'lastName', 'email', 'companyName'],
            site: ['code'],
            unit: ['name'],
          }}
        />
      </ModalDialog>
    );
  }

  function sendStatement() {
    const submit = () => actions.emails.send('unitRental.owner.unpaidInvoices', listQuery.params.unitRentalId)
      .then(() => {
        helpers.ui.notify({ bsType: 'success', text: <><i className="fa fa-check" /> <cms.Text id="sendStatement.sent" /></> });
        modal.close();
      })
      .catch(err => {
        helpers.ui.notify({ bsType: 'danger', text: err.message, duration: 15000 });
        modal.close();
      });

    modal.open(
      <Modal
        title={<cms.Text id="sendStatement.title" />}
        closeModal={modal.close}
        footer={<ApiButton onClick={submit}><cms.Text id="sendStatement.send" /></ApiButton>}
        noPadding
      >
        <iframe 
          src={actions.emails.previewUrl(`unitRental.owner.unpaidInvoices/${listQuery.params.unitRentalId}`)}
          style={{ width: '100%', display: 'block', height: 500, border: 0 }}
        />
      </Modal>
    );
    return 
  }

  function bulkPay() {
    modal.open(
      <UnitRentalLoader id={listQuery.params.unitRentalId}>
        {unitRental => (
          <UnitBulkPayForm
            onSubmit={async data => {
              const job = await actions.jobs.create(unitRental.ownerId, 'unitRental_applyPayment', {
                unitRentalId: unitRental.id,
                ...data,
              });

              const invoices = await fetchInvoices();

              const ids = [
                ...job.result.invoiceIds,
                ...job.result.leftoverInvoiceId ? [job.result.leftoverInvoiceId] : []
              ];

              const sids = ids.map(id => invoices.find(inv => inv.id === id)?.sid || id);

              location.hash = sids;

              helpers.ui.notify({
                bsType: 'success',
                text: <cms.Text id="menu.bulkPaySuccess" vars={{ sids: sids.map(s => s.toUpperCase()) }} />,
              });
            }}
            onSuccess={modal.close}
            closeModal={modal.close}
          />
        )}
      </UnitRentalLoader>
    )
  }

  // NOTE: This method is currently only for testing prepayments etc.
  // It creates the same type of invoice that is generated for a unit each month
  function createUnitSystemInvoice() {
    const invoiceDate = prompt('NOTE: For testing only. Enter the invoice date in format YYYY-MM-DD. This should usually be on the same date of the month as previous invoices.');

    // Handle cancel
    if (invoiceDate === null) return;

    // Handle OK with no text
    if (!invoiceDate) {
      alert('Date is required');
      return;
    }

    const m = moment(invoiceDate);
    if (!m.isValid()) return alert(`Invalid date ${invoiceDate}`);

    const unitRental = actions.fetchOneCached('unit-rentals', listQuery.params.unitRentalId)?.data;
    if (!unitRental) return alert('Unit rental not found');

    actions.jobs.create(null, 'unit_invoice', {
      siteId: unitRental.siteId,
      unitRentalId: unitRental.id,
      invoiceDate: m.format('YYYY-MM-DD'),
      //chargeDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    })
      .then(async job => {
        const invoices = await fetchInvoices();
        location.hash = invoices.find(inv => inv.id === job.result.invoiceId)?.sid || job.result.invoiceId;
      });
  }

  return (
    <cms.Object id="menu" keys="export,exportXero,bulkPay,sendStatement,helpUrl">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        <MenuItem
          key="export"
          onSelect={exportCsv}
        >
          <i className="fas fa-fw fa-download" /> {cms.text('menu.export')}
        </MenuItem>
        <MenuItem
          key="exportXero"
          onSelect={() => exportCsv('xero')}
        >
          <i className="fas fa-fw fa-download" /> {cms.text('menu.exportXero')}
        </MenuItem>
        {listQuery.params.unitRentalId && helpers.auth.hasRole('admin') && (
          <MenuItem
            key="sendStatement"
            onSelect={sendStatement}
          >
            <i className="fas fa-fw fa-file-invoice-dollar" /> {cms.text('menu.sendStatement')}
          </MenuItem>
        )}
        {listQuery.params.unitRentalId && helpers.auth.hasRole('admin') && (
          <MenuItem
            key="bulkPay"
            onSelect={bulkPay}
          >
            <i className="fas fa-fw fa-lightbulb-dollar" /> {cms.text('menu.bulkPay')}
          </MenuItem>
        )}
        {listQuery.params.unitRentalId && helpers.auth.hasRole('_support') && (
          <MenuItem key="createUnitInvoice" onSelect={createUnitSystemInvoice}>
            <em className="text-muted"><i className="far fa-fw fa-file-invoice-dollar" /> Create system unit invoice</em>
          </MenuItem>
        )}
        {cms.text('menu.helpUrl') && (
          <MenuItem href={cms.text('menu.helpUrl')} target="_blank">
            <i className="fal fa-fw fa-external-link" /> {cms.wurd.text('common.filters.helpText') || 'Help'}
          </MenuItem>
        )}
      </DropdownButton>
    </cms.Object>
  );
}
