import pick from 'lodash/pick';
import store from '../store';
import api from '../utils/api';
import * as poller from '../utils/poller';
import errHandler from './_err-handler';
import debounce from '../utils/debounce';

// Map of which 'include' options are available when loading items.
// Used to keep included data in the cache when new data comes in but without the includes
export const includeKeysByEndpoint = {
  users: ['units', 'items', 'visits', 'billing', 'creditsDebits', 'customFields'],
  invoices: ['billing', 'billingMethod', 'customFields'],
  items: ['customFields'],
  sites: ['customFields', 'accessTypes', 'positions'],
  units: ['rental', 'customFields'],
  'unit-rentals': ['unit', 'customFields'],
  'unit-types': ['site', 'customFields'],
  'valet-orders': ['job', 'items', 'customFields'],
  visits: ['deliverBoxes', 'deliverItems', 'collectItems', 'order']
};


// lower-level method for specific endpoints
export function _fetch(path, params) {
  return api.get(`/v1/admin/${path}`, { params }).then(res => res.data);
}

function getQueryId(params) {
  return JSON.stringify(params, Object.keys(params).sort());
}

export function updateCachedData(endpoint, items) {
  if (!items) return;

  // Normalise single item to array
  const itemsArray = Array.isArray(items) ? items : [items];

  const key = `${endpoint}_byId`;

  // Add the fetched time to each object so we can compare to maxAge later
  const time = Date.now();
  const itemsById = Object.fromEntries(itemsArray.filter(Boolean).map(item => {
    // Keep the 'include' data from the old instance of the cached item
    const oldItem = store.get()[key][item.id];
 
    const newItem = {
      ...pick(oldItem, includeKeysByEndpoint[endpoint]),
      ...item,
      _fetched: time,
    };

    return [item.id, newItem];
  }));

  store.set({
    [key]: { ...store.get(key), ...itemsById } // we might want to apply a max cache size here
  });
}


/**
 * Fetches entities from the API and caches them
 *
 * @param {String} endpoint     e.g. invoices, jobs, etc.
 * @param {Object} [params]
 * @param {Object} [options.cancelToken]  for axios
 * @param {Function} [options.sortItems] sorting function (items) => sortedItems
 *
 * @resolves {Object[]}
 */
export async function fetch(endpoint, params = {}, { cancelToken, sortItems, skipCache } = {}) {
  try {
    let items;

    if (params.ids) {
      const ids = Array.isArray(params.ids) ? params.ids : params.ids.split(',');

      // chunk ids by groups of 50, so it stays under uri maxlength (2048)
      if (ids.length === 0) return [];

      const uniqueIds = [...new Set(ids)];
      const chunkedIds = Array.from({ length: Math.ceil(uniqueIds.length / 50) }, (_, i) => uniqueIds.slice(50 * i, 50 * (i + 1)));

      const chunks = await Promise.all(chunkedIds.map(xs => api.get(`/v1/admin/${endpoint}`, { cancelToken, params: { ...params, ids: xs } }).then(res => res.data)));

      items = chunks.flat();
    } else {
      items = await api.get(`/v1/admin/${endpoint}`, { cancelToken, params }).then(res => res.data);
    }

    if (sortItems) items = sortItems(items);

    if (!skipCache) {
      // Save data to cache
      updateCachedData(endpoint, items);

      // Save query result to cache
      const queries = store.get(`${endpoint}_queries`);
      const queryId = getQueryId(params);
      const itemIds = items.map((item) => item.id);

      store.set({
        [`${endpoint}_queries`]: {
          ...queries,
          [queryId]: {
            _fetched: Date.now(),
            ids: itemIds,
          },
        },
      });
    }

    return items;
  } catch (err) {
    errHandler(err);
    return null;
  }
}


/**
 * Fetches entities from the cache
 *
 * @param {String} endpoint     e.g. invoices, jobs, etc.
 * @param {Object} [params]
 *
 * @resolves {Object[]}
 */
export function fetchCached(endpoint, params = {}) {
  const queries = store.get(`${endpoint}_queries`);
  const itemsById = store.get(`${endpoint}_byId`);

  const queryId = getQueryId(params);
  const storedQuery = queries[queryId];

  if (!storedQuery || !storedQuery.ids) return null;

  const items = storedQuery.ids.reduce((memo, id) => {
    const item = itemsById[id];
    if (!item) return memo;

    return [...memo, item];
  }, []);

  return {
    data: items,
    fetched: storedQuery._fetched,
  };
}


/**
 * Fetches a single entity from the API and caches it
 *
 * @param {String} endpoint
 * @param {String} id
 * @param {Object} [queryParams]
 *
 * @resolves {Object}
 */
export async function fetchOne(endpoint, id, queryParams = {}) {
  try {
    if (!id) return null;

    const res = await api.get(`/v1/admin/${endpoint}/${id}`, { params: queryParams });

    updateCachedData(endpoint, res.data);

    return res.data;
  } catch (err) {
    errHandler(err);
    return null;
  }
}


/**
 * Fetches a single entity from the cache (TODO we should maybe store promises in cache, also maybe switch to react-query)
 *
 * @param {String} endpoint
 * @param {Object} params
 * @param {String} [options.altIdKey]        Key to search by if ID is not found; usually `sid` but can be `email` etc as well
 *
 * @resolves {Object}
 */
export function fetchOneCached(endpoint, id, { altIdKey = null } = {}) {
  if (!id) return null;

  const itemsById = store.get(`${endpoint}_byId`);

  // First try to lookup directly by ID (fast)
  let item = itemsById[id];

  // Otherwise, if it could match another key e.g. `sid`, search by this key
  if (!item && altIdKey) {
    const altId = id.toLowerCase();

    item = Object.values(itemsById).find((i) => i[altIdKey] === altId);
  }

  if (!item) return null;

  return {
    data: item,
    fetched: item._fetched,
  };
}

// throttled fetchOne, used to avoid many single fetchOne at the same time
// make sure to define one per endpoint
export function throttle_fetchOne(ms = 150) {
  let queue = [];
  let resolvers = [];
  let endpoint, include;

  const throttleQueue = debounce(() => {
    const ids = [...queue];
    if (!queue.length) return [];
    queue = [];
    return fetch(endpoint, { ids, include });
  }, ms, { leading: false, maxWait: ms });

  return (_endpoint, id, { include: _include }) => {
    endpoint = _endpoint;
    include = _include;
    return new Promise((r) => {
      if (!id) return r(null);
      const cachedItem = store.get(`${endpoint}_byId`)[id];
      if (cachedItem) return r(cachedItem);

      queue.push(id);
      resolvers.push([id, r]);

      return throttleQueue()
        .then(() => {
          const rs = [...resolvers];
          resolvers = [];
          for (const [id, r] of rs) {
            const cachedItem = store.get(`${endpoint}_byId`)[id];
            r(cachedItem);
          }
         })
        .catch(err => {
          errHandler(err);
        });
    });
  }
}

export function fetchOneWithCache(endpoint, id, { altIdKey, ...queryParams } = {}) {
  return fetchOneCached(endpoint, id, { altIdKey })?.data || fetchOne(endpoint, id, queryParams);
}


export function removeFromCache(endpoint, id) {
  const key = `${endpoint}_byId`;

  const before = store.get(key);
  const { [id]: item, ...after } = before;

  store.set({
    [key]: after,
  });
}

/**
 * Continuously fetches an item until a condition is met or the timer runs out
 * 
 * @param {String} endpoint 
 * @param {String} id 
 * @param {Number} options.interval How often to poll in ms
 * @param {Number} options.timeout When to stop the polling in ms
 * @param {Function} options.until Stop polling when a condition is met. E.g. until(job => job.state === 'completed')
 * @return {Promise}
 */
export function pollOneForUpdates(endpoint, id, {
  until = () => {},
  interval = 2000,
  timeout = 60000,
  ...rest
} = {}) {
  return new Promise((resolve, reject) => {
    // If there is a cached version that already fits the until() condition then no polling is required
    const doc = fetchOneCached(endpoint, id, rest)?.data;
    if (doc && until(doc)) return resolve();

    // Start
    poller.start({
      id: `${endpoint}/${id}`,
      fn: async function () {
        const doc = await fetchOne(endpoint, id, rest);
        //console.log(Date.now(), doc);

        // Stop the polling if the conditions in until() have been met
        if (until(doc)) {
          resolve();
          return false;
        }

        return true; // continue polling
      },
      interval,
      timeout,
    });
  });
}


/**
 * Updates a single entity and updates the cached data
 */
export async function create(endpoint, data, queryParams) {
  try {
    const res = await api.post(`/v1/admin/${endpoint}`, data, { params: queryParams });

    updateCachedData(endpoint, res.data);

    return res.data;
  } catch (err) {
    errHandler(err);
    return null;
  }
}


/**
 * Updates a single entity and updates the cached data
 */
export async function update(endpoint, id, data, queryParams) {
  try {
    const res = await api.put(`/v1/admin/${endpoint}/${id}`, data, { params: queryParams });

    updateCachedData(endpoint, res.data);

    return res.data;
  } catch (err) {
    errHandler(err);
    return null;
  }
}


export async function del(endpoint, id) {
  try {
    await api.delete(`/v1/admin/${endpoint}/${id}`);

    removeFromCache(endpoint, id);

    return null;
  } catch (err) {
    errHandler(err);
    return null;
  }
}
