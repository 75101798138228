import { DropdownButton, MenuItem } from 'react-bootstrap';

import * as actions from 'actions';
import * as helpers from 'helpers';

import ModalForm from 'components/form2/modal-form';

const wurdIds = [
  
];


export default function AddonDetailMenu({
  modal,
  cms,
  addonConfig,
  refetch,
}) {

  function getMenuItems() {
    const items = [];

    return items;
  }

  const menuItems = getMenuItems();
  if (!menuItems.length) return null;


  return (
    <cms.Object keys={wurdIds.join(',')}>
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="itemMenu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}
