import { useState } from 'react';

export default function useModal(opts) {
  const [modalProps, setModalProps] = useState(null); // {content: string, options: object}

  function open(newContent, extraOpts) {
    setModalProps({ content: newContent, options: { ...opts, ...extraOpts } });
  }

  function close() {
    setModalProps(null);
  }

  return {
    ...modalProps,
    open,
    close,
  };
}
