import wurd from 'wurd-react';

import { ModalForm2, Field, DatePicker } from 'components/form2';

export default function TaskJobModal({
  job,
  mode = job ? 'update' : 'create',
  ...rest
}) {
  const cms = wurd.block('common.tasks');

  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode={mode}
      className="form2"
      {...rest}
    >
      {({ fieldProps }) => {
        return (
          <>
            <Field {...fieldProps('title')} autoFocus />

            <Field {...fieldProps('desc')}>
              <textarea />
            </Field>

            <Field {...fieldProps('dueDate')}>
              <DatePicker />
            </Field>
          </>
        );
      }}
    </ModalForm2>
  );
}
