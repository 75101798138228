import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { WurdText } from 'wurd-react';
import Input from './input';
import ErrorMessage from 'components/error-message';


function getFieldNames(schema) {
  return schema.map(field => field.name);
}

function getRequiredFieldNames(schema) {
  return schema.filter(field => field.required).map(field => field.name);
}


/**
 * @param {Object} schema
 * @param {Object} [options]
 * @param {String} [options.wurdKey]  Key of the section the label names can come from (based on field name
 */
const schema2form = function (schema, options = {}) {
  return class Form extends PureComponent {
    static propTypes = {
      defaultValue: PropTypes.object.isRequired,
      onSubmit: PropTypes.func.isRequired, // Should return be a promise
      onSuccess: PropTypes.func,
      submitButton: PropTypes.node,

      modal: PropTypes.bool,
      onHideModal: PropTypes.func, // Required if modal prop is true
      intro: PropTypes.any, // Optional intro text or element, useful for warnings, info etc.

      onDelete: PropTypes.func, // If passed, adds a 'Delete' button for modal forms
      deleteButton: PropTypes.element, //Custom text for delete button

      // Middleware - changes will be passed to this function; the data can then
      // be modified and returned (e.g. to add/remove new data).
      // Useful for updating a form if another input changes
      preChange: PropTypes.func,

      fields: PropTypes.arrayOf(PropTypes.string),
      requiredFields: PropTypes.arrayOf(PropTypes.string),
    }

    static defaultProps = {
      fields: getFieldNames(schema),
      requiredFields: getRequiredFieldNames(schema)
    };

    state = {
      values: { ...this.props.defaultValue },
      errors: this.validate(this.props.defaultValue),
      errMsg: null,
      success: false,
      loading: false,
    };

    render() {
      return this.props.modal ? this.renderModalLayout() : this.renderNormalLayout();
    }

    renderNormalLayout() {
      var { props, state } = this;

      return (
        <div className="clearfix">
          {this.renderForm()}

          <button
            type="button"
            className={`btn btn-primary pull-right ${state.success ? 'btn-success' : ''}`}
            onClick={this.onSubmit}
            disabled={state.errors}
          >
            {props.submitButton
              ? props.submitButton
              : <WurdText id={state.success ? 'common.savedBtn' : 'common.saveBtn'} />
            }
          </button>
        </div>
      );
    }

    renderModalLayout() {
      const { title, intro, onDelete, onHideModal, submitButton, deleteButton } = this.props;
      const { errors, loading } = this.state;

      return (
        <div>
          {title &&
            <div className="modal-header">
              <h4 className="modal-title">{title}</h4>
            </div>
          }
          <div className="modal-body">
            {intro}

            {this.renderForm()}
          </div>
          <div className="modal-footer">
            {onDelete &&
              <button
                type="button"
                className="btn btn-danger pull-left"
                onClick={onDelete}
              >
                {deleteButton || <WurdText id="common.deleteBtn" />}
              </button>
            }

            <button
              type="button"
              className="btn btn-default"
              onClick={onHideModal}
            >
              <WurdText id="common.cancelBtn" />
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSubmit}
              disabled={errors || loading}
            >
              {loading && <i className="fa fa-spinner fa-spin" />}
              {submitButton || <WurdText id="common.okBtn" />}
            </button>
          </div>
        </div>
      );
    }

    renderForm() {
      const { fields } = this.props;
      const { errMsg } = this.state;

      // Show custom fields
      return (
        <div className="form-horizontal">
          {schema.filter(field => fields.includes(field.name)).map(this.renderField)}

          {errMsg &&
            <ErrorMessage>
              {errMsg}
            </ErrorMessage>
          }
        </div>
      )
    }

    renderField = (field) => {
      const formProps = this.props;
      const { values, errors } = this.state;
      const { type, name, required, style } = field;

      // Main props
      const props = {
        key: name,
        name: name,
        label: field.label,
        value: values[name],
        onChange: (value) => this.onChange({ [name]: value }),
        error: errors && errors[name],
        style,
      };

      // Support Wurd for naming and editing fields
      if (options.wurdSection) {
        props.wurdId = `${options.wurdSection}.${name}`;
      }

      // Custom fields render themselves
      if (field.custom) return field.custom(props, formProps, values);

      // Children
      var children;
      if (type === 'select') {
        var selectOptions;
        if (typeof field.options === 'function') {
          selectOptions = field.options(props, formProps, values);
        } else {
          selectOptions = field.options;
        }

        children = selectOptions.map(({ value, text }) =>
          <option key={value} value={value}>{text}</option>
        );
      }

      return <Input {...props} type={type} required={required}>{children}</Input>;
    };

    validate(data) {
      const { fields, requiredFields } = this.props;

      const errs = {};

      const activeRequiredFields = _.intersection(fields, requiredFields);

      for (const field of activeRequiredFields) {
        const val = data[field];

        //If number input has 0, that counts as a value
        if (val === 0) continue;

        if (!val) {
          errs[field] = 'required';
        } else {
          if (val.trim && !val.trim()) errs[field] = 'required';
        }
      }

      return (_.isEmpty(errs)) ? null : errs;
    }

    /**
     * @param {Object} data
     */
    onChange(_data) {
      const { preChange } = this.props;
      const { values } = this.state;

      const data = preChange ? preChange(_data) : _data;

      const newValues = { ...values, ...data };

      this.setState({
        values: newValues,
        errors: this.validate(newValues)
      });
    }

    onSubmit = async () => {
      const { fields, onSubmit, onSuccess, onError } = this.props;
      const { values } = this.state;

      const data = _.pick(values, fields);

      this.setState({ loading: true });

      try {
        const result = await onSubmit(data);

        // If an error is returned, display it
        if (result instanceof Error) {
          throw result;
        }

        this.setState({
          errors: null,
          errMsg: null,
          success: true,
          loading: false,
        });

        if (onSuccess) onSuccess(result);

      } catch (err) {
        this.setState({
          errors: err.errors,
          errMsg: err.message || 'Validation failed',
          success: false,
          loading: false,
        });

        if (onError) onError(err);
      }
    }
  };

};


export default schema2form;
