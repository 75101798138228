import wurd, { WurdText, WurdObject } from 'wurd-react';
import styled from '@emotion/styled';
import { Modal, DropdownButton, MenuItem } from 'react-bootstrap';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import Section from '../../section';
import { getLabelForm, LabelItem } from 'plugins/labels/settings';
import { useModal } from '../../../hooks';
import { ModalDialog } from 'components/modal';
import ExportButton from 'components/export-button2';
import ImportButton from 'components/import-button2';


const InlineList = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  border: '1px solid #ddd',
  margin: 0,

  '& > li': {
    border: 'none',
    padding: 0,
    display: 'inline-flex',
    margin: 1,
    '&:last-child': { // overrides stupid main.css's .list-group-item:last-child
      margin: 1,
    },
  },
});

const InlineContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  marginBottom: 20,

  '.btn': {
    marginBottom: '0 !important',
  },
});

const TitleLabel = styled.h4({
  '@media (min-width: 768px)': {
    textAlign: 'right',
    width: 140,
  },
  margin: '0 1rem 0 0!important',
});


function LabelCrudList({ propName, settings, modal }) {
  const Form = getLabelForm(propName);
  const items = settings[propName] || [];

  function onChangeClose() {
    actions.business.fetch();
    modal.close();
  }

  function add() {
    modal.open(
      <Form
        onSubmit={actions.business[propName].create}
        onSuccess={onChangeClose}
        submitText={<WurdText id="common.modalForm.create" />}
        modal
        closeModal={modal.close}
        mode="create"
      />
    );
  }

  function edit(item) {
    modal.open(
      <Form
        initialValue={item}
        onSubmit={data => actions.business[propName].update(item.id, data)}
        onSuccess={onChangeClose}
        closeModal={modal.close}
        deleteButton={<WurdObject id="common.modalForm" keys="delete,confirmDelete">{wurd.text('common.modalForm.delete')}</WurdObject>}
        onDelete={() => handleDelete(item)}
        mode="update"
      />
    );
  }

  function handleDelete(item) {
    if (!window.confirm(wurd.text('common.modalForm.confirmDelete'))) return;

    return actions.business[propName].delete(item.id)
      .then(onChangeClose)
  }

  function openExportModal() {
    modal.open(
      <ModalDialog cms={wurd.block('common.modalForm.exportModal')}>
        <ExportButton items={items} filename={`${propName}.csv`} onComplete={modal.close} />
      </ModalDialog>
    );
  }

  function openImportModal() {
    modal.open(
      <ModalDialog cms={wurd.block('common.modalForm.importModal')}>
        <ImportButton actions={actions.business[propName]} onComplete={onChangeClose} />
      </ModalDialog>
    );
  }

  return (
    <InlineContainer>
      <TitleLabel>
        <WurdText id={`settings.inputs.${propName}.label`} />
      </TitleLabel>

      <InlineList className="list-group" style={{ flex: 1 }} >
        {items.length > 0
          ? items.map((item, i) => {
            return (
              <li
                key={i}
                className="list-group-item clearfix"
                style={{ cursor: 'pointer' }}
                onClick={() => edit(item)}
              >
                <LabelItem item={item} />
              </li>
            );
          })
          : (
            <li className="list-group-item text-muted" style={{ padding: 2 }}>
              <em><WurdText id="common.crudList.noResults" /></em>
            </li>
          )
        }
      </InlineList>

      <button
        type="button"
        className="btn-link" // .btn has an annoying margin-bottom: 5x!important in main.css
        onClick={add}
        style={{ padding: 8, outline: 'none' }}
      >
        <i className="fa fa-plus" />
      </button>
      {(items.length > 0 || helpers.auth.hasRole('manager')) && (
          <WurdObject id="settings.labels.menu" keys="export,import">
            <DropdownButton
              title={<i className="fa fa-ellipsis-v" />}
              id="menu"
              bsStyle="link"
              pullRight
              noCaret
            >
              {items.length > 0 && (
                <MenuItem key="export" onSelect={openExportModal}>
                  <i className="fas fa-fw fa-download" /> {wurd.text('settings.labels.menu.export') || 'Export'}
                </MenuItem>
              )}
              {helpers.auth.hasRole('manager') && (
                <MenuItem key="import" onSelect={openImportModal}>
                  <i className="fas fa-fw fa-upload" /> {wurd.text('settings.labels.menu.import') || 'Import'}
                </MenuItem>
              )}
            </DropdownButton>
          </WurdObject>
        )}
    </InlineContainer>
  );
}


export default function LabelsSection({ settings }) {
  const modal = useModal();
  const selfStorage = settings.modes.includes('selfStorage');
  const valetStorage = settings.modes.includes('valetStorage');

  return (
    <>
      <Section cms={wurd.block('settings.labels')}>
        <LabelCrudList propName="invoiceLabels" settings={settings} modal={modal} />
        {valetStorage && <LabelCrudList propName="itemLabels" settings={settings} modal={modal} />}
        <LabelCrudList propName="jobLabels" settings={settings} modal={modal} />
        {selfStorage && <LabelCrudList propName="siteLabels" settings={settings} modal={modal} />}
        {selfStorage && <LabelCrudList propName="unitLabels" settings={settings} modal={modal} />}
        {selfStorage && <LabelCrudList propName="unitTypeLabels" settings={settings} modal={modal} />}
        <LabelCrudList propName="userLabels" settings={settings} modal={modal} />
        {valetStorage && <LabelCrudList propName="valetOrderLabels" settings={settings} modal={modal} />}
      </Section>

      <Modal show={!!modal.content} onHide={modal.close}>
        {modal.content}
      </Modal>
    </>
  );
}
