import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import wurd, { WurdText } from 'wurd-react';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import UserCreditForm from '../form/user/credit';
import UserLoader from '../loaders/user';
import Link from 'components/nested-link';

const cms = wurd.block('userView.credits');


const UserCredits = createClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  },

  render() {
    const account = this.props.user.creditsDebits;

    return (
      <section className="block-flat">
        <div className="header">
          <button
            className="btn btn-link pull-right"
            onClick={this.addItem}
          >
            <i className="fa fa-plus"></i>
          </button>

          <cms.Text type="h3" id="title" />
        </div>
        <div className="content clearfix">
          <table className="table no-border table-hover">
            <tbody className="no-border-x no-border-y">
              {this.renderItems(account.items)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="10" className="text-right" style={{ fontSize: 16 }}>
                  <cms.Text id="total" /> <strong>{helpers.ui.currency(account.total)}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    );
  },

  renderItems(items) {
    if (!items) return this.renderLoading();
    if (!items.length) return this.renderNoResults();

    return items.map(item =>
      item.type === 'credit' ? this.renderCredit(item) : this.renderDebit(item)
    );
  },

  renderCredit(item) {
    return (
      <tr
        key={item.id}
        style={{ cursor: 'pointer' }}
        onClick={this.editItem.bind(null, item)}
      >
        <td><strong>{helpers.ui.date(item.created)}</strong></td>
        <td>
          <strong>{item.note}</strong>
          {item.sourceType === 'referral_referee' &&
            <UserLoader id={item.sourceId}>
              {(user) => (
                <div>Referred by <Link to={`/users/${user.email}`}>{user.name}</Link></div>
              )}
            </UserLoader>
          }
          {item.sourceType === 'referral_referrer' &&
            <UserLoader id={item.sourceId}>
              {(user) => (
                <div>
                  Referred <Link to={`/users/${user.email}`}>{user.name}</Link>
                </div>
              )}
            </UserLoader>
          }
        </td>
        <td className="text-right" style={{ color: 'green' }}>{helpers.ui.currency(item.amount)}</td>
      </tr>
    )
  },

  renderDebit(item) {
    var invoice = item.invoice;

    return (
      <tr key={item.id}>
        <td className="text-muted">{helpers.ui.date(item.created)}</td>
        <td className="text-muted">
          Credit used: <Link to={`/invoices/${invoice.sid}`}>[View invoice]</Link>
        </td>
        <td className="text-right text-danger">
          - {helpers.ui.currency(item.amount)}
        </td>
      </tr>
    )
  },

  renderLoading() {
    return <tr><td colSpan="7" className="text-center" style={{ padding: 40 }}><i className="fa fa-spin fa-spinner" /> <WurdText id="common.loading" /></td></tr>
  },

  renderNoResults() {
    return <tr><td colSpan="7" className="text-center" style={{ padding: 40 }}><cms.Text id="noResults" /></td></tr>
  },

  fetch() {
    actions.fetch('invoices', { owner: this.props.user.id })
      .then(invoices => this.setState({ invoices: invoices }))
  },

  addItem() {
    const { user, openModal, closeModal } = this.props;

    openModal(
      <UserCreditForm
        defaultValue={{}}
        onSubmit={actions.users.credits(user.id).create}
        onSuccess={this.onChange}
        title={<cms.Text id="create.title" vars={user} />}
        submitButton={<cms.Text id="create.ok" />}
        modal
        onHideModal={closeModal}
      />
    );
  },

  editItem(credit) {
    const { openModal, closeModal } = this.props;

    openModal(
      <div>
        <div className="modal-body">
          <table className="table no-border">
            <tbody className="no-border-x no-border-y">
              {this.renderCredit(credit)}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger pull-left"
            onClick={this.deleteItem.bind(null, credit)}
          >
            <cms.Object id="edit" keys="delete,confirmDelete">
              {cms.text('edit.delete')}
            </cms.Object>
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={closeModal}
          >
            <WurdText id="common.okBtn" />
          </button>
        </div>
      </div>
    )
  },

  deleteItem(credit) {
    if (!window.confirm(cms.text('edit.confirmDelete'))) return;

    actions.users.credits(this.props.user.id).delete(credit.id)
      .then(this.onChange)
  },

  onChange(invoice) {
    this.props.closeModal();
    this.props.onChange();
  }

});

export default UserCredits;
