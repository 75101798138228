import wurd from 'wurd-react';
import moment from 'moment-timezone';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import ListGroupItem from '../../list-group-item';
import ChargesMenuButton from '../../menu-button_charges';
import AddChargeForm from './add-charge-form';

const cms = wurd.block('valetOrder.ownerLocation');


export default function StepCharges({
  valetOrder,
  step,
  modal,
  fetchValetOrder,
}) {
  function addCharge(chargeType = null) {
    const initialValue = {
      quantity: 1,
      desc: chargeType && helpers.ui.getLangText(chargeType.title),
      price: chargeType?.amount,
    };

    modal.open(
      <AddChargeForm
        initialValue={initialValue}
        onSubmit={async (data) => {
          const charge = {
            ...data,
            date: moment().format('YYYY-MM-DD'),
            stepId: step.id,
          };

          await actions.valetOrders.charges(valetOrder.id).create(charge);
          fetchValetOrder();
        }}
        onSuccess={() => {
          modal.close();
          fetchValetOrder();
        }}
        closeModal={modal.close}
      />,
    );
  }

  const stepCharges = valetOrder.charges.filter((charge) => charge.stepId === step.id);


  return (
    <cms.Object id="charges" keys="noResults,addBtn">
      <ListGroupItem
        faIcon="far fa-money-check-alt"
        text={stepCharges.length > 0 && (
          <>
            {stepCharges.map((charge) => (
              <div key={charge.id}>
                {charge.desc} &times; {charge.quantity} = {helpers.ui.currency(charge.price * charge.quantity)}
              </div>
            ))}
          </>
        )}
        subtext={!stepCharges.length && cms.text('charges.noResults')}
        action={(
          <ChargesMenuButton
            title={cms.text('charges.addBtn')}
            onSelect={addCharge}
          />
        )}
        action2={(
          <button
            type="button"
            className="btn btn-link"
            onClick={addCharge}
          >
            {cms.text('charges.addBtn')}
          </button>
        )}
      />
    </cms.Object>
  );
}
