import wurd from 'wurd-react';

import ModalForm2 from '../../form2/modal-form2';
import { Field } from '../../form2';
import * as helpers from 'helpers';

const cms = wurd.block('itemView.forms.updateType');

export default function ItemTypeForm(props) {
  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode="create"
      className="form2"
      {...props}
    >
      {({ fieldProps, formValue }) => (
        <>
          <Field {...fieldProps('type')} required>
            <select>
              {helpers.item.listEmpty(true).map(({ type, title }) => (
                <option key={type} value={type}>{title} [{type}]</option>
              ))}
            </select>
          </Field>
        </>
      )}
    </ModalForm2>
  );
}
