/**
 * A simple Bootstrap input field.
 */
import { cloneElement } from 'react';
import wurd from 'wurd-react';
import Secret from './secret';


export default function Input({
  type, name, defaultValue, value = defaultValue, onChange, include = true, wurdSection, className = 'form-control', children, ...inputProps
}) {
  if (!include) return null;

  function handleChange(evt) {
    // if the evt.target is a select[multiple], patch evt.value to be an array of selected values
    // by default select[multiple].value is a string with the first selected value
    if (evt.target && evt.target.selectedOptions && evt.target.multiple) {
      onChange({
        target: {
          name: evt.target.name,
          value: [...evt.target.selectedOptions].map(o => o.value),
        }
      });
    } else {
      onChange({
        target: {
          name: evt.target.name,
          value: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value,
        }
      });
    }
  }

  const allInputProps = {
    type,
    name,
    className,
    value: value ?? '', // inputs wants empty strings for falsy values
    onChange: handleChange,
    ...type === 'number' && { autoComplete: 'off' },
    ...inputProps,
  }

  if (type === 'select') {
    const { type, ...props } = allInputProps;
    return (
      <select {...props}>
        {children}
      </select>
    );
  }

  if (type === 'secret') {
    const { type, ...props } = allInputProps;
    return (
      <Secret {...props} />
    );
  }

  if (type === 'checkbox') {
    const { value, ...props } = allInputProps;
    return (
      <input checked={value ?? false} {...props} />
    );
  }

  if (children) {
    return cloneElement(children, allInputProps);
  }

  return (
    <input {...allInputProps} />
  );
}
