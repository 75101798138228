import Activity from '../activity';


export default function UserDetailHistory({ user }) {
  return (
    <Activity
      context="user"
      endpoint={`/users/${user.id}/actions`}
    />
  );
}
