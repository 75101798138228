export default function ({ name, value, onChange, color = '#5cb85c', ...props }) {
  return (
    <button
      className="btn btn-link mb-0"
      {...props}
      type="button"
      onClick={() => onChange({ target: { name, value: !value } })}
    >
      <i className={`far ${value ? 'fa-toggle-on' : 'fa-toggle-off'}`} style={{ fontSize: '2rem', color: value ? color : '#0006' }} />
    </button>
  );
}