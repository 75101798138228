import { useItemLoader } from '../../hooks';
import { throttle_fetchOne } from '../../actions';

const fetch = throttle_fetchOne();

export default function AddonLoader({
  id,
  include,
  maxAge,
  loading = <i className="fas fa-spinner fa-spin" />,
  fallback = <i className="fas fa-question-circle" title="Not found" />,
  children: renderFn,
}) {
  const { item: addon, isLoading: isLoadingAddon, refetch: refetchAddon } = useItemLoader('addons', id, {
    include,
    maxAge,
    fetch,
  });

  const { item: addonConfig, isLoading: isLoadingAddonConfig, refetch: refetchAddonConfig } = useItemLoader('addon-configs', addon && addon.addonConfigId, {
    maxAge: 0,
    include: [],
  });

  const isLoading = isLoadingAddon || isLoadingAddonConfig;

  function refetch() {
    refetchAddonConfig();
    refetchAddon();
  }

  if (!addon && !addonConfig) {
    return isLoading ? loading : fallback;
  }



  return renderFn({ addonConfig, ...addon }, { isLoading, refetch });
}
