import wurd from 'wurd-react';

import { getAccountingCodeById } from '../helpers/business';

import ModalForm from './form2/modal-form';
import { Field } from './form2';
import AccountingCodeSelect from './form2/accounting-code-select';
import Section from './section';

const settingsCms = wurd.block('settings.accounting');


export default function CustomSystemCharges({
  modal,
  cms,
  systemCharges = {},
  onSubmit,
  onSuccess,
}) {
  function handleSubmit(data) {
    // Remove empty objects where no accountingCode was selected
    const formattedData = Object.fromEntries(
      Object.entries(data).map(([chargeType, { accountingCodeId }]) => [chargeType, { accountingCodeId: accountingCodeId || null }])
    );

    return onSubmit(formattedData);
  }

  function edit() {
    modal.open(
      <ModalForm
        initialValue={systemCharges}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        closeModal={modal.close}
        wurdSection={cms.id()}
        mode="update"
      >
        {(fieldProps) => (
          <>
            <Field
              {...fieldProps('unitDeposit.accountingCodeId')}
              label={settingsCms.text('unitDeposit')}
            >
              <AccountingCodeSelect nullText={wurd.text('common.phrases.default')} />
            </Field>

            <Field
              {...fieldProps('unitRent.accountingCodeId')}
              label={settingsCms.text('unitRent')}
            >
              <AccountingCodeSelect nullText={wurd.text('common.phrases.default')} />
            </Field>
          </>
        )}
      </ModalForm>,
    );
  }

  // Convert the systemCharges object into a simpler array
  const simpleCharges = Object.entries(systemCharges).map(([chargeType, { accountingCodeId }]) => {
    if (!accountingCodeId) return null;

    return {
      chargeType,
      accountingCode: getAccountingCodeById(accountingCodeId) || {},
    };
  }).filter(Boolean);

  return (
    <Section
      cms={cms}
      onClickEdit={edit}
    >
      {simpleCharges.length > 0 && (
        <table className="table no-border table-hover no-margin">
          <thead className="no-border">
            <tr>
              <settingsCms.Text type="th" id="type" />
              <settingsCms.Text type="th" id="code" />
              <settingsCms.Text type="th" id="taxDesc" />
              <settingsCms.Text type="th" id="taxPct" />
            </tr>
          </thead>
          <tbody className="no-border-x no-border-y">
            {simpleCharges.map(({ chargeType, accountingCode }) => (
              <tr key={chargeType}>
                <td><strong><settingsCms.Text id={chargeType} /></strong></td>
                <td>{accountingCode.code}</td>
                <td>{accountingCode.taxDesc}</td>
                <td>{accountingCode.taxPct && `${accountingCode.taxPct}%`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Section>
  );
}
