import moment from 'moment-timezone';
import { WurdObject } from 'wurd-react';

import { datetime } from '../../helpers/ui';
import { currentLang } from 'utils/language';
import getAction from './getAction';

import Markdown from '../markdown';
import styled from '@emotion/styled';


const ActivityItemContent = styled.div({
  overflow: 'hidden',
  'img': {
    maxWidth: '100%',
    maxHeight: 600
  },
  'cite': {
    filter: 'opacity(.5)'
  },
  'ol.inline': {
    display: 'inline',
    padding: 0,

    '&>li': {
      display: 'inline',
    },

    '&.array::before': {  content:  '"["' },
    '&.array::after': {  content:  '"]"' },
    '&.object::before': {  content:  '"{"' },
    '&.object::after': {  content:  '"}"' },
    '&>li:not(:last-child)::after': { content: '", "' },
  },

  '.extra li': {
    fontSize: 14,
    lineHeight: '1.5em',
  }
});


export default function ActivityItem({ currentUser, context, item }) {
  const action = getAction(currentUser, item, context);

  return (
    <WurdObject
      key={item.id}
      id="actions"
      keys={item.type}
      type="li"
      className="activity__item"
    >
      <i className={action.icon} />
      <span className="date">{moment(item.date).locale(currentLang).format('D MMM')}</span>
      <ActivityItemContent className="content">
        <Markdown>{action.text}</Markdown>

        <div className="extra">
          {action.extra && (
            typeof action.extra === 'string'
              ? <Markdown as="blockquote">{action.extra}</Markdown>
              : <blockquote>{action.extra}</blockquote>
          )}
        </div>
        
        <p><small className="text-muted">{datetime(item.date)}</small></p>
      </ActivityItemContent>
    </WurdObject>
  );
}
