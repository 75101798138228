import { Component } from 'react';
import { Rect, Text, Group, Image } from 'react-konva'

import colors from '../colors';
import { TYPES, MEASURE_TO_FT } from '../Constants';


export default class SitemapItem extends Component {

  state = {
    image: null,
    initialPosition: { x: this.props.position.x, y: this.props.position.y }, // never updated, but we keep initial positions here, if you use position coordinates in render() it'll do an update loop
  };

  componentDidUpdate(prevProps) {
    if (prevProps.position !== this.props.position) {
      const { x, y, scaleX, scaleY, rotation } = this.props.position;
      this.group.setPosition({ x, y })
      this.group._setTransform({ scaleX, scaleY, rotation })
    }

    if (prevProps.isSelected !== this.props.isSelected && this.group) {
      this.group.moveToTop();
    }

    this.fitTextScale(this.props.position);
  }

  async componentDidMount() {
    const image = new window.Image()
    const item = this.props.item
    if (item.image) {
      image.src = (await import(`../Images/${item.image}.png`)).default;
      image.onload = () => {
        this.setState({ image })
      }
    }
    if (this.group) {
      const groupX = this.group.x()
      const groupY = this.group.y()
      this.group.setPosition({ x: groupX, y: groupY })
      this.group.offsetY(groupY)
      this.group.offsetX(groupX)

      const position = this.props.position;
      const { scaleX, scaleY } = position
      this.group._setTransform({ scaleX, scaleY })

      this.fitTextScale(position);
    }
  }

  fitTextScale(objectPosition) {
    if (this.textNode) {
      const { scaleX = 1, scaleY = 1, width = 1 } = objectPosition;
      const itemScaleX = Math.max(1, scaleX);
      const itemScaleY = Math.max(1, scaleY);
      const itemWidth = width * this.props.scale;
      const itemHeight = width * this.props.scale;
      this.textNode.scaleX(1 / itemScaleX);
      this.textNode.scaleY(1 / itemScaleY);
      this.textNode.offsetX(itemWidth / 2 - (itemScaleX * itemWidth) / 2);
      this.textNode.offsetY(itemHeight / 2 - (itemScaleY * itemHeight) / 2);
    }
  }

  onTransform = (e, item) => {
    const attrs = e.currentTarget.attrs

    this.fitTextScale({
      width: attrs.item.width,
      height: attrs.item.height,
      scaleX: attrs.scaleX,
      scaleY: attrs.scaleY,
    });

    if (this.group) {
      this.group.moveUp()
      const x = this.group.x()
      const y = this.group.y()
      const rotation = this.group.rotation()
      this.props.updateItemPosition({
        id: item.id,
        width: item.width,
        length: item.length,
        scaleX: attrs.scaleX,
        scaleY: attrs.scaleY,
        x,
        y,
        rotation,
      })
    }
  }

  handleDoubleClickText = () => {
    const { item, editMode } = this.props
    if (!editMode || item.type !== TYPES.object) return
    // create textarea over canvas with absolute position
    // first we need to find position for textarea
    const textNode = this.textNode
    const stage = this.group.getStage()
    const layer = this.layer
    // at first lets find position of text node relative to the stage:
    var textPosition = textNode.getAbsolutePosition()
    // then lets find position of stage container on the page:
    var stageBox = stage.container().getBoundingClientRect()

    // so position of textarea will be the sum of positions above:
    var areaPosition = {
      x: stageBox.left + textPosition.x,
      y: stageBox.top + textPosition.y,
    }

    // create textarea and style it
    var textarea = document.createElement('textarea')
    document.body.appendChild(textarea)

    textarea.value = textNode.text()
    textarea.style.position = 'absolute'
    textarea.style.top = areaPosition.y + 'px'
    textarea.style.left = areaPosition.x + 'px'
    textarea.style.width = textNode.width()

    textarea.focus()

    textarea.addEventListener('keydown', e => {
      switch (e.key) {
        case 'Enter': // save & hide on enter
          this.props.updateItemPosition({
            id: item.id,
            name: textarea.value,
          });
          textNode.text(textarea.value)
          layer.draw()
          document.body.removeChild(textarea)
          break;

        case 'Escape':
          document.body.removeChild(textarea)
          break;
      }
    });
  }

  render() {
    const { item, position, scale, editMode, isSelected, highlightUnitIds } = this.props;
    const { initialPosition, image } = this.state;

    const itemWidth = (item.width || 5) * (MEASURE_TO_FT[item.measure] || 1) * scale;
    const itemLength = (item.length || 5) * (MEASURE_TO_FT[item.measure] || 1) * scale;
    const shouldBeImage = item.type === TYPES.object && item.image;

    const itemState = item.overdue ? 'overdue' : item.state;
    const isHighlighted = !highlightUnitIds || highlightUnitIds.includes(item.id);
    const itemColor = isHighlighted && colors[itemState] || '#ddd7';
    const strokeColor = isSelected ? colors.red : isHighlighted ? colors.black : '#aaa';
    const textColor = isSelected ? colors.white : isHighlighted ? colors.black : '#aaa';

    const itemName = position.name || item.name || ''; // user can edit an object name (dbl-click), in this case it gets saved in site.positions

    return (
      <Group
        item={item}
        dragBoundFunc={pos => this.props.dragBoundFunc(pos, item)}
        ref={ref => (this.group = ref)}
        onTransform={e => this.onTransform(e, item)}
        onClick={this.props.handleStageClick}
        onTap={this.props.handleStageClick}
        onDragMove={e => this.onTransform(e, item)}
        draggable={editMode}
        rotation={position.rotation}
        x={initialPosition.x}
        y={initialPosition.y}
        name={`group_${item.id}`}>
        {shouldBeImage && (
          <Image
            name={item.name}
            width={itemWidth}
            height={itemLength}
            x={initialPosition.x}
            y={initialPosition.y}
            image={image}
            stroke={
              isSelected ? colors.red : item.name === 'Toilet' && colors.black
            }
          />
        )}
        {!shouldBeImage && (
          <Group ref={ref => (this.layer = ref)}>
            <Rect
              name={itemName}
              width={itemWidth}
              height={itemLength}
              fill={itemColor}
              x={initialPosition.x}
              y={initialPosition.y}
              stroke={!shouldBeImage && strokeColor}
            />
            <Text
              x={initialPosition.x}
              y={initialPosition.y}
              fill={textColor}
              height={itemLength}
              width={itemWidth}
              fontSize={position.type !== TYPES.object || position.type === TYPES.object && item.length >= 5 ? 24 : undefined}
              verticalAlign={'middle'}
              align={'center'}
              text={itemName}
              onDblClick={this.handleDoubleClickText}
              ref={ref => (this.textNode = ref)}
            />
            {item.hasSecuritySystem && (
              <Text
                x={initialPosition.x + itemWidth - 20}
                y={initialPosition.y + itemLength - 20}
                fill={textColor}
                fontFamily={'"Font Awesome 5 Pro"'}
                fontWeight={900}
                fontSize={16}
                text={'\uf3ed'}
              />
            )}
          </Group>
        )}
      </Group>
    )
  }
}
