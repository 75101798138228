import wurd, { WurdText } from 'wurd-react';
import UploadImageButton from '../uploader';

const cms = wurd.block('settings.brand');


export default function ImageUploader({ name, value: url, onChange, style, wurdKey, showUrlButton }) {
  return (
    <div className="form-group">
      <label className="control-label col-sm-3">{wurd.text(`${wurdKey}.label`) ? <WurdText id={`${wurdKey}.label`} /> : name}</label>
      <div className="col-sm-9">
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img src={url} style={style} alt="Preview" />
          </a>
        )}

        <div>
          <UploadImageButton
            onChange={onChange}
            text={<cms.Text id='uploadImage' />}
            name={name}
            value={url}
            wurdKey={wurdKey}
            showUrlButton={showUrlButton}
          />
        </div>

        <p className="help-block"><WurdText id={`${wurdKey}.help`} /></p>
      </div>
    </div>
  );
}
