import AccountPlugin from '../../plugins/account';
import { hasRole } from 'helpers/auth';


export default function UnitDetailDeposit({
  modal,
  cms,
  unit,
  rental,
  onChange,
}) {
  return (
    <AccountPlugin
      cms={cms.block('deposit')}
      endpoint={`/v1/admin/unit-rentals/${rental.id}/deposit`}
      modal={modal}
      onChange={onChange}
      canAdd={hasRole('__superadmin')}
    />
  );
}
