import wurd from 'wurd-react';

import JobStep from '../../job-step2';

const cms = wurd.block('unitJobs.transfer.steps.order_cancel');


export default function UniveTransferSteps_waitForTransferDate({
  job,
  step,
  onChange,
  modal,
}) {
  function render_completed() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" />}
      />
    );
  }

  if (step.state === 'completed') return render_completed();

  return null;
}
