import wurd from 'wurd-react';
import _ from 'lodash';

import MultiLangText from '../multi-lang-text';

import ModalForm from '../modal-form';
import Field from '../horizontal-field';
import DatePicker from '../datepicker';

const cms = wurd.block('user.charge');

export default function UserChargeForm({ initialValue, closeModal, mode = 'create', onSubmit, ...rest }) {
  return (
    <ModalForm
      wurdSection={cms.id()}
      mode={mode}
      onSubmit={onSubmit}
      initialValue={initialValue}
      closeModal={closeModal}
      {...rest}
    >
      {(getProps) => {
        return (
          <>
            <Field {...getProps('title')} required>
              <MultiLangText autoFocus />
            </Field>

            <Field {...getProps('amount')} required type="number" />

            <Field {...getProps('startDate')} required>
              <DatePicker />
            </Field>

            <Field {...getProps('endDate')}>
              <DatePicker />
            </Field>
          </>
        );
      }}
    </ModalForm>
  );
}
