import { useRef, useState } from 'react';
import wurd from 'wurd-react';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import store from 'store';
import LangText from 'components/lang-text';
import { getLangText } from 'helpers/ui';
import { Filter } from './button';


const Form = styled.form({
  position: 'relative',
  width: 'max(15%, 150px)',
  display: 'inline-flex',
  alignItems: 'center',
  margin: '0 0 5px 8px',
  input: {
    minWidth: 60
  },
  '[type="submit"]': {
    padding: '2px 4px 0',
    position: 'absolute',
    right: 0,
    zIndex: 2,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  'input::-webkit-calendar-picker-indicator': {
    display: 'none!important'
  }
});

export default function SearchFilter({
 searchables = [],
 modelName,
 cms,
 active,
 name,
 onSelect = () => { },
}) {
  const { settings } = store.get();
  const inputRef = useRef();
  const [edit, setEdit] = useState(!active);
  // useEffect(() => { // autofocus only if 
  //   if (edit && document.activeElement !== inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [!edit]);
  
  function search(e) {
    e.preventDefault();
    onSelect(inputRef.current?.value);
    setEdit(false)
  }

  function focus(e) {
    setEdit(true);
    window.requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  }

  function select(code) {
    inputRef.current.value = `${code}:${inputRef.current.value.trim().split(':').slice(-1)[0].replace(/ +/g, '+')}`;
  }

  return (
    <cms.Object
      keys="title,placeholder"
      className={`filter ${active ? 'filter--active' : ''}`}
    >
      {(edit || !active)
        ? (
          <Form onSubmit={search}>
            <input
              type="search"
              name={name}
              ref={inputRef}
              defaultValue={active}
              className="form-control input-sm"
              placeholder={cms.text('placeholder')}
              onBlur={search}
              list={`${name}_fields`}
            />
            <Button key="search" type="submit" bsStyle="link" bsSize="sm">
              <i className="fal fa-search" />
            </Button>
            <datalist id={`${name}_fields`}>
              {[
                ...searchables.map(name => ({ code: name, title: wurd.text(`${modelName}.${name}.label`) })),
                ...settings[`${modelName}CustomFields`] || [],
              ].map(({ code, title }) => (
                <option key={code} value={`${code}:`} onClick={() => select(code)} style={{ marginRight: 4 }}>
                  {wurd.text('common.phrases.by') || 'By'} {getLangText(title)}
                </option>
              ))}
            </datalist>
          </Form>
        ) : (
          <Filter>
            <Button bsStyle="link" onClick={focus}>
              {cms.text('title') || 'Search'}: {active}
            </Button>
            <Button bsStyle="link" onClick={() => onSelect(null)}>
              <i className="fal fa-times" />
            </Button>
          </Filter>
        )}
    </cms.Object>
  );
}
