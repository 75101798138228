import wurd from 'wurd-react';

import { useListLoader } from 'hooks';

import TableList from 'components/table-list';
import Filters from './filters';
import Item from './item';

const cms = wurd.block('unitJobList');

function viewJob(job) {
  if (job.type === 'task') {
    const { targetType, targetId } = job.result;

    switch (targetType) {
      case 'unit':
        return `/units/${targetId}`;
      
      case 'unitRental':
        return `/unit-rentals/${targetId}`;
      
      case 'user':
        return `/users/${targetId}`;
      
      case 'invoice':
        return `/invoices/${targetId}`;
      
      case 'addon':
        return `/addons/${targetId}`;
    }
  }

  const rentalId = job.result.unitRentalId || job.result.newRentalId;

  if (job.type === 'unit_moveIn' && !rentalId) {
    return `/sites/${job.result.siteId}/jobs/${job.id}`;
  }

  return `/unit-rentals/${rentalId}`;
}


export default function UnitJobList({
  listQuery,
  hideFilters = false,
  hideSite = false,
  hideOwner = false,
  hideUnit = false,
}) {
  const listLoader = useListLoader('jobs', listQuery.params, { maxAge: 0 });

  const renderItem = (job) => (
    <Item
      job={job}
      hideSite={hideSite}
      hideOwner={hideOwner}
      hideUnit={hideUnit}
    />
  );

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={!hideFilters && (
        <Filters
          listQuery={listQuery}
          hideSite={hideSite}
          hideOwner={hideOwner}
          hideUnit={hideUnit}
        />
      )}
      renderItem={renderItem}
      getItemLink={viewJob}
    />
  );
}
