import wurd from 'wurd-react';
import * as helpers from 'helpers';
import moment from 'moment-timezone';

import UnitRentalLoader from '../loaders/unit-rental';
import UserLoader from '../loaders/user';
import SiteLoader from '../loaders/site';

const styles = {
  secondary: {
    color: '#bbb',
  },
  tertiary: {
    color: '#bbb',
    fontSize: '.8em',
    lineHeight: '2.1em',
  },
};

const cms = wurd.block('invoiceView.payments');


export default function PaymentListItem({
  payment,
  hideSite = false,
  hideOwner = false,
  hideUnit = false,
}) {

  const ownerName = !hideOwner && (
    <strong>
      <UserLoader id={payment.invoice.owner.id} loading={payment.invoice.owner.name}>
        {user => user.name}
      </UserLoader>
      &nbsp;&nbsp;
    </strong>
  );

  const unitName = !hideUnit && payment.invoice.unitRentalId && (
    <UnitRentalLoader id={payment.invoice.unitRentalId} include="unit" maxAge={300000}>
      {(unitRental) => (
        <span style={styles.secondary} className="text-nowrap">
          <i className="far fa-building" /> {unitRental.unit?.name}
        </span>
      )}
    </UnitRentalLoader>
  );

  return (
    <>
      <div role="cell">
        <div>
          {ownerName}
          <small className="visible-xs-inline">{unitName}</small>
          <span style={styles.secondary} className="hidden-xs">{payment.invoice.sid.toUpperCase()}</span>
        </div>

        <div style={styles.secondary} className="hidden-xs">
          {unitName}
          {!hideSite && payment.invoice.siteId && <span className="hidden-xs">, <SiteLoader id={payment.invoice.siteId}>{site => site.code}</SiteLoader></span>}
        </div>

        <div style={styles.secondary} className="visible-xs">
          {helpers.ui.date(payment.date || moment.utc(payment.created))} &nbsp; {payment.invoice.sid.toUpperCase()}
        </div>
      </div>

      <div role="cell" className="hidden-xs">
        <div>{helpers.ui.date(payment.date || moment.utc(payment.created))}</div>
      </div>

      <div role="cell">
        <span style={{ fontWeight: 400 }}>{helpers.payment.method_display(payment) || helpers.payment.type_display(payment)}</span>
        <div className="text-muted" style={{ wordBreak: 'break-all' }}>{[payment.data?.id, payment.notes].filter(Boolean).join(' ')}</div>
      </div>

      <div role="cell" className="text-right">
        {helpers.ui.currency(payment.amount)}
      </div>
    </>
  );
}