import _ from 'lodash';

import * as language from '../utils/language';
import * as settingsHelpers from './settings';
import { getAccountingCodeById } from './business';
import { getLangText, currency } from './ui';
import { getLangKeys } from './csv';
import * as siteHelpers from './site';
import * as actions from '../actions/general';


function doTranslate(itemConfig, lang) {
  const obj = { ...itemConfig };

  ['title', 'subtitle', 'info'].forEach(prop => {
    obj[prop] = getLangText(obj[prop], lang);
  });

  return obj;
}


/**
 * Returns the list of types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const list = function (translate) {
  const configs = settingsHelpers.get().sites.flatMap(site => site.unitTypes);

  if (translate) {
    const lang = language.get();

    return configs.map(config => doTranslate(config, lang));
  } else {
    return configs;
  }
};


/**
 * Returns an object of item types, keyed by type
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object}
 */
export const byId = function (translate) {
  return _.keyBy(list(translate), 'id');
};


/**
 * Returns a specific plan type
 *
 * @param {Object} site
 * @param {String} id
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const get = function (site, id, translate) {
  const type = _.find(site.unitTypes, { id: id });

  if (!type) {
    const err = new Error(`unitType ${id} not found`);
    //_errs.push(err);
    console.error(err);
    return {};
  }

  if (translate) {
    return doTranslate(type, language.get());
  } else {
    return type;
  }
};

export const currentPrice = function (unitType) {
  return unitType.price_dynamic ?? (unitType.price_display || unitType.price);
}

export const sort_occupancyPrices = function (a, b) {
  return a.threshold - b.threshold;
}


export async function fetchDetailed(params) {
  const unitTypes = await actions.fetch('unit-types', params, { skipCache: true });
  const sites = await actions.fetch('sites', { ids: unitTypes.map(unitType => unitType.siteId) }, { skipCache: true }).then(sites => new Map(sites.map(o => [o.id, o])));

  return unitTypes.map(unitType => {
    const site = sites.get(unitType.siteId);
    return { ...unitType, site };
  });
}

/**
 * @param {Object} unitType
 * @return {String} csv
 */
export function toCsv(unitType, { site } = {}) {
  return {
    id: unitType.id,
    code: unitType.code,
    ...getLangKeys({
      title: unitType.title,
      subtitle: unitType.subtitle,
    }),
    image: unitType.image,
    deposit: unitType.deposit,
    deposit_display: unitType.deposit_display,
    price: unitType.price,
    price_display: unitType.price_display,
    price_dynamic: unitType.price_dynamic,
    hidden: unitType.hidden,
    order: unitType.order,
    groupId: unitType.groupId,
    tagIds: unitType.tagIds?.join(','),
    'systemCharges.unitDeposit.accountingCodeId': getAccountingCodeById(unitType.systemCharges?.unitDeposit.accountingCodeId)?.code,
    'systemCharges.unitRent.accountingCodeId': getAccountingCodeById(unitType.systemCharges?.unitRent.accountingCodeId)?.code,
    ...unitType.charges && Object.fromEntries(
      unitType.charges.flatMap((charge, i) => {
        return [
          [`charges.${i}.type`, charge?.type || ''],
          ...Object.entries(getLangKeys({ [`charges.${i}.title`]: charge?.title })),
          [`charges.${i}.amount`, charge?.amount || ''],
          [`charges.${i}.accountingCodeId`, getAccountingCodeById(charge?.accountingCodeId)?.code || ''],
        ];
      })
    ),
    ...site && _.mapKeys(siteHelpers.toCsv(site), (v, k) => `site.${k}`)
  };
}


export async function fromCsv({ _site_code, ...unitType }, params) {
  if (_site_code && params && _site_code !== params.idOrCode) throw new Error(`site "${_site_code}" doesn't match current site "${params.idOrCode}"`);

  const site = params && await actions.fetchOneWithCache('sites', unitType.siteId || params.idOrCode, !unitType.siteId && { altIdKey: 'code' });

  return {
    ...unitType,
    siteId: site?.id,
    tagIds: (unitType.tagIds || undefined)?.split(','),
    labels: (unitType.labels || undefined)?.split(','),
  };
}
