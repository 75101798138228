import { pick } from 'lodash';
import { WurdText } from 'wurd-react';

import store from '../../../store';

import ModalForm2 from '../../form2/modal-form2';
import { Field } from '../../form2';


const wurdSection = 'unitRental';


export default function UnitRentalForm({
  onSubmit,
  accessTypes,
  ...rest
}) {
  const { settings } = store.get();

  const showInvoiceDate = settings.invoiceGenerationMode === 'anniversary';

  const fields = [
    'accessCodes',
    'price',
    ...showInvoiceDate ? ['invoiceDate'] : [],
  ];

  function handleSubmit(data, patches) {
    return onSubmit(pick(patches, fields));
  }

  return (
    <ModalForm2
      wurdSection={wurdSection}
      {...rest}
      onSubmit={handleSubmit}
    >
      {({ fieldProps }) => (
        <div>
          {Object.entries(accessTypes || {}).map(([key, acl]) => {
            const labelKey = `${wurdSection}.${key}.label`;
            return (
              <Field
                key={key}
                {...fieldProps(`accessCodes.${key}`)}
                label={<WurdText id={labelKey} />}
                wurdKeys={`${labelKey},help`}
                readOnly={!acl.includes('write')}
              />
            );
          })}

          <Field {...fieldProps('price')} type="number" autoFocus required />

          {showInvoiceDate && (
            <Field {...fieldProps('invoiceDate')} type="number" min="1" max="31" />
          )}
        </div>
      )}
    </ModalForm2>
  );
}
