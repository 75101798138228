import { Suspense, lazy } from 'react';


export default function DynamicLoader(cb, fallback = <i className="fa fa-spinner fa-spin" />) {
  const Component = lazy(cb);

  return (props) => (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  );
}
