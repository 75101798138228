import wurd from 'wurd-react';
import styled from '@emotion/styled';

import * as helpers from 'helpers';
import { useListLoader } from 'hooks';

import Empty from 'components/empty';
import UnitTypePreview from '../unit-type-preview';
import UnitOnboarding from '../unit-onboarding';
import Task from '../task';


const cms = wurd.block('siteOnboarding.tasks');

const PreviewList = styled.div({
  width: '75%',
  margin: 'auto!important',
  filter: 'opacity(.8)',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});


export default function SiteOnboardingUnitsTask({ site, fetchSite, modal }) {  
  const nUnits = helpers.site.getOccupancy(site).total;
  const { items: unitTypes = [] } = useListLoader('unit-types', { siteId: site.id });
  const nUnitTypes = unitTypes.length;

  function openUnitOnboarding(props) {
    modal.open(
      <UnitOnboarding
        site={site}
        {...props}
        fetchSite={fetchSite}
        onClose={modal.close}
      />,
      { bsSize: 'lg' }
    );
  }


  return (
    <Task
      title={<cms.Text id="units.title" />}
      isDone={nUnits > 0}
      open={nUnits === 0}
      action={
        <>
          {unitTypes.length > 0 && (
            <cms.Text id="units.action.text" className="text-muted" vars={{ nUnitTypes: String(unitTypes.length), nUnits: String(nUnits) }} />
          )}
          <button className="btn btn-link" onClick={openUnitOnboarding}>
            <i className="far fa-plus" /> <cms.Text id={`units.action.${unitTypes.length > 0 ? 'done' : 'todo'}`} />
          </button>
        </>
      }
    >
      {unitTypes.length > 0
        ? (
          <PreviewList>
            {unitTypes.sort(helpers.site.unitTypes_compare).map(unitType => (
              <UnitTypePreview
                key={unitType.id}
                item={unitType}
                button={(
                  <div className="btn-group">
                    <button className="btn btn-primary" onClick={() => openUnitOnboarding({ unitType })}>
                      <cms.Text id="units.preview.btn" />
                    </button>
                    <button className="btn btn-primary" onClick={() => openUnitOnboarding({ unitType, activeTab: 'units' })}>
                      <cms.Text id="units.preview.btn2" vars={{ nUnits: helpers.site.getOccupancy(unitType).total }} /> <i className="far fa-plus" />
                    </button>
                  </div>
                )}
              />
            ))}
          </PreviewList>
        ) : (
          <Empty
            cms={wurd.block('siteView.unitTypes.empty')}
            onClickAdd={openUnitOnboarding}
          />
        )}
    </Task>
  );
}