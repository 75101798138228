import { useState } from 'react';
import wurd, { WurdText } from 'wurd-react';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import * as helpers from '../../helpers';
import * as actions from '../../actions';
import { useApiForm } from '../../hooks';

import Tabs from 'components/tabs';
import UnitTypePreview from './unit-type-preview';
import UnitNameList from './unit-name-list';
import UnitTypeDetailsForm from './forms/unit-type-details';
import UnitTypePricingForm from './forms/unit-type-pricing';
import UnitsForm from './forms/units';
import ErrorMessage from 'components/error-message';


const cms = wurd.block('siteOnboarding.unit');

const SplitView = styled.div({
  display: 'flex',
  gap: '1rem',
  '> *': {
    flex: 1,
    padding: '1rem',
  },

  '> :last-child': {
    borderLeft: '1px solid #eeea',
    paddingLeft: '2rem',

    '@media (max-width: 1200px)': {
      display: 'none!important',
    },

    '> :only-child': {
      position: 'sticky',
      top: 20,
    }
  }
});

const TabsStyled = styled(Tabs)({
  position: 'static',
  '> .inner > buton:only-child': {
    display: 'none'
  }
});

const forms = {
  'unit-type-details': UnitTypeDetailsForm,
  'unit-type-pricing': UnitTypePricingForm,
  'units': UnitsForm,
};

const defaultUnitTypeData = {
  image: 'https://storeganise.s3.amazonaws.com/images/unit.png',
  //title: { en: 'Small (5x5)' },
  //subtitle: { en: '1 bedroom apartment' },
  deposit: 100,
  price: 100,
  floor: 1,
};


export default function UnitOnboardingForm({ site, unitType, activeTab: defaultActiveTab, fetchSite, onClose }) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || 'unit-type-details');
  const mode = !unitType && !defaultActiveTab ? 'full' : defaultActiveTab === 'units' ? 'addUnits' : 'editUnitType';
  const tabs = mode === 'full'
    ? ['unit-type-details', 'unit-type-pricing', 'units']
    : mode === 'addUnits'
      ? ['units']
      : ['unit-type-details', 'unit-type-pricing'];

  const [searchParams] = useSearchParams();
  const searchData = Object.fromEntries(searchParams);

  const activeTabIndex = tabs.indexOf(activeTab);

  function showNextTab() {
    setActiveTab(tabs[activeTabIndex + 1]);
  }

  async function handleSubmit({ names, width, length, height, floor, ...data }) {
    let _unitType = unitType;

    if (tabs.includes('unit-type-details')) {
      const unitTypeData = _.pick(_.mapValues(data, v => v || undefined), [
        'image',
        'imageFit',
        'title',
        'code',
        'subtitle',
        'deposit',
        'deposit_display',
        'price',
        'price_display',
        'tagIds',
      ]);

      _unitType = unitType
        ? await actions.update('unit-types', unitType.id, unitTypeData)
        : await actions.create('unit-types', { ...unitTypeData, siteId: site.id });

      await fetchSite();
    }

    if (tabs.includes('units')) {
      const unitNames = helpers.unit.expandNameRange(names);
      
      for (const name of unitNames) {
        await actions.units.create({
          siteId: site.id,
          typeId: _unitType.id,
          name,
          width,
          length,
          height,
          floor,
          defaultPrice: _unitType.price,
          defaultDeposit: _unitType.deposit,
          featureIds: _unitType.tagIds,
        });
      }

      // update site stats
      await actions.update('sites', site.id, { updateAvailability: true });
    }
  }

  const { inputProps, submit, formValue, errMsg, errors, loading } = useApiForm({
    initialValue: {
      ...defaultUnitTypeData,
      ...searchData,
      ...unitType,
      names: searchData.names,
    },
    onSubmit: handleSubmit,
    onSuccess: () => fetchSite().then(onClose),
  });

  const errs = Object.fromEntries(Object.entries(errors || {}).map(([k, v]) => [k.split('.').slice(-1)[0], v]));

  const unitTags = helpers.unit.tag.list(true).filter(({ id }) => formValue.tagIds?.includes(id));

  const unitNames = helpers.unit.expandNameRange(formValue.names).sort((a,b) => a.localeCompare(b));

  return (
    <>
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>&times;</button>
        <h4 className="modal-title"><cms.Text id={`${mode}.title`} /></h4>
      </div>

      <SplitView className={`modal-body ${errMsg ? 'error' : ''}`}>
        <div>
          <TabsStyled
            activeTabId={activeTab}
            onChange={newTab => setActiveTab(newTab)}
            items={tabs.map((tab) => ({
              id: tab,
              title: <cms.Text id={`tab.${tab}`} />,
              renderContent: () => {
                const Form = forms[tab];
                const cms = wurd.block(tab === 'units' ? 'unit' : 'unitType');
                const getProps = name => ({ ...inputProps(name), error: errs[name], cms });

                return (
                  <form
                    id={`tab-${tab}`}
                    onSubmit={e => {
                      e.preventDefault();
                      return activeTabIndex === tabs.length - 1
                        ? submit(e)
                        : setActiveTab(tabs[activeTabIndex + 1]);
                    }}>
                    <Form site={site} getProps={getProps} inputProps={inputProps} errs={errs} />
                  </form>
                );
              },
            }))}
          />

          {errMsg && (
            <ErrorMessage>{errMsg} {Object.entries(errors || {}).map(([k, v]) => `'${k}': ${v.message || v}`).join(', ')}</ErrorMessage>
          )}
        </div>
        <div>
          <div>
            <h4 style={{ marginBottom: '2.4rem' }}><cms.Text id="preview.title" /></h4>
            <UnitTypePreview
              item={{ ...unitType, ...formValue, tags: unitTags }}
            />
            {unitNames.length > 0 && (
              <div style={{ marginTop: '3rem' }}>
                <p><cms.Text id="preview.unitNames" /></p>
                
                <UnitNameList>{unitNames}</UnitNameList>
              </div>
            )}
          </div>
        </div>
      </SplitView>

      <div className="modal-footer">
        {activeTabIndex === 0 ? null : (
          <button
            key="prev"
            type="button"
            className="btn btn-default"
            onClick={activeTabIndex === 0 ? onClose : () => setActiveTab(tabs[activeTabIndex - 1])}
          >
            <WurdText id="common.prevBtn" />
          </button>
        )}

        {activeTabIndex === tabs.length - 1
          ? (
            <button
              key="submit"
              className="btn btn-primary"
              id={`tab-${activeTab}`}
              type="submit"
              disabled={loading}
              form={`tab-${activeTab}`}
            >
              {loading
                ? <i className="fa fa-spinner fa-spin" />
                : <cms.Text id={`${mode}.submit`} />
              }
            </button>
          ) : (
            <button
              key="next"
              className="btn btn-primary"
              id={`tab-${activeTab}`}
              type="submit"
              disabled={loading}
              form={`tab-${activeTab}`}
            >
              <WurdText id="common.nextBtn" />
            </button>
          )}
      </div>
    </>
  );
}
