import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment-timezone';

import DatePicker from 'react-datepicker';

import store from '../../store';
import { currentLang } from 'utils/language';


export default function FormDatePicker({ name, value, onChange, ...rest }) {
  function handleChange(momentDate) {
    const value = momentDate && momentDate.format('YYYY-MM-DD');

    onChange({
      target: {
        name,
        value,
      },
    });
  }

  const { settings } = store.get();
  const momentDate = value && moment(value);

  return (
    <DatePicker
      name={name}
      className="form-control"
      dateFormat={settings.dateFormats.long}
      autoComplete="off"
      minDate={moment()}
      locale={currentLang}
      {...rest}
      selected={momentDate}
      onChange={handleChange}
    />
  )
}
