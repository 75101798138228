import { WurdText } from 'wurd-react';
import OccupancyProgressBar from './occupancy-progress-bar';
import LabelsListCell from 'plugins/labels/list';
import LangText from 'components/lang-text';
import StateLabel from 'components/state-label';

const styles = {
  image: {
    width: 50,
    height: 50,
  },

  title: {
    margin: 0,
    fontWeight: 600,
  },

  code: {
    color: '#bbb',
    fontStyle: 'italic',
    fontSize: '.8em',
  },

  subtitle: {
    color: '#999',
  },
};


export default function SiteListItem({ site }) {
  return (
    <>
      <div role="cell" style={{ width: 64 }}>
        <img src={site.image} style={{ ...styles.image, objectFit: site.imageFit || 'cover' }} alt="" />
      </div>

      <div role="cell">
        <div>
          <span style={styles.title}><LangText obj={site.title} /></span>
          &nbsp;
          <span style={styles.code}>{site.code}</span>
          {site.hidden && <StateLabel type="warning" as="small" style={{ marginLeft: '1rem' }}><WurdText id="site.hidden.true" /></StateLabel>}
        </div>
        <div style={styles.subtitle}><LangText obj={site.address} /></div>
      </div>

      <div role="cell" style={{ width: 200 }} className="hidden-xs">
        <OccupancyProgressBar site={site} style={{ marginBottom: 0 }} />
      </div>

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={site.labels} style={{ fontSize: '1rem' }} />
      </div>
    </>
  );
}
