import _ from 'lodash'
import moment from 'moment-timezone'

import * as areaHelpers from './area'
import * as itemHelpers from './item'
import * as userHelpers from './user'
import * as settings from './settings'

import { t } from './ui';

var visitStates = ['wait', 'picked', 'delivered', 'collected', 'stored', 'complete'];


/**
 * @return {String[]}
 */
export const getTimeslotsForDate = function (user, date) {
  var market = userHelpers.getMarket(user);

  //Get timeslots for selected date
  if (!date) return [];

  var dayName = moment(date).format('dddd').toLowerCase();

  var timeslots = market.timeslots[dayName];

  return timeslots;
}


/**
 * Formats a timeslot for display
 *
 * @param {String} timeslot       E.g. '1100-1300'
 * 
 * @return {String}               E.g. '11am to 1pm'
 */
export const formatTimeslot = function (timeslot) {
  if (!timeslot) return null;

  var timeFormat = settings.get().dateFormats.time;

  var [start, end] = timeslot.split('-');

  function formatTime(timeStr) {
    var hours = timeStr.substr(0, 2);
    var mins = timeStr.substr(2, 4);

    return moment.utc().startOf('day')
      .add(hours, 'hours')
      .add(mins, 'minutes')
      .format(timeFormat);
  }

  return `${formatTime(start)}-${formatTime(end)}`;
}


/**
 * Counts the all the empty boxes to be delivered in an order
 *
 * @param {Object} visit
 */
export const countEmptyBoxes = function (visit) {
  return itemHelpers.listEmpty().reduce((total, itemConfig) => {
    var itemCount = visit.orderedItems[itemConfig.type] || 0;

    return total + itemCount;
  }, 0);
};


/**
 * Counts the number of empty boxes left to add to an order
 *
 * @param {Object} visit
 * @param {String} itemType
 * 
 * @return {Number}
 */
export const countEmptyBoxesLeftToAdd = function (visit, itemType) {
  var numOrderedBoxes = visit.orderedItems[itemType] || 0;

  var addedBoxes = _.filter(visit.deliverBoxes, { type: itemType });

  return numOrderedBoxes - addedBoxes.length;
};


/**
 * Checks if the correct number of empty boxes has been added to an order
 *
 * @param {Object} visit
 * 
 * @return {Boolean}
 */
export const hasAddedAllEmptyBoxes = function (visit) {
  //Get empty box types that have been ordered
  var itemTypes = _.reduce(visit.orderedItems, (memo, quantity, itemType) => {
    if (quantity && !itemHelpers.isBulky(itemType)) memo.push(itemType);

    return memo;
  }, []);

  return !_.find(itemTypes, function (itemType) {
    return countEmptyBoxesLeftToAdd(visit, itemType);
  });
};


/**
 * Counts the number of products left to add to an order
 *
 * @param {Object} visit
 * @param {String} productId
 * 
 * @return {Number}
 */
export const countProductsLeftToAdd = function (visit, productId) {
  var order = visit.order || {};

  var orderedById = _.reduce(order.storeItems, (memo, product) => {
    if (product.quantity > 0) {
      memo[product.id] = product.quantity;
    }

    return memo;
  }, {});

  var addedById = _.countBy(visit.storeItems, 'type');

  var numOrdered = orderedById[productId] || 0;
  var numAdded = addedById[productId] || 0;

  return numOrdered - numAdded;
};


/**
 * Checks if the correct number of product has been added to an order
 *
 * @param {Object} visit
 * 
 * @return {Boolean}
 */
export const hasAddedAllProducts = function (visit) {
  //TODO: Visit should always have an order, with storeItems
  var order = visit.order || {};
  var storeItems = order.storeItems || [];

  var productIds = storeItems.map(product => product.id);

  return !_.find(productIds, function (productId) {
    return countProductsLeftToAdd(visit, productId);
  });
}


/**
 * Checks if all items collected on a visit have had locations applied
 */
export const hasSetAllLocations = function (visit) {
  var foundItemToBeUpdated = _.find(visit.collectItems, function (item) {
    if (item.state === 'transit-storage' || item.state === 'transit-user') {
      return true;
    }

    if (item.state === 'storage') {
      return !_.isString(item.location) || !item.location.length;
    }
  });

  return foundItemToBeUpdated ? false : true;
}


export const isStepComplete = function (visit, step) {
  var currentIndex = visitStates.indexOf(visit.state),
    stepIndex = visitStates.indexOf(step);

  return stepIndex < currentIndex;
}


export const isActiveStep = function (visit, step) {
  var currentState = visit.state;

  //If no items to deliver allow skipping to collect
  if (!isDelivery(visit)) {
    if (currentState === 'wait' && step === 'delivered') {
      return true;
    }
  }

  var currentIndex = visitStates.indexOf(currentState),
    stepIndex = visitStates.indexOf(step);

  return stepIndex === currentIndex;
}

export const isFutureStep = function (visit, step) {
  return (!isStepComplete(visit, step) && !isActiveStep(visit, step));
}


export const getAddressMapURL = function (visit) {
  var address = visit.address;
  if (!address) return null;

  //Replace enters with commas to fit on one line for map link
  address = address.replace(/\n/g, ",");

  return `https://maps.google.com/maps?daddr=${address}&amp;ll=`;
};


/**
 * Returns if the visit is of a given type (e.g. delivery, collection)
 *
 * @param {Object} visit
 * @param {String} type
 *
 * @return {Boolean}
 */
export const isType = function (visit, type) {
  switch (type) {
    case 'delivery':
      return isDelivery(visit);

    case 'collection':
      return isCollection(visit);

    case 'emptyBoxes':
      return hasOrderedItems(visit);

    default:
      return false;
  }
};


export const hasOrderedItems = function (visit) {
  return !!_.find(visit.orderedItems, (numOrdered, type) => {
    return type.substr(0, 5) !== 'bulky' && numOrdered > 0;
  });
};


/**
 * Returns true if the customer has ordered products
 *
 * @param {Visit} visit
 * @return {Boolean}
 */
export const orderRequestsProducts = function (visit) {
  let order = visit.order || {};
  let storeItems = order.storeItems || [];

  return !!storeItems.length;
};


export const isDelivery = function (visit) {
  if (visit.deliverItems.length) return true;

  if (hasOrderedItems(visit)) return true;

  if (needsToHandleProducts(visit)) return true;

  /*return !!(
    visit.deliverItems.length 
    || hasOrderedItems(visit)
  );*/
};

export const isCollection = function (visit) {
  return !!(visit.collectItems.length || visit.numBulkyItems);
};


/**
 * Returns if the visit is within the given date range
 *
 * @param {Object} visit
 * @param {Date} start
 * @param {Date} end
 */
export const isWithin = function (visit, start, end) {
  var startStr = moment(start).format('YYYY-MM-DD');
  var endStr = moment(end).format('YYYY-MM-DD');

  return visit.date >= startStr && visit.date <= endStr;
};


/**
 * Checks if the pick step should be shown for a visit
 * 
 * @param {Visit} visit
 * @return {Boolean}
 */
export const needsPickStep = function (visit) {
  //Items to deliver need picking
  if (visit.deliverItems.length) return true;

  //Empty boxes need picking
  if (hasOrderedItems(visit)) return true;

  //Products need picking if this is the only visit
  /*if (orderRequestsProducts(visit)) {
    let {relatedVisit} = visit;

    if (!relatedVisit) return true;

    if (relatedVisit.type === 'emptyBoxCollection') return true;
  }*/

  if (needsToHandleProducts(visit)) return true;

  //If we got this far, no need for pick step
  return false;
};


/**
 * Checks if the deliver step should be shown for a visit
 * 
 * @param {Visit} visit
 * @return {Boolean}
 */
export const needsDeliverStep = function (visit) {
  //Are there items to deliver
  if (visit.deliverItems.length) return true;

  //Are there unassigned/empty items to deliver
  if (hasOrderedItems(visit)) return true;

  //Are there products to deliver
  if (needsToHandleProducts(visit)) return true;

  //If we got this far, no need for pick step
  return false;
};


export const needsToHandleProducts = function (visit) {
  if (!orderRequestsProducts(visit)) return false;

  if (!visit.relatedVisit) return true;

  if (visit.relatedVisit.type === 'emptyBoxCollection') return true;

  return false;
};


/**
 * Gets the FontAwesome icon className for the visit state
 *
 * @param {String} state
 * 
 * @return {String}
 */
export const getStateIcon = function (state) {
  var map = {
    wait: '',
    picked: 'hand-o-left text-warning',
    delivered: 'truck text-warning',
    collected: 'truck text-warning',
    stored: 'check text-success',
    complete: 'check text-success',
    cancelled: 'ban text-danger',
    'failed-delivery': 'exclamation text-danger',
    'failed-collection': 'exclamation text-danger'
  }

  return 'fa fa-fw fa-' + map[state];
};


function formatItems(items) {
  var summaries = items.map(item => {
    var str = item.sid ? item.sid.toUpperCase() : '*';

    if (item.location) str += ` (${item.location})`;

    return str;
  });

  return summaries.join('\n');
}

/**
 * Formats a visit for CSV export
 */
export const toCsv = function (visit) {
  return {
    'Visit ID': visit.sid && visit.sid.toUpperCase(),
    'Date': visit.date,
    'Time': visit.timeslot,
    'Customer ID': visit.owner.id,
    'Customer name': visit.owner.name,
    'Address': visit.address,
    'Area': areaHelpers.getTitle(visit.area),
    'Instructions': visit.instructions,
    'Phone': visit.phone,
    'Alternate phone': visit.altPhone,
    'Empty boxes ordered': _.reduce(visit.orderedItems, (memo, num) => memo + num, 0),
    'Empty boxes picked': formatItems(visit.deliverBoxes),
    'Items to collect': formatItems(visit.collectItems),
    'Items to deliver': formatItems(visit.deliverItems),
    'Assigned to': visit.assignee && visit.assignee.name,
    'State': t('visit:_states.' + visit.state),
    'Created': moment(visit.created).format('YYYY-MM-DD'),
    'Updated': moment(visit.updated).format('YYYY-MM-DD')
  };
};
