export default ({ item, itemConfig, children }) => (
  <li className="list-group-item clearfix" style={{ fontSize: '1.3em' }}>
    {/*<div className="text-muted pull-right">
      {itemConfig.title}
    </div>*/}
    <div className="text-muted pull-right">
      {children}
    </div>

    <div>
      <img className="pull-left visits__itemtype-image" src={itemConfig.image} alt="Item" />
      {item.sid.toUpperCase()}
    </div>
    <div className="text-muted" style={{ fontSize: '.8em' }}>{itemConfig.title}</div>
  </li>
);
