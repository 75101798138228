import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from '@emotion/styled';

import ListGroupItem from './list-group-item';

const MyPopover = styled(Popover)({
  maxWidth: 'none',
  width: 200,

  '.popover-content': {
    padding: 0,
  },
});


export default function PopupMenu({
  button,
  items = [],
  placement = 'bottom',
}) {
  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      overlay={(
        <MyPopover id="popover">
          {items.map((props, index) => (
            <ListGroupItem
              key={index}
              {...props}
              style={{ border: 0 }}
            />
          ))}
        </MyPopover>
      )}
      rootClose
    >
      <span style={{ cursor: 'pointer' }}>{button}</span>
    </OverlayTrigger>
  );
}
