/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import classnames from 'classnames';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import { WurdObject, WurdText } from 'wurd-react';
import { Modal } from 'react-bootstrap';
import Item from './item';
import ItemForm from '../../form/item';

const { hasRole } = helpers.auth;
const { k, t } = helpers.cms.getters('visitView.store');


const StoreStep = createClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired
  },

  getInitialState() {
    return {
      modalContent: null
    };
  },

  render() {
    var { visit } = this.props;

    if (!helpers.visit.isCollection(visit)) return null;

    var step = 'collected';

    return (
      <section className="panel">
        <div
          className={classnames('panel-heading', {
            success: helpers.visit.isStepComplete(visit, step)
          })}
        >
          <h4 className="panel-title">
            <a className={classnames({ collapsed: helpers.visit.isFutureStep(visit, step) })}>
              <WurdObject id={k()} keys="title,setLocationBtn,setLocationBtn_storage,setLocationBtn_unassigned,setLocationBtn_user,nextBtn">
                {t('title')}
              </WurdObject>
            </a>
          </h4>
        </div>
        {helpers.visit.isActiveStep(visit, step) && hasRole('operations') && this.renderMain()}
      </section>
    );
  },

  renderMain() {
    var { props, state } = this;
    var { visit } = props;

    var hasSetAllLocations = helpers.visit.hasSetAllLocations(visit);

    var itemConfigs = helpers.item.byType(true);

    return (
      <div className="panel-body">
        <ul className="list-group">
          {visit.collectItems.map(item => {
            var itemConfig = itemConfigs[item.type] || {};

            return (
              <Item key={item.id} item={item} itemConfig={itemConfig}>
                <button
                  type="button"
                  className={classnames("btn pull-right", {
                    'btn-default': item.state === 'transit-storage' || item.state === 'transit-user',
                    'btn-success': item.state === 'storage',
                    'btn-warning': item.state === 'unassigned',
                    'btn-danger': item.state === 'user'
                  })}
                  onClick={this.setItemLocation.bind(null, item)}
                //disabled="item._syncing"
                >
                  {(item.state === 'transit-storage' || item.state === 'transit-user') &&
                    <span>
                      {t('setLocationBtn')}
                    </span>
                  }
                  {(item.state !== 'transit-storage' && item.state !== 'transit-user') &&
                    <span>
                      {t(`setLocationBtn_${item.state}`, item)}
                    </span>
                  }
                </button>
              </Item>
            );
          })}
        </ul>

        <button
          type="button"
          className={classnames('btn btn-block', {
            'btn-warning': !hasSetAllLocations,
            'btn-success': hasSetAllLocations
          })}
          disabled={!hasSetAllLocations}
          onClick={this.completeStep}
        >
          <i className="fa fa-check" />
          {t('nextBtn')}
        </button>

        <Modal show={!!state.modalContent} onHide={this.closeModal}>
          {state.modalContent}
        </Modal>
      </div>
    );
  },

  /*isActive() {
    return helpers.visit.isActiveStep(this.props.visit, 'collected');
  },

  isComplete() {
    return helpers.visit.isStepComplete(this.props.visit, 'collected');
  },*/

  setItemLocation(item) {
    var defaultValue = {
      state: item.owner ? 'storage' : 'unassigned',
      location: item.location
    }

    /*var onSubmit = function(data) {
      //Require location if item is in storage
      if (data.state === 'storage' && !data.location) {
        return new Error('Location is required')
      }

      return actions.items.update(item.id, data);
    }*/

    var content = (
      <ItemForm
        defaultValue={defaultValue}
        onSubmit={actions.items.update.bind(null, item.id)}
        onSuccess={this.onSetItemLocation}
        title={`Store item ${item.sid.toUpperCase()}`}
        submitButton={<WurdText id="common.saveBtn" />}
        modal
        onHideModal={this.closeModal}
        fields={['state', 'location', 'opsTitle']}
        requiredFields={['state']}
        stateOptions={[
          { value: 'user', text: 'Not collected' },
          { value: 'storage', text: 'Stored' },
          { value: 'unassigned', text: 'Empty' },
        ]}
      />
    );

    this.setState({
      modalContent: content
    });
  },

  onSetItemLocation() {
    this.closeModal();
    this.props.onChange();
  },

  closeModal() {
    this.setState({ modalContent: null });
  },

  completeStep() {
    var { visit, onChange } = this.props;

    actions.visits.update(visit.id, { state: 'stored' })
      .then(onChange);
  }

});


export default StoreStep;
