const styles = {
  wrapper: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 16,
    cursor: 'pointer',
  }
}

export default function SitemapSidePanelMarker({ item, onClick }) {
    
  return (
    <li style={styles.wrapper} onClick={onClick}>
      {item.name}
    </li>
  );

}
