import wurd, { WurdText } from 'wurd-react';

import * as helpers from 'helpers';

import SiteLoader from '../loaders/site';
import LangText from '../lang-text';
import StateLabel from '../state-label';
import LabelsListCell from 'plugins/labels/list';
import { useItemLoader } from 'hooks';

const cms = wurd.block('siteView.unitTypes');


const styles = {
  image: {
    width: 50,
    height: 50,
  },

  title: {
    margin: 0,
    fontWeight: 600,
  },

  code: {
    color: '#999',
    fontStyle: 'italic',
    fontSize: '.8em',
  },

  subtitle: {
    color: '#999',
  },
};


export default function UnitTypeListItem({
  unitType,
  site = unitType.site,
  hideSite,
  listQuery
}) {
  return (
    <>
      <div role="cell" style={{ width: 64 }}>
        <img src={unitType.image} style={{ ...styles.image, objectFit: unitType.imageFit || 'cover' }} alt="" />
      </div>

      <div role="cell">
        <div>
          <span style={styles.title}>
            <LangText obj={unitType.title} />
          </span>
          &nbsp;
          <span style={styles.code}>{unitType.code}</span>
          {unitType.hidden && <StateLabel type="warning" as="small" style={{ marginLeft: '1rem' }}><WurdText id="unitType.hidden.true" /></StateLabel>}
        </div>
        <div style={styles.subtitle}><LangText obj={unitType.subtitle} /></div>
      </div>

      {!hideSite && !listQuery.params.siteId && (
        <div role="cell">
          <SiteLoader id={unitType.siteId}>
            {site => <LangText obj={site.title} />}
          </SiteLoader>
        </div>
        )}

      <div role="cell" className="text-right">
        {helpers.ui.priceChange(helpers.unitType.currentPrice(unitType), unitType.price, helpers.ui.rentPeriod())}
      </div>

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={unitType.labels} style={{ fontSize: '1rem' }} />
      </div>
    </>
  );
}
