import _ from 'lodash';
import wurd from 'wurd-react';
import * as language from '../utils/language';
import * as settingsHelpers from './settings';
import { getLangText } from './ui';
import { getLangKeys } from './csv';
import processCustomFields from '../plugins/custom-fields/actions';


export function doTranslate(itemConfig, lang) {
  const obj = { ...itemConfig };

  for (const prop of ['title', 'subtitle', 'address', 'info']) {
    obj[prop] = getLangText(obj[prop], lang);
  }

  return obj;
}


/**
 * Returns the list of site types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const list = function (translate) {
  const siteConfigs = settingsHelpers.get().sites;

  if (translate) {
    const lang = language.get();

    return siteConfigs.map(siteConfig => doTranslate(siteConfig, lang));
  } else {
    return siteConfigs;
  }
};


/**
 * Returns an object of item types, keyed by type
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object}
 */
export const byId = function (translate) {
  return _.keyBy(list(translate), 'id');
};


/**
 * Returns titles for item types, keyed by type and in the user's language
 *
 * @return {Object}
 */
export const titlesById = function () {
  const lang = language.get();

  return list().reduce((memo, site) => {
    memo[site.id] = getLangText(site.title, lang);
    return memo;
  }, {});
};


/**
 * Returns a specific site type
 *
 * @param {String} siteId
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const get = function (siteId, translate) {
  const sites = settingsHelpers.get()?.sites || [];

  const site = sites.find(({ id }) => id === siteId);
  if (!site) throw new Error(`Site ${siteId} not found`);

  if (translate) {
    return doTranslate(site, language.get());
  } else {
    return site;
  }
};


/**
 * Gets the title of a site given it's ID
 *
 * @param {String} id
 */
export const getTitle = function (id) {
  const site = get(id, true) || {};

  return site.title;
};


export const unitTypes_compare = (a, b) =>
  ((a.order || 0) - (b.order || 0)) ||
  ((a.price_display || a.price) - (b.price_display || b.price)) ||
  ((a.deposit_display || a.deposit) - (b.deposit_display || b.deposit));


export const unitTypes_list = function (siteId, translate) {
  const site = get(siteId);

  site.unitTypes.sort(unitTypes_compare);

  if (translate) {
    return site.unitTypes.map(unitType => doTranslate(unitType, language.get()));
  } else {
    return site.unitTypes;
  }
};


export const unitTypes_get = function (siteId, unitTypeId, translate) {
  const site = get(siteId);

  const unitType = site.unitTypes.find(({ id }) => id === unitTypeId);
  if (!unitType) throw new Error(`Unit type ${unitTypeId} not found on site ${siteId}`);

  if (translate) {
    return doTranslate(unitType, language.get());
  } else {
    return unitType;
  }
};

export function products_get(siteId, productId, translate) {
  const site = get(siteId);

  const product = site.products.find(({ id }) => id === productId);
  if (!product) throw new Error(`Product ${productId} not found on site ${siteId}`);

  if (translate) {
    return doTranslate(product, language.get());
  } else {
    return product;
  }
}


/**
 * Returns the overall site availability by combining availability of all the site's unit types
 *
 * @param {Object} site
 *
 * @returns {Object}
 */
export const getOccupancy = function (site) {
  const data = site.occupancy || {};

  const total = Object.entries(data)
    .filter(([state]) => state !== 'overdue')
    .reduce((memo, [state, numUnits]) => memo + numUnits, 0);

  return {
    ...data,
    total,
    occupancyRate: total && Math.round(((data.occupied || 0) + (data.reserved || 0)) / total * 100),
  };
};


export const getCode = function (siteId) {
  try {
    const site = get(siteId);

    return site.code;
  } catch (err) {
    return wurd.text('common.phrases.deleted') || '[deleted]';
  }
};

/**
 * @param {Object} site
 * @return {String} csv
 */
export function toCsv(site) {
  const settings = settingsHelpers.get();

  return {
    id: site.id,
    code: site.code,
    ...getLangKeys({
      title: site.title,
      subtitle: site.subtitle,
    }),
    image: site.image,
    phone: site.phone,
    email: site.email,
    ...getLangKeys({
      address: site.address,
    }),
    // Info_en: site.info.en,
    // Hours_en: site.hours.en,
    lat: site.lat,
    lng: site.lng,
    enableMoveInAgreement: site.enableMoveInAgreement,
    moveIn_autoConfirmOrder: site.moveIn_autoConfirmOrder,
    moveIn_autoChargeInvoice: site.moveIn_autoChargeInvoice,
    moveIn_autoCompleteOrder: site.moveIn_autoCompleteOrder,
    hidden: site.hidden,
    measure: site.measure,
    area_gross: site.area_gross,
    area_net: site.area_net,
    labels: site.labels?.join(','),
    ...Object.fromEntries(
      settings.siteCustomFields?.sort((a,b) => a.code.localeCompare(b.code)).map(({ code, title }) => {
        const value = site.customFields?.[code];
        return [`customFields.${code}`, value?.url || value];
      })
    ),
    created: site.created,
    updated: site.updated,
  };
}

export async function fromCsv(site) {
  if (site.customFields) {
    site.customFields = await processCustomFields(site.customFields, 'sites');
  }
  return {
    ..._.omit(site, ['id', 'created', 'updated']),
    labels: (site.labels || undefined)?.split(','),
  };
}