import { useParams } from 'react-router-dom';
import { useModal, useItemLoader } from 'hooks';

import NotFound from 'components/not-found';
import Spinner from 'components/spinner';
import UnitTypeDetailInnerPage from 'components/unit-type-detail/page';


export default function UnitTypeDetailPage() {
  const { id, section } = useParams();

  const modal = useModal();

  const { item: unitType, isLoading: isLoadingUnitType, refetch } = useItemLoader('unit-types', id, {
    include: ['customFields'],
    maxAge: 0,
  });

  const { item: site, isLoading: isLoadingSite } = useItemLoader('sites', unitType && unitType.siteId, {
    include: ['customFields'],
  });

  const isLoading = isLoadingUnitType || isLoadingSite;
  
  if (!unitType || !site) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  return (
    <UnitTypeDetailInnerPage
      unitType={unitType}
      site={site}
      refetch={refetch}
      modal={modal}
      section={section}
    />
  );
}
