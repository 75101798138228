import StepList2 from '../step-list2';
import * as stepComponents from './steps';


export default function UnitMoveInStepList({
  cms,
  job,
  site,
  unit,
  rental,
  summary, // Optional component to appear above the step list
  fallback = null, // Fallback content to show if no step is active (e.g. job is being set up)
  onChange,
  modal,
}) {
  return (
    <StepList2
      cms={cms}
      job={job}
      site={site}
      unit={unit}
      rental={rental}
      summary={summary}
      fallback={fallback}
      stepComponents={stepComponents}
      onChange={onChange}
      modal={modal}
    />
  );
}
