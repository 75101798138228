import Modal from 'components/modal';
import AddonConfigCard from './list-card-item';


export default function AddonConfigSelector({
  cms,
  modal,
  site,
  addonConfigs,
  onSelectItem,
}) {
  // Sort the addonConfigs by whether they are installed or not
  addonConfigs = addonConfigs.sort((a, b) => {
    if (a._addon && !b._addon) return 1;
    if (!a._addon && b._addon) return -1;
    return 0;
  });

  return (
    <Modal
      style={{ width: '80%' }}
      title="Select an addon"
      closeModal={modal.close}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2.5rem' }}>
        {addonConfigs.map(addonConfig => (
          <AddonConfigCard
            key={addonConfig.id}
            cms={cms}
            addonConfig={addonConfig}
            onClick={() => onSelectItem(addonConfig)}
          />
        ))}
      </div>
    </Modal>
  );
}