import { useState } from 'react';
import wurd from 'wurd-react';

import * as helpers from '../../../helpers';
import useSelector from '../hooks/selector';

import NoResults from '../../no-results';
import JobStep from '../../jobs/job-step2';
import Modal from '../../modal';
import BulkUpdateModalItem from '../../item-list/bulk-update/item';
import Tabs from '../shared/tabs';
import ResultsItemList from '../shared/results-item-list';
import Item2List from '../shared/item2-list';
import ScanReceiver from '../shared/scan-receiver';
import LargeButtonGroup from '../../large-button-group';
import SelectionButton from '../shared/selection-button';
import AddItemButton from '../shared/add-item-button';
import ScanItemsButton from '../shared/scan-items-button';
import CompleteStepButton from '../shared/complete-step-button';
import ItemDetailsForm from '../shared/item-details-form';

const cms = wurd.block('valetOrder.steps.items_store');


export default function Step_items_store(props) {
  const {
    valetOrder,
    step,
    items,
    updateItems,
    modal,
    fetchItems,
    fetchValetOrder,
  } = props;

  function itemHasBeenProcessed(item) {
    if (item.valetOrderId !== valetOrder.id) return false;

    if (item.state === 'transit-storage' || item.state === 'transit-user') {
      return false;
    }

    if (item.state === 'storage') {
      if (typeof item.location !== 'string' || !item.location.length) {
        return false;
      }
    }

    return true;
  }

  const hasProcessedAll = items.every(itemHasBeenProcessed);

  const allItems = items;
  const todoItems = allItems.filter((item) => !itemHasBeenProcessed(item));
  const uncollectedItems = allItems.filter((item) => item.state === 'user');
  const storedItems = allItems.filter((item) => item.state === 'storage');
  const emptyItems = allItems.filter((item) => item.state === 'unassigned');

  const selector = useSelector();

  const [updateErrs, setUpdateErrs] = useState(null);

  const [activeTab, setActiveTab] = useState('todo');
  const [lastAction, setLastAction] = useState();

  function onTabChange(tabId) {
    selector.clearSelection();

    setActiveTab(tabId);
  }

  async function addItems(itemSids) {
    await updateItems('valetOrder_add', itemSids);
  }

  async function storeItems(itemSids, itemData = {}) {
    itemData.location = itemData.location || helpers.ui.prompt('Enter the new location');
    if (itemData.location === '') return helpers.ui.alert('Location is required');
    if (!itemData.location) return null;

    const { errors, updatedSids } = await updateItems('valetOrder_store', itemSids, itemData);

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Stored ${updatedSids.length} items`,
        bsType: 'success',
      });
    }

    setLastAction('store');
    selector.clearSelection();
    modal.close();
  }

  async function uncollectItems(itemSids, itemData = {}) {
    const { errors, updatedSids } = await updateItems('valetOrder_uncollect', itemSids, itemData);

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Marked ${updatedSids.length} items as with customer`,
        bsType: 'default',
      });
    }

    setLastAction('uncollect');
    selector.clearSelection();
    modal.close();
  }

  async function unassignItems(itemSids, itemData = {}) {
    itemData.location = itemData.location || helpers.ui.prompt('Enter the new location') || null;

    const { errors, updatedSids } = await updateItems('valetOrder_unassign', itemSids, itemData);

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Unassigned ${updatedSids.length} items`,
        bsType: 'default',
      });
    }

    setLastAction('unassign');
    selector.clearSelection();
    modal.close();
  }

  async function receiveScannedSids(itemSids) {
    // First add any items that were not already part of the order
    const newSids = itemSids.filter((sid) => !items.find((item) => item.sid === sid));

    if (newSids.length) {
      await addItems(newSids);
    }

    selector.setSelectedSids(itemSids);
  }

  function onClickItem(item) {
    modal.open(
      <ItemDetailsForm
        itemId={item.id}
        initialValue={item}
        modal={modal}
        fields={['opsImage', 'opsTitle', 'location', 'state']}
        stateOptions={[
          [null, ''],
          ['storage', wurd.text('item._states.storage')],
          ['user', wurd.text('item._states.user')],
          !item.isBulky && ['unassigned', wurd.text('item._states.unassigned')],
        ].filter(Boolean)}
        onSubmit={async ({ state, ...itemData }) => {
          const fn = ({
            storage: storeItems,
            user: uncollectItems,
            unassigned: unassignItems,
          })[state];

          if (fn) fn([item.sid], itemData);
        }}
      />,
    );
  }

  function openUpdateModal() {
    const itemSids = selector.selectedSids;

    const cms = wurd.block('itemList.bulkActions.editState.modal');

    let done;
    const promise = new Promise((resolve) => {
      done = resolve;
    });

    modal.open(
      <Modal>
        <div className="list-group">
          <BulkUpdateModalItem
            cms={cms.block('unassign')}
            onClick={async () => {
              modal.close();
              await unassignItems(itemSids);
              done();
            }}
            faIcon="fas fa-user-times"
          />
          <BulkUpdateModalItem
            cms={cms.block('setWithUser')}
            onClick={async () => {
              modal.close();
              await uncollectItems(itemSids);
              done();
            }}
            faIcon="fas fa-home"
          />
          <BulkUpdateModalItem
            cms={cms.block('setInStorage')}
            onClick={async () => {
              modal.close();
              await storeItems(itemSids);
              done();
            }}
            faIcon="fas fa-building"
          />
        </div>
      </Modal>,
    );

    return promise;
  }

  function renderSelectionButtons() {
    return (
      <cms.Object keys="updateBtn,uncollectBtn,unassignBtn,storeBtn">
        <SelectionButton
          bsType="primary"
          faIcon="fas fa-tasks"
          text={cms.text('updateBtn')}
          onClick={openUpdateModal}
        />
      </cms.Object>
    );
  }

  function renderAddItemButton() {
    return (
      <AddItemButton
        valetOrder={valetOrder}
        modal={modal}
        fetchItems={fetchItems}
        newItemState="transit-storage"
      />
    );
  }

  function renderActions() {
    return (
      <JobStep
        step={step}
        faIcon="fas fa-box"
        title={<cms.Text id="requiresInput.title" vars={{ numItems: todoItems.length }} />}
        helpWurdId={cms.id('instructions.helpUrl')}
      >
        <ScanReceiver onScan={selector.setSelectedSids} />

        <Tabs
          onChange={onTabChange}
          items={[
            {
              id: 'todo',
              faIcon: 'fa-tasks',
              title: <cms.Text id="todoTab" />,
              badge: todoItems.length,
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={todoItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  todoState="transit-storage"
                  fallback={(
                    <>
                      {renderAddItemButton()}

                      <NoResults faIcon="fa-check-circle" faColor="success">
                        <cms.Markdown id="todo.noResults" />
                      </NoResults>
                    </>
                  )}
                  extra={selector.isSelecting ? renderSelectionButtons() : renderAddItemButton()}
                  onClickItem={onClickItem}
                />
              ),
            },
            {
              id: 'stored',
              faIcon: 'fa-box-check',
              title: <cms.Text id="storedTab" />,
              badge: storedItems.length,
              animateBadge: lastAction === 'store',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={storedItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  todoState="transit-storage"
                  fallback={(
                    <NoResults faIcon="fa-box-check">
                      <cms.Markdown id="stored.noResults" />
                    </NoResults>
                  )}
                  extra={selector.isSelecting && renderSelectionButtons()}
                  onClickItem={onClickItem}
                />
              ),
            },
            {
              id: 'uncollected',
              faIcon: 'fa-home',
              title: <cms.Text id="uncollectedTab" />,
              badge: uncollectedItems.length,
              animateBadge: lastAction === 'uncollect',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={uncollectedItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  todoState="transit-storage"
                  fallback={(
                    <NoResults faIcon="fa-home">
                      <cms.Markdown id="uncollected.noResults" />
                    </NoResults>
                  )}
                  extra={selector.isSelecting && renderSelectionButtons()}
                  onClickItem={onClickItem}
                />
              ),
            },
            {
              id: 'empty',
              faIcon: 'fa-box-open',
              title: <cms.Text id="emptyTab" />,
              badge: emptyItems.length,
              animateBadge: lastAction === 'unassign',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={emptyItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  todoState="transit-storage"
                  fallback={(
                    <NoResults faIcon="fa-box-open">
                      <cms.Markdown id="empty.noResults" />
                    </NoResults>
                  )}
                  extra={selector.isSelecting && renderSelectionButtons()}
                  onClickItem={onClickItem}
                />
              ),
            },
          ]}
        />

        <cms.Object keys="completeBtn,notFinished_confirm">
          <LargeButtonGroup style={{ marginTop: 20 }}>
            <ScanItemsButton
              modal={modal}
              receiveScannedSids={receiveScannedSids}
            />
            <CompleteStepButton
              valetOrder={valetOrder}
              step={step}
              cms={cms}
              hasProcessedAll={hasProcessedAll}
              onClick={fetchValetOrder}
            />
          </LargeButtonGroup>
        </cms.Object>
      </JobStep>
    );
  }

  function renderResults() {
    const storedItemIds = step.result.stored;
    const emptyItemIds = step.result.empty;
    const uncollectedItemIds = step.result.uncollected;
    const otherItemIds = step.result.other;

    if (!storedItemIds) return null;

    const numStoredItems = (storedItemIds && storedItemIds.length) || 0;

    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" vars={{ numStoredItems }} />}
      >
        {storedItemIds?.length > 0 && (
          <div>
            <h4>Stored items</h4>
            <ResultsItemList itemIds={storedItemIds} />
          </div>
        )}

        {emptyItemIds?.length > 0 && (
          <div>
            <h4>Empty items</h4>
            <ResultsItemList itemIds={emptyItemIds} />
          </div>
        )}

        {uncollectedItemIds?.length > 0 && (
          <div>
            <h4>Uncollected items</h4>
            <ResultsItemList itemIds={uncollectedItemIds} />
          </div>
        )}

        {otherItemIds?.length > 0 && (
          <div>
            <h4>Other items</h4>
            <ResultsItemList itemIds={otherItemIds} />
          </div>
        )}
      </JobStep>
    );
  }


  return (step.state === 'completed') ? renderResults() : renderActions();
}
