import { useItemLoader } from '../../hooks';
import { throttle_fetchOne } from '../../actions';

const fetch = throttle_fetchOne();

export default function SiteLoader({
  id,
  include,
  maxAge,
  loading = <i className="fas fa-spinner fa-spin" />,
  fallback = <i className="fas fa-question-circle" title="Not found" />,
  children: renderFn,
}) {
  const { item: site, isLoading, refetch } = useItemLoader('sites', id, {
    altIdKey: 'code',
    include,
    maxAge,
    fetch,
  });

  if (id && !site) {
    return isLoading ? loading : fallback;
  }

  return renderFn(site, { isLoading, refetch });
}
