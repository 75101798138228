import styled from '@emotion/styled';

import { iconColors } from '../helpers/ui';

const Wrapper = styled.div({
  '.checkbox': {
    background: 'none',
    border: 'none',
    color: '#ccc',
    fontSize: 22,
    width: 44,
    height: 44,
    margin: 0,
    marginLeft: -14,

    '&:focus': {
      outline: 0,
    },
  },

  '.checkbox-icon': {
    marginLeft: 6,
  },
});


export default function SelectItemCheckbox({
  isSelected = false,
  isHalfSelected = false,
  onClick,
}) {
  let className = isSelected ? 'fas fa-check-square' : 'far fa-square';

  if (isHalfSelected) className = 'fas fa-check-square';

  return (
    <Wrapper>
      <button
        type="button"
        className="checkbox"
        style={isSelected ? iconColors.primary : iconColors.default}
        onClick={onClick}
      >
        <div className="checkbox-icon"><i className={className} /></div>
      </button>
    </Wrapper>
  );
}
