import moment from 'moment-timezone';
import wurd from 'wurd-react';


import { states, stateBsTypes } from '../../helpers/invoice';
import { settings } from '../../helpers';

import ListFilter from '../filter/list';
import DateFilter from '../filter/date';
import UserFilter from '../filter/user';
import StateLabel from '../state-label';
import SiteFilter from '../filter/site';
import LabelsFilter from 'plugins/labels/filter';
import SearchFilter from '../filter/search';

const cms = wurd.block('invoiceList.filters');

export default function InvoiceListFilters({
  listQuery,
  hideSite,
  hideOwner,
}) {
  const { params } = listQuery;

  function onChange(key, value) {
    const newParams = {};

    switch (key) {
      case 'daterange':
        newParams.start = value ? moment(value.start).format('YYYY-MM-DD') : null;
        newParams.end = value ? moment(value.end).format('YYYY-MM-DD') : null;
        break;

      default:
        newParams[key] = value;
    }

    listQuery.update({
      offset: 0,
      ...newParams,
    });
  }

  const setSiteId = (val) => onChange('siteId', val);
  const setState = (val) => onChange('state', val);
  const setDateRange = (val) => onChange('daterange', val);
  const setOwner = (val) => onChange('ownerId', val);
  const setLabels = (val) => onChange('labels', val);

  const daterange = (params.start && params.end)
    ? { start: params.start, end: params.end }
    : null;

  return (
    <>
      {!hideSite && (
        <SiteFilter
          active={params.siteId}
          onSelect={setSiteId}
        />
      )}

      <cms.Object id="state" keys={['title', 'all'].concat(states)}>
        <ListFilter
          title={cms.text('state.title')}
          active={params.state}
          onSelect={setState}
          multi
        >
          {states.map((state) => (
            <li key={state} value={state}>
              <StateLabel type={stateBsTypes[state]}>{cms.text(`state.${state}`)}</StateLabel>
            </li>
          ))}
        </ListFilter>
      </cms.Object>

      <cms.Object id="daterange" keys="title">
        <DateFilter
          title={cms.text('daterange.title')}
          range={daterange}
          onSelect={setDateRange}
        />
      </cms.Object>

      {!hideOwner && (
        <UserFilter
          active={params.ownerId}
          onSelect={setOwner}
        />
      )}

      <LabelsFilter
        active={params.labels}
        onSelect={setLabels}
        labels={settings.get().invoiceLabels}
      />

      <SearchFilter
        name="invoice-search"
        cms={wurd.block('invoiceList.lookup')}
        active={params.search}
        onSelect={(val) => onChange('search', val)}
        modelName="invoice"
      />
    </>
  );
}
