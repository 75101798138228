import { Modal } from 'react-bootstrap';
import wurd from 'wurd-react';

import { useModal } from 'hooks';
import * as actions from 'actions';

import EmailTemplateForm from './email-template-form';
import EmailEditor from './email-editor';
import Spinner from 'components/spinner';


const cms = wurd.block('settings.invoiceReminders');


function InvoiceReminderForm({ defaultTemplate, ...props }) {
  return (
    <EmailTemplateForm wurdSection="settings.invoiceReminders" {...props}>
      {({ getProps, ...rest }) => (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <cms.Text type="label" htmlFor="invoice-reminder-days" id="daysSinceSent.label" className="mb-0" />
            <input
              {...getProps('daysSinceSent')}
              id="invoice-reminder-days"
              className="form-control"
              required type="number" step="1"
              style={{ width: 60, display: 'inline-block', marginLeft: 6 }}
            />
          </div>
          <EmailEditor
            defaultTemplate={defaultTemplate}
            getProps={getProps}
            {...rest}
          />
        </div>
      )}
    </EmailTemplateForm>
  )
}


export default function InvoiceReminders({ settings, emailSettings }) {
  const modal = useModal();
  const { invoiceReminders } = settings;

  if (!invoiceReminders) return <Spinner />;

  async function updateInvoiceReminder({ daysSinceSent, subject, text }, index) {
    const templateName = `general_user_invoiceReminder${index + 1}`;

    const langKeys = Object.keys(subject);
    const changedKeys = langKeys.filter(lang => {
      const emailTemplate = emailSettings.templates[templateName]?.[lang] || { subject: '', text: '' };
      return emailTemplate.subject !== subject[lang] || emailTemplate.text !== text[lang];
    });

    // validate
    const errs = await Promise.all(
      changedKeys.map(lang => actions.emails.previewChanges(templateName, lang, { subject: subject[lang], text: text[lang] }, true))
    );

    if (errs.some(Boolean)) {
      const msg = changedKeys.map((lang, i) => errs[i] ? `${lang}: ${errs[i]}` : '').join('\n\n');
      throw new Error(`Validation error: ${msg}`);
    }

    const newInvoiceReminders = [...invoiceReminders];
    newInvoiceReminders[index] = { daysSinceSent };

    const newTemplates = {
      [templateName]: Object.fromEntries(langKeys.map(lang => [lang, { subject: subject[lang], text: text[lang] }]))
    };

    await actions.business.update({
      emailTemplates: newTemplates,
      invoiceReminders: newInvoiceReminders,
    });
  }

  async function deleteLastInvoiceReminder() {
    await actions.business.update({
      invoiceReminders: invoiceReminders.slice(0, -1),
    });
    modal.close();
  }

  const langKeys = settings.languages || ['en'];

  return (
    <>
      {invoiceReminders.length === 0 && (
        <cms.Markdown id="invoiceReminders.empty" />
      )}
      <ul className="list-unstyled">
        {invoiceReminders.map(({ daysSinceSent }, i) => {
          const templateKey = `general_user_invoiceReminder${i + 1}`;
          const emailTemplate = emailSettings?.templates?.[templateKey];

          return (
            <li key={i}>
              <button
                className="btn-link text-left"
                style={{ outline: 'none' }}
                onClick={(e) => {
                  modal.open(
                    <InvoiceReminderForm
                      mode="update"
                      initialValue={{
                        templateKey,
                        daysSinceSent,
                        subject: Object.fromEntries(langKeys.map(k => [k, emailTemplate?.[k]?.subject || ''])),
                        text: Object.fromEntries(langKeys.map(k => [k, emailTemplate?.[k]?.text || ''])),
                      }}
                      onSubmit={(data) => updateInvoiceReminder(data, i)}
                      onSuccess={modal.close}
                      closeModal={modal.close}
                      onDelete={i === invoiceReminders.length - 1 && deleteLastInvoiceReminder}
                      locals={emailSettings?.variables[templateKey]}
                      defaultTemplate={emailSettings?.defaultTemplates[`general_user_invoiceReminder1`]}
                    />
                  );
                }}
              >
                <i className="far fa-check-square" />
                <cms.Text id="daysSinceSent.title" vars={{ daysSinceSent }} style={{ marginLeft: 8 }} />
              </button>
            </li>
          );
        })}
        {invoiceReminders.length < 6 && (
          <li>
            <button
              className="btn-link text-left"
              style={{ outline: 'none' }}
              type="button"
              onClick={() => {
                const templateKey = `general_user_invoiceReminder${invoiceReminders.length + 1}`;
                const emailTemplate = emailSettings?.templates[templateKey] || emailSettings?.defaultTemplates['general_user_invoiceReminder1'];

                modal.open(
                  <InvoiceReminderForm
                    mode="create"
                    initialValue={{
                      templateKey,
                      daysSinceSent: [5, 15, 30][invoiceReminders.length],
                      subject: Object.fromEntries(langKeys.map(k => [k, emailTemplate?.[k]?.subject || ''])),
                      text: Object.fromEntries(langKeys.map(k => [k, emailTemplate?.[k]?.text || ''])),
                    }}
                    onSubmit={(data) => updateInvoiceReminder(data, invoiceReminders.length)}
                    onSuccess={modal.close}
                    closeModal={modal.close}
                    locals={emailSettings?.variables['general_user_invoiceReminder1']}
                    defaultTemplate={emailSettings?.defaultTemplates[`general_user_invoiceReminder1`]}
                  />
                );
              }}
            >
              <i className="fas fa-plus" />
              <cms.Text id="daysSinceSent.add" style={{ marginLeft: 8 }} />
            </button>
          </li>
        )}
      </ul>

      <cms.Markdown id="invoiceReminders.help" />

      <Modal bsSize="lg" show={!!modal.content} keyboard onHide={modal.close} backdrop="static">
        {modal.content}
      </Modal>
    </>
  );
}
