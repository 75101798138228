import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import * as helpers from '../../helpers';
import userActions from '../../actions/users';

import mimeToIcon from '../../utils/mimeToIcon';
import LangText from 'components/lang-text';
import Alert from 'components/alert';

const cms = wurd.block('userView.files');

export default class FilesSection extends PureComponent {

  static propTypes = {
    user: PropTypes.shape({
      files: PropTypes.array.isRequired
    }).isRequired
  }

  render() {
    const { user: { files = [] } } = this.props;

    return (
      <section className="block-flat">
        <div className="header">
          <cms.Text type="h3" id="title" />
        </div>
        <Alert type='warning'>
          <cms.Markdown id="deprecated.message" />
        </Alert>
        <div className="content clearfix">
          {this.renderItems(files)}
        </div>
      </section>
    );
  }

  renderItems(items) {
    if (!items.length) return this.renderNoResults();

    return (
      <table className="table no-border table-hover">
        <thead className="no-border"></thead>
        <tbody className="no-border-x no-border-y">
          {items.map(file =>
            <tr key={file.id}>
              <td title={file.type} style={{ width: 30 }}>
                <i className={`fa ${mimeToIcon(file.type)}`} />
              </td>

              <td title="Title">
                <strong><LangText obj={file.title} /></strong>
              </td>

              <td title="Created on">
                {helpers.ui.date(file.created)}
              </td>

              <td title="View" style={{ width: 50 }}>
                <a
                  className="btn btn-link pull-right"
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-external-link-alt"></i>
                </a>
              </td>

              <td title="Remove" style={{ width: 50 }}>
                <button
                  className="btn btn-link pull-right"
                  onClick={() => this.removeFile(file.id)}
                >
                  <i className="fa fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  renderNoResults() {
    return <div className="text-center" style={{ padding: 40 }}><cms.Text id="noResults" /></div>
  }

  removeFile(fileId) {
    if (!window.confirm(wurd.text('common.confirmDelete'))) return;

    const { user } = this.props;
    userActions.files.delete(user.id, fileId)
      .then(() => {
        this.props.onChange();
      })
      .catch(err => {
        this.props.onChange();
        window.alert(err);
      });
  }

}
