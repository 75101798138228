import wurd from 'wurd-react';

import { useHistoryListQuery } from '../hooks';

import Page from '../components/page';
import MasterJobList from '../components/master-job-list';

const cms = wurd.block('jobList');

const initialParams = {
  state: 'active',
};


export default function JobListPage() {
  const listQuery = useHistoryListQuery({ initialParams });

  return (
    <>
      <Page
        titleKey={cms.id('title')}
        userRole="__superadmin"
      >
        <MasterJobList
          listQuery={listQuery}
        />
      </Page>
    </>
  );
}
