import { useEffect } from 'react';
import wurd from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';

import Section from 'components/section';

import UnitsTask from './tasks/units';
import SitemapTask from './tasks/sitemap';
import ProductsTask from './tasks/products';
import VisibilityTask from './tasks/visibility';


const cms = wurd.block('siteOnboarding.tasks');


export default function SiteOnboarding({ site, fetchSite, modal }) {
  useEffect(() => {
    wurd.load(['siteOnboarding']);
  }, []);
  
  const nUnits = helpers.site.getOccupancy(site).total;

  function skipSetup() {
    if (!window.confirm(cms.text('skip.confirm'))) return;

    return actions.sites.update(site.id, { area_gross: 1, area_net: 1 });
  }


  return (
    <Section
      link={null}
      cms={cms}
      extra={nUnits > 0 && (
        <cms.Object id="skip" keys="btn,confirm" type="button" className="btn btn-link" onClick={skipSetup}>
          {cms.text('skip.btn')} <i className="fal fa-times" />
        </cms.Object>
      )}
    >
      <UnitsTask
        site={site}
        fetchSite={fetchSite}
        modal={modal}
      />
      <hr style={{ margin: '1em 0' }} />
      <SitemapTask
        site={site}
      />
      <hr style={{ margin: '1em 0' }} />
      <ProductsTask
        site={site}
        fetchSite={fetchSite}
        modal={modal}
      />
      <hr style={{ margin: '1em 0' }} />
      <VisibilityTask
        site={site}
      />
    </Section>
  );
}