import wurd from 'wurd-react';
import { useQuery } from 'react-query';
import Select from 'react-select';


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: 'auto',
    float: 'left',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width || 'min(420px, 280%)',
  }),
};

export default function FontAwesomeIconInput({ name, value, onChange, variant = "fas" }) {
  const { data: solidIcons = [], isLoading } = useQuery('fa-solid-icons', () => import('@fortawesome/fontawesome-pro/metadata/icons.json').then(m => Object.keys(m.default).filter(k => m.default[k].styles.includes('solid') && k !== 'font-awesome-logo-full')));

  const v = value?.replace(/^fa.? fa-/, '');

  return (
    <Select
      name="fa-icon"
      value={{ value: v, label: <i className={`fa-fw fa-2x ${variant} fa-${v}`} title={v} /> }}
      placeholder={wurd.text('common.phrases.search') || 'Search icons'}
      onChange={o => onChange({ target: { name, value: `${variant} fa-${o.value}` } })}
      options={solidIcons.map(icon => ({ value: icon, label: <i className={`fa-fw fa-2x ${variant} fa-${icon}`} title={icon} /> }))}
      styles={customStyles}
    />
  );
}
