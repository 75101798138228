import { WurdText } from 'wurd-react';
import store from 'store';
import { getLangText } from 'helpers/ui';
import LangText from 'components/lang-text';
import { Field, MultiLangText, FormObject, Toggle, ModalForm2 } from 'components/form2';
import Logo from 'components/logo';
import UrlPicker from '../url-picker';


export default ({ block, setBlock, modal }) => {
  const { settings } = store.get();

  function editItem(i) {
    modal.open(
      <ModalForm2
        wurdSection="website.page.block_item"
        mode={i !== undefined ? 'update' : 'create'}
        initialValue={block.links?.[i]}
        onSubmit={i !== undefined
          ? async data => setBlock({ links: block.links.map((b, j) => i === j ? { ...b, ...data } : b) })
          : async data => setBlock({ links: [...block.links || [], data] })
        }
        onDelete={i !== undefined && (async () => setBlock({ links: block.links.filter((_, j) => i !== j) }))}
        onSuccess={modal.close}
        closeModal={modal.close}
      >
        {({ fieldProps }) => (
          <>
            <Field {...fieldProps('text')} autoFocus><MultiLangText /></Field>
            <Field {...fieldProps('url')}><UrlPicker /></Field>
            <Field {...fieldProps('primary')} type="checkbox" className="btn btn-link mb-0"><Toggle color={settings.brand?.linkColor} /></Field>
          </>
        )}
      </ModalForm2>
    )
  }

  return (
    <ul className="list-unstyled mb-0" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '1rem', padding: '.25rem 1rem', borderTop: `2px solid ${settings.brand?.linkColor}` }}>
      <li key="logo" style={{ flex: 1 }}>
        <Logo style={{ maxWidth: '100%', height: 20, objectFit: 'contain', opacity: .75 }} />
      </li>
      {block.links?.map((item, i) => item && (
        <li key={i}>
          <a
            href={item.url}
            onClick={e => { e.preventDefault(); editItem(i); }}
            target="_blank"
            className="btn btn-link btn-sm mb-0"
            style={{
              ...item.primary && {
                backgroundColor: settings.brand?.linkColor,
                color: '#fff',
                fontWeight: 500,
                borderRadius: 2,
              }
            }}
          >
            <LangText>{item.text}</LangText>
          </a>
        </li>
      ))}
      <li style={{ outline: '1px dashed var(--bs-primary)', outlineOffset: -1 }}>
        <button className="btn btn-link btn-sm mb-0" onClick={() => editItem()}>
          <i className="far fa-plus" /> <WurdText id="website.blocks.addLink" />
        </button>
      </li>
    </ul>
  );
}
