import Page from '../components/page';
import { useModal, useHistoryListQuery } from '../hooks';
import UnitTypeList from '../components/unit-type-list';


export default function UnitListPage() {
  const modal = useModal();
  const listQuery = useHistoryListQuery();

  return (
    <Page
      titleKey="unitTypeList.title"
      userRole="admin"
      modal={modal}
    >
      <UnitTypeList
        modal={modal}
        listQuery={listQuery}
        hideFilters={false}
      />
    </Page>
  );
}
