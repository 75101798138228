import store from 'store';

export default ({ style }) => {
  const { settings } = store.get();

  if (settings.brand?.logo) return <img src={settings.brand.logo} style={style} alt={settings.companyName} />;

  return (
    <span style={style}>
      <svg viewBox="0 0 200 230" xmlns="http://www.w3.org/2000/svg" fill={settings.brand?.linkColor} style={{ verticalAlign: 'middle', height: style?.height }}>
        <path d="M93.7037 219.684V122.8L10.4282 74.7207V171.845L93.7037 219.684Z" />
        <path d="M22.2133 57.3889L104.155 10.3162L187.24 58.0459L104.222 104.737L22.2133 57.3889Z" />
        <path d="M197.752 64.0848L197.881 64.3147V64.1591L197.752 64.0848Z" />
        <path d="M197.881 76.0423C197.881 113.456 197.881 134.432 197.881 171.845L114.606 219.684V122.878L197.881 76.0423Z" />
        <path d="M22.2133 57.3889L104.155 10.3162L187.24 58.0459L104.222 104.737L22.2133 57.3889Z" />
      </svg>
      <span
        style={{
          fontWeight: 700,
          color: settings.brand?.linkColor,
          filter: 'brightness(.8)',
          fontSize: .67 * style?.height,
          verticalAlign: 'middle',
          marginLeft: '.375rem',
          letterSpacing: '-.0165rem'
        }}
      >
        {settings.companyName}
      </span>
    </span>
  );
};