import * as actions from 'actions';
import { date, currency, confirm } from 'helpers/ui';

import Markdown from 'components/markdown';
import Alert from 'components/alert';
import ApiButton from 'components/api-button';


export default function UpdatePriceJob({
  cms,
  job,
  onChange,
}) {
  cms = cms.block('jobs.updatePrice');

  async function cancelJob() {
    if (!confirm(cms.text('confirmCancel'))) return;
    
    await actions.jobs.cancel(job.id);
    onChange();
  }

  return (
    <cms.Object keys="alert.text,confirmCancel">
      <Alert
        type="warning"
        icon="far fa-dollar-sign"
        actions={(
          <ApiButton
            bsType="link"
            onClick={cancelJob}
          >
            <i className="far fa-times" />
          </ApiButton>
        )}
      >
        <Markdown>
          {cms.text('alert.text', {
            startDate: date(job.result.startDate),
            oldPrice: currency(job.result.oldPrice),
            newPrice: currency(job.result.newPrice),
          })}
        </Markdown>
      </Alert>
    </cms.Object>
  );
}
