/**
 * A simple wrapper which applies the strings for label, placeholder etc.
 */
import PropTypes from 'prop-types';

import wurd, { WurdObject } from 'wurd-react';
import Input from '../input';


function SettingsInput({ name, component = 'div', wurdKey, wurdObjectKeys = 'label,placeholder,help', wurdSection, ...props }) {
  const fullWurdKey = `settings.inputs.${wurdKey || name}`;
  const { label, help, placeholder } = wurd.get(fullWurdKey) || {};

  return (
    <WurdObject type={component} id={fullWurdKey} keys={wurdObjectKeys}>
      <Input
        labelClassName="col-sm-3"
        wrapperClassName="col-sm-9"
        label={label}
        help={help}
        placeholder={placeholder}
        name={name}
        {...props}
      />
    </WurdObject>
  );
}

SettingsInput.propTypes = {
  name: PropTypes.string.isRequired,
  wurdKey: PropTypes.string,            //Key to look up the label, placeholder, help content; defaults to the name
  wurdObjectKeys: PropTypes.string      //Optionally override the default keys that are passed to CmsObject (label, placeholder, help)
}

export default SettingsInput;
