import { useEffect, useReducer, useRef } from 'react';

import store from '../store';


export default function useStore(key, defaultValue) {
  const [, forceUpdate] = useReducer(c => c + 1, 0);
  const ref = useRef(store.get(key));

  function listener() {
    const next = store.get(key);
    if (next !== ref.current) {
      ref.current = next;
      forceUpdate();
    }
  }

  function setValue(newValue) {
    store.set(key, newValue);
  }

  useEffect(() => store.onChange(listener), []);

  const value = store.get(key) || defaultValue;

  return [value, setValue];
}
