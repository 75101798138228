import * as helpers from 'helpers';

import LangText from 'components/lang-text';
import ListGroupItem from 'components/list-group-item';
import CustomFieldValue from './detail';


export default function CustomFieldsList({
  cfConfig,
  modelName,
  model,
  showUnsetFields = false, // Whether to show customFields without values
  children,
}) {
  return (
    <>
      {cfConfig.length > 0 ? children : undefined}
      <div className="list-group">
        {cfConfig.sort(helpers.ui.sortByCode).map(({ code, title, ...props }) => {
          return (
            <ListGroupItem
              key={code}
              items={[
                {
                  id: code,
                  title: <LangText obj={title} />,
                  text: <CustomFieldValue value={model.customFields?.[code] || '-'} {...props} />,
                },
              ]}
            />
          );
        })}
      </div>
    </>
  );
}
