import * as helpers from '../../../helpers';
import { WurdText } from 'wurd-react';

import UnitLoader from '../../loaders/unit';
import UserLoader from '../../loaders/user';
import ShortLabel from '../../short-label';
import SiteLoader from 'components/loaders/site';
import JobStateLabel from './job-state-label';
import LabelsListCell from 'plugins/labels/list';

const { stateColors } = helpers.unit;

const styles = {
  jobIcon: { color: stateColors.overdue },
  unitIcon: { color: '#aaa', fontSize: 18, marginTop: 6 },
};


export default function UnitMoveOutJobListItem({
  job,
  hideOwner,
  hideUnit,
  hideSite,
}) {
  const { endDate, ownerId, unitId } = job.result;

  
  return (
    <>
      <div role="cell" style={{ width: 30 }}>
        <div><i className="fas fa-fw fa-sign-out-alt" style={styles.jobIcon} /></div>
      </div>

      <div role="cell">
        <div>
          <strong>
            <WurdText id="unitJobList.filters.type.unit_moveOut" />:&nbsp;
            {!hideUnit && unitId && (
              <UnitLoader id={unitId}>
                {(unit) => (
                  <>
                    {unit.name}
                    {!hideSite && (
                      <span>, <SiteLoader id={unit.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader></span>
                    )}
                  </>
                )}
              </UnitLoader>
            )}
          </strong>
        </div>

        <div>
          {!hideOwner && (
            <UserLoader id={ownerId}>
              {(user) => user.name}
            </UserLoader>
          )}
        </div>
      </div>

      <div role="cell">
        <div title="Move-out date">
          {helpers.ui.date(endDate)}
        </div>
      </div>

      <div role="cell" style={{ textAlign: 'right' }}>
        <div><JobStateLabel job={job} /></div>
        {job.error && (
          <div>
            <ShortLabel bsType="danger" width="min(200px, 25vw)">{job.error}</ShortLabel>
          </div>
        )}
      </div>

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={job.labels} />
      </div>
    </>
  );
}
