import Link from 'components/nested-link';


export default ({ job }) => {
  const { data, result } = job;
  const { invoiceDate } = data;
  const { invoiceId, note, error } = result;

  return (
    <div>
      <div title="invoiceDate">{invoiceDate}</div>

      {invoiceId && (
        <div title="invoiceId">
          <Link to={`/invoices/${invoiceId}`}>
            <i className="fas fa-fw fa-money-bill-alt" /> {invoiceId}
          </Link>
        </div>
      )}

      {note && (
        <small>
          <div>{note}</div>
        </small>
      )}

      {error && (
        <div className="label label-danger">
          Failed: {error}
        </div>
      )}
    </div>
  );
};
