/**
 * A Bootstrap form field.
 */
import { cloneElement, useEffect } from 'react';
import styled from '@emotion/styled';
import wurd, { WurdObject } from 'wurd-react';
import Input from './input';
import Markdown from 'components/markdown';

const CheckboxContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '0 1rem ',
  'input[type="checkbox"]': {
    width: 14,
    height: 14,
    marginTop: 8,
  },
  '.help-block': {
    gridColumnStart: 2,
    marginBottom: 0,
  }
});

export default function Field({
  // FieldWrapper props
  include = true,
  name, type, wurdSection, wurdId = `${wurdSection}.${name}`, wurdKeys = 'label,help',
  label: labelProp, help: helpProp, // optional
  error, required, 'data-required': dataRequired = required, htmlFor, // optional
  component: Component = type === 'checkbox' ? CheckboxContainer : 'div',
  // innerComponent props
  icon, children, className = 'form-control', ...inputProps
}) {
  useEffect(() => {
    if (location.hash === '#' + name) {
      document.getElementById(name).scrollIntoViewIfNeeded?.({ behavior: 'smooth' });
    }
  }, []);

  if (!include) return null;

  //Get content from Wurd
  const wurdContent = wurd.get(wurdId) || {};

  const label = labelProp || wurdContent.label || name.replace(/^[a-z]/, s => s.toUpperCase());
  const help = helpProp || wurdContent.help;

  const allInputProps = {
    id: name, // TODO remove this, it cause duplicate id's warnings in console when using Multilang component for example, and not necessary to be added automatically like this
    name,
    type,
    className,
    required,
    ...inputProps
  };

  const LabelText = (location.hash === '#' + name) ? 'mark' : 'span';

  const inputComponent = children
    ? cloneElement(children, { ...allInputProps, ...children.props, className: `${className || ''} ${children.props.className || ''}` })
    : <Input {...allInputProps} />;

  return (
    <WurdObject
      id={wurdId}
      keys={wurdKeys}
      type="div"
      className={`form-group form-horizontal ${error ? 'has-error' : ''} clearfix`}
    >
      {labelProp !== null && (
        <label
          htmlFor={type === 'secret' ? undefined : htmlFor || name}
          className={`control-label col-sm-3 ${dataRequired ? 'required' : ''}`}
        >
          <LabelText>{label}</LabelText>
        </label>
      )}
      <Component className={labelProp !== null ? 'col-sm-9' : 'col-sm-12'}>
        {inputComponent}

        {help && helpProp !== null && <Markdown inline className="help-block">{help}</Markdown>}
        {error && <p className="help-block">{error}</p>}
      </Component>
    </WurdObject>
  );
}
