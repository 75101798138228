import { Children, cloneElement } from 'react';
import styled from '@emotion/styled';


const Option = styled.option({
  '&.default::after': {
    content: '"   *"'
  }
});


/**
 * <select multiple> that preserves the order of selected values (the order user selects them) 
 */
export default function SelectMultiple({ value, onChange, as: Component = 'select', children, ...props }) {
  const handleChange = (event) => {
    const values = Array.isArray(value) ? value : []; // value might be an empty string initially
    const newUnorderedValues = new Set([...event.target.selectedOptions].map(o => o.value));
    const orderedValues = [...values.filter(v => newUnorderedValues.has(v)), ...newUnorderedValues];
    onChange({
      target: {
        name: event.target.name,
        value: [...new Set(orderedValues)], // dedupe
      }
    });
  };

  return (
    <Component value={value} onChange={handleChange} {...props}>
      {Children.map(children, c => <Option key={c.key} className={value?.[0] === c.props.value ? 'default' : null} {...c.props} />)}
    </Component>
  )
}
