import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import wurd from 'wurd-react';
import styled from '@emotion/styled';
import Page from './page';
import api from '../utils/api';
import store from '../store';
import { hasRole } from '../helpers/auth';

const PageNoPadding = styled(Page)({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 56px)', // temp hack, see app_logged-in for how we should fix this with flexbox
  marginBottom: 0,

  '& > .cl-mcont': {
    padding: 0,
    flex: 1,
  },

  'iframe': {
    border: 'none',
    width: '100%',
    height: '100%',
  }
});

const Select = styled.select({
  width: 300,
  fontSize: 20,
  height: 46,
  fontWeight: 400,
  position: 'absolute',
  marginTop: 8,
  border: '0!important',
  boxShadow: 'none!important',
  option: {
    fontWeight: 300,
    fontSize: 18,
  }
});

export function showAnalytics(settings) {
  if (!settings.zoho?.dashboards?.length) return false;
  if (settings.zoho.showTo) return hasRole(settings.zoho.showTo);
  return hasRole('__superadmin') ? 1 : false;
}

export default function Analytics() {
  const { settings } = store.get();
  const { id = settings.zoho.dashboards[0] } = useParams();
  const navigate = useNavigate();
  const [embedUrl, setEmbedUrl] = useState(null);

  useEffect(() => {
    api.get(`/v1/admin/analytics/embed/${id.split('#')[0]}`)
      .then(({ data: { embedUrl } }) => {
        setEmbedUrl(embedUrl);
      })
      .catch(err => {
        window.alert(err.message);
      });
  }, [id]);

  if (!showAnalytics(settings)) return null;

  return (
    <PageNoPadding
      userRole="admin"
      documentTitle={wurd.text('common.nav.analytics')}
    >
      {settings.zoho.dashboards.length > 1 && (
        <Select
          id="analytics-dashboards"
          className="form-control"
          defaultValue={settings.zoho.dashboards.find(v => v.startsWith(id))}
          onChange={e => navigate(`/analytics/${e.target.value}`)}
        >
          {settings.zoho.dashboards.map(v => <option key={v} value={v}>{v.split('#')[1]}</option>)}
        </Select>
      )}
      <iframe src={embedUrl} />
    </PageNoPadding>
  );
}
