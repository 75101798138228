import { useParams } from 'react-router-dom';
import wurd, { WurdText } from 'wurd-react';

import * as helpers from '../helpers';
import * as actions from '../actions';
import { useModal, useItemLoader } from '../hooks';
import { useTasksPlugin } from 'plugins/tasks';

import DetailPage from '../components/detail-page';
import NotFound from '../components/not-found';
import Spinner from '../components/spinner';
import Activity from '../components/activity';
import Alert from '../components/alert';
import LangText from '../components/lang-text';
import Menu from '../components/invoice-detail/menu';
import SidebarSummary from '../components/invoice-detail/sidebar-summary';
import InvoiceItems from '../components/invoice-detail/items';
import InvoiceEntries from '../components/invoice-detail/entries';
import InvoicePayments from '../components/invoice-detail/payments';
import CustomFieldsSection from 'plugins/custom-fields/section';
import StateLabel from 'components/state-label';
import JobLoader from 'components/loaders/job';
import Link from 'components/nested-link';

import LabelsList from 'plugins/labels/detail';

const cms = wurd.block('invoiceView');

const { isCreditNote, stateBsTypes, getStateTitles } = helpers.invoice;


export default function InvoiceDetailPage() {
  const { idOrSid } = useParams();

  const modal = useModal();

  const { item: invoice, isLoading, refetch } = useItemLoader('invoices', idOrSid, {
    altIdKey: 'sid',
    include: actions.includeKeysByEndpoint.invoices,
    maxAge: 0,
  });
  const { item: unitRental } = useItemLoader('unit-rentals', invoice?.unitRentalId, { include: 'unit' });
  const { item: site } = useItemLoader('sites', invoice?.siteId);
  
  const tasksPlugin = useTasksPlugin({
    modal,
    targetType: 'invoice',
    targetId: invoice?.id,
  });

  if (!invoice) {
    return isLoading ? <Spinner /> : <NotFound />;
  }


  // Build breadcrumb ancestors
  const ancestors = site
    ? [
      { text: <LangText obj={site.title} />, url: `/sites/${site.code}` },
      { text: <WurdText id="invoiceList.title" />, url: `/sites/${site.code}/invoices` },
    ] : [
      { text: <WurdText id="invoiceList.title" />, url: '/invoices' },
    ];

  return (
    <DetailPage
      documentTitle={cms.text('title', { invoiceId: helpers.ui.sid(invoice) })}
      title={helpers.ui.sid(invoice)}
      subtitle={
        <>
          {helpers.auth.hasRole('_support') && Boolean(invoice.tags?.length) && (
            <small title="System tags"><i className="fa-fw far fa-sm fa-tag" />{invoice.tags.join(',')}</small>
          )}
          {isCreditNote(invoice) && <StateLabel type={stateBsTypes.credit}>{getStateTitles()?.credit}</StateLabel>}
        </>
      }
      ancestors={ancestors}
      userRole="admin"
      modal={modal}
      extra={(
        <>
          <LabelsList
            labels={invoice.labels}
            settingsLabels={helpers.settings.get().invoiceLabels}
            onChange={labels => actions.invoices.labels.update(invoice.id, labels)}
            disabled={invoice.deleted}
          />

          <Menu
            invoice={invoice}
            modal={modal}
            onChange={refetch}
            tasksPlugin={tasksPlugin}
          />
        </>
      )}
      sidebar={
        <SidebarSummary
          invoice={invoice}
          unitRental={unitRental}
          onChange={refetch}
        />
      }
    >
      {invoice.deleted && (
        <Alert type="danger">
          <cms.Markdown id="alert.isDeleted" />
        </Alert>
      )}

      {invoice.state === 'pending' && invoice.parentJobId && (
        <JobLoader id={invoice.parentJobId}>
          {(job) => {
            if (job.type !== 'unit_invoice') return null;

            return (
              <Alert
                type="info"
                icon="far fa-clock"
                actions={helpers.auth.hasRole('__superadmin') && (
                  <Link to={`/jobs/${invoice.parentJobId}`}><em className="text-muted">View job</em></Link>
                )}
              >
                <cms.Markdown id="alert.delayedCharge" vars={{
                  chargeDate: helpers.ui.date(job.data.chargeDate),
                }} />
              </Alert>
            );
          }}
        </JobLoader>
      )}
      
      <tasksPlugin.TaskList
        listLoader={tasksPlugin.listLoader}
      />

      <InvoiceItems
        invoice={invoice}
        onChange={refetch}
        modal={modal}
      />

      <InvoiceEntries
        invoice={invoice}
        site={site}
        unitRental={unitRental}
        modal={modal}
        onChange={refetch}
      />

      <InvoicePayments
        invoice={invoice}
        onChange={refetch}
        modal={modal}
      />

      <CustomFieldsSection
        cms={cms.block('customFields')}
        modelName="invoice"
        model={invoice}
        onChange={refetch}
        modal={modal}
      />

      <Activity context="invoice" endpoint={`/invoices/${invoice.id}/actions`} key={invoice?._fetched} showCommentBox={!invoice.deleted} />
    </DetailPage>
  );
}
