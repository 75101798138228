import wurd from 'wurd-react';
import styled from '@emotion/styled';

import * as actions from 'actions';
import * as helpers from 'helpers';
import { getAccountingCodeById } from 'helpers/business';

import Section from 'components/section';
import ClickableDiv from 'components/clickable-div';
import { WurdMarkdown } from 'components/markdown';
import StateLabel from 'components/state-label';
import Form, { calculateDynamicPrice } from './form';


const Item = styled(ClickableDiv)({
  '.secondary': {
    color: '#bbb',
  },
});


export default function UnitTypeOccupancyPrices({
  modal,
  cms,
  unitType,
  onChange,
}) {
  async function saveOPs(data) {
    await actions.update('unit-types', unitType.id, {
      occupancyPrices: data,
    });

    onChange();
  }

  function createOP() {
    modal.open(
      <Form
        mode="create"
        preview_basePrice={unitType.price}
        closeModal={modal.close}
        onSubmit={(data) => saveOPs([
          ...unitType.occupancyPrices,
          { direction: 'gt', ...data },
        ])}
      />,
      { bsSize: 'md' }
    );
  }

  function deleteOP(charge) {
    if (!window.confirm(wurd.text('common.confirmDelete'))) return;

    saveOPs(unitType.occupancyPrices.filter(({ id }) => id && id !== charge.id));
  }

  function editOP(event) {
    const selectedCharge = unitType.occupancyPrices.find(charge => charge.id === event.currentTarget.id);

    modal.open(
      <Form
        mode="update"
        preview_basePrice={unitType.price}
        closeModal={modal.close}
        initialValue={selectedCharge}
        onSubmit={(data) => saveOPs(unitType.occupancyPrices.map(charge => (charge === selectedCharge ? data : charge)))}
        onDelete={() => deleteOP(selectedCharge)}
      />,
      { bsSize: 'md' }
    );
  }


  return (
    <Section
      cms={cms}
      onClickAdd={createOP}
    >
      {unitType.occupancyPrices.length > 0 && unitType.occupancyPrices.sort(helpers.unitType.sort_occupancyPrices).map((rule) => {
        const computedPrice = calculateDynamicPrice(unitType.price, rule).toLocaleString('en', { minimumFractionDigits: rule.precision ?? 2, maximumFractionDigits: rule.precision ?? 2 }).replace(/[^\d.]/, '');

        return (
          <Item
            key={rule.id}
            id={rule.id}
            className="list-group-item clearfix"
            onClick={editOP}
          >
            <div>
              <cms.Text id="item.trigger" /> <strong><cms.Text id={`item.${rule.direction}`} /> {rule.threshold}%</strong> <cms.Text id="item.change" style={{ textTransform: 'lowercase' }} /> <strong><cms.Text id={`item.${rule.change < 0 ? 'decBy' : 'incBy'}`} /> <span style={{ color: `var(--bs-accent-${rule.change > 0 ? 'green' : 'red'})` }}>{Math.abs(rule.change)}%</span></strong>

              <span className="pull-right" style={{ direction: 'rtl' }}>
                {helpers.ui.priceChange(calculateDynamicPrice(unitType.price, rule), unitType.price)}
                {computedPrice == unitType.price_dynamic && <StateLabel type="info" style={{ margin: '0 .5rem' }}><cms.Text id="active" /></StateLabel>}
              </span>
            </div>
          </Item>
        );
      })}
    </Section>
  );
}
