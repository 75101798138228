import * as helpers from '../../../helpers';

import { Line } from 'react-chartjs-2';

const { states, stateColors } = helpers.unit;


export default function SiteOccupancyChart({
  site,
  reports,
}) {
  const stateTitles = helpers.unit.getStateTitles();

  const data = reports.reduce((memo, report) => {
    memo.dates.push(report.date);

    report.occupancy = report.occupancy || {};
    
    states.forEach(state => memo[state].push(report.occupancy[state]));

    return memo;
  }, {
    dates: [],
    blocked: [],
    available: [],
    reserved: [],
    occupied: [],
    overdue: [],
  });

  const datasets = states.map(state => ({
    label: stateTitles[state],
    data: data[state],
    borderColor: stateColors[state],
    backgroundColor: stateColors[state],
  }));

  return (
    <Line
      data={{
        labels: data.dates,
        datasets
      }}
    />
  );
}
