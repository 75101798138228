import store from '../../store';


export function getCfKey(site) {
  if (!site) return null;

  const settings = store.get('settings');

  if (site.securitySystem?.enabled) return `${site.securitySystem.name}_id`;

  if (site?.sec_plugin) return `${site.sec_plugin}_id`;

  if (site.customFields?.securitySystem) return `${site.customFields.securitySystem}_id`;

  return null;
}
