const Count = ({ name, value }) => value > 0 && <small>{name}: {value}</small>;

export default ({ job, refetch }) => {
  return (
    <div style={{ display: 'grid' }}>
      <Count name="markOverdue" value={job.result.markOverdueCount} />
      <Count name="unmarkOverdue" value={job.result.unmarkOverdueCount} />
      <Count name="reminders" value={job.result.reminders?.map(c => c?.length)} />
      <Count name="lateFees" value={job.result.lateFeesCount} />
    </div>
  );
};
