import wurd from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';

import SectionCrudList from 'components/section_crud-list';
import Section from 'components/section';
import LangText from 'components/lang-text';
import UnitPromoForm from 'components/form2/business/unit-promo';
import SettingsForm from '../form';
import SiteLoader from 'components/loaders/site';
import Input from '../input';
import ValetPromoForm from './valet-promo-form';


const cms = wurd.block('settings.promos');


export default function PromosSettings({ settings }) {
  return (
    <>
      {settings.modes.includes('selfStorage') && (
        <SectionCrudList
          cms={wurd.block('settings.unitPromos')}
          items={settings.unitPromos}
          Form={UnitPromoForm}
          formProps={{ settings }}
          actions={actions.business.unitPromos}
          onChange={actions.business.fetch}
        >
          {item => (
            <>
              <h4 style={{ margin: 0, fontSize: 18 }}>{item.code}</h4>
              <div>
                <div><LangText obj={item.title} /></div>
                <div className="text-muted" style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ flex: 1 }} title={cms.text('validDates') || 'Valid between'}>{item.validFrom ? helpers.ui.date(item.validFrom) : ' '} - {item.validTo ? helpers.ui.date(item.validTo) : ' '}</span>
                  <span style={{ marginLeft: 12 }}>{item.siteIds.slice(0, 4).map((siteId, i) => <SiteLoader id={siteId} key={siteId}>{site => <span>{helpers.ui.getLangText(site.title)}{i < item.siteIds.length - 1 ? ', ' : ' '}</span>}</SiteLoader>)} {item.siteIds.length > 4 ? <span>+{item.siteIds.length - 4}</span> : ''}</span>
                </div>
              </div>
            </>
          )}
        </SectionCrudList>
      )}

      {settings.modes.includes('valetStorage') && (
        <>
          <SectionCrudList
            cms={wurd.block('settings.valetPromos')}
            items={settings.promos}
            Form={ValetPromoForm}
            actions={actions.business.promos}
            onChange={actions.business.fetch}
            style={{ float: 'initial', marginTop: 0 }}
          >
            {item => (
              <>
                <div className="pull-right text-right">{helpers.ui.currency(item.amount)}</div>
                <h4 style={{ margin: 0, fontSize: 18 }}>{item.code}</h4>
                <div>
                  <div><LangText obj={item.title} /></div>
                  <div style={{ color: '#aaa' }}>{helpers.ui.date(item.validFrom)} - {helpers.ui.date(item.validTo)}</div>
                </div>
              </>
            )}
          </SectionCrudList>

          <Section cms={wurd.block('settings.referrals')}>
            <SettingsForm name="referral" defaultValue={settings.referral}>
              <Input
                name="referrerCredit"
                type="number"
                required
                wurdKey="referral.referrerCredit"
              />
              <Input
                name="refereeCredit"
                type="number"
                required
                wurdKey="referral.refereeCredit"
              />
            </SettingsForm>
          </Section>
        </>
      )}
    </>
  );
}
