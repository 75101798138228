import wurd from 'wurd-react';
import { Link } from 'react-router-dom';

import { date, currency, sid } from '../../../../helpers/ui';
import * as actions from '../../../../actions';

import JobStep from '../../job-step2';
import Alert from '../../../alert';
import ApiButton from '../../../api-button';
import InvoiceLoader from '../../../loaders/invoice';
import Invoice from '../../../invoice';
import InvoiceEntries from '../../../invoice-detail/entries';
import { Section, Heading } from '../../components';
import ConfirmAction from '../../confirm-action';
import OperationsBar from '../operations-bar';

const cms = wurd.block('unitOrder.steps.invoice_confirm');


export default function UniveMoveInJobSteps_InvoiceConfirm({
  site,
  job,
  step,
  onChange,
  modal,
}) {
  async function completeStep() {
    await actions.jobs.updateStep(job.id, step.id, {
      state: 'completed',
    });

    onChange();
  }

  function render_ready() {
    if (step.requiresInput) return render_requiresInput();

    return (
      <JobStep
        step={step}
        title={<cms.Text id="ready.title" />}
      >
        {step.error && (
          <Alert type="danger">
            {step.error}
          </Alert>
        )}
      </JobStep>
    );
  }

  function render_requiresInput() {
    const { invoiceId } = job.result;

    return (
      <InvoiceLoader id={invoiceId} include="billing">
        {(invoice, { refetch }) => (
          <JobStep
            step={step}
            title={<cms.Text id="requiresInput.title" />}
          >
            <Section>
              <Heading>
                <div className="pull-right" style={{ color: '#aaa' }}>{date(invoice.startDate)}</div>
                <Link to={`/invoices/${sid(invoice)}`}>
                  <cms.Text id="invoiceLink" vars={{ sid: sid(invoice) }} />
                </Link>
              </Heading>

              <InvoiceEntries
                //ref={(ref) => { this.invoiceItems = ref; }}
                showAddButton
                invoice={invoice}
                onChange={() => {
                  refetch();
                  onChange();
                }}
                modal={modal}
              />
              
              <OperationsBar
                job={job}
                modal={modal}
                onChange={onChange}
              />
            </Section>

            <div className="btn-group btn-group-justified" style={{ marginTop: 40 }}>
              <div className="btn-group">
                <ConfirmAction
                  mustConfirm={invoice.state !== 'paid'}
                  alertBtn={invoice?.billing && invoice.balance !== 0 && invoice.state !== 'pending' && {
                    onClick: () => actions.invoices.process(invoice.id).finally(onChange),
                    text: <cms.Text id="chargeInvoice" />
                  }}
                  cms={cms.block('continueUnpaid')}
                >
                  <ApiButton onClick={completeStep}>
                    <i className="fas fa-check" />
                    &nbsp;
                    <cms.Text id="requiresInput.completeBtn" />
                  </ApiButton>
                </ConfirmAction>
              </div>
            </div>
          </JobStep>
        )}
      </InvoiceLoader>
    );
  }

  function render_completed() {
    const { invoiceId } = job.result;

    return (
      <InvoiceLoader id={invoiceId}>
        {(invoice) => (
          <JobStep
            step={step}
            title={<cms.Text id="completed.title" vars={{ amount: currency(invoice.total) }} />}
          >
            <cms.Text id="completed.invoice" />
            <Invoice invoice={invoice} />
          </JobStep>
        )}
      </InvoiceLoader>
    );
  }

  if (step.state === 'ready') return render_ready();
  if (step.state === 'requiresInput') return render_requiresInput();
  if (step.state === 'completed') return render_completed();

  return null;
}
