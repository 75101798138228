import { useItemLoader } from '../../hooks';
import { throttle_fetchOne } from '../../actions';

const fetch = throttle_fetchOne();

export default function UnitTypeLoader({
  id,
  include,
  maxAge,
  loading = <i className="fas fa-spinner fa-spin" />,
  fallback = <i className="fas fa-question-circle" title="Not found" />,
  children: renderFn,
}) {
  const { item: unitType, isLoading, refetch } = useItemLoader('unit-types', id, {
    include,
    maxAge,
    fetch,
  });

  if (!unitType) {
    return isLoading ? loading : fallback;
  }

  return renderFn(unitType, { isLoading, refetch });
}
