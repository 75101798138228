import { WurdText } from 'wurd-react';
import { getOccupancy } from '../../helpers/site';
import { stateColors } from '../../helpers/unit';

const styles = {
  main: {
    position: 'relative',
  },

  text: {
    position: 'absolute',
    top: 1,
    left: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    color: '#fff',
    fontSize: 12,
  }
};

function getPercent(occupancy, state) {
  return (occupancy[state] / occupancy.total) * 100;
}


export default function ({ site, style }) {
  const occupancy = getOccupancy(site);

  return (
    <div style={styles.main}>
      <div className="progress" style={style}>
        {['occupied', 'reserved', 'available', 'blocked'].map(state => {
          const numUnits = occupancy[state];
          if (!numUnits) return null;

          return (
            <div
              key={state}
              className="progress-bar"
              style={{
                width: `${getPercent(occupancy, state)}%`,
                backgroundColor: stateColors[state]
              }}
            />
          );
        })}
      </div>

      <WurdText id="siteList.item.occupancy" vars={{ rate: String(occupancy.occupancyRate) }} style={styles.text} />
    </div>
  );
}
