import LangText from 'components/lang-text';

import * as helpers from '../../helpers';


var styles = {
  title: {
    margin: 0,
    fontSize: 18
  },

  type: {
    color: '#aaa',
    fontStyle: 'italic'
  },

  img: {
    width: 50,
    height: 50,
    marginRight: 10,
    border: '1px solid #eee'
  },

  displayPrice: {

  },

  price: {
    color: '#aaa',
    fontStyle: 'italic',
    textAlign: 'right'
  },

  info: {
    color: '#aaa'
  }
};

/**
 * Renders a Plan, Item or Product for use in lists etc.
 */
export default function ItemType({ item }) {
  return (
    <div>
      <img className="pull-left" src={item.image} style={{ ...styles.img, objectFit: item.imageFit || 'cover' }} alt="Item" />
      <div className="pull-right">
        <div style={styles.displayPrice}><LangText obj={item.displayPrice} /></div>
        <div style={styles.price}>{helpers.ui.currency(item.price)}</div>
      </div>

      <h4 style={styles.title}><LangText obj={item.title} /> <small style={styles.type}>{item.type || item.name || item.code || item.slug}</small></h4>
      <div style={styles.info}><LangText obj={item.info} /></div>
    </div>
  );
}
