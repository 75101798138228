import store from 'store';
import { getLangText } from 'helpers/ui';
import { currentLang } from 'utils/language';
import { Editable } from 'components/markdown';
import { Field, MultiLangText, FormObject, ModalForm2 } from 'components/form2';
import { VariantField } from './hero';


export function edit({ block, setBlock, modal }) {
  modal.open(
    <ModalForm2
      wurdSection="website.page.block"
      mode="update"
      initialValue={block}
      onSubmit={async data => setBlock(data)}
      onSuccess={modal.close}
      closeModal={modal.close}
    >
      {({ fieldProps }) => (
        <>
          <Field {...fieldProps('title')} autoFocus><MultiLangText /></Field>
          <Field {...fieldProps('features.text')}><MultiLangText /></Field>
          <Field {...fieldProps('availability.text')}><MultiLangText /></Field>
          <Field {...fieldProps('btn.text')}><MultiLangText /></Field>
          <VariantField {...fieldProps('variant')} />
          <Field {...fieldProps('id')} defaultValue="sites" />
        </>
      )}
    </ModalForm2>
  )
}

export default ({ block, setBlock, modal }) => {
  const sites = store.get('settings').sites?.filter(site => !site.hidden);

  return (
    <>
      <h4 className="text-center" style={{ fontWeight: 400 }}>
        <Editable inline placeholder="[Title]" onChange={e => setBlock({ title: { [currentLang]: e.target.value } })}>{getLangText(block.title)}</Editable>
      </h4>
      <Editable className="text-center" placeholder="[Text]" style={{ padding: '0 1rem', maxWidth: '50%' }} onChange={e => setBlock({ text: { [currentLang]: e.target.value } })}>{getLangText(block.text)}</Editable>

      <ul className="list-unstyled" style={{ display: 'flex', gap: '1.5rem', ...sites.length <= 2 ? { justifyContent: 'center' } : { overflowX: 'auto' } }}>
        {sites?.filter(s => !s.hidden).map(site => (
          <li key={site.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <em>{getLangText(site.title)}</em>
            <img src={site.image} style={{ width: 150, height: 60, margin: '8px 0', objectFit: site.imageFit || 'contain' }} alt={site.code} />
            <button className="btn btn-link" onClick={edit}>
              <Editable inline onChange={e => setBlock({ btn: { text: { [currentLang]: e.target.value } } })}>{getLangText(block.btn?.text) || 'View'}</Editable>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}
