import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { sid } from '../../helpers/ui';
import * as actions from '../../actions';
import { useHistoryListQuery } from '../../hooks';

import InvoiceList from '../invoice-list';
import UnitInvoiceForm from './forms/invoice';


export default function UnitInvoices({
  modal,
  unit,
  rental,
  fetchUnit,
}) {
  const navigate = useNavigate();
  const listQuery = useHistoryListQuery({
    implicitParams: {
      unitRentalId: rental.id,
    },
    initialParams: {
      state: null,
    },
  });

  function addManualInvoice({ refetch }) {
    const initialValue = {
      startDate: moment().format('YYYY-MM-DD'),
    };

    modal.open(
      <UnitInvoiceForm
        initialValue={initialValue}
        onSubmit={async (data) => actions.unitRentals.createManualInvoice(rental.id, data)}
        onSuccess={(invoice) => {
          refetch();
          modal.close();
          navigate(`/invoices/${sid(invoice)}`);
        }}
        closeModal={modal.close}
      />,
    );
  }


  return (
    <InvoiceList
      listQuery={listQuery}
      modal={modal}
      hideSite={true}
      hideOwner
      hideUnit
      onClickAdd={addManualInvoice}
      onChange={fetchUnit}
    />
  );
}
