import wurd from 'wurd-react';

import { useListLoader } from '../../hooks';

import TableList from '../table-list';
import Filters from './filters';
import Item from './item';
import Menu from './menu';

const cms = wurd.block('paymentList');

function viewPayment(payment) {
  return `/invoices/${payment.invoice.sid.toUpperCase()}`;
}

export default function PaymentList({
  listQuery,
  modal,
  hideSite = false,
  hideOwner = false,
  hideUnit = false,
}) {
  const listLoader = useListLoader('invoices/payments', listQuery.params, { maxAge: 0 });

  const renderItem = (payment) => (
    <Item
      payment={payment}
      hideSite={hideSite}
      hideOwner={hideOwner}
      hideUnit={hideUnit}
    />
  );

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={(
        <Filters
          listQuery={listQuery}
          hideOwner={hideOwner}
          hideSite={hideSite}
        />
      )}
      menu={(
        <Menu
          modal={modal}
          listQuery={listQuery}
        />
      )}
      renderItem={renderItem}
      getItemLink={viewPayment}
    />
  );
}