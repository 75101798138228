import { date } from '../../../helpers/ui';
import Link from 'components/nested-link';


export default ({ job }) => {
  const { sid } = job;
  const { siteId, startDate } = job.result;

  return (
    <div>
      <div>
        <Link to={`/sites/${siteId}/jobs/${sid}`}>
          <i className="fa-fw fas fa-warehouse" /> Move-in {sid}
        </Link>
      </div>
      <div style={{ color: '#aaa' }}>
        {date(startDate)}
      </div>
    </div>
  );
};
