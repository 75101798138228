import { useState } from 'react';

import { iconColors } from '../../../helpers/ui';

const buttonStyle = {
  borderRadius: 2,
};


export default function SelectionButton({
  bsType = 'default',
  iconBsType = 'default',
  faIcon,
  text,
  onClick,
  disabled = false,
  className = '',
}) {
  const [isLoading, setIsLoading] = useState(false);

  function handleClick() {
    const result = onClick();

    // Handle async/promise functions (show a spinner until complete)
    if (result && result.finally) {
      setIsLoading(true);

      result.finally(() => setIsLoading(false));
    }
  }

  return (
    <button
      type="button"
      style={buttonStyle}
      className={`btn btn-flat btn-${bsType} ${className}`}
      onClick={handleClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <><i className="far fa-spin fa-spinner" /> {text}</>
      ) : (
        <><i className={faIcon} style={iconColors[iconBsType]} /> {text}</>
      )}
    </button>
  );
}
