import PropTypes from 'prop-types';
import wurd, { WurdObject } from 'wurd-react';

import store from '../../store';

import FilterButton from './button';
import UserSelect from '../user-select';
import ClearIndicator from './clear-indicator';


const cms = wurd.block('common.filters.user');


export default function UserFilter({ active, onSelect, ...props }) {
  let title = cms.text('title');

  const user = active && store.get().users_byId[active];

  if (user) {
    title += `: ${user.firstName} ${user.lastName}`;
  }

  return (
    <WurdObject id={cms.id()} keys="title,placeholder,clear">
      <FilterButton active={active} onSelect={onSelect} title={title} {...props}>
        <div style={{ padding: 10, width: 250 }}>
          <UserSelect
            placeholder={cms.text('placeholder')}
            onChange={onSelect}
            value={active}
            autoFocus
            isClearable
            components={{ ClearIndicator: props => <ClearIndicator {...props} onSelect={onSelect} /> }}
          />
        </div>
        {/* <div style={{ padding: '0 10px', textAlign: 'right' }}>
          <Button bsStyle="default" onClick={() => onSelect(null)}>{cms.text('clear')}</Button>
        </div> */}
      </FilterButton>
    </WurdObject>
  );
}


UserFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.any, // The current filter value (null if not set)
};
