/**
 * A badge that animates when the value changes
 */
import { memo } from 'react';
import styled from '@emotion/styled';
import AnimateOnChange from 'react-animate-on-change';


const Wrapper = styled.span({
  '.highlight-success': {
    animation: 'sg-anim__badge-highlight-success .8s cubic-bezier(1,-0.45,.62,1.74)',
  },

  '@keyframes sg-anim__badge-highlight-success': {
    '0%': {
      backgroundColor: 'rgb(92, 184, 92)',
      transform: 'scale(2)',
    },
    '100%': {
      backgroundColor: '#ccc',
      transform: 'scale(1)',
    },
  },
});


function AnimatedBadge({
  value,
}) {
  return (
    <Wrapper>
      <AnimateOnChange
        baseClassName="badge"
        animationClassName="highlight-success"
        animate
      >
        {value}
      </AnimateOnChange>
    </Wrapper>
  );
}

export default memo(AnimatedBadge);
