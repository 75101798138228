import _ from 'lodash';
import moment from 'moment-timezone';
import wurd from 'wurd-react';

import * as marketHelpers from './market';
import * as areaHelpers from './area';
import * as planHelpers from './plan';
import * as settingsHelpers from './settings';
import processCustomFields from '../plugins/custom-fields/actions';


export const emailConfirmedProps = user => !user.emailConfirmed && ({
  className: 'text-danger text-truncate',
  title: wurd.text('common.emailUnconfirmed') || 'Unverified email',
});


export const getMarket = function (user) {
  const markets = settingsHelpers.get().markets;

  return _.find(markets, { id: user.market.id });
};


export const charges = {
  /**
   * Returns recurring charges on the user that are active (i.e. within date range)
   */
  getActive(user) {
    const today = moment().format('YYYY-MM-DD');

    return user.charges?.filter(charge => !charge.endDate || charge.endDate >= today);
  },


  /**
   * Returns recurring charges on the user that are inactive (i.e. oustide of valid date range)
   */
  getInactive(user) {
    const today = moment().format('YYYY-MM-DD');

    return user.charges?.filter(charge => charge.endDate && charge.endDate < today);
  },


  /**
   * Returns the total amount of active recurring charges
   *
   * @param {User} user
   * 
   * @return {Number}
   */
  getActiveTotal(user) {
    let total = 0;

    //Add plan charge
    if (user.planId) {
      const plan = planHelpers.get(user.planId);
      if (plan) total += plan.price;
    }

    for (const charge of charges.getActive(user) || []) {
      total += charge.amount;
    }

    return total;
  }
}


/**
 * Formats user as CSV
 *
 * @param {Object} user
 * @return {String} csv
 */
export function toCsv(user) {
  const settings = settingsHelpers.get();
  const billing = user.billing || {};  
  const plan = marketHelpers.get(user.planId);
  const market = planHelpers.get(user.market?.id);
  const area = areaHelpers.get(user.area, true);

  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    language: user.language,
    marketId: user.marketId,
    _market: market?.name,
    _plan: plan?.name,
    _planTotal: plan?.price,
    _chargesTotal: charges.getActiveTotal(user),
    companyName: user.companyName,
    address: user.address,
    area: user.area,
    _areaTitle: area.title,
    phone: user.phone,
    promoCode: user.promoCode,
    referCode: user.referCode?.toUpperCase(),
    invoiceDate: user.invoiceDate,
    'billing.id': billing.id,
    'billing.type': billing.type,
    ...billing.type === 'gmopg' && { 'billing.paymentType': billing.paymentType },
    billingMethod: user.billingMethod,
    billingTrigger: user.billingTrigger,
    roles: _.without(user.roles, '__superadmin')?.join(','),
    permissions: user.permissions?.join(','),
    isActive: user.isActive,
    isDisabled: user.isDisabled,
    disabledReason: user.disabledReason,
    labels: user.labels?.join(','),
    ...Object.fromEntries(
      settings.userCustomFields?.sort((a,b) => a.code.localeCompare(b.code)).map(({ code, title }) => {
        const value = user.customFields?.[code];
        return [`customFields.${code}`, value?.url || value];
      })
    ),
    created: user.created,
    updated: user.updated,
  };
}


export async function fromCsv({ market, plan, ...user }) {
  if (user.customFields) {
    user.customFields = await processCustomFields(user.customFields, 'users');
  }
  const marketId = user.marketId || market && marketHelpers.list().find(o => o.name === market)?.id;
  const planId = user.planId || plan && planHelpers.list().find(o => o.name === plan)?.id;

  if (!marketId && market || !planId && plan) throw new Error([!marketId && market && `market ${user.marketId || market} not found`, !planId && plan && `plan ${user.planId || plan} not found`].filter(s => s).join(', '));

  return {
    ...user,
    roles: (user.roles || undefined)?.split(','),
    labels: (user.labels || undefined)?.split(','),
    permissions: (user.permissions || undefined)?.split(','),
    marketId,
    planId,
  };
}
