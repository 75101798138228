import moment from 'moment-timezone';
import wurd from 'wurd-react';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import LangText from '../lang-text';
import Section from '../section';
import ChargesMenuButton from '../menu-button_charges';
import UserForm from '../form2/user';
import UserChargeForm from '../form2/user/charge';

const cms = wurd.block('userView.charges');


export default function UserCharges({
  user,
  onChange,
  openModal,
  closeModal,
}) {
  const activeCharges = helpers.user.charges.getActive(user);
  const inactiveCharges = helpers.user.charges.getInactive(user);

  function handleChange() {
    closeModal();
    onChange();
  }

  function addCharge(chargeType = null) {
    const charge = {
      type: 'recurringCharge',
      startDate: moment().format('YYYY-MM-DD'),
      sourceType: chargeType ? 'preset' : 'custom',
      sourceId: chargeType?.id,
      title: chargeType?.title,
      amount: chargeType?.amount,
    };

    openModal(
      <UserChargeForm
        initialValue={charge}
        onSubmit={data => actions.users.charges.create(user.id, data)}
        onSuccess={handleChange}
        title={<cms.Text id="create.title" vars={user} />}
        submitButton={<cms.Text id="create.ok" />}
        modal
        closeModal={closeModal}
      />,
    );
  }

  async function deleteCharge(charge) {
    if (!window.confirm('Are you sure?')) return;

    await actions.users.charges.delete(user.id, charge.id);

    handleChange();
  }

  function editCharge(charge) {
    openModal(
      <UserChargeForm
        initialValue={charge}
        onSubmit={data => actions.users.charges.update(user.id, charge.id, data)}
        onSuccess={handleChange}
        title={<cms.Text id="update.title" vars={user} />}
        submitButton={<cms.Text id="update.ok" />}
        modal
        closeModal={closeModal}
        onDelete={() => deleteCharge(charge)}
      />,
    );
  }

  function editPlan() {
    openModal(
      <UserForm
        initialValue={user}
        onSubmit={data => actions.users.update(user.id, data)}
        onSuccess={handleChange}
        title={<h4 className="modal-title"><cms.Text id="editPlanModal.title" vars={user} /></h4>}
        submitButton={<cms.Text id="editPlanModal.ok" />}
        closeModal={closeModal}
        fields={['planId']}
      />,
    );
  }

  function renderItems(items) {
    return items.map(charge => (
      <tr
        key={charge.id}
        style={{ cursor: 'pointer' }}
        onClick={() => editCharge(charge)}
      >
        <td><strong><LangText obj={charge.title} /></strong></td>
        <td className="text-muted">
          {helpers.ui.date(charge.startDate)}
          {' - '}
          {charge.endDate ? (
            helpers.ui.date(charge.endDate)
          ) : (
            <cms.Text id="ongoing" />
          )}
        </td>
        <td className="text-right">{helpers.ui.currency(charge.amount)}</td>
      </tr>
    ));
  }

  function renderUserPlan() {
    // Exit if no plans are configured
    if (!helpers.plan.list().length) return null;

    // Show the plan
    if (user.planId) {
      const plan = helpers.plan.get(user.planId, true) || {};

      return (
        <tr
          style={{ cursor: 'pointer' }}
          onClick={editPlan}
        >
          <td><strong><cms.Text id="plan" />: {plan.title}</strong></td>
          <td />
          <td className="text-right">{helpers.ui.currency(plan.price)}</td>
        </tr>
      );
    }

    return (
      <tr
        style={{ cursor: 'pointer' }}
        onClick={editPlan}
      >
        <td><strong><cms.Text id="plan" />: <cms.Text id="noPlan" /></strong></td>
        <td />
        <td />
      </tr>
    );
  }

  return (
    <Section
      cms={cms}
      extra={<ChargesMenuButton onSelect={addCharge} />}
    >
      <>
        <table className="table no-border table-hover">
          <thead>
            <tr><th colSpan="10"><cms.Text id="active" /></th></tr>
          </thead>
          <tbody className="no-border-x no-border-y">
            {renderUserPlan()}
            {renderItems(activeCharges)}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="10" className="text-right" style={{ fontSize: 16 }}>
                <strong>{helpers.ui.currency(helpers.user.charges.getActiveTotal(user))}</strong>
              </td>
            </tr>
          </tfoot>
        </table>

        <table className="table no-border table-hover">
          <thead>
            <tr><th colSpan="10"><cms.Text id="expired" /></th></tr>
          </thead>
          <tbody className="no-border-x no-border-y">
            {renderItems(inactiveCharges)}
          </tbody>
        </table>
      </>
    </Section>
  );
}
