/**
 * return FontAwesome icon className from a content-type https://gist.github.com/colemanw/9c9a12aae16a4bfe2678de86b661d922
 * @param {string} mime 
 */
export default function mimeToIcon(mime = '') {
  if (mime.startsWith('image')) return 'fa-file-image';
  if (mime.startsWith('audio')) return 'fa-file-audio';
  if (mime.startsWith('video')) return 'fa-file-video';
  if (mime.startsWith('application/pdf')) return 'fa-file-pdf';
  if (mime.startsWith('application/msword')) return 'fa-file-word';
  if (mime.startsWith('application/vnd.ms-word')) return 'fa-file-word';
  if (mime.startsWith('application/vnd.oasis.opendocument.text')) return 'fa-file-word';
  if (mime.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml')) return 'fa-file-word';
  if (mime.startsWith('application/vnd.ms-excel')) return 'fa-file-excel';
  if (mime.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml')) return 'fa-file-excel';
  if (mime.startsWith('application/vnd.oasis.opendocument.spreadsheet')) return 'fa-file-excel';
  if (mime.startsWith('application/vnd.ms-powerpoint')) return 'fa-file-powerpoint';
  if (mime.startsWith('application/vnd.openxmlformats-officedocument.presentationml')) return 'fa-file-powerpoint';
  if (mime.startsWith('application/vnd.oasis.opendocument.presentation')) return 'fa-file-powerpoint';
  if (mime.startsWith('text/plain')) return 'fa-file-text';
  if (mime.startsWith('text/html')) return 'fa-file-code';
  if (mime.startsWith('application/json')) return 'fa-file-code';
  if (mime.startsWith('application/gzip')) return 'fa-file-archive';
  if (mime.startsWith('application/zip')) return 'fa-file-archive';
  return 'fa-file';
}
