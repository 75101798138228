import { Component } from 'react';
import PropTypes from 'prop-types';

import store from '../store';
import * as actions from '../actions';
import debounce from '../utils/debounce';

import AsyncSelect from 'react-select/async';


const userToOption = user => user && {
  value: user.id,
  label: `${user.firstName} ${user.lastName} (${user.email})`
};

const selectStyles = {
  container: (base) => ({
    ...base,
    zIndex: 1,
    flex: 1,
    position: 'relative',
  }),
};

export default class FormUserSelect extends Component {
  static propTypes = {
    // if value (userId) is not undefined, this component is controlled, and its state won't be used
    value: PropTypes.string,
  };

  state = {
    selectedOption: null,
  }

  render() {
    const { value: userId, onChange, ...props } = this.props;

    const { users_byId } = store.get();

    const defaultOptions = Object.values(users_byId).map(userToOption);

    return (
      <AsyncSelect
        cacheOptions
        defaultOptions={defaultOptions}
        loadOptions={this.debouncedLoadOptions}
        {...props}
        value={userId !== undefined ? userToOption(users_byId[userId]) || '' : this.state.selectedOption}
        isOptionSelected={option => option.value === userId}
        onChange={this.onChange}
        styles={selectStyles}
      />
    );
  }

  loadOptions = (inputValue) => {
    //Search and format options
    return actions.users.fetch({ search: inputValue, limit: 100 })
      .then(users => users.map(userToOption));
  };

  debouncedLoadOptions = debounce(this.loadOptions, 400);

  onChange = (option) => {
    if (!option) return;

    if (this.props.value === undefined) { // if uncontrolled update the local state (no need to if controlled)
      this.setState({ selectedOption: option });
    }

    this.props.onChange(option.value);
  }
}
