import { MenuItem } from 'react-bootstrap';
import wurd from 'wurd-react';
import { useListLoader } from 'hooks';
import * as actions from 'actions';

import List from './list';
import TaskJobModal from './modal';


export function useTasksPlugin({
  modal,
  targetType,
  targetId,
}) {
  const queryParams = targetId ? {
    type: 'task',
    state: 'active',
    targetId,
  } : null; // Making it null until we have a targetId prevents fetching without the targetId

  const listLoader = useListLoader('jobs', queryParams, { maxAge: 0 });

  async function addTask({ targetId: override_targetId = targetId }) {
    modal.open(
      <TaskJobModal
        onSubmit={async (data) => {
          await actions.jobs.create(null, 'task', {
            ...data,
            targetType,
            targetId: override_targetId,
          });
        }}
        onSuccess={async () => {
          modal.close();
          listLoader.refetch();
        }}
        closeModal={modal.close}
      />
    )
  }

  const AddTaskMenuItem = ({ targetId: override_targetId = targetId }) => (
    <MenuItem onSelect={() => addTask({ targetId: override_targetId })}>
      <i className="far fa-fw fa-clipboard-check" />&nbsp;&nbsp;&nbsp;{wurd.text('common.tasks.addTask')}
    </MenuItem>
  )

  const TaskList = () => (
    <List
      listLoader={listLoader}
    />
  );

  return {
    listLoader,
    taskJobs: listLoader.items,
    addTask,
    AddTaskMenuItem,
    TaskList,
  };
}
