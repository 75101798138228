import { useState } from 'react';

import { iconColors } from '../helpers/ui';


const wrapperStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  margin: '0 -4px',
};

const buttonStyle = {
  borderRadius: 2,
  boxShadow: 'none',
  flex: '1 1 0',
  margin: '0 4px',
};

const iconStyle = {
  fontSize: 30,
  marginBottom: 6,
};

export function LargeButton({
  bsType = 'default',
  iconBsType = 'default',
  faIcon,
  text,
  onClick,
  disabled = false,
}) {
  const [isLoading, setIsLoading] = useState(false);

  function handleClick() {
    const result = onClick();

    // Handle async/promise functions (show a spinner until complete)
    if (result && result.finally) {
      setIsLoading(true);

      result.finally(() => setIsLoading(false));
    }
  }

  return (
    <button
      type="button"
      className={`btn btn-${bsType}`}
      onClick={handleClick}
      style={buttonStyle}
      disabled={disabled || isLoading}
    >
      <div>
        {isLoading ? (
          <i className="far fa-spin fa-spinner" style={iconStyle} />
        ) : (
          <i className={faIcon} style={{ ...iconStyle, ...iconColors[iconBsType] }} />
        )}
      </div>
      <div>{text}</div>
    </button>
  );
}


export default function LargeButtonGroup({
  style,
  children,
}) {
  return (
    <div style={{ ...wrapperStyle, ...style }}>
      {children}
    </div>
  );
}
