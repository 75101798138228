import _ from 'lodash';
import wurd from 'wurd-react';
import * as actions from '../actions';
import * as helpers from '.';
import * as language from '../utils/language';


function doTranslate(itemConfig, lang) {
  var obj = Object.assign({}, itemConfig);

  ['title'].forEach(prop => {
    obj[prop] = helpers.ui.getLangText(obj[prop], lang);
  });

  return obj;
}

export const list = function (translate) {
  const settings = helpers.settings.get();

  const methodConfigs = settings.manualPaymentMethods || [];

  if (translate) {
    const lang = language.get();

    return methodConfigs
      .map(methodConfig => doTranslate(methodConfig, lang))
      .sort((a, b) => a.title?.localeCompare(b.title));
  } else {
    return methodConfigs
      .sort((a, b) => a.code.localeCompare(b.code));
  }
}

export const type_display = payment => wurd.text(`paymentList.filters.method.${payment.type}`) || payment.type;
export const method_display = payment => list(true).find(m => m.code === payment.method)?.title || payment.method;

export async function fetchDetailed(params) {
  const payments = await actions.fetch('invoices/payments', params, { skipCache: true });

  const invoiceIds = [...new Set(payments.map(p => p.invoice.id))];
  const ownerIds = [...new Set(payments.map(p => p.invoice.owner.id))];
  const siteIds = [...new Set(payments.map(p => p.invoice.siteId).filter(Boolean))];
  const unitRentalIds = [...new Set(payments.map(p => p.invoice.unitRentalId).filter(Boolean))];

  const invoices = await actions.fetch('invoices', { ids: invoiceIds, include: 'customFields' }).then(vs => new Map(vs.map(o => [o.id, o])));
  const users = await actions.fetch('users', { ids: ownerIds, include: 'billing,customFields' }).then(vs => new Map(vs.map(o => [o.id, o])));
  const sites = await actions.fetch('sites', { ids: siteIds, include: 'customFields' }).then(vs => new Map(vs.map(o => [o.id, o])));
  const rentals = await actions.fetch('unit-rentals', { ids: unitRentalIds, include: 'unit,customFields' }).then(vs => new Map(vs.map(o => [o.id, o])));


  return payments.map(payment => {
    return {
      ...payment,
      invoice: invoices.get(payment.invoice.id),
      owner: users.get(payment.invoice.owner.id),
      site: sites.get(payment.invoice.siteId),
      unit: rentals.get(payment.invoice.unitRentalId)?.unit,
      rental: rentals.get(payment.invoice.unitRentalId),
    };
  });
}

export function toCsv({ invoice, owner, site, unit, rental, ...payment }) {
  return {
    // id: payment.id,
    type: payment.type || '',
    _transactionId: payment.data?.id,
    method: payment.method || '',
    amount: payment.amount || '',
    date: payment.date || '',
    created: payment.created || '',
    notes: payment.notes || '',
    ..._.mapKeys(helpers.invoice.toCsv(invoice), (v, k) => `invoice.${k}`),
    ..._.mapKeys(helpers.user.toCsv(owner), (v, k) => `owner.${k}`),
    ...site && _.mapKeys(helpers.site.toCsv(site), (v, k) => `site.${k}`),
    ...unit && _.mapKeys(helpers.unit.toCsv(unit), (v, k) => `unit.${k}`),
    ...rental && _.mapKeys(helpers.unitRental.toCsv(rental), (v, k) => `rental.${k}`),
  };
}

