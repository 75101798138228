import ModalForm from '../../form2/modal-form';
import Field from '../../form2/horizontal-field';


export default function ValetOrderAddChargeForm(props) {
  const initialValue = {
    quantity: 1
  };

  return (
    <ModalForm
      wurdSection="valetOrder.addCharge"
      mode="create"
      initialValue={initialValue}
      {...props}
    >
      {(getProps) => (
        <>
          <Field {...getProps('desc')}
            required
            autoFocus
          />

          <Field {...getProps('price')}
            type="number"
            step="any"
            required
          />

          <Field {...getProps('quantity')}
            type="number"
            min="0"
            step="1"
            required
          />
        </>
      )}
    </ModalForm>
  );
}
