import { getLangText } from 'helpers/ui';
import wurd from 'wurd-react';

import * as helpers from 'helpers';
import { useItemLoader } from 'hooks';
import { editOrder, changeUnit, changeStartDate } from './actions';

import ListGroupItem from 'components/list-group-item';

const { currency, date } = helpers.ui;

const cms = wurd.block('unitOrder');

const editableSteps = [
  'await_submitOrder',
  'await_confirmOrder',
  'order_confirm',
];

const Label = ({ id }) => (
  <cms.Text id={`${id}.label`} />
);


export default function UnitMoveInJobSummary({
  job,
  unit,
  rental,
  modal,
  onChange,
}) {
  const {
    siteId,
    unitTypeId,
    startDate,
    unitDeposit,
    prepayPeriods,
    promoCodeId,
    customPromo,
    products = {},
  } = job.result;
  const { item: unitType } = useItemLoader('unit-types', unitTypeId, { include: ['customFields'] });
  const { item: site } = useItemLoader('sites', siteId, { include: ['customFields'] });

  const unitPrice = rental?.price ?? job.result.unitPrice;
  const { unitPromos } = helpers.settings.get();

  const promo = promoCodeId && unitPromos.find(promo => promo.id === promoCodeId);

  async function handleUpdateOrder() {
    editOrder({
      job,
      modal,
      onSuccess: onChange,
    });
  }

  async function handleEditStartDate() {
    changeStartDate({
      job,
      modal,
      onSuccess: onChange,
    });
  }

  async function handleEditUnit() {
    changeUnit({
      job,
      unit,
      modal,
      onSuccess: onChange,
    });
  }

  const filteredProducts = Object.fromEntries(Object.entries(products).filter(([k, v]) => v > 0));
  
  const productsSummary = Object.entries(filteredProducts).map(([productId, qty]) => {
    try {
      const product = site.products.find(p => p.id === productId);
      return `${getLangText(product.title)}: ${qty}`;
    } catch {
      return `[Deleted]: ${qty}`;
    }
  }).join(', ');
  
  const canEdit = editableSteps.includes(job.step);


  return (
    <div className="list-group">
      <ListGroupItem
        faIcon="far fa-warehouse"
        items={[
          {
            id: 'unitType',
            title: <Label id="unitType" />,
            text: helpers.ui.getLangText(unitType?.title),
          },
          ...unit ? [{
            id: 'unitId',
            title: <Label id="unitId" />,
            text: unit.name.toUpperCase(),
          }] : [],
        ]}
        onClickEdit={handleEditUnit}
      />

      <ListGroupItem
        faIcon="far fa-calendar"
        title={<Label id="startDate" />}
        text={date(startDate)}
        onClickEdit={handleEditStartDate}
      />

      <ListGroupItem
        faIcon={`far ${helpers.ui.currencyIcon()}`}
        items={[{
          id: 'deposit',
          title: <Label id="deposit" />,
          text: currency(unitDeposit)
        }, {
          id: 'price',
          title: <Label id="price" />,
          text: currency(unitPrice)
        }]}
        onClickEdit={canEdit && handleUpdateOrder}
      />

      <ListGroupItem
        faIcon="far fa-credit-card"
        items={[{
          id: 'prepayPeriods',
          title: <Label id="prepayPeriods" />,
          text: prepayPeriods ? `${prepayPeriods} months` : '-',
        }]}
        onClickEdit={canEdit && handleUpdateOrder}
      />

      <ListGroupItem
        faIcon="far fa-gift"
        items={[
          {
            id: 'promoCode',
            title: <Label id="promoCode" />,
            text: promo ? `${getLangText(promo.title)} [${promo.code}]` : '-',
          },
          ...customPromo ? [{
            id: 'customPromo',
            title: <Label id="customPromo" />,
            text: getLangText(customPromo.title),
          }] : [],
        ]}
        onClickEdit={canEdit && handleUpdateOrder}
      />

      <ListGroupItem
        faIcon="far fa-dolly"
        title={<Label id="products" />}
        text={productsSummary}
        onClickEdit={canEdit && handleUpdateOrder}
      />
    </div>
  );
}
