import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import Item from './item';
import Product from './product';


const PickAdded = createClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  },

  render() {
    return (
      <ul className="list-group">
        {this.renderItems()}
        {this.renderProducts()}
      </ul>
    );
  },

  /**
   * Renders picked empty boxes (unassigned items) and user items added to the visit
   */
  renderItems() {
    var visit = this.props.visit;
    var items = [].concat(visit.deliverBoxes, visit.deliverItems);
    var itemConfigs = helpers.item.byType(true);

    return items.map(item => {
      var itemConfig = itemConfigs[item.type] || {};

      return (
        <Item key={item.id} item={item} itemConfig={itemConfig}>
          {item.state === 'unassigned' &&
            <button
              type="button"
              className="btn btn-link"
              style={{ position: 'absolute', top: 8, right: 0, }}
              onClick={this.removeEmptyBox.bind(null, item)}
            >
              <i className="fa fa-times text-muted"></i>
            </button>
          }
          {item.state !== 'unassigned' && item.location}
        </Item>
      );
    });
  },

  /**
   * Renders the ordered products added to the visit
   */
  renderProducts() {
    const visit = this.props.visit;
    const products = visit.storeItems;
    const productConfigs = helpers.product.byId(true);

    return products.map(product => {
      const productConfig = productConfigs[product.type] || {};

      return (
        <Product key={product.id} product={product} productConfig={productConfig}>
          <button
            type="button"
            className="btn btn-link"
            style={{ position: 'absolute', top: 8, right: 0 }}
            onClick={this.removeProduct.bind(null, product)}
          >
            <i className="fa fa-times text-muted" />
          </button>
        </Product>
      );
    });
  },

  removeEmptyBox(item) {
    const { visit, onChange } = this.props;

    actions.visits.removeNewBox(visit.id, item.id)
      .then(onChange);
  },

  removeProduct(product) {
    const { t, visit, onChange } = this.props;

    if (!window.confirm(t('confirmRemoveStoreItem'))) return;

    actions.visits.removeProduct(visit.id, product.id)
      .then(onChange);
  }

});

export default PickAdded;
