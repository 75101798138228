import { useState } from 'react';
import wurd from 'wurd-react';
import { Link, useNavigate } from 'react-router-dom';
import Field from '../simple-form-field';

import { useApiForm } from 'hooks';
import * as actions from 'actions';
import * as helpers from 'helpers';
import * as config from 'config';
import ErrorMessage from 'components/error-message';
import Markdown from 'components/markdown';
import Captcha from 'plugins/recaptcha';


const cms = wurd.block('auth.resetPassword');

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const { fieldProps, submit, errMsg, loading } = useApiForm({
    cms,
    onSubmit: data => actions.auth.resetPassword({ ...data, token: new URLSearchParams(location.search).get('token') })
      .then(() => actions.auth.login(data)),
    onSuccess: () => {
      navigate('/');
      helpers.ui.notify({
        text: <><i className="fa fa-check" /> <cms.Text id="success.0" /></>,
        bsType: 'success',
        duration: wurd.editMode ? 0 : 5000,
      });
    },
  });

  return (
    <div className="sg-auth-login login-container logged-out-page">
      <div className="middle-login">
        <section className="block-flat">
          <div className="header text-center">
            <h3 className="brand"><img src={`${config.publicUrl}/logo.png`} alt="Storeganise" /></h3>
          </div>
          <div className="content">
            <h4 className="title"><cms.Text id="header" /></h4>

            <cms.Text id="intro" type="p" className="text-muted" style={{ marginBottom: 20 }} />

            <form onSubmit={submit}>
              <Field
                {...fieldProps('email')}
                type="email"
                icon="envelope"
                placeholder={wurd.text('auth.login.email.placeholder')}
                autoFocus
                autoComplete="username"
                required
              />

              <Field
                {...fieldProps('password')}
                type="password"
                icon="lock"
                placeholder={wurd.text('auth.login.password.placeholder')}
                help={wurd.text('auth.login.password.help') || 'At least 7 characters'}
                required
              />

              {errMsg &&
                <ErrorMessage icon="fa fa-times">
                  <Markdown as="span" inline>{cms.text(`errors.${errMsg}`) || errMsg}</Markdown>
                </ErrorMessage>
              }

              <button
                className="btn btn-primary btn-flat btn-block"
                type="submit"
                disabled={loading}
              >
                {loading
                  ? <i className="fa fa-spinner fa-spin" />
                  : <cms.Text id="submit" />
                }
              </button>
              <Captcha />
            </form>

            <hr />

            <Link to="/login" className="pull-right" style={{ marginBottom: 20 }}><cms.Text id="login" /></Link>
          </div>
        </section>
      </div>
    </div>
  );
}