/**
 * A filter which presents a simple dropdown style list.
 *
 * Expects children to be elements representing list items with a value prop, e.g. <li value="foo">Foo</li>
 */

import { Children } from 'react';
import PropTypes from 'prop-types';

import { ListGroup, ListGroupItem } from 'react-bootstrap';
import FilterButton from './button';


export default function ListFilter({
 title: baseTitle, active, onSelect, header, children, multi, ...props 
}) {
  let title = baseTitle;

  const selected = []; // mutated below in the Children.map

  const items = Children.map(children, child => {
    if (!child) return null;

    const value = child.props.value;
    const activeValues = Array.isArray(active) ? active : (active || null)?.split(',') || [];
    const isActive = activeValues.includes(value);

    if (value && isActive) selected.push(child.props.children);

    const onClick = e => {
      if (e.target.type === 'checkbox') {
        onSelect(isActive ? activeValues.filter(v => v !== value) : [...activeValues, value])
      } else {
        onSelect(value);
      }
    };

    // Create a menuitem with the onSelect handler
    return (
      <ListGroupItem
        active={isActive}
        onClick={onClick}
      >
        {multi && <input type="checkbox" checked={isActive} style={{ verticalAlign: 'top', width: 14, height: 14 }} />} {child.props.children}
      </ListGroupItem>
    );
  });

  // Set the dropdown title to the active item contents
  if (selected.length > 0) {
    title = <span>{title}: {selected.every(s => typeof s === 'string') ? selected.join(',') : selected}</span>;
  }

  return (
    <FilterButton {...props} title={title} active={(active || '').length > 0} onSelect={onSelect} className="filter--list">
      {header}
      <ListGroup>
        {items}
      </ListGroup>
    </FilterButton>
  )
}

ListFilter.propTypes = {
  title: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.any,    //The current filter value (null if not set), can be an array of multiple values
  header: PropTypes.node,
};
