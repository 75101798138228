 import { useState, useEffect } from 'react';
import PapaCsv from 'papaparse';
import wurd from 'wurd-react';
import countBy from 'lodash/countBy';
import pickBy from 'lodash/pickBy';

import * as helpers from '../helpers';
import * as actions from '../actions';

import { formatApiErrors } from '../hooks/api-form';

const cms = wurd.block('common.importBtn');

export default function ImportButton({ processRow, onComplete = () => {}, mode = 'create' }) {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();
  const [result, setResult] = useState([]);

  function downloadErrors(event) {
    const a = document.createElement('a');
    a.download = `import${new Date().toJSON().slice(0, 10)}-errors.csv`;
    const blob = new Blob([PapaCsv.unparse(errors, { escapeFormulae: true })], { type: 'text/plain' });
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  useEffect(() => {
    if (localStorage.getItem('open-csv-modal')) document.querySelector('input[accept=".csv"]').click(); // open directly for fast testing
  }, []);

  useEffect(() => {
    if (!Number.isInteger(count)) return;
    if (count >= files.length){
      setLoading(false);
      onComplete(result);
      return;
    }
    if (!loading){
      onComplete(result);
      return;
    }
    
    const [fileName, line, row] = files[count];
    const next = (v) => { setCount(count => count + 1); setResult(r => [...r, v]); };
    const handleErr = err => {
      const message = `${err.message}${err.errors ? ` [${Object.entries(formatApiErrors(err.errors)).map(([k, msg]) => `${k}: ${msg}`).join(', ')}]` : ''}`
      setErrors(errs => [...errs, { message, fileName, line }]);
    }

    if (mode === 'update' && !row.id) {
      handleErr(new Error('Column `id` is required for updates'));
      return;
    }

    return processRow(row).catch(handleErr).then(next);
  }, [loading, count]);

  function selectFiles(event) {
    const inputFiles = [...event.target.files];

    event.target.value = null; // Reset the file input to allow uploading again
    setErrors([]);
    setCount(undefined);

    Promise.all(
      inputFiles.map(file => new Promise(resolve => PapaCsv.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: resolve
        }))
          .then(result => {
            const rows = result.data.map(row => helpers.csv.parseKeys(pickBy(row))); // remove empty string to let default values work in api, for explicitely setting something empty or null, set null as value csv
            if (rows.length === 0) throw new Error('Empty file, no data found');
            return rows.map(row => [file.name, row]);
          })
          .catch(err => {
            setErrors(errs => [
              ...errs,
              ...err.errors
                ? err.errors.map(({ message, line }) => ({ message: `${err.message} [${message}]`, line, fileName: file.name }))
                : [{ message: err.message, line: '', fileName: file.name }]
            ]);
          })
      )
    )
      .then(groupedFiles => {
        const files = groupedFiles.flatMap(xs => xs.map(([name, row], i) => [name, i + 2, row]));

        const filesDesc = Object.entries(countBy(files, '0')).map(([name, count]) => `${name} (${count})`).join(', ');
        if (!window.confirm(cms.text(mode === 'update' ? 'confirmUpdate' : 'confirmCreate', { files: filesDesc }) || `Are you sure to ${mode === 'update' ? 'update' : 'import'} data with ${filesDesc}?`)) return;

        setFiles(files);
        setLoading(true);
        setCount(0);
    });
  }

  return (
    <cms.Object keys="create,update,confirmCreate,confirmUpdate,successMsg,errorMsg,downloadErrors">
      <label className="btn btn-default" disabled={loading}>
        <i className={loading ? 'fa fa-spinner fa-spin' : 'fa fa-upload'} />
        <cms.Text id={mode} style={{ marginLeft: 4 }} />
        <input
          type="file"
          accept=".csv"
          style={{ position: 'absolute', width: 0 }}
          onChange={selectFiles}
          autoFocus
          multiple
        />
      </label>

      {files.length > 0 && <progress value={Math.round(count * 100 / files.length)} max="100" style={{ display: 'block', margin: '6px auto 0' }}></progress>}
      <output style={{ paddingTop: 0 }}>
        {count > 0 && <p className="text-success">{cms.text('successMsg', { count: count - errors.length, total: files.length }) || `Imported ${count - errors.length} rows of ${files.length}`}</p>}
        {errors.length > 0 && (
          <>
            <pre className="text-danger" style={{ textAlign: 'left' }}>
              {errors.slice(-20).map(({ fileName, line, message }) => cms.text('errorMsg', { fileName, line, message }) || `${fileName}#${line}: ${message}`).join('\n')}{errors.length > 20 && '\n…'}
            </pre>
            <button className="btn btn-sm btn-danger" onClick={downloadErrors}><cms.Text id="downloadErrors" /></button>
          </>
        )}
      </output>
    </cms.Object>
  );
}