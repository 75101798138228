/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import classnames from 'classnames';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import { WurdObject } from 'wurd-react';
import Location from './location';
import Item from './item';

const { k, t } = helpers.cms.getters('visitView.collect');


const CollectStep = createClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired
  },

  render() {
    var { visit } = this.props;

    if (!helpers.visit.isCollection(visit)) return null;

    var step = 'delivered';

    return (
      <section className="panel">
        <div
          className={classnames('panel-heading', {
            success: helpers.visit.isStepComplete(visit, step)
          })}
        >
          <h4 className="panel-title">
            <a className={classnames({ collapsed: helpers.visit.isFutureStep(visit, step) })}>
              <WurdObject id={k()} keys="title,failedBtn,nextBtn">
                {t('title')}
              </WurdObject>
            </a>
          </h4>
        </div>
        {helpers.visit.isActiveStep(visit, step) && this.renderMain()}
      </section>
    );
  },

  renderMain() {
    const { visit } = this.props;

    const itemConfigs = helpers.item.byType(true);

    return (
      <div className="panel-body">
        <Location visit={visit} />

        <ul className="list-group">
          {visit.collectItems.map(item => {
            const itemConfig = itemConfigs[item.type] || {};

            return <Item key={item.id} item={item} itemConfig={itemConfig} />
          })}
        </ul>

        <div className="btn-group btn-group-justified">
          <div className="btn-group">
            <button type="button"
              className="btn btn-danger btn-flat"
              onClick={this.setFailed}
            >
              <i className="fa fa-times" />
              {t('failedBtn')}
            </button>
          </div>
          <div className="btn-group">
            <button type="button"
              className="btn btn-success"
              onClick={this.completeStep}
            >
              <i className="fa fa-check" />
              {t('nextBtn')}
            </button>
          </div>
        </div>
      </div>
    );
  },

  /*isActive() {
    return helpers.visit.isActiveStep(this.props.visit, 'delivered');
  },

  isComplete() {
    return helpers.visit.isStepComplete(this.props.visit, 'delivered');
  },*/

  setFailed() {
    const { visit, onChange } = this.props;

    actions.visits.update(visit.id, { state: 'failed-collection' })
      .then(onChange);
  },

  completeStep() {
    const { visit, onChange } = this.props;

    actions.visits.update(visit.id, { state: 'collected' })
      .then(onChange);
  }

});

export default CollectStep;
