import wurd from 'wurd-react';
import { useParams } from 'react-router-dom';

import store from '../store';
import * as helpers from '../helpers';
import { useModal } from '../hooks';

import DetailPage from '../components/detail-page';
import Spinner from '../components/spinner';
import NotFound from '../components/not-found';
import VisitsSection from '../components/settings/market/sections/visits';
import ChargesSection from '../components/settings/market/sections/charges';
import ItemsSection from '../components/settings/market/sections/items';
import PlansProductsSection from '../components/settings/market/sections/plans-products';
import History from '../components/settings/market/sections/history';
import { getLangText } from 'helpers/ui';

const cms = wurd.block('marketSettings');

const sectionComponents = {
  visits: VisitsSection,
  charges: ChargesSection,
  items: ItemsSection,
  'plans-products': PlansProductsSection,
  history: History,
};


export default function MarketSettings() {
  const { section = 'visits', marketName } = useParams();

  const modal = useModal();

  const { settings } = store.get();
  if (!settings) return <Spinner />;

  const SectionComponent = sectionComponents[section];
  if (!SectionComponent) return <NotFound />;

  const market = settings.markets.find(({ name }) => name === marketName);

  const sections = [
    ['visits', 'fas fa-truck'],
    ['charges', 'far fa-money-bill-alt'],
    ['items', 'fas fa-archive'],
    ['plans-products', 'fas fa-certificate'],
    ['history', 'fas fa-history'],
  ].map(([name, icon]) => {
    return {
      name,
      icon,
    };
  }).filter(Boolean);

  const title = getLangText(market.title);
  const baseUrl = `/settings/markets/${market.name}`;

  return (
    <DetailPage
      key={marketName} // make sure it's re-rendered when switching market
      modal={modal}
      cms={cms}
      userRole="manager"
      documentTitle={title}
      title={cms.text(`nav.${section}`)}
      ancestors={[
        { text: title, url: baseUrl },
      ]}
      sections={sections}
      currentSection={section}
      baseUrl={baseUrl}
    >
      <SectionComponent
        settings={settings}
        market={market}
      />
    </DetailPage>
  );
}
