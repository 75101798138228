import { WurdText } from 'wurd-react';

import * as helpers from 'helpers';

import ModalForm from '../modal-form';
import Field from '../horizontal-field';
import CustomField from 'plugins/custom-fields/field';

const wurdSection = 'user';


export default function UserForm({ mode = 'create', onSubmit, ...rest }) {
  return (
    <ModalForm
      wurdSection={wurdSection}
      mode={mode}
      onSubmit={data => {
        if (data.planId === '') data.planId = null;
        return onSubmit(data);
      }}
      {...rest}
    >
      {(getProps, data) => {
        const settings = helpers.settings.get();

        return (
          <div>
            <Field {...getProps('firstName')} required />

            <Field {...getProps('lastName')} required />

            <Field {...getProps('email')} type="email" required />

            <Field {...getProps('address')}>
              <textarea></textarea>
            </Field>
            <Field {...getProps('phone')} />
            <Field {...getProps('companyName')} />

            {settings.userCustomFields?.length > 0 && (
              <details>
                <summary><WurdText id={`${wurdSection}.modal.sections.customFields`} type="h4" style={{ display: 'inline-block' }} /></summary>
                {settings.userCustomFields.sort(helpers.ui.sortByCode).map(({ code, ...cfProps }) => (
                  <CustomField
                    key={code}
                    {...getProps(`customFields.${code}`)}
                    {...cfProps}
                  />
                ))}
              </details>
            )}
          </div>
        );
      }}
    </ModalForm>
  );
}
