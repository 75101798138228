import { useEffect } from 'react';
import wurd, { WurdText } from 'wurd-react';
import { useQuery } from 'react-query';
import { MenuItem } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import _ from 'lodash';
import * as actions from 'actions';
import * as helpers from 'helpers';
import { currentLang } from 'utils/language';
import { useApiForm } from 'hooks';
import { Field, ModalForm2, MultiLangText } from 'components/form2';
import Section from 'components/section';
import MenuButton from 'components/menu-button';
import Empty from 'components/empty';
import Markdown from 'components/markdown';
import StateLabel from 'components/state-label';
import Spinner from 'components/spinner';
import MetaForm from './meta';

import aliases, * as blocks from './blocks';
import Alert from 'components/alert';


const cms = wurd.block('website.page');


export default function WebsitePageEditor({ path, modal, pages, fetchPages }) {
  const settings = helpers.settings.get();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    refetch,
  } = useQuery('website-' + path, () => actions.business.website.fetch(path));

  const form = useApiForm({
    wurdSection: cms.id(),
    initialValue: data,
    onSubmit: (data) => actions.business.website.update(path, data),
    onSuccess: refetch,
    onDone: () => { sessionStorage.removeItem('website-' + path); },
  });

  useEffect(() => {
    if (!data) return; // wait for load

    const localChanges = sessionStorage.getItem('website-' + path);
    if (localChanges) {
      form.setFormValue(JSON.parse(localChanges));
    }

    if (!data.blocks && !data.enabled) {
      form.setFormValue({ enabled: true }); // new page, pre-set this change
    }
  }, [!data]);

  const page = form.formValue;

  useEffect(() => {
    if (form.dirty) {
      sessionStorage.setItem('website-' + path, JSON.stringify(form.formValue));
    }
  }, [form.formValue]);

  useBeforeunload(e => {
    if (form.dirty) {
      e.preventDefault();
      sessionStorage.removeItem('website-' + path);
    }
  });

  function toggleEnabled() {
    form.setFormValue({ enabled: !page.enabled });
  }

  function deletePage() {
    if (!helpers.ui.confirm(cms.text('menu.confirmDelete', { path:'/' + path }))) return;
    return actions.business.website.delete(encodeURIComponent(path))
      .then(() => navigate('/settings/website'))
      .then(fetchPages);
  }

  function openTypeModal({
    mode = 'update',
    type,
    onChange,
    block_prefix,
  }) {
    modal.open(
      <>
        <div className="modal-header">
          <button type="button" className="close" onClick={modal.close}>×</button>
          <cms.Text id={`editType.title_${mode}`} type="h4" />
        </div>
        <div className="modal-body">
          <div style={{ columns: 2, gap: '2rem' }}>
            <cms.List id="types" keys="type,title,preview,defaultContent">
              {(item) => {
                const t = item.get('type') || '';
                if (block_prefix && !t.startsWith(block_prefix)) return null;
                if (!block_prefix && t.startsWith('header') && page.header) return null;
                if (!block_prefix && t.startsWith('footer') && page.footer) return null;
                if (t.startsWith('sites') && !settings.modes?.includes('selfStorage')) return null;

                return (
                  <div key={item.id()} style={{ breakInside: 'avoid-column', marginBottom: '1.5rem' }}>
                    <strong style={{ padding: 2 }}>{wurd.editMode ? <item.Text id="title" /> : (item.text('title') || aliases[t] || t)}</strong>
                    <fieldset
                      style={{ border: '1px dashed #dadada', cursor: 'pointer', outline: t === type ? '1px auto var(--bs-primary)' : null }}
                      onClick={() => { onChange(t, item.text('defaultContent')); if (!wurd.editMode) modal.close(); }}
                    >
                      <item.Image id="preview" style={{ width: '100%' }} />
                      {wurd.editMode && <item.Text id="defaultContent" type="pre" style={{ whiteSpace: 'pre-wrap', resize: 'both' }} />}
                    </fieldset>
                  </div>
                );
              }}
            </cms.List>
          </div>
        </div>
      </>,
      { bsSize: 'lg' }
    );
  }

  function addBlock() {
    openTypeModal({
      mode: 'create',
      onChange: (type, defaultContentJSON) => {
        let defaultContent;
        try {
          defaultContent = JSON.parse(
            defaultContentJSON
              ?.replace(/\[lang\]/g, `"${currentLang}"`) // deprecated, will remove soon
              .replace(/{{lang}}/g, currentLang)
              .replace(/{{brand}}/g, settings.brand.linkColor)
          );
        } catch {}

        return form.setFormValue(
          type.startsWith('header')
            ? { header: { ...defaultContent, type } }
            : type.startsWith('footer')
              ? { footer: { ...defaultContent, type } }
              : { blocks: [...page.blocks || [], { ...defaultContent, type }] }
        );
      }
    });
  }

  function showMetaModal() {
    modal.open(
      <MetaForm
        initialValue={{ meta: data?.meta, path }}
        onSubmit={async (fullData, { path: newPath, meta }) => {
          if (meta) {
            await actions.business.website.update(path, { ...form.formValue, meta }).then(refetch);
          }

          if (newPath !== undefined && fullData.path !== path) {
            await actions.business.website.update(path, { path: newPath })
              .then(() => {
                const localChanges = sessionStorage.getItem('website-' + path);
                if (localChanges) {
                  sessionStorage.setItem('website-' + newPath, localChanges);
                  sessionStorage.removeItem('website-' + path);
                }
              })
              .then(() => navigate(`/settings/website/${newPath || '-'}`))
              .then(refetch);
          }
        }}
        closeModal={modal.close}
        mode="update"
      />
    );
  }

  function showPageData() {
    modal.open(
      <form
        className="modal-body"
        onSubmit={e => {
          e.preventDefault();
          form.setFormValue(JSON.parse(e.target.page.value));
          modal.close();
        }}
      >
        <textarea name="page" autoFocus defaultValue={JSON.stringify(page, null, 2)} className="form-control" rows={30} />

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <cms.Text id="data.help" markdown inline className="text-muted" />
          <button type="submit" className="btn btn-primary mb-0 mt-2"><WurdText id="common.saveBtn" /></button>
        </div>
      </form>,
      { bsSize: 'lg' }
    );
  }

  function moveBlock(i, j) { // move page.blocks[i] as j-th position
    if (i == j) return;
    
    const blocks = [...page.blocks]; // copy
    blocks.splice(i, 1); // remove i-th
    blocks.splice(j, 0, page.blocks[i]); // add item at j-th

    return form.setFormValue({ ...page, blocks });
  }

  function BlockField({ block_prefix, i, style }) {
    const block = block_prefix ? page[block_prefix] : page.blocks[i];
    const { default: BlockComponent, edit } = blocks[block.type] || {};

    const setBlock = i !== undefined
      ? data => form.setFormValue({ blocks: { [i]: data } })
      : data => form.setFormValue({ [block_prefix]: data });

    const deleteBlock = () => {
      if (!helpers.ui.confirm(cms.text('block_menu.confirmDelete'))) return;
      return i !== undefined
        ? form.setFormValue({ blocks: page.blocks.filter((_, j) => j !== i) })
        : form.setFormValue({ [block_prefix]: null });
    };

    const editType = () => openTypeModal({
      type: block.type,
      block_prefix,
      onChange: type => setBlock({ ...block, type }),
    });

    return (
      <div style={{ margin: '1rem 0 2.5rem', position: 'relative' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: -8 }}>
          <small className="text-muted" style={{ flex: 1 }}>{aliases[block.type] || block.type}</small>
          {edit && (
            <button
              onClick={() => edit({ block, setBlock, modal })}
              className="btn btn-link btn-sm"
              style={{ padding: '7px 4px' }}
            >
              <i className="far fa-fw fa-pen" />
            </button>
          )}
          <MenuButton
            cms={cms.block('block_menu')}
            cmsKeys="moveUp,moveDown,moveTop,editType,deleteBlock,confirmDelete"
            bsSize="sm"
          >
            {i > 0 && (
              <MenuItem key="moveUp" onSelect={() => moveBlock(i, i - 1)}>
                <i className="far fa-fw fa-angle-up" />&nbsp;&nbsp;&nbsp;{cms.text('block_menu.moveUp')}
              </MenuItem>
            )}
            {i < page.blocks?.length - 1 
              ? (
                <MenuItem key="moveDown" onSelect={() => moveBlock(i, i + 1)}>
                  <i className="far fa-fw fa-angle-down" />&nbsp;&nbsp;&nbsp;{cms.text('block_menu.moveDown')}
                </MenuItem>
              ) : i === page.blocks?.length - 1 && (
                <MenuItem key="moveTop" onSelect={() => moveBlock(i, 0)}>
                  <i className="far fa-fw fa-angle-double-up" />&nbsp;&nbsp;&nbsp;{cms.text('block_menu.moveTop') || 'Move top'}
                </MenuItem>
              )}
            <MenuItem key="editType" onSelect={editType}>
              <i className="far fa-fw fa-exchange" />&nbsp;&nbsp;&nbsp;{cms.text('block_menu.editType')}
            </MenuItem>
            <MenuItem key="deleteBlock" onSelect={deleteBlock}>
              <i className="far fa-fw fa-trash" />&nbsp;&nbsp;&nbsp;{cms.text('block_menu.deleteBlock')}
            </MenuItem>
          </MenuButton>          
        </div>
        <fieldset style={{ border: '1px dashed #dadada', ...style }}>
          {BlockComponent
            ? <BlockComponent block={block} setBlock={setBlock} modal={modal} />
            : <Alert type="danger">{`Block type "${block.type}" not found`}</Alert>}
        </fieldset>
      </div>
    );
  }


  return (
    <Section
      cms={cms}
      title={(
        <>
          <cms.Text id="title" vars={{ path: path ? 'p/' + path : path }} />
          {form.dirty && <span style={{ opacity: .7 }} title={cms.text('unsaved') || 'Unsaved'}>*</span>}
        </>
      )}
      extra={
        <>
          <a href={data?.enabled ? `${location.origin}/${path ? 'p/' + path : path}` : null} target="_blank">
            <StateLabel type={page.enabled ? 'success' : 'secondary'}>
              <i className={`far ${page.enabled ? 'fa-external-link' : 'fa-ban'}`} />
              &nbsp;&nbsp;<cms.Text id={`enabled.${!!page.enabled}`} />
              {form.dirty?.includes('enabled') ? ' *' : null}
            </StateLabel>
          </a>

          <MenuButton
            cms={cms.block('menu')}
            cmsKeys="meta,import,editPath,promptPath,deletePage,confirmDelete"
          >
            <MenuItem key="toggleEnabled" onSelect={toggleEnabled}>
              <i className={`far fa-fw ${page.enabled ? 'fa-eye-slash' : 'fa-check'}`} />&nbsp;&nbsp;&nbsp;{cms.text(`menu.${page.enabled ? 'disable' : 'enable'}`)}
            </MenuItem>
            <MenuItem key="meta" onSelect={showMetaModal}>
              <i className="far fa-fw fa-pen" />&nbsp;&nbsp;&nbsp;{cms.text('menu.meta')}
            </MenuItem>
            <MenuItem key="import" onSelect={showPageData}>
              <i className="far fa-fw fa-code" />&nbsp;&nbsp;&nbsp;{cms.text('menu.import')}
            </MenuItem>
            <MenuItem key="deletePage" onSelect={deletePage}>
              <i className="far fa-fw fa-trash" />&nbsp;&nbsp;&nbsp;{cms.text('menu.deletePage')}
            </MenuItem>
          </MenuButton>
        </>
      }
    >
      {page.header && (
        <BlockField
          key="header"
          block_prefix="header"
        />
      )}

      {isLoading ? <Spinner /> : !page.blocks?.length && <div className="clear" style={{ margin: '6rem 0 2rem' }}><Empty cms={cms.block('empty')} /></div>}

      {page.blocks?.map?.((block, i) => {
        return (
          <BlockField
            key={'main-' + i}
            i={i}
            style={{ backgroundColor: block.variant === 'white' ? '#fff' : '#f8f8f8' }}
          />
        );
      })}

      {!isLoading && (
        <div style={{ margin: '3rem 0' }}>
          <button className="btn btn-link" onClick={addBlock} style={{ width: '100%', textAlign: 'center', border: '1px dashed var(--bs-primary)', backgroundColor: '#f8f8f8' }}>
            <i className="far fa-plus" /> <cms.Text id="addBlock" />
          </button>
        </div>
      )}

      {page.footer && (
        <BlockField
          key="footer"
          block_prefix="footer"
          style={{ backgroundColor: '#f8f8f8' }}
        />
      )}

      <button
        className="btn btn-primary pull-right"
        style={{
          marginTop: '1em',
          bottom: '1rem',
          zIndex: 1,
          ...form.dirty && { position: 'sticky', boxShadow: '0 0 1em 0 #3680BF60' },
        }}
        type="button"
        disabled={form.loading || !form.dirty}
        onClick={form.submit}
      >
        {form.loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>

      <datalist id="website-pages">
        <option value="/">{helpers.ui.getLangText(pages?.find(p => p.path === '')?.meta?.title) || cms.text('urls.home')}</option>
        {settings.modes?.includes('selfStorage') && <option value="/sites">{cms.text('urls.unitsApp')}</option>}
        {settings.modes?.includes('valetStorage') && <option value="/valet">{cms.text('urls.valetApp')}</option>}
        {pages?.filter(page => page.path).map(page => <option key={page.path} value={`${page.path ? '/p/' : '/'}${page.path}`}>{helpers.ui.getLangText(page.meta?.title)}</option>)}
      </datalist>
    </Section>
  );
}