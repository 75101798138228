import { pick } from 'lodash';

import ModalForm from 'components/form2/modal-form';
import { Field, ImageInput } from 'components/form2';

const fields = [
  'image',
];


export default function SiteImageForm({
  addonConfig,
  ...rest
}) {

  return (
    <ModalForm
      {...rest}
      initialValue={pick(addonConfig, fields)}
      wurdSection="site"
    >
      {fieldProps =>
        <>
          <Field {...fieldProps('image')}>
            <ImageInput />
          </Field>
        </>
      }
    </ModalForm>
  );
}
