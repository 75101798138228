/**
 * Main app layout for logged in users - used for main pages
 */
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import * as actions from '../actions';
import errHandler from '../actions/_err-handler';
import store from '../store';
import { usePrevious } from '../hooks';

import { Modal } from 'react-bootstrap';
import Navbar from './navbar';
import Spinner from './spinner';
import LoginModal from './login/modal';


const cms = wurd.block('auth.login');


const SmallModal = styled(Modal)({
  '.modal-dialog': {
    width: 'min(calc(100vw - 20px), 430px)'
  }
});


export default function App_LoggedIn() {
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(null);
  const { currentUser, settings } = store.get();

  useEffect(() => {
    Promise.allSettled([
      actions.auth.fetchUser(),
      actions.business.fetch(),
    ]);

    /**
     * Opens the login modal for when users need to authenticate
     */
    errHandler.openLogin = (err) => setLoginModal(
      <LoginModal
        closeModal={() => setLoginModal(null)}
        err={err}
      />
    );
  }, []);

  // // track page changes only (not ?search changes used in list filters), but we opted to move this logic in nested-link rather
  // const location = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location.pathname]);

  function logout() {
    actions.auth.logout()
      .then(() => {
        navigate('login')
      });
  }

  return (
    <>
      {
        (currentUser && settings)
          ? (
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
              <Navbar
                currentUser={currentUser}
                modes={settings.modes}
                markets={settings.markets}
                onLogout={logout}
              />

              <div style={{ paddingTop: 50/*header is fixed*/, flex: 1 }}><Outlet /></div>
            </div>
          )
          : <Spinner />
      }

      <SmallModal show={!!loginModal}>
        {loginModal}
      </SmallModal>
    </>
  );
}
