import loadScript from 'load-script2';
import store from 'store';


const actions = {};

actions.update = async function (user) {
  const settings = store.get('settings');

  if (typeof window.Beacon !== 'function') {
    window.Beacon = function (t, n, a) {
      window.Beacon.readyQueue.push({ method: t, options: n, data: a });
    };
    window.Beacon.readyQueue = [];

    await loadScript('https://beacon-v2.helpscout.net');

    window.Beacon('init', 'efa47d22-604d-45d3-8c93-54a4ce27c3ed');
  }

  user && settings && window.Beacon?.('identify', {
    name: user.name,
    email: user.email,
    roles: user.roles?.join(', '),
    company: settings.companyName,
    businessCode: settings.code,
    businessMode: settings.modes.sort().join('+'),
    sgAppVersion: window.app_version,
    signature: user.helpScoutVerification,
    userAgentData: JSON.stringify(navigator.userAgentData),
  });
};


actions.open = function () {
  window.Beacon?.('open');
};


actions.shutdown = function () {
  window.Beacon?.('destroy');
};


export default actions;
