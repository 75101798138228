import PropTypes from 'prop-types';
import wurd, { WurdText, WurdMarkdown } from 'wurd-react';
import _ from 'lodash';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import BillingPlugin, { UserBillingAdd } from '../../plugins/billing';
import CustomFieldsSection from '../../plugins/custom-fields/section';
import Section from '../section';
import UserForm from '../form2/user';
import Note from './note';
import UserJobs from './jobs';
import StateLabel from '../state-label';
import AdminEmails from './admin-emails';
import DetailList from 'components/detail-list';

const cms = wurd.block('userView.details');
const { hasRole } = helpers.auth;
const t = wurd.text;

const Label = ({ id }) => (
  <WurdText type="dt" id={`user.${id}.label`} />
);


export default function UserDetails({
  settings,
  user,
  onChange,
  openModal,
  closeModal,
  modal,
}) {
  const valetStorage = settings.modes?.includes('valetStorage');
  const sectionFields = {
    main: ['firstName', 'lastName', 'email', 'language', 'address', ...valetStorage ? ['area'] : [], 'phone', 'companyName'],
    valet: [], // Filled in below depending on valet features enabled in the business
    billing: ['promoCode', 'invoiceDate', 'billingMethod', 'billingTrigger'],
    deprecated: ['personalId', 'phone2', 'companyId', 'externalId', 'altContactName', 'altContactAddress', 'altContactPhone', 'altContactEmail'].filter(field => user[field]),
    roles: ['roles', 'permissions'],
  };

  // Define valet fields
  if (helpers.market.hasMultiple()) sectionFields.valet.push('marketId');
  if (helpers.plan.isEnabled()) sectionFields.valet.push('planId');

  const editSection = (section) => {
    // Make sure the correct market is selected to begin with
    const defaultValue = { ...user, marketId: user.market.id };

    openModal(
      <UserForm
        initialValue={defaultValue}
        onSubmit={data => actions.users.update(user.id, data)}
        onSuccess={onChange}
        submitButton={<WurdText id="userView.details.updateUser" />}
        closeModal={closeModal}
        fields={sectionFields[section]}
        closeIcon={null}
        title={null}
      />,
    );
  };

  return (
    <>
      <Note
        cms={wurd.block('userView.note')}
        user={user}
        onChange={onChange}
      />

      <UserJobs
        user={user}
      />

      {sectionFields.deprecated.length > 0 && (
        <Section
          cms={cms.block('deprecatedFields')}
          onClickEdit={() => editSection('deprecated')}
        >
          <dl className="dl-horizontal">
            {sectionFields.deprecated.map(field => (
              <>
                <Label id={field} />
                <dd>{user[`${field}`]}</dd>
              </>
            ))}
          </dl>
        </Section>
      )}

      <Section
        cms={cms}
        onClickEdit={() => editSection('main')}
      >
        <DetailList>
          <Label id="fullName" />
          <dd>{user.firstName} {user.lastName}</dd>


          <Label id="email" />
          <dd><a href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer" {...helpers.user.emailConfirmedProps(user)}>{user.email}</a></dd>

          <Label id="language" />
          <dd>{t(`common.languages.${user.language}`)}</dd>

          <Label id="address" />
          <dd style={{ whiteSpace: 'pre-line' }}>{user.address}</dd>

          {valetStorage && (
            <>
              <Label id="area" />
              <dd>{helpers.area.getTitle(user.area)}</dd>
            </>
          )}

          <Label id="phone" />
          <dd>{user.phone && <a href={`tel:${user.phone.replace(/\s/g, '')}`} target="_blank" rel="noopener noreferrer">{user.phone}</a>}</dd>

          <Label id="companyName" />
          <dd>{user.companyName}</dd>
        </DetailList>
      </Section>

      <CustomFieldsSection
        cms={cms.block('customFields')}
        modelName="user"
        model={user}
        onChange={onChange}
        modal={{ open: openModal, close: closeModal }}
      />

      <Section
        cms={cms.block('billing')}
        onClickEdit={() => editSection('billing')}  
      >
        <DetailList>
          {settings.modes.includes('valetStorage') && (
            <>
              <Label id="referCode" />
              <dd>{user.referCode.toUpperCase()}</dd>
            </>
          )}

          <Label id="invoiceDate" />
          <dd>{user.invoiceDate || '-'}</dd>

          <Label id="billingMethod" />
          <WurdText type="dd" id={`user.billingMethods.${user.billingMethod}`} />

          <Label id="billingTrigger" />
          <WurdText type="dd" id={`user.billingTriggers.${user.billingTrigger}`} />

          <UserBillingAdd
            user={user}
            method={settings.billing}
            onChange={onChange}
          >
            {content => content && (
              <>
                <Label id="billingId" />
                <dd>
                  {content}
                </dd>
              </>
            )}
          </UserBillingAdd>

          <Label id="paymentMethod" />
          <dd style={{ fontSize: 15 }}>
            <BillingPlugin
              user={user}
              method={settings.billing}
              onChange={onChange}
            />
          </dd>
        </DetailList>
      </Section>

      {settings.modes.includes('valetStorage') && (
        <Section
          cms={cms.block('valet')}
          onClickEdit={() => editSection('valet')}         
        >
          <DetailList> 
            {sectionFields.valet.includes('marketId') && (
              <>
                <Label id="marketId" />
                <dd>{helpers.market.getTitle(user.market.id)}</dd>
              </>
            )}

            {sectionFields.valet.includes('planId') && (
              <>
                <Label id="planId" />
                <dd>
                  {user.planId
                    ? helpers.plan.getTitle(user.planId)
                    : <WurdText id="user.plans.none" />
                  }
                </dd>
              </>
            )}
          </DetailList>
        </Section>
      )}

      <Section
        cms={cms.block('metadata')}
        onClickEdit={hasRole('manager') && function () { editSection('roles') }}
      >
        <DetailList>
          <Label id="id"  />
          <dd>{user.id}</dd>

          <Label id="created" />
          <dd>{helpers.ui.date(user.created)}</dd>

          <Label id="roles" />
          <dd>
            {user.roles.map(role => (
              <StateLabel type="warning" key={role}>
                {wurd.text(`user._roles.${role}`)}
              </StateLabel>
            ))}

            {user.permissions?.map(permission => (
              <StateLabel type="warning" key={permission} style={{ filter: 'sepia(1)' }}>
                {permission}
              </StateLabel>
            ))}
            </dd>
        </DetailList>
      </Section>

      {user.roles.length > 0 && (
        <AdminEmails user={user} />
      )}
    </>
  );
}

UserDetails.propTypes = {
  settings: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
