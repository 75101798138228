import * as helpers from '../../../helpers';

import ListGroupItem from '../../list-group-item';
import StepCharges from './step-charges';


const formatDate = helpers.ui.date;
const { formatTimeslot } = helpers.valetOrder;


function formatAddressForMap(address = '') {
  return address.replace(/\n/g, ',');
}

function getMapURL(address) {
  return `https://maps.google.com/maps?daddr=${formatAddressForMap(address)}`;
}


export default ({
  valetOrder,
  step,
  modal,
  fetchValetOrder,
  appointmentType = null,
}) => {
  const { address, phone, altPhone, instructions } = valetOrder;

  const appointment = appointmentType
    ? helpers.valetOrder.getAppointments(valetOrder).find(({ type }) => type === appointmentType)
    : null;

  return (
    <div style={{ marginBottom: 20 }}>
      <div style={{ marginLeft: -15, marginRight: -15, marginTop: -15, marginBottom: 8 }}>
        <iframe
          title="Location"
          src={`https://www.google.com/maps?q=${formatAddressForMap(address)}&output=embed`}
          width="100%"
          height="300"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>

      <div className="list-group">
        {appointment && (
          <ListGroupItem
            faIcon="far fa-clock"
            text={`${formatDate(appointment.date)}, ${formatTimeslot(appointment.timeslot)}`}
          />
        )}
        <ListGroupItem
          faIcon="far fa-map-marked-alt"
          text={(
            <div style={{ whiteSpace: 'pre-line' }}>
              {address}
            </div>
          )}
          action={<a className="btn btn-link" target="_blank" rel="noopener noreferrer" href={getMapURL(address)}>Navigate</a>}
        />
        <ListGroupItem
          faIcon="far fa-phone-alt"
          text={phone}
          action={<a className="btn btn-link" target="_blank" rel="noopener noreferrer" href={`tel:${phone}`}>Call</a>}
        />
        {altPhone && (
          <ListGroupItem
            faIcon="far fa-phone-alt"
            text={altPhone}
            action={<a className="btn btn-link" target="_blank" rel="noopener noreferrer" href={`tel:${altPhone}`}>Call</a>}
          />
        )}
        {instructions && (
          <ListGroupItem
            faIcon="far fa-info-circle"
            text={(
              <div style={{ whiteSpace: 'pre-line' }}>
                {instructions}
              </div>
            )}
          />
        )}
        {step && (
          <StepCharges
            valetOrder={valetOrder}
            step={step}
            modal={modal}
            fetchValetOrder={fetchValetOrder}
          />
        )}
      </div>
    </div>
  );
};
