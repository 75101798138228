import wurd from 'wurd-react';
import * as actions from 'actions';
import * as helpers from 'helpers';

import ModalForm from '../modal-form';
import Field from '../horizontal-field';
import Toggle from '../toggle';

import { adminPermissions } from '.';


const cms = wurd.block('user');

export default function AdminForm({ mode = 'create', onSubmit, ...rest }) {
  return (
    <ModalForm
      wurdSection={cms.id()}
      mode={mode}
      onSubmit={async (fullData, data) => {
        let user;

        if (mode === 'create') {
          try {
            user = await actions.users.create(fullData);
          } catch (err) {
            if (err.status !== 409) throw err;

            user = await actions.users.update(fullData.email, fullData);
          }
        } else { // update
          user = await actions.users.update(fullData.id, data);
        }

        if (fullData.sendInvite) await actions.users.invite(user.id);
      }}
      {...rest}
    >
      {(getProps, data) => {
        return (
          <div>
            <Field {...getProps('firstName')} required />

            <Field {...getProps('lastName')} required />

            <Field {...getProps('email')} type="email" required />

            <Field {...getProps('roles')}>
              <select multiple>
                {['driver', 'operations', 'admin', 'manager'].map(role =>
                  <option key={role} value={role}>{cms.text(`_roles.${role}`)}</option>
                )}
              </select>
            </Field>

            {helpers.auth.hasRole('admin', data) && (
              <Field {...getProps('permissions')} disabled={!helpers.auth.hasRole('_support')}>
                <select multiple>
                  {adminPermissions.map(permission =>
                    <option key={permission} value={permission}>{permission}</option>
                  )}
                </select>
              </Field>
            )}

            <Field {...getProps('sendInvite')} type="checkbox" className="btn btn-link mb-0">
              <Toggle />
            </Field>
          </div>
        );
      }}
    </ModalForm>
  );
}