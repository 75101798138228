import Activity from '../../activity';


export default function UnitMoveInJobHistory({ job }) {
  return (
    <Activity
      context="site"
      endpoint={`/jobs/${job.id}/actions`}
    />
  );
}
