import * as helpers from '../../../helpers';

import { Line } from 'react-chartjs-2';

const { colors } = helpers.ui;

const types = {
  'moveIns': {
    title: 'Completed move-ins',
    color: colors.green,
  },
  'moveOuts': {
    title: 'Completed move-outs',
    color: colors.red,
  },
};


export default function SiteJobsChart({
  site,
  reports,
}) {
  const data = reports.reduce((memo, report) => {
    memo.dates.push(report.date);
    
    Object.keys(types).forEach(type => memo[type].push(report.jobs[type]));

    return memo;
  }, {
    dates: [],
    moveIns: [],
    moveOuts: [],
  });

  const datasets = Object.entries(types).map(([type, config]) => ({
    label: config.title,
    data: data[type],
    borderColor: config.color,
    backgroundColor: config.color,
  }));

  return (
    <Line
      data={{
        labels: data.dates,
        datasets,
      }}
    />
  );
}
