/**
 * Renders the given item; if only an ID is passed then it will be fetched
 */
import { Component } from 'react';
import PropTypes from 'prop-types';

import * as actions from '../actions';

import StaticInvoiceList from './invoice-list/static-list';


export default class Invoice extends Component {
  static propTypes = {
    id: PropTypes.string,
    invoice: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.state = {
      invoice: props.invoice || null,
    };
  }

  componentDidMount() {
    const { invoice, id } = this.props;

    if (invoice) return;

    actions.fetchOne('invoices', id).then((invoice) => this.setState({ invoice }));
  }

  render() {
    const { invoice } = this.state;

    if (!invoice) {
      return (
        <div>Loading...</div>
      );
    }

    return (
      <StaticInvoiceList
        invoices={[invoice]}
        hideOwner
        hideUnit
      />
    );
  }
}
