import { Button } from 'react-bootstrap';
import wurd from 'wurd-react';

import store from 'store';

import ModalForm from 'components/form2/modal-form';
import Field from 'components/form2/horizontal-field';
import MultiLangText from 'components/form2/multi-lang-text';
import DatePicker from 'components/form2/datepicker';
import AccountingCodeSelect from 'components/form2/accounting-code-select';
import AsyncSelect from 'components/form2/async-select';

const cms = wurd.block('business.unitPromo');

const headerStyle = {
  fontSize: '90%',
  textAlign: 'center',
};


function RuleItem({ name, value, currency = '', onChange, onDelete }) {
  return (
    <cms.Object keys="rule.type.discount,rule.type.pctDiscount" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginLeft: '33%', paddingLeft: 12 }}>
      <select name="type" value={value.type} onChange={e => onChange({ ...value, type: e.target.value })} className="form-control" style={{ width: 150 }}>
        <option value="discount">{cms.text('rule.type.discount', { currency })}</option>
        <option value="pctDiscount">{cms.text('rule.type.pctDiscount', { currency })}</option>
      </select>
      <input
        name="amount" className="form-control" style={{ width: 60 }}
        value={value.amount} onChange={e => onChange({ ...value, amount: e.target.value })}
        type="number" step={value.type === 'discount' ? '0.01' : undefined}
      />
      <input
        name="startMonth" className="form-control" style={{ width: 60 }}
        value={value.startMonth} onChange={e => onChange({ ...value, startMonth: e.target.value || undefined })}
        type="number" step="1" min="0"
      />
      <input
        name="endMonth" className="form-control" style={{ width: 60 }}
        value={value.endMonth} onChange={e => onChange({ ...value, endMonth: e.target.value || undefined })}
        type="number" min={value.startMonth || 0} step="1"
      />
      <Button bsStyle="link" onClick={onDelete}><i className="fas fa-times" /></Button>
    </cms.Object>
  );
}


export default function UnitPromoForm({ settings, extraFields, ...props }) {
  const { invoicePeriod } = store.get('settings');

  return (
    <ModalForm
      wurdSection="unit.promos"
      {...props}
      initialValue={{
        ...props.initialValue,
        rules: props.initialValue?.rules || [{ type: 'pctDiscount', amount: 15, startMonth: 1 }],
        siteIds: props.initialValue?.siteIds || [],
      }}
    >
      {(getProps) => {
        const rulesProps = getProps('rules');

        function onRulesChange(rules) {
          rulesProps.onChange({
            target: {
              name: 'rules',
              value: rules,
            }
          })
        }

        return (
          <div>
            <Field {...getProps('code')} required include={extraFields?.includes('code')} autoFocus />

            <Field {...getProps('title')} required autoFocus={extraFields?.includes('code') !== true}>
              <MultiLangText />
            </Field>

            <Field {...getProps('validFrom')} include={extraFields?.includes('validFrom')}>
              <DatePicker minDate={undefined} />
            </Field>

            <Field {...getProps('validTo')} include={extraFields?.includes('validTo')}>
              <DatePicker minDate={undefined} />
            </Field>

            <Field {...getProps('siteIds')} include={extraFields?.includes('siteIds')} className={null}>
              <AsyncSelect endpoint="sites" />
            </Field>

            <Field {...getProps('accountingCodeId')}>
              <AccountingCodeSelect />
            </Field>

            <Field {...getProps('rules')}>
              <Button
                onClick={() => onRulesChange([...rulesProps.value || [], { type: 'pctDiscount', amount: 15, startMonth: 1 }])}
                disabled={rulesProps.value?.length >= 4}
                bsStyle="link"
                style={{ textAlign: 'right', width: 40, float: 'right' }}
              >
                <i className="fas fa-plus" />
              </Button>
            </Field>

            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end', marginLeft: '33%', marginRight: 37, paddingLeft: 12 }}>
              <cms.Text id="rules.header.type" type="em" style={{ width: 150, ...headerStyle }} />
              <cms.Text id="rules.header.amount" type="em" style={{ width: 60, ...headerStyle }} />
              <cms.Text id="rules.header.startPeriod" vars={{ period: wurd.text(`common.phrases.${invoicePeriod}`) }} type="em" style={{ width: 60, ...headerStyle }} />
              <cms.Text id="rules.header.endPeriod" vars={{ period: wurd.text(`common.phrases.${invoicePeriod}`) }} type="em" style={{ width: 60, ...headerStyle }} />
            </div>
            {rulesProps.value?.map((rule, i) => {
              return (
                <RuleItem
                  key={i} value={rule} currency={settings.currency}
                  onChange={v => onRulesChange(rulesProps.value?.map(((r, j) => i === j ? v : r)))}
                  onDelete={() => onRulesChange(rulesProps.value?.filter(((r, j) => i !== j)))}
                />
              );
            })}
          </div>
        );
      }}
    </ModalForm>
  );
}
