import * as config from 'config';

import ImagePreview from 'components/image-preview';


export default function AddonDetailSideBarSummary({
  cms,
  modal,
  addonConfig,
  refetch,
}) {
  return (
    <>
      <ImagePreview
        doc={addonConfig.image ? addonConfig : { image: `${config.publicUrl}/addon.png` }}
        style={{ borderBottom: 0 }}
      />
    </>
  );
}
