import { WurdText } from 'wurd-react';

import * as actions from 'actions';
import { getLangText } from 'helpers/ui';

import DetailPage from 'components/detail-page';
import NotFound from 'components/not-found';

import Alert from 'components/alert';
import ApiButton from 'components/api-button';
import SiteLoader from 'components/loaders/site';
import SidebarSummary from './sidebar-summary';
import Menu from './menu';
import Overview from './overview';
import History from './history';

const sectionComponents = {
  overview: Overview,
  history: History,
};

export default function AddonDetailPage({
  section,
  modal,
  cms,
  refetch,
  addonConfig,
  addon,
  tasksPlugin,
}) {
  async function enable() {
    await actions.update('addons', addon.id, {
      state: 'enabled',
    });

    refetch();
  }

  
  const isAddonDeveloper = Boolean(addonConfig.webtasks);  // if webtasks are present, then the currentUser is the addonConfig developer
  const isInstalled = Boolean(addon);

  const CurrentSection = sectionComponents[section];
  if (!CurrentSection) return <NotFound />;

  return (
    <SiteLoader id={addon.siteId}>
      {(site) => (
        <DetailPage
          modal={modal}
          cms={cms}
          userRole="manager"
          documentTitle={getLangText(addonConfig.title)}
          title={getLangText(addonConfig.title)}
          subtitle={getLangText(addonConfig.subtitle)}
          ancestors={site
            ? [
              { text: <WurdText id="siteList.title" />, url: `/sites` },
              { text: getLangText(site.title), url: `/sites/${site.code}` },
              { text: <WurdText id="siteView.nav.addons" />, url: `/sites/${site.code}/addons` }
            ] : [
              { text: <WurdText id="settings.title" />, url: '/settings' },
              { text: cms.text('title'), url: '/settings/addons' },
            ]
          }
          sections={[
            { name: 'overview', icon: 'far fa-info' },
            ...isInstalled ? [{ name: 'history', icon: 'far fa-history' }] : [],
          ]}
          currentSection={section}
          baseUrl={`/addons/${addon.id}`}
          sidebar={(
            <div>
              <SidebarSummary
                cms={cms}
                modal={modal}
                addonConfig={addonConfig}
                addon={addon}
                refetch={refetch}
                isInstalled={isInstalled}
              />
            </div>
          )}
          extra={(
            <Menu
              modal={modal}
              cms={cms}
              addonConfig={addonConfig}
              addon={addon}
              refetch={refetch}
              tasksPlugin={tasksPlugin}
            />
          )}
        >
          <>
            {addon.state === 'paused' && (
              <Alert
                type="warning"
                icon="far fa-pause"
                actions={(
                  <ApiButton
                    bsType="primary"
                    onClick={enable}
                  >
                    <cms.Text id="enable" />
                  </ApiButton>
                )}
              >
                <cms.Markdown id="isPausedAlert.text" />
              </Alert>
            )}

            {addon.state === 'disabled' && (
              <Alert
                type="danger"
                icon="far fa-minus"
                actions={(
                  <ApiButton
                    bsType="primary"
                    onClick={enable}
                  >
                    <cms.Text id="enable" />
                  </ApiButton>
                )}
              >
                <cms.Markdown id="isDisabledAlert.text" vars={{ disabledReason: addon.disabledReason }} />
              </Alert>
            )}
          
            <tasksPlugin.TaskList
              listLoader={tasksPlugin.listLoader}
            />

            <CurrentSection
              cms={cms}
              modal={modal}
              refetch={refetch}
              addonConfig={addonConfig}
              addon={addon}
              isAddonDeveloper={isAddonDeveloper}
            />
          </>
        </DetailPage>
      )}
    </SiteLoader>
  );
}
