import { useState } from 'react';
import _ from 'lodash';
import wurd from 'wurd-react';


function mergeCustomizer(oldValue, newValue) {
  if (Array.isArray(oldValue) && _.isPlainObject(newValue)) {
    return _.mergeWith([], oldValue, newValue, mergeCustomizer);
  }
  if (_.isPlainObject(newValue)) return _.mergeWith({}, oldValue, newValue, mergeCustomizer);
  return newValue;
}

/**
 * Takes errors returned from the API and formats them for sending through to the base
 * base Form component
 *
 * @param {Object}
 *
 * @return {Object}
 */
export function formatApiErrors(errors) {
  return Object.fromEntries(
    Object.entries(errors || {}).map(([field, err]) => {
      return [field.replace(/\[\d+\]$/, ''), err?.message || err?.type || err || 'invalid'];
    })
  );
}


export default function useApiForm({
  onSubmit, // (formData: object, dataPatchesExtented: object, dataPatches: object) => Promise<any>
  fields, // Optional array of field names to include
  validate,
  initialValue,
  onChange,
  onSuccess,
  onError = () => { },
  onDone,
  wurdSection,
  ...rest
}) {
  // props.initialValue is not applied initially to state.data
  // onSubmit will give in first arg the full form data (initialValue included), and in second arg only the patches
  // this can be useful for very large forms (used in settings/general, settings/plugins)
  const [data, setData] = useState({});

  const [{ loading, errMsg, errors, err }, setState] = useState({
    loading: false,
    errMsg: null,
    errors: {},
    err: null,
  });

  // get full form data, initialValue and form changes deeply merged (we accept names with dot)
  const fullData = _.mergeWith({}, initialValue, data, mergeCustomizer);
  const formData = !fields ? fullData : Object.fromEntries(Object.entries(fullData).filter(([key]) => fields.includes(key)));

  const updateData = (newData) => setData(data => _.mergeWith({}, data, newData, mergeCustomizer));

  const getInputProps = (name, addWurdContent) => ({
    name,
    value: _.get(formData, name),
    onChange: handleChange,
    ...addWurdContent && wurdSection && wurd.get(`${wurdSection}.${name}`),
  });

  const getFieldProps = (name, addWurdContent) => ({
    ...getInputProps(name, addWurdContent),
    include: fields ? fields.includes(name) : true,
    error: _.get(errors, name),
    wurdSection,
  });


  function handleChange(event) {
    const { name } = event.target;
    const value = event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.multiple 
        ? [...event.target.selectedOptions].map(o => o.value)
        : event.target.value;

    setData(data => {
      const newData = _.set({ ...data }, name, value); // we just make a shallow copy of data to make sure React sees a change, data can be mutated for deep changes, but that's fine as data is internal to this hook

      if (onChange) {
        onChange({
          name,
          value,
          prevValue: _.get(data, name) ?? _.get(initialValue, name),
          // formData: _.set({},  formData, name, value), // removed
          setFormData: updateData,
        });
      }

      return newData;
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (validate) {
      try {
        await validate(formData);
      } catch (err) {
        setState({ errMsg: err.message });
        onError(err.message);
        return;
      }
    }

    setState({
      loading: true,
      errMsg: null,
      err: null,
    });

    // make a dataPatchesExtended object containing full data at the first-level of nesting, this is what we want for most forms, to comply with api validation
    // example: formData: {a: {b:1, c:2}, x: {v:5}} data: {a: {c: 3}} dataExtended: {a: {b:1, c:3}}
    const dataPatchesExtended = Object.fromEntries(
      Object.entries(data).map(([name, value]) => {
        return _.isPlainObject(value)
          ? [name, { ...formData[name], ...value }]
          : [name, value];
      })
    );

    return onSubmit(formData, dataPatchesExtended, data)
      .then(onSuccess)
      .then(() => {
        setState({
          loading: false
        });
        setData({});
      })
      .then(onDone)
      .catch(err => {
        setState({
          errMsg: err.message,
          errors: formatApiErrors(err.errors),
          err,
          loading: false
        });
        onError(`${err.message}\n\n${Object.entries(formatApiErrors(err.errors)).map(([k, v]) => `- ${k}: ${v}`).join('\n')}`);
        throw err;
      });
  }

  function clear() {
    setState({
      loading: false,
      errMsg: null,
      err: null,
      errors: {},
    });
    setData({});
  }

  const dirtyFields = Object.keys(data);

  return {
    ...rest,
    fieldProps: getFieldProps,
    getProps: getFieldProps, //DEPRECATED; use fieldProps() instead
    inputProps: getInputProps,
    submit: handleSubmit,
    formValue: formData,
    setFormValue: updateData,
    errors,
    errMsg,
    err,
    loading,
    clear,
    dirty: dirtyFields.length > 0 ? dirtyFields : null,
  };
}
