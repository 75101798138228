import { ModalForm, Field, MultiLangText, ImageInput } from '../../form2';


export default function ItemForm({ mode, ...props }) {
  return (
    <ModalForm
      wurdSection="business.product"
      mode={mode}
      {...props}
    >
      {(getProps) => (
        <>
          <Field {...getProps('slug')}
            pattern="^[a-z0-9_-\d\.]{2,20}$"
            required
          />

          <hr />

          <Field {...getProps('image')}>
            <ImageInput
              imageFitProps={getProps('imageFit')}
            />
          </Field>

          <Field {...getProps('title')} required>
            <MultiLangText />
          </Field>

          <Field {...getProps('info')}>
            <MultiLangText />
          </Field>

          <Field {...getProps('price')}
            type="number"
            pattern="^\d+(\.\d{2})?"
            required
            autoComplete="off"
          />

          <Field {...getProps('displayPrice')}>
            <MultiLangText />
          </Field>

          <Field {...getProps('quantity')}
            type="number"
            min="0"
            step="1"
          />

          <Field {...getProps('maxQuantity')}
            type="number"
            min="0"
            step="1"
          />

          <Field {...getProps('order')} type="number" step="1" />
        </>
      )}
    </ModalForm>
  );
}
