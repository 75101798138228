import { useState, useEffect } from 'react';
import wurd from 'wurd-react';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import _ from 'lodash';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import { useModal } from 'hooks';
import { Bar, Line } from 'react-chartjs-2';
import Page from '../page';


const cms = wurd.block('dashboard');


export default function Dashboard() {
  const navigate = useNavigate();
  const modal = useModal();
  useEffect(() => {
    //Redirect for users without permission
    if (!helpers.auth.hasRole('admin')) {
      navigate('/valet-orders');
    }
  }, []);

  const { data: reports } = useQuery('reports', () => actions.reports.fetch()
    .then(reports => {
      //Determine number of days to show based on how long business has been running
      var numDays = (reports.length < 90) ? reports.length : 90;
      var daysInterval = Math.floor(numDays / 7) || 1;

      //Get last X days of data
      reports = reports.slice(-numDays);

      //Filter reports to the timescale and frequency we want
      var filteredReports = [];

      //Go backwards to make sure the latest report is included
      for (var i = reports.length - 1; i > 0; i -= daysInterval) {
        filteredReports.push(reports[i]);
      }

      filteredReports.reverse();

      return filteredReports;
    })
  );

  const { data: valetOrders } = useQuery('valet-orders', () => actions.valetOrders.fetch({ type: 'valet_order', state: 'active' }));


  function renderSummary() {
    const latestReport = _.last(reports);

    const summary = {
      totalUpcomingValetOrders: valetOrders.length,
      ...latestReport && {
        totalItemsInStorage: latestReport.stats.items_storage || 0,
        totalItemsWithUser: latestReport.stats.items_user || 0,
        totalUsers: latestReport.stats.users || 0,
      }
    };

    return (
      <div className="row">
        <div className="col-sm-3 col-xs-6">
          <div className="butpro butstyle clearfix">
            <Link to="/items?state=storage">
              <div className="sub">
                <cms.Text type="h2" id="summary.itemsInStorage" />
                <span><i className="fa fa-building"></i> {summary.totalItemsInStorage}</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-sm-3 col-xs-6">
          <div className="butpro butstyle">
            <Link to="/items?state=user">
              <div className="sub">
                <cms.Text type="h2" id="summary.itemsWithUser" />
                <span><i className="fa fa-user"></i> {summary.totalItemsWithUser}</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-sm-3 col-xs-6">
          <div className="butpro butstyle">
            <Link to="/valet-orders">
              <div className="sub">
                <cms.Text type="h2" id="summary.upcomingValetOrders" />
                <span><i className="fa fa-truck"></i> {summary.totalUpcomingValetOrders}</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-sm-3 col-xs-6">
          <div className="butpro butstyle">
            <Link to="/users">
              <div className="sub">
                <cms.Text type="h2" id="summary.users" />
                <span><i className="fa fa-users"></i> {summary.totalUsers}</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  function renderItemsInStorageChart() {
    const itemConfigs = helpers.item.list(true);

    //Container for report dates (main X axis)
    const dates = [];

    //Container for counts of items by type
    const itemData = {
      all: {
        label: cms.text('itemsInStorage.all'),
        data: [],
        borderColor: 'rgba(247,70,74,1)',
        backgroundColor: 'rgba(247,70,74,1)',
        borderWidth: 2,
      }
    };

    itemConfigs.forEach(itemConfig => {
      const color = helpers.ui.stringToColor(itemConfig.type);

      itemData[itemConfig.type] = {
        label: itemConfig.title,
        data: [],
        borderColor: color,
        backgroundColor: color,
      };
    });

    //Populate data
    reports.forEach(report => {
      const stats = report.stats;

      dates.push(report.date);

      itemData.all.data.push(stats.items_storage || 0);

      itemConfigs.forEach(({ type }) => {
        itemData[type].data.push(stats[`items_storage_${type}`] || 0);
      });
    });

    return (
      <Line
        data={{
          labels: dates,
          datasets: _.values(itemData)
        }}
      />
    );
  }

  function renderUnassignedItemsChart() {
    const itemConfigs = helpers.item.listEmpty(true);

    //Container for report dates (main X axis)
    const dates = [];

    //Container for counts of items by type
    const itemData = {};

    itemConfigs.forEach(itemConfig => {
      const color = helpers.ui.stringToColor(itemConfig.type);

      itemData[itemConfig.type] = {
        label: itemConfig.title,
        data: [],
        borderColor: color,
        backgroundColor: color,
      };
    });

    //Populate data
    reports.forEach(report => {
      const stats = report.stats;

      dates.push(report.date);

      itemConfigs.forEach(({ type }) => {
        itemData[type].data.push(stats[`items_unassigned_${type}`] || 0);
      });
    });

    // must check datasets isn't empty else chartjs will crash, we could add a 'all' property in itemData maybe?
    return reports.length > 0 && itemConfigs.length > 0 && (
      <Line
        data={{
          labels: dates,
          datasets: _.values(itemData)
        }}
        options={{
          scales: { y: { beginAtZero: true } },
        }}
      />
    );
  }

  function renderValetOrdersChart() {
    const ordersByDate = _.countBy(valetOrders, 'date');

    const dates = [];
    const orderCounts = [];

    _.forEach(ordersByDate, (numOrders, date) => {
      dates.push(moment(date).format('YYYY-MM-DD'));

      orderCounts.push(numOrders);
    });

    return (
      <Bar
        data={{
          labels: dates,
          datasets: [{
            label: cms.text('upcomingValetOrders.orders'),
            data: orderCounts,
            backgroundColor: "rgba(151,187,205,0.4)",
            borderColor: "rgba(151,187,205,0.9)",
          }]
        }}
        options={{
          elements: {
            bar: {
              borderWidth: 1,
            },
          },
        }}
      />
    );
  }

  function renderUsersChart() {
    const labels = [];

    const data = {
      all: [],
      storing: []
    };

    reports.forEach(report => {
      labels.push(report.date);

      data.all.push(report.stats.users);
      data.storing.push(report.stats.users_storing || 0);
    });

    return (
      <Line
        data={{
          labels: labels,
          datasets: [
            {
              label: cms.text('users.all'),
              data: data.all,
              borderColor: 'rgba(220,220,220,1)',
              backgroundColor: 'rgba(220,220,220,1)',
              borderWidth: 2,
            }, {
              label: cms.text('users.active'),
              data: data.storing,
              borderColor: 'rgba(151,187,205,1)',
              backgroundColor: 'rgba(151,187,205,1)',
              borderWidth: 2,
            }
          ]
        }}
      />
    );
  }

  function renderCharts() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="block">
              <div className="header no-border">
                <cms.Text type="h2" id="itemsInStorage.title" />
              </div>
              <div className="content">
                {renderItemsInStorageChart()}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="block">
              <div className="header no-border">
                <cms.Text type="h2" id="unassignedItems.title" />
              </div>
              <div className="content">
                {renderUnassignedItemsChart()}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="block">
              <div className="header no-border">
                <cms.Text type="h2" id="upcomingValetOrders.title" />
              </div>
              <div className="content">
                {renderValetOrdersChart()}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="block">
              <div className="header no-border">
                <cms.Text type="h2" id="users.title" />
              </div>
              <div className="content">
                {renderUsersChart()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Page
      titleKey={cms.id('title')}
      userRole="admin"
      modal={modal}
    >      
      {reports && valetOrders && (
        <div className="dashboard">
          {renderSummary()}
          {renderCharts()}
        </div>
      )}
    </Page>
  );
}
