export default ({ job, refetch }) => {
  const counts = job.result?.counts ?? [];
  const remindersCount = counts.map((n, i) => n && `${n} reminders${i + 1}`).filter(Boolean).join(', ');

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <small>{remindersCount}</small>
    </div>
  );
};
