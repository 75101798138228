import { DropdownButton, MenuItem } from 'react-bootstrap';
import omit from 'lodash/omit';

import * as helpers from '../../helpers';
import * as actions from '../../actions';

import { ModalDialog } from '../modal';
import ExportButton from '../export-button';
import ImportButton from '../import-button';


export default function UserListMenu({
  modal,
  cms,
  listQuery,
  refetch,
}) {
  if (!modal) return null;

  function openExportModal() {
    modal.open(
      <ModalDialog cms={cms.block('exportModal')}>
        <ExportButton
          filename="users.csv"
          fetch={params => actions.fetch('users', {
            ...listQuery.params,
            ...params,
            include: 'billing,customFields',
          }, { skipCache: true })}
          toCsv={helpers.user.toCsv}
        />
      </ModalDialog>
    );
  }

  function openImportModal() {
    modal.open(
      <ModalDialog cms={cms.block('importModal')}>
        <ImportButton
          processRow={async row => actions.create('users', await helpers.user.fromCsv(row), { include: 'customFields,billing' })}
          onComplete={refetch}
          mode="create"
        />
      </ModalDialog>
    );
  }

  function openUpdateModal() {
    modal.open(
      <ModalDialog cms={cms.block('updateModal')}>
        <ImportButton
          processRow={async row => actions.update('users', row.id, omit(await helpers.user.fromCsv(row), 'id'), { include: actions.includeKeysByEndpoint.users })}
          onComplete={refetch}
          mode="update"
        />
      </ModalDialog>
    );
  }

  return (
    <cms.Object id="menu" keys="export,import,update,helpUrl">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        <MenuItem
          key="export"
          onSelect={openExportModal}
        >
          <i className="fas fa-fw fa-download" /> {cms.text('menu.export')}
        </MenuItem>

        {helpers.auth.hasRole('manager') && (
          <>
            <MenuItem
              key="import"
              onSelect={openImportModal}
            >
              <i className="fas fa-fw fa-upload" /> {cms.text('menu.import')}
            </MenuItem>
            <MenuItem
              key="update"
              onSelect={openUpdateModal}
            >
              <i className="fal fa-fw fa-upload" /> {cms.text('menu.update')}
            </MenuItem>
          </>
        )}

        {cms.text('menu.helpUrl') && (
          <MenuItem href={cms.text('menu.helpUrl')} target="_blank">
            <i className="fal fa-fw fa-external-link" /> {cms.wurd.text('common.filters.helpText') || 'Help'}
          </MenuItem>
        )}
      </DropdownButton>
    </cms.Object>
  );
}
