import { Fragment, useState } from 'react';
import { notify } from 'helpers/ui';
import Button from 'components/button';
import * as actions from 'actions';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useApiForm } from 'hooks';


const Dl = styled('dl')({
  marginBottom: 0,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '.5rem 1rem',
});

const Buttons = styled('div')({
  justifyContent: 'center',
  display: 'grid',
  gridTemplateColumns: 'minmax(200px, auto) auto',
  gap: '.5rem 1rem',
})

const same = (a, b) => Object.keys({ ...a, ...b }).every(k => a?.[k] === b?.[k]);

export default ({ site, cms }) => {
  const [loading, setLoading] = useState();
  const [accesses, setAccesses] = useState([]);
  const [units, setUnits] = useState([]);
  const form = useApiForm({ initialValue: { sync: 'offset=0&limit=100', fetch: '' } });

  const fetchUnits = (query) => actions.fetch('units', { limit: 500, ...query, siteId: site.id, search: `${site.securitySystem.name}_id:`, include: 'rental' });

  async function fetchSecuritySystem() { // Fetch data from security system
    setLoading('fetch');
    try {
      const accesses = await actions.sites.securitySystem.fetch(site.id, { ...Object.fromEntries(new URLSearchParams(form.formValue.fetch)), siteName: site.code });
      setAccesses(accesses);
      setUnits(await fetchUnits());
    } finally {
      setLoading(null);
    }
  }

  async function syncAccessCodes() {
    setLoading('setAccessCodes');
    const units = await fetchUnits({ state: 'occupied' });

    let syncedRentals = [];
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      const result = accesses.find(u => u.unitName === unit.name && (!u.siteName || u.siteName === site.code));
      if (result?.accessCodes && !same(unit.rental?.accessCodes, result.accessCodes)) {
        await actions.update('unit-rentals', unit.rental.id, { accessCodes: { ...unit.rental.accessCodes, ...result.accessCodes } });
        syncedRentals.push(unit.rental.id);
      }
    }
    setLoading(null);
    notify({ text: `Synced ${syncedRentals.length} rentals access codes`, bsType: 'success' });
  }

  async function syncSecuritySystem() { // Sync all units to security system
    setLoading('sync')
    const units = await fetchUnits(Object.fromEntries(new URLSearchParams(form.formValue.sync)));
    const dataToSync = units.map(unit => unit.state === 'occupied' ? { action: 'moveIn', unitId: unit.id, rentalId: unit.rentalId } : { action: 'moveOut', unitId: unit.id });
    try {
      const result = await actions.sites.securitySystem.sync(site.id, dataToSync);
      window.alert('OK ' + JSON.stringify(result));
    } catch (e) {
      window.alert('ERR ' + e);
    }
    setLoading(null);
  }

  function renderTable() {
    const accessCodesReadable = Object.values(site.accessTypes || {}).every(v => v.includes('read'));

    return (
      <>
        <table className="table table-condensed">
          <thead>
            <tr>
              <th><cms.Text id="col.id" /></th>
              <th><cms.Text id="col.unit" /></th>
              <th><cms.Text id="col.user" /></th>
              <th><cms.Text id="col.accessCodes" /></th>
              <th><cms.Text id="col.overlocked" /></th>
            </tr>
          </thead>
          <tbody>
            {units.map(unit => {
              const access = accesses.find(a => a.unitName === unit.name);
              const rentalOwnerId = unit?.state === 'occupied' && unit?.rental?.ownerId || undefined;

              return (
                <tr key={access?.id || `_${unit.name}`}>
                  <td>{access?.id}</td>
                  <td className={!unit ? 'danger' : null}><Link to={`/units/${unit?.id}`}>{unit.name}{access?.siteName && access?.siteName !== site.code ? <i className="far fa-exclamation-circle" title={`site is incorrect ${access?.siteName}`} /> : null}</Link></td>
                  <td className={access?.userId !== rentalOwnerId ? 'danger' : null}>
                    {access?.userId
                      ? <Link to={`/users/${access?.userId}`}>{access?.firstName} {access?.lastName} <small>{access?.email}</small></Link>
                      : <span style={{ opacity: .5 }}>{access?.firstName} {access?.lastName} <small className="text-muted">{access?.email}</small></span>}
                  </td>
                  <td className={accessCodesReadable && unit?.state === 'occupied' && !same(unit.rental?.accessCodes, access?.accessCodes) ? 'danger' : null}><Dl>{Object.entries(access?.accessCodes || {}).map(([k, v]) => <Fragment key={k}><dt>{k}</dt><dd>{v}</dd></Fragment>)}</Dl></td>
                  <td className={!!access?.overlocked !== !!unit?.rental?.overlocked ? 'danger' : null}>{access?.overlocked}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        <Button className="pull-right" onClick={syncAccessCodes} loading={loading === 'setAccessCodes'}><cms.Text id="syncAccessCodes" /></Button>
      </>
    );
  }

  return (
    <>
      <Buttons>
        <input {...form.inputProps('sync')} className="form-control" />
        <Button onClick={syncSecuritySystem} loading={loading === 'sync'}><cms.Text id="sync" /></Button>
        <input {...form.inputProps('fetch')} className="form-control" placeholder="unitName=100&userId=abc" />
        <Button onClick={fetchSecuritySystem} loading={loading === 'fetch'}><cms.Text id="fetch" /></Button>
      </Buttons>

      {loading !== 'fetch' && accesses.length > 0 && renderTable()}
    </>
  )
}
