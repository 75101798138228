import { useState } from 'react';
import wurd, { WurdObject } from 'wurd-react';

import store from '../../store';
import * as helpers from '../../helpers';

import FilterButton from './button';
import ListFilter from './list';
import AsyncSelect from '../form2/async-select';
import ClearIndicator from './clear-indicator';


const cms = wurd.block('common.filters.unitType');


export default function UnitTypeFilter({ active, onSelect, params, ...props }) {
  const [open, setOpen] = useState(undefined);
  let title = cms.text('title') || 'Unit type';

  const unitType = active && store.get()['unit-types_byId'][active];

  if (unitType) {
    title += `: ${helpers.ui.getLangText(unitType.title)}`;
  }

  const _onSelect = v => {
    onSelect(v);
    setOpen(undefined);
  };

  return (
    <WurdObject id={cms.id()} keys="title,placeholder,clear">
      <FilterButton active={active} onSelect={onSelect} title={title} {...props}>
        <div style={{ padding: 10, width: 250 }}>
          <AsyncSelect
            endpoint="unit-types"
            params={params}
            placeholder={cms.text('placeholder')}
            onChange={e => _onSelect(e.target.value)}
            value={active}
            autoFocus
            components={{ ClearIndicator: props => <ClearIndicator {...props} onSelect={onSelect} /> }}
            onMenuOpen={() => setOpen(true)}
            menuIsOpen={open}
          />
        </div>
      </FilterButton>
    </WurdObject>
  );
}
