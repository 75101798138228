import PropTypes from 'prop-types';

import * as actions from '../../../actions'

import SettingsForm from '../form'


const MarketForm = ({ marketId, ...props }) => <SettingsForm
  {...props}
  action={values => actions.markets.update(marketId, values)}
/>

MarketForm.propTypes = {
  marketId: PropTypes.string.isRequired
};

export default MarketForm;
