import styled from '@emotion/styled';

const Container = styled.div`
  text-align: center;
  border-radius: 4px;
  border: 1px dashed var(--bs-gray-light);
  background: var(--bs-primary-light);
  padding-top: 32px;
  padding-bottom: 37px;

  .sgc-image {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .sgc-text {
    max-width: 600px;
    margin: auto;
    color: var(--bs-primary-hover);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .sgc-add {
    margin-top: 20px;
  }

  button {
    border: 1px solid var(--bs-gray-light);
    background: #fff;
    color: var(--bs-primary);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.167px;
    width: 274px;
    height: 52px;
  }
`;


export default function Empty({
  cms,
  onClickAdd,
}) {
  return (
    <Container>
      {cms.text('image') && (
        <cms.Image id="image" className="sgc-image" />
      )}

      <cms.Markdown id="text" className="sgc-text" />

      {onClickAdd && cms.text('add') && (
        <button
          type="button"
          onClick={onClickAdd}
        >
          <i className="far fa-plus" /> <cms.Text id="add" />
        </button>
      )}
    </Container>
  );
}
