import ReactHoverObserver from 'react-hover-observer';

import * as config from 'config';
import * as actions from 'actions';

import ImagePreview from 'components/image-preview';
import ImageForm from './image-form';


const styles = {
  main: {
    position: 'relative',
  },
  editOverlay: {
    position: 'absolute',
    display: 'inline-block',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    cursor: 'pointer',
  },
  editText: {
    position: 'absolute',
    display: 'inline-block',
    verticalAlign: 'middle',
    color: 'rgba(255,255,255,0.75)',
    left: 0,
    right: 0,
    top: '50%',
    fontSize: 36,
    marginTop: -18,
  },
  image: {
    width: '100%',
    height: 200,
    background: '#fff',
    border: '1px solid #ddd',
    objectFit: 'contain',
  },
}


export default function AddonConfigDetailSideBarSummary({
  cms,
  modal,
  addonConfig,
  refetch,
  isAddonDeveloper,
}) {
  function editImage() {
    modal.open(
      <ImageForm
        addonConfig={addonConfig}
        onSubmit={formData => actions.update('addon-configs', addonConfig.id, formData)}
        onSuccess={modal.close}
        closeModal={modal.close}
        mode="update"
      />
    );
  }

  return (
    <>
      {isAddonDeveloper ? (
        <ReactHoverObserver>
          {({ isHovering }) => (
            <div style={styles.main}>
              <img src={addonConfig.image || `${config.publicUrl}/addon.png`} alt="" style={styles.image} />

              {isHovering && (
                <div style={styles.editOverlay} onClick={editImage}>
                  <span style={styles.editText}><i className="fas fa-edit" /></span>
                </div>
              )}
            </div>
          )}
        </ReactHoverObserver>
      ) : (
        <ImagePreview
          doc={addonConfig}
          style={{ borderBottom: 0 }}
        />
      )}
    </>
  );
}
