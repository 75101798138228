import Link from 'components/nested-link';


export default function DetailPageNav({ cms, baseUrl, activeKey, sections = [] }) {
  return (
    <ul className="list-group">
      {sections.map(({ name, icon, badge, bsType }) => {
        const isActive = (name === activeKey);

        return (
          <Link
            key={name}
            style={{ cursor: 'pointer' }}
            className={`list-group-item ${isActive && 'active'}`}
            to={`${baseUrl}/${name}`}
          >
            <i className={`fa-fw fa-lg ${icon}`} />&nbsp;&nbsp;&nbsp;&nbsp; <cms.Text id={`nav.${name}`} />
            {!!badge && <span className={`badge${bsType ? ' alert-' + bsType : ''}`}>{badge}</span>}
          </Link>
        );
      })}
    </ul>
  );
}
