import wurd from 'wurd-react';
import { round } from 'lodash';
import moment from 'moment-timezone';

import { ModalForm2, Field, DatePicker, CurrencyInput } from 'components/form2';
import InputGroup from 'components/form2/input-group';
import DualInputField from 'components/form2/dual-input-field';

const wurdSection = 'unitJobs.updatePrice.create';


export function getAmountForPercentIncrease(oldPrice, pctIncrease) {
  return round(((oldPrice * pctIncrease) / 100) + oldPrice, 2);
}


export default function UnitRentalUpdatePriceForm({ fields, ...rest }) {

  return (
    <ModalForm2
      wurdSection={wurdSection}
      mode="create"
      {...rest}
    >
      {({ fieldProps, inputProps, formValue, setFormValue }) => {
        const { oldPrice, startDate } = formValue;

        function newPriceHandler(event) {
          const { value: newPrice } = event.target;
          
          setFormValue({
            newPrice,
            _pctIncrease: round(((newPrice / oldPrice) * 100) - 100, 2),
          });
        }

        function pctIncreaseHandler(event) {
          const { value: _pctIncrease } = event.target;

          setFormValue({
            _pctIncrease,
            newPrice: getAmountForPercentIncrease(oldPrice, _pctIncrease),
          });
        }

        return (
          <div>
            <Field {...fieldProps('oldPrice')} disabled>
              <CurrencyInput />
            </Field>

            <DualInputField {...fieldProps('newPrice')} required>
              <div>
                <CurrencyInput
                  {...inputProps('newPrice')}
                  className="form-control"
                  required
                  onChange={newPriceHandler}
                />

                <span className="help-block">{wurd.text(`${wurdSection}.newPrice.amount.help`)}</span>
              </div>

              <div>
                <InputGroup
                  {...inputProps('_pctIncrease')}
                  className="form-control"
                  type="number"
                  step="any"
                  after="% increase"
                  style={{ textAlign: 'right' }}
                  onChange={pctIncreaseHandler}
                />

                <span className="help-block">{wurd.text(`${wurdSection}.newPrice.pct.help`)}</span>
              </div>
            </DualInputField>

            <Field {...fieldProps('startDate')} required>
              <DatePicker />
            </Field>

            <Field {...fieldProps('noticeDate')}>
              <DatePicker
                maxDate={startDate ? moment(startDate) : null}
              />
            </Field>
          </div>
        );
      }}
    </ModalForm2>
  );

}
