import styled from '@emotion/styled';

const Label = styled.span({
  display: 'inline-block',
  marginBottom: 2,
  padding: '.4em .6em',
  '&:scope': {
    fontSize: '50%'
  },
});

export const colorStyles = {
  info: {
    backgroundColor: '#d9edf7',
    color: 'var(--bs-info)',
  },
  warning: {
    backgroundColor: '#fcf8e3',
    color: 'var(--bs-warning)',
  },
  danger: {
    backgroundColor: '#f2dede',
    color: 'var(--bs-danger)',
  },
  success: {
    backgroundColor: '#dff0d8',
    color: 'var(--bs-success)',
  },
  secondary: {
    backgroundColor: '#e5e5e5',
    color: 'var(--bs-secondary)',
  },
};


export default function StateLabel({ type = 'info', style, children: content, ...rest }) {
  return (
    <Label
      className="label"
      style={{ ...colorStyles[type], ...style }}
      {...rest}
    >
      {content}
    </Label>
  );
}
