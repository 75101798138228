import wurd from 'wurd-react';
import _ from 'lodash';

import schema2form from '../schema2form';

import DatePicker from '../datepicker';

import * as helpers from 'helpers';

const cms = wurd.block('invoice.payment');

const schema = [
  {
    name: 'type',
    type: 'hidden',
    required: true
  },
  {
    name: 'date',
    custom: props => <DatePicker {...props} />,
    required: true
  },
  {
    name: 'amount',
    type: 'number',
    required: true
  },
  {
    name: 'method',
    type: 'select',
    options: () => {
      return [
        { value: '', text: cms.text('selectPayment') },
        ...helpers.payment.list(true).map(item => ({
          value: item.code,
          text: item.title
        })),
    ]},
    required: true
  },
  {
    name: 'notes',
    label: 'Notes'
  }
];

export default schema2form(schema, { wurdSection: cms.id() });
