import moment from 'moment-timezone';
import CalendarIcon from 'react-calendar-icon';

import { datePeriod } from '../../helpers/ui';

import SidebarItem from './index';
import Link from '../nested-link';

const styles = {
  secondary: {
    color: '#bbb',
    fontSize: '.8em',
    lineHeight: '2.1em',
  },
};


export default function SidebarDate({
  date,
  endDate,
  title = 'Date',
  onClickEdit,
  linkTo,
}) {
  // Use moment for creating the date to account for timezone changes
  // Without this, the CalendarIcon date in negative timezones is incorrect
  const iconDate = moment(date).toDate();

  const [displayStartDate, displayEndDate] = datePeriod(date, endDate);

  return (
    <SidebarItem
      thumbnail={(
        <span style={{ fontSize: 9 }}>
          <CalendarIcon date={iconDate} />
        </span>
      )}
      typeTitle={title}
      itemTitle={linkTo
        ? (
          <Link to={linkTo}>
            {displayStartDate}
            {endDate && (
              <span style={styles.secondary}> → {displayEndDate}</span>
            )}
          </Link>
        ) : (
          <>
            {displayStartDate}
            {endDate && (
              <span style={styles.secondary}> → {displayEndDate}</span>
            )}
          </>
        )
      }
      onClickEdit={onClickEdit}
    />
  );
}
