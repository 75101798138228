import wurd from 'wurd-react';

import { changeUnit, changeStartDate } from './actions';

import SidebarState from '../../sidebar-items/state';
import SidebarUser from '../../sidebar-items/user';
import SidebarDate from '../../sidebar-items/date';
import SidebarUnitType from '../../sidebar-items/unit-type';
import SidebarUnit from '../../sidebar-items/unit';

const cms = wurd.block('unitOrder');

const bsTypeByState = {
  requiresInput: 'warning',
  cancelled: 'danger',
  completed: 'success',
};


export default function UnitMoveInJobSidebarSummary({ job, modal, owner }) {
  const { siteId, unitTypeId, startDate, orderState, unitId } = job.result;

  function handleChangeUnit() {
    changeUnit({ job, modal });
  }

  function handleChangeStartDate() {
    changeStartDate({ job, modal });
  }

  const canChangeUnit = (orderState === 'submitted');
  const canChangeStartDate = (orderState === 'submitted');

  return (
    <div className="list-group">
      <SidebarState type={bsTypeByState[orderState]}>
        <cms.Text id={`_states.${orderState}`} />
      </SidebarState>

      {owner && (
        <SidebarUser user={owner} />
      )}

      {unitId && (
        <SidebarUnit
          id={unitId}
          onClickEdit={canChangeUnit && handleChangeUnit}
        />
      )}

      {!unitId && (
        <SidebarUnitType
          id={unitTypeId}
        />
      )}

      <SidebarDate
        date={startDate}
        title={orderState === 'completed' ? <cms.Text id="movedIn" /> : <cms.Text id="moveIn" />}
        onClickEdit={canChangeStartDate && handleChangeStartDate}
      />
    </div>
  );
}
