import ModalForm from '../../form2/modal-form';
import { Field, MultiLangText } from '../../form2';


export default function NewSiteForm(props) {
  const initialValue = { ...props.initialValue };

  return (
    <ModalForm
      {...props}
      wurdSection="site"
      initialValue={initialValue}
    >
      {(fieldProps) => (
        <>
          <Field {...fieldProps('title')} required>
            <MultiLangText autoFocus />
          </Field>

          <Field {...fieldProps('address')}>
            <MultiLangText multiline />
          </Field>

          <Field {...fieldProps('phone')} />

          <Field {...fieldProps('email')} />
        </>
      )}
    </ModalForm>
  );
}
