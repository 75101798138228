import InputGroup from './input-group';

import store from '../../store';


export default function CurrencyInput(props) {
  const { currency } = store.get('settings');

  return (
    <InputGroup
      before={currency}
      type="number"
      min="0"
      autoComplete="off"
      step="any"
      {...props}
    />
  );
}
