import api from '../utils/api';
import errHandler from './_err-handler';


var actions = {};

actions.fetch = function () {
  return api.get('/v1/admin/reports')
    .then(res => res.data)
    .catch(errHandler)
}


export default actions;
