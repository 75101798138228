import * as helpers from '../helpers';

const styles = {
  image: {
    width: 50,
    height: 50,
    marginRight: 10,
    objectFit: 'cover'
  },

  title: {
    fontSize: 16
  },

  type: {
    color: '#666'
  }
};


const ItemType = ({ type, quantity, ...rest }) => {

  const itemType = helpers.item.get(type, true);

  return (
    <div {...rest}>
      <img src={itemType.image} alt={type} style={styles.image} />

      <div className="pull-right">
        <div style={styles.displayPrice}>{itemType.displayPrice}</div>
        <div style={styles.price}>{helpers.ui.currency(itemType.price)}</div>
      </div>

      <span style={styles.title}>
        {itemType.title}
        {quantity && ` × ${quantity}`}
      </span>
    </div>
  );

};


export default ItemType;
