import PropTypes from 'prop-types';
import wurd, { WurdMarkdown } from 'wurd-react';

import * as helpers from '../../../helpers';

import ModalForm from '../../form2/modal-form';
import Field from '../../form2/horizontal-field';
import DatePicker from '../../form2/datepicker';
import UnitSelector from '../../form2/unit-selector';
import SiteProductsField from '../../form2/site/products-field';
import PromoField from 'components/form2/unit/promo-field';
import PrepayPeriodsField from 'components/form2/unit/prepay-periods-field';
import { useItemLoader } from 'hooks';


const cms = wurd.block('unitOrder.form');


export default function UnitMoveInJobForm({ fields, siteId, ...rest }) {
  function include(name) {
    if (!fields) return true;
    if (fields.includes(name)) return true;
    return false;
  }

  const settings = helpers.settings.get();
  const { item: site } = useItemLoader('sites', siteId);

  return (
    <ModalForm
      wurdSection={cms.id()}
      {...rest}
    >
      {(getProps, formValue) => {
        const { unitTypeId, startDate } = formValue;
        return (
          <div>
            {/*{include('siteId') && (
              <Field {...getProps('siteId')} required>
                <SiteSelect />
              </Field>
            )}

            {include('unitTypeId') && siteId && (
              <Field {...getProps('unitTypeId')} required>
                <select>
                  <option value="">{cms.text('unitTypeId.placeholder')}</option>
                  {helpers.site.unitTypes_list(siteId, true).map((unitType) =>
                    <option key={unitType.id} value={unitType.id}>{unitType.title}</option>
                  )}
                </select>
              </Field>
            )}*/}

            {include('unitId') && (
              <Field {...getProps('unitId')}>
                <UnitSelector
                  siteId={siteId}
                  unitTypeId={unitTypeId}
                />
              </Field>
            )}

            {include('startDate') && (
              <Field {...getProps('startDate')} required>
                <DatePicker
                  minDate={null}
                />
              </Field>
            )}

            {include('unitDeposit') && (
              <Field {...getProps('unitDeposit')} type="number" required />
            )}

            {include('unitPrice') && (
              <Field {...getProps('unitPrice')} type="number" required />
            )}

            {include('prepayPeriods') && (
              <PrepayPeriodsField {...getProps('prepayPeriods')} wurdKeys="label,help,1,multiple" cms={cms.block('prepayPeriods')} />
            )}

            {(include('promoCodeId') || include('customPromo')) && (
              <PromoField
                {...getProps('promoCodeId')}
                field="id"
                customPromo={formValue.customPromo}
                promos={settings.unitPromos.filter(promo => helpers.business.isPromoValid(promo, siteId, startDate))}
                help={!settings.unitPromos.length && <WurdMarkdown id="common.forms.noUnitPromos" />}
              />
            )}

            {include('products') && site.products?.length > 0 && (
              <details open={Object.keys(formValue.products || {}).length > 0}>
                <summary><cms.Text id="products.label" type="h5" style={{ display: 'inline-block', fontWeight: 500 }} /></summary>
                <SiteProductsField products={site.products.sort(helpers.ui.sortByCode)} {...getProps('products')} />
              </details>
            )}
          </div>
        );
      }}
    </ModalForm>
  );
}


UnitMoveInJobForm.propTypes = {
  siteId: PropTypes.string.isRequired,
}
