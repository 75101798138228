import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import * as helpers from '../../helpers';

import wurd, { WurdText, WurdObject } from 'wurd-react';
import { Modal } from 'react-bootstrap';


const CrudList = createClass({

  propTypes: {
    title: PropTypes.element,
    items: PropTypes.array.isRequired,
    Form: PropTypes.func.isRequired,
    //apiEndpoint: PropTypes.string.isRequired, //Base API endpoint for actions (POST, PUT, DELETE)
    actions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.object,            //Optional default value when creating a new item. Use it to set defaults
    formProps: PropTypes.object,               //Optional props to pass to 'create' and 'update' forms
    emptyText: PropTypes.node,
  },

  getDefaultProps() {
    return {
      defaultValue: {}
    }
  },

  getInitialState() {
    return {
      modalContent: null
    };
  },

  render() {
    const { title, items, style } = this.props;
    const { modalContent } = this.state;

    return (
      <div style={style}>
        <div className="clearfix">
          {helpers.auth.hasRole('admin') &&
            <button
              type="button"
              className="btn btn-link pull-right"
              onClick={this.createItem}
            >
              <i className="fa fa-plus" />
            </button>
          }
          <h4>{title}</h4>
        </div>
        <ul className="list-group">
          {items.length > 0
            ? this.renderItems()
            : this.renderEmpty()
          }
        </ul>

        <Modal show={!!modalContent} onHide={this.closeModal}>
          {modalContent}
        </Modal>
      </div>
    );
  },

  renderEmpty() {
    return (
      <li className="list-group-item text-muted">
        <em>{this.props.emptyText || <WurdText id="common.crudList.empty" />}</em>
      </li>
    );
  },

  renderItems() {
    var { items, children } = this.props;

    return items.map(item =>
      <li
        key={item.id}
        className="list-group-item clearfix"
        style={{ cursor: 'pointer' }}
        onClick={this.updateItem.bind(null, item)}
      >
        {children && cloneElement(children, { item: item })}
      </li>
    );
  },

  createItem() {
    var { props } = this;

    var Form = props.Form;
    //var actions = createActions(props.apiEndpoint);

    var item = props.defaultValue;

    var content = (
      <Form
        defaultValue={item}
        onSubmit={props.actions.create}
        onSuccess={this.onChange}
        submitButton={<WurdText id="common.addBtn" />}
        modal
        onHideModal={this.closeModal}
        {...props.formProps}
      />
    );

    this.setState({ modalContent: content });
  },

  updateItem(item) {
    var { props } = this;

    var Form = props.Form;
    //var actions = createActions(props.apiEndpoint);

    var content = (
      <Form
        defaultValue={item}
        onSubmit={props.actions.update.bind(null, item.id)}
        onSuccess={this.onChange}
        submitButton={<WurdText id="common.updateBtn" />}
        modal
        onHideModal={this.closeModal}
        deleteButton={<WurdObject id="common" keys="deleteBtn,confirmDelete">{wurd.get('common.deleteBtn')}</WurdObject>}
        onDelete={this.deleteItem.bind(null, item)}
        {...props.formProps}
      />
    );

    this.setState({ modalContent: content });
  },

  deleteItem(item) {
    if (!window.confirm(wurd.get('common.confirmDelete'))) return;

    //var actions = createActions(this.props.apiEndpoint);

    this.props.actions.delete(item.id)
      .then(this.onChange)
  },

  onChange() {
    this.props.onChange();
    this.closeModal();
  },

  closeModal() {
    this.setState({ modalContent: null });
  }

});

export default CrudList;
