import wurd, { WurdText } from 'wurd-react';
import { currentLang } from 'utils/language';
import * as helpers from 'helpers';
import ModalForm from 'components/form2/modal-form';
import { Input } from 'components/form2';
import RadioList from 'components/form2/radiolist';
import InputGroup from 'components/form2/input-group';
import Alert from 'components/alert';


const cms = wurd.block('unitType.occupancyPrices');

export const calculateDynamicPrice = (basePrice, rule) => basePrice * (1 + (Number(rule.change ?? 0) / 100));

export default function UnitTypeOccupancyPricesForm({ preview_basePrice, ...props }) {
  return (
    <ModalForm
      {...props}
      wurdSection={cms.id()}
    >
      {(fieldProps, formValue) => (
        <div style={{ margin: '0 2.5rem' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '.25rem' }}>
            <WurdText id="unitTypeView.occupancyPrices.item.trigger" />

            <div style={{ display: 'flex', gap: '1rem' }}>
              <Input type="select" {...fieldProps('direction')} style={{ width: 'auto' }}>
                <option value="gt">{wurd.text('unitTypeView.occupancyPrices.item.gt')}</option>
                <option value="lt">{wurd.text('unitTypeView.occupancyPrices.item.lt')}</option>
              </Input>
              <InputGroup
                after="%"
                {...fieldProps('threshold')}
                required
                type="number"
                min="0"
                max="100"
                step="any"
                style={{ width: 65 }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '.25rem', margin: '1.5rem 0 2.5rem' }}>
            <WurdText id="unitTypeView.occupancyPrices.item.change" />

            <div style={{ display: 'flex', gap: '1rem' }}>
              <Input
                type="select"
                name="change"
                value={formValue.change?.toLocaleString().startsWith('-') ? -1 : 1}
                onChange={e => fieldProps('change').onChange({
                  target: {
                    name: 'change',
                    value: `${e.target.value < 0 ? '-' : ''}${String(formValue.change || 0).replace(/^-/, '')}`
                  }
                })}
                style={{ width: 'auto' }}
              >
                <option value={1}>{wurd.text('unitTypeView.occupancyPrices.item.incBy')}</option>
                <option value={-1}>{wurd.text('unitTypeView.occupancyPrices.item.decBy')}</option>
              </Input>
              <InputGroup
                after="%"
                name="change"
                onChange={e => fieldProps('change').onChange({
                  target: {
                    name: 'change',
                    value: `${formValue.change?.toLocaleString().startsWith('-') ? '-' : ''}${e.target.value}`
                  }
                })}
                value={String(formValue.change || '').replace(/^-/, '')}
                required
                type="number"
                step="any" 
                min="0"
                style={{ width: 65 }}
              />
            </div>
            
          </div>

          {formValue.change != null && formValue.threshold !== undefined && (
            <Alert>
              <cms.Markdown
                id="preview"
                vars={{
                  oldValue: `<span class="text-muted">${helpers.ui.currency(preview_basePrice)}</span>`,
                  newValue: `<span style="font-weight:500;color:var(--bs-accent-${formValue.change > 0 ? 'green' : 'red'})">${helpers.ui.currency(calculateDynamicPrice(preview_basePrice, formValue))}</span>`
                }}
              />

              <details style={{ marginTop: '.5rem' }}>
                <summary><cms.Text id="precision.label" /></summary>
                <RadioList {...fieldProps('precision')}>
                  {[0, 1, 2].map(v => ({ value: v, title: <h6><cms.Text id={`precision.decimal${v === 1 ? '' : 's'}`} vars={{ value: String(v) }} /></h6> }))}
                </RadioList>
              </details>
            </Alert>
          )}
        </div>
      )}
    </ModalForm>
  );
}
