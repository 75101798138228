import { useEffect, Fragment } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import wurd, { WurdText } from 'wurd-react';
import * as helpers from 'helpers';
import store from 'store';
import Select from 'components/form2/select';


const cms = wurd.block('business.emailTemplates');

function getSectionDefault(name) {
  switch (name.split('.', 1)[0]) {
    case 'daysSinceSent':
      return 'invoice';
    case 'settings':
    case 'plan':
      return 'order';
    case 'invoicePreview':
    case 'unit':
      return 'rental';
    case 'order':
    case 'site':
    case 'rental':
    case 'invoice':
      return name.split('.', 1)[0];
    default:
      return 'user';
  }
}




export default function PlaceholderDropdown({ editorRef, locals, partials, getSection = getSectionDefault }) {
  const { settings } = store.get();

  function insertPlaceholder(_name) {
    const name = _name.split(' ', 1)[0];
    const cfSetting = helpers.settings.getCf(name);
    const placeholder = name === '>button'
      ? `{{${name} text="Text" url="https://storeganise.com"}}`
      : cfSetting?.type === 'file'
        ? `[${helpers.ui.getLangText(cfSetting.title)}]({{${name}.url}})`
        : `{{${name}}}`

    editorRef?.current.doc.replaceSelection(placeholder);
    editorRef?.current.focus();
  }

  const globals = [
    'companyName',
    'brand.logo',
    ...settings.modes.map(mode => `urls.${mode === 'valetStorage' ? 'valetApp' : 'unitsApp'}`)
  ];

  const sections = locals.reduce((o, name) => {
    const section = getSection(name);
    return { ...o, [section]: [...o[section] || [], name] };
  }, {});

  return (
    <cms.Object keys={['insertVar', ...Object.keys(sections).map(section => `locals_${section}`), ...new Set(locals.filter(l => !l.includes('.customFields.'))), 'globals', ...globals || [], 'partials', ...partials || []]}>
      <Dropdown
        bsSize="sm"
        pullRight
        id="email-template-placeholder-dropdown"
      >
        <Dropdown.Toggle><cms.Text id="insertVar" /></Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: 480, overflowY: 'auto' }}>
          {Object.entries(sections).map(([section, locals]) => (
            <Fragment key={section}>
              <MenuItem header style={{ textTransform: 'capitalize' }}>{cms.text(`locals_${section}`) || section}</MenuItem>
              {locals.map(name => <MenuItem key={name} onClick={() => insertPlaceholder(name)}>{name}</MenuItem>)}
              <MenuItem divider />
            </Fragment>
          ))}
          <MenuItem header>{cms.text('globals') || 'Brand'}</MenuItem>
          {globals.map(name => <MenuItem key={name} onClick={() => insertPlaceholder(name)}>{name}</MenuItem>)}
          <MenuItem divider />
          {partials && (
            <>
              <MenuItem header>{cms.text('partials') || 'Partials'}</MenuItem>
              {partials.map(name => <MenuItem key={name} onClick={() => insertPlaceholder('>' + name)}>{name}</MenuItem>)}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </cms.Object>
  );
}
