import wurd from 'wurd-react';
import { useNavigate, useParams } from 'react-router-dom';

import { useModal, useItemLoader, useListLoader, useHistoryListQuery } from '../hooks';

import Page from '../components/page';
import NotFound from '../components/not-found';
import Spinner from '../components/spinner';
import BreadcrumbTitle from '../components/breadcrumb-title';
import Sitemap from '../components/sitemap';
import Filters from '../components/unit-list/filters';
import UnitModalSummary from '../components/unit-detail/modal-summary';
import { getLangText } from 'helpers/ui';

const cms = wurd.block('siteView.sitemap');


export default function SitemapPage() {
  const navigate = useNavigate();
  const { idOrCode } = useParams();

  const { item: site, refetch: fetchSite } = useItemLoader('sites', idOrCode, {
    altIdKey: 'code',
    maxAge: 0,
    include: 'positions',
  });

  const listQuery = useHistoryListQuery({
    implicitParams: {
      limit: 1000,
      siteId: site?.id,
      include: 'rental,customFields',
    },
  });

  const { items: units, refetch: fetchUnits, isLoading: isLoadingUnits } = useListLoader('units', site && listQuery.params, { maxAge: 0 });

  const modal = useModal();

  if (!site) {
    return isLoadingUnits ? <Spinner /> : <NotFound />;
  }

  const title = getLangText(site.title);

  const unitsIds = units.map(item => item.id);

  function onClickUnit(unit) {
    modal.open(
      <UnitModalSummary
        unit={unit}
        rental={unit.rental}
        modal={modal}
        onChange={() => navigate(`/units/${unit.id}`)}
      />,
    );
  }


  return (
    <Page
      userRole="admin"
      documentTitle={`${cms.text('title')} | ${title}`}
      title={(
        <BreadcrumbTitle
          title={<cms.Text id="title" />}
          ancestors={[
            { text: title, url: `/sites/${site.code}` },
          ]}
        />
      )}
      modal={modal.content}
      closeModal={modal.close}
      style={{ marginBottom: 0 }} // Chat/help icon can overlap, it's fine here
    >
      <div className="tab-content no-padding" style={{ marginBottom: 0, borderBottom: 0 }}>
        <div style={{ padding: '10px 10px 7px 10px', borderBottom: '1px solid #ddd' }}>
          <Filters listQuery={listQuery} hideSite />
          <button
            type="button"
            className="btn btn-link pull-right"
            onClick={fetchUnits}
            disabled={isLoadingUnits}
            title="refresh"
          >
            <i className={`fas fa-sync-alt ${isLoadingUnits ? 'fa-spin' : ''}`} />
          </button>
        </div>

        <div style={{ position: 'relative' }}>
          <Sitemap
            modal={modal}
            site={site}
            fetchSite={fetchSite}
            units={units.map(unit => ({ ...unit, measure: unit.measure || site.measure }))}
            fetchUnits={fetchUnits}
            highlightUnitIds={unitsIds}
            hideSite
            onClickUnit={onClickUnit}
          />
        </div>
      </div>
    </Page>
  );
}
