import wurd from 'wurd-react';

import { useListLoader } from '../../hooks';
import * as helpers from '../../helpers';

import TableList from '../table-list';
import Filters from './filters';
import Item from './item';
import Menu from './menu';
import BulkActions from './bulk-actions';

const cms = wurd.block('itemList');

function viewItem(item) {
  return `/items/${item.sid.toUpperCase()}`;
}


export default function ItemList({
  listQuery,
  modal,
  market = null, // To show correct prices for items, pass the market (usually the user's market)
  hideOwner = false,
  hidePrice = false,
  hideValetOrder = false,
  hideFilters = false,
  onClickAdd = null,
}) {
  const listLoader = useListLoader('items', listQuery.params, { maxAge: 0 });

  const itemConfigs = helpers.item.byType(true);
  const stateTitles = helpers.item.getStateTitles();

  const renderItem = (item) => (
    <Item
      item={item}
      itemConfigs={itemConfigs}
      stateTitles={stateTitles}
      market={market}
      hideOwner={hideOwner}
      hidePrice={hidePrice}
      hideValetOrder={hideValetOrder}
    />
  );

  const renderBulkActions = ({ selectedItems, onActionComplete }) => (
    <BulkActions
      modal={modal}
      selectedItems={selectedItems}
      onActionComplete={onActionComplete}
    />
  );


  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={!hideFilters && (
        <Filters
          listQuery={listQuery}
          hideOwner={hideOwner}
          hidePrice={hidePrice}
          hideValetOrder={hideValetOrder}
        />
      )}
      menu={helpers.auth.hasRole('admin') && (
        <Menu
          modal={modal}
          cms={cms}
          listQuery={listQuery}
          fetchItems={listLoader.refetch}
        />
      )}
      renderItem={renderItem}
      getItemLink={viewItem}
      renderBulkActions={renderBulkActions}
      onClickAdd={onClickAdd}
    />
  );
}
