import wurd, { WurdText } from 'wurd-react';
import { Fragment } from 'react';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import SectionCrudList from '../section_crud-list';
import SiteProductsForm from '../form2/site/products-form';
import LangText from 'components/lang-text';
import StateLabel from 'components/state-label';

const cms = wurd.block('siteView.products');

// styles copied from src/components/settings/item-type.js, might want to make a generic component for items with image, title and desc
const styles = {
  type: {
    color: '#aaa',
    fontStyle: 'italic'
  },

  img: {
    width: 50,
    height: 50,
    marginRight: 10,
  },

  displayPrice: {

  },

  info: {
    margin: '8px 0 0',
    color: '#aaa',
    // note: we could do whiteSpace: 'pre-wrap' here and avoid the \n/↳ replace below
  },
};

function Item({ item, ...props }) {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', gap: '1rem' }} {...props}>
      <img src={item.image} style={{ ...styles.img, objectFit: item.imageFit || 'cover' }} alt="Item" />
      <div className="h4" style={{ margin: 0 }}>
        <LangText obj={item.title} /> <small style={styles.type}>{wurd.text(`site.products.type.options.${item.type}`) || item.type}</small>
        {item.hidden && <StateLabel type="warning" as="small" style={{ marginLeft: '1rem' }}><WurdText id="site.products.hidden.true" /></StateLabel>}
        <p style={styles.info}>{helpers.ui.getLangText(item.description)?.replace(/\n/g, '↳')}</p>
      </div>
      <div className="text-right">
        <div><LangText obj={item.displayPrice} /></div>
        <div>{helpers.ui.currency(item.price)}</div>
      </div>
    </div>
  );
}


export default function ProductsSection({
  site,
  fetchSite,
  modal,
}) {
  return (
    <SectionCrudList
      cms={cms}
      items={site.products.sort(helpers.ui.sortByCode)}
      Form={SiteProductsForm}
      actions={actions.sites.products(site.id)}
      onChange={fetchSite}
    >
      {item => <Item item={item} />}
    </SectionCrudList>
  );
}
