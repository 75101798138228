import Link from 'components/nested-link';

export default function ({ title, ancestors }) {
  return (
    <span>
      {ancestors.map(({ text, url }) => (
        <span key={`${url}_${text}`}><Link to={url}>{text}</Link> <span style={{ color: '#aaa' }}>:</span> </span>
      ))}

      {title}
    </span>
  );

}
