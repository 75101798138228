import _ from 'lodash';

import * as actions from 'actions';
import * as helpers from 'helpers';

import UnitMoveInForm from './forms/move-in';
import UnitPrepayPeriodsForm from './forms/prepay-periods';
import UnitTransferForm from './forms/transfer';


export function scheduleMoveIn({ unit, modal, onChange }) {
  const initialValue = {
    unitDeposit: unit.defaultDeposit,
    unitPrice: unit.defaultPrice,
  };

  modal.open(
    <UnitMoveInForm
      siteId={unit.siteId}
      initialValue={initialValue}
      onSubmit={async data => {
        if (!data.userId) throw new Error('User is required');

        return actions.jobs.create(data.userId, 'unit_moveIn', {
          siteId: unit.siteId,
          unitTypeId: unit.typeId,
          unitId: unit.id,
          ..._.pick(data, [
            'startDate',
            'unitDeposit',
            'unitPrice',
            'prepayPeriods',
            'promoCode',
            'customPromo',
            'products',
          ]),
        });
      }}
      onSuccess={async (job) => {
        modal.close();
        onChange(job);
      }}
      closeModal={modal.close}
    />
  );
}


export function prepayPeriods({ unit, rental, modal, onChange }) {
  modal.open(
    <UnitPrepayPeriodsForm
      siteId={unit.siteId}
      onSubmit={async data => {
        const job = await actions.jobs.create(rental.ownerId, 'unitRental_prepayPeriods', {
          ...data,
          unitRentalId: rental.id,
        });

        modal.close();
        onChange(job);

        helpers.ui.notify({
          bsType: 'success',
          text: 'Prepayment invoice created',
        });
      }}
      closeModal={modal.close}
    />
  );
}


export function transferUnit({ unit, rental, modal, onChange }) {
  const { siteId, ownerId, typeId } = unit;

  const initialValue = {
    price: rental.price,
  };

  modal.open(
    <UnitTransferForm
      siteId={siteId}
      typeId={typeId}
      initialValue={initialValue}
      onSubmit={async data => {
        const job = await actions.jobs.create(ownerId, 'unit_transfer', {
          oldRentalId: rental.id,
          ..._.pick(data, [
            'newUnitId',
            'transferDate',
            'price',
          ]),
        });

        modal.close();
        onChange(job);

        helpers.ui.notify({
          bsType: 'success',
          text: 'Transfer job created',
        });
      }}
      closeModal={modal.close}
    />
  );
}
