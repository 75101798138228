import { useState } from 'react';
import { pick } from 'lodash';
import wurd, { WurdText, WurdMarkdown } from 'wurd-react';

import * as actions from 'actions';

import Section from 'components/section';
import { Field } from 'components/form2';
import { useApiForm } from 'hooks';

const fields = [
  'invoice_prefix',
  'invoice_counter',
  'invoice_zeroPadding',
];


export default function SiteInvoiceDetailsSettings({ site, fetchSite, ...rest }) {
  const [enabled, setEnabled] = useState(!!site.invoice_prefix);

  const { fieldProps, submit, loading, dirty, formValue, setFormValue } = useApiForm({
    wurdSection: 'site',
    initialValue: pick(site, fields),
    onSubmit: formData => actions.sites.update(site.id, {
      ...formData,
      invoice_prefix: formData.invoice_prefix?.toLowerCase() || null,
      invoice_counter: formData.invoice_counter ?? 1,
      invoice_zeroPadding: formData.invoice_zeroPadding ?? 6,
    }),
    onSuccess: fetchSite,
    onError: window.alert,
    ...rest
  });

  function toggleEnabled(e) {
    setEnabled(!!e.target.value);
    if (!e.target.value) {
      setFormValue({ invoice_prefix: '' });
    }
  }

  const previewSid = formValue.invoice_prefix
    ? `${formValue.invoice_prefix.toUpperCase() || ''}${String(formValue.invoice_counter ?? 1).padStart(formValue.invoice_zeroPadding ?? 6, 0)}` 
    : null;

  return (
    <Section cms={wurd.block('siteView.invoice')}>
      <Field
        name="invoice_inc_enabled"
        value={enabled}
        onChange={toggleEnabled}
        wurdSection="site"
        wurdKeys="label,help,disabled,enabled">
        <select style={!enabled ? { backgroundColor: '#eee' } : null}>
          <option value="">{wurd.text('site.invoice_inc_enabled.disabled')}</option>
          <option value="true">{wurd.text('site.invoice_inc_enabled.enabled')}</option>
        </select>
      </Field>

      {enabled && (
        <>
          <Field {...fieldProps('invoice_prefix')} required placeholder={wurd.text('site.invoice_prefix.placeholder')} wurdKeys="label,help,placeholder" />
          <Field {...fieldProps('invoice_zeroPadding')} type="number" step="1" min="0" defaultValue="6" max="8" />
          <Field {...fieldProps('invoice_counter')} type="number" step="1" min="0" defaultValue="1" />

          <Field {...fieldProps('invoice_nextSidPreview')} value={previewSid} disabled />
        </>
      )}

      <button
        className="btn btn-primary pull-right"
        type="button"
        disabled={loading || !dirty}
        onClick={submit}
      >
        {loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>
    </Section>
  );
}
