import _ from 'lodash';

import { getLangText } from './ui';
import * as settingsHelpers from './settings';
import { currentLang } from 'utils/language';


function doTranslate(productConfig, lang) {
  const obj = Object.assign({}, productConfig);

  ['title', 'displayPrice', 'info'].forEach(prop => {
    obj[prop] = getLangText(obj[prop], lang);
  });

  return obj;
}


/**
 * Returns the list of plan types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const list = function (translate) {
  const settings = settingsHelpers.get();
  if (!settings) return [];

  const productConfigs = settings.products.sort((a, b) => a.slug.localeCompare(b.slug));

  if (translate) {
    return productConfigs.map(productConfig => doTranslate(productConfig, currentLang));
  } else {
    return productConfigs;
  }
};


/**
 * Returns an object of product types, keyed by ID
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object}
 */
export const byId = function (translate) {
  return _.keyBy(list(translate), 'id');
};


/**
 * Returns a specific product config object
 *
 * @param {String} id
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const get = function (id, translate) {
  const productConfig = _.find(list(), { id });

  if (!productConfig) {
    const err = new Error('productConfig not found for ' + id);
    //_errs.push(err);
    console.error(err);
    return {};
  }

  if (translate) {
    return doTranslate(productConfig, currentLang);
  } else {
    return productConfig;
  }
};


/**
 * Gets the title of a product given it's ID
 *
 * @param {String} id
 */
export const getTitle = function (id) {
  const productConfig = get(id, true) || {};

  return productConfig.title;
};
