import { Component } from 'react';
import PropTypes from 'prop-types';

import { isUnit } from '../helpers';

import { Stage, Layer } from 'react-konva';
import Item from './Item';
import Transformer from './Transformer';


const STAGE_NAME = 'STAGE_WRAPPER';


export default class MapBuilder extends Component {

  static propTypes = {
    onSelectItem: PropTypes.func.isRequired,
    selectedItem: PropTypes.object,
  };

  handleShapeClick = e => {
    const { editMode, onSelectItem = () => { }, onClickUnit } = this.props;

    const currentTarget = e.currentTarget.name();
    const target = e.target.name();

    if (target === STAGE_NAME) {
      onSelectItem(null);
    } else if (currentTarget !== STAGE_NAME) {
      const { item } = e.currentTarget.attrs;

      if (editMode) {
        onSelectItem({ ...item, shapeName: currentTarget });
      }
      else if (isUnit(item) && onClickUnit) { // show unit details in View mode
        onClickUnit(item);
      }
    }
  }

  discreteDragging = pos => {
    let blockSnapSize = this.props.blockSnapSize * this.props.scale

    const zoom = this.stage.scaleX();

    /*if (zoom > 1) {
      blockSnapSize = blockSnapSize / zoom
    } else {
      blockSnapSize = blockSnapSize * zoom
    }*/

    blockSnapSize = (zoom > 1)
      ? blockSnapSize / zoom
      : blockSnapSize * zoom

    return {
      x: Math.round(pos.x / blockSnapSize) * blockSnapSize,
      y: Math.round(pos.y / blockSnapSize) * blockSnapSize,
    }
  }

  dragBoundFunc = (pos, dragging) => {
    const positionDragging = this.getObjectPosition(dragging.id);

    // Disable dragging limit for modified objects
    if (positionDragging.rotation > 0) return this.discreteDragging(pos)
    return this.discreteDragging(pos)
  }

  handleZoom = (scaleBy = 0, zoomPoint) => {
    const { stage } = this;

    this.setZoom(stage.scaleX() + scaleBy, zoomPoint);
  }

  setZoom = (newScale, zoomPoint) => {
    const { stage } = this;

    //Default to zoom in the middle of the stage
    zoomPoint = zoomPoint || {
      x: stage.width() / 2,
      y: stage.height() / 2
    };

    const oldScale = stage.scaleX();

    const oldPosition = {
      x: zoomPoint.x / oldScale - stage.x() / oldScale,
      y: zoomPoint.y / oldScale - stage.y() / oldScale,
    };

    const newPosition = {
      x: -(oldPosition.x - zoomPoint.x / newScale) * newScale,
      y: -(oldPosition.y - zoomPoint.y / newScale) * newScale,
    };

    if (newScale <= 0 || newScale > 4) return;

    stage.scale({ x: newScale, y: newScale });
    stage.position(newPosition);
    stage.batchDraw();

    // console.info(`Zoom: ${newScale}`);
  }

  handleWheel = e => {
    if (!e.evt.ctrlKey) return;
    e.evt.preventDefault();
    const stage = e.currentTarget;
    const zoomPoint = stage.getPointerPosition();
    this.handleZoom(-e.evt.deltaY / 500, zoomPoint);
  }

  getObjectPosition = id => {
    const { positions } = this.props;

    const position = positions.find(position => position.id === id);

    return position || {};
  }

  render() {
    const { positions, getSitemapData, width, height, scale, editMode, selectedItem, updateItemPosition, highlightUnitIds } = this.props;

    return (
      <Stage
        ref={el => (this.stage = el)}
        onWheel={this.handleWheel}
        width={width}
        height={height}
        draggable
        onClick={this.handleShapeClick}
        name={STAGE_NAME}>
        <Layer>
          {positions.map(position => {
            const item = getSitemapData(position);

            return (
              <Item
                key={position.id}
                item={item}
                position={position}
                dragBoundFunc={this.dragBoundFunc}
                updateItemPosition={updateItemPosition}
                handleStageClick={this.handleShapeClick}
                scale={scale}
                editMode={editMode}
                isSelected={editMode && selectedItem && selectedItem && selectedItem.id === item.id}
                highlightUnitIds={highlightUnitIds}
              />
            );
          })}
          {editMode && selectedItem && (
            <Transformer
              selectedShapeName={selectedItem.shapeName}
              selectedShapeType={selectedItem.type}
              scale={scale}
              editMode={editMode}
            />
          )}
        </Layer>
      </Stage>
    )
  }
}
