import wurd, { WurdText } from 'wurd-react';
import { useParams } from 'react-router-dom';

import * as helpers from 'helpers';
import { getLangText } from 'helpers/ui';
import * as actions from 'actions';

import StateLabel from 'components/state-label';
import DetailPage from '../detail-page';
import NotFound from '../not-found';
import SidebarSummary from './sidebar-summary';
import Details from './details';
import Units from './units';
import Charges from './charges';
import SystemCharges from './system-charges';
import History from './history';
import Menu from './menu';
import LabelsList from 'plugins/labels/detail';

const cms = wurd.block('unitTypeView');

const sectionComponents = {
  'details': Details,
  'units': Units,
  'charges': Charges,
  'accounting': SystemCharges,
  'history': History,
};

export default function UnitTypeDetailInnerPage({
  unitType,
  site,
  refetch,
  modal,
}) {
  const { section = 'details' } = useParams();
  const { occupied = 0, reserved = 0, available = 0, blocked = 0 } = unitType.occupancy;

  const sections = [
    { name: 'details', icon: 'far fa-info-circle' },
    { name: 'units', icon: 'far fa-building', badge: `${occupied + reserved}/${occupied + reserved + available + blocked}` },
    { name: 'charges', icon: 'far fa-money-bill-alt' },
    { name: 'accounting', icon: 'far fa-calculator' },
    { name: 'history', icon: 'far fa-history' },
  ];

  const CurrentSection = sectionComponents[section];
  if (!CurrentSection) return <NotFound />;

  function onChange() {
    modal.close();
    refetch();
  }

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="admin"
      documentTitle={getLangText(unitType.title)}
      title={getLangText(unitType.title)}
      subtitle={unitType.hidden && <StateLabel type="warning" as="small"><WurdText id="unitType.hidden.true" /></StateLabel>}
      ancestors={[
        { text: <WurdText id="siteList.title" />, url: `/sites` },
        { text: getLangText(site.title), url: `/sites/${site.code}` },
        { text: <WurdText id="siteView.nav.unit-types" />, url: `/sites/${site.code}/unit-types` },
      ]}
      sections={sections}
      currentSection={section}
      baseUrl={`/unit-types/${unitType.id}`}
      extra={(
        <>
          <LabelsList
            labels={unitType.labels}
            settingsLabels={helpers.settings.get().unitTypeLabels}
            onChange={labels => actions.update('unit-types', unitType.id, { labels })}
          />

          <Menu
            unitType={unitType}
            modal={modal}
            section={section}
            onChange={refetch}
          />
        </>
      )}
      sidebar={(
        <SidebarSummary
          unitType={unitType}
          modal={modal}
        />
      )}
    >
      <CurrentSection
        site={site}
        unitType={unitType}
        onChange={onChange}
        cms={cms}
        modal={modal}
      />
    </DetailPage>
  );
}
