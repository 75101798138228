import { Component } from 'react';
import { WurdText } from 'wurd-react';
import { notify } from '../helpers/ui';


export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    notify({
      bsType: 'danger',
      text: error.message,
      duration: 10000,
    });
  }

  render() {
    if (this.state.error) {
      return this.props.fallback || (
        <>
          <h1 className="text-center" style={{ marginTop: '4rem' }}><WurdText id="common.phrases.error" vars={{ msg: this.state.error.message }} /></h1>
        </>
      );
    }

    return this.props.children; 
  }
}