import wurd, { WurdText } from 'wurd-react';
import styled from '@emotion/styled';
import { currency, getLangText } from 'helpers/ui';


const Wrapper = styled.div({
	backgroundColor: '#fff',
	boxShadow: '0 0 1.25rem 0 #0000001a',
	borderRadius: 4,
	display: 'flex',
	alignItems: 'center',
	maxWidth: 560,

	'@media (max-width: 465px)': {
		flexWrap: 'wrap',
	},

	'.preview-left img': {
		width: '100%',
	},
	'.preview-left': {
		'@media (max-width: 465px)': {
			width: '100%',
		},
		'img': {
			maxHeight: 180,
			aspectRatio: '4 / 3',
			objectFit: 'contain',
		}
	},
	'.preview-right_icons ul': {
		listStyle: 'none',
		paddingLeft: '0px',
		display: 'flex',
		gap: '1.5rem',
		marginTop: '0.4rem'
	},
	'.preview-right_icons ul li .far': {
		fontSize: '1.375rem',
	},
	'.preview-right': {
		width: '100%',
		padding: '.75rem 1.5rem',
		minWidth: 0,
	},
	'.preview-right div:first-child': {
		fontSize: '18px',
	},
	'.preview-right_heading': {
		wordBreak: 'break-word',
		'span': {
			fontSize: '15px',
			fontWeight: '300',
			marginBottom: '15px',
			display: 'block',
		},
		'h4': {
			margin: '.5rem 0',
			fontSize: '2.25rem',
			letterSpacing: '.05rem',
			lineHeight: '1.4', // else overflow hidden crops a bit text
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}
	},
	'.preview-right_bottom': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '2rem',
		marginBottom: '.5rem',
		fontSize: '1.3em',
		flexWrap: 'wrap',
	},
});
export default function UnitTypePreview({ item, button }) {
	const cms = wurd.block('siteOnboarding.unit.preview');

	return (
		<Wrapper>
			<div className="preview-left">
				<img src={item.image || 'https://storeganise.s3.amazonaws.com/images/unit.png'} style={{ objectFit: item.imageFit || 'contain' }} />
			</div>
			<div className="preview-right">
				<div className='preview-right_heading'>
					<h4><strong>{getLangText(item.title) || wurd.text('unitType.title.label')}</strong></h4>
					<span className="text-muted">{getLangText(item.subtitle || item.description) || wurd.text('unitType.subtitle.label')}</span>
				</div>
				<div className="preview-right_icons">
					<ul>
						{item.tags?.map((tag, j) => (
							<li key={j} title={tag.title}><i aria-label={tag.title} className={`${tag.icon} far text-muted`} /></li>
						))}
					</ul>
				</div>
				<div className="preview-right_bottom">
					<div><strong>{getLangText(item.displayPrice) || currency(+item.price_display || +item.price || 0, '$')}</strong><small><cms.Text id="perMonth" className="text-nowrap" /></small></div>
					{button}
				</div>
			</div>
		</Wrapper>
	);
}
