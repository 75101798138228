import api from '../utils/api';
import errHandler from './_err-handler';

import { update, updateCachedData, fetchOne } from './general';
import jobActions from './jobs';
import { getCurrentUser } from '../helpers/auth';

const endpoint = 'unit-rentals';
const url = `/v1/admin/${endpoint}`;

const actions = {};


actions.fetch = function (params = {}) {
  return api.get(url, { params })
    .then(res => {
      const unitRentals = res.data;

      return unitRentals;
    })
    .catch(errHandler);
};

actions.update = async function (id, data) {
  return update(endpoint, id, data);
};

actions.regenerateAgreement = function (unitRentalId) {
  return api.post(`${url}/${unitRentalId}/regenerateAgreement`)
    .catch(errHandler);
};

actions.createManualInvoice = function (unitRentalId, data) {
  return api.post(`${url}/${unitRentalId}/invoices`, data)
    .then((res) => {
      const invoice = res.data;

      updateCachedData('invoices', invoice);

      return invoice;
    })
    .catch(errHandler);
};

actions.createCustomPromo = function (unitRentalId, data) {
  return api.post(`${url}/${unitRentalId}/promo`, data)
    .then((res) => {
      const unitRental = res.data;

      // updateCachedData('unit-rentals', unitRental);

      return unitRental;
    })
    .catch(errHandler);
};

export default actions;
