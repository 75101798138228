import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { getLuminance } from '../../helpers/ui';


/**
 * simple color palette obtained from material-ui "@material-ui/core/colors, editable on https://codesandbox.io/s/mui-color-palette-phl58
 */
export const labelColors = ["#ffff8d", "#ffd180", "#ff9e80", "#ff5252", "#d50000", "#795548", "#ea80fc", "#aa00ff", "#6200ea", "#82b1ff", "#448aff", "#2962ff", "#84ffff", "#00e5ff", "#00b8d4", "#b9f6ca", "#69f0ae", "#00c853", "#eeeeee", "#e0e0e0", "#9e9e9e"];



export default function ColorPicker({ name, value, onChange }) {
  const [open, setOpen] = useState(false);

  function onSelect(value) {
    onChange({ target: { name, value } });
    setOpen(false);
  }

  return (
    <Dropdown
      id="label-colorpicker-dropdown"
      open={open}
      onToggle={setOpen}
    >
      <Dropdown.Toggle style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ backgroundColor: value, fontSize: '1.5em', display: 'inline-block', width: 100, height: 16, marginRight: 8 }} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <li
          style={{
            overflow: 'auto', maxHeight: 326, width: 350,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: 6
          }}
        >
          {labelColors.map(color =>
            <button
              key={color}
              type="button"
              style={{
                border: 'none',

                padding: '4px 24px',
                fontWeight: 600,
                // fontSize: '1.3em',
                maxWidth: 120,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontFamily: 'monospace',
                justifySelf: 'center',
                backgroundColor: color,
                color: getLuminance(color) > 0.5 ? '#000b' : '#fffe'
              }}
              onClick={() => onSelect(color)}
            >
              {color}
            </button>
          )}
        </li>
      </Dropdown.Menu>
    </Dropdown>
  )
}
