import createClass from 'create-react-class';
import moment from 'moment-timezone';
import _ from 'lodash';

import * as helpers from '../../../helpers';
import schema2form from '../schema2form';

import DatePicker from '../datepicker';
import Checklist from '../checklist';
import Input from '../horizontal-input';


const wurdSection = 'visit';

const schema = [
  {
    name: 'date',
    custom: props => <DatePicker {...props} minDate={moment()} />,
    required: true
  },
  {
    name: 'timeslot',
    custom: (props, formProps, formData) => (
      <Input type="select" {...props}>
        <option value=""></option>
        {helpers.visit.getTimeslotsForDate(formProps.user, formData.date).map(timeslot =>
          <option key={timeslot} value={timeslot}>{timeslot}</option>
        )}
      </Input>
    ),
    required: true
  },
  {
    name: 'address',
    type: 'textarea',
    required: true
  },
  {
    name: 'area',
    type: 'select',
    options: function () {
      return [
        { value: '', text: 'Select an area...' },
        ..._.map(helpers.area.titlesById(), (title, id) => (
          { value: id, text: title }
        ))
      ];
    },
    required: true
  },
  {
    name: 'phone',
    required: true
  },
  {
    name: 'instructions',
    type: 'textarea'
  },
  {
    name: 'altPhone',
  },
  {
    name: 'orderedItems',
    custom: props => {
      function onChange(itemType, numItems) {
        var newValue = _.extend({}, props.value, {
          [itemType]: numItems
        });

        props.onChange(newValue);
      }

      return helpers.item.listEmpty(true).map(item =>
        <Input {...props}
          key={item.type}
          type="number"
          customLabel={item.title}
          value={props.value && props.value[item.type]}
          onChange={onChange.bind(null, item.type)}
          min="0"
        />
      )
    }
  },
  {
    name: 'deliverItems',
    custom: (props, formProps) => {
      var itemTitles = helpers.item.titlesByType();
      var itemsInStorage = formProps.user.items.filter(item => item.state === 'storage');

      if (!itemsInStorage.length) return null;

      return (
        <Checklist {...props}>
          {itemsInStorage.map(item =>
            <span key={item.id} id={item.id}>
              {item.sid.toUpperCase()} &nbsp;
              <span className="text-muted" style={{ fontWeight: 'normal' }}>{itemTitles[item.type]}</span>
            </span>
          )}
        </Checklist>
      )
    }
  },
  {
    name: 'collectItems',
    custom: (props, formProps) => {
      var itemTitles = helpers.item.titlesByType();
      var itemsWithUser = formProps.user.items.filter(item => item.state === 'user');

      if (!itemsWithUser.length) return null;

      return (
        <Checklist {...props}>
          {itemsWithUser.map(item =>
            <span key={item.id} id={item.id}>
              {item.sid.toUpperCase()} &nbsp;
              <span className="text-muted" style={{ fontWeight: 'normal' }}>{itemTitles[item.type]}</span>
            </span>
          )}
        </Checklist>
      )
    }
  }
];


const VisitSchemaForm = schema2form(schema, { wurdSection });

const VisitForm = createClass({

  render() {
    return <VisitSchemaForm {...this.props} preChange={this.preChange} />
  },

  preChange(data) {
    //If the date changes, reset the timeslot to make sure they select a timeslot
    //that is valid for the given date
    if (data.date) {
      data.timeslot = '';
    }

    return data;
  }

});

export default VisitForm;
