/**
 * Creates a clickable, accessible <div>
 */
const style = {
  cursor: 'pointer',
};


export default function ClickableDiv({
  onClick,
  children,
  ...rest
}) {
  return (
    <div
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
      style={style}
      {...rest}
    >
      {children}
    </div>
  );
}
