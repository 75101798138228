import { Button } from 'react-bootstrap';
import { WurdText } from 'wurd-react';


const RefreshButton = ({ onClick }) => (
  <Button onClick={onClick}><i className="fas fa-sync-alt" /> <WurdText id="common.refreshBtn" /></Button>
);


export default RefreshButton;
