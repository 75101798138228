import { Link, useNavigate } from 'react-router-dom';
import wurd from 'wurd-react';
import { useListLoader } from 'hooks';

import { apiUrl } from 'config';
import * as helpers from 'helpers';

import Empty from 'components/empty';
import Task from '../task';


const cms = wurd.block('siteOnboarding.tasks');


export default function SiteOnboardingSitemap({ site }) {
  const navigate = useNavigate();
  const { items: unitTypes = [] } = useListLoader('unit-types', { siteId: site.id });
  
  const nUnits = helpers.site.getOccupancy(site).total;
  const nPositioned = site.positions?.filter(p => p.type === 'unit').length;


  return (
    <Task
      title={<cms.Text id="sitemap.title" />}
      isDone={nPositioned > 0 && (nPositioned === nUnits ? true : 'undeterminate')}
      open={nUnits > 0 && nPositioned !== nUnits}
      action={
        <>
          {unitTypes.length > 0 && (
            <cms.Text id="sitemap.action.status" className="text-muted" vars={{ nPositioned: String(nPositioned), nUnits: String(nUnits) }} />
          )}

          {nUnits > 0 && (
            <Link className="btn btn-link" to={`/sites/${site.code}/sitemap#edit`}>
              <i className="far fa-pen" /> <cms.Text id="sitemap.action.todo" />
            </Link>
          )}
        </>
      }
    >
      {nPositioned > 0
        ? (
          <Link to={`/sites/${site.code}/sitemap#edit`}>
            <img src={`${apiUrl}/v1/settings/sites/${site.code}/sitemap`} alt="Sitemap" style={{ height: 460, maxWidth: '100%' }} />
          </Link>
        ) : (
          <Empty
            cms={cms.block('sitemap.empty')}
            onClickAdd={() => navigate(`/sites/${site.code}/sitemap#edit`)}
          />
        )}
    </Task>
  );
}
