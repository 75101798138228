import { useState, useEffect } from 'react';


export function useResize(cb){
  useEffect(() => {
    window.addEventListener('resize', cb);
    return () => window.removeEventListener('resize', cb);
  }, []);
}



const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export const isMobile = (breakpoint) => !window.matchMedia(`(min-width: ${breakpoints[breakpoint] || 0}px)`).matches;

export function useMobile(breakpoint){
  const [mobile, setMobile] = useState(isMobile(breakpoint));
  useResize(() => setMobile(isMobile(breakpoint)));
  return mobile;
}
