import axios from 'axios';

import { notify } from '../helpers/ui';


/**
 * An error handler for the .catch() promise method which treats syntax errors etc.
 * as normal errors, including keeping line numbers.
 * See https://stackoverflow.com/questions/33376308/es6-promises-swallow-type-errors
 */
export default function errHandler(err = {}) {
  switch (err.type || err.statusText) {
    case 'Validation':
      // Do nothing, errors will be shown in forms inline
      break;

    case 'Unauthorized':
      // Do nothing, should be handled to show login form
      if (errHandler.openLogin) errHandler.openLogin(err);
      break;

    case 'use_azure_login':
      break;

    default:
      if (err.message !== 'Internal Error' && !axios.isCancel(err)) {
        notify({
          text: err.message,
          bsType: 'danger'
        });
      }
      break;
  }

  // For logging with error stack
  setTimeout(() => console.error(err));

  // Throw it for other catch()s in the chain
  throw err;
}

errHandler.openLogin = null; // function to open login modal, defined from components/app_logged-in
