import Link from 'components/nested-link';
import styled from '@emotion/styled';

const TabMenu = styled.div`
  display: flex;
  gap: 6px;
  overflow: auto;
  padding: 7px;

  a {
    border-radius: 4px;
    border: 1px solid #DFDFDF;
    background: #fff;
    display: flex;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 42px;
    color: var(--bs-primary);
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.167px;
    white-space: nowrap;
  }

  .badge {
    border-radius: 160px;
    background: var(--bs-primary-light);
    color: var(--bs-black);
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: -0.167px;
  }

  a:hover  {
    background: var(--bs-primary-hover);
    color: #fff;
  }

  .active {
    background: var(--bs-primary-hover);
    color: #fff;
  }
`

export default function DetailPageSectionNavMobile({ cms, baseUrl, activeKey, sections = [] }) {
  const activeSection = sections.find((section) => section.name === activeKey);
  if (!activeSection) return null;

  const items = sections.map(({ name, icon, badge }) => ({
    id: name,
    faIcon: `fa-${icon}`,
    title: cms.text(`nav.${name}`),
    badge,
  }));

  return (
    <div>
      <TabMenu>
        {items.map(({ id, title, badge }, i) => {
          const active = items.findIndex(i => i.id === activeKey)
          
          return(
            <Link
              key={id}
              to={`${baseUrl}/${id}`}
              className={`btn btn-flat ${i === active ? 'active' : ''}`}
            >
              {title}
              <span className="badge">{badge}</span>
            </Link>
          )
        })}
      </TabMenu>
    </div>
  );
}
