/**
 * Creates endpoints for managing a list of items
 */
import api from '../../utils/api';
import errHandler from '../_err-handler';


/**
 * @param {String} endpoint
 */
export default function (endpoint) {
  return {
    list(query) {
      return api.get(endpoint, query)
        .then(res => res.data)
        .catch(errHandler)
    },

    /**
     * Creates a user charge preset
     *
     * @param {Object} data
     */
    create(data) {
      return api.post(endpoint, data)
        .then(res => res.data)
        .catch(errHandler);
    },

    /**
     * Updates a user charge preset
     *
     * @param {String} itemId
     * @param {Object} data 
     */
    update(itemId, data) {
      return api.put(`${endpoint}/${itemId}`, data)
        .then(res => res.data)
        .catch(errHandler);
    },

    /**
     * Deletes a user charge preset
     *
     * @param {String} itemId
     */
    delete(itemId) {
      return api.delete(`${endpoint}/${itemId}`)
        .then(res => res.data)
        .catch(errHandler);
    }
  }
}
