/* eslint-disable camelcase */

import moment from 'moment-timezone';
import wurd, { WurdText } from 'wurd-react';

import * as language from '../utils/language';
import store from '../store';
import { basePath } from '../config';


const fa_currency_icons = {
  '€': 'fa-euro-sign', // EUR
  '£': 'fa-pound-sign', // GBP
  '$': 'fa-dollar-sign', // USD
  '₹': 'fa-rupee-sign', // INR
  '¥': 'fa-yen-sign', // JPY
  '₽': 'fa-ruble-sign', // RUB (Russia)
  '₩': 'fa-won-sign', // KRW (South-Korea)
  '₺': 'fa-lira-sign', // TRY (Turkey)
  '₪': 'fa-shekel-sign', // ILS (Israel)
  '₸': 'fa-tenge-sign', // KZT (Kazahkstan)
  '₴': 'fa-hryvnia-sign', // UAH (Ukraine)
};

export function currencyIcon() {
  const { settings } = store.get();

  return fa_currency_icons[settings.currency] || 'fa-dollar-sign';
}

export const iconColors = {
  primary: { color: '#337ab7' },
  success: { color: '#5cb85c' },
  info: { color: '#5bc0de' },
  warning: { color: '#f0ad4e' },
  danger: { color: '#d9534f' },
};


/**
 * Hides a notification
 *
 * @param {String} notificationId
 */
export function hideNotification(notificationId) {
  const { notifications = [] } = store.get();

  store.set({
    notifications: notifications.filter(notification => notification.id !== notificationId),
  });
}


/**
 * Opens a 'toast' style notification (pops up for a short duration)
 **/
export function notify({
  text,
  bsType = 'default', // or success|warning|info|danger
  undo = null,        // If passed, an undo button will be added with this as onClick
  duration = 3200,
  onHide, // optional callback executed on hide
}) {
  const { notifications = [] } = store.get();

  // Add the toast to the list
  const notificationId = Date.now();
  store.set({
    notifications: [
      ...notifications,
      {
        id: notificationId,
        text,
        bsType,
        undo,
      },
    ],
  });

  // Remove the notification when done
  if (duration > 0 && !wurd.editMode) {
    setTimeout(() => {
      hideNotification(notificationId);
      if (onHide) onHide();
    }, duration);
  }
}

window.notify = notify; // Just for debug


export function getLangText(obj, lang = language.get()) {
  if (!obj || typeof obj === 'string') return obj || null;

  const settings = store.get('settings') || {};
  const defaultLang = settings.defaultLang /* deprecated */ || settings.languages?.[0];

  return obj[lang] || obj[defaultLang] || Object.values(obj).find(Boolean);
}


export const rentPeriod = () => {
  const settings = store.get('settings') || {};
  return <WurdText id={`common.phrases.rentPer_${settings.invoicePeriod}`} className="text-muted" />;
}


/**
 * Formats a number as currency
 *
 * @param {String|Number} amount
 *
 * @return {String}
 */
export function currency(amount, settings = store.get('settings') || {}) {
  const symbol = settings.currency || '';
  const lang = settings.languages?.[0] || 'en';

  // if (settings.currencyCode) {
  //   const parts = Intl.NumberFormat(lang, {
  //     style: 'currency',
  //     currency: settings.currencyCode,
  //     currencyDisplay: 'narrowSymbol',
  //   }).formatToParts(amount);

  //   if (!symbol) return parts.map(({ value }) => value).join('');

  //   if (symbol === ' ') return parts.filter(({ type }) => type !== 'currency' 
  //     && type !== 'literal').map(({ value }) => value).join('');

  //   return parts.map(({ type, value }) => type === 'currency' ? symbol : value).join('');
  // }

  // Some currencies such as Yen don't use decimals
  const fractionDigits = ['￥'].includes(symbol) ? 0 : 2;

  const formattedNum = new Intl.NumberFormat(lang, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount);

  return symbol + formattedNum;
}


export function priceChange(newPrice, oldPrice, period) {
  const color = newPrice < oldPrice
    ? 'var(--bs-accent-red)'
    : newPrice > oldPrice
      ? 'var(--bs-accent-green)'
      : null;
  return (
    <>
      <span style={{ color, fontWeight: 500 }}>{currency(newPrice)}</span> {period} {oldPrice != newPrice && <del className="fw-light" style={{ filter: 'opacity(.5)' }}>{currency(oldPrice)}</del>}
    </>
  )
}


/**
 * Formats a date according to the selected setting
 *
 * @param {String} date
 * @param {String} format
 *
 * @return {String|Moment} date
 */
export function date(dateStr, format = 'short') {
  if (!dateStr) return null;
  const { settings } = store.get();
  const pattern = settings?.dateFormats[format] || format;
  return moment(dateStr).locale(language.get()).format(pattern);
}


/**
 * Formats a date according to the selected setting
 *
 * @param {String} date
 * @param {String} format
 *
 * @return {String|Moment} date
 */
export function dateWithSpecificInputFormat(dateObj, format = 'short') {
  if (!dateObj) return null;

  const { day, month, year } = dateObj;

  // Create a date object with the specified day, using the current year and month
  const date = moment([year, month - 1, day]);
  
  const { settings } = store.get();
  const pattern = settings?.dateFormats[format] || format;
  return moment(date).locale(language.get()).format(pattern);
}


/**
 * Formats a start and end date for display.
 *
 * NOTE:
 * If the endDate is the same date of the month as the startDate,
 * 1 day from the endDate will be removed so that invoice
 * periods appear in the form `2020-01-01 to 2020-01-31`, instead of their actual period
 * which is 1 complete month (`2020-01-01 to 2020-02-01`).
 *
 * It is just the expectation of some operators that the invoice period should end 1 day earlier.
 *
 * @param {String} date
 * @param {String} format
 *
 * @return {String} date
 */
export function datePeriod(startDate, endDate, format = 'short') {
  const { settings } = store.get();
  const lang = language.get();
  const momentFormat = settings.dateFormats[format] || format;

  const startMoment = moment(startDate).locale(lang);
  const result = [startMoment.format(momentFormat)];

  if (endDate) {
    const endMoment = moment(endDate).locale(lang);

    if (startDate !== endDate && endMoment.date() === startMoment.date()) {
      endMoment.add(-1, 'days');
    }

    result.push(endMoment.format(momentFormat));
  }

  return result;
}


/**
 * Returns the uppercase version of an item's/invoice's etc. sid (short ID)
 *
 * @param {Object} item  or invoice, job, etc.
 *
 * @return {String}
 */
export function sid(item) {
  return item.sid?.toUpperCase();
}


/**
 * Formats a date according to the selected setting
 *
 * @param {String} datetime
 *
 * @return {String} time
 */
export function time(val) {
  const { settings } = store.get();
  const { dateFormats } = settings;

  return moment(val).locale(language.get()).format(dateFormats.time);
}

export function datetime(val) {
  const { settings } = store.get();
  const { dateFormats } = settings;

  return moment(val).locale(language.get()).format(`${dateFormats.short} ${dateFormats.time}`);
}

export function datetime_utc(val) {
  const formatted = moment(val).locale(language.get()).utc().format('YYYY-MM-DD HH:mm');

  return `${formatted} UTC`;
}

export function datetime_operator(val) {
  const { settings } = store.get();
  const { timezone } = settings;

  const formatted = moment(val).locale(language.get()).tz(timezone).format('YYYY-MM-DD HH:mm');

  return `${formatted} ${timezone}`;
}

export const sortByCode = (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity) || a.code?.localeCompare(b.code);


/**
 * Gets a string from the CMS
 *
 * @param {String} key
 * @param {Object} [locals]
 *
 * @return {String}
 */
export function t(key, locals) {
  const val = wurd.text(key, locals);

  return val === key ? '' : val;
}


/**
 * Generates a colour for a given string
 *
 * @param {String} str
 *
 * @return {String}
 */
export function stringToColor(str) {
  // From https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}


/**
 * The relative brightness of any point in a color space, normalized to 0 for darkest black and 1 for lightest white. Formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 * 
 * taken from https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/colorManipulator.js#L170
 *
 * @param {string} hexColor
 * @returns {number} The relative brightness of the color in the range 0 - 1
 */
export function getLuminance(hexColor = '#eeeeee') {
  const rgb = hexColor.replace(/^#/, '').match(/../g)
    .map(v => parseInt(v, 16))
    .map(val => {
      val /= 255; // normalized
      return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4;
    });

  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
}


export function formatAddressForMap(address = '') {
  return address.replace(/\n/g, ',');
}

export function getGoogleMapsUrlForAddress(address) {
  return `https://www.google.com/maps?q=${formatAddressForMap(address)}&output=embed`;
}

export function getGoogleMapsUrlForLatLng(lat, lng) {
  return `https://www.google.com/maps?q=loc:${lat}+${lng}&output=embed`;
}

export function alert(msg) {
  return window.alert(msg);
}

export function prompt(msg, v) {
  return window.prompt(msg, v);
}

export function confirm(msg) {
  return window.confirm(msg);
}

export function href(path) {
  if (path.startsWith('#')) throw new Error('href()/redirect() should not include the #');

  return `${basePath.replace(/\/$/, '')}${path.replace(/^[^/]/, '/$&')}`;
}

export function redirect(path) {
  window.location = href(path); // prefer using react-router-dom useNavigate() to preserve console logs
}

export function redirectBack(times = 1) {
  window.history.go(-times);
}

/*
 * Scrolls an element into view, taking into account the header navbar offset etc.
 *
 * @param {DOMElement} el
 */
export function scrollToEl(el) {
  if (!el) return;

  el.scrollIntoView({ behavior: 'smooth' });
  // const elRect = el.getBoundingClientRect();
  // const bodyRect = document.body.getBoundingClientRect();

  // const offset = elRect.top - bodyRect.top;
  // const navbarHeight = 60;

  // const top = offset - navbarHeight;

  // window.scrollTo({
  //   top,
  //   behavior: 'smooth',
  // });
}

export const colors = {
  green: '#60C060',
  gray: '#ababab',
  blue: '#2da8e5',
  red: '#d9534f',
  yellow: '#f0ad4e',
};
