import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const RawLink = styled(Link)({
  color: 'inherit',
  '&:hover, &:active, &:focus': {
    color: 'inherit',
  }
})

export default function SidebarState({ type = 'info', link, onClick, children }) {
  return (
    <div
      className={`list-group-item list-group-item-${type}`}
      style={{
        textTransform: 'uppercase',
        fontWeight: 600,
        cursor: onClick ? 'pointer' : 'normal',
      }}
      to={link}
      onClick={onClick}
    >
      {link ? <RawLink to={link}>{children}</RawLink> : children}
    </div>
  );
}
