import { stateColors } from '../../../helpers/unit';

const colors = {
  available: stateColors.available,
  reserved: stateColors.reserved,
  occupied: stateColors.occupied,
  blocked: stateColors.blocked,
  overdue: stateColors.overdue,
  grey: 'grey',
  black: 'black',
  sidePanel: '#faf9ff',
  red: 'red',
  white: 'white',
};

export default colors;
