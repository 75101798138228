import { pick } from 'lodash';
import wurd from 'wurd-react'

import * as helpers from '../../../helpers';
import * as actions from '../../../actions';
import { useItemLoader, useListLoader } from 'hooks';

import ModalForm2 from '../../form2/modal-form2';
import { Field, Checklist, CurrencyInput } from '../../form2';
import AsyncSelect from 'components/form2/async-select';

const cms = wurd.block('unit');

const fields = [
  'typeId',
  'name',
  'width',
  'length',
  'height',
  'floor',
  'defaultDeposit',
  'defaultPrice',
  'featureIds',
];

export default function UnitDetailsForm({
  mode,
  initialValue,
  onSubmit = mode === 'create'
    ? data => actions.units.create({ siteId: initialValue.siteId, ...data })
    : data => actions.units.update(initialValue.id, data),
  multi,
  ...props
}) {
  const { item: site } = useItemLoader('sites', initialValue.siteId);
  const { items: unitTypes = [] } = useListLoader('unit-types', { siteId: initialValue.siteId });

  const sp = new URLSearchParams(location.search);

  async function submit({ names, name, ...data }, patches) {
    const unitNames = names ? helpers.unit.expandNameRange(names) : [name];
    
    for (const name of unitNames) {
      await onSubmit(pick({ name, ...data }, fields), patches);
    }

    if (mode === 'create' || patches.typeId) {
      await actions.update('sites', site.id, { updateAvailability: true });
    }
  }

  const unitType = sp.get('typeId') && unitTypes.find(t => t.id === sp.get('typeId'));

  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode={mode}
      initialValue={{
        typeId: unitType?.id,
        ...mode === 'create' && {
          defaultPrice: unitType?.price,
          defaultDeposit: unitType?.deposit,
          featureIds: unitType?.tagIds,
        },
        ...initialValue,
      }}
      {...props}
      onSubmit={submit}
    >
      {({ fieldProps, inputProps, formValue }) => {
        function dimensionProps(name) {
          return {
            type: 'number',
            step: .1,
            className: 'form-control',
            style: { width: 70, display: 'inline-block', marginRight: 6 },
            placeholder: name,
            ...inputProps(name),
          };
        }

        const typeIdProps = fieldProps('typeId');
        const handle_typeIdChange = (e) => {
          typeIdProps.onChange(e);
          if (mode !== 'create' || !e.target.value) return;
          const unitType = unitTypes.find(t => t.id === e.target.value);

          if (!formValue.defaultPrice) {
            fieldProps('defaultPrice').onChange({ target: { name: 'defaultPrice', value: unitType?.price } });
          }
          if (!formValue.defaultDeposit) {
            fieldProps('defaultDeposit').onChange({ target: { name: 'defaultDeposit', value: unitType?.deposit } });
          }
          if (!formValue.featureIds) {
            fieldProps('featureIds').onChange({ target: { name: 'featureIds', value: unitType?.tagIds } });
          }
        };

        const features = helpers.unit.tag.list(true);

        return (
          <div>
            <Field {...typeIdProps} onChange={handle_typeIdChange} className={null} required>
              <AsyncSelect endpoint="unit-types" params={{ siteId: site.id }} />
            </Field>

            <Field {...fieldProps(mode === 'update' || !multi ? 'name' : 'names')} required />

            <label className="control-label col-sm-3"><cms.Text id="size.label" /></label>
            <div className="col-sm-9" style={{ marginBottom: 12 }}>
              <input {...dimensionProps('width')} />&times;&nbsp;&nbsp;
              <input {...dimensionProps('length')} />&times;&nbsp;&nbsp;
              <input {...dimensionProps('height')} />
              <span className="text-muted">{site.measure}</span>
              {/*<select {...inputProps('measure')} required>
                <option value=""></option>
                <option value="ft">ft</option>
                <option value="m">m</option>
              </select>*/}
            </div>

            <Field {...fieldProps('floor')} />

            <Field {...fieldProps('defaultPrice')} type="number">
              <CurrencyInput min={0} required />
            </Field>

            <Field {...fieldProps('defaultDeposit')} type="number">
              <CurrencyInput min={0} required />
            </Field>

            {features.length > 0 && (
              <Field {...fieldProps('featureIds')} className={null}>
                <Checklist>
                  {features.map(tagConfig =>
                    <span key={tagConfig.id} id={tagConfig.id}>
                      <i className={`fa fa-fw ${tagConfig.icon}`} />&nbsp;&nbsp;
                      {tagConfig.title}
                    </span>
                  )}
                </Checklist>
              </Field>
            )}
          </div>
        );
      }}
    </ModalForm2>
  )
}
