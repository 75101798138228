export * as hero1 from './hero';
export * as hero2 from './hero';

export * as features1 from './features';
export * as features2 from './features';
export * as blogs1 from './features';
export * as prices1 from './features';

export * as header1 from './header';

export * as footer1 from './footer-cols';
export * as footer2 from './footer-links';

export * as sites1 from './sites';

export * as text1 from './text';

export * as spacer1 from './spacer';

export * as faq1 from './faq';


// aliases
export default {
  hero1: 'hero-overlay',
  hero2: 'hero-split',

  features1: 'features-grid',
  features2: 'features-split',

  footer1: 'footer-cols',
  footer2: 'footer-links',

  spacer1: 'spacer-lg',
};
