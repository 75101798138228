import Section from 'components/section';
import CustomFieldsList from './list';
import CustomFieldsEditModal from './modal';


export default function CustomFieldsSection({
  cms,
  cfConfig,
  modelName,
  model,
  modal,
  onChange,
  onSubmit,
}) {
  function edit() {
    modal.open(
      <CustomFieldsEditModal
        cms={cms}
        cfConfig={cfConfig}
        modelName={modelName}
        model={model}
        modal={modal}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );
  }

  return cfConfig?.length > 0 && (
    <Section
      cms={cms}
      onClickEdit={model.deleted ? null : edit}
    >
      <CustomFieldsList
        cfConfig={cfConfig}
        modelName={modelName}
        model={model}
      />
    </Section>
  );
}