import { useHistoryListQuery } from '../../hooks';

import InvoiceList from '../invoice-list';


export default function SiteInvoices({ modal, site }) {
  const listQuery = useHistoryListQuery({
    implicitParams: {
      siteId: site.id,
    },
    initialParams: {
      state: 'draft,sent,failed',
    },
  });

  return (
    <InvoiceList
      hideSite={false}
      listQuery={listQuery}
      modal={modal}
    />
  );
}
