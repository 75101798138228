import Link from 'components/nested-link';


export default ({ job }) => {
  const { data, result } = job;
  const { invoiceDate } = data;
  const { invoiceId, error } = result;

  return (
    <div>
      <div title="invoiceDate">{invoiceDate}</div>

      {invoiceId && (
        <div>
          <Link to={`/invoices/${invoiceId}`}>
            <i className="fas fa-fw fa-money-bill-alt" /> {invoiceId}
          </Link>
        </div>
      )}

      {error && (
        <div className="label label-danger">
          Charge failed: {error}
        </div>
      )}
    </div>
  );
};
