import * as config from 'config';

import StateLabel from 'components/state-label';
import LangText from 'components/lang-text';
import SiteLoader from 'components/loaders/site';
import styled from '@emotion/styled';

const Container = styled.div`
  position: relative;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 8%);
  color: inherit;
  width: 270px;
  cursor: pointer;
`;

const styles = {
  image: {
    flex: 1,
    width: 240,
    height: 120,
    background: '#fff',
    objectFit: 'contain',
  },
  installedIcon: {
    filter: 'brightness(1.5)',
    fontSize: '2rem',
    position: 'absolute',
    top: -4,
    right: -8,
    background: '#fff',
    borderRadius: '50%',
  },
  siteLabel: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
};


export default function AddonCard({
  cms,
  addonConfig,
  onClick,
}) {
  // If the addon is installed it will have been added locally to the addonConfig
  const addon = addonConfig._addon;

  return (
    <Container
      onClick={() => onClick(addonConfig)}
    >
      <img
        src={addonConfig.image || `${config.publicUrl}/addon.png`}
        style={styles.image}
      />

      {addon?.state === 'enabled' && (
        <i
          title="Installed and enabled"
          className="fas fa-check-circle text-success"
          style={styles.installedIcon}
        />
      )}

      {addon?.state === 'paused' && (
        <i
          title="Installed but paused"
          className="fas fa-pause-circle text-warning"
          style={styles.installedIcon}
        />
      )}

      {addon?.state === 'disabled' && (
        <i
          title="Installed but disabled"
          className="fas fa-minus-circle text-danger"
          style={styles.installedIcon}
        />
      )}

      {addon?.siteId && (
        <StateLabel
          type="info"
          style={styles.siteLabel}
        >
          <SiteLoader id={addon.siteId}>{site => <LangText>{site.title}</LangText>}</SiteLoader>
        </StateLabel>
      )}

      {addon && !addon?.siteId && (
        <StateLabel
          type="info"
          style={styles.siteLabel}
        >
          All sites
        </StateLabel>
      )}

      <div style={{ padding: '1rem .5rem', textAlign: 'center', fontSize: '1.1em' }}>
        <strong><LangText>{addonConfig.title}</LangText></strong>
      </div>
    </Container>
  );
}
