import store from '../store';
import { getLangText } from './ui';
import moment from 'moment-timezone';


export function getAccountingCodes() {
  const { settings } = store.get();
  const { accountingCodes } = settings;

  return accountingCodes
    .sort((a,b) => a.code.localeCompare(b.code))
    .map((accountingCode) => {
      // Add a standard way of describing the accountingCode with tax info
      const title = getLangText(accountingCode.title);
      let desc = title || accountingCode.code;

      if (accountingCode.taxPct) {
        desc += ` (${accountingCode.taxPct}% ${accountingCode.taxDesc})`;
      }

      return {
        ...accountingCode,
        desc,
      };
    });
}

export function getAccountingCodeById(id) {
  if (!id) return null;

  const accountingCodes = getAccountingCodes();

  return accountingCodes.find((code) => code.id === id);
}

export function getAccountingCodeByCode(code) {
  if (!code) return null;

  const accountingCodes = getAccountingCodes();

  return accountingCodes.find((ac) => ac.code === code);
}


export function getAccountingCodeByCodeNameOrDesc(searchTerm) {
  const accountingCodes = getAccountingCodes();

  return accountingCodes.filter((ac) => {
    return ac.code.includes(searchTerm) || ac.taxtDesc?.includes(searchTerm) || ac.desc.includes(searchTerm);
  });

}


/**
 * 
 * @param {{validFrom: string, validTo: string, siteIds?: array}} promo 
 * @param {string} siteId 
 * @param {*} [startDate] 
 */
export function isPromoValid(promo, siteId, startDate) {
  if (promo.siteIds && !promo.siteIds.includes(siteId)) return false;

  return moment(startDate).isBetween(promo.validFrom || 0, promo.validTo || '9999-01-01', null, '[]');
}
