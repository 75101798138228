import { Link } from 'react-router-dom';

import * as helpers from '../../../helpers';

import PieChart from './pie';
import SimpleNavList from 'components/simple-nav-list';

const { states, stateColors, stateIcons } = helpers.unit;


export default function SiteCurrentOccupancyChart({
  site,
  getStateLink = state => `/sites/${site.code}/units?state=${state}`,
}) {
  const stateTitles = helpers.unit.getStateTitles();
  const occupancy = helpers.site.getOccupancy(site);

  const entries = Object.entries(stateColors).filter(([state]) => state !== 'overdue')
  const data = {
    labels: entries.map(([state, color]) => stateTitles[state]),
    datasets: [
      {
        label: '',
        data: entries.map(([state, color]) => occupancy[state]),
        backgroundColor: entries.map(([state, color]) => color),
        borderWidth: 1,
      }
    ]
  };

  return (
    <PieChart
      data={data}
      summaryPercent={occupancy.occupancyRate}
      summaryTitle="occupancy"
      renderLegend={() => (
        <SimpleNavList style={{ width: 'auto' }}>
          {states.filter(state => state !== 'overdue').map(state => {
            return (
              <Link key={state} role="row" to={getStateLink(state)}>
                <div role="cell" className="text-left">
                  <i
                    className={`fa-fw ${stateIcons[state]}`}
                    style={{ color: stateColors[state] }}
                  />&nbsp;&nbsp;
                    {stateTitles[state]}
                </div>
                <div role="cell" className="text-right">
                  {occupancy[state]}
                </div>
              </Link>
            );
          })}
        </SimpleNavList>
      )}
    />
  );
}
