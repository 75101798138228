import { useHistoryListQuery } from 'hooks';

import UnitTypesList from 'components/unit-type-list';


export default function SiteUnitTypes({
  site,
  modal,
}) {
  const unitTypeslistQuery = useHistoryListQuery({
    implicitParams: {
      siteId: site.id,
    },
  });
  

  return (
    <UnitTypesList
      listQuery={unitTypeslistQuery}
      modal={modal}
      site={site}
      hideSite
    />
  );
}
