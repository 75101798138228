import wurd from 'wurd-react';
import { useParams } from 'react-router-dom';

import { useModal, useHistoryListQuery, useListLoader } from 'hooks';

import DetailPage from 'components/detail-page';
import Spinner from 'components/spinner';
import DeveloperList from 'components/addons/list/developer';

const cms = wurd.block('addons');


export default function AddonConfigListController() {
  const { section = 'developer' } = useParams();

  const modal = useModal();

  const listQuery = useHistoryListQuery();
  const addonConfigsLoader = useListLoader('addon-configs', listQuery.params, { maxAge: 0 });
  const addonsLoader = useListLoader('addons', {}, { maxAge: 0 });

  const addonConfigs = addonConfigsLoader.items || [];
  const addons = addonsLoader.items || [];

  const isLoading = addonConfigsLoader.isLoading || addonsLoader.isLoading;

  function refetch() {
    addonConfigsLoader.refetch();
    addonsLoader.refetch();
  }

  if (isLoading) {
    return <Spinner />
  }

  const developerAddonConfigs = addonConfigs.filter(ac => ac.webtasks); // if webtasks are present, then the currentUser is the addonConfig developer


  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="manager"
      titleKey={cms.id('addonConfigs.title')}
      sections={[
        { name: 'developer', icon: 'far fa-code', badge: developerAddonConfigs.length },
      ]}
      currentSection={section}
      baseUrl={`/addon-configs`}
    >
      <DeveloperList
        modal={modal}
        cms={cms}
        addonConfigs={developerAddonConfigs}
        addons={addons}
        refetch={refetch}
      />
    </DetailPage>
  );
}
