import wurd, { WurdText } from 'wurd-react';
import { pick } from 'lodash';
import moment from 'moment-timezone';

import { userLangs } from 'config';
import * as actions from 'actions';
import { useApiForm } from 'hooks';

import Section from 'components/section';
import SectionCrudList from 'components/section_crud-list';
import StateLabel from 'components/state-label';
import { Field, Checklist } from 'components/form2';
import FormObject from 'components/form2/object';
import Select from "components/form2/select";
import CustomDomainForm from './custom-domain-form';
import WebtaskForm from './webtask-form';

const cms = wurd.block('settings.advanced2');

export default function BusinessAdvancedSettings({ settings }) {
  const initialValue = pick(settings, [
    'timezone',
    'languages',
    'wurdLanguages',
    'modes',
    'urls',
  ]);

  const { fieldProps, submit, loading, dirty, formValue } = useApiForm({
    initialValue,
    wurdSection: cms.id(),
    onSubmit: actions.business.update,
  });

  return (
    <>
      <Section cms={cms}>
        <Field {...fieldProps('timezone')}>
          <Select
            options={moment.tz.names().map((name) => {
              const offset = moment().tz(name).utcOffset();
              const m = offset % 60;
              const h = (offset - m) / 60;
              return {
                value: name,
                label: `${name} (${offset >= 0 ? "+" : ""}${h}${
                  m ? `:${Math.abs(m)}` : ""
                })`,
              };
            })}
          />
        </Field>

        <Field {...fieldProps('languages')}>
          <Select
            options={userLangs.map((lang) => ({
              value: lang,
              label: `${lang}: ${wurd.text(`common.languages.${lang}`)}`,
            }))}
            isMulti
          />
        </Field>

        <br />

        <FormObject {...fieldProps("wurdLanguages")}>
          {({ getProps }) => (
            <div>
              {settings.languages.map((lang) => (
                <Field
                  key={lang}
                  {...getProps(lang)}
                  wurdSection={cms.id("wurdLanguages")}
                  placeholder={wurd.get(`common.languages.${lang}`)}
                />
              ))}
            </div>
          )}
        </FormObject>

        <hr />

        <h4><cms.Text id="modes.label" /></h4>
        <Checklist {...fieldProps('modes')} style={{ padding: '4px 15px 0', marginLeft: '33%' }}>
          <div id="valetStorage">
            <strong>Valet storage</strong> <span className="text-muted">Deliveries, collections, items</span>
          </div>
          <div id="selfStorage">
            <strong>Self storage</strong> <span className="text-muted">Sites, units</span>
          </div>
        </Checklist>

        <hr />

        <h4><cms.Text id="urls.label" /></h4>
        <FormObject {...fieldProps('urls')}>
          {({ getProps }) => (
            <div>
              <Field {...getProps('adminApp')} wurdSection={cms.id('urls')} />
              <Field {...getProps('valetApp')} wurdSection={cms.id('urls')} />
              <Field {...getProps('unitsApp')} wurdSection={cms.id('urls')} />
            </div>
          )}
        </FormObject>

        <button
          className="btn btn-primary pull-right"
          type="button"
          disabled={loading || !dirty}
          onClick={submit}
        >
          {loading
            ? <i className="fa fa-spinner fa-spin" />
            : <WurdText id="common.saveBtn" />
          }
        </button>
      </Section>

      <SectionCrudList
        cms={wurd.block('settings.customDomains')}
        items={settings.customDomains}
        Form={CustomDomainForm}
        actions={actions.business.customDomains}
        onChange={actions.business.fetch}
      >
        {customDomain => (
          <>
            <strong>{customDomain.host}</strong> {customDomain.app}
          </>
        )}
      </SectionCrudList>

      <SectionCrudList
        cms={wurd.block('settings.webtasks')}
        items={settings.webtasks}
        Form={WebtaskForm}
        actions={actions.business.webtasks}
        onChange={actions.business.fetch}
        // style={{ marginBottom: 20 }}
        title={<h4>{wurd.text('settings.webtasks.title')}</h4>}
        modalProps={{ bsSize: 'lg' }}
      >
        {webtask => (
          <>
            <div className="pull-right">
              {webtask.roles?.map((role) => (
                <StateLabel key={role} type="warning">
                  {wurd.text(`user._roles.${role}`)}
                </StateLabel>
              ))}
              {webtask.permissions?.map((permission) => (
                <StateLabel key={permission} type="warning">
                  {permission}
                </StateLabel>
              ))}
            </div>

            <div><strong>{webtask.name}</strong></div>
            <div className="text-muted">{webtask.events.join(', ')}</div>
          </>
        )}
      </SectionCrudList>
    </>
  );
}
