import styled from '@emotion/styled';

import { iconColors } from '../../../helpers/ui';


export default function ItemTypeHeader({
  summary,
}) {
  const {
    type,
    numPicked,
    numOrdered,
    image,
    title,
    hasPickedAll,
  } = summary;

  const Wrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 16,
    marginTop: 20,
    marginBottom: 10,

    '&:first-child': {
      marginTop: 0,
    },

    '.success': iconColors.success,
    // '.warning': { color: '#999' },
    '.warning': iconColors.primary,

    '.image': {
      flex: '0 0 50px',
      width: 50,
      height: 50,
      objectFit: 'cover',
    },

    '.desc': {
      flex: '1 1',
      marginLeft: 10,
      // fontWeight: 'bold',
    },

    '.count': {
      justifySelf: 'right',
      fontWeight: 'bold',
    },

    '.state': {
      fontSize: 22,
      marginLeft: 6,
    },
  });

  const count = `${numPicked} / ${numOrdered}`;

  return (
    <Wrapper>
      <img className="image" src={image} alt={type} />

      <div className="desc">
        {title}
      </div>

      {hasPickedAll ? (
        <>
          <div className="count success">{count}</div>
          <i className="state success far fa-check-circle" />
        </>
      ) : (
        <>
          <div className="count warning">{count}</div>
        </>
      )}
    </Wrapper>
  );
}
