import wurd from 'wurd-react';
import { Link } from 'react-router-dom';

import { useModal, useHistoryListQuery } from '../hooks';

import Page from '../components/page';
import InvoiceList from '../components/invoice-list';

const cms = wurd.block('invoiceList');

const initialParams = {
  state: 'draft,sent,failed',
};

export default function InvoiceListPage() {
  const modal = useModal();
  const listQuery = useHistoryListQuery({ initialParams });

  return (
    <Page
      titleKey={cms.id('title')}
      userRole="admin"
      modal={modal}
      extra={(
        <Link to="/payments" className="btn btn-link">
          <cms.Text id="paymentsLink" />
        </Link>
      )}
    >
      <InvoiceList
        listQuery={listQuery}
        modal={modal}
      />
    </Page>
  );
}
