/**
 * A filter which presents a simple dropdown style list.
 */
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import MomentLocaleUtils from 'react-day-picker/moment';


import { Button } from 'react-bootstrap';
import DayPicker, { DateUtils } from 'react-day-picker';

import store from '../../store';
import { currentLang } from 'utils/language';

import FilterButton from './button';


export default function DateFilter({ title, onSelect, initialRange, range = initialRange, ...props }) {
  // When loading filters saved to localStorage they need to be converted to dates
  if (range) {
    range.start = moment(range.start).toDate();
    range.end = moment(range.end).toDate();
  }

  function onDayClick(day) {
    // DateUtils.addDayToRange(d1, {from: null, to: null}) -> {from: d1, to: d1}
    // DateUtils.addDayToRange(d1, {from: d1, to: d1}) -> {from: null, to: null}
    // DateUtils.addDayToRange(d1, {from: d2, to: d3}) -> {from: d1, to: d2}
    // DateUtils.addDayToRange(d1, {from: d2, to: d3}) -> {from: d1, to: d3}
    // DateUtils.addDayToRange(d1, {from: d2, to: d1}) -> {from: d1, to: d1}
    const { from, to = from } = DateUtils.addDayToRange(day, { from: range?.start, to: range?.end });
    const newRange = from == null && to == null ? null : { start: from, end: to };
    onSelect(newRange);
  }

  function getTitle() {
    const formatDate = (date) => moment(date).locale(currentLang).format('D MMM');

    let finalTitle = title;

    if (range) {
      finalTitle += `: ${formatDate(range.start)}`;

      if (range.end) {
        finalTitle += ` - ${formatDate(range.end)}`;
      }
    }

    return finalTitle;
  }

  const modifiers = {
    today: (day) => moment(day).isSame(moment(), 'day'),

    selected: (day) => DateUtils.isDayInRange(day, {
      from: range && range.start,
      to: range && range.end,
    }),
  };

  return (
    <FilterButton {...props} onSelect={onSelect} title={getTitle()} className="filter--date" active={!!range}>
      <DayPicker
        modifiers={modifiers}
        onDayClick={onDayClick}
        enableOutsideDays
        localeUtils={MomentLocaleUtils}
        locale={currentLang}
      />
      {/* <div className="filter--date__buttons">
        <Button bsStyle="default" onClick={() => onSelect(null)}>Clear</Button>
        &nbsp;
        <Button bsStyle="primary" onClick={apply}>Apply</Button>
      </div> */}
    </FilterButton>
  );
}

DateFilter.propTypes = {
  title: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  range: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    end: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};
