import wurd from 'wurd-react';
import Avatar from 'components/avatar';
import StateLabel from 'components/state-label';
import { emailConfirmedProps } from 'helpers/user';

export default function TeamListItem({ team }) {
  const labels = (
    <>
      {team.roles?.map((role) => (
        <StateLabel type="warning" key={role}>
          {wurd.text(`user._roles.${role}`)}
        </StateLabel>
      ))}
      {team.permissions?.map((permission) => (
        <StateLabel
          type="warning"
          key={permission}
          style={{ filter: 'sepia(1)' }}>
          {permission}
        </StateLabel>
      ))}
    </>
  )

  return (
    <>
      <td style={{ width: 45 }}>
        <Avatar user={team} size={30} />
      </td>

      <td>
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <strong>
            {team.firstName} {team.lastName}
            {team.companyName && ` (${team.companyName})`}
          </strong>
          <span className="visible-xs-inline">
            {labels}
          </span>
        </div>
        <div className="visible-xs">
          <span {...emailConfirmedProps(team)}>{team.email}</span>
        </div>
      </td>

      <td className="hidden-xs">
        <span {...emailConfirmedProps(team)}>{team.email}</span>
      </td>

      <td className="hidden-xs">
        {labels}
      </td>
    </>
  );
}
