import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import wurd from 'wurd-react';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import { Modal } from 'react-bootstrap';
import ChargeForm from '../form/visit/charge';

const cms = wurd.block('visitView.charges');


const VisitCharges = createClass({

  propTypes: {
    visit: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      modalContent: null
    };
  },

  render() {
    const { visit } = this.props;
    const { modalContent } = this.state;

    return (
      <section className="block-flat">
        <div className="header">
          {helpers.auth.hasRole('admin') &&
            <button
              className="btn btn-link pull-right"
              onClick={this.createCharge}
            >
              <i className="fa fa-plus"></i>
            </button>
          }

          <cms.Text type="h3" id="title" />
        </div>
        <div className="content">
          <ul className="list-group">
            {visit.charges.map(charge =>
              <li key={charge.id} className="list-group-item clearfix">
                <button className="pull-right btn btn-link" onClick={this.deleteCharge.bind(null, charge)}>
                  <cms.Object keys="confirmDelete">
                    <i className="fa fa-trash"></i>
                  </cms.Object>
                </button>

                {charge.note || charge.type}<br />
                {helpers.ui.currency(charge.amount)}
              </li>
            )}
          </ul>
        </div>

        <Modal show={!!modalContent} onHide={this.closeModal}>
          {modalContent}
        </Modal>
      </section>
    );
  },

  createCharge() {
    const { visit } = this.props;

    var content = (
      <ChargeForm
        defaultValue={{}}
        onSubmit={actions.visits.createCharge.bind(null, visit.id)}
        onSuccess={this.onChargeCreated}
        title={<cms.Text id="addModal.title" vars={{ visitId: visit.sid.toUpperCase() }} />}
        submitButton={<cms.Text id="addModal.ok" />}
        modal
        onHideModal={this.closeModal}
      />
    );

    this.setState({
      modalContent: content
    });
  },

  onChargeCreated() {
    this.closeModal();
    this.props.onChange();
  },

  deleteCharge(charge) {
    var { props } = this;

    if (!window.confirm(cms.text('confirmDelete'))) return;

    actions.visits.deleteCharge(props.visit.id, charge.id)
      .then(props.onChange);
  },

  closeModal() {
    this.setState({ modalContent: null });
  }

});

export default VisitCharges;
