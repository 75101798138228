import { WurdText, WurdMarkdown } from 'wurd-react';
import styled from '@emotion/styled';

import StateLabel from 'components/state-label';
import { Checklist } from '../../form2';


const Help = styled.div({
  lineHeight: '16px',
  color: '#777b',
  p: {
    display: 'contents',
    lineHeight: '16px',
    fontSize: '10px',
  }
});

const allEvents = {
  'business.settings.updated': { deprecated: true },
  'business.updated': {},
  'site.settings.updated': {},
  'site.availability.updated': {},

  'addon.installed': {},
  'addon.customFields.validate': { deprecated: true },
  'addon.dailyEvent.started': {},
  'job.addon_update.submitted': { deprecated: true },

  'items.updated': {},
  
  'job.unit_moveIn.create_started': {},
  'job.unit_moveIn.created': {},
  'job.unit_moveIn.started': {},
  'job.unit_moveIn.completed': {},
  'job.unit_moveIn.cancelled': {},
  
  'job.unit_moveOut.created': {},
  'job.unit_moveOut.completed': {},
  'job.unit_moveOut.cancelled': {},

  'job.unit_transfer.completed': {},
  
  'job.valetOrder.submitted': {},
  'job.valetOrder.created': {},
  'job.valetOrder.started': {},
  'job.valetOrder.completed': {},
  'job.valetOrder.cancelled': {},
  
  'unit.reserved': {},
  'unit.occupied': {},
  'unit.blocked': {},
  'unit.unassigned': {},
  'unit.unblocked': {},
  'unit.archived': {},

  // sent for security systems
  'unitRental.markOverdue': {},
  'unitRental.unmarkOverdue': {},
  'unitRental.updated': {},
  'unitRental.invoice.created': {}, // for unitRental/self storage invoices
  
  'user.created': {},
  'user.updated': {},
  'user.billing.updated': {},

  // Valet specific
  'valet.invoice.created': {}, // for user/valet invoices
  
  // Deprecated
  'invoice.created': { deprecated: true },
};


export default function DeveloperEventsChecklist(props) {
  const { value = [] } = props;
  
  // Filter out deprecated events that are not already checked
  const events = Object.entries(allEvents)
    .map(([event, options]) => ({ id: event, ...options }))
    .filter(event => !event.deprecated || value.includes(event.id));

  return (
    <Checklist {...props}>
      {events.map(event => (
        <div key={event.id} id={event.id} disabled={event.deprecated}>
          <div>{event.id} {event.deprecated && (<StateLabel type="danger"><WurdText id="developer.deprecated" /></StateLabel>)}</div>
          <WurdMarkdown id={`developer.events.${event.id}.help`} type={Help} />
        </div>
      ))}
    </Checklist>
  );
}
