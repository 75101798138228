import { useRef } from 'react';
import { WurdText, WurdObject } from 'wurd-react';
import { Button } from 'react-bootstrap';

import * as helpers from 'helpers';
import { previewChanges } from 'actions/emails';

import Tabs from 'components/tabs';
import DynamicLoader from 'components/dynamic-loader';


const CodeEditor = DynamicLoader(() => import('components/code-editor'));


export default function EmailEditor({ defaultTemplate, getProps, VariablePicker, formValue, initialValue, setFormValue, clear = () => { } }) {
  const langKeys = helpers.settings.langs();
  const editorRef = useRef();

  function reset(lang, { subject, text } = {}) {
    clear();
    setFormValue({
      subject: { [lang]: subject || defaultTemplate[lang]?.subject || defaultTemplate.en?.subject || '' },
      text: { [lang]: text || defaultTemplate[lang]?.text || defaultTemplate.en?.text || '' },
    })
  }

  return (
    <WurdObject type="div" id="settings.emails" keys="confirmChangeLang" style={{ position: 'relative' }}>
      <Tabs
        style={{ position: 'static' }} // no sticky here, else it looks ugly
        items={langKeys.map((lang) => ({
          id: lang,
          title: <WurdText id={`common.languages.${lang}`} />,
          dirty: initialValue.subject[lang] !== formValue.subject[lang] || initialValue.text[lang] !== formValue.text[lang],
          renderContent: () => {
            return (
              <>
                <input
                  {...getProps('subject', lang)}
                  className="form-control"
                  required
                  placeholder="Subject"
                  style={{ marginBottom: '1rem' }}
                />

                <CodeEditor
                  {...getProps('text', lang)}
                  key={lang}
                  editorRef={editorRef}
                />
              </>
            )
          }
        }))}
        renderExtra={lang => {
          return (
            <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 400, display: 'flex' }}>
              <Button bsSize="sm" onClick={() => reset(lang)}><WurdText id="settings.emails.reset" /></Button>
              <Button bsSize="sm" style={{ marginLeft: 4 }} onClick={() => previewChanges(formValue.templateKey, lang, { subject: formValue.subject[lang], text: formValue.text[lang] })}><WurdText id="settings.emails.preview" /></Button>
              <VariablePicker editorRef={editorRef} />
            </div>
          );
        }}
      />
    </WurdObject>
  );
}
