import React from 'react';
import wurd from 'wurd-react';

import { useSimpleListQuery, useListLoader } from '../../hooks';
import * as helpers from '../../helpers';

import TableList from '../table-list';
import Filters from './filters';
import Item from './item';

const cms = wurd.block('itemList');

function viewItem(item) {
  return `/items/${item.sid.toUpperCase()}`;
}


export default function ItemListPicker({
  selectedIds = [],
  initialParams = {},
  market = null, // To show correct prices for items, pass the market (usually the user's market)
  hideOwner = false,
  hidePrice = false,
  hideValetOrder = false,
  hideFilters = false,
  onClickAdd = null,
  renderBulkActions = undefined,
  onCancelSelection = undefined,
}) {
  const listQuery = useSimpleListQuery({ initialParams });

  const listLoader = useListLoader('items', listQuery.params, { maxAge: 0 });

  const itemConfigs = helpers.item.byType(true);
  const stateTitles = helpers.item.getStateTitles();

  const renderItem = (item) => (
    <Item
      item={item}
      itemConfigs={itemConfigs}
      stateTitles={stateTitles}
      market={market}
      hideOwner={hideOwner}
      hidePrice={hidePrice}
      hideValetOrder={hideValetOrder}
    />
  );


  return (
    <div style={{ maxHeight: '95vh', overflow: 'auto' }}>
      <TableList
        cms={cms}
        listQuery={listQuery}
        loader={listLoader}
        filters={!hideFilters && (
          <Filters
            listQuery={listQuery}
            hideOwner={hideOwner}
            hidePrice={hidePrice}
            hideValetOrder={hideValetOrder}
          />
        )}
        renderItem={renderItem}
        getItemLink={viewItem}
        //onClickItem={(item) => console.log('click', item)}
        renderBulkActions={renderBulkActions}
        onClickAdd={onClickAdd}
        initialSelectedIds={selectedIds}
        stickyTop={0}
        onCancelSelection={onCancelSelection}
      />
    </div>
  );
}
