import StepList from '../step-list2';
import * as stepComponents from './steps';


export default function UnitMoveInStepList(props) {
  return (
    <StepList
      {...props}
      stepComponents={stepComponents}
    />
  );
}
