import { Component } from 'react';
import PropTypes from 'prop-types';

import UserSelect from '../user-select';


export default class Form2UserSelect extends Component {

  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  render() {
    const { value, autoFocus } = this.props;

    return (
      <UserSelect
        onChange={this.onChange}
        value={value}
        autoFocus={autoFocus}
      />
    );
  };

  onChange = (userId) => {
    const { name, onChange } = this.props;

    onChange({
      target: {
        name: name,
        value: userId
      }
    });
  };

}
