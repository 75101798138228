import PropTypes from 'prop-types';
import wurd, { WurdText } from 'wurd-react';

import * as helpers from 'helpers';
import { useApiForm } from 'hooks';

import Alert from 'components/alert';
import ErrorMessage from 'components/error-message';


const { hasRole } = helpers.auth;

export default function ModalForm2({
  wurdSection, id = wurdSection, children,
  closeModal, mode, onDelete, initialValue, includeFieldsConfig,
  title, dirty: initialDirty,
  ...rest
}) {
  const fieldsToInclude = !includeFieldsConfig ? null : Object.keys(includeFieldsConfig).filter(name => {
    const field = includeFieldsConfig[name];
    if (!field) return false;

    if (field.mode && field.mode !== mode) return false;
    if (field.role && !hasRole(field.role)) return false;

    return true;
  });

  const formProps = useApiForm({
    wurdSection,
    initialValue,
    fields: fieldsToInclude,
    ...rest,
  });

  const { submit, errMsg, errors, loading, dirty } = formProps;

  const cms = wurd.block(wurdSection);
  const helpUrl = cms.text('helpUrl');
  const info = cms.text('info');
  const warning = cms.text('warning');

  return (
    <>
      <div className="modal-header">
        <button type="button" className="close" onClick={closeModal}>&times;</button>

        {helpUrl && (
          <cms.Object keys="helpUrl" className="pull-right" style={{ marginTop: -7 }}>
            <a
              className="btn btn-link"
              href={helpUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="far fa-fw fa-question-circle" title={wurd.text('common.phrases.help')} />
            </a>
          </cms.Object>
        )}
          
        <h4 className="modal-title">{title || <WurdText id={`${wurdSection}.modal.title_${mode}`} vars={initialValue} />}</h4>
      </div>

      <form
        id={id}
        className={`modal-body form-horizontal ${errMsg ? 'error' : ''}`}
        onSubmit={submit}
      >
        {info && (
          <Alert type="info"><cms.Markdown id="info" /></Alert>
        )}

        {warning && (
          <Alert type="warning"><cms.Markdown id="warning" /></Alert>
        )}
        
        {children(formProps)}

        {errMsg && (
          <ErrorMessage>
            {errMsg}
            {errors && (
              <ul>
                {Object.entries(errors).map(([k, v]) => <li key={k}>{k}: {v?.message || v}</li>)}
              </ul>
            )}
          </ErrorMessage>
        )}
      </form>

      <div className="modal-footer">
        {onDelete && (
          <button
            type="button"
            className="btn btn-danger pull-left"
            onClick={() => onDelete().then(closeModal)}
          >
            <WurdText id="common.deleteBtn" />
          </button>
        )}

        <button
          type="button"
          className="btn btn-default"
          onClick={closeModal}
        >
          <WurdText id="common.cancelBtn" />
        </button>

        <button
          className="btn btn-primary"
          type="submit"
          form={id}
          disabled={!(initialDirty || dirty) || loading}
        >
          {loading
            ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <WurdText id={`${wurdSection}.modal.submit_${mode}`} />
            )}
        </button>
      </div>
    </>
  );
}


ModalForm2.propTypes = {
  // 'create' or update'
  mode: PropTypes.string.isRequired,

  // Render prop callback function
  children: PropTypes.func.isRequired,

  // An object defining conditions to meet when determining if a field should be included
  includeFieldsConfig: PropTypes.object,

  titles: PropTypes.object,
};
