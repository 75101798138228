import { Component } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import * as helpers from '../../helpers';

import ListFilter from '../filter/list';
import LabelsFilter from 'plugins/labels/filter';

const cms = wurd.block('jobList.filters');

const states = ['active', 'completed', 'failed'];

const types = [
  'invoiceJobs_create',
  'invoice_create',
  'invoice_chargeWithGmopg',
  'invoice_reminders',
  'invoice_handleOverdue',
  'invoice_handleDisputes',
  'invoice_lateFee',
  'invoice_overdue',
  'jobs_cleanup',
  'report_create',
  'system_warning',
  'unit_block',
  'unit_invoice',
  'unit_moveIn',
  'unit_moveOut',
  'unit_renew',
  'unit_transfer',
  'unit_unblock',
  'units_syncSecuritySystem',
  'valet_assignOrders',
  'valet_order',
  'valet_orders_reminders',
  'webhook',
  'zoho_export',
];


export default class JobListFilters extends Component {
  static propTypes = {
    listQuery: PropTypes.shape({ params: PropTypes.object }).isRequired,
  }

  onChange(key, value) {
    const { listQuery } = this.props;

    listQuery.update({
      offset: 0,
      [key]: value,
    });
  }

  render() {
    const { listQuery } = this.props;
    const { params } = listQuery;

    return (
      <>
        <cms.Object id="state" keys={['title', 'all'].concat(states)}>
          <ListFilter
            title={cms.text('state.title')}
            active={params.state}
            onSelect={(val) => this.onChange('state', val)}
          >
            {states.map((state) => (
              <li key={state} value={state}>{cms.text(`state.${state}`)}</li>
            ))}
          </ListFilter>
        </cms.Object>

        <cms.Object id="type" keys={['title', 'all'].concat(types)}>
          <ListFilter
            title={cms.text('type.title')}
            active={params.type}
            onSelect={(val) => this.onChange('type', val)}
          >
            <li value={null}>{cms.text('type.all')}</li>
            {types.map((type) => (
              <li key={type} value={type}>{type}</li>
            ))}
          </ListFilter>
        </cms.Object>

        <LabelsFilter
          active={params.labels}
          onSelect={(val) => this.onChange('labels', val)}
          labels={helpers.settings.get().jobLabels}
        />
      </>
    );
  }
}
