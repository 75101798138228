import PropTypes from 'prop-types';
import MultiLangText from 'components/form2/multi-lang-text';

import wurd, { WurdObject } from 'wurd-react';


export default function MultiLangField({ wurdId, wurdKeys, error, onChange, ...props }) {
  const { label, help } = wurd.get(wurdId) || {};

  function handleChange(event) {
    onChange(event.target.value);
  }

  return (
    <WurdObject
      id={wurdId}
      keys={wurdKeys || 'label,help'}
      type="div"
      className={`form-group ${error ? 'has-error' : ''}`}
    >
      <label className="control-label col-sm-3">{label}</label>
      <div className="col-sm-9">
        <MultiLangText onChange={handleChange} {...props} />
        {help && <span className="help-block">{help}</span>}
        {error && <span className="help-block">{error[0].message}</span>}
      </div>
    </WurdObject>
  );
}

MultiLangField.propTypes = {
  wurdKey: PropTypes.string,
  error: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
};
