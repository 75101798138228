import React from 'react';

import { Alert } from 'react-bootstrap';
import Link from 'components/nested-link';

import { datetime } from '../../../helpers/ui';

const warningTypes = {
  deprecatedEndpoint: ({ endpoint, lastCalled }) => (
    <Alert bsStyle="info">
      Using deprecated endpoint <small>(last called {datetime(lastCalled)})</small>:<br />
      <code>{endpoint}</code><br />
      
    </Alert>
  ),
  item_unassignedWithContents: ({ itemId }) => (
    <Alert bsStyle="warning">
      Unassigned item has contents:<br />
      <Link to={`/items/${itemId}`}>{itemId}</Link>
    </Alert>
  ),
  user_activeWithoutInvoiceDate: ({ userId }) => (
    <Alert bsStyle="danger">
      Active user without invoiceDate:<br />
      <Link to={`/users/${userId}`}>{userId}</Link>
    </Alert>
  ),
}


export default function SystemWarningPreview({ job }) {
  const { warningType } = job.data;

  const fn = warningTypes[warningType];
  
  if (fn) {
    return fn(job.data);
  } else {
    return (
      <>
        <div><strong>{warningType}</strong></div>
        <div><small style={{ wordBreak: 'break-all' }}>{JSON.stringify(job.data)}</small></div>
      </>
    );
  }
}
