import * as helpers from '../../helpers';

import { WurdText } from 'wurd-react';
import Alert from 'components/alert';

const { k } = helpers.cms.getters('visitView.warning');

const warningStates = [
  'cancelled',
  'failed-delivery',
  'failed-collection'
];


const Warning = ({ visit }) => {
  if (!warningStates.includes(visit.state)) return null;

  return (
    <Alert type="warning">
      <p><WurdText id={k(visit.state)} /></p>
    </Alert>
  );
};


export default Warning;
