import { useState, useEffect, useRef } from 'react';
import wurd from 'wurd-react';
import PapaCsv from 'papaparse';

import * as helpers from '../helpers';


const cms = wurd.block('common.importBtn');

export default function ImportButton2({ actions, onComplete = () => {}, openOnInit = true }) {
  const [errors, setErrors] = useState([]);
  const [total, setTotal] = useState();
  const [count, setCount] = useState();
  const buttonRef = useRef();
  useEffect(() => {
    if (openOnInit && buttonRef?.current) {
      buttonRef.current.click();
    }
  }, []);

  useEffect(() => {
    if (total > 0 && count === total && errors.length === 0) {
      onComplete();
    }
  }, [count]);

  async function selectFile(event) {
    const inputFile = event.target.files[0]
    event.target.value = null; // Reset the file input to allow uploading again

    const result = await new Promise((resolve, reject) => PapaCsv.parse(inputFile, {
      header: true,
      skipEmptyLines: true,
      complete: resolve,
      error: reject,
    }))
      .catch(err => {
        window.alert(err.message);
        throw err;
      });
    
    const rows = result.data.map(row => helpers.csv.parseKeys(Object.fromEntries(Object.entries(row).filter(([k,v]) => v)))); // remove empty string to let default values work in api, for explicitely setting something empty or null, set null as value csv
    if (rows.length === 0) throw new Error('Empty file, no data found');
    
    if (window.confirm(cms.text('confirmCreate', { files: `${inputFile.name} (${rows.length})` }) || `Import ${inputFile.name} (${rows.length})?`)) {
      setTotal(rows.length);
      setCount(0);

      for (let i = 0; i < rows.length; i++) {
        try {
          await actions.create(rows[i]);
        } catch (err) {
          setErrors(errs => [...errs, { message: err.message, line: i + 2, filename: inputFile.name }]);
        }
        setCount(c => c + 1);
      }
    }
  }

  return (
    <>
      <label className="btn btn-default">
        <i className="fa fa-upload" />
        <span style={{ marginLeft: 4 }}>{cms.text('create')}</span>
        <input
          type="file"
          accept=".csv"
          style={{ position: 'absolute', width: 0 }}
          onChange={selectFile}
          ref={buttonRef}
          autoFocus
        />
      </label>

      <output style={{ paddingTop: 0 }}>
        {total > 0 && <p className="text-success">{cms.text('successMsg', { count: count - errors.length, total }) || `${count - errors.length}/${total}`}</p>}
        {errors.length > 0 && (
          <>
            <textarea
              className="text-danger"
              style={{ textAlign: 'left', height: 260, width: '100%' }}
              readOnly
              key={errors.length}
              defaultValue={errors.map(({ filename, line, message }) => cms.text('errorMsg', { filename, line, message }) || `${filename}#${line}: ${message}`).join('\n')}
            />
          </>
        )}
      </output>
    </>
  );
}