import ModalForm from '../modal-form';
import Field from '../horizontal-field';
import MultiLangText from '../multi-lang-text';
import FAIcon from '../fa-icon';


const UnitTagForm = (props) => (

  <ModalForm
    wurdSection="unit.tag"
    {...props}
  >
    {(getProps) =>
      <div>

        <Field {...getProps('title')}>
          <MultiLangText />
        </Field>

        <Field {...getProps('icon')}>
          <FAIcon />
        </Field>

      </div>
    }
  </ModalForm>

);


export default UnitTagForm;
