import wurd, { WurdText } from 'wurd-react';
import { useQuery } from 'react-query';

import * as actions from 'actions';
import * as secActions from 'plugins/security-systems/actions';

import Section from 'components/section';
import { Field } from 'components/form2';
import CustomField from 'plugins/custom-fields/field';
import { useApiForm } from 'hooks';


const cms = wurd.block('site');

const sec_plugins = [
  'bearbox',
  'entryfy',
  'noke',
  'opentech',
  'salto',
  'sensorberg',
  'pti'
];

const sec_events_enabled = [
  'job.unit_moveIn.completed',
  'job.unit_moveOut.completed',
  'job.unit_transfer.completed',
  'unitRental.updated',
];

const sec_events_all = [
  'job.unit_moveIn.completed',
  'job.unit_moveOut.completed',
  'job.unit_transfer.completed',
  'unitRental.markOverdue',
  'unitRental.unmarkOverdue',
  'unitRental.updated',
];


export default function SiteSecPluginSettings({ site, fetchSite, ...rest }) {
  const { fieldProps, submit, loading, dirty, formValue } = useApiForm({
    wurdSection: cms.id(),
    initialValue: {
      sec_plugin: site.sec_plugin,
      sec_events: site.sec_events?.sort().join(','),
      customFields: site.customFields,
    },
    onSubmit: data => actions.sites.update(site.id, {
      ...data,
      sec_plugin: data.sec_plugin || null,
      sec_events: `${data.sec_events || ''}`.split(',').filter(Boolean),
      customFields: Object.fromEntries(Object.entries(data.customFields || {}).filter(([k]) => k.startsWith(data.sec_plugin + '_'))),
    }),
    onSuccess: () => fetchSite(),
    onError: window.alert,
    ...rest
  });

  const secQuery = useQuery(`sec-${formValue.sec_plugin}`, () => secActions.fetch(formValue.sec_plugin), { enabled: !!formValue.sec_plugin });

  return (
    <Section cms={wurd.block('siteView.secPlugin')}>
      <Field {...fieldProps('sec_plugin')} wurdKeys={`label,help,none,${sec_plugins}`}>
        <select style={!formValue.sec_plugin ? { backgroundColor: '#eee' } : null}>
          <option value="">{cms.text('sec_plugin.none')}</option>
          {sec_plugins.map(v => <option key={v} value={v}>{cms.text(`sec_plugin.${v}`)}</option>)}
        </select>
      </Field>

      {secQuery.data && (
        <Field {...fieldProps('sec_events')} wurdKeys="label,help,disabled,enabled,enabled_overlock">
          <select style={!formValue.sec_events?.length ? { backgroundColor: '#eee' } : null}>
            <option value="">{cms.text('sec_events.disabled')}</option>
            <option value={sec_events_enabled.join(',')}>{cms.text('sec_events.enabled')}</option>
            <option value={sec_events_all.join(',')}>{cms.text('sec_events.enabled_overlock')}</option>
          </select>
        </Field>
      )}

      {secQuery.data && Object.entries(secQuery.data.schema.site || {}).map(([code, cfProps]) => (
        <CustomField
          key={code}
          {...fieldProps(`customFields.${code}`)}
          {...cfProps}
        />
      ))}

      <button
        className="btn btn-primary pull-right"
        type="button"
        disabled={loading || !dirty}
        onClick={submit}
      >
        {loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>
    </Section>
  );
}
