import wurd, { WurdText } from 'wurd-react';
import * as helpers from '../../helpers';
import * as actions from '../../actions';
import { useItemLoader } from 'hooks';
import * as chargesHelpers from '../../plugins/charges/helpers';
import { useTasksPlugin } from 'plugins/tasks';

import Link from 'components/nested-link';
import Alert from '../alert';
import UnitLoader from '../loaders/unit';
import DetailPage from '../detail-page';
import NotFound from '../not-found';
import Menu from './menu';
import SidebarSummary from './sidebar-summary';
import Overview from './overview';
import Details from './details';
import Charges from './charges';
import Invoices from './invoices';
import Deposit from './deposit';
import Prepayment from './prepayment';
import Revenue from './revenue';
import Rentals from './rentals';
import History from './history';
import LabelsList from 'plugins/labels/detail';
import UpdatePriceJob from './jobs/update-price';
import ErrorMessage from 'components/error-message';

const cms = wurd.block('unitView');
const { currency } = helpers.ui;

const getPageLink = path => path === location.pathname ? '#' /*don't reload current page*/ : path;

const sectionComponents = {
  overview: Overview,
  details: Details,
  deposit: Deposit,
  prepayment: Prepayment,
  revenue: Revenue,
  charges: Charges,
  invoices: Invoices,
  rentals: Rentals,
  history: History,
};


export default function UnitDetailInnerPage({
  unit, // can be null, if rental submitted but no unit of this type is avail
  rental,
  fetchUnit,
  section = 'overview',
  modal,
}) {
  function getSections() {
    const items = [
      { name: 'overview', title: cms.text('overview.title'), icon: 'far fa-tasks' },
      { name: 'details', title: cms.text('details.title'), icon: 'far fa-info-circle' }
    ];

    if (rental) {
      if (rental.depositBalance !== null) {
        items.push({ name: 'deposit', title: cms.text('deposit.title'), icon: 'far fa-money-bill-alt', badge: currency(rental.depositBalance) });
      }

      if (rental.prepaymentBalance !== null) {
        items.push({ name: 'prepayment', title: cms.text('prepayment.title'), icon: 'far fa-money-bill-alt', badge: currency(rental.prepaymentBalance) });
      }

      if (rental.revenueBalance !== null) {
        items.push({ name: 'revenue', title: cms.text('revenue.title'), icon: 'far fa-money-bill-alt', badge: currency(rental.revenueBalance) });
      }

      items.push(
        { name: 'charges', title: cms.text('charges.title'), icon: 'far fa-money-bill-alt', badge: currency(chargesHelpers.getTotal(rental.charges)) },
        { name: 'invoices', title: cms.text('invoices.title'), icon: 'far fa-file-invoice-dollar', badge: rental.unpaidBalance && currency(rental.unpaidBalance), bsType: 'danger' },
      );
    }

    items.push(
      { name: 'rentals', title: cms.text('rentals.title'), icon: 'far fa-user-clock' },
      { name: 'history', title: 'History', icon: 'far fa-history' },
    );

    return items;
  }

  const baseUrl = rental || !unit ? `/unit-rentals/${rental?.id}` : `/units/${unit?.id}`;

  const { item: site } = useItemLoader('sites', unit?.siteId);

  const tasksPlugin = useTasksPlugin({
    modal,
    targetType: rental ? 'unitRental' : 'unit',
    targetId: rental ? [rental.id, unit?.id] : unit?.id,
  });

  const CurrentSection = sectionComponents[section];
  if (!CurrentSection) return <NotFound />;

  const sections = getSections();

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="admin"
      documentTitle={cms.text('title', { name: unit?.name.toUpperCase() || '-', site: helpers.ui.getLangText(site?.title) })}
      title={(
        <>
          <Link to={unit ? `/units/${unit.id}` : ''}>{unit?.name.toUpperCase() || '＿'}</Link>
          {rental && <Link to ={`/unit-rentals/${rental.id}`}><cms.Object id="rental.shortState" keys="submitted,cancelled,reserved,occupied,ended">{cms.text(`rental.shortState.${rental.state}`)}</cms.Object></Link>}
        </>
      )}
      ancestors={[
        { text: helpers.ui.getLangText(site?.title), url: `/sites/${site?.code}` },
        { text: <WurdText id="unitList.title" />, url: `/sites/${site?.code}/units` },
      ]}
      sections={sections}
      currentSection={section}
      baseUrl={baseUrl}
      extra={(
        <>
          <LabelsList
            labels={unit?.labels || []}
            settingsLabels={helpers.settings.get().unitLabels}
            onChange={labels => actions.units.labels.update(unit.id, labels)}
          />

          <Menu
            unit={unit}
            rental={rental}
            modal={modal}
            onChange={fetchUnit}
            tasksPlugin={tasksPlugin}
          />
        </>
      )}
      sidebar={(
        <SidebarSummary
          unit={unit}
          rental={rental}
          modal={modal}
          onChange={fetchUnit}
        />
      )}
      innerClassName={`${(unit?.state === 'archived') && 'faded'}`}
    >
      {unit?.state === 'blocked' && (
        <Alert type="danger" icon="far fa-ban">
          <cms.Markdown
            id="warning.blocked"
            vars={{
              reason: unit.blockedReason,
            }}
          />
        </Alert>
      )}

      {rental?.overlocked && (
        <Alert type="danger">
          <cms.Markdown
            id="alert.isOverlocked"
            vars={{
              overlocked: helpers.ui.date(rental.overlocked),
            }}
          />
        </Alert>
      )}

      {rental?.overdue && (
        <Alert type="danger">
          <cms.Markdown
            id="alert.isOverdue"
            vars={{
              overdue: helpers.ui.date(rental.overdue),
            }}
          />
        </Alert>
      )}

      {rental?.state === 'ended' && (
        <Alert type="info" icon="far fa-user-slash">
          <cms.Markdown
            id="warning.hasEnded"
            vars={{
              endDate: helpers.ui.date(rental.endDate),
            }}
          />
        </Alert>
      )}

      {rental?.activeJobs?.map(job => {
        switch (job.type) {
          case 'unit_moveIn':
            return (
              <Alert key={job.id} type="warning" icon="far fa-user">
                <cms.Markdown
                  id="warning.hasMoveInJob"
                  vars={{
                    jobId: job.sid.toUpperCase(),
                    jobUrl: getPageLink(helpers.ui.href(`unit-rentals/${rental.id}`)),
                  }}
                />
              </Alert>
            );
          
          case 'unit_moveOut':
            return (
              <Alert key={job.id} type="warning" icon="far fa-user-slash">
                <cms.Markdown
                  id="warning.hasMoveOutJob"
                  vars={{
                    jobId: job.sid.toUpperCase(),
                    jobUrl: getPageLink(helpers.ui.href(`unit-rentals/${rental.id}`)),
                  }}
                />
              </Alert>
            );
          
          case 'unit_transfer':
            if (job.result?.oldRentalId === rental?.id) {
              return (
                <Alert key={job.id} type="warning" icon="far fa-user-minus">
                  <UnitLoader id={job.result.newUnitId}>
                    {newUnit => (
                      <cms.Markdown
                        id="warning.hasTransferJob_out"
                        vars={{
                          newUnitName: newUnit.name,
                        }}
                      />
                    )}
                  </UnitLoader>
                </Alert>
              );
            } else if (job.result?.newRentalId === rental?.id) {
              return (
                <Alert key={job.id} type="warning" icon="far fa-user-plus">
                  <UnitLoader id={job.result.oldUnitId}>
                    {oldUnit => (
                      <cms.Markdown
                        id="warning.hasTransferJob_in"
                        vars={{
                          oldUnitName: oldUnit.name,
                        }}
                      />
                    )}
                  </UnitLoader>
                </Alert>
              );
            }
            break;
          
          case 'unitRental_updatePrice':
            return (
              <UpdatePriceJob
                key={job.id}
                cms={cms}
                job={job}
                onChange={fetchUnit}
              />
            );
            
          default:
            return null;
        }
      })}

      <CurrentSection
        unit={unit}
        site={site}
        rental={rental}
        fetchUnit={fetchUnit}
        onChange={fetchUnit}
        cms={cms}
        modal={modal}
        tasksPlugin={tasksPlugin}
      />
    </DetailPage>
  );
}
