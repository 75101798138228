import wurd from 'wurd-react';

import * as helpers from 'helpers';
import * as actions from 'actions';

import Task from '../task';


const cms = wurd.block('siteOnboarding.tasks');


export default function SiteOnboardingVisibilityTask({ site }) {  
  const nUnits = helpers.site.getOccupancy(site).total;
  const nPositioned = site.positions?.filter(p => p.type === 'unit').length;


  return (
    <Task
      title={<cms.Text id="show.title" />}
      isDone={!site.hidden} open={nUnits > 0 && nPositioned === nUnits && site.hidden}
      action={<cms.Text id={`show.action.${site.hidden ? 'todo' : 'done'}`} className="text-muted" style={{ marginRight: 8 }} />}
    >
      <cms.Markdown id="show.description" type="div" vars={{ link: helpers.ui.href(`/sites/${site.code}/advanced-settings#advanced`) }} />

      <button className="btn btn-primary pull-right" onClick={() => actions.sites.update(site.id, { hidden: !site.hidden })}>
        <cms.Text id={`show.preview.${site.hidden ? 'btn' : 'btn2'}`} />
      </button>
    </Task>
  );
}
