import wurd from 'wurd-react';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
import * as actions from '../../actions';
import { hasRole } from '../../helpers/auth';

import Page from '../page';
import TableList from '../table-list';
import NewSiteForm from './forms/new-site';
import Item from './item';
import Menu from './menu';
import Filters from './filters';

const cms = wurd.block('siteList');

function viewSite(site) {
  return `/sites/${site.code}`;
}


export default function SiteList({
  modal,
  listQuery,
  listLoader,
}) {
  const navigate = useNavigate();
  const renderItem = (site) => (
    <Item
      site={site}
    />
  );

  function createSite() {
    modal.open(
      <NewSiteForm
        onSubmit={actions.sites.create}
        onSuccess={(site) => {
          listLoader.refetch();
          modal.close();
          navigate(`/sites/${site.code}`);
        }}
        closeModal={modal.close}
        mode="create"
      />,
    );
  }

  useEffect(() => {
    if (location.hash === '#new') createSite();
  }, []);

  return (
    <Page
      titleKey={cms.id('title')}
      userRole="admin"
      modal={modal}
      extra={(
        <>
          <Link to="/units" className="btn btn-link">
            <cms.Text id="unitsLink" />
          </Link>
          <Link to="/unit-rentals" className="btn btn-link">
            <cms.Text id="unitRentalsLink" />
          </Link>
        </>
      )}
    >
      <TableList
        cms={cms}
        listQuery={listQuery}
        loader={listLoader}
        filters={(
          <Filters
            listQuery={listQuery}
          />
        )}
        menu={hasRole('manager') && (
          <Menu
            modal={modal}
            cms={cms}
            listQuery={listQuery}
            refetch={listLoader.refetch}
          />
        )}
        renderItem={renderItem}
        getItemLink={viewSite}
        onClickAdd={hasRole('manager') && createSite}
      />
    </Page>
  );
}
