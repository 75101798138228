import store from '../store';
import api from '../utils/api';
import errHandler from './_err-handler';
import crudList from './plugins/crud-list';


const actions = {};


/**
 * Sets the current/selected market
 */
/*actions.select = function(marketId) {
  StateStore.set({ activeMarket: marketId });
};*/


/**
 * Sets the markets list
 */
// actions.set = function (markets) {
//   StateStore.set({
//     markets: markets
//   });
// };


/**
 * Fetches markets
 * 
 * @param {Object} [params]          Query parameters
 */
actions.fetch = function (params = {}) {
  return api.get('/v1/admin/markets', { params: params })
    .then(res => {
      const markets = res.data;

      /*var state = StateStore.getState();

      StateStore.set({
        markets: _.keyBy(markets, 'id'),
        activeMarket: state.activeMarket || markets[0].id
      });*/;
      const { settings } = store.get();
      store.set({ settings: { ...settings, markets } });

      return markets;
    })
    .catch(errHandler)
};


actions.update = function (marketId, data) {
  return api.put(`/v1/admin/markets/${marketId}`, data)
    .then(res => {
      var updatedMarket = res.data;

      // StateStore.set({
      //   markets: StateStore.get().markets.map(market => {
      //     return (market.id === updatedMarket.id) ? updatedMarket : market
      //   })
      // });

      /*StateStore.set({
        markets: Object.assign({}, StateStore.get().markets, {
          [updatedMarket.id]: updatedMarket
        })
      });*/

      const { settings } = store.get();
      const newMarkets = settings.markets.map(market => market.id === marketId ? updatedMarket : market);
      store.set({ settings: { ...settings, markets: newMarkets } });

      return updatedMarket;
    })
    .catch(errHandler)
};


//actions.userCharges = crudList('user-charges');
//actions.spaces = crudList('spaces');
//actions.items = crudList('/v1/admin/markets', 'items');
//actions.products = crudList('products');

actions.items = function (marketId) {
  const itemsCrudList = crudList(`/v1/admin/markets/${marketId}/items`);
  return {
    ...itemsCrudList,
    update(itemId, data) {
      return itemsCrudList.update(itemId, data)
        .then(item => {
          const { settings } = store.get();
          const newMarkets = settings.markets.map(market => market.id === marketId ? { ...market, items: market.items.map(o => o.id === item.id ? item : o) } : market);
          store.set({ settings: { ...settings, markets: newMarkets } });
        })
    }
  };
}


export default actions;
