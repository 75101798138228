import Activity from '../activity';


export default function UnitTypeHistory({ unitType }) {
  return (
    <Activity
      context="unitType"
      endpoint={`/unit-types/${unitType.id}/actions`}
    />
  );
}
