import { useState } from 'react';
import wurd from 'wurd-react';
import { Link } from 'react-router-dom';
import Field from '../simple-form-field';

import { useApiForm } from '../../hooks';
import * as actions from '../../actions';
import * as config from '../../config';
import ErrorMessage from 'components/error-message';
import Alert from 'components/alert';
import Markdown from 'components/markdown';
import Captcha from 'plugins/recaptcha';


const cms = wurd.block('auth.forgotPassword');

export default function ForgotPasswordPage() {
  const [emailSent, setEmailSent] = useState(false);
  const { fieldProps, submit, errMsg, loading } = useApiForm({
    cms,
    onSubmit: data => actions.auth.sendForgotPasswordEmail(data.email),
    onSuccess: () => setEmailSent(true),
  });

  function renderForm() {
    return (
      <div>
        <cms.Text id="intro" type="p" className="text-muted" style={{ marginBottom: 20 }} />

        <form onSubmit={submit}>
          <Captcha />
          <Field
            {...fieldProps('email')}
            type="email"
            icon="envelope"
            placeholder={cms.text('email.placeholder')}
            autoFocus
            autoComplete="username"
            required
          />

          {errMsg &&
            <ErrorMessage icon="fa fa-times">
              <Markdown as="span" inline>{cms.text(`errors.${errMsg}`) || errMsg}</Markdown>
            </ErrorMessage>
          }

          <button
            className="btn btn-primary btn-flat btn-block"
            type="submit"
            disabled={loading}
          >
            {loading
              ? <i className="fa fa-spinner fa-spin" />
              : <cms.Text id="submit" />
            }
          </button>
        </form>
      </div>
    );
  }

  function renderSuccess() {
    return (
      <Alert type="success">
        <cms.Text id="success" />
      </Alert>
    );
  }

  return (
    <div className="sg-auth-login login-container logged-out-page">
      <div className="middle-login">
        <section className="block-flat">
          <div className="header text-center">
            <h3 className="brand"><img src={`${config.publicUrl}/logo.png`} alt="Storeganise" /></h3>
          </div>
          <div className="content">
            <h4 className="title"><cms.Text id="header" /></h4>

            {emailSent ? renderSuccess() : renderForm()}

            <hr />

            <Link to="/login" className="pull-right" style={{ marginBottom: 20 }}><cms.Text id="login" /></Link>
          </div>
        </section>
      </div>
    </div>
  );
}