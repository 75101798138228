import { Component } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import JobStep from '../../job-step2';

const cms = wurd.block('unitJobs.moveIn.steps.order_cancel');


export default class UnitMoveInJobSteps_OrderCancel extends Component {

  static propTypes = {
    job: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
  };

  render() {
    const { step } = this.props;

    const renderFn =  this[`render_${step.state}`];
    if (!renderFn) return null;

    return renderFn.call(this);
  };

  render_completed() {
    const { step } = this.props;

    return (
      <JobStep 
        step={step} 
        title={<cms.Text id="completed.title" />}
      />
    );
  };

};
