import wurd from 'wurd-react';
import styled from '@emotion/styled';

import * as actions from 'actions';
import { downloadAgreement } from './helpers';

import LinkButtonGroup, {
  DangerOutlineLink,
  SecondaryOutlineLink,
} from './button-bar/action-button-group';

const cms = wurd.block('unitOrder.operations');

const ActionButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 768px)": {
    flexDirection: "column-reverse",
    ".primary-button": {
      flex: 1,
      width: "100%",
    },
    ".buttonGroup span": {
      flex: 1,
    },
    ".buttonGroup button": {
      flex: 1,
      width: "100%",
      marginTop: "10px",
    },
  },
});


export default function UnitMoveInOperationsBar({ job, onChange }) {
  function openPdf() {
    return downloadAgreement({ job });
  }

  async function cancelJob() {
    const confirmed = window.confirm(cms.text('cancelJob.confirm'));
    if (!confirmed) return;
    await actions.jobs.runOperation(job.id, 'cancel');
    onChange();
  }

  const loading = false;
  function submit() {

  }

  /* return (
    <LinkButtonGroup align="center">
      <cms.Object id="cancelJob" keys="btn,confirm">
        <DangerOutlineLink
          faIcon="far fa-trash"
          text={cms.text('cancelJob.btn')}
          onClick={cancelJob}
          className="btn"
        />
      </cms.Object>
      <cms.Object id="viewAgreement" keys="btn">
        <SecondaryOutlineLink
          faIcon="far fa-file-contract"
          text={cms.text('viewAgreement.btn')}
          onClick={openPdf}
        />
      </cms.Object>
    </LinkButtonGroup>
  ); */

  return (
    <ActionButtonWrapper>
      <cms.Object id="cancelJob" keys="btn,confirm">
        <LinkButtonGroup align="center">
          <DangerOutlineLink
            faIcon="far fa-times"
            text={cms.text("cancelJob.btn")}
            onClick={cancelJob}
            className="btn danger-button"
          />
          <SecondaryOutlineLink
            faIcon="far fa-file-contract"
            text={cms.text('viewAgreement.btn')}
            onClick={openPdf}
            className="secondary-button btn"
          />
        </LinkButtonGroup>
      </cms.Object>
      <div>
        <button
          className="btn btn-success primary-button"
          onClick={submit}
          disabled={loading}
        >
          <i
            className={`fa ${
              loading ? "fa-spinner fa-spin" : "fa-check"
            }`}
          />{" "}
          Confirm move-out
        </button>
      </div>
    </ActionButtonWrapper>
  )
}
