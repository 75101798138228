import wurd from 'wurd-react';
import { useParams } from 'react-router-dom';

import store from '../store';
import { useModal } from '../hooks';
import { hasRole } from '../helpers/auth';

import DetailPage from '../components/detail-page';
import Spinner from '../components/spinner';
import NotFound from '../components/not-found';
import GeneralSettings from '../components/settings/general';
import ValetSettings from '../components/settings/valet';
import UnitsSettings from '../components/settings/units';
import PromosSettings from '../components/settings/promos';
import DeveloperSettings from '../components/settings/developer';
import BrandSettings from '../components/settings/brand';
import ChargesSettings from '../components/settings/charges';
import InvoicingSettings from '../components/settings/invoicing';
import AccountingSettings from '../components/settings/accounting';
import LabelsSettings from '../components/settings/labels';
import CustomFieldsSettings from '../components/settings/custom-fields';
import AdvancedSettings from '../components/settings/advanced';
import EmailsSettings from '../components/settings/emails';
import PluginsSettings from '../components/settings/plugins';
import WebsiteSettings from '../components/settings/website';
import TeamSettings from '../components/settings/team';
import Addons from '../components/settings/addons';
import History from '../components/settings/history';

const cms = wurd.block('settings');

const sectionComponents = {
  general: GeneralSettings,
  valet: ValetSettings,
  units: UnitsSettings,
  brand: BrandSettings,
  emails: EmailsSettings,
  promos: PromosSettings,
  developer: DeveloperSettings,
  charges: ChargesSettings,
  invoicing: InvoicingSettings,
  accounting: AccountingSettings,
  advanced: AdvancedSettings,
  plugins: PluginsSettings,
  labels: LabelsSettings,
  'custom-fields': CustomFieldsSettings,
  website: WebsiteSettings,
  team: TeamSettings,
  addons: Addons,
  history: History,
};


export default function SettingsPage() {
  const { section = 'general', sectionId } = useParams();

  const modal = useModal();

  const { settings } = store.get();
  if (!settings) return <Spinner />;

  const SectionComponent = sectionComponents[section];
  if (!SectionComponent) return <NotFound />;

  const sections = [
    ['general', 'far fa-cog'],
    ['brand', 'far fa-image'],
    ['emails', 'far fa-envelope'],
    ['website', 'fal fa-globe'],
    ['units', 'far fa-warehouse', 'selfStorage'],
    ['valet', 'far fa-archive', 'valetStorage'],
    ['promos', 'far fa-star'],
    ['charges', 'far fa-money-bill-alt'],
    ['invoicing', 'far fa-file-invoice-dollar'],
    ['accounting', 'far fa-calculator'],
    ['labels', 'far fa-tags'],
    ['custom-fields', 'far fa-brackets-curly'],
    ['team', 'far fa-users'],
    ['addons', 'far fa-cubes'],
    ['developer', 'far fa-code'],
    ...hasRole('__superadmin') ? [['plugins', 'far fa-plug']] : [],
    ...hasRole('__superadmin') ? [['advanced', 'far fa-wrench']] : [],
    ['history', 'far fa-history'],
  ].map(([name, icon, mode]) => {
    if (mode && !settings.modes.includes(mode)) return null;

    return {
      name,
      icon,
      mode,
    };
  }).filter(Boolean);

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="manager"
      documentTitle={cms.text('title')}
      title={sectionId === '-' ? '/' : sectionId ? <cms.Text id={`subnav.${section}`} vars={{ sectionId }} /> : cms.text(`nav.${section}`)}
      ancestors={[
        { text: <cms.Text id="title" />, url: '/settings' },
        ...sectionId ? [{ text: <cms.Text id={`nav.${section}`} />, url: `/settings/${section}` }] : [],
      ]}
      sections={sections}
      currentSection={section}
      baseUrl="/settings"
    >
      <SectionComponent settings={settings} modal={modal} />
    </DetailPage>
  );
}
