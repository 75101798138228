import { Link } from 'react-router-dom';

/**
 * Simple link component for having nested links inside SimpleNavList rows without triggering the main row link
 * @param {*} props 
 */
export default function NestedLink({ onClick, ...props }) {
  return (
    <Link
      {...props}
      onClick={e => {
        e.stopPropagation();
        window.scrollTo(0, 0);
        if (onClick) onClick(e);
      }}
    />
  );
}
