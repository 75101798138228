import api from '../utils/api';
import { fetch, fetchOne, fetchOneCached, update, del, updateCachedData } from './general';
import errHandler from './_err-handler';
import crudList from './plugins/crud-list';
import labelsPlugin from '../plugins/labels/actions';
import jobs from './jobs';


const endpoint = 'items';
const url = `/v1/admin/${endpoint}`;

const actions = {};

function handleResponse(res) {
  updateCachedData(endpoint, res.data);

  return res.data;
}


actions.get = function (idOrSid) {
  const result = fetchOneCached(endpoint, idOrSid, { altIdKey: 'sid' });

  return result ? result.data : null;
};


/**
 * Fetches items
 *
 * @param {Object} [params]          Query parameters
 */
actions.fetch = function (params = {}) {
  return fetch(endpoint, params);
};


/**
 * Fetches a single item
 */
actions.fetchOne = function (id, params = {}) {
  return fetchOne(endpoint, id, params);
};


/**
 * Creates an item
 *
 * @param {String} user
 * @parma {Object} props
 */
actions.createForUser = function (userId, props) {
  return api.post(`/v1/admin/users/${userId}/items`, props)
    .then(handleResponse)
    .catch(errHandler);
};


actions.update = async function (id, data) {
  return update(endpoint, id, data);
};


// UPDATE COMMANDS
actions.updateState = async function (itemSids, command, data) {
  const { data: result } = await api.put(url, {
    command,
    itemSids,
    data,
  });

  updateCachedData(endpoint, result.updatedItems);

  return result;
};


actions.delete = function (id) {
  return del(endpoint, id);
};


actions.addToValetOrder = function (sid, valetOrderId) {
  if (!valetOrderId) alert('Missing valetOrderId');

  const data = {
    valetOrderId,
  };

  return actions.update(sid, data)
    .catch(err => alert(err.message));
};


actions.removeFromValetOrder = function (sid) {
  const data = {
    valetOrderId: null,
  };

  return actions.update(sid, data);
};


/**
 * Returns CRUD actions
 */
actions.opsImages = function (itemId) {
  return crudList(`/v1/admin/items/${itemId}/ops-images`);
};

actions.labels = labelsPlugin('items');

export default actions;
