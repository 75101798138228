import ModalForm from '../../form2/modal-form';
import { Field, DatePicker } from '../../form2';

const wurdSection = 'unitJobs.invoice.create';


export default function UnitInvoiceForm({ fields, ...rest }) {
  return (
    <ModalForm
      wurdSection={wurdSection}
      mode="create"
      {...rest}
    >
      {(getProps) => (
        <div>
          <Field {...getProps('startDate')} required>
            <DatePicker
              minDate={null}
            />
          </Field>
        </div>
      )}
    </ModalForm>
  );
}
