import PropTypes from 'prop-types';
import wurd from 'wurd-react';
import { Link } from 'react-router-dom';

import * as actions from 'actions';
import * as helpers from 'helpers';

import JobStep from '../../job-step2';
import UnitLoader from '../../../loaders/unit';
import Alert from '../../../alert';
import UnitSelector from '../../../form2/unit-selector';
import SidebarSummaryRow from '../../../sidebar-summary-row';

const cms = wurd.block('unitOrder.steps.unit_reserve');


export default function UnitMoveInJobSteps_UnitReserve({ job, step, site, modal, onChange }) {
  const { siteId, unitTypeId } = job.result;

  const renderFns = {
    ready() {
      if (step.requiresInput) return renderFns.requiresInput();

      return (
        <JobStep
          step={step}
          title={<cms.Text id="ready.title" />}
        >
          {step.error && (
            <Alert type="danger">
              {step.error}
            </Alert>
          )}
        </JobStep>
      );
    },

    requiresInput() {
      return (
        <JobStep
          step={step}
          title={<cms.Text id="requiresInput.title" />}
        >
          <UnitSelector
            siteId={siteId}
            unitTypeId={unitTypeId}
            bsStyle="primary"
            onChange={e => actions.jobs.updateStep(job.id, step.id, { unitId: e.target.value }).then(onChange)}
          />
        </JobStep>
      );
    },

    completed() {
      const { unitId } = step.result;

      return (
        <UnitLoader id={unitId} include={['rental', 'customFields']}>
          {(unit) => (
            <JobStep
              step={step}
              title={<cms.Text id="completed.title" vars={{ unitName: unit.name }} />}
            >
              <Link to={`/units/${unit.id}`}>
                <SidebarSummaryRow
                  className="list-group-item list-group-item-success"
                  image={helpers.unitType.get(site, unit.typeId)?.image}
                  typeTitle={helpers.unitType.get(site, unit.typeId, true)?.title}
                  itemTitle={`Unit ${unit.name} - ${helpers.ui.getLangText(site.title)}`}
                />
              </Link>
            </JobStep>
          )}
        </UnitLoader>
      );
    },

    failed() {
      if (step.requiresInput) return renderFns.requiresInput();
    },
  };

  const renderFn = renderFns[step.state];
  if (!renderFn) return null;

  return renderFn();
}


UnitMoveInJobSteps_UnitReserve.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    sid: PropTypes.string,
    result: PropTypes.object,
  }).isRequired,
  step: PropTypes.shape({
    id: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};