import wurd from 'wurd-react';

import { scheduleMoveIn } from './helpers';
import { stateLabelTypes } from '../../helpers/unit';
import { get as getSite } from '../../helpers/site';
import * as secsys from 'plugins/security-systems';

import SidebarState from '../sidebar-items/state';
import SidebarUser from '../sidebar-items/user';
import SidebarDate from '../sidebar-items/date';
import SidebarUnitType from '../sidebar-items/unit-type';

const cms = wurd.block('unitView.state');

function getUnitName(unit) {
  let site;
  try {
    site = getSite(unit.siteId);
  } catch {}

  return <><cms.Text id="name" vars={{ name: unit.name }} />{unit.customFields?.[secsys.getCfKey(site)] && <i className="fas fa-shield-alt" style={{ fontSize: '80%' }} />}</>;
}

export default function UnitDetailSidebarSummary({
  unit,
  rental,
  onChange,
  modal,
}) {
  if (!unit) return null;

  const billedUntilSidebar = rental?.billedUntil && (
    <SidebarDate
      date={rental.billedUntil}
      title={<cms.Text id="billedUntil" />}
      linkTo={`/unit-rentals/${rental.id}/invoices`}
    />
  );

  const moveInSidebar = rental && (
    <SidebarDate
      title={<cms.Text id="moveIn" />}
      date={rental.startDate}
      linkTo={rental.moveInJobId === rental.id ? `/unit-rentals/${rental.id}` : `/sites/${unit.siteId}/jobs/${rental.moveInJobId}`}
    />
  );

  const moveOutSidebar = rental && (
    <SidebarDate
      date={rental.endDate}
      title={<cms.Text id="movedOut" />}
      linkTo={rental.moveOutJobId === rental.id ? `/unit-rentals/${rental.id}` : `/sites/${unit.siteId}/jobs/${rental.moveOutJobId}`}
    />
  );

  const renderByState = {
    archived() {
      return (
        <div className="list-group">
          <SidebarState type={stateLabelTypes.blocked} link={`/units/${unit.id}`}>
            {getUnitName(unit)}
            <div className="pull-right"><cms.Text id="archived.title" /></div>
          </SidebarState>
  
          <SidebarUnitType id={unit.typeId} />
  
          <div className="list-group-item">
            <div style={{ textTransform: 'uppercase', color: '#aaa', fontSize: 12, marginTop: 3 }}>
              <cms.Text id="archived.reason" className="text-muted" />
            </div>
            {unit.archivedReason}
          </div>
        </div>
      );
    },

    blocked() {
      return (
        <div className="list-group">
          <SidebarState type={stateLabelTypes.blocked} link={`/units/${unit.id}`}>
            {getUnitName(unit)}
            <div className="pull-right"><cms.Text id="blocked.title" /></div>
          </SidebarState>
  
          <SidebarUnitType id={unit.typeId} />
  
          <div className="list-group-item">
            <div style={{ textTransform: 'uppercase', color: '#aaa', fontSize: 12, marginTop: 3 }}>
              <cms.Text id="blocked.reason" className="text-muted" />
            </div>
            {unit.blockedReason}
          </div>
        </div>
      );
    },

    available() {
      return (
        <div className="list-group">
          <SidebarState type={stateLabelTypes.available} link={`/units/${unit.id}`}>
            {getUnitName(unit)}
            <div className="pull-right"><cms.Text id="available.title" /></div>
          </SidebarState>
  
          <SidebarUnitType id={unit.typeId} />
  
          <div className="list-group-item">
            <cms.Object id="available" keys="scheduleMoveInBtn">
              <button type="button" onClick={handleScheduleMoveIn} className="btn btn-success btn-block">
                <i className="fa fa-fw fa-sign-in-alt" /> {cms.text('available.scheduleMoveInBtn')}
              </button>
            </cms.Object>
          </div>
        </div>
      );
    },

    reserved() {
      return (
        <div className="list-group">
          <SidebarState type={stateLabelTypes.reserved} link={`/units/${unit.id}`}>
            {getUnitName(unit)}
            <div className="pull-right"><cms.Text id="reserved.title" /></div>
          </SidebarState>
  
          <SidebarUnitType id={unit.typeId} />
  
          {rental &&
            <>
              <SidebarUser id={rental.ownerId} />

              {rental.startDate && moveInSidebar}

              {billedUntilSidebar}
            </>
          }
        </div>
      );
    },

    occupied() {
      return (
        <div className="list-group">
          <SidebarState type={stateLabelTypes.occupied} link={`/units/${unit.id}`}>
            {getUnitName(unit)}
            <div className="pull-right"><cms.Text id="occupied.title" /></div>
          </SidebarState>
  
          <SidebarUnitType id={unit.typeId} />
  
          {rental &&
            <>
              <SidebarUser id={rental.ownerId} />
              {rental.startDate && moveInSidebar}

              {rental.endDate && moveOutSidebar}

              {billedUntilSidebar}
            </>
          }
        </div>
      );
    },

    cancelled() {
      return (
        <div className="list-group">
          <SidebarState type={stateLabelTypes.blocked} link={`/units/${unit.id}`}>
            {getUnitName(unit)}
            <div className="pull-right"><cms.Text id="cancelled.title" /></div>
          </SidebarState>
  
          <SidebarUnitType id={unit.typeId} />
  
          <SidebarUser id={rental.ownerId} />

          {moveInSidebar}

          {billedUntilSidebar}
        </div>
      );
    },

    ended() {
      return (
        <div className="list-group">
          <SidebarState type={stateLabelTypes.blocked} link={`/units/${unit.id}`}>
            {getUnitName(unit)}
            <div className="pull-right"><cms.Text id="ended.title" /></div>
          </SidebarState>
  
          <SidebarUnitType id={unit.typeId} />
  
          <SidebarUser id={rental.ownerId} />

          {moveInSidebar}
          
          {moveOutSidebar}

          {billedUntilSidebar}
        </div>
      );
    }
  };

  function handleScheduleMoveIn() {
    scheduleMoveIn({ unit, modal, onChange });
  }

  const renderFn = renderByState[rental ? rental.state : unit.state];

  return renderFn ? renderFn() : null;
}
