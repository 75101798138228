import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import wurd from 'wurd-react';

import * as helpers from '../../helpers';

import Alert from '../alert';


const cms = wurd.block('job');

export default class ConfirmFutureStep extends Component {

  static propTypes = {
    date: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  state = {
    hasConfirmedDelayedStepCanStart: false
  };

  render() {
    const { date, children } = this.props;
    const { hasConfirmedDelayedStepCanStart } = this.state;

    const isFutureDate = moment(date).startOf('day') > moment().startOf('day');

    if (!isFutureDate) {
      return children;
    }

    return (
      <div className="panel-body">
        <Alert type="warning" icon="far fa-clock">
          <cms.Text id="delayedStepWarning" vars={{
            date: helpers.ui.date(date)
          }} />
        </Alert>

        {hasConfirmedDelayedStepCanStart
          ? children
          : (
            <button
              type="button"
              className="btn btn-warning btn-block"
              onClick={this.confirmStart}
            >
              <cms.Text id="startNow" />
            </button>
          )
        }
      </div>
    );
  }

  confirmStart = () => {
    this.setState({ hasConfirmedDelayedStepCanStart: true });
  }

}
