import { useSimpleListQuery } from '../../hooks';

import ItemList from '../item-list';


export default function ValetOrderItems({ valetOrder, modal }) {
  const listQuery = useSimpleListQuery({
    initialParams: {
      valetOrderId: valetOrder.id,
      state: null,
    },
  });

  return (
    <ItemList
      listQuery={listQuery}
      modal={modal}
      hideOwner
      hideValetOrder
    />
  );
}
