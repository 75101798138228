import wurd from 'wurd-react';

import { useListLoader } from '../../hooks';

import Spinner from '../spinner';

const cms = wurd.block('valetOrderList.selectDriverModal');

const styles = {
  li: {
    cursor: 'pointer',
  },
};

const driversQuery = {
  roles: 'driver',
};


export default function ValetOrderListSelectDriverModal({ onSelect }) {
  const { items: drivers, isLoading } = useListLoader('users', driversQuery);

  if (isLoading) return <Spinner />;

  return (
    <div>
      <div className="modal-header">
        <h4 className="modal-title"><cms.Text id="title" /></h4>
      </div>
      <div className="modal-body">
        <div className="list-group">
          <li
            className="list-group-item"
            style={styles.li}
            onClick={() => onSelect(null)}
          >
            <h4 className="list-group-item-heading"><strong><cms.Text id="unassign" /></strong></h4>
          </li>
          {drivers.map((user) => (
            <li
              key={user.id}
              className="list-group-item"
              style={styles.li}
              onClick={() => onSelect(user)}
            >
              <h4 className="list-group-item-heading">{user.name}</h4>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
}
