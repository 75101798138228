import wurd from 'wurd-react';

import ModalForm2 from '../../form2/modal-form2';
import { Field, DatePicker, CurrencyInput } from '../../form2';
import AccountingCodeSelect from '../../form2/accounting-code-select';

const cms = wurd.block('invoice.entry');


export default function InvoiceEntryForm({
  mode = 'create',
  ...rest
}) {
  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode={mode}
      className="form2"
      dirty
      {...rest}
    >
      {({ fieldProps, formValue: entry }) => (
        <>
          <Field {...fieldProps('type')} required>
            <select disabled={mode !== 'create'}>
              {['deposit', 'prepayment', 'revenue'].map((val) => (
                <option value={val} key={val}>{cms.text(`type.select.${val}`)}</option>
              ))}
            </select>
          </Field>

          <Field {...fieldProps('desc')} required autoFocus />

          <Field {...fieldProps('amount')} required>
            <CurrencyInput min={null} type="number" />
          </Field>

          <Field {...fieldProps('qty')} type="number" />

          <Field {...fieldProps('date')} required>
            <DatePicker
              minDate={null}
            />
          </Field>

          <Field {...fieldProps('endDate')}>
            <DatePicker
              minDate={null}
            />
          </Field>

          <Field {...fieldProps('accountingCodeId')}>
            <AccountingCodeSelect
              nullText={entry.code || wurd.text('common.phrases.none')}
            />
          </Field>
        </>
      )}
    </ModalForm2>
  );
}
