import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { sid } from '../../../helpers/ui';
import * as actions from '../../../actions';
import { useSimpleListQuery } from '../../../hooks';

import InvoiceList from '../../invoice-list';


export default function UnitMoveInInvoices({ modal, job }) {
  const { unitRentalId } = job.result;
  const navigate = useNavigate();

  const listQuery = useSimpleListQuery({
    initialParams: {
      unitRentalId,
      state: null,
    },
  });

  async function createManualInvoice() {
    const invoice = await actions.unitRentals.createManualInvoice(unitRentalId, {
      startDate: moment().format('YYYY-MM-DD'),
    });

    navigate(`/invoices/${sid(invoice)}`);
  }

  return (
    <InvoiceList
      listQuery={listQuery}
      modal={modal}
      hideOwner
      hideUnit
      onClickAdd={createManualInvoice}
    />
  );
}
