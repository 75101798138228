import { WurdText } from 'wurd-react';
import styled from '@emotion/styled';
import TransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { getStateTitles } from '../../../helpers/item';

import NoResults from '../../no-results';
import SelectItemCheckbox from '../../checkbox_select-item';
import Item2 from './item2';

const Container = styled.div({
  '.header': {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    position: 'sticky',
    top: 89,
    zIndex: 99,
    boxShadow: '0px 4px 10px -5px #ddd',
    height: 50,
  },

  '.numSelected': {
    color: '#999',
  },

  '.extra': {
    marginLeft: 'auto',
  },

  '.item': {
    borderTop: '1px solid #eee',
    '&:first-child': { borderTop: '1px solid #fff' },
    /* '&:nth-child(2n+1)': {
      background: '#f8f8f8',
    }, */
  },

  '.example-enter': {
    maxHeight: 0,
  },

  '.example-enter.example-enter-active': {
    maxHeight: 44,
    overflowY: 'hidden',
    transition: 'max-height 200ms ease-in-out',
  },

  '.example-leave': {
    maxHeight: 44,
  },

  '.example-leave.example-leave-active': {
    maxHeight: 0,
    overflowY: 'hidden',
    transition: 'max-height 500ms ease-in-out',
  },
});


export default function Item2List({
  items,
  selector,
  updateErrs,
  successState,
  todoState,
  hideLocation = false,
  hideDesc = false,
  fallback = <NoResults />,
  extra = null, // right side buttons etc.
  onClickItem = null,
}) {
  const stateTitles = getStateTitles();

  const hasSelectedAll = selector.selectedSids.length === items.length;

  function selectAll() {
    selector.setSelectedItems(items);
  }

  function unselectAll() {
    selector.clearSelection();
  }

  function toggleSelectAll() {
    if (hasSelectedAll) {
      unselectAll();
    } else {
      selectAll();
    }
  }

  if (!items.length) {
    return (
      <Container>{fallback}</Container>
    );
  }

  const numSelected = selector.selectedSids.length;

  return (
    <Container>
      <div className="header">
        <SelectItemCheckbox
          isSelected={hasSelectedAll}
          isHalfSelected={numSelected}
          onClick={toggleSelectAll}
        />
        <div className="numSelected"><WurdText id="common.phrases.numSelected" vars={{ numSelected }} /></div>

        <div className="extra">{extra}</div>
      </div>

      <TransitionGroup
        transitionName="example"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={500}
      >
        {items.map((item) => (
          <div
            key={item.sid}
            className="item"
          >
            <Item2
              item={item}
              isSelected={selector.selectedSids.includes(item.sid)}
              toggleSelected={() => selector.toggleItemSelected(item)}
              updateErr={updateErrs?.[item.sid]}
              stateTitle={stateTitles[item.state]}
              onClick={() => onClickItem && onClickItem(item)}
              successState={successState}
              todoState={todoState}
              hideLocation={hideLocation}
              hideDesc={hideDesc}
            />
          </div>
        ))}
      </TransitionGroup>
    </Container>
  );
}
