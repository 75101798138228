import { WurdText } from 'wurd-react';


export default (props) => (
  <a className="btn btn-default btn-flat" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 270, marginTop: 8, borderRadius: 4 }} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" style={{ height: 18, width: 18, marginRight: '.75rem' }}>
      <rect x="1" y="1" width="9" height="9" fill="#f25022"/>
      <rect x="1" y="11" width="9" height="9" fill="#00a4ef"/>
      <rect x="11" y="1" width="9" height="9" fill="#7fba00"/>
      <rect x="11" y="11" width="9" height="9" fill="#ffb900"/>
    </svg>
    <WurdText id="auth.login.azureBtn" style={{ fontWeight: 600 }} />
  </a>
);