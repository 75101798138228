import PropTypes from 'prop-types';
import { useState } from 'react';
import _ from 'lodash';
import { WurdText } from 'wurd-react';

import ErrMsg from './err-msg';
import { useApiForm } from '../../hooks';
import ErrorMessage from 'components/error-message';

// update of modal-form.js using useApiForm hook

export default function ModalForm({
  // These props should be passed by the main component which defines the fields/inputs (children)
  wurdSection, id = wurdSection, children,
  // These props are passed elsewhere e.g. by CrudList
  onChange, onSubmit, onSuccess, closeModal, mode, onDelete, initialValue,
  // optional fields override
  title = <WurdText id={`${wurdSection}.modal.title_${mode}`} vars={initialValue} type="h4" className="modal-title" />,
  submitButton = <WurdText id={`${wurdSection}.modal.submit_${mode}`} />,
  extraButton,
  closeIcon = <button type="button" className="close" onClick={closeModal}>&times;</button>,
  className,
  ...rest
}) {
  const { fieldProps, submit, errMsg, errors, loading, formValue, setFormValue, dirty, clear } = useApiForm({
    wurdSection,
    initialValue,
    onChange,
    onSubmit,
    onSuccess,
    ...rest,
  });

  const [error, setError] = useState(null);

  async function handleDelete() {
    try {
      await onDelete();
    } catch (err) {
      setError(err);
    }
  }

  const anyErrMsg = error ? ErrMsg(error) : errMsg;

  return (
    <form id={id} onSubmit={submit} className={className}>
      {(title || closeIcon) && (
        <div className="modal-header">
          {closeIcon}
          {title}
        </div>
      )}
      <div className="modal-body form-horizontal">

        {children(fieldProps, formValue, { setFormValue, clear })}

        {anyErrMsg && (
          <ErrorMessage>
            {anyErrMsg}

            {errors && (
              <ul>
                {_.map(errors, (msg, key) => (
                  <li key={key}>{key}: {msg}</li>
                ))}
              </ul>
            )}
          </ErrorMessage>
        )}

      </div>
      <div className="modal-footer">
        {onDelete && (
          <button
            type="button"
            className="btn btn-danger pull-left"
            onClick={handleDelete}
          >
            <WurdText id="common.deleteBtn" />
          </button>
        )}

        {extraButton}

        <button
          type="button"
          className="btn btn-default"
          onClick={closeModal}
        >
          <WurdText id="common.cancelBtn" />
        </button>

        {submitButton && (
          <button
            className="btn btn-primary"
            type="submit"
            form={id}
            disabled={loading}
          >
            {loading
              ? <i className="fa fa-spinner fa-spin" />
              : submitButton
            }
          </button>
        )}
      </div>
    </form>
  );
}


ModalForm.propTypes = {
  children: PropTypes.func.isRequired,
  titles: PropTypes.object
};
