import styled from '@emotion/styled';

import { iconColors } from '../../../helpers/ui';

import ShortLabel from '../../short-label';
import SelectItemCheckbox from '../../checkbox_select-item';
import ItemStateIcon from '../../item-state-icon';

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: 16,
  padding: '4px 0',

  '.image': {
    width: 40,
    height: 40,
    objectFit: 'cover',
  },

  '.text': {
    marginLeft: 8,
  },

  '.desc': {
    fontSize: 12,
    color: '#666',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  '.sid': {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },

  '.updateErr': {
    marginLeft: -6,
    fontSize: 12,
    /* overflow: 'hidden',
    whiteSpace: 'nowrap', */
  },

  '.state': {
    marginLeft: 'auto',
  },

  '.state-icon': {
    fontSize: 22,
  },

  '.location': {
    marginRight: 8,
    color: '#999',
  },
});


export default function Item({
  item,
  isSelected = false,
  toggleSelected,
  updateErr = null,
  onClick,
  successState,
  todoState,
  hideLocation = false,
  hideDesc = false,
}) {
  return (
    <Wrapper>
      <SelectItemCheckbox
        isSelected={isSelected}
        onClick={toggleSelected}
      />

      <img
        className="image"
        src={item.image}
        alt=""
      />

      <div
        className="text"
        onClick={onClick}
        onKeyPress={onClick}
        tabIndex={0}
        role="button"
      >
        {!hideDesc && (
          <div className="desc">{item.opsTitle || item.typeTitle.en}</div>
        )}

        <div className="sid">{item.sid}</div>

        {updateErr && (
          <div className="updateErr">
            <ShortLabel
              bsType="danger"
            >
              <i className="fas fa-exclamation-triangle" /> {updateErr}
            </ShortLabel>
          </div>
        )}
      </div>

      <div
        className="state"
        onClick={onClick}
        onKeyPress={onClick}
        tabIndex={0}
        role="button"
      >
        {!hideLocation && (
          <span className="location">{item.location}</span>
        )}

        <span className="state-icon">
          {successState && item.state === successState && (
            <i style={iconColors.success} className="far fa-check-circle" />
          )}
          {/* {successState && item.state !== successState && (
            <i style={iconColors.warning} className="far fa-circle" />
          )} */}
          {!successState && item.state !== todoState && (
            <ItemStateIcon item={item} />
          )}
        </span>
      </div>
    </Wrapper>
  );
}
