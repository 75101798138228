import wurd from 'wurd-react';
import { ModalForm2, Field, MultiLangText } from 'components/form2';
import { getLangText } from 'helpers/ui';
import { get as getSettings } from 'helpers/settings';


export function slugify(title) {
  return title
    ?.trim()
    .replace(/ +/g, '-')
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, '')
}

export default function SiteForm({ site, ...rest }) {
  return (
    <ModalForm2
      onSuccess={rest.closeModal}
      {...rest}
      onSubmit={(data, patch) => rest.onSubmit({ ...data, path: data.path ?? slugify(getLangText(data.meta?.title)) }, patch)}
      wurdSection="website.page"
    >
      {({ fieldProps, formValue }) => (
        <>
          <Field {...fieldProps('meta.title')} placeholder={wurd.text('website.page.meta.title.placeholder', { companyName: getSettings()?.companyName })}>
            <MultiLangText autoFocus />
          </Field>

          <Field {...fieldProps('path')} value={formValue.path ?? slugify(getLangText(formValue.meta?.title))} />

          <Field {...fieldProps('meta.description')}>
            <MultiLangText />
          </Field>
        </>
      )}
    </ModalForm2>
  );
}
