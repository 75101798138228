import wurd from 'wurd-react';

import ModalForm2 from '../../form2/modal-form2';
import { Field, DatePicker } from '../../form2';
import UnitSelector from '../../form2/unit-selector';


const cms = wurd.block('jobs.unit_transfer.create');


export default function UnitTransferForm({ siteId, typeId, ...rest }) {
  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode="create"
      className="form2"
      {...rest}
    >
      {({ fieldProps, formValue }) => {
        return (
          <>
            <Field {...fieldProps('newUnitId')}>
              <UnitSelector
                required
                siteId={siteId}
                unitTypeId={typeId}
                bsStyle={formValue.newUnitId ? 'primary' : 'default'}
              />
            </Field>

            <Field {...fieldProps('transferDate')}>
              <DatePicker
                minDate={null}
                required
              />
            </Field>

            <Field {...fieldProps('price')} />
          </>
        );
      }}
    </ModalForm2>
  );
}
