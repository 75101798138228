import { DropdownButton, MenuItem } from 'react-bootstrap';
import omit from 'lodash/omit';

import { ModalDialog } from '../modal';
import ExportButton from '../export-button';
import ImportButton from '../import-button';
import * as helpers from '../../helpers';
import * as actions from '../../actions';


export default function SiteListMenu({
  modal,
  cms,
  listQuery,
  refetch,
}) {
  if (!modal) return null;

  function openExportModal() {
    modal.open(
      <ModalDialog cms={cms.block('exportModal')}>
        <ExportButton
          filename="sites.csv"
          fetch={params => actions.fetch('sites', {
            ...listQuery.params,
            ...params,
            include: 'customFields',
          }, { skipCache: true })}
          toCsv={helpers.site.toCsv}
        />
      </ModalDialog>
    );
  }

  function openImportModal() {
    modal.open(
      <ModalDialog cms={cms.block('importModal')}>
        <ImportButton
          processRow={async row => actions.create('sites', await helpers.site.fromCsv(row), { include: 'customFields' })}
          onComplete={refetch}
          mode="create"
        />
      </ModalDialog>
    );
  }

  function openUpdateModal() {
    modal.open(
      <ModalDialog cms={cms.block('updateModal')}>
        <ImportButton
          processRow={async row => actions.update('sites', row.id, omit(await helpers.site.fromCsv(row), 'id'), { include: 'customFields' })}
          onComplete={refetch}
          mode="update"
        />
      </ModalDialog>
    );
  }

  return (
    <cms.Object id="menu" keys="export,import,update">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        <MenuItem
          key="export"
          onSelect={openExportModal}
        >
          <i className="fas fa-fw fa-download" /> {cms.text('menu.export')}
        </MenuItem>
        <MenuItem
          key="import"
          onSelect={openImportModal}
        >
          <i className="fas fa-fw fa-upload" /> {cms.text('menu.import')}
        </MenuItem>
        <MenuItem
          key="update"
          onSelect={openUpdateModal}
        >
          <i className="fal fa-fw fa-upload" /> {cms.text('menu.update')}
        </MenuItem>
      </DropdownButton>
    </cms.Object>
  );
}
