import styled from '@emotion/styled';

import * as config from 'config';
import * as helpers from 'helpers';

import LangText from 'components/lang-text';
import StateLabel from 'components/state-label';
import SiteLoader from 'components/loaders/site';

const styles = {
  imageCol: {
    width: 65,
  },
  image: {
    width: 50,
    height: 50,
    background: '#fff',
    objectFit: 'contain',
  },
  secondary: {
    color: '#bbb',
  },
  tertiary: {
    color: '#bbb',
    fontSize: '.8em',
    lineHeight: '2.1em',
  },
};

const ListItemPropList = styled.ul`
  margin: 0;
  margin-top: 4px;
  padding: 0;
  font-size: .8em;

  li {
    margin-right: 1em;
    display: inline;

    i {
      color: #bbb;
    }
  }
`;


export default function AddonListInstalledItem({
  cms,
  addonConfig,
  addon,
  hideSite,
}) {
  return (
    <>
      <div role="cell" style={styles.imageCol}>
        <img src={addonConfig?.image || `${config.publicUrl}/addon.png`} style={styles.image} alt="" />
      </div>

      <div role="cell">
        <div><strong><LangText>{addonConfig?.title}</LangText></strong></div>

        <ListItemPropList>
          {!hideSite && addon.siteId && (
            <li title="Site">
              <i className="far fa-building" />&nbsp;
              <SiteLoader id={addon.siteId}>
                {(site) => (
                  <LangText>{site.title}</LangText>
                )}
              </SiteLoader>
            </li>
          )}
          {addon.price > 0 && (
            <li title="Price per month">
              <i className="far fa-dollar-sign" /> {helpers.ui.currency(addon.price, { currency: '' })}
            </li>
          )}
        </ListItemPropList>
      </div>

      <div role="cell" className="text-right">
        {addon.state === 'enabled' && (
          <StateLabel
            type="success"
          >
            <cms.Text id="enabled" />
          </StateLabel>
        )}
        {addon.state === 'paused' && (
          <StateLabel
            type="warning"
          >
            <cms.Text id="paused" />
          </StateLabel>
        )}
        {addon.state === 'disabled' && (
          <StateLabel
            type="danger"
          >
            <cms.Text id="disabled" />
          </StateLabel>
        )}
      </div>
    </>
  );
}
