import { Children, cloneElement } from 'react';
import styled from '@emotion/styled';


const TableNav = styled('div')({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',

  '[role=rowgroup]': {
    display: 'table-row-group',
    verticalAlign: 'middle',
  },
  '[role=row]': {
    display: 'table-row',
    verticalAlign: 'inherit',
    color: 'inherit',
    userSelect: 'text',
  },
  '[role=row]:hover, [role=row]:active': {
    color: 'inherit',
  },
  'label[role=row]': {
    fontWeight: 'inherit',
    marginBottom: 'inherit',
    maxWidth: 'inherit',
  },

  '[role=cell]': {
    display: 'table-cell',
    verticalAlign: 'middle !important',
  },

  '[role=row].fade': {
    opacity: 0.5,
  },

  '[role=row]:nth-child(2n)': {
    background: '#f8f8f8',
  },

  '[role=rowgroup]>[role=row]:hover>[role=cell]': {
    backgroundColor: '#f5f5f5',
  },

  '[role=rowgroup]>[role=row]>[role=cell]': {
    padding: 8,
    lineHeight: 1.42857143,
    verticalAlign: 'top',
    borderTop: '1px solid #ddd',
    fontSize: '1.2em',
  },

  '.no-border-y [role=cell]': {
    borderLeft: 0,
  },
  '.no-border-x [role=cell]': {
    borderBottom: 0,
  },

  '.secondary': {
    color: '#bbb',
  },

  '.tertiary': {
    fontSize: '.8em',
    color: '#bbb',
  },

  '.selectCheckboxCol': {
    paddingLeft: 10,
    paddingTop: 4,
    width: 20,
  }
});


export default function SimpleNavList({ children, ...props }) {
  return (
    <TableNav role="table" className="table table-list no-border table-hover no-margin" {...props}>
      <nav role="rowgroup" className="no-border-x no-border-y">
        {Children.map(children, child => {
          return cloneElement(child, { draggable: false });
        })}
      </nav>
    </TableNav>
  );
}
