import { WurdText } from 'wurd-react';
import styled from '@emotion/styled';
import { getLangText, date as formatDate } from 'helpers/ui';
import { currentLang } from 'utils/language';
import store from 'store';
import LangText from 'components/lang-text';
import Markdown, { Editable } from 'components/markdown';
import { Field, MultiLangText, FormObject, DatePicker, ModalForm2 } from 'components/form2';
import ImageUploader from 'components/settings/image-uploader';
import FAIcon from 'components/form2/fa-icon';
import { VariantField } from './hero';
import UrlPicker from '../url-picker';


const Item = styled.li({
  padding: '.5rem .5rem 0',
  '&:hover': {
    outline: '#0002 auto 1px'
  },
});


export function edit({ block, setBlock, modal }) {
  modal.open(
    <ModalForm2
      wurdSection="website.page.block"
      mode="update"
      initialValue={block}
      onSubmit={async data => setBlock(data)}
      onSuccess={modal.close}
      closeModal={modal.close}
    >
      {({ fieldProps }) => (
        <>
          <Field {...fieldProps('title')} autoFocus><MultiLangText /></Field>
          <Field {...fieldProps('text')}><MultiLangText multiline rows={10} /></Field>
          {block.type === 'blogs1' && <Field {...fieldProps('btn.text')}><MultiLangText /></Field>}
          <VariantField {...fieldProps('variant')} />
          <Field {...fieldProps('id')} />
        </>
      )}
    </ModalForm2>
  )
}

export default ({ block, setBlock, modal }) => {
  function editItem(i) {
    modal.open(
      <ModalForm2
        wurdSection="website.page.block_item"
        mode={i !== undefined ? 'update' : 'create'}
        initialValue={block.items?.[i]}
        onSubmit={i !== undefined
          ? async data => setBlock({ items: block.items.map((b, j) => i === j ? { ...b, ...data } : b) })
          : async data => setBlock({ items: [...block.items || [], data] })
        }
        onDelete={i !== undefined && (async () => setBlock({ items: block.items.filter((_, j) => i !== j) }))}
        onSuccess={modal.close}
        closeModal={modal.close}
      >
        {({ fieldProps }) => (
          <>
            {block.type.startsWith('features') && <Field {...fieldProps('icon')}><FAIcon variant="fal" /></Field>}
            <Field {...fieldProps('title')} autoFocus><MultiLangText /></Field>
            <Field {...fieldProps('text')}><MultiLangText multiline={block.type !== 'blogs1'} /></Field>
            {block.type.startsWith('features') && <Field {...fieldProps('btn.text')}><MultiLangText /></Field>}
            {!block.type.startsWith('features') && (
              <>
                <ImageUploader {...fieldProps('img.url')} showUrlButton style={{ maxWidth: '100%', height: 50 }} />
                <Field {...fieldProps('img.alt')} />
              </>
            )}
            {block.type !== 'prices1' && <Field {...fieldProps('btn.url')}><UrlPicker /></Field>}
            {block.type === 'prices1' && (
              <>
                <Field {...fieldProps('price.display')}><MultiLangText /></Field>
                <Field {...fieldProps('price.info')}><MultiLangText /></Field>
              </>
            )}
            {block.type === 'blogs1' && (
              <>
                <Field {...fieldProps('date')}><DatePicker minDate={null}/></Field>
              </>
            )}
          </>
        )}
      </ModalForm2>
    )
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: block.type === 'features2' ? 'row' : 'column', flexWrap: 'wrap', gap: '1rem', alignItems: block.type === 'features2' ? 'center' : null, padding: '.5rem 1rem' }}
    >
      <div style={{ flex: block.type === 'features2' ? '1 1 250px' : null }}>
        <h4 className="text-center" style={{ fontWeight: 400 }}>
          <Editable inline placeholder="[Title]" onChange={e => setBlock({ title: { [currentLang]: e.target.value } })}>{getLangText(block.title)}</Editable>
        </h4>
        <Editable className="text-center" placeholder="[Text]" style={{ padding: '0 1rem', maxWidth: '50%' }} onChange={e => setBlock({ text: { [currentLang]: e.target.value } })}>{getLangText(block.text)}</Editable>
      </div>

      <ul
        className="list-unstyled"
        style={{ flex: block.type === 'features2' ? '1 1 250px' : null, display: 'flex', justifyContent: 'center', flexDirection: block.type === 'features2' ? 'column' : 'row', flexWrap: 'wrap', gap: block.type === 'features2' ? '1rem' : '2rem', marginTop: '1rem' }}
      >
        {block.items?.map((item, i) => item && (
          <Item
            key={i}
            onClick={e => editItem(i)}
            style={{ flex: '1 1 25%', display: 'flex', flexWrap: 'wrap', flexDirection: block.type === 'features2' ? 'row' : 'column', alignItems: 'center', gap: '.5rem', maxWidth: block.type === 'features2' ? null : 150, ...!block.type.startsWith('features') && { padding: '.5rem', border: '1px solid #eee', backgroundColor: '#fff' } }}
          >
            {block.type.startsWith('features')
              ? <i className={`fa-fw ${item.icon}`} style={{ fontSize: block.type === 'features2' ? '1.25em' : '2em' }} />
              : <img src={item.img?.url} style={{ height: '3em' }} />
            }
            {block.type === 'blogs1' && <small className="text-muted">{formatDate(item.date, 'long')}</small>}
            <Markdown inline style={{ fontWeight: 600, maxWidth: '100%', wordBreak: 'break-word' }}>{getLangText(item.title)}</Markdown>
            <Markdown inline={block.type === 'features2'} style={{ flex: block.type !== 'features2' ? 1 : null, maxWidth: '100%', wordBreak: 'break-word' }}>{getLangText(item.text)}</Markdown>
            {block.type === 'prices1' && (
              <>
                <strong>{getLangText(item.price?.display)}</strong> 
                <small className="text-muted">{getLangText(item.price?.info)}</small>
              </>
            )}
            {block.type.startsWith('features') && (
              <a
                href={item.btn?.url}
                onClick={e => e.preventDefault()}
                target="_blank"
                className="btn btn-link btn-sm mb-0"
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', ...block.type === 'features2' && { padding: 0, order: 2 } }}
              >
                <LangText>{item.btn?.text}</LangText>
              </a>
            )}
          </Item>
        ))}
        <li style={!block.type.startsWith('features') ? { flex: '1 1 10%', maxWidth: 150 } : null}>
          <button className="btn btn-link btn-sm mb-0" onClick={() => editItem()} style={{ outline: '1px dashed var(--bs-primary)', outlineOffset: -1, height: '100%', ...!block.type.startsWith('features') && { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fffe' } }}>
            <i className="far fa-plus" /> <WurdText id="website.blocks.addItem" />
          </button>
        </li>
      </ul>
    </div>
  );
}
