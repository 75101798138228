import api from '../utils/api';
import { apiUrl } from 'config';
import { create, update, del, updateCachedData } from './general';
import errHandler from './_err-handler';
import crudList from './plugins/crud-list';
import labelsPlugin from '../plugins/labels/actions';


const endpoint = 'sites';
const url = `/v1/admin/${endpoint}`;

const actions = {};

function handleResponse(res) {
  updateCachedData(endpoint, res.data);

  return res.data;
}


actions.create = function (data) {
  return create(endpoint, data);
};


actions.update = async function (id, data) {
  return update(endpoint, id, data, { include: 'customFields' });
};

actions.delete = async function (id) {
  return del(endpoint, id);
};

/**
 * Generate agreements
 * @param  {string} moveInJobId
 */
actions.generateAgreement = (moveInJobId) => {
  return fetchApi(`/esign/generate/${moveInJobId}`);
}

/**
 * Preview agreements
 * @param {{ text: string, mode: 'html'|'pdf' }}
 */
actions.previewAgreement = ({ text, pdf, mode }) => {
  return fetchApi('/esign/preview', {
    body: { agreementTemplate: text, agreementPdf: pdf, agreementType: mode },
    ...mode === 'pdf' && { headers: { Accept: 'application/pdf' } },
  });
}


/**
 * @param {*} id
 * @param {array<SitePosition>} data
 */
actions.updatePositions = async function (id, data) {
  const positions = data.map(({
    type, id, x, y, rotation, scaleX, scaleY, width, length, floor, objectType, name,
  }) => {
    return {
      type, id, x, y, rotation, scaleX, scaleY, width, length, floor,
      ...type === 'object' && { objectType, name },
    };
  });

  return api.put(`${url}/${id}/positions`, positions, { params: { include: 'positions' } })
    .then(handleResponse)
    .catch(errHandler);
};

actions.securitySystem = {
  fetch(siteId, params) {
    return api.get(`${url}/${siteId}/securitySystem`, { params })
      .then(res => res.data)
      .catch(errHandler);
  },
  sync(siteId, data) {
    return api.put(`${url}/${siteId}/securitySystem`, data)
      .then(res => res.data)
      .catch(errHandler);
  },
};


actions.unitTypeGroups = function (siteId) {
  return crudList(`${url}/${siteId}/unit-type-groups`);
};

actions.unitTypes = function (siteId) {
  return crudList(`${url}/${siteId}/unit-types`);
};

actions.products = function (siteId) {
  return crudList(`${url}/${siteId}/products`);
};

actions.lateFees = function (siteId) {
  return crudList(`${url}/${siteId}/late-fees`);
};

actions.labels = labelsPlugin('sites');

export default actions;


function fetchApi(path, { body, headers } = {}) {
  return fetch(`${apiUrl}${url}${path}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      ...headers,
      ...body && { 'Content-Type': 'application/json' },
    },
    body: body && JSON.stringify(body),

  })
    .then(async r => {
      if (!r.ok) {
        const data = await r.json();
        throw Object.assign(new Error(`Validation error: ${data.error?.message}`), { type: data.error?.type });
      }
      return r;
    })
    .catch(errHandler);
}