import _ from 'lodash';

import * as language from '../utils/language';
import * as settingsHelpers from './settings';
import { getLangText } from './ui';


function doTranslate(itemConfig, lang) {
  const obj = Object.assign({}, itemConfig);

  ['title'].forEach(prop => {
    obj[prop] = getLangText(obj[prop], lang);
  });

  return obj;
}


/**
 * Returns the list of market types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const list = function (translate) {
  const marketConfigs = settingsHelpers.get().markets;

  if (translate) {
    const lang = language.get();

    return marketConfigs.map(marketConfig => doTranslate(marketConfig, lang));
  } else {
    return marketConfigs;
  }
};


/**
 * Returns an object of item types, keyed by type
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object}
 */
export const byId = function (translate) {
  return _.keyBy(list(translate), 'id');
};


/**
 * Returns titles for item types, keyed by type and in the user's language
 *
 * @return {Object}
 */
export const titlesById = function () {
  const lang = language.get();

  return list().reduce((memo, market) => {
    memo[market.id] = getLangText(market.title, lang);
    return memo;
  }, {});
};


/**
 * Returns a specific market type
 *
 * @param {String} id
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const get = function (id, translate) {
  const marketConfig = _.find(list(), { id: id });

  if (!marketConfig) {
    const err = new Error('marketConfig not found for ' + id);
    //_errs.push(err);
    console.error(err);
    return {};
  }

  if (translate) {
    return doTranslate(marketConfig, language.get());
  } else {
    return marketConfig;
  }
};



/**
 * Gets the title of a market given it's ID
 *
 * @param {String} marketId
 */
export const getTitle = function (marketId) {
  const market = get(marketId, true) || {};

  return market.title;
};


/**
 * Returns whether the operator has multiple markets configured.
 * So that market functionality can be hidden if not being used.
 *
 * @return {Boolean}
 */
export const hasMultiple = function () {
  return list().length > 1;
};
