import wurd from 'wurd-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { fetchDetailed, toCsv } from 'helpers/payment';

import { ModalDialog } from '../modal';
import ExportButton from '../export-button';

const cms = wurd.block('invoiceList.payments');

export default function PaymentList({
  modal,
  listQuery,
}) {
  if (!modal) return null;

  function exportCsv(format) {
    modal.open(
      <ModalDialog cms={cms.block('exportModal')}>
        <ExportButton
          filename="payments.csv"
          fetch={params => fetchDetailed({ ...listQuery.params, ...params })}
          toCsv={toCsv}
          sections={{
            main: '*',
            invoice: ['sid'],
            owner: ['firstName', 'lastName', 'email'],
            site: ['code'],
            unit: ['name'],
          }}
        />
      </ModalDialog>
    );
  }

  return (
    <cms.Object id="menu" keys="export,helpUrl">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        <MenuItem
          key="export"
          onSelect={exportCsv}
        >
          <i className="fas fa-fw fa-download" /> {cms.text('menu.export')}
        </MenuItem>
      </DropdownButton>
    </cms.Object>
  );
}