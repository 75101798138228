/**
 * A checklist form control.
 * Renders child components as a list of items with checkboxes.  Each child should have an 
 * "id" property. This is added/removed from an array on the form and used to control whether 
 * the item is checked or not.
 */
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import classnames from 'classnames';
import _ from 'lodash';

import wurd, { WurdObject } from 'wurd-react';


const FormChecklist = createClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array
  },

  render() {
    const { wurdId, wurdKeys, error, children } = this.props;

    const { label, help } = wurd.get(wurdId) || {};

    const selectedIds = this.props.value;

    return (
      <WurdObject
        id={wurdId}
        keys={wurdKeys || 'label,help'}
        type="div"
        className={classnames('form-group', { 'has-error': error })}
      >
        <label className="control-label col-sm-3">{label}</label>
        <div className="col-sm-9">
          {help && <div className="help-block">{help}</div>}
          {error && error[0] && <div className="help-block">{error[0].message}</div>}

          <div className="list-group" style={{ maxHeight: 300, overflow: 'auto' }}>
            {children.map(item => {
              let itemId = item.props.id;

              return (
                <label key={itemId} className="list-group-item clearfix" style={{ fontWeight: 'normal' }}>
                  <div className="pull-left" style={{ width: 25 }}>
                    <input
                      type="checkbox"
                      checked={_.includes(selectedIds, itemId)}
                      onChange={this.toggleSelected.bind(null, itemId)}
                    />
                  </div>

                  {item}
                </label>
              );
            })}
          </div>
        </div>
      </WurdObject>
    );
  },

  toggleSelected(itemId) {
    var selectedIds = this.props.value || [];

    if (_.includes(selectedIds, itemId)) {
      selectedIds = _.without(selectedIds, itemId);
    } else {
      selectedIds = selectedIds.concat(itemId);
    }

    this.props.onChange(selectedIds);
  }

});


export default FormChecklist;
