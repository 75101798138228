import ReactDOM from 'react-dom';
import wurd from 'wurd-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { marked } from 'marked';
import store from './store';
import Router from './router';
import { basePath, wurdSections } from './config';
import Notifications from './components/notifications';

import * as language from './utils/language';

import './index.css';
import '@fortawesome/fontawesome-pro/css/all.css';

// todo move this only where used
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip
);
// ChartJS.defaults.responsive = true; // already true https://www.chartjs.org/docs/latest/configuration/responsive.html#configuration-options
ChartJS.overrides.bar.interaction = { mode: 'x', intersect: false };
ChartJS.overrides.line.interaction = { mode: 'x', intersect: false };
ChartJS.overrides.line.borderWidth = 1.5;
ChartJS.overrides.line.pointRadius = 2;
ChartJS.overrides.line.pointHoverRadius = 3;
ChartJS.overrides.line.tension = 0.4;

// Force HTTPS
if (window.location.hostname !== 'localhost') {
  if (window.location.protocol === 'http:') {
    window.location.protocol = 'https:';
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  }
});

function render() {
  ReactDOM.render((
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={basePath}>
          <Router />
        </BrowserRouter>
      </QueryClientProvider>
      <Notifications />
    </>
  ), document.getElementById('root'));
}

// Show UI with cached content if available
language.initLang();

wurd.connect('storeganise-admin', {
  lang: language.get(),
  editMode: 'querystring',
  markdown: marked,
  onLoad: render,
});
render();

wurd.load(wurdSections.join(','));

// Re-render when store data changes
store.onChange(render);

window.app_version = process.env.REACT_APP_VERSION; // useful for debugging, could be shown somewhere in UI
