import { WurdText } from 'wurd-react';
import { Link } from 'react-router-dom';
import Avatar from '../avatar';
import UserLoader from '../loaders/user';
import SidebarItem from './index';
import Placeholder from './loading-placeholder';


export default function SidebarUser({ id, user, title }) {
  const actualTitle = title || <WurdText id="common.sidebarItems.user" />;

  return (
    <UserLoader
      id={id || user.id}
      loading={<Placeholder typeTitle={actualTitle} />}
    >
      {(user) => (
        <SidebarItem
          thumbnail={<Avatar user={user} />}
          typeTitle={title || <WurdText id="common.sidebarItems.user" />}
          itemTitle={<Link to={`/users/${user.email}`}>{user.name}</Link>}
        />
      )}
    </UserLoader>
  );
}
