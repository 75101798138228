import wurd from 'wurd-react';
import { useNavigate } from 'react-router-dom';

import * as actions from 'actions';
import { useHistoryListQuery, useListLoader } from 'hooks';

import TableList from 'components/table-list';
import InstalledItem from 'components/addons/list/installed-item';
import AddonConfigSelector from 'components/addons/addon-config-selector';

const cms = wurd.block('addons');


export default function SiteAddons({
  modal,
  site,
}) {
  const navigate = useNavigate();

  const listQuery = useHistoryListQuery();
  
  const addonConfigsLoader = useListLoader('addon-configs', listQuery.params, { maxAge: 0 });
  
  const siteAddonsLoader = useListLoader('addons', {
    siteId: site.id,
  }, { maxAge: 0 });

  const allAddonsLoader = useListLoader('addons', {}, { maxAge: 0 });

  const addonConfigs = addonConfigsLoader.items;

  // Combine all site-specific and business-wide addons; i.e. all addons that act on this site
  const siteAddons = siteAddonsLoader.items;
  const businessAddons = allAddonsLoader.items.filter(addon => !addon.siteId);
  const installedAddons = [...siteAddons, ...businessAddons];

  function getRelatedAddonConfig(addon) {
    return addonConfigs.find(addonConfig => addonConfig.id === addon.addonConfigId);
  }

  async function installAddon(addonConfig) {
    const addon = await actions.create('addons', {
      addonConfigId: addonConfig.id,
      siteId: site.id,
    });

    navigate(`/addons/${addon.id}`)
  }

  function browseAddonConfigs() {
    modal.open(
      <AddonConfigSelector
        modal={modal}
        site={site}
        addonConfigs={addonConfigs}
        addons={installedAddons}
        onSelectAddonConfig={installAddon}
      />
    );
  }

  return (
    <>
      <TableList
        cms={cms.block('siteAddonsList')}
        listQuery={listQuery}
        loader={siteAddonsLoader}
        renderItem={(addon) => (
          <InstalledItem
            cms={cms}
            addonConfig={getRelatedAddonConfig(addon)}
            addon={addon}
            hideSite
          />
        )}
        getItemLink={(addon) => `/addons/${addon.id}`}
        onClickAdd={browseAddonConfigs}
      />
    </>
  );
}
