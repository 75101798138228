import wurd from 'wurd-react';

import SidebarItem from './index';


export default function SidebarLoadingPlaceholder({ typeTitle }) {
  return (
    <SidebarItem
      icon="fas fa-square-full"
      typeTitle={typeTitle}
      itemTitle={wurd.text('common.loading')}
    />
  );
}
