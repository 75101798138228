import { useState } from 'react';
import wurd, { WurdObject } from 'wurd-react';
import * as actions from '../actions';
import * as helpers from '../helpers';


/**
 * @param {({target: {value: string}}) => {}} onChange
 * @param {React.ReactNode} text buttonText
 * @param {String} wurdKey
 */
export default function Uploader({
  name,
  value,
  onChange,
  accept = 'image/*',
  text,
  wurdKey = 'common.uploadImageBtn',
  keys = 'add,uploading,promptUrl',
  type,
  icon = <i className="fa fa-image" />,
  showUrlButton,
  bsStyle = 'default',
  id,
  ...props
}) {
  const [uploading, setUploading] = useState(false);

  function handleChange(event) {
    //Exit if user cancelled file selection
    const input = event.target;
    if (!input.files.length) return;
    const file = input.files[0];

    setUploading(true);

    actions.uploads.uploadFile(file)
      .then(result => {
        onChange({ target: { name, value: result.url.replace(/ /g, '+'), file } });
      })
      .catch(err => {
        window.alert(err.message);
      })
      .finally(() => {
        setUploading(false);
      })
  }

  function promptUrl() {
    const url = helpers.ui.prompt(wurd.text(`${wurdKey}.promptUrl`) || 'Image URL:', value);
    if (url != null) onChange({ target: { name, value: url.replace(/ /g, '+') } });
  }


  return (
    <WurdObject
      id={wurdKey}
      keys={keys}
      {...props}
    >
      <label className={`btn btn-flat btn-${bsStyle}`}>
        {uploading
          ? <span><i className="fa fa-spin fa-spinner" /> {wurd.text(`${wurdKey}.uploading`)}</span>
          : <span>{icon} {text || wurd.text(`${wurdKey}.add`)}</span>
        }

        <input type="file" accept={accept} onChange={handleChange} name={name} id={id} style={{ display: 'none' }} />
      </label>
      {showUrlButton && (
        <button className="btn btn-default btn-flat" type="button" onClick={promptUrl}>
          <i className="far fa-link" />
        </button>
      )}
    </WurdObject>
  );
}
