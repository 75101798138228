import * as helpers from '../../../helpers';
import * as actions from '../../../actions';

import { LargeButton } from '../../large-button-group';


export default function CompleteStepButton({
  valetOrder,
  step,
  cms,
  hasProcessedAll,
  onClick,
}) {
  async function completeStep(e) {
    // Warn if has not processed all items
    if (!hasProcessedAll) {
      const confirmProceed = window.confirm(cms.text('notFinished_confirm'));
      if (!confirmProceed) return;
    }

    await actions.valetOrders.updateStep(valetOrder.id, step.id, {
      state: 'completed',
    });

    if (onClick) await onClick(e);

    // Scroll to the new step
    helpers.ui.scrollToEl(document.querySelector('.sg-active-step'));
  }

  return (
    <LargeButton
      bsType={hasProcessedAll ? 'success' : 'default'}
      faIcon="far fa-check"
      text={cms.text('completeBtn')}
      onClick={completeStep}
    />
  );
}
