/**
 * A 'loading' component; for replacing page content while content is being fetched
 */
export default function Spinner(props) {
  return (
    <div className="text-center text-muted" style={{ margin: '3em 0 1em' }} {...props}>
      <i className="fa fa-spinner fa-spin fa-2x" />
    </div>
  );
}
