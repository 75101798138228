import styled from '@emotion/styled';
import Input from './input';

const Container = styled.div({
  marginBottom: 0,
  display: 'flex',
  'input': {
    zIndex: 'auto !important', // To prevent this input overlapping datepicker when next to it
  },
  '.input-group-addon': {
    display: 'flex',
    alignItems: 'center',
    filter: 'opacity(.75)',
    color: '#0008',
    width: 'auto',
  }
});

/**
 * An input that has a start and/or end section to make the field more contextual.
 * Used for adding currency symbols, etc. to the input.
 **/
export default function InputGroup({
  before,
  after,
  ...rest
}) {
  return (
    <Container>
      {before && (
        <div className="input-group-addon">{before}</div>
      )}
      <Input {...rest} />
      {after && (
        <div className="input-group-addon">{after}</div>
      )}
    </Container>
  );
}
