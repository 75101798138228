import { Component } from 'react';
import PropTypes from 'prop-types';


class ItemScanner extends Component {

  static propTypes = {
    onScan: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      sid: '',
      lastKeyTime: null
    };
  }

  componentDidMount() {
    this.textHandler = window.addEventListener('keypress', this.onTextChange);
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.onTextChange);
  }

  render() {
    return null;
  }

  onTextChange = (event) => {
    const { onScan } = this.props;
    const { sid, lastKeyTime } = this.state;
    const { key, timeStamp } = event;

    const timeDiff = timeStamp - lastKeyTime;

    if (lastKeyTime && timeDiff > 100) {
      this.setState({
        sid: key,
        lastKeyTime: timeStamp
      });

      return;
    }

    if (key === 'Enter') {
      onScan(sid.toLowerCase());

      this.setState({ sid: '' });
    } else {
      const newSid = sid + key;

      this.setState({
        sid: newSid,
        lastKeyTime: timeStamp
      });
    }
  }

}


export default ItemScanner;
