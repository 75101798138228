import wurd from 'wurd-react';
import { useNavigate } from 'react-router-dom';

import { useModal, useHistoryListQuery } from '../hooks';
import * as actions from '../actions';
import { hasRole } from '../helpers/auth';

import Page from '../components/page';
import UserList from '../components/user-list';
import UserForm from '../components/form2/user/custom';

const cms = wurd.block('userList');

const initialParams = {
  state: 'enabled',
};


export default function UserListPage() {
  const navigate = useNavigate();
  const modal = useModal();
  const listQuery = useHistoryListQuery({ initialParams });

  function createUser({ refetch }) {
    modal.open(
      <UserForm
        onSubmit={actions.users.create}
        onSuccess={(user) => {
          refetch();
          modal.close();
          navigate(`/users/${user.email}`);
        }}
        closeModal={modal.close}
      />,
    );
  }

  return (
    <Page
      titleKey={cms.id('title')}
      userRole="admin"
      modal={modal}
    >
      <UserList
        listQuery={listQuery}
        modal={modal}
        onClickAdd={hasRole('admin') && createUser}
      />
    </Page>
  );
}
