import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import _ from 'lodash';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import { Modal } from 'react-bootstrap';
import wurd, { WurdText } from 'wurd-react';

import VisitForm from '../form/visit';

const { hasRole } = helpers.auth;
const t = (path) => wurd.get(path);

const Label = ({ id }) => (
  <WurdText type="dt" id={`visit.${id}.label`} />
);


const VisitDetails = createClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired,
    relatedvisit: PropTypes.object
  },

  getInitialState() {
    return {
      settings: helpers.settings.get(),
      user: null,
      modalContent: null
    };
  },

  componentDidMount() {
    this.fetchUser();
  },

  render() {
    const { visit, relatedVisit } = this.props;
    const { user, modalContent } = this.state;
    const order = visit.order;

    return (
      <section className="block-flat">
        <div className="header">
          <WurdText type="h3" id="visitView.details.title" />
        </div>
        <div className="content clearfix">
          <dl className="dl-horizontal">
            <Label id="state" />
            <dd>{t('visit._states.' + visit.state)}</dd>

            <Label id="owner" />
            <dd>
              {hasRole('admin')
                ? <a href={helpers.ui.href(`/users/${visit.owner.id}`)}>{visit.owner.name}</a>
                : visit.owner.name
              }
            </dd>

            {helpers.market.hasMultiple() && user && user.market &&
              <span>
                <WurdText type="dt" id="user.marketId.label" />
                <dd>{helpers.market.getTitle(user.market.id)}</dd>
              </span>
            }

            {order && order.planId &&
              <span>
                <WurdText type="dt" id="user.planId.label" />
                <dd>
                  {helpers.plan.getTitle(order.planId)}
                </dd>
              </span>
            }

            {order && order.promoCode &&
              <span>
                <Label id="promoCode" />
                <dd>{order.promoCode}</dd>
              </span>
            }
          </dl>

          {hasRole('admin') &&
            <button
              className="btn btn-link pull-right"
              onClick={this.editSection.bind(null, 'time')}
            >
              <i className="fas fa-edit"></i>
            </button>
          }

          <hr />

          <dl className="dl-horizontal">
            <Label id="date" />
            <dd>
              {helpers.ui.date(visit.date, 'long')}<br />
              {helpers.visit.formatTimeslot(visit.timeslot)}
            </dd>
          </dl>

          {visit.collect === 'immediately' &&
            <div>
              <dl className="dl-horizontal">
                <Label id="collect" />
                <WurdText type="dd" id="visit.collect_immediately" />
              </dl>
            </div>
          }

          {hasRole('admin') &&
            <button
              className="btn btn-link pull-right"
              onClick={this.editSection.bind(null, 'address')}
            >
              <i className="fas fa-edit"></i>
            </button>
          }

          <hr />

          <dl className="dl-horizontal">
            <Label id="address" />
            <dd style={{ whiteSpace: 'pre-line' }}>{visit.address}</dd>

            <Label id="area" />
            <dd style={{ whiteSpace: 'pre-line' }}>{helpers.area.getTitle(visit.area)}</dd>

            <Label id="phone" />
            <dd>{visit.phone}</dd>

            <Label id="instructions" />
            <dd style={{ whiteSpace: 'pre-line' }}>{visit.instructions || '-'}</dd>

            <Label id="altPhone" />
            <dd>{visit.altPhone || '-'}</dd>
          </dl>

          {hasRole('admin') && visit.state === 'wait' &&
            <button
              className="btn btn-link pull-right"
              onClick={this.editSection.bind(null, 'emptyItems')}
            >
              <i className="fas fa-edit"></i>
            </button>
          }


          <hr />

          <dl className="dl-horizontal">
            <Label id="orderedItems" />
            <dd>
              {_.map(visit.orderedItems, (num, type) => {
                if (num === 0) return null;

                return <div key={type}>{helpers.item.getTitle(type)}: {num}</div>
              })}
            </dd>
          </dl>

          {relatedVisit &&
            <div>
              <hr />

              <dl className="dl-horizontal">
                <Label id="relatedVisit" />
                <dd>
                  <a href={helpers.ui.href(`/visits/${relatedVisit.sid.toUpperCase()}`)}>
                    {relatedVisit.sid.toUpperCase()}
                  </a>
                  &nbsp;
                  ({t(`visit._types.${visit.relatedVisit.type}`)})<br />
                  {helpers.ui.date(relatedVisit.date, 'long')}<br />
                  {helpers.visit.formatTimeslot(relatedVisit.timeslot)}
                </dd>
              </dl>
            </div>
          }
        </div>

        <Modal show={!!modalContent} onHide={this.closeModal}>
          {modalContent}
        </Modal>
      </section>
    );
  },

  fetchUser() {
    const userId = this.props.visit.owner.id;

    actions.users.fetchOne(userId)
      .then(user => {
        this.setState({ user: user })
      });
  },

  editSection(section) {
    const sectionFields = {
      time: ['date', 'timeslot'],
      address: ['address', 'area', 'phone', 'instructions', 'altPhone'],
      emptyItems: ['orderedItems']
    }

    const Form = VisitForm;
    const fields = sectionFields[section];

    const content = (
      <Form
        defaultValue={this.props.visit}
        onSubmit={actions.visits.update.bind(null, this.props.visit.id)}
        onSuccess={this.onUpdatedVisit}
        submitButton={<WurdText id="visitView.details.updateJob" />}
        modal
        onHideModal={this.closeModal}
        fields={fields}
        user={this.state.user}
      />
    );

    this.setState({
      modalContent: content
    });
  },

  onUpdatedVisit() {
    this.closeModal();
    this.props.onChange();
  },

  closeModal() {
    this.setState({ modalContent: null });
  }

});


export default VisitDetails;
