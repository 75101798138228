import wurd from 'wurd-react';
import styled from '@emotion/styled';

import { iconColors } from '../helpers/ui';

const defaultCms = wurd.block('common');

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  minHeight: 200,
  justifyContent: 'center',
  color: '#666',
  textAlign: 'center',

  '.icon': {
    display: 'block',
    color: '#ccc',
    marginBottom: 16,
  },

  p: {
    fontSize: 14,
  },
});


export default function NoResults({
  faIcon = null,
  faColor = 'default',
  cms = defaultCms,
  children,
  onClickAdd,
}) {
  return (
    <Container>
      <div>
        {faIcon && (
          <i className={`icon far fa-4x ${faIcon}`} style={iconColors[faColor]} />
        )}

        {children || <cms.Markdown id="noResults" />}

        {onClickAdd && cms.text('empty.add') && (
          <button
            type="button"
            className="empty__add btn btn-primary btn-flat"
            onClick={onClickAdd}
          >
            <i className="far fa-plus" /> <cms.Text id="empty.add" />
          </button>
        )}
      </div>
    </Container>
  );
}
