import ModalForm from '../modal-form';
import Field from '../horizontal-field';
import AccountingCodeSelect from '../accounting-code-select';


export default function BusinessChargeForm({ intro, ...props }) {
  return (
    <ModalForm
      wurdSection="business.charge"
      {...props}
    >
      {(getProps) => (
        <>
          {intro}

          <Field {...getProps('accountingCodeId')}>
            <AccountingCodeSelect />
          </Field>
        </>
      )}
    </ModalForm>
  );
}
