import { useHistoryListQuery } from '../../hooks';

import UnitDetailsForm from '../unit-detail/forms/unit-details';
import UnitList from '../unit-list';


export default function SiteUnitList({ modal, site, fetchSite }) {
  const listQuery = useHistoryListQuery({
    implicitParams: {
      siteId: site.id,
      include: 'rental,customFields',
    },
  });

  function createUnits() {
    modal.open(
      <UnitDetailsForm
        mode="create"
        multi
        initialValue={{ siteId: site.id }}
        onSuccess={() => { modal.close(); listQuery.update({ _t: Date.now() }); }}
        closeModal={modal.close}
      />
    );
  }

  return (
    <UnitList
      listQuery={listQuery}
      modal={modal}
      site={site}
      fetchSite={fetchSite}
      hideSite
      onClickAdd={createUnits}
    />
  );
}
