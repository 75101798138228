import { useState } from 'react';
import wurd, { WurdText, WurdMarkdown } from 'wurd-react';
import { Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import pick from 'lodash/pick';
import styled from '@emotion/styled';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import SectionCrudList from '../../section_crud-list';
import Section from '../../section';
import LangText from '../../lang-text';
import UnitTagForm from '../../form2/business/unit-tag';

import { useApiForm } from '../../../hooks';

import Field from 'components/form2/horizontal-field';
import RadioList from 'components/form2/radiolist';
import HtmlEditor from 'components/form2/esign/html';
import PdfEditor from 'components/form2/esign/pdf';


const Wrapper = styled.div({
  '.list-group': {
    maxHeight: 320,
    overflow: 'auto',
  }
})

export default function UnitsSettings({ settings, modal }) {
  const [defaultEsign, setDefaultEsign] = useState();
  const { data: esignSettings = {}, isSuccess, refetch } = useQuery('esign-settings', () => actions.business.esign.fetch()
      .then(esignSettings => {
        setDefaultEsign(
          Object.values(esignSettings.moveInAgreementPdf || {}).filter(Boolean).length
            ? 'pdf'
            : 'html'
        );
        return esignSettings;
      }));

  const { submit, loading, dirty, formValue, setFormValue } = useApiForm({
    initialValue: {
      moveInAgreementTemplate: { ...esignSettings.defaultMoveInAgreementTemplate, ...esignSettings.moveInAgreementTemplate },
      moveInAgreementPdf: esignSettings.moveInAgreementPdf,
      enableMoveInAgreement: esignSettings.enableMoveInAgreement,
    },
    wurdSection: 'settings.unitMoveInAgreement',
    onSubmit: actions.business.update,
    onSuccess: refetch,
    onError: window.alert,
  });

  function resetHtml(lang) {
    setFormValue({
      moveInAgreementTemplate: {
        ...formValue.moveInAgreementTemplate,
        [lang]: esignSettings.defaultMoveInAgreementTemplate.en,
      }
    });
  }

  return (
    <Wrapper>
      <SectionCrudList
        cms={wurd.block('settings.unitFeatures')}
        items={settings.unitTags}
        Form={UnitTagForm}
        actions={actions.business.unitTags}
        onChange={actions.business.fetch}
      >
        {unitTag => (
          <>
            <i className={`fa-fw fa-2x ${unitTag.icon}`} />&nbsp;&nbsp;
          <span style={{ fontSize: '1.5em' }}><LangText obj={unitTag.title} /></span>
          </>
        )}
      </SectionCrudList>

      {helpers.auth.hasRole('manager') && isSuccess && (
        <Section cms={wurd.block('settings.unitMoveInAgreement')}>
          <RadioList
            onChange={e => setDefaultEsign(e.target.value)}
            value={defaultEsign}
          >
            {['html', 'pdf'].map(v => ({
              value: v,
              title: <WurdText id={`settings.esign.${v || 'none'}.title`} />,
              description: <WurdMarkdown id={`settings.esign.${v || 'none'}.desc`} type="div" className="text-muted" />
            }))}
          </RadioList>

          {defaultEsign === 'html' && <HtmlEditor value={formValue} onChange={setFormValue} reset={resetHtml} />}
          {defaultEsign === 'pdf' && <PdfEditor value={formValue} onChange={setFormValue} initialValue={esignSettings} modal={modal} />}

          <button
            className="btn btn-primary pull-right"
            type="button"
            disabled={loading || !dirty}
            onClick={submit}
          >
            {loading
              ? <i className="fa fa-spinner fa-spin" />
              : <WurdText id="common.saveBtn" />
            }
          </button>
        </Section>
      )}
    </Wrapper>
  );
}
