import moment from 'moment-timezone';
import wurd from 'wurd-react';

import * as helpers from '../../../helpers';
import * as actions from '../../../actions';

import JobStep from '../../jobs/job-step2';
import LargeButtonGroup from '../../large-button-group';
import MobileBottomButtons from '../shared/mobile-bottom-buttons';
import CompleteStepButton from '../shared/complete-step-button';
import StepCharges from '../shared/step-charges';

const cms = wurd.block('valetOrder.steps.products_pick');


const styles = {
  image: {
    width: 36,
    height: 36,
    marginRight: 8,
    objectFit: 'cover',
    border: '1px solid #eee',
  },
};


export default function Step_products_pick(props) {
  const {
    valetOrder,
    step,
    fetchValetOrder,
    modal,
  } = props;

  async function addProduct(productConfig, requestedQuantity) {
    const quantity = window.prompt(`How many items of ${productConfig.title} will you add to the order?`, requestedQuantity);
    if (!quantity) return;

    const charge = {
      productId: productConfig.id,
      desc: productConfig.title,
      price: productConfig.price,
      quantity,
      date: moment().format('YYYY-MM-DD'),
      stepId: step.id,
    };

    await actions.valetOrders.charges(valetOrder.id).create(charge);
    fetchValetOrder();
  }

  function renderActions() {
    return (
      <JobStep
        step={step}
        faIcon="fas fa-store"
        title={<cms.Text id="requiresInput.title" />}
        helpWurdId={cms.id('instructions.helpUrl')}
      >
        <div className="list-group">
          <StepCharges
            valetOrder={valetOrder}
            step={step}
            modal={modal}
            fetchValetOrder={fetchValetOrder}
          />
        </div>

        {valetOrder.products.map(({ id, quantity }) => {
          const productConfig = helpers.product.get(id, true);

          return (
            <div key={id} className="sg-job-detail__item-header clearfix">
              <div className="pull-right">
                <button
                  type="button"
                  className="btn btn-default"
                  style={{ marginTop: 8 }}
                  onClick={() => addProduct(productConfig, quantity)}
                >
                  <i className="far fa-plus" /> <cms.Text id="addBtn" />
                </button>
              </div>

              <h5>
                <img src={productConfig.image} alt="" style={styles.image} />
                {productConfig.title} &times; {quantity}
              </h5>
            </div>
          );
        })}

        <div style={{ marginTop: 40 }}>
          <MobileBottomButtons>
            <cms.Object keys="completeBtn,notFinished_confirm">
              <LargeButtonGroup>
                <CompleteStepButton
                  valetOrder={valetOrder}
                  step={step}
                  cms={cms}
                  hasProcessedAll
                />
              </LargeButtonGroup>
            </cms.Object>
          </MobileBottomButtons>
        </div>
      </JobStep>
    );
  }

  function renderResults() {
    return null;
  }


  return (step.state === 'completed') ? renderResults() : renderActions();
}
