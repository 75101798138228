import moment from 'moment-timezone';
import _ from 'lodash';

import store from '../store';
import * as actions from '../actions';
import { getAccountingCodeById } from './business';
import { getLangKeys } from './csv';
import * as helpers from '.';
import * as charges from '../plugins/charges/helpers';
import processCustomFields from '../plugins/custom-fields/actions';


export const states = [
  'submitted', 
  'cancelled',
  'abandoned', 
  'reserved',
  'occupied',
  'overdue',
  'ended',
];


/**
 * Sorts unitRentals by state (reserved, occupied), then by endDate
 * 
 * @param {Object[]}
 * @return {Object[]}
 */
export function sortByStateAndDate(items) {
  function sortByDate(a, b) {
    if ((a.endDate || a.startDate) < (b.endDate || b.startDate)) {
      return 1;
    } else {
      return -1;
    }
  }
  
  const byState = _.groupBy(items, function ({ state }) {
    if (state === 'reserved' || state === 'occupied') return state;

    return 'rest';
  });

  return [
    ...(byState['reserved'] || []).sort(sortByDate),
    ...(byState['occupied'] || []).sort(sortByDate),
    ...(byState['rest'] || []).sort(sortByDate),
  ];
}


export async function fetchDetailed(params) {
  const rentals = await actions.fetch('unit-rentals', params, { skipCache: true });

  const users = await actions.fetch('users', { ids: rentals.map(rental => rental.ownerId).filter(Boolean), include: 'billing,customFields' }, { skipCache: true }).then(users => new Map(users.map(o => [o.id, o])));
  const sites = await actions.fetch('sites', { ids: rentals.map(rental => rental.siteId), include: 'customFields' }, { skipCache: true }).then(sites => new Map(sites.map(o => [o.id, o])));
  const types = await actions.fetch('unit-types', { ids: rentals.map(rental => rental.unit?.typeId), include: 'customFields' }, { skipCache: true }).then(types => new Map(types.map(o => [o.id, o])));

  return rentals.map(rental => {
    const owner = users.get(rental.ownerId);
    const site = sites.get(rental.siteId);
    const type = types.get(rental.unit?.typeId);
    return { ...rental, owner, site, type };
  });
}


/**
 * Formats unit rentals as CSV
 *
 * @param {Object} rental
 * @return {String} csv
 */
export function toCsv(rental) {
  const settings = store.get('settings');
  const site = rental.site;
  const unitType = rental.type || site?.unitTypes.find(t => t.id === rental.unit.typeId);

  return {
    id: rental.id,
    unitId: rental.unitId,
    ownerId: rental.ownerId,
    state: rental.state,
    price: rental.price,
    deposit: rental.deposit,
    startDate: rental.startDate,
    endDate: rental.endDate,
    billedUntil: rental.billedUntil,
    depositBalance: rental.depositBalance,
    prepaymentBalance: rental.prepaymentBalance,
    revenueBalance: rental.revenueBalance,
    unpaidBalance: rental.unpaidBalance,
    invoiceDate: settings.invoiceGenerationMode === 'anniversary' ? rental.invoiceDate : undefined,
    'systemCharges.unitDeposit.accountingCodeId': getAccountingCodeById(rental.systemCharges?.unitDeposit.accountingCodeId)?.code,
    'systemCharges.unitRent.accountingCodeId': getAccountingCodeById(rental.systemCharges?.unitRent.accountingCodeId)?.code,
    _chargesTotal: charges.getTotal(rental.charges),
    ...rental.charges && Object.fromEntries(
      rental.charges.flatMap((charge, i) => {
        return [
          ...Object.entries(getLangKeys({ [`charges.${i}.title`]: charge?.title })),
          [`charges.${i}.amount`, charge?.amount],
          [`charges.${i}.qty`, charge?.qty],
          [`charges.${i}.accountingCodeId`, getAccountingCodeById(charge?.accountingCodeId)?.code],
          [`charges.${i}.startDate`, charge?.startDate],
          [`charges.${i}.endDate`, charge?.endDate],
        ];
      })
    ),
    ...Object.fromEntries(
      settings.unitRentalCustomFields?.sort((a,b) => a.code.localeCompare(b.code)).map(({ code, title }) => {
        const value = rental.customFields?.[code];
        return [`customFields.${code}`, value?.url || value];
      })
    ),
    created: rental.created,
    updated: rental.updated,
    ...rental.owner && _.mapKeys(helpers.user.toCsv(rental.owner), (v, k) => `owner.${k}`),
    ...rental.unit && _.mapKeys(helpers.unit.toCsv(rental.unit), (v, k) => `unit.${k}`),
    ...site && _.mapKeys(helpers.site.toCsv(site), (v, k) => `site.${k}`),
    ...unitType && _.mapKeys(helpers.unitType.toCsv(unitType), (v, k) => `type.${k}`),
  };
}


export async function fromCsv({ _owner_email, _site_code, _unit_name, ...rental }, params) {
  if (_site_code && _site_code !== params.idOrCode) throw new Error(`site "${_site_code}" doesn't match current site "${params.idOrCode}"`);

  if (rental.customFields) {
    rental.customFields = await processCustomFields(rental.customFields, 'unit-rentals');
  }

  const siteId = rental.siteId || (await actions.fetchOneWithCache('sites', _site_code, { altIdKey: 'code' }))?.id;

  const unitId = rental.unitId || _unit_name && (await actions.fetch('units', { search: _unit_name, siteId })).find(o => o.name === _unit_name.toLowerCase())?.id;
  if (!unitId && _unit_name) throw new Error(`unit ${_unit_name} not found`);

  const ownerId = rental.ownerId || _owner_email && (await actions.fetchOneWithCache('users', _owner_email, { altIdKey: 'email' }))?.id;
  if (!ownerId) throw new Error(`owner ${rental.ownerId || _owner_email} not found`);

  return { ...rental, ownerId, unitId };
}

export async function fromCsvUpdate(rental) {
  if (rental.customFields) {
    rental.customFields = await processCustomFields(rental.customFields, 'unit-rentals');
  }
  return _.omit(rental, ['id', 'ownerId', 'startDate', 'endDate', 'depositBalance', 'prepaymentBalance', 'revenueBalance', 'created', 'updated']);
}
