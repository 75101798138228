/* import Section from 'components/section';
import InvoiceLoader from 'components/loaders/invoice';
import Invoice from 'components/invoice'; */
import StepList2 from '../step-list2';
import * as stepComponents from './steps';


export default function UnitMoveOutStepList({
  cms,
  job,
  site,
  unit,
  rental,
  summary, // Optional component to appear above the step list
  fallback = null, // Fallback content to show if no step is active (e.g. job is being set up)
  onChange,
  modal,
}) {
  /* function renderSummary() {
    const { refundInvoiceId, revenueInvoiceId } = job.result;
    
    if (!refundInvoiceId && !revenueInvoiceId) return null;

    const sectionCms = cms.block('summary');

    return (
      <Section cms={sectionCms}>
        {refundInvoiceId && (
          <InvoiceLoader id={refundInvoiceId}>
            {invoice =>
              <>
                <sectionCms.Text id="refundInvoice" />
                <Invoice invoice={invoice} />
              </>
            }
          </InvoiceLoader>
        )}

        {revenueInvoiceId && (
          <InvoiceLoader id={revenueInvoiceId}>
            {invoice => {
              if (invoice.state === 'paid') return null;
              
              return (
                <>
                  <sectionCms.Text id="revenueInvoice" />
                  <Invoice invoice={invoice} />
                </>
              );
            }}
          </InvoiceLoader>
        )}
      </Section>
    )
  } */

  return (
    <StepList2
      cms={cms}
      job={job}
      site={site}
      unit={unit}
      rental={rental}
      summary={summary}
      fallback={fallback}
      stepComponents={stepComponents}
      onChange={onChange}
      modal={modal}
    />
  );
}
