export default function ClearIndicator({
  children = <i className="fas fa-times" />,
  getStyles,
  innerProps,
  onSelect,
  ...props
}) {
  return (
    <div
      {...innerProps}
      onMouseDown={e => { e.stopPropagation() }}
      onClick={() => onSelect(null)}
      style={getStyles('clearIndicator', { getStyles, ...props })}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
}