import wurd from 'wurd-react';
import * as actions from 'actions';
import * as helpers from 'helpers';
import SectionCrudList from 'components/section_crud-list';
import SiteLateFeeForm from 'components/form2/site/late-fee-form';
import LangText from 'components/lang-text';
import { WurdMarkdown } from 'components/markdown';


function Item({ item, ...props }) {
  const amount = item.type === 'pct' ? `${item.amount}%` : helpers.ui.currency(item.amount);

  return (
    <div {...props} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '0 1rem', alignItems: 'start' }}>
      <h4 style={{ margin: 0, justifySelf: 'start' }}><LangText obj={item.desc} /></h4>
      <WurdMarkdown id="siteView.lateFees.item.days" vars={{ days: `${item.days}` }} inline style={{ justifySelf: 'end' }} />
      <div style={{ justifySelf: 'end', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
        <WurdMarkdown id="siteView.lateFees.item.amount" vars={{ amount }} inline style={{ justifySelf: 'end' }} />
        {item.threshold > 0 && <WurdMarkdown id="siteView.lateFees.item.thresold" vars={{ threshold: helpers.ui.currency(item.threshold) }} inline />}
      </div>
    </div>
  );
}

export default function SiteLateFeeSettings({ site, fetchSite, ...rest }) {
  return (
    <SectionCrudList
      cms={wurd.block('siteView.lateFees')}
      items={site.lateFees}
      Form={SiteLateFeeForm}
      actions={actions.sites.lateFees(site.id)}
      onChange={fetchSite}
    >
      {item => <Item item={item} />}
    </SectionCrudList>
  );
}
