import { WurdText } from 'wurd-react';
import { getLangText } from 'helpers/ui';
import { currentLang } from 'utils/language';
import LangText from 'components/lang-text';
import { Editable } from 'components/markdown';
import { Field, MultiLangText, FormObject, ModalForm2 } from 'components/form2';
import Logo from 'components/logo';
import UrlPicker from '../url-picker';
export { edit } from './footer-cols';


export default ({ block, setBlock, modal }) => {
  function editItem(i) {
    modal.open(
      <ModalForm2
        wurdSection="website.page.block_item"
        mode={i !== undefined ? 'update' : 'create'}
        initialValue={block.links?.[i]}
        onSubmit={i !== undefined
          ? async data => setBlock({ links: block.links.map((b, j) => i === j ? { ...b, ...data } : b) })
          : async data => setBlock({ links: [...block.links || [], data] })
        }
        onDelete={i !== undefined && (async () => setBlock({ links: block.links.filter((_, j) => i !== j) }))}
        onSuccess={modal.close}
        closeModal={modal.close}
      >
        {({ fieldProps }) => (
          <>
            <Field {...fieldProps('text')} autoFocus><MultiLangText /></Field>
            <Field {...fieldProps('url')}><UrlPicker /></Field>
          </>
        )}
      </ModalForm2>
    )
  }


  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '2rem', flexWrap: 'wrap', padding: '1rem' }}>
      <div>
        <Logo style={{ maxWidth: '100%', height: 20, objectFit: 'contain', opacity: .75, marginRight: '1rem' }} />

        <Editable inline placeholder="[Text]" style={{ verticalAlign: 'middle' }} onChange={e => setBlock({ text: { [currentLang]: e.target.value } })}>{getLangText(block.text)}</Editable>
      </div>

      <ul className="list-unstyled mb-0" style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
        {block.links?.map((item, i) => item && (
          <li key={i} onClick={e => editItem(i)}>
            <a
              href={item.url}
              onClick={e => e.preventDefault()}
              target="_blank"
              className="btn btn-link btn-sm mb-0"
            >
              <LangText>{item.text}</LangText>
            </a>
          </li>
        ))}
        <li>
          <button className="btn btn-link btn-sm mb-0" onClick={() => editItem()} style={{ outline: '1px dashed var(--bs-primary)', outlineOffset: -1 }}>
            <i className="far fa-plus" /> <WurdText id="website.blocks.addLink" />
          </button>
        </li>
      </ul>
    </div>
  );
}
