import { DropdownButton, MenuItem } from 'react-bootstrap';
import { WurdObject } from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';


export default function UserDetailMenu({
  user,
  onChange,
  wurdKey,
  tasksPlugin,
}) {
  const { t } = helpers.cms.getters(wurdKey);

  async function disable() {
    if (!window.confirm(t('disable_confirm'))) return;

    var reason = window.prompt(t('disable_reason'));
    if (!reason) return;

    await actions.users.disable(user.id, reason);

    onChange();
  }

  async function enable() {
    if (!window.confirm(t('enable_confirm'))) return;

    await actions.users.enable(user.id);

    onChange();
  }

  return (
    <WurdObject id={wurdKey} keys="disable,disable_confirm,disable_reason,enable,enable_confirm">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="userMenu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {user.isDisabled
          ? <MenuItem onSelect={enable}><i className="fas fa-fw fa-user-check" />&nbsp;&nbsp;&nbsp;{t('enable')}</MenuItem>
          : <MenuItem onSelect={disable}><i className="fas fa-fw fa-user-times" />&nbsp;&nbsp;&nbsp;{t('disable')}</MenuItem>
        }

        <tasksPlugin.AddTaskMenuItem />
      </DropdownButton>
    </WurdObject>
  );
};
