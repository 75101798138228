import wurd from 'wurd-react';

import { useModal, useItemLoader } from '../../hooks';
import * as helpers from '../../helpers';

import DetailPage from '../../components/detail-page';
import Alert from '../../components/alert';
import SidebarSummary from '../../components/jobs/unit_transfer/sidebar-summary';
import StepList from '../../components/jobs/unit_transfer/step-list';

const cms = wurd.block('unitJobs.transfer');


export default function UnitTransferPage({
  site,
  job,
  fetchJob,
}) {
  const modal = useModal();

  const { ownerId } = job;

  const { item: owner } = useItemLoader('users', ownerId, {});

  const sid = helpers.ui.sid(job);

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="admin"
      documentTitle={cms.text('title', { sid })}
      title={<cms.Text id="title" vars={{ sid }} />}
      ancestors={[
        { text: helpers.ui.getLangText(site.title), url: `/sites/${site.code}` },
        { text: 'Jobs', url: `/sites/${site.code}/jobs` },
      ]}
      baseUrl={`/sites/${site.code}/jobs/${sid}`}
      sidebar={(
        <SidebarSummary
          job={job}
          owner={owner}
          modal={modal}
        />
      )}
    >
      {job.error && (
        <Alert type="danger">{job.error}</Alert>
      )}

      <StepList
        site={site}
        job={job}
        owner={owner}
        cms={cms}
        onChange={fetchJob}
        modal={modal}
      />
    </DetailPage>
  );
}
