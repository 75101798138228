import * as actions from 'actions';
import { ui } from 'helpers';
import store from 'store';


export function toCsv(order) {
  return {
    sid: order.sid.toUpperCase(),
    ownerId: order.ownerId,
    startDate: order.startDate,
    siteId: order.siteId,
    unitTypeId: order.unitTypeId,
    state: order.state,
    created: order.created,
    updated: order.updated,
  }
}


export async function downloadAgreement({ job }) {
  const rental = await actions.fetchOne('unit-rentals', job.result.unitRentalId, { include: ['agreementUrl', 'activeJobs'] }).catch(() => null);

  if (rental?.agreementUrl) {
    return window.open(rental.agreementUrl);
  }

  const r = await actions.sites.generateAgreement(job.id);

  const blob = await r.blob();
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
}


export async function previewAgreement({ text, pdf, mode, lang }) {
  try {
    const r = await actions.sites.previewAgreement({ text, pdf, mode, lang });

    const popupOpts = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=${window.outerHeight},left=${window.screenLeft + window.outerWidth - 1100},top=${window.screenTop}`;

    switch (mode) {
      case 'pdf': {
        const blob = await r.blob();
        const blobUrl = URL.createObjectURL(blob);

        return window.open(blobUrl, 'Preview agreement', popupOpts);
      }
      case 'html': {
        const data = await r.json();

        const w = window.open('', 'Preview agreement', popupOpts);
        w.document.write(`<html lang="${lang}">
<head>
<meta charset="utf-8">
<style>${baseCss}</style>
</head>
<body>
${data.result}
</body>
</html>`);
        w.document.close();

        w.onload = () => {
          w.focus();
          w.document.title = 'Agreement HTML preview - press Ctrl+P to preview PDF'
          // w.print();
          // w.close();
        };
        return;
      }
    }
  } catch (err) {
    window.alert(err.message);
  }
}

const baseCss = `
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@400;700&display=swap');

@page {
  size: A4 portrait;
  margin: 2.5cm 2cm 3.05cm 2cm;
}

body {
  font-family: 'Noto Sans HK', sans-serif;
  line-height: 1.375;
  font-size: .875rem;
  margin: 0;
}

@media screen {
  body {
    margin: 2.5cm auto;
    width: 21cm;
  }
}

* { box-sizing: border-box; }
b, strong { font-weight: bold; }
u, ins { text-decoration: underline; cursor: initial; }
s, del { text-decoration: line-through; }
i, em { font-style: italic; }
h1, h2, h3, h4, h5, h6 { font-weight: 600; margin-bottom: 0; margin-top: 1em; }
h1 { font-size: 2.5em; }
h2 { font-size: 2em; }
h3 { font-size: 1.5em; }
h4 { font-size: 1.2em; }
h5 { font-size: 1em; }
h6 { font-size: 1em; }
a { color: blue; text-decoration: underline; line-break: anywhere; }
p { margin-top: 0; margin-bottom: .5rem; }
ul { margin-bottom: 5px; margin-left: 5px; list-style: square; }
table > thead > tr > th:empty { display: none; }
table { margin-bottom: 5px; border-collapse: collapse; width: 100%; font-size: .875rem; break-inside: auto;  }
td:nth-of-type(1) { width: var(--table-first-col, 35%); }
td, th { border: 1px solid #000a; padding: 4px 8px; }
th:empty { display: none; }
th { font-weight: bold; background-color: #EEEEEE; }
tr { break-inside: avoid; break-after: auto; }
thead, tfoot { display: table-row-group; }
img { max-width: 100%; }
`;