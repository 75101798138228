import { DropdownButton } from 'react-bootstrap';


export default function MenuButton({
  cms,
  cmsKeys,
  title = null,
  faIcon = 'fas fa-ellipsis-v',
  children: menuItems,
  bsSize,
  ...props
}) {
  // Exit if no children (or all falsey/empty children) are passed
  if (!menuItems || !menuItems.find(Boolean)) return null;

  return (
    <cms.Object keys={cmsKeys} {...props}>
      <DropdownButton
        title={title || <i className={faIcon} />}
        id={`${cms.id()}_menu`}
        bsStyle="link"
        bsSize={bsSize}
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}
