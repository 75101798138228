import wurd, { WurdObject } from 'wurd-react';

import store from '../../store';
import * as helpers from '../../helpers';

import FilterButton from './button';
import ListFilter from './list';
import AsyncSelect from '../form2/async-select';
import ClearIndicator from './clear-indicator';


const cms = wurd.block('common.filters.site');


export default function SiteFilter({ active, onSelect, ...props }) {
  let title = cms.text('title') || 'Site';

  const site = active && store.get().sites_byId[active];

  if (site) {
    title += `: ${helpers.ui.getLangText(site.title)}`;
  }

  return (
    <WurdObject id={cms.id()} keys="title,placeholder,clear">
      <FilterButton active={active} onSelect={onSelect} title={title} {...props}>
        <div style={{ padding: 10, width: 250 }}>
          <AsyncSelect
            endpoint="sites"
            placeholder={cms.text('placeholder')}
            onChange={e => onSelect(e.target.value)}
            value={active}
            autoFocus
            isClearable
            components={{ ClearIndicator: props => <ClearIndicator {...props} onSelect={onSelect} /> }}
          />
        </div>
      </FilterButton>
    </WurdObject>
  );
}
