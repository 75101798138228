import wurd from 'wurd-react';

import { ModalForm, Field } from '../../form2';


export const apiPermissions = [
  'invoice:read',
  'item:read',
  'job:read',
  'site:read',
  'unit:read',
  'unitRental:read',
  'user:read',
  'user.billing:read',
  'user.contact:read',
  'valetOrder:read',
];


function select(ev) {
  ev.currentTarget.focus();
  ev.currentTarget.setSelectionRange(0, ev.currentTarget.value.length);
  // document.execCommand('copy');
}

export default function ApiKeysForm({ mode, ...props }) {
  return (
    <ModalForm
      wurdSection="business.apiKeys"
      mode={mode}
      {...props}
    >
      {(getProps, formValue) => (
        <>
          {mode === 'update' && <Field type="secret" {...getProps('key')} readOnly onClick={select} />}

          <Field {...getProps('name')} required autoFocus />

          <Field {...getProps('roles')}>
            <select multiple>
              {['driver', 'operations', 'admin', 'manager', 'api'].map(role => (
                <option key={role} value={role}>{wurd.text(`user._roles.${role}`)}</option>
              ))}
            </select>
          </Field>

          {formValue.roles?.includes('api') && (
            <Field {...getProps('permissions')}>
              <select multiple>
                {apiPermissions.map(permission => (
                  <option key={permission} value={permission}>{permission}</option>
                ))}
              </select>
            </Field>
          )}
        </>
      )}
    </ModalForm>
  );
}
