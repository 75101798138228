export default ({ product, productConfig, children }) => (
  <li className="list-group-item clearfix" style={{ fontSize: '1.3em' }}>
    <div className="text-muted pull-right">
      {children}
    </div>

    <img
      className="pull-left visits__itemtype-image" alt="Product" src={productConfig.image} /> {productConfig.title}
  </li>
);
