import { WurdText } from 'wurd-react';

import Modal from '../../modal';
import ErrorMessage from 'components/error-message';


export default function MultipleErrorsModal({
  alertMessage = <WurdText id="valetOrder.multipleErrors.message" />,
  errorsBySid = {},
}) {
  const errors = Object.entries(errorsBySid);
  if (!errors.length) return null;

  return (
    <Modal>
      <ErrorMessage>
        {alertMessage}
      </ErrorMessage>

      <table className="table no-border table-hover no-margin">
        <thead className="no-border">
          <tr>
            <th>Item</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody className="no-border-x no-border-y">
          {errors.map(([sid, message]) => (
            <tr key={sid}>
              <td>{sid.toUpperCase()}</td>
              <td>{message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
}
