import { adminLangs } from '../config';
import wurd from 'wurd-react';


export let currentLang;

export const get = () => currentLang; // deprecated, just use import {currentLang} from 'utils/language';

const longLocales = {
  en: 'en-US',
  he: 'he-IL',
  ja: 'ja-JP',
  ko: 'ko-KR',
  sv: 'sv-SE',
  zh: 'zh-HK',
};

export const flag = (langCode) => {
  const countryCode = langCode.length === 5 ? langCode.slice(3) : longLocales[langCode]?.slice(3) || langCode;
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

/**
 * Detects and sets the current language
 */
export function detectLang() {
  const detectedLang = new URLSearchParams(window.location.search).get('lang') // First get from query string
    || localStorage.getItem('lang') // Then localStorage
    || (navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage); // Fall back to browser setting

  const _baseLang = detectedLang.split('-', 1)[0];
  const baseLang = { nb: 'no' }[_baseLang] || _baseLang; // convert 'nb' to 'no' as we use 'no' in our availableLanguages settings for Norsk

  // Ensure in list of supported languages or check if there is content for a non-region specific language, fallback to default
  return [detectedLang, baseLang].find(l => adminLangs.includes(l)) || adminLangs[0];
}


/**
 * Init UI lang, wurd and moment locale
 * It must be literal strings in import for efficient webpack bundling
 */
export function initLang(lang = detectLang()) {
  currentLang = lang;

  wurd.lang = currentLang;

  switch (currentLang) {
    case 'da': return import('moment/locale/da');
    case 'de': return import('moment/locale/de');
    case 'es': return import('moment/locale/es');
    case 'fr': return import('moment/locale/fr');
    case 'it': return import('moment/locale/it');
    case 'ja': return import('moment/locale/ja');
    case 'ko': return import('moment/locale/ko');
    case 'nl': return import('moment/locale/nl');
    case 'pt': return import('moment/locale/pt');
    case 'zh': return import('moment/locale/zh-cn');
    case 'no': return import('moment/locale/nb');
    case 'sv': return import('moment/locale/sv');
    case 'th': return import('moment/locale/th');
  }  
}
