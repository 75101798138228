import { useState } from 'react';

const defaultParams = {
  limit: 50,
  offset: 0,
};


export default function useSimpleListQuery({ initialParams = {} }) {
  const [params, setParams] = useState({
    ...defaultParams,
    ...initialParams,
  });

  /**
   * Resets all query params
   *
   * @param {Object} newParams
   */
  function setQuery(newParams) {
    setParams(newParams);
  }

  /**
   * Updates the given query params, keeping other existing params
   *
   * @param {Object} newParams
   */
  function updateQuery(newParams) {
    setParams({
      ...params,
      ...newParams,
    });
  }

  return {
    params,
    set: setQuery,
    update: updateQuery,
  };
}
