import { useParams } from 'react-router-dom';
import wurd, { WurdText } from 'wurd-react';

import { useModal } from '../hooks';

import * as helpers from '../helpers';
import * as actions from '../actions';

import Page from '../components/page';
import JobLoader from '../components/loaders/job';
import JobDetail from '../components/master-job-list/job-detail';
import BreadcrumbTitle from '../components/breadcrumb-title';
import LabelsList from 'plugins/labels/detail';

const cms = wurd.block('jobView');

export default function JobsPage() {
  const params = useParams();
  const modal = useModal();

  return (
    <JobLoader id={params.id}>
      {(job) => (
        <Page
          documentTitle={cms.text('title', params)}
          title={(
            <BreadcrumbTitle
              title={<cms.Text id="title" vars={params} />}
              ancestors={[
                { text: <WurdText id="jobList.title" />, url: `/jobs` },
              ]}
            />
          )}
          userRole="__superadmin"
          modal={modal}
          extra={(
            <LabelsList
              labels={job.labels}
              settingsLabels={helpers.settings.get().jobLabels}
              onChange={labels => actions.jobs.labels.update(job.id, labels)}
            />
          )}
        >
          <JobDetail {...job} />
        </Page>
      )}
    </JobLoader>
  );
}
