import styled from '@emotion/styled';

export const Section = styled('div')({
  marginTop: '4em',

  '&:first-child': {
    marginTop: 0
  }
});

export const Heading = styled('h4')({
  //borderBottom: '1px solid #ddd',
  //paddingBottom: '.5em',
});


const EditButtonStyle = styled('button')({
  float: 'right',
  marginTop: '-.5em',
});

export const EditButton = props => (
  <EditButtonStyle
    {...props}
    type="button"
    className="btn btn-link"
  />
);
