import moment from 'moment-timezone';
import _ from 'lodash';

import * as areaHelpers from './area';
import * as userHelpers from './user';
import * as productHelpers from './product';
import * as helpers from '.';
import { getLangText } from './ui';
import * as actions from '../actions/general';


/**
 * @return {String[]}
 */
export const getTimeslotsForDate = function (user, date) {
  const market = userHelpers.getMarket(user);

  // Get timeslots for selected date
  if (!date) return [];

  // Day name must be in English here
  const dayName = moment(date).locale('en').format('dddd').toLowerCase();

  const timeslots = market.timeslots[dayName];

  return timeslots;
};


/**
 * Formats a timeslot for display
 *
 * @param {String} timeslot       E.g. '1100-1300'
 * 
 * @return {String}               E.g. '11am to 1pm'
 */
export const formatTimeslot = function (timeslot) {
  if (!timeslot) return null;

  var timeFormat = helpers.settings.get().dateFormats.time;

  var [start, end] = timeslot.split('-');

  function formatTime(timeStr) {
    var hours = timeStr.substr(0, 2);
    var mins = timeStr.substr(2, 4);

    return moment.utc().startOf('day')
      .add(hours, 'hours')
      .add(mins, 'minutes')
      .format(timeFormat);
  }

  return `${formatTime(start)}-${formatTime(end)}`;
};


export const countEmpties = function (valetOrder) {
  if (!valetOrder.boxCounts) return 0;

  return Object.entries(valetOrder.boxCounts).reduce((count, [type, quantity]) => {
    return count + Number(quantity);
  }, 0);
};


/**
 * Returns true if the order has any items to deliver
 *
 * @param {Object}
 *
 * @return {Boolean}
 */
export const isDelivery = function (valetOrder) {
  if (hasStoredItemsToDeliver(valetOrder)) return true;

  if (hasEmpties(valetOrder)) return true;

  if (hasProducts(valetOrder)) return true;
};


export const isCollection = function (valetOrder) {
  if (hasItemsToCollect(valetOrder)) return true;

  if (hasEmpties(valetOrder)) return true;
};


export const formShouldHaveCollectDate = function (valetOrder) {
  //If there are no items to deliver then the main date and timeslot will be used 
  if (!isDelivery(valetOrder)) return false;

  //Next we need to check if there are ALSO items to collect
  if (hasEmpties(valetOrder)) return true;

  if (isCollection(valetOrder)) return true;

  if (hasBulkies(valetOrder)) return true;
};


/**
 * Returns true if the order has empty items to deliver
 *
 * @param {Object} order
 *
 * @return {Boolean}
 */
export const hasEmpties = function (valetOrder) {
  /*if (valetOrder.empties) {
    return !!valetOrder.empties.length;
  }

  //For old order format
  if (valetOrder.orderedItems) {
    return Object.entries(valetOrder.orderedItems).find(([type, quantity]) => {
      return type.substr(0,5) !== 'bulky' && quantity > 0;
    });
  }*/

  if (!valetOrder.boxCounts) return false;

  return !!Object.values(valetOrder.boxCounts).find(quantity => Number(quantity) > 0);
};


export const hasBulkies = function (valetOrder) {
  /*if (order.bulkies) {
    return !!order.bulkies.length;
  }

  //For old order format
  if (order.orderedItems) {
    return Object.entries(order.orderedItems).find(([type, quantity]) => {
      return type.substr(0,5) === 'bulky' && quantity > 0;
    });
  }*/

  if (!valetOrder.bulkyCounts) return false;

  return !!Object.values(valetOrder.bulkyCounts).find(quantity => Number(quantity) > 0);
};


export const hasProducts = function (valetOrder) {
  /*if (order.products) {
    return !!order.products.length;
  }

  //For old order format
  if (order.storeItems) {
    return !!order.storeItems.length;
  }*/

  if (!valetOrder.productCounts) return false;

  return !!Object.values(valetOrder.productCounts).find(quantity => Number(quantity) > 0);
};


export const hasStoredItemsToDeliver = function (valetOrder) {
  return !!valetOrder.deliverItems && valetOrder.deliverItems.length;
};


export const hasItemsToCollect = function (valetOrder) {
  return !!valetOrder.collectItems && valetOrder.collectItems.length;
};


/**
 * Returns an array of objects containing type,date,timeslot for showing all relevant dates for an order
 */
export const getAppointments = function (valetOrder) {
  const {
 date, timeslot, collectDate, collectTimeslot, nextDate, nextTimeslot 
} = valetOrder;
  const isDel = isDelivery(valetOrder);
  const isCol = isCollection(valetOrder);

  if (isDel && !isCol) {
    return [{ type: 'deliver', title: 'Deliver', date, timeslot, isNext: true }];
  }
  else if (isCol && !isDel) {
    return [{ type: 'collect', title: 'Collect', date, timeslot, isNext: true }];
  }
  else if (isDel && isCol) {
    if (collectDate === date && collectTimeslot === timeslot) { //Collect immediately
      return [
        { type: 'deliver', title: 'Deliver', date, timeslot, isNext: (date === nextDate && timeslot === nextTimeslot) },
        {
 type: 'collect', title: 'Collect', text: 'Immediate collection', date: collectDate, timeslot: collectTimeslot, isNext: (collectDate === nextDate && collectTimeslot === nextTimeslot) 
}
      ];
    } else { //Collect later
      return [
        { type: 'deliver', title: 'Deliver', date, timeslot, isNext: (date === nextDate && timeslot === nextTimeslot) },
        { type: 'collect', title: 'Collect', date: collectDate, timeslot: collectTimeslot, isNext: (collectDate === nextDate && collectTimeslot === nextTimeslot) }
      ];
    }
  }
  else {
    return [{ date, timeslot }];
  }
};


export function toCsv(valetOrder) {
  const settings = helpers.settings.get();
  const area = areaHelpers.get(valetOrder.area, true);

  return {
    sid: valetOrder.sid?.toUpperCase(),
    date: valetOrder.date,
    timeslot: valetOrder.timeslot,
    collectDate: valetOrder.collectDate || '',
    collectTimeslot: valetOrder.collectTimeslot || '',
    ownerId: valetOrder.ownerId,
    ..._.mapKeys(userHelpers.toCsv(valetOrder.owner || {}), (v, k) => `owner.${k}`),
    address: valetOrder.address,
    area: valetOrder.area,
    _areaTitle: area.title,
    instructions: valetOrder.instructions,
    phone: valetOrder.phone,
    altPhone: valetOrder.altPhone,
    _emptyCount: countEmpties(valetOrder),
    //'Empty boxes picked': formatItems(job.deliverBoxes),
    collectItems: valetOrder.collectItems?.join(','),
    deliverItems: valetOrder.deliverItems?.join(','),
    products: Object.entries(valetOrder.productCounts || {}).map(([id, qty]) => `${productHelpers.get(id)?.slug}:${qty}`).join(','),
    driverName: valetOrder.driverName,
    driverId: valetOrder.driverId,
    state: valetOrder.state,
    promoCode: valetOrder.promoCode,
    //'Step': job.step,
    // charges?  { desc, price, quantity, date }
    labels: valetOrder.labels?.join(','),
    ...Object.fromEntries(
      settings.valetOrderCustomFields?.sort((a,b) => a.code.localeCompare(b.code)).map(({ code, title }) => {
        const value = valetOrder.customFields?.[code];
        return [`customFields.${code}`, value?.url || value];
      })
    ),
    created: valetOrder.created,
    updated: valetOrder.updated,
  };
}


export async function fetchDetailed(params) {
  const orders = await actions.fetch('valet-orders', params, { skipCache: true });

  const users = await actions.fetch('users', { ids: orders.map(order => order.ownerId).filter(Boolean), include: 'billing,customFields' }).then(users => new Map(users.map(o => [o.id, o])));

  const itemIds = orders.flatMap(order => [...order.collectItems, ...order.deliverItems]);
  const itemSids = await actions.fetch('items', { ids: itemIds, include: 'customFields' })
    .then(items => new Map(items.map(item => [item.id, item.sid.toUpperCase()])));

  return orders.map(order => {
    const owner = users.get(order.ownerId);

    return {
      ...order,
      owner,
      collectItems: (order.collectItems || []).map(id => itemSids.get(id) || `${id} (DELETED)`),
      deliverItems: (order.deliverItems || []).map(id => itemSids.get(id) || `${id} (DELETED)`),
    }
  });
}
