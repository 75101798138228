import { pick } from 'lodash';

import * as helpers from 'helpers';

import ModalForm from 'components/form2/modal-form';
import { Field, Checklist } from 'components/form2';

const fields = [
  'tagIds',
];


export default function UnitTypeFeaturesForm({ unitType, ...rest }) {
  return (
    <ModalForm
      {...rest}
      initialValue={pick(unitType, fields)}
      wurdSection="unitType.features"
    >
      {fieldProps => (
        <>
          <Field {...fieldProps('tagIds')} className={null}>
            <Checklist>
              {helpers.unit.tag.list(true).map(tagConfig => (
                <span key={tagConfig.id} id={tagConfig.id}>
                  <i className={`fa fa-fw ${tagConfig.icon}`} />&nbsp;&nbsp;
                  {tagConfig.title}
                </span>
              ))}
            </Checklist>
          </Field>
        </>
      )}
    </ModalForm>
  );
}
