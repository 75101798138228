import { pick } from 'lodash';
import wurd, { WurdText } from 'wurd-react';

import * as actions from 'actions';

import Section from 'components/section';
import { Field } from 'components/form2';
import { useApiForm } from 'hooks';

const cms = wurd.block('site');

const fields = [
  'moveIn_autoConfirmOrder',
  'moveIn_autoChargeInvoice',
  'moveIn_autoCompleteOrder',
];


export default function SiteAdvancedDetailsSettings({ site, fetchSite, ...rest }) {
  const initialValue = pick(site, fields);

  const { fieldProps, submit, loading, dirty, formValue } = useApiForm({
    wurdSection: cms.id(),
    initialValue,
    onSubmit: formData => actions.sites.update(site.id, formData),
    onSuccess: () => fetchSite(),
  });

  function FlagItem({
    name,
  }) {
    return (
      <Field {...fieldProps(name)} wurdKeys="label,help,off,on">
        <select style={`${formValue[name]}` === 'false' ? { backgroundColor: '#eee' } : null}>
          <option value={false}>{cms.text(`${name}.off`)}</option>
          <option value={true}>{cms.text(`${name}.on`)}</option>
        </select>
      </Field>
    );
  }

  return (
    <Section cms={wurd.block('siteView.automation')}>
      <FlagItem name="moveIn_autoConfirmOrder" />
      <FlagItem name="moveIn_autoChargeInvoice" />
      <FlagItem name="moveIn_autoCompleteOrder" />

      <button
        className="btn btn-primary pull-right"
        type="button"
        disabled={loading || !dirty}
        onClick={submit}
      >
        {loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>
    </Section>
  );
}
