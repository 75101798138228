/**
 * Returns the account balance (result of all entries added together)
 *
 * @param {Object[]} accountEntries
 * 
 * @return {Number} total
 */
export const getBalance = function (accountEntries) {
  const balance = accountEntries.reduce((memo, accountEntry) => {
    return memo + accountEntry.total;
  }, 0);

  return balance;
};
