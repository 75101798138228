import { useSimpleListQuery } from '../../hooks';

import UnitRentalList from '../unit-rental-list';


export default function UserUnits({ user }) {
  const listQuery = useSimpleListQuery({
    initialParams: {
      ownerId: user.id,
      state: null,
      include: 'unit',
    },
  });

  return (
    <UnitRentalList
      listQuery={listQuery}
      hideOwner
    />
  );
}
