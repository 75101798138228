import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import store from '../../store';
import * as actions from '../../actions';

import Spinner from '../spinner';
import CommentForm from './comment-form';
import Item from './item';

const cms = wurd.block('activity');

const LIMIT = 100; // max  number of actions returned, next ones will be fetched when user click "load more"


export default function Activity({
  context,
  endpoint,
  target,
  showCommentBox = !!endpoint,
}) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);

  const appendItems = items => setItems(curItems => [...curItems, ...items]);
  const incrOffset = () => setOffset(curOffset => curOffset + LIMIT);

  useEffect(() => {
    /**
     * Requests visits from the API, applying remote filters as required
     */
    setLoading(true);
    const fetchPromise = target
      ? actions.activity.fetchByTarget(target, { offset, limit: LIMIT })
      : actions.activity.fetch(endpoint, { offset, limit: LIMIT });

    fetchPromise.then(appendItems)
      .finally(() => setLoading(false));
  }, [offset]);

  function addComment(text) {
    actions.activity.createComment(endpoint, text)
      .then((comment) => {
        setItems(curItems => [comment, ...curItems]);
      });
  }

  const { currentUser } = store.get();

  return (
    <section className="block-transparent">
      <div className="header">
        <cms.Text type="h4" id="title" />
      </div>

      {showCommentBox && (
        <CommentForm onSubmit={addComment} />
      )}

      <ul className="timeline">
        {items.map((item) => (
          <Item
            key={item.id}
            currentUser={currentUser}
            context={context}
            item={item}
          />
        ))}
      </ul>

      {loading
        ? <Spinner />
        : items.length === offset + LIMIT
          ? (
            <button type="button" className="btn btn-link" onClick={incrOffset}>
              <i className="fa fa-arrow-circle-down" /> <cms.Text id="loadMore" />
            </button>
          ) : (
            null
          )
      }
    </section>
  );
}

Activity.propTypes = {
  context: PropTypes.string,
  endpoint: PropTypes.string,
  target: PropTypes.string,
  showCommentBox: PropTypes.bool,
};
