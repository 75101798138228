import wurd from 'wurd-react';

import * as actions from 'actions';
import { downloadAgreement } from './helpers';

import LinkButtonGroup, { LinkButton } from 'components/link-button-group';
import InvoiceEntries from 'components/invoice-detail/entries';


const cms = wurd.block('unitOrder.operations');


export default function UnitMoveInOperationsBar({
  job,
  onChange,
  modal,
  buttons = ['viewAgreement', 'cancelJob'],
}) {
  function openPdf() {
    return downloadAgreement({ job });
  }

  async function cancelJob() {
    const confirmed = window.confirm(cms.text('cancelJob.confirm'));
    if (!confirmed) return;
  
    await actions.jobs.runOperation(job.id, 'cancel');

    onChange();
  }

  async function previewInvoice() {
    const invoice = await actions.jobs.runOperation(job.id, 'previewInvoice');
    
    modal.open(
      <InvoiceEntries
        invoice={invoice}
      />
    );
  }

  return (
    <LinkButtonGroup align="center" style={{ margin: '30px 0' }}>
      {job.result.jobVersion >= '2023-04-04' && buttons.includes('previewInvoice') && (
        <cms.Object id="previewInvoice" keys="btn,confirm">
          <LinkButton
            faIcon="far fa-file-invoice-dollar"
            text={cms.text('previewInvoice.btn')}
            onClick={previewInvoice}
          />
        </cms.Object>
      )}

      {buttons.includes('viewAgreement') && (
        <cms.Object id="viewAgreement" keys="btn">
          <LinkButton
            faIcon="far fa-file-contract"
            text={cms.text('viewAgreement.btn')}
            onClick={openPdf}
          />
        </cms.Object>
      )}

      {buttons.includes('cancelJob') && (
        <cms.Object id="cancelJob" keys="btn,confirm">
          <LinkButton
            faIcon="far fa-trash"
            text={cms.text('cancelJob.btn')}
            onClick={cancelJob}
          />
        </cms.Object>
      )}
    </LinkButtonGroup>
  );
}
