import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import wurd from 'wurd-react';

import * as helpers from '../../helpers';

const cms = wurd.block('visitView.items');

const { hasRole } = helpers.auth;


const VisitItems = createClass({

  propTypes: {
    visit: PropTypes.object.isRequired
  },

  render() {
    const { visit } = this.props;

    const nOrderedItems = visit.deliverBoxes.length;
    const nDeliverItems = visit.deliverItems.length;
    const nCollectItems = visit.collectItems.length;

    return (
      <section className="block-flat">
        <div className="header">
          <cms.Text type="h3" id="title" />
        </div>
        <div className="content">
          {!!nOrderedItems &&
            <div>
              <cms.Text type="strong" id="orderedItems" vars={{ count: nOrderedItems }} />
              <ul className="list-unstyled">
                {this.renderItems(visit.deliverBoxes)}
              </ul>
            </div>
          }

          {!!nDeliverItems &&
            <div>
              <cms.Text type="strong" id="deliverItems" vars={{ count: visit.deliverItems.length }} />
              <ul className="list-unstyled">
                {this.renderItems(visit.deliverItems)}
              </ul>
            </div>
          }

          {!!nCollectItems &&
            <div>
              <cms.Text type="strong" id="collectItems" vars={{ count: visit.collectItems.length }} />
              <ul className="list-unstyled">
                {this.renderItems(visit.collectItems)}
              </ul>
            </div>
          }
        </div>
      </section>
    );
  },

  renderItems(items) {
    const titles = helpers.item.titlesByType();

    if (hasRole('admin')) {
      return items.map(item =>
        <li key={item.id}>
          <a href={helpers.ui.href(`/items/${item.sid.toUpperCase()}`)}>{item.sid.toUpperCase()}</a>
          &nbsp;<span className="text-muted">{titles[item.type]}</span>
        </li>
      );
    } else {
      return items.map(item =>
        <li key={item.id}>
          {item.sid.toUpperCase()}
          &nbsp;<span className="text-muted">{titles[item.type]}</span>
        </li>
      );
    }
  }

});

export default VisitItems;
