import Spinner from 'components/spinner';
import MoveOutStepList from 'components/jobs/unit_move-out/step-list';

export default function UnitDetailMoveOut({
  cms,
  site,
  job,
  unit,
  rental,
  onChange,
  modal,
}) {
  return (
    <MoveOutStepList
      cms={cms}
      job={job}
      site={site}
      unit={unit}
      rental={rental}
      onChange={onChange}
      modal={modal}
      fallback={<div><Spinner /> {job.step}</div>}
    />
  );
}
