import * as actions from 'actions';
import * as helpers from 'helpers';

import Section from 'components/section';
import Markdown from 'components/markdown';
import AddonCustomFieldsSection from 'components/addons/custom-fields/section';


export default function AddonDetailOverview({
  cms,
  modal,
  refetch,
  addonConfig,
  addon,
}) {
  async function onSubmitCustomFields(data) {
    try {
      await actions.update('addons', addon.id, data);
    } catch (err) {
      console.log('External validation error', err);
    }
    refetch();
  }

  const info = helpers.ui.getLangText(addonConfig.info);
  const instructions = helpers.ui.getLangText(addonConfig.instructions);

  return (
    <>
      {info && (
        <Section
          cms={cms.block('info')}
          extra={(
            <cms.Text id="pricePerMonth" vars={{ price: helpers.ui.currency(addon.price, { currency: '$' }) }} />
          )}
        >
          <Markdown>{info}</Markdown>
        </Section>
      )}

      {instructions && (
        <Section cms={cms.block('instructions')}>
          <Markdown>{instructions}</Markdown>
        </Section>
      )}

      {addon && (
        <AddonCustomFieldsSection
          cms={cms.block('customFieldsValues')}
          cfConfig={addonConfig.addonCustomFields}
          modelName="addon"
          model={addon}
          onChange={refetch}
          modal={modal}
          showUnsetFields
          onSubmit={onSubmitCustomFields}
        />
      )}
    </>
  );
}
