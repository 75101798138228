import { DropdownButton, MenuItem } from 'react-bootstrap';

import { toCsv, fetchDetailed } from '../../helpers/valet-order';
import * as actions from '../../actions';

import { ModalDialog } from '../modal';
import ExportButton from '../export-button';


export default function ValetOrderListMenu({
  modal,
  cms,
  listQuery,
}) {
  if (!modal) return null;

  function openExportModal() {
    modal.open(
      <ModalDialog cms={cms.block('exportModal')}>
        <ExportButton
          filename="valet-orders.csv"
          fetch={params => fetchDetailed({ ...listQuery.params, ...params, include: 'customFields' })}
          toCsv={toCsv}
          sections={{
            main: '*',
            owner: ['firstName', 'lastName', 'email', 'companyName', '_market', '_plan', ],
          }}
        />
      </ModalDialog>
    );
  }

  return (
    <cms.Object keys="menu.export,helpUrl">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        <MenuItem
          key="export"
          onSelect={openExportModal}
        >
          <i className="fas fa-fw fa-download" /> {cms.text('menu.export')}
        </MenuItem>

        {cms.text('menu.helpUrl') && (
          <MenuItem href={cms.text('menu.helpUrl')} target="_blank">
            <i className="fal fa-fw fa-external-link" /> {cms.wurd.text('common.filters.helpText') || 'Help'}
          </MenuItem>
        )}
      </DropdownButton>
    </cms.Object>
  );
}
