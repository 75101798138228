import { Button } from 'react-bootstrap';
import wurd from 'wurd-react';
import PapaCsv from 'papaparse';

import * as helpers from '../helpers';


const cms = wurd.block('common.exportBtn');

export default function ExportButton2({ items, filename, onComplete = () => {} }) {
  function download(e) {
    const rows = helpers.csv.formatRows(items);

    const csvText = PapaCsv.unparse(rows, { escapeFormulae: true });
    const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    e.currentTarget.href = url;
    setTimeout(() => {
      URL.revokeObjectURL(url);
      onComplete();
    }, 0);
  }

  return (
    <Button
      href="blob:"
      target="_blank"
      download={filename}
      bsStyle="success"
      onClick={download}
    >
      <i className="fas fa-download" /> {cms.text('download')}
    </Button>
  );
}