import wurd from 'wurd-react'

import * as helpers from '../../helpers';

import StateLabel from '../state-label';
import ValetOrderLoader from '../loaders/valet-order';
import UserLoader from '../loaders/user';
import LabelsListCell from 'plugins/labels/list';
import Link from 'components/nested-link';


const cms = wurd.block('itemList');

const { currency, sid } = helpers.ui;
const { stateLabelTypes } = helpers.item;

const styles = {
  imageCol: {
    width: 65,
  },
  image: {
    maxWidth: 65,
    maxHeight: 65,
  },
};


export default function ItemListItem({
  item,
  itemConfigs,
  stateTitles,
  market = null,
  hideOwner,
  hidePrice,
  hideValetOrder,
}) {
  function getMarketItemPrice(itemConfig) {
    const marketItemConfig = market.items.find(item => item.type === itemConfig.type);

    const price = marketItemConfig ? marketItemConfig.price : itemConfig.price;

    return currency(price);
  }

  let itemConfig = itemConfigs[item.type];
  if (!itemConfig) {
    console.error(`itemConfig not found for "${item.type}"`);
    itemConfig = {};
  }

  return (
    <>
      <div role="cell" title={item.type} style={styles.imageCol}>
        <img src={item.image} style={styles.image} alt="" />
      </div>

      <div role="cell">
        <div><strong>{sid(item)}</strong></div>
        {item.opsTitle
          ? <div title={item.type}>{item.opsTitle} <small className="text-muted">{itemConfig.title}</small></div>
          : <div title={item.type}>{itemConfig.title}</div>
        }
      </div>

      {!hideOwner && (
        <div role="cell">
          {item.owner && (
            <UserLoader id={item.owner.id} loading={item.owner.name}>
              {user => user.name}
            </UserLoader>
          )}
        </div>
      )}

      <div role="cell">
        <div>
          <StateLabel type={stateLabelTypes[item.state]}>{stateTitles[item.state]}</StateLabel>
        </div>
        {!hideValetOrder && item.valetOrderId && (
          <ValetOrderLoader id={item.valetOrderId}>
            {(valetOrder) => (
              <div>{cms.text('partOf')} <Link to={`/valet-orders/${sid(valetOrder)}`}>{sid(valetOrder)}</Link></div>
            )}
          </ValetOrderLoader>
        )}
      </div>

      <div role="cell">{item.location}</div>

      {!hidePrice && market && (
        <div role="cell" className="text-right text-muted">{getMarketItemPrice(itemConfig)}</div>
      )}

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={item.labels} />
      </div>
    </>
  );
}
