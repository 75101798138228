import _ from 'lodash';

import * as language from '../utils/language';
import * as settingsHelpers from './settings';
import { getLangText } from './ui';


function doTranslate(itemConfig, lang) {
  const obj = Object.assign({}, itemConfig);

  ['title', 'displayPrice', 'info'].forEach(prop => {
    obj[prop] = getLangText(obj[prop], lang);
  });

  return obj;
}


/**
 * Returns the list of plan types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const list = function (translate) {
  const planConfigs = settingsHelpers.get().plans.sort((a, b) => a.price - b.price);

  if (translate) {
    const lang = language.get();

    return planConfigs.map(planConfig => doTranslate(planConfig, lang));
  } else {
    return planConfigs;
  }
};


/**
 * Returns an object of item types, keyed by type
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object}
 */
export const byId = function (translate) {
  return _.keyBy(list(translate), 'id');
};


/**
 * Returns titles for item types, keyed by type and in the user's language
 *
 * @return {Object}
 */
export const titlesById = function () {
  const lang = language.get();

  return list().reduce((memo, plan) => {
    memo[plan.id] = getLangText(plan.title, lang);
    return memo;
  }, {});
};


/**
 * Returns a specific plan type
 *
 * @param {String} id
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const get = function (id, translate) {
  const planConfig = _.find(list(), { id: id });

  if (!planConfig) {
    const err = new Error('planConfig not found for ' + id);
    //_errs.push(err);
    console.error(err);
    return {};
  }

  if (translate) {
    return doTranslate(planConfig, language.get());
  } else {
    return planConfig;
  }
};


/**
 * Returns whether the Plans functionality is enabled for the operator
 *
 * @return {Boolean}
 */
export const isEnabled = function () {
  return !!list().length;
};


/**
 * Gets the title of a plan given it's ID
 *
 * @param {String} planId
 */
export const getTitle = function (planId) {
  const plan = get(planId, true) || {};

  return plan.title;
};
