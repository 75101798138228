import wurd from 'wurd-react';

import JobStep from '../../jobs/job-step2';
import LargeButtonGroup from '../../large-button-group';
import CompleteStepButton from '../shared/complete-step-button';
import Summary from '../shared/summary';

const cms = wurd.block('valetOrder.steps.order_start');


export default function Step_items_order({
  valetOrder,
  step,
  ...props
}) {
  function renderActions() {
    return (
      <div>
        <Summary valetOrder={valetOrder} {...props} />

        <cms.Object keys="completeBtn">
          <LargeButtonGroup>
            <CompleteStepButton
              valetOrder={valetOrder}
              step={step}
              cms={cms}
              hasProcessedAll
            />
          </LargeButtonGroup>
        </cms.Object>
      </div>
    );
  }

  function renderResults() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" />}
      />
    );
  }

  return (step.state === 'completed')
    ? renderResults()
    : renderActions();
}
