import wurd from 'wurd-react';

import { useSimpleListQuery, useListLoader } from 'hooks';

import Section from 'components/section';
import UnitJobList from 'components/unit-job-list';

const cms = wurd.block('userView.jobs');


export default function UserJobList({ user }) {
  const listQuery = useSimpleListQuery({
    initialParams: {
      state: 'active',
      ownerId: user.id,
    },
  });

  const { items: jobs } = useListLoader('jobs', listQuery.params, { maxAge: 0 });

  if (!jobs.length) return null;

  return (
    <Section
      cms={cms}
      noPadding
    >
      <UnitJobList
        listQuery={listQuery}
        hideOwner
        hideFilters
      />
    </Section>
  );
}
