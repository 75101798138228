import store from '../store';

/**
 * Returns whether the current user has a give role, or a role above it.
 * For example, admins are considered to have both driver and operations roles as well.
 */
export const hasRole = function (role, user = store.get('currentUser')) {
  if (!user) return false;

  const allRoles = getAllRoles(user);

  return allRoles.includes(role);
};


export const hasPermission = function (permission, user = store.get('currentUser')) {
  if (!user) return false;

  return user.permissions?.includes(permission);
};


/**
 * Returns whether the current user is a CS/support user.
 * The user must have a storeganise email (e.g. john@storeganise.com), and the email must be confirmed.
 * The email must not contain a '+' sign, as it is used for email aliases.
 * @returns {Boolean}
 */
export function hasSupportEmail(user) {
  if (!user) return false;

  return (
    Boolean(user.emailConfirmed) 
    && user.email.endsWith('@storeganise.com')
    && !user.email.includes('+')
  );
}


export const getAllRoles = function (user) {
  if (!user || !user.roles) return [];

  const allRoles = user.roles.slice();

  // Add lower roles for higher roles
  if (allRoles.includes('__superadmin')) {
    allRoles.push('driver', 'operations', 'admin', 'manager', '_support');
  } else if (allRoles.includes('manager')) {
    allRoles.push('driver', 'operations', 'admin');
  }
  else if (allRoles.includes('admin')) {
    allRoles.push('driver', 'operations');
  }
  else if (allRoles.includes('operations')) {
    allRoles.push('driver');
  }

  // Add support role if user as a CS email and is at least admin
  if (allRoles.includes('admin') && hasSupportEmail(user)) {
    allRoles.push('_support');
  }

  return allRoles;
};


/**
 * Returns whether the current user explicitly has the given role.
 * For a user with 'admin' role, this method will return false for having the 'driver' role
 */
export const hasRoleStrict = function (role) {
  const user = store.get('currentUser');
  if (!user) return false;

  return user.roles.includes(role);
};

export const getCurrentUser = () => store.get('currentUser');
