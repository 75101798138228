import { useNavigate } from 'react-router-dom';

import * as actions from 'actions';
import * as helpers from 'helpers';

import Alert from 'components/alert';
import ApiButton from 'components/api-button';
import AddonConfigCard from './card';
import CreateAddonModalForm from './forms/create';


export default function AddonConfigList({
  modal,
  cms,
  refetch,
  addonConfigs,
}) {
  cms = cms.block('developerList');

  const navigate = useNavigate();

  function createAddon() {
    modal.open(
      <CreateAddonModalForm
        onSubmit={(data) => actions.create('addon-configs', data)}
        onSuccess={(addonConfig) => {
          refetch();
          modal.close();
          navigate(`/addon-configs/${addonConfig.id}`);
        }}
        closeModal={modal.close}
        mode="create"
      />
    );
  }


  return (
    <>
      <Alert
        type="info"
      >
        <cms.Markdown id="info.text" />
        {helpers.auth.hasRole('__superadmin') && (
          <ApiButton
            onClick={createAddon}
            style={{ marginTop: '2rem' }}
        >
          <cms.Text id="createBtn.text" />
        </ApiButton>
        )}
        
      </Alert>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2.5rem' }}>
        {addonConfigs.map(addonConfig => (
          <AddonConfigCard
            key={addonConfig.id}
            addonConfig={addonConfig}
          />
        ))}
      </div>
    </>
  );
}