import wurd, { WurdText, WurdObject } from 'wurd-react';

import * as actions from 'actions';

import Section from 'components/section';
import Alert from 'components/alert';
import { Field } from 'components/form2';
import { useApiForm } from 'hooks';
import Modal from 'components/modal';
import SecuritySystemModal from 'plugins/security-systems/modal';


const authDataFields = {
  bearbox: ['username', 'password', 'siteID'],
  pti: ['clientID', 'apiKey', 'siteKey', 'siteCode', 'username', 'password'],
  entryfy: ['username', 'password', 'organization', 'device_uuid'],
}

const cms = wurd.block('site.securitySystem');

export default function SiteSecuritySystemSettings({ site, fetchSite, modal, ...rest }) {
  const authDataParts = Array.isArray(site.securitySystem.authData)
    ? site.securitySystem.authData
    : site.securitySystem.authData?.split(':') || []; // fallback to old string format, deprected, TODO remove that code later, authData will always be an array

  const initialValue = {
    ...site.securitySystem,
    ...site.securitySystem.name && {
      [site.securitySystem.name]: Object.fromEntries(
        authDataFields[site.securitySystem.name]?.map((field, i) => [field, authDataParts[i]]) || []
      )
    }
  };

  const { fieldProps, submit, loading, dirty, formValue } = useApiForm({
    wurdSection: cms.id(),
    initialValue,
    onSubmit: data => {
      const securitySystem = !data.name
        ? { name: '', enabled: '', authData: null }
        : {
          name: data.name,
          enabled: data.enabled,
          authData: authDataFields[data.name].map(field => data[data.name]?.[field]),
        };
      return actions.sites.update(site.id, { securitySystem });
    },
    onSuccess: () => fetchSite(),
    onError: window.alert,
    ...rest
  });

  const securitySystemInfo = cms.text(`info.${formValue.name}`) || wurd.editMode;

  function openSecuritySystemModal() {
    const modalCms = cms.block('syncModal');

    modal.open(
      <Modal title={<modalCms.Text id="title" />} closeModal={modal.close}>
        <modalCms.Markdown id="intro" className="text-muted" />

        <div style={{ marginTop: 40 }}>
          <SecuritySystemModal site={site} cms={modalCms} />
        </div>
      </Modal>,
      { bsSize: 'lg', backdrop: 'static' }
    );
  }

  return (
    <Section
      cms={wurd.block('siteView.securitySystem')}
      extra={formValue.enabled && (
        <button type="button" className="btn btn-link" onClick={openSecuritySystemModal}>
          <i className="far fa-fw fa-magic" />
        </button>
      )}
    >
      {formValue.name && securitySystemInfo && (
        <Alert type="info"><cms.Markdown id={`info.${formValue.name}`} /></Alert>
      )}

      <Field {...fieldProps('name')} wurdKeys="label,help,none,entryfy,bearbox,pti">
        <select style={!formValue.name ? { backgroundColor: '#eee' } : null}>
          <option value="">{cms.text('name.none')}</option>
          <option value="entryfy">{cms.text('name.entryfy')}</option>
          <option value="bearbox">{cms.text('name.bearbox')}</option>
          <option value="pti">{cms.text('name.pti')}</option>
        </select>
      </Field>

      {formValue.name && (
        <Field {...fieldProps('enabled')} wurdKeys="label,help,disabled,sync,sync_overlock">
          <select style={!formValue.enabled ? { backgroundColor: '#eee' } : null}>
            <option value="">{cms.text('enabled.disabled')}</option>
            <option value="sync">{cms.text('enabled.sync')}</option>
            <option value="sync,overlock">{cms.text('enabled.sync_overlock')}</option>
          </select>
        </Field>
      )}

      {formValue.name && authDataFields[formValue.name].map(field => (
        <Field
          {...fieldProps(`${formValue.name}.${field}`)}
          key={`${formValue.name}.${field}`}
          type="secret"
        />
      ))}

      <button
        className="btn btn-primary pull-right"
        type="button"
        disabled={loading || !dirty}
        onClick={submit}
      >
        {loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>
    </Section>
  );
}
