/**
 * A filter which filters based on text input
 */

import PropTypes from 'prop-types';
import { useRef } from 'react';
import wurd, { WurdText } from 'wurd-react';
import styled from '@emotion/styled';

import { Button, FormControl, InputGroup, DropdownButton, MenuItem } from 'react-bootstrap';
import LangText from 'components/lang-text';
import FilterButton from './button';


const Form = styled.form({
  padding: 10,

  '.input-group:last-child': {
    marginBottom: 0,
  }
});

export default function TextFilter(props) {
  const { active = '', title, placeholder, onSelect } = props;
  const displayTitle = active ? `${title}: ${active}` : title;

  function submit(e) {
    e.preventDefault();

    onSelect(e.target.closest('form').search.value); // needs to work for both apply button click or enter key on the input
  }

  return (
    <FilterButton {...props} title={displayTitle} className="filter--text" active={!!active}>
      <Form onSubmit={submit}>
        <InputGroup>
          <FormControl
            type="search"
            name="search"
            defaultValue={active}
            placeholder={placeholder}
            autoFocus
          />
          <InputGroup.Button>
            <Button bsStyle="primary" type="submit"><i className="far fa-search" /></Button>
          </InputGroup.Button>
        </InputGroup>
      </Form>
    </FilterButton>
  );
}

TextFilter.propTypes = {
  title: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.any, //The current filter value (null if not set)
};