import { useState } from 'react';
import wurd from 'wurd-react';

import * as actions from '../../actions';
import { isBulky } from '../../helpers/item';

import Modal from '../modal';
import BulkUpdateModalItem from './bulk-update/item';
import ErrorMessage from 'components/error-message';

const cms = wurd.block('itemList.bulkActions.editState.modal');

function checkAllHaveSameOwner(items) {
  if (!items.length) return false;

  const { ownerId } = items[0];
  if (!ownerId) return false;
  if (items.find((item) => item.ownerId !== ownerId)) return false;

  return true;
}


export default function ItemListBulkActions({
  selectedItems,
  onSuccess,
  onError,
}) {
  const [errors, setErrors] = useState([]);

  const itemSids = selectedItems.map((item) => item.sid);
  const allHaveSameOwner = checkAllHaveSameOwner(selectedItems);
  const count = selectedItems.length;

  function validateSetLocation() {
    return null;
  }

  async function setLocation() {
    const location = window.prompt(cms.text('enterLocation', { count }));

    await actions.items.updateState(itemSids, 'setLocation', { location });
    onSuccess();
  }

  function validateUnassign() {
    // Must have an owner and all belong to same owner
    if (!allHaveSameOwner) return 'differentOwners';

    // Bulky items can't be unassigned
    if (selectedItems.find((item) => isBulky(item.type))) return 'hasBulkyItem';

    return null;
  }

  async function unassign() {
    const location = window.prompt(cms.text('enterLocation', { count }));
    if (!location) return;

    await actions.items.updateState(itemSids, 'unassign', {
      location,
    });
    onSuccess();
  }

  function validateSetWithUser() {
    // Must have an owner and all belong to same owner
    if (!allHaveSameOwner) return 'differentOwners';

    // Must all be in storage
    if (selectedItems.find((item) => item.state !== 'storage')) return 'notInStorage';

    return null;
  }

  async function setWithUser() {
    await actions.items.updateState(itemSids, 'setState', {
      state: 'user',
    });
    onSuccess();
  }

  function validateSetInStorage() {
    // Must have an owner and all belong to same owner
    if (!allHaveSameOwner) return 'differentOwners';

    // Must all be with customer
    if (selectedItems.find((item) => item.state !== 'user')) return 'notWithUser';

    return null;
  }

  async function setInStorage() {
    const location = window.prompt(cms.text('enterLocation', { count }));
    if (!location) return;

    await actions.items.updateState(itemSids, 'setState', {
      location,
      state: 'storage',
    });
    onSuccess();
  }

  function validateDeleteBulky() {
    // Must have an owner and all belong to same owner
    if (!allHaveSameOwner) return 'differentOwners';

    // Must be bulky items
    if (selectedItems.find((item) => !isBulky(item.type))) return 'notBulky';

    // Must all be with customer
    if (selectedItems.find((item) => item.state !== 'user')) return 'notWithUser';

    return null;
  }

  async function deleteBulky() {
    const actionErrors = [];

    await Promise.all(selectedItems.map(async (item) => {
      try {
        await actions.del('items', item.id);
      } catch (err) {
        actionErrors.push({ item, message: err.message });
      }
    }));

    if (actionErrors.length) {
      setErrors(actionErrors);
      onError();
    } else {
      onSuccess();
    }
  }

  const setLocationErr = validateSetLocation();
  const unassignErr = validateUnassign();
  const setWithUserErr = validateSetWithUser();
  const setInStorageErr = validateSetInStorage();
  const deleteBulkyErr = validateDeleteBulky();

  const hasUpdateErrors = Boolean(errors.length);

  return (
    <Modal
      title={<cms.Text id="title" vars={{ count }} />}
    >
      {!hasUpdateErrors && (
        <div className="list-group">
          <BulkUpdateModalItem
            cms={cms.block('setLocation')}
            errKey={setLocationErr}
            onClick={setLocation}
            faIcon="fas fa-search-location fa-fw"
          />
          <BulkUpdateModalItem
            cms={cms.block('unassign')}
            errKey={unassignErr}
            onClick={unassign}
            faIcon="fas fa-user-times"
          />
          <BulkUpdateModalItem
            cms={cms.block('setInStorage')}
            errKey={setInStorageErr}
            onClick={setInStorage}
            faIcon="fas fa-building"
          />
          <BulkUpdateModalItem
            cms={cms.block('setWithUser')}
            errKey={setWithUserErr}
            onClick={setWithUser}
            faIcon="fas fa-home"
          />
          <BulkUpdateModalItem
            cms={cms.block('deleteBulky')}
            errKey={deleteBulkyErr}
            onClick={deleteBulky}
            faIcon="fas fa-trash"
          />
        </div>
      )}

      {hasUpdateErrors && (
        <>
          <ErrorMessage>
            <cms.Text id="hadErrors" />
          </ErrorMessage>
          
          <table className="table no-border table-hover no-margin">
            <thead className="no-border">
              <tr>
                <th>Item</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody className="no-border-x no-border-y">
              {errors.map(({ item, message }) => (
                <tr key={item.id}>
                  <td>{item.sid.toUpperCase()}</td>
                  <td>{message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </Modal>
  );
}
