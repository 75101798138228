import * as helpers from '../../../helpers';


export default ({ job }) => {
  return (
    <div>
      Date: {helpers.ui.date(job.data.date)}
    </div>
  );
};
