import { Link } from 'react-router-dom';

import * as helpers from '../../helpers';

import SimpleNavList from '../simple-nav-list'
import Item from './item';

const styles = {
  tr: {
    cursor: 'pointer',
  },
};


export default function StaticInvoiceList({ invoices = [], hideOwner, hideUnit }) {
  const stateTitles = helpers.invoice.getStateTitles();

  return (
    <SimpleNavList>
      {invoices.map((invoice) => (
        <Link
          role="row"
          key={invoice.id}
          to={`/invoices/${invoice.sid.toUpperCase()}`}
          style={styles.tr}
        >
          <Item
            invoice={invoice}
            stateTitles={stateTitles}
            hideOwner={hideOwner}
            hideUnit={hideUnit}
          />
        </Link>
      ))}
    </SimpleNavList>
  );
}
