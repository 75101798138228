import styled from '@emotion/styled';

import * as actions from 'actions';
import * as helpers from 'helpers';

import Section from 'components/section';
import FeaturesForm from './form';
import LangText from 'components/lang-text';


const Tag = styled('span')({
  display: 'inline-block',
  padding: '0.5em .6em .5em',
  marginRight: 10,
  fontWeight: 'normal',
  fontSize: 12,
  marginBottom: 4,
});


export default function UnitTypeFeatures({
  modal,
  cms,
  unitType,
  onChange,
}) {
  const unitTags = helpers.unit.tag.byId();

  const tags = unitType.tagIds.map(tagId => unitTags[tagId]);


  function editFeatures() {
    modal.open(
      <FeaturesForm
        unitType={unitType}
        onSubmit={data => actions.update('unit-types', unitType.id, data)}
        onSuccess={onChange}
        closeModal={modal.close}
        mode="update"
      />,
    );
  }


  return (
    <Section
      cms={cms}
      onClickEdit={editFeatures}
    >
      {tags.length > 0 && tags.map(tag => (
        <Tag key={tag.id} className="label label-info">
          <i className={tag.icon} style={{ marginRight: 6 }} /><LangText obj={tag.title} />
        </Tag>
      ))}
    </Section>
  );
}
