import api from '../utils/api';
import { updateCachedData } from './general';
import errHandler from './_err-handler';


const actions = {};


/**
 * Fetches visits
 * 
 * @param {Object} [params]          Query parameters
 */
actions.fetch = function (params = {}) {
  return api.get('/v1/admin/visits', { params: params })
    .then(res => {
      const visits = res.data;

      return visits;
    })
    .catch(errHandler)
};


/**
 * Fetches a single item
 */
actions.fetchOne = function (id) {
  return api.get(`/v1/admin/visits/${id}?include=deliverBoxes,deliverItems,collectItems,order`)
    .then(res => res.data)
    .catch(errHandler)
}


/**
 * Assigns a visit to a user (e.g. driver)
 *
 * @param {String} visitId
 * @param {String|Null} userId
 */
actions.assignToDriver = function (visitId, userId) {
  const data = {
    assignee: {
      id: userId
    }
  };

  return api.put(`/v1/admin/visits/${visitId}`, data)
    .then(res => {
      const updatedVisit = res.data;

      updateCachedData('visits', updatedVisit);

      return updatedVisit;
    })
    .catch(errHandler)
};


/**
 * Creates a visit/job
 *
 * @param {String} userId
 * @parma {Object} props
 */
actions.create = function (userId, props) {
  return api.post(`/v1/admin/users/${userId}/visits`, props)
    .then(res => {
      const visit = res.data;

      updateCachedData('visits', visit);

      return visit;
    })
    .catch(errHandler)
};


actions.update = function (visitId, data) {
  return api.put(`/v1/admin/visits/${visitId}`, data)
    .then(res => {
      const visit = res.data;

      updateCachedData('visits', visit);

      return visit;
    })
    .catch(errHandler);
};


/**
 * Adds an empty box to a visit
 */
actions.addNewBox = function (visitId, itemId) {
  const data = {
    id: itemId
  };

  return api.post(`/v1/admin/visits/${visitId}/new-boxes`, data)
    .then(res => res.data)
    .catch(errHandler);
};

/**
 * Removes an empty box from a visit
 */
actions.removeNewBox = function (visitId, itemId) {
  return api.delete(`/v1/admin/visits/${visitId}/new-boxes/${itemId}`)
    .then(res => res.data)
    .catch(errHandler);
};

actions.addProduct = function (visitId, productId) {
  const data = {
    id: productId
  };

  return api.post(`/v1/admin/visits/${visitId}/store-items`, data)
    .then(res => res.data)
    .catch(errHandler);
};

actions.removeProduct = function (visitId, productId) {
  return api.delete(`/v1/admin/visits/${visitId}/store-items/${productId}`)
    .then(res => res.data)
    .catch(errHandler);
};

actions.createCharge = function (visitId, data) {
  return api.post(`/v1/admin/visits/${visitId}/charges`, data)
    .then(res => res.data)
    .catch(errHandler)
};

actions.deleteCharge = function (visitId, chargeId) {
  return api.delete(`/v1/admin/visits/${visitId}/charges/${chargeId}`)
    .then(res => res.data)
    .catch(errHandler)
};


export default actions;
