import { useState } from 'react';
import wurd from 'wurd-react';


export default function Secret({ as: Component = 'input', value, placeholder, style, ...props }) {
  const [show, setShow] = useState(!value);

  const handleEvent = e => {
    setShow(e.type === 'focus');
  };

  const first4 = `${value || ''}`.slice(0, 4);
  const last4 = `${value || ''}`.slice(-4);

  const shouldShow = show || !value;

  return (
    <Component
      {...props}
      type="text"
      onFocus={handleEvent}
      onBlur={handleEvent}
      placeholder={!shouldShow ? (wurd.text('common.input.secret', { first4, last4 }) || `${first4}… [hidden; click to show]`) : placeholder}
      value={!shouldShow ? '' : value}
      style={{ ...style, ...!shouldShow && { backgroundColor: 'rgba(0,0,0,.08)' } }}
    />
  );
}