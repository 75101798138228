/**
 * A wrapper around react-bootstraps' Input, which highlights errors on change and on blur
 */
import createClass from 'create-react-class';
import _ from 'lodash';
import classnames from 'classnames';

import wurd, { WurdObject } from 'wurd-react';
import { FormControl } from 'react-bootstrap';


const FormInput = createClass({

  getDefaultProps() {
    return {
      type: 'text'
    };
  },

  render() {
    //const {type, name, value, label, wurdKey, error, help, children} = this.props;
    const { type, name, value, customLabel, required, wurdId, wurdKeys, error, style, children } = this.props;

    const { label, placeholder, help } = wurd.get(wurdId) || {};
    //console.log(wurdId, wurd.get(wurdId),label,placeholder,help)

    /*let inputProps = Object.assign({
      componentClass: 'input',
      type: type || 'text',
      value: value || '',
      onChange: this.onChange
    }, _.pick(this.props, 'required', 'placeholder', 'name'));*/

    let inputProps = {
      componentClass: 'input',
      type: type || 'text',
      ...type !== 'file' && { value: value || '' },
      onChange: this.onChange,
      name,
      required,
      placeholder,
      style,
    };

    if (['select', 'textarea'].includes(type)) {
      inputProps.componentClass = type;
      inputProps.type = null;
    }

    if (type === 'select') {
      inputProps.multiple = this.props.multiple;
    }

    return (
      <WurdObject
        id={wurdId}
        keys={wurdKeys || "label,placeholder,help"}
        type="div"
        className={classnames('form-group', { 'has-error': error })}
      >
        <label className="control-label col-sm-3">{customLabel || label}</label>
        <div className="col-sm-9">
          <FormControl {...inputProps}>
            {children}
          </FormControl>
          {help && <span className="help-block">{help}</span>}
          {error && <span className="help-block">{error[0].message}</span>}
        </div>
      </WurdObject>
    );
  },

  getErrorMessage() {
    var error = this.props.error;

    //API validation errors
    if (Array.isArray(error)) {
      return error[0].message;
    }

    //Clientside validation errors
    /*else {
      return error;
    }*/
  },

  getValidationState() {
    if (this.props.error) return 'error';
  },

  onChange(event) {
    var target = event.target;
    var value = target.value;

    //Return an array of values for select multiple inputs
    if (target.type === 'select-multiple') {
      value = _.reduce(target.options, (memo, option) => {
        return option.selected ? memo.concat(option.value) : memo;
      }, []);
    }

    if (target.type === 'file') {
      value = target.files;
    }

    this.props.onChange(value);
  }

});

export default FormInput;
