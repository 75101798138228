import wurd from 'wurd-react';

import SidebarUser from '../../sidebar-items/user';
import SidebarDate from '../../sidebar-items/date';
import SidebarUnit from '../../sidebar-items/unit';

const cms = wurd.block('unitJobs.transfer');


export default function UnitTransferJobSidebarSummary({ job, modal, owner }) {
  const { oldUnitId, newUnitId, transferDate } = job.result;

  return (
    <div className="list-group">
      {owner && (
        <SidebarUser user={owner} />
      )}

      <SidebarUnit
        title="Transfer from"
        id={oldUnitId}
      />

      <SidebarUnit
        title="Transfer to"
        id={newUnitId}
      />

      <SidebarDate
        date={transferDate}
        title={<cms.Text id="transferDate" />}
      />
    </div>
  );
}
