import PropTypes from 'prop-types';

import { WurdText } from 'wurd-react';


export default function Pagination({ limit, offset, count, onChange }) {
  function onPrevious() {
    const newOffset = Math.max(0, offset - limit);
    onChange({ offset: newOffset, limit });
    window.scrollTo(0, 0);
  }

  function onNext() {
    const newOffset = Number(offset) + Number(limit);
    onChange({ offset: newOffset, limit });
    window.scrollTo(0, 0);
  }

  function onLimitChange(event) {
    const limit = parseInt(event.target.value, 10);
    onChange({ offset, limit });
    window.scrollTo(0, 0);
  }

  return (
    <div>
      <div className="pull-left">
        <div className="form-inline" style={{ marginTop: 20 }}>
          <select
            className="form-control"
            value={limit}
            onChange={onLimitChange}
          >
            {[25, 50, 100, 500].map(n =>
              <option key={n} value={n}>{n}</option>
            )}
          </select> <WurdText id="common.pager.perPage" />
        </div>
      </div>
      <div className="pull-right">
        <ul className="pagination">
          {(offset > 0) &&
            <li><a style={{ cursor: 'pointer' }} onClick={onPrevious}>&larr; <WurdText id="common.prevBtn" /></a></li>
          }

          {(count >= limit) &&
            <li><a style={{ cursor: 'pointer' }} onClick={onNext}><WurdText id="common.nextBtn" /> &rarr;</a></li>
          }
        </ul>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  limit: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  offset: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
