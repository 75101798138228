import UserCharges from './user-charges';


export default function Charges({ settings }) {
  return (
    <>
      <UserCharges settings={settings} />
    </>
  );
}
