import { WurdText } from 'wurd-react';
import * as actions from '../../actions';
import { hasRole } from '../../helpers/auth';

import Alert from '../alert';
import ApiButton from '../api-button';


export default function JobStepList2({
  cms,
  job,
  summary, // Optional component to appear above the step list
  stepComponents,
  fallback = null, // Fallback content to show if no step is active (e.g. job is being set up)
  onChange,
  ...rest
}) {
  const orderState = job.result.orderState || 'ready';

  const steps = job.steps || [];

  const activeStep = steps.find(step => step.state === 'ready');
  const completedSteps = steps.filter(step => step.state !== 'ready').reverse();

  if (!activeStep && !completedSteps.length) return fallback;

  const ActiveStepComponent = activeStep && stepComponents[activeStep.type];
  const CompletedStepComponents = completedSteps.map(step => stepComponents[step.type]).filter(Boolean);
  const hasError = job.state === 'failed' || job.step && job.error;

  async function retryFailedJob() {
    await actions.jobs.retry(job.id);
    onChange();
  }

  async function skipFailedStep() {
    if (!window.confirm('Are you sure?')) return;

    const step = job.steps.find(step => step.state === 'failed');
    if (!step) return alert('No failed step found');

    await actions.jobs.skipStep(job.id, step.id);
    onChange();
  }

  return (
    <>
      {hasError && (
        <Alert type="danger">
          {hasRole('manager') && (
            <div className="pull-right">
              <ApiButton bsType="danger" onClick={retryFailedJob}>
                <i className="far fa-redo" /> <WurdText id="common.phrases.retry" />
              </ApiButton>
              <ApiButton bsType="danger" onClick={skipFailedStep}>
                <i className="far fa-forward" /> <WurdText id="common.phrases.skip" />
              </ApiButton>
            </div>
          )}

          <div><cms.Text id="failedInfo" /></div>
          <div>{job.step}</div>
          <div>{job.error}</div>
        </Alert>
      )}

      {job.state === 'completed' && orderState !== 'cancelled' && (
        <Alert type="success">
          <cms.Text id="completedInfo" />
        </Alert>
      )}

      {job.state === 'completed' && orderState === 'cancelled' && (
        <Alert type="danger" icon="fas fa-times">
          <cms.Text id="cancelledInfo" />
        </Alert>
      )}

      {summary}

      {ActiveStepComponent && (
        <div id="accordion" className="panel-group accordion accordion-semi">
          <ActiveStepComponent
            cms={cms}
            job={job}
            step={activeStep}
            onChange={onChange}
            {...rest}
          />
        </div>
      )}

      {!ActiveStepComponent && job.state !== 'completed' && !hasError && fallback}

      {CompletedStepComponents.length > 0 && (
        <div className={ActiveStepComponent && 'hidden-xs'}>
          <section className="block-transparent" style={{ marginTop: 40 }}>
            <div className="header">
              <h4><cms.Text id="previousSteps" /></h4>
            </div>

            <ul className="timeline">
              {completedSteps.map((step) => {
                const StepComponent = stepComponents[step.type];
                if (!StepComponent) return null;

                return (
                  <StepComponent
                    key={step.id}
                    cms={cms}
                    job={job}
                    step={step}
                    onChange={onChange}
                    {...rest}
                  />
                );
              })}
            </ul>
          </section>
        </div>
      )}
    </>
  );
}
