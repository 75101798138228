import Avatar from 'react-avatar';


export default ({ user, size = 50, ...rest }) => (
  <Avatar
    email={user.email}
    name={user.name}
    size={size}
    style={{ overflow: 'hidden' }}
    textSizeRatio={2.5}
    //color={Avatar.getRandomColor(user.email)}
    round
    {...rest}
  />
);
