import moment from 'moment-timezone';
import wurd from 'wurd-react';

import crudList from '../../actions/plugins/crud-list';
import * as helpers from '../../helpers';
import * as chargeHelpers from './helpers';
import { getAccountingCodeById } from '../../helpers/business';

import LangText from '../../components/lang-text';
import Section from '../../components/section';
import ChargesMenuButton from '../../components/menu-button_charges';
import ChargeForm from './form';

const { currency, date, getLangText } = helpers.ui;


export default function ChargesPlugin({
  modal,
  cms,
  endpoint,
  charges = [],
  onChange,
}) {
  const actions = crudList(endpoint);

  function addCharge(chargeType = null) {
    const initialValue = {
      type: 'recurringCharge',
      startDate: moment().format('YYYY-MM-DD'),
      qty: 1,
      sourceType: chargeType ? 'preset' : 'custom',
      sourceId: chargeType?.id,
      title: chargeType?.title,
      amount: chargeType?.amount,
      accountingCodeId: chargeType?.accountingCodeId
    };

    modal.open(
      <ChargeForm
        initialValue={initialValue}
        onSubmit={async (charge) => {
          await actions.create(charge);
          onChange();
        }}
        onSuccess={() => {
          modal.close();
          onChange();
        }}
        closeModal={modal.close}
      />,
    );
  }

  function removeCharge(charge) {
    const title = getLangText(charge.title);

    const confirmed = window.confirm(cms.text('confirmDelete', { title }));
    if (!confirmed) return null;

    return actions.delete(charge.id)
      .then(onChange);
  }

  function editCharge(charge) {
    modal.open(
      <ChargeForm
        mode="update"
        initialValue={charge}
        onSubmit={async (data) => {
          await actions.update(charge.id, data);
          onChange();
        }}
        onSuccess={() => {
          modal.close();
          onChange();
        }}
        closeModal={modal.close}
        onDelete={() => removeCharge(charge)}
      />,
    );
  }

  function renderDate(charge) {
    if (!charge.endDate) {
      return (
        <cms.Text id="recurringCharge_ongoing" vars={{ startDate: date(charge.startDate) }} />
      );
    }

    if (charge.startDate && charge.endDate) {
      if (charge.startDate === charge.endDate) {
        return (
          <cms.Text id="simpleCharge" vars={{ date: date(charge.startDate) }} />
        );
      }

      return (
        <cms.Text
          id="recurringCharge_endDate"
          vars={{
            startDate: date(charge.startDate),
            endDate: date(charge.endDate),
          }}
        />
      );
    }

    return null;
  }

  function renderCharges(selectedCharges, mode = 'current') {
    return selectedCharges.map((charge) => {
      const accountingCode = getAccountingCodeById(charge.accountingCodeId);

      return (
        <tr
          key={charge.id}
          style={{
            cursor: 'pointer',
            opacity: mode === 'expired' ? 0.5 : 1,
          }}
          onClick={() => editCharge(charge)}
        >
          <td>
            <strong><LangText obj={charge.title} /></strong>
            {charge.qty > 1 && <small className="text-muted"> x {charge.qty}</small>}
          </td>
          <td className="text-muted">
            {renderDate(charge)}
          </td>
          {accountingCode && (
            <td
              className="hidden-xs"
              style={{ color: '#bbb' }}
              title={wurd.text('common.phrases.accountingCode')}
            >
              <small><i className="fas fa-calculator" /> {accountingCode.desc}</small>
            </td>
          )}
          <td className="text-right" colSpan={!accountingCode ? 2 : null}>{currency(charge.amount * (charge.qty || 1))}</td>
        </tr>
      );
    });
  }

  const currentCharges = chargeHelpers.getCurrent(charges);
  const currentTotal = chargeHelpers.getTotal(charges);
  const expiredCharges = chargeHelpers.getExpired(charges);

  return (
    <Section
      cms={cms}
      extra={(
        <>
          <ChargesMenuButton
            onSelect={addCharge}
          />

          {wurd.editMode && (
            <cms.Object
              keys="confirmDelete"
              title="Edit off-page CMS content"
            >
              <button
                type="button"
                className="btn btn-link"
                disabled
              >
                <i className="fas fa-font" />
              </button>
            </cms.Object>
          )}
        </>
      )}
    >
      {charges.length > 0 && (
        <>
          <table className="table no-border table-hover">
            <tbody className="no-border-x no-border-y">
              {renderCharges(currentCharges, 'current')}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="10" className="text-right" style={{ fontSize: 16 }}>
                  <strong>{wurd.text('common.phrases.total')}: {currency(currentTotal)}</strong>
                </td>
              </tr>
            </tfoot>
          </table>

          {expiredCharges.length > 0 && (
            <table className="table no-border table-hover">
              <thead>
                <tr><th colSpan="10"><cms.Text id="expired" /></th></tr>
              </thead>
              <tbody className="no-border-x no-border-y">
                {renderCharges(expiredCharges, 'expired')}
              </tbody>
            </table>
          )}
        </>
      )}
    </Section>
  );
}
