import { useState } from 'react';
import _ from 'lodash';
import wurd from 'wurd-react';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';
import useSelector from '../hooks/selector';
import { useListLoader } from '../../../hooks';

import NoResults from '../../no-results';
import JobStep from '../../jobs/job-step2';
import LargeButtonGroup from '../../large-button-group';
import LinkButtonGroup, { LinkButton } from '../../link-button-group';
import Tabs from '../shared/tabs';
import ResultsItemList from '../shared/results-item-list';
import Item2List from '../shared/item2-list';
import OwnerLocation from '../shared/owner-location';
import ScanReceiver from '../shared/scan-receiver';
import SelectionButton from '../shared/selection-button';
import ScanItemsButton from '../shared/scan-items-button';
import CompleteStepButton from '../shared/complete-step-button';
import ItemDetailsForm from '../shared/item-details-form';

const cms = wurd.block('valetOrder.steps.items_deliver');


export default function Step_items_deliver(props) {
  const {
    valetOrder,
    step,
    items,
    updateItems,
    modal,
    fetchValetOrder,
  } = props;

  function itemHasBeenDelivered(item) {
    return (
      item.state === 'user'
      && item.valetOrderId === valetOrder.id
    );
  }

  const unpickedItemIds = valetOrder.deliverItems.filter((deliverItemId) => !items.some(({ id }) => id === deliverItemId));

  const { items: unpickedItems } = useListLoader('items', unpickedItemIds.length > 0 && {
    ids: unpickedItemIds.join(','),
  });

  const allItems = _.uniqBy(items.concat(unpickedItems), 'id');
  const hasDeliveredAll = allItems.every(itemHasBeenDelivered);

  const deliveredItems = allItems.filter(itemHasBeenDelivered);
  const undeliveredItems = allItems.filter(item => !itemHasBeenDelivered(item));

  const selector = useSelector();

  const [updateErrs, setUpdateErrs] = useState(null);

  const [activeTab, setActiveTab] = useState('todo');
  const [lastAction, setLastAction] = useState();

  function onTabChange(tabId) {
    selector.clearSelection();

    setActiveTab(tabId);
  }

  async function deliverItems(itemSids, itemData = {}) {
    const { errors, updatedSids } = await updateItems('valetOrder_deliver', itemSids, itemData);

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Delivered ${updatedSids.length} items`,
        bsType: 'success',
        undo: () => undeliverItems(updatedSids, itemData),
      });
    }

    setLastAction('deliver');
    selector.clearSelection();
    modal.close();
  }

  async function undeliverItems(itemSids, itemData = {}) {
    const { errors, updatedSids } = await updateItems('valetOrder_undeliver', itemSids, itemData);

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Undelivered ${updatedSids.length} items`,
        undo: () => deliverItems(updatedSids, itemData),
      });
    }

    setLastAction('undeliver');
    selector.clearSelection();
    modal.close();
  }

  function onClickItem(item) {
    modal.open(
      <ItemDetailsForm
        itemId={item.id}
        initialValue={item}
        modal={modal}
        fields={['state']}
        stateOptions={[
          ['transit-user', cms.text('todoTab')],
          ['user', wurd.text('item._states.user')],
        ]}
        onSubmit={async ({ state, ...itemData }) => {
          const fn = (state === 'user') ? deliverItems : undeliverItems;
          fn([item.sid], itemData);
        }}
      />,
    );
  }

  async function markFailed() {
    const failureReason = helpers.ui.prompt(cms.text('failedReason'));
    if (!failureReason) return;

    await actions.valetOrders.updateStep(valetOrder.id, step.id, {
      state: 'failed',
      error: failureReason,
    });
  }

  function renderResults() {
    const deliveredItemIds = step.result.delivered || [];
    const numItems = deliveredItemIds.length;

    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" vars={{ numItems }} />}
      >
        <h4>Delivered items</h4>
        <ResultsItemList itemIds={deliveredItemIds} />
      </JobStep>
    );
  }

  function renderSelectionButtons() {
    return (
      <cms.Object keys="deliverBtn,undeliverBtn">
        {activeTab === 'todo' && (
          <SelectionButton
            bsType="success"
            faIcon="fas fa-check-circle"
            text={cms.text('deliverBtn')}
            onClick={() => deliverItems(selector.selectedSids)}
          />
        )}
        {activeTab === 'done' && (
          <SelectionButton
            bsType="danger"
            faIcon="fas fa-times-circle"
            text={cms.text('undeliverBtn')}
            onClick={() => undeliverItems(selector.selectedSids)}
          />
        )}
      </cms.Object>
    );
  }

  function renderActions() {
    return (
      <JobStep
        step={step}
        faIcon="fas fa-truck"
        title={<cms.Text id="requiresInput.title" vars={{ numItems: undeliveredItems.length }} />}
        helpWurdId={cms.id('instructions.helpUrl')}
      >
        <ScanReceiver onScan={deliverItems} />

        <OwnerLocation
          valetOrder={valetOrder}
          step={step}
          modal={modal}
          appointmentType="deliver"
          fetchValetOrder={fetchValetOrder}
        />

        <Tabs
          onChange={onTabChange}
          items={[
            {
              id: 'todo',
              faIcon: 'fa-tasks',
              title: <cms.Text id="todoTab" />,
              badge: undeliveredItems.length,
              animateBadge: lastAction === 'undeliver',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={undeliveredItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  successState="user"
                  hideLocation
                  fallback={(
                    <NoResults faIcon="fa-check-circle" faColor="success">
                      <cms.Markdown id="todo.noResults" />
                    </NoResults>
                  )}
                  extra={selector.isSelecting && renderSelectionButtons()}
                  onClickItem={onClickItem}
                />
              ),
            },
            {
              id: 'done',
              faIcon: 'fa-check-circle',
              title: <cms.Text id="doneTab" />,
              badge: deliveredItems.length,
              animateBadge: lastAction === 'deliver',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={deliveredItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  successState="user"
                  hideLocation
                  fallback={(
                    <NoResults faIcon="fa-truck">
                      <cms.Markdown id="done.noResults" />
                    </NoResults>
                  )}
                  extra={selector.isSelecting && renderSelectionButtons()}
                  onClickItem={onClickItem}
                />
              ),
            },
          ]}
        />

        <div style={{ marginTop: 20 }}>
          <cms.Object keys="markFailedBtn,failedReason,completeBtn,notFinished_confirm">
            <LargeButtonGroup>
              <ScanItemsButton
                modal={modal}
                receiveScannedSids={deliverItems}
              />
              <CompleteStepButton
                valetOrder={valetOrder}
                step={step}
                cms={cms}
                hasProcessedAll={hasDeliveredAll}
              />
            </LargeButtonGroup>

            {deliveredItems.length === 0 && (
              <LinkButtonGroup align="center" style={{ marginTop: 18 }}>
                <LinkButton
                  faIcon="far fa-times"
                  text={cms.text('markFailedBtn')}
                  onClick={markFailed}
                />
              </LinkButtonGroup>
            )}
          </cms.Object>
        </div>
      </JobStep>
    );
  }


  return (step.state === 'completed') ? renderResults() : renderActions();
}
