import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { useHistoryListQuery } from '../../hooks';
import * as actions from '../../actions';

import ValetOrderList from '../valet-order-list';
import ValetOrderForm from '../form2/valet-order';


export default function UserValetOrders({ modal, user }) {
  const navigate = useNavigate();
  const listQuery = useHistoryListQuery({
    implicitParams: {
      ownerId: user.id,
    },
    initialParams: {},
  });

  function createValetOrder({ refetch }) {
    const valetOrder = {
      address: user.address,
      area: user.area,
      phone: user.phone,
      date: moment().format('YYYY-MM-DD'),
      collectItems: [],
      deliverItems: [],
    };

    modal.open(
      <ValetOrderForm
        user={user}
        initialValue={valetOrder}
        onSubmit={(formData) => actions.valetOrders.create(user.id, formData)}
        onSuccess={async (valetOrder) => {
          // This job pauses execution while it finishes setting up
          // so we check for updates until it is ready to show in the valet-order-detail page
          await actions.pollOneForUpdates('valet-orders', valetOrder.id, {
            include: 'job,customFields',
            until: valetOrder => valetOrder.job?.requiresInput,
          });

          refetch();
          modal.close();

          navigate(`/valet-orders/${valetOrder.sid.toUpperCase()}`)
        }}
        closeModal={modal.close}
        mode="create"
      />,
    );
  }

  return (
    <ValetOrderList
      listQuery={listQuery}
      modal={modal}
      hideOwner
      hideArea
      hideDriver
      onClickAdd={createValetOrder}
    />
  );
}
