import wurd from 'wurd-react';
import { useState } from 'react';

import * as actions from '../../actions';

import Modal from '../modal';
import ErrorMessage from 'components/error-message';

const cms = wurd.block('invoiceList.bulkActions');


export default function InvoiceListBulkActions({ modal, selectedItems, onActionComplete }) {
  const [loading, setLoading] = useState(false);

  async function chargeInvoices() {
    const hasInvalidInvoice = selectedItems.find((invoice) => invoice.state === 'paid');
    if (hasInvalidInvoice) return window.alert(cms.text('charge.reject_hasInvalidInvoice'));

    const confirm = window.confirm(cms.text('charge.confirm', { count: selectedItems.length }));
    if (!confirm) return;

    const errors = [];
    setLoading(true);

    // proceed invoices one at a time, else some payment gateways like GMO can reject too many parallel requests
    for (const invoice of selectedItems) {
      try {
        await actions.invoices.process(invoice.id);
      } catch (err) {
        errors.push({ invoice, message: err.message });
      }
    }

    if (errors.length) {
      modal.open(
        <Modal>
          <ErrorMessage>
            <cms.Text id="charge.hadErrors" />
          </ErrorMessage>

          <table className="table no-border table-hover no-margin">
            <thead className="no-border">
              <tr>
                <th>Owner</th>
                <th>Invoice</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody className="no-border-x no-border-y">
              {errors.map(({ invoice, message }) => (
                <tr key={invoice.sid}>
                  <td>{invoice.owner.name}</td>
                  <td>{invoice.sid.toUpperCase()}</td>
                  <td>{message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>,
      );
    }

    onActionComplete();
  }

  return (
    <button
      type="button"
      className="btn btn-flat btn-primary"
      disabled={!selectedItems.length || loading}
      onClick={chargeInvoices}
    >
      <cms.Text id="charge.btn" />
    </button>
  );
}
