import Link from 'components/nested-link';


export default ({ job }) => {
  const sid = job.data && job.data.order && job.data.order.sid;

  if (!sid) return (
    <em>Missing job.data.order.sid</em>
  );

  return (
    <div>
      <Link to={`/valet-orders/${sid}`}>
        <i className="fa-fw fas fa-truck" /> Valet order {sid}
      </Link>
    </div>
  );
};
