import wurd from 'wurd-react';

import { hasRole } from '../../helpers/auth';

import BulkUpdateModal from './bulk-update-modal';
import DynamicLoader from '../dynamic-loader';
import Spinner from 'components/spinner';


const QrCode = DynamicLoader(() => import('qrcode.react'), <Spinner />);

const cms = wurd.block('itemList.bulkActions');


export default function ItemListBulkActions({ modal, selectedItems, onActionComplete }) {
  const count = selectedItems.length;

  async function openUpdateModal() {
    function onSuccess() {
      modal.close();
      onActionComplete();
    }

    modal.open(
      <BulkUpdateModal
        selectedItems={selectedItems}
        onSuccess={onSuccess}
        onError={onActionComplete}
      />,
    );
  }

  async function showQrCodes() {
    modal.open(
      <div className="row" style={{ background: 'white', padding: 20 }}>
        <input
          type="text"
          style={{ width: '100%' }}
          value={selectedItems.map((item) => item.sid.toUpperCase()).join(',')}
        />

        <hr />

        {selectedItems.map((item) => (
          <div className="col-xs-6 col-sm-4" key={item.sid}>
            <QrCode
              key={item.sid}
              value={item.sid.toUpperCase()}
              size={150}
            />
            <div className="text-center" style={{ marginBottom: 50 }}>
              <div>{item.sid.toUpperCase()} {item.type} {item.state}</div>
              <div>{item.owner?.name}</div>
            </div>
          </div>
        ))}
      </div>,
    );
  }


  return (
    <>
      {hasRole('manager') && (
        <button
          type="button"
          className="btn btn-flat btn-default"
          disabled={!count}
          onClick={showQrCodes}
        >
          <cms.Text id="qrCode.btn" />
        </button>
      )}

      <button
        type="button"
        className="btn btn-flat btn-primary"
        disabled={!count}
        onClick={openUpdateModal}
      >
        <cms.Text id="bulkUpdate.btn" />
      </button>
    </>
  );
}
