import wurd from 'wurd-react';

import { useListLoader } from 'hooks';
import * as helpers from 'helpers';
import * as actions from 'actions';

import TableList from '../table-list';
import Item from './item';
import Filters from './filters';
import Menu from './menu';

import NewUnitTypeForm from './forms/new-unit-type';

const cms = wurd.block('siteView.unitTypes');

function viewUnitType(unitType) {
  return `/unit-types/${unitType.id}`;
}


export default function UnitTypeList({
  listQuery,
  modal,
  hideSite,
  hideFilters,
  getItemLink = viewUnitType,
  site = null,
}) {
  const listLoader = useListLoader('unit-types', listQuery.params, {
    maxAge: 0,
  });

  function create() {
    modal.open(
      <NewUnitTypeForm
        onSubmit={data => actions.create('unit-types', {
          ...data,
          siteId: listQuery.params.siteId,
          code: data.code || helpers.ui.getLangText(data.title)?.toLowerCase().replace(/[^a-z0-9_-]/g, ''),
        })}
        onSuccess={async unitType => {
          modal.close();
          await listLoader.refetch();
        }}
        closeModal={modal.close}
        mode="create"
      />,
    );
  }

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={!hideFilters && (
        <Filters
          listQuery={listQuery}
          hideSite={hideSite}
          site={site}
        />
      )}
      menu={(
        <Menu
          modal={modal}
          cms={cms}
          listQuery={listQuery}
          refetch={listLoader.refetch}
        />
      )}
      renderItem={(unitType) => (
        <Item
          unitType={unitType}
          hideSite={hideSite}
          listQuery={listQuery}
        />
      )}
      getItemLink={getItemLink}
      onClickAdd={listQuery.params.siteId && create}
    />
  );
}
