import { ModalForm, Field, ImageInput, MultiLangText } from '../../form2';


export default function SettingsPlanForm(props) {
  return (
    <ModalForm
      wurdSection="business.plan"
      {...props}
    >
      {getProps => (
        <>
          <Field {...getProps('name')} required autoFocus />

          <Field {...getProps('image')}>
            <ImageInput
              imageFitProps={getProps('imageFit')}
            />
          </Field>

          <Field {...getProps('title')} required>
            <MultiLangText />
          </Field>

          <Field {...getProps('info')}>
            <MultiLangText />
          </Field>

          <Field {...getProps('price')} required type="number" />

          <Field {...getProps('displayPrice')}>
            <MultiLangText />
          </Field>

          <Field {...getProps('order')} type="number" step="1" />
        </>
      )}
    </ModalForm>
  );
}
