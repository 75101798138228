export default function InvoiceJobCreateSummary({ job }) {
  const { date } = job.data;
  const {
    valetInvoiceDate,
    valetInvoicesCount,
    unitInvoiceDate,
    unitInvoiceChargeDate,
    unitInvoicesCount,
  } = job.result;

  return (
    <>
      <div>Job date: {date}</div>
      {valetInvoiceDate && (
        <small>
          {valetInvoicesCount > 0 && (
            <div>Created {valetInvoicesCount} valet invoices for {valetInvoiceDate}</div>
          )}
          {unitInvoicesCount > 0 && (
            <div>Created {unitInvoicesCount} unit invoices for {unitInvoiceDate}; charging on {unitInvoiceChargeDate}</div>
          )}
        </small>
      )}
    </>
  );
};
