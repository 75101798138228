import { pick } from 'lodash';
import wurd, { WurdText } from 'wurd-react';

import * as actions from 'actions';

import Section from 'components/section';
import { Field } from 'components/form2';
import ExcludedDates from 'components/excluded-dates';
import { useApiForm } from 'hooks';

const fields = [
  'bookingCutoffTime',
  'bookingDaysAhead',
  'excludedBookingDates',
];


export default function SiteBookingDetailsSettings({ site, fetchSite, ...rest }) {
  const { fieldProps, submit, loading, dirty, formValue } = useApiForm({
    wurdSection: 'site',
    initialValue: pick(site, fields),
    onSubmit: formData => actions.sites.update(site.id, formData),
    onSuccess: fetchSite,
    onError: window.alert,
    ...rest
  });

  return (
    <Section cms={wurd.block('siteView.booking')}>
      <Field {...fieldProps('bookingCutoffTime')}>
        <select>
          {Array.from({ length: 24 }, (_, i) => {
            const time = new Date(2000, 0, 1, i, 0, 0).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' });

            return <option key={i} value={i}>{time}</option>;
          })}
        </select>
      </Field>

      <Field {...fieldProps('bookingDaysAhead')} type="number" min="0" />

      <div className="form-group form-horizontal">
        <label className="control-label col-sm-3"><WurdText id="site.excludedBookingDates.label" /></label>
        <div className="col-sm-9">
          <ExcludedDates {...fieldProps('excludedBookingDates')} />
          <WurdText id="site.excludedBookingDates.help" className="help-block" />
        </div>
      </div>

      <button
        className="btn btn-primary pull-right"
        type="button"
        disabled={loading || !dirty}
        onClick={submit}
      >
        {loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>
    </Section>
  );
}
