import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import * as helpers from '../../helpers';

import { WurdObject } from 'wurd-react';
import ListFilter from '../filter/list';
import DateFilter from '../filter/date';
import UserFilter from '../filter/user';

const { hasRoleStrict } = helpers.auth;

const { k, t } = helpers.cms.getters('visitList.filters');


export default class VisitListFilters extends Component {

  render() {
    const { query, currentUser, drivers } = this.props;
    const onChange = this.onChange;

    const daterange = (query.start && query.end)
      ? { start: query.start, end: query.end }
      : null;

    const states = ['active', 'complete', 'cancelled', 'failed'];
    const types = ['emptyBoxes', 'delivery', 'collection'];

    return (
      <div style={{ padding: '10px 10px 7px 10px' }}>
        <WurdObject id={k('state')} keys={['title', 'all'].concat(states)}>
          <ListFilter title={t('state.title')} active={query.state} onSelect={onChange.bind(this, 'state')}>
            <li value={null}>{t('state.all')}</li>
            {states.map(state =>
              <li key={state} value={state}>{t(`state.${state}`)}</li>
            )}
          </ListFilter>
        </WurdObject>


        <WurdObject id={k('daterange')} keys={['title']}>
          <DateFilter
            title={t('daterange.title')}
            range={daterange}
            onSelect={onChange.bind(this, 'daterange')}
          />
        </WurdObject>


        <WurdObject id={k('area')} keys={['title', 'all']}>
          <ListFilter title={t('area.title')} active={query.area} onSelect={onChange.bind(this, 'area')}>
            <li value={null}>{t('area.all')}</li>
            {helpers.area.list(true).map(area =>
              <li key={area.id} value={area.id}>{area.title}</li>
            )}
          </ListFilter>
        </WurdObject>


        <WurdObject id={k('type')} keys={['title', 'all'].concat(types)}>
          <ListFilter title={t('type.title')} active={query.type} onSelect={onChange.bind(this, 'type')}>
            <li value={null}>{t('type.all')}</li>
            {types.map(type =>
              <li key={type} value={type}>{t(`type.${type}`)}</li>
            )}
          </ListFilter>
        </WurdObject>


        <WurdObject id={k('assignee')} keys="title,all,me,unassigned">
          <ListFilter title={t('assignee.title')} active={query.assignee} onSelect={onChange.bind(this, 'assignee')}>
            {hasRoleStrict('driver') &&
              <li value={currentUser.id}>{t('assignee.me')}</li>
            }
            <li value={null}>{t('assignee.all')}</li>
            <li value={'unassigned'}>{t('assignee.unassigned')}</li>
            {drivers?.map(user =>
              <li key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</li>
            )}
          </ListFilter>
        </WurdObject>


        <UserFilter active={query.owner} onSelect={onChange.bind(this, 'owner')} />
      </div>
    );
  }

  onChange(key, value) {
    let newParams = {
      offset: 0
    };

    switch (key) {
      case 'daterange':
        newParams.start = value ? moment(value.start).format('YYYY-MM-DD') : null;
        newParams.end = value ? moment(value.end).format('YYYY-MM-DD') : null;
        break;

      default:
        newParams[key] = value;
    }

    this.props.onChange(newParams);
  }

};


VisitListFilters.propTypes = {
  query: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  drivers: PropTypes.array
};
