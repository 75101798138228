import wurd from 'wurd-react';
import styled from '@emotion/styled';

import * as helpers from 'helpers';
import * as actions from 'actions';

import Empty from 'components/empty';
import SiteProductsForm from 'components/form2/site/products-form';
import UnitTypePreview from '../unit-type-preview';
import Task from '../task';


const cms = wurd.block('siteOnboarding.tasks');

const PreviewList = styled.div({
  width: '75%',
  margin: 'auto!important',
  filter: 'opacity(.8)',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});


export default function SiteOnboardingProductsTask({ site, fetchSite, modal }) {
  const nUnits = helpers.site.getOccupancy(site).total;
  const nPositioned = site.positions?.filter(p => p.type === 'unit').length;

  function openProduct(product = {}, mode = 'create') {
    modal.open(
      <SiteProductsForm
        initialValue={product}
        onSubmit={(_, patches) => { // here the first arg is an event when mode=='create' and idk why??
          return mode === 'create'
          ? actions.sites.products(site.id).create(patches)
          : actions.sites.products(site.id).update(product.id, patches);
        }}
        onSuccess={() => { modal.close(); return fetchSite();}}
        closeModal={modal.close}
        mode={mode}
        onDelete={mode === 'update' && (() => {
          if (!helpers.ui.confirm(wurd.text('site.products.confirmDelete') || wurd.text('common.confirmDelete'))) return;

          return actions.sites.products(site.id).delete(product.id)
            .then(() => { modal.close(); return fetchSite();});
        })}
      />,
    );
  }


  return (
    <Task
      title={<cms.Text id="products.title" />}
      isDone={site.products.length > 0}
      open={nUnits > 0 && nPositioned === nUnits && site.products.length === 0}
      action={
        <button className="btn btn-link" onClick={openProduct}>
          <i className="far fa-plus" /> <cms.Text id={`products.action.${site.products.length > 0 ? 'done' : 'todo'}`} />
        </button>
      }
    >
      {site.products.length > 0
        ? (
          <PreviewList>
            {site.products.sort(helpers.ui.sortByCode).map(product => (
              <UnitTypePreview
                key={product.id}
                item={product}
                button={<button className="btn btn-primary" onClick={() => openProduct(product, 'update')}><cms.Text id="products.preview.btn" /></button>}
              />
            ))}
          </PreviewList>
        ) : (
          <Empty
            cms={wurd.block('siteView.products.empty')}
            onClickAdd={openProduct}
          />
        )}
    </Task>
  );
}