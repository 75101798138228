import styled from '@emotion/styled';


const Table = styled('table')({
  tableLayout: 'auto',
  width: '100%',

  '&:not(.compact) tr': {
    cursor: 'pointer',
    height: '4.5em',
  },

  'tr.fade': {
    opacity: 0.5,
  },

  '&:not(.align-top) td': {
    verticalAlign: 'middle !important',
  },

  'td.fitContent': {
    whiteSpace: 'nowrap',
    width: '1%',
  },

  '.secondary': {
    color: '#bbb',
  },

  '.tertiary': {
    fontSize: '.8em',
    color: '#bbb',
  },

  '.selectCheckboxCol': {
    paddingLeft: 10,
    paddingTop: 4,
    width: 20,
  },
});


export default function SimpleTableList({ children, className }) {
  return (
    <Table role="table" className={`table table-list no-border table-hover no-margin ${className || ''}`}>
      <tbody role="rowgroup" className="no-border-x no-border-y">
        {children}
      </tbody>
    </Table>
  );
}
