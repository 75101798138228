import wurd from 'wurd-react';
import styled from '@emotion/styled';

import * as actions from 'actions';
import { currency } from 'helpers/ui';
import { getAccountingCodeById } from 'helpers/business';

import Section from 'components/section';
import ClickableDiv from 'components/clickable-div';
import ChargeForm from './form';
import LangText from 'components/lang-text';


const Charge = styled(ClickableDiv)({
  '.secondary': {
    color: '#bbb',
  },
});


export default function UnitTypeCharges({
  modal,
  cms,
  unitType,
  onChange,
}) {
  const { charges } = unitType;

  async function saveCharges(data) {
    await actions.update('unit-types', unitType.id, {
      charges: data,
    });

    onChange();
  }

  function createCharge() {
    modal.open(
      <ChargeForm
        mode="create"
        closeModal={modal.close}
        onSubmit={(data) => saveCharges([
          ...unitType.charges,
          data,
        ])}
      />,
    );
  }

  function deleteCharge(charge) {
    if (!window.confirm(wurd.text('common.confirmDelete'))) return;

    saveCharges(charges.filter(({ id }) => id && id !== charge.id));
  }

  function editCharge(event) {
    const { currentTarget } = event;
    const selectedCharge = charges.find(charge => charge.id === currentTarget.id);

    modal.open(
      <ChargeForm
        mode="update"
        closeModal={modal.close}
        initialValue={selectedCharge}
        onSubmit={(data) => saveCharges(charges.map(charge => (charge === selectedCharge ? data : charge)))}
        onDelete={() => deleteCharge(selectedCharge)}
      />,
    );
  }


  return (
    <Section
      cms={cms.block('charges')}
      onClickAdd={createCharge}
    >
      {charges.length > 0 && charges.map((charge) => {
        const accountingCode = getAccountingCodeById(charge.accountingCodeId);

        return (
          <Charge
            key={charge.id}
            id={charge.id}
            className="list-group-item clearfix"
            onClick={editCharge}
          >
            <div>
              <LangText obj={charge.title} />
              <div className="pull-right">{currency(charge.amount)}</div>
            </div>
            <div className="text-muted">
              {accountingCode && (
                <span className="secondary" title={wurd.text('business.unitCharge.accountingCodeId.label')}>
                  <i className="far fa-calculator" /> {accountingCode.desc}
                </span>
              )}
              {charge.type === 'recurring' && (
                <div className="pull-right">{charge.type}</div>
              )}
            </div>
          </Charge>
        );
      })}
    </Section>
  );
}
