import wurd, { WurdMarkdown as WurdMarkdown_ } from 'wurd-react';
import { useState, useRef, useEffect } from 'react';
import { marked } from 'marked';
import styled from '@emotion/styled';


marked.setOptions({
  breaks: true,
});



export default function Markdown({ 
  inline,
  as: Component = inline ? 'span' : 'div',
  children: content,
  ...props
}) {
  // Prevent error from markdown if content is undefined or not a string (it could be a React.Node in that case just render it)
  if (typeof content !== 'string') return content || null;

  return (
    <Component
      {...props}
      dangerouslySetInnerHTML={{ __html: inline ? marked.parseInline(content) : marked(content) }}
    />
  )
}


export function WurdMarkdown({ id, vars, inline, as, ...rest }) {
  return wurd.editMode
    ? <WurdMarkdown_ type={as} id={id} vars={vars} inline={inline} {...rest} />
    : <Markdown as={as} inline={inline} {...rest}>{wurd.text(id, vars)}</Markdown>;
}

const Preview = styled.div({
  '&:hover:not([contenteditable])': {
    outline: '#0002 auto 1px',
    outlineOffset: 4,
  },
  '&[contenteditable]': {
    outline: '#0006 auto 1px',
    outlineOffset: 4,
    whiteSpace: 'pre-wrap',
    zIndex: 1,
    position: 'relative'
  },
  '&:is(span)': {
    display: 'inline-block',
    minWidth: '.5em',
  },
  marginLeft: 'auto',
  marginRight: 'auto',
  'img, iframe, video': {
    maxHeight: '10rem',
    width: '100%',
    objectFit: 'contain',
    margin: '.5rem auto 1rem auto',
  },
  h1: { fontSize: 16 },
  h2: { fontSize: 15 },
  h3: { fontSize: 14 },
  'h4,h5,h6': {
    fontSize: 13,
  }
});

export function Editable({
  children: content = '',
  inline,
  as: As = inline ? 'span' : 'div',
  onChange,
  placeholder,
  style,
  ...props
}) {
  const [editMode, setEditMode] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (editMode) {
      ref.current.focus();

      document.addEventListener('keydown', shortcuts);

      return () => document.removeEventListener('keydown', shortcuts);
    }
  }, [editMode]);

  if (content && typeof content !== 'string') return content;

  const startEdit = e => {
    setEditMode(true);
  };

  const Component = Preview.withComponent(As);

  if (editMode) {
    return (
      <Component
        {...props}
        style={style}
        ref={ref}
        tabIndex="0"
        contentEditable
        onBlur={e => {
          setEditMode(false);
          if (e.currentTarget.innerText !== content) onChange({ target: { value: e.currentTarget.innerText } });
        }}
        onPaste={e => {
          e.preventDefault();
          document.execCommand('insertText', false, e.clipboardData.getData('Text'));
        }}
      >
        {content}
      </Component>
    );
    // return (
    //   <Textarea
    //     {...props}
    //     style={{
    //       ...style,
    //       width: !inline ? '100%' : null,
    //       textAlign: styles.textAlign,
    //       height: styles.height * 1.01,
    //     }}
    //     ref={ref}
    //     defaultValue={content}
    //     onBlur={e => {
    //       setEditMode(false);
    //       setStyles({});
    //       if (e.currentTarget.value !== content) onChange({ target: { value: e.currentTarget.value } });
    //     }}
    //   />
    // );
  }

  return (
    <Component
      {...props}
      style={style}
      tabIndex="0"
      onClick={startEdit}
      onFocus={startEdit}
      dangerouslySetInnerHTML={{
        __html: !content ? `<span style="filter: opacity(.5)">${placeholder}</span>` : inline ? marked.parseInline(content) : marked(content)
      }}
    />
  );
}


// to be improved
function shortcuts(e) {
  if (e.ctrlKey && e.key === 'b') return document.execCommand('insertText', false, `**${getSelection().getRangeAt(0)}**`);
  if (e.ctrlKey && e.key === 'i') return document.execCommand('insertText', false, `*${getSelection().getRangeAt(0)}*`);
  if (e.ctrlKey && e.key === 'u') return document.execCommand('insertText', false, `[${getSelection().getRangeAt(0)}]()`);
}