import { useRef, useState } from 'react';
import wurd, { WurdText } from 'wurd-react';
import { Button, Dropdown, MenuItem } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { previewAgreement } from 'components/jobs/unit_move-in/helpers';
import * as helpers from 'helpers';

import Tabs from 'components/tabs';
import DynamicLoader from 'components/dynamic-loader';
import { useVars, getPlaceholder } from './variables';

const CodeEditor = DynamicLoader(() => import('components/code-editor'));

const cms = wurd.block('settings.moveInAgreement');


export default function EsignHTMLEditor({ value, onChange, reset, resetText = <WurdText id="settings.emails.reset" /> }) {
  const langKeys = helpers.settings.langs();
  const editorRef = useRef();
  const previewMutation = useMutation(previewAgreement);

  const vars = useVars();

  return (
    <>
      <div className="form-group">
        <div className="col-sm-12" style={{ float: 'none' }}>
          <Tabs
            items={langKeys.map((lang) => ({
              id: lang,
              title: <WurdText id={`common.languages.${lang}`} />,
              renderContent: () => {
                return (
                  <CodeEditor
                    key={lang}
                    value={value.moveInAgreementTemplate?.[lang] || ''}
                    onChange={e => onChange({ moveInAgreementTemplate: { ...value.moveInAgreementTemplate, [lang]: e.target.value } })}
                    editorRef={editorRef}
                  />
                )
              }
            }))}
            renderExtra={lang => {
              function insertPlaceholder(name) {
                const placeholder = getPlaceholder(name);
                editorRef?.current.doc.replaceSelection(placeholder);
                editorRef?.current.focus();
              }

              return (
                <div style={{ position: 'absolute', right: 10, top: 5, zIndex: 400, display: 'flex' }}>
                  <Button bsSize="sm" style={{ marginLeft: 4 }} onClick={() => reset(lang)}>{resetText}</Button>
                  <Dropdown id="html-preview-type" disabled={previewMutation.isLoading} bsSize="sm" style={{ marginRight: 0 }}>
                    <Dropdown.Toggle id="html-preview-type-value" noCaret><WurdText id="settings.emails.preview" /></Dropdown.Toggle>
                    <Dropdown.Menu>
                      {['html', 'pdf'].map(mode => (
                        <MenuItem
                          key={mode}
                          onClick={e => previewMutation.mutate({
                            text: value.moveInAgreementTemplate?.[lang] || '',
                            mode,
                            lang,
                          })}
                        >
                          {mode.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <cms.Object keys={['insertVar', 'siteFields', 'userFields', 'rentalFields']}>
                    <Dropdown id="move-in-agreement-template-placeholder-dropdown" bsSize="sm" pullRight>
                      <Dropdown.Toggle><cms.Text id="insertVar" /></Dropdown.Toggle>
                      <Dropdown.Menu style={{ maxHeight: 480, overflowY: 'auto' }}>
                        <MenuItem header>{cms.text('siteFields')}</MenuItem>
                        {vars.site.map(name => <MenuItem key={name} onClick={() => insertPlaceholder(name)}>{name}</MenuItem>)}

                        <MenuItem divider />
                        <MenuItem header>{cms.text('userFields')}</MenuItem>
                        {vars.user.map(name => <MenuItem key={name} onClick={() => insertPlaceholder(name)}>{name}</MenuItem>)}

                        <MenuItem divider />
                        <MenuItem header>{cms.text('rentalFields')}</MenuItem>
                        {vars.rental.map(name => <MenuItem key={name} onClick={() => insertPlaceholder(name)}>{name}</MenuItem>)}
                      </Dropdown.Menu>
                    </Dropdown>
                  </cms.Object>
                </div>
              );
            }}
          />
        </div>
      </div>
    </>
  );
}
