import wurd from 'wurd-react';

import * as helpers from '../../helpers';

import ListFilter from '../filter/list';
import LabelsFilter from 'plugins/labels/filter';
import SearchFilter from '../filter/search';

const cms = wurd.block('userList.filters');


export default function UserListFilters({
  listQuery,
  markets,
}) {
  const { params } = listQuery;

  function onChange(obj) {
    listQuery.update({
      offset: 0,
      ...obj,
    });
  }

  const plans = helpers.plan.list(true);
  const hasMultipleMarkets = markets.length > 1;
  const states = ['enabled', 'active', 'inactive', 'disabled', 'overdue', 'staff'];
  const valetStorage = helpers.settings.get().modes?.includes('valetStorage');

  const setState = (val) => {
    onChange(val === 'staff'
      ? { state: '', roles: '' }
      : { state: val, roles: undefined }
    );
  };
  const setMarket = (val) => onChange({ market: val });
  const setPlan = (val) => onChange({ plan: val });

  return (
    <>
      <cms.Object id="state" keys={['title', 'all'].concat(states)}>
        <ListFilter
          title={cms.text('state.title')}
          active={params.roles !== undefined ? 'staff' : params.state}
          onSelect={setState}
        >
          <li value={null}>{cms.text('state.all')}</li>
          {states.map((state) => (
            <li key={state} value={state}>{cms.text(`state.${state}`)}</li>
          ))}
        </ListFilter>
      </cms.Object>

      {hasMultipleMarkets && (
        <cms.Object id="market" keys="title,all">
          <ListFilter
            title={cms.text('market.title')}
            active={params.market}
            onSelect={setMarket}
          >
            <li value={null}>{cms.text('market.all')}</li>
            {markets.map((market) => (
              <li key={market.id} value={market.id}>{market.title}</li>
            ))}
          </ListFilter>
        </cms.Object>
      )}

      {valetStorage && (
        <cms.Object id="plan" keys="title,all">
          <ListFilter
            title={cms.text('plan.title')}
            active={params.plan}
            onSelect={setPlan}
          >
            <li value={null}>{cms.text('plan.all')}</li>
            {plans.map((plan) => (
              <li key={plan.id} value={plan.id}>{plan.title}</li>
            ))}
          </ListFilter>
        </cms.Object>
      )}

      <LabelsFilter
        active={params.labels}
        onSelect={(val) => onChange({ labels: val })}
        labels={helpers.settings.get().userLabels}
      />

      <SearchFilter
        name="user-search"
        cms={cms.block('search')}
        active={params.search}
        onSelect={(val) => onChange({ search: val })}
        modelName="user"
        searchables={['address', ...valetStorage ? ['area'] : [], 'companyName', 'phone']}
      />
    </>
  );
}
