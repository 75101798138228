import { Component } from 'react';
import PropTypes from 'prop-types';
import { WurdText } from 'wurd-react';
import * as actions from '../../actions';

import Markdown from '../markdown';

import styled from '@emotion/styled';
import Alert from 'components/alert';




const styles = {
  header: {
    fontSize: '1em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginTop: 0,
  },
  header_editing: {
    fontSize: '1em',
    marginTop: 0,
  },
  textarea: {
    backgroundColor: 'rgba(0,0,0,0)',
    height: 200
  }
}


export default class Note extends Component {

  static propTypes = {
    cms: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func
  }

  state = {
    editMode: false
  }

  render() {
    const { editMode } = this.state;

    return (
      <div className="alert alert-warning clearfix"> 
        {editMode ? this.renderEditMode() : this.renderViewMode()}
      </div>
    );
  }

  renderViewMode() {
    const { cms, user } = this.props;

    return (
      <div>
        <button
          type="button"
          className="pull-right btn-link"
          onClick={this.toggleEditMode}
        >
          <i className="fa fa-edit" title="Edit" />
        </button>

        <h3 style={styles.header}><cms.Text id="title" /></h3>

        {user.note
          ? <Markdown>{user.note}</Markdown>
          : <cms.Text id="placeholder" />
        }
      </div>
    );
  }

  renderEditMode() {
    const { cms, user } = this.props;

    return (
      <div>
        <button
          type="button"
          className="pull-right btn-link"
          onClick={this.save}
        >
          <i className="fa fa-check" title="Save" /> <WurdText id="common.saveBtn" />
        </button>

        <h3 style={styles.header_editing}><cms.Markdown id="editingInfo" /></h3>

        <textarea
          ref={el => { this.textarea = el; }}
          className="form-control"
          style={styles.textarea}
          defaultValue={user.note}
        />
      </div>
    );
  }

  toggleEditMode = () => {
    const { editMode } = this.state;

    this.setState({ editMode: !editMode });
  }

  save = () => {
    const { user, onChange } = this.props;

    const note = this.textarea.value;

    actions.users.update(user.id, { note })
      .then(res => {
        this.toggleEditMode();

        if (onChange) onChange();
      });
  }

}
