import PropTypes from 'prop-types';

import * as config from '../../config';
import Form from './form';


export default function LoginModal({ err, closeModal }) {
  return (
    <>
      <div className="modal-header" style={{ backgroundColor: 'var(--bs-primary)' }}>
        <h4 className="modal-title text-center"><img src={`${config.publicUrl}/logo.png`} alt="Storeganise" style={{ height: 30 }} /></h4>
      </div>
      <div className="modal-body" style={{ padding: 30, paddingBottom: 20 }}>
        <Form err={err} onLogin={closeModal} />
      </div>
    </>
  );
}


LoginModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};
