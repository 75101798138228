import { useSimpleListQuery } from '../../hooks';

import UnitRentalList from '../unit-rental-list';


export default function UnitRentals({
  modal,
  unit,
  rental
}) {
  const listQuery = useSimpleListQuery({
    initialParams: {
      ...unit ? { unitId: unit.id } : { ids: rental?.id },
      state: null,
    },
  });

  return (
    <UnitRentalList
      listQuery={listQuery}
      modal={modal}
      hideSite
      hideUnit
    />
  );
}
