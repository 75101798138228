import styled from '@emotion/styled';
import Page from '../page';
import BreadcrumbTitle from '../breadcrumb-title';
import SectionNav from './section-nav';
import SectionNavMobile from './section-nav-mobile';
import { useMobile } from 'hooks';


const StickySidebar = styled.aside({
  '@media (min-width: 992px)': {
    position: 'sticky',
    top: 70,
    zIndex: 1020,
  }
});

export default function DetailPage({
  modal,
  cms,
  userRole = null,
  documentTitle = '',
  title = '',
  ancestors = [],
  sections,
  currentSection,
  baseUrl = '',
  extra = null,
  sidebar = null,
  children,
  ...props
}) {
  const isMobile = useMobile('lg');

  return (
    <Page
      documentTitle={documentTitle || title}
      title={(
        <BreadcrumbTitle
          title={title}
          ancestors={ancestors}
        />
      )}
      userRole={userRole}
      modal={modal}
      extra={extra}
      {...props}
    >
      <div className="container-fluid">
        <div className="row">
          <StickySidebar className="col-md-4">
            {sidebar}

            {sections && !isMobile && (
              <SectionNav
                cms={cms}
                baseUrl={baseUrl}
                activeKey={currentSection}
                sections={sections}
              />
            )}
          </StickySidebar>
          <div className="col-md-8">
            {sections && isMobile && (
              <SectionNavMobile
                cms={cms}
                baseUrl={baseUrl}
                activeKey={currentSection}
                sections={sections}
              />
            )}

            {children}
          </div>
        </div>
      </div>
    </Page>
  );
}

