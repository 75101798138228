import styled from '@emotion/styled';

const TaskSummary = styled.summary({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '1rem',
  cursor: 'pointer',

  '>:first-child': {
    flex: 'unset'
  },
  h4: {
    flex: 1,
    margin: '2rem 0!important'
  },

  '.btn': {
    marginBottom: '0!important'
  },

  '& + *': {
    paddingTop: '1rem'
  }
});

export default function Task({ title, isDone, open, action, children }) {
  return (
    <details open={open}>
      <TaskSummary>
        <span className="fa-stack" style={{ fontSize: '1.125em' }}>
          <i className={`${isDone ? 'fa' : 'fal'} fa-stack-2x fa-circle`} style={{ filter: 'brightness(1.75) opacity(.8)', color: isDone === true ? 'var(--bs-success)' : 'var(--bs-gray-dark)' }} />
          {isDone && <i className="fa fa-stack-1x fa-check" style={{ color: '#fff' }} />}
        </span>
        <h4 className={isDone ? 'text-muted' : null}>{title}</h4>
        <div className="hidden-xs">{action}</div>
      </TaskSummary>
      {children}
    </details>
  );
}