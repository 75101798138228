import { WurdText } from 'wurd-react';
import { pick } from 'lodash';

import * as helpers from 'helpers';
import * as actions from 'actions';

import { ModalForm } from 'components/form2';
import CustomField from './field';
import processCustomFields from './actions';


export default function CustomFieldsModal({
  cms,
  cfConfig,
  modelName,
  model,
  modal,
  onChange = () => {},
  onSubmit = null, // Optional custom submit handler; resolves the values rather than updating on the server
}) {
  const endpoint = modelName.replace(/[A-Z]/, s => `-${s[0].toLowerCase()}`).replace(/([^s])$/, '$1s');

  return (
    <ModalForm
      wurdSection={cms.id()}
      initialValue={pick(model, cfConfig.map(({ code }) => `customFields.${code}`))}
      onSubmit={async (fullData, dataPatchesExtended, dataPatches) => {
        // submit only patches because file cfs are in name/url read format, but saved in name/path format, so unless they're modified don't send them
        if (dataPatches.customFields) {
          dataPatches.customFields = await processCustomFields(dataPatches.customFields, cfConfig);
        }

        if (onSubmit) {
          return onSubmit(dataPatches);
        } else {
          return actions.update(endpoint, model.id, dataPatches, { include: 'customFields' })
        }
      }}
      onSuccess={(r) => {
        onChange(r);
        modal.close();
      }}
      submitButton={<WurdText id="common.modalForm.update" />}
      closeModal={modal.close}
      closeIcon={null}
      title={null}
    >
      {(getProps) => (
        <div>
          {cfConfig.sort(helpers.ui.sortByCode).map(({ code, ...cfProps }) => (
            <CustomField
              key={code}
              {...getProps(`customFields.${code}`)}
              {...cfProps}
            />
          ))}
        </div>
      )}
    </ModalForm>
  );
}