import { Link } from 'react-router-dom';

import * as config from 'config';

import StateLabel from 'components/state-label';
import LangText from 'components/lang-text';
import SiteLoader from 'components/loaders/site';

const styles = {
  image: {
    flex: 1,
    width: 240,
    height: 120,
    background: '#fff',
    objectFit: 'contain',
  },
  installedIcon: {
    filter: 'brightness(1.5)',
    fontSize: '2rem',
    position: 'absolute',
    top: -4,
    right: -8,
    background: '#fff',
    borderRadius: '50%',
  },
  siteLabel: {
    position: 'absolute',
    top: 92,
    left: 10,
  },
  disabledLabel: {
    position: 'absolute',
    top: 92,
    right: 10,
  },
};


export default function AddonCard({
  cms,
  addonConfig,
  addon,
  onClick,
}) {
  return (
    <Link
      key={addonConfig.id}
      to={`/addon-configs/${addonConfig.id}`}
      style={{
        position: 'relative',
        boxShadow: '0 1px 7px 0 rgb(0 0 0 / 8%)',
        color: 'inherit',
        width: 240,
      }}
      onClick={onClick}
    >
      <img src={addonConfig.image || `${config.publicUrl}/addon.png`} style={styles.image} />

      {addon?.state === 'enabled' && (
        <i
          title="Installed and enabled"
          className="fas fa-check-circle text-success"
          style={styles.installedIcon}
        />
      )}

      {addon?.state === 'paused' && (
        <i
          title="Installed but paused"
          className="fas fa-pause-circle text-warning"
          style={styles.installedIcon}
        />
      )}

      {addon?.state === 'disabled' && (
        <i
          title="Installed but disabled"
          className="fas fa-minus-circle text-danger"
          style={styles.installedIcon}
        />
      )}

      {addon?.siteId && (
        <StateLabel
          type="info"
          style={styles.siteLabel}
        >
          <SiteLoader id={addon.siteId}>{site => <LangText>{site.title}</LangText>}</SiteLoader>
        </StateLabel>
      )}

      <div style={{ padding: '1rem .5rem', textAlign: 'center', fontSize: '1.1em' }}>
        <strong><LangText>{addonConfig.title}</LangText></strong>
      </div>
    </Link>
  );
}
