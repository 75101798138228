import moment from 'moment-timezone';
import wurd from 'wurd-react';
import _ from 'lodash';

import * as helpers from '.';
import * as actions from '../actions/general';


export const getTransactionId = (payment) => payment.data && (
  payment.data.id // stripe, braintree
  || payment.data.OrderID // gmopg
);

const getPaymentMethod = (payment) => {
  switch (payment.type) {
    case 'manual':
      return `${payment.type}:${payment.method}`;
    case 'gmopg':
      return `${payment.type}:${/^d-/.test(payment.data?.id) ? 'debit' : 'card'}`;
    default:
      return payment.type;
  }
};


export const states = ['draft', 'sent', 'pending', 'failed', 'overdue', 'paid', 'deleted'];


export const stateBsTypes = {
  draft: 'warning',
  sent: 'info',
  pending: 'info',
  failed: 'danger',
  overdue: 'danger',
  deleted: 'danger',
  paid: 'success',
  credit: 'info',
};


export function getStateTitles() {
  return wurd.get('invoice._states') || {};
}

function getExtendedState(invoice) {
  if (invoice.deleted) return 'deleted';
  if (invoice.overdue) return 'overdue';
  return invoice.state;
}

/**
 * Formats invoice as CSV
 *
 * @param {Object} invoice
 * @return {String} csv
 */
export function toCsv(invoice) {
  const settings = helpers.settings.get();
  const site = invoice.site;
  const [startDate, endDate] = helpers.ui.datePeriod(invoice.startDate, invoice.endDate, 'YYYY-MM-DD');

  return {
    sid: invoice.sid?.toUpperCase(),
    startDate,
    endDate,
    state: getExtendedState(invoice),
    ...invoice.owner && _.mapKeys(helpers.user.toCsv(invoice.owner), (v, k) => `owner.${k}`),
    ...site && _.mapKeys(helpers.site.toCsv(site), (v, k) => `site.${k}`),
    ...invoice.unit && _.mapKeys(helpers.unit.toCsv(invoice.unit), (v, k) => `unit.${k}`),
    ...invoice.rental && _.mapKeys(helpers.unitRental.toCsv(invoice.rental), (v, k) => `rental.${k}`),
    subtotal: invoice.subtotal,
    tax: invoice.tax,
    total: invoice.total,
    balance: invoice.balance,
    paid: invoice.paid && moment(invoice.paid).format('YYYY-MM-DD'),
    _paymentMethod: invoice.payments?.map(getPaymentMethod).find(Boolean),
    _paymentNotes: invoice.payments?.map(p => p.notes).find(Boolean),
    _paymentsTotal: invoice.payments?.reduce((acc, o) => acc + o.amount, 0),
    _transactionId: invoice.payments?.map(getTransactionId).find(Boolean),
    // get the transactionID of the first gateway payment found (should we show possible others?)
    labels: invoice.labels?.join(','),
    ...Object.fromEntries(
      settings.invoiceCustomFields?.sort((a,b) => a.code.localeCompare(b.code)).map(({ code, title }) => {
        const value = invoice.customFields?.[code];
        return [`customFields.${code}`, value?.url || value];
      })
    ),
    created: invoice.created,
    updated: invoice.updated,
  };
}


/**
 * Formats invoices as CSV for importing into Xero (with separate line items)
 *
 * @param {Object[]} invoices
 * @return {String} csv
 */
export function toCsvForXero(invoice) {
  const settings = helpers.settings.get();
  const site = invoice.site;
  const [startDate, endDate] = helpers.ui.datePeriod(invoice.startDate, invoice.endDate, 'YYYY-MM-DD');

  const basePrefix = {
    sid: invoice.sid?.toUpperCase(),
    startDate,
    state: getExtendedState(invoice),
    ownerId: invoice.owner?.id,
    paid: invoice.paid && moment(invoice.paid).format('YYYY-MM-DD'),
    _paymentMethod: invoice.payments?.map(getPaymentMethod).find(Boolean),
    _paymentNotes: invoice.payments.map(p => p.notes).find(Boolean),
    _transactionId: invoice.payments?.map(getTransactionId).find(Boolean),
    labels: invoice.labels?.join(','),
    ...Object.fromEntries(
      settings.invoiceCustomFields?.sort((a,b) => a.code.localeCompare(b.code)).map(({ code, title }) => {
        const value = invoice.customFields?.[code];
        return [`customFields.${code}`, value?.url || value];
      })
    ),
    created: invoice.created,
    updated: invoice.updated,
    ..._.mapKeys(helpers.user.toCsv(invoice.owner || {}), (v, k) => `owner.${k}`),
    ...site && _.mapKeys(helpers.site.toCsv(site), (v, k) => `site.${k}`),
    ...invoice.unit && _.mapKeys(helpers.unit.toCsv(invoice.unit), (v, k) => `unit.${k}`),
    ...invoice.rental && _.mapKeys(helpers.unitRental.toCsv(invoice.rental), (v, k) => `rental.${k}`),
  };

  // items and entries must have the same fields, else the csv will be missing some
  const items = invoice.items.map((item) => ({
    ...basePrefix,
    type: item.type, // not existing, just here for entries
    description: item.desc,
    quantity: item.quantity,
    date: item.startDate,
    endDate: item.endDate, // not existing, just here for entries
    amount: item.price,
    subtotal: item.subtotal, // not existing, just here for entries
    tax: item.tax, // not existing, just here for entries
    total: item.total, // not existing, just here for entries
    code: item.accountingCode,
    taxPct: settings.tax?.percent,
    taxType: settings.tax?.type,
  }));

  const entries = invoice.entries.map((entry) => ({
    ...basePrefix,
    type: entry.type,
    description: entry.desc,
    quantity: entry.qty,
    date: entry.date,
    endDate: entry.endDate,
    amount: entry.amount,
    subtotal: entry.subtotal,
    tax: entry.tax,
    total: entry.total,
    code: entry.code,
    taxPct: entry.taxPct,
    taxType: entry.taxType, // not existing, just here for items
  }));

  return [...items, ...entries].map(({ date, endDate, ...o }) => {
    const periods = helpers.ui.datePeriod(date, endDate, 'YYYY-MM-DD');

    return {
      date: periods[0],
      endDate: periods[1],
      ...o
    };
  });
}


/**
 * Fetches invoices for export
 *
 * @param {Object} params       Query params for fetching data
 */
export async function fetchDetailed(params) {
  const invoices = await actions.fetch('invoices', params, { skipCache: true });

  const users = await actions.fetch('users', { ids: invoices.map(invoice => invoice.owner?.id).filter(Boolean), include: 'billing,customFields' }).then(users => new Map(users.map(o => [o.id, o])));
  const rentals = await actions.fetch('unit-rentals', { ids: invoices.map(invoice => invoice.unitRentalId).filter(Boolean), include: 'customFields' }).then(rs => new Map(rs.map(o => [o.id, o])));
  const units = await actions.fetch('units', { ids: [...rentals.values()].map(unitRental => unitRental.unitId).filter(Boolean), include: 'customFields' }).then(units => new Map(units.map(o => [o.id, o])));
  const sites = await actions.fetch('sites', { ids: invoices.map(invoice => invoice.siteId), include: 'customFields' }, { skipCache: true }).then(sites => new Map(sites.map(o => [o.id, o])));

  return invoices.map(invoice => {
    const owner = users.get(invoice.owner?.id);
    const rental = rentals.get(invoice.unitRentalId);
    const unit = rental && units.get(rental.unitId);
    const site = sites.get(invoice.siteId);
   
    return { ...invoice, owner, rental, unit, site };
  });
}


/**
 * Checks if the invoice is a credit note (i.e. a negative invoice, with money owed to customer)
 * 
 * @param {Object} invoice 
 * @returns {Boolean}
 */
export function isCreditNote(invoice) {
  return Math.sign(invoice.total) === -1;
}


export function calculateDates_dateOfMonth(settings, today = moment()) {
  const nextInvoiceCreateDate = moment().date(settings.invoiceGeneration_dateOfMonth_createDate);
  const nextInvoiceChargeDate = moment().date(settings.invoiceGeneration_dateOfMonth_chargeDate);
  const nextInvoiceStartDate = moment().date(settings.invoiceGeneration_dateOfMonth_startDate);

  if (nextInvoiceCreateDate.isBefore(today)) {
    nextInvoiceCreateDate.add(1, 'month');
    if (nextInvoiceCreateDate.date() > nextInvoiceStartDate.date()) {
      nextInvoiceStartDate.add(2, 'month');
    }
    else {
      nextInvoiceStartDate.add(1, 'month');
    }

    if (nextInvoiceCreateDate.date() > nextInvoiceChargeDate.date()) {
      nextInvoiceChargeDate.add(2, 'month');
    }
    else {
      nextInvoiceChargeDate.add(1, 'month');
    }
  }
  else {
    if (nextInvoiceCreateDate.date() > nextInvoiceStartDate.date()) {
      nextInvoiceStartDate.add(1, 'month');
    }
    else if (nextInvoiceStartDate.isBefore(today)) {
      nextInvoiceStartDate.add(1, 'month');
    }

    if (nextInvoiceCreateDate.date() > nextInvoiceChargeDate.date()) {
      nextInvoiceChargeDate.add(1, 'month');
    }
    else if (nextInvoiceChargeDate.isBefore(today)) {
      nextInvoiceChargeDate.add(1, 'month');
    }
  }

  const nextInvoiceEndDate = nextInvoiceStartDate.clone().add(1, 'month');

  return {
    nextInvoiceCreateDate,
    nextInvoiceChargeDate,
    nextInvoiceStartDate,
    nextInvoiceEndDate
  };
}

export function calculateDates_anniversary(settings, today = moment()) {
  const exampleDate = moment().add(10, 'days').get('date');
  
  const nextInvoiceAnniversaryCreateDate = moment().date(exampleDate).subtract(settings.invoiceGeneration_anniversary_createDaysAhead, 'days');
  const nextInvoiceAnniversaryChargeDate = moment().date(exampleDate).subtract(settings.invoiceGeneration_anniversary_chargeDaysAhead, 'days');
  
  if (nextInvoiceAnniversaryCreateDate.isBefore(today)) {
    nextInvoiceAnniversaryCreateDate.add(1,'month');
  }
  if (nextInvoiceAnniversaryChargeDate.isBefore(today)) {
    nextInvoiceAnniversaryChargeDate.add(1,'month');
  }

  return {
    exampleDate,
    nextInvoiceAnniversaryCreateDate,
    nextInvoiceAnniversaryChargeDate,
  };
}
