import wurd from 'wurd-react'

import SidebarUser from '../sidebar-items/user';

const cms = wurd.block('valetOrder.siderbar')


function renderOrderState(orderState) {
  if (orderState === 'submitted') {
    return (
      <div className="list-group-item list-group-item-warning"><cms.Text id='state.submmited' /></div>
    );
  }

  if (orderState === 'completed') {
    return (
      <div className="list-group-item list-group-item-success"><cms.Text id='state.completed' /></div>
    );
  }

  else if (orderState === 'failed') {
    return (
      <div className="list-group-item list-group-item-danger"><cms.Text id='state.failed' /></div>
    );
  }

  else if (orderState === 'failedDelivery') {
    return (
      <div className="list-group-item list-group-item-danger"><cms.Text id='state.failedDelivery' /></div>
    );
  }

  else if (orderState === 'failedCollection') {
    return (
      <div className="list-group-item list-group-item-danger"><cms.Text id='state.failedCollection' /></div>
    );
  }

  else if (orderState === 'cancelled') {
    return (
      <div className="list-group-item list-group-item-danger"><cms.Text id='state.cancelled' /></div>
    );
  }

  else {
    return (
      <div className="list-group-item list-group-item-warning"><cms.Text id='state.inProgress' /></div>
    );
  }
}


export default ({ valetOrder }) => {

  if (!valetOrder) return null;

  return (
    <div className="list-group">
      {renderOrderState(valetOrder.state)}

      <SidebarUser id={valetOrder.ownerId} />
    </div>
  );
};
