import wurd, { WurdMarkdown } from 'wurd-react';

import * as helpers from '../../../helpers';
import store from 'store';
import { useItemLoader } from 'hooks';

import ModalForm2 from 'components/form2/modal-form2';
import { Field, DatePicker, UserSelectAdd, CurrencyInput } from 'components/form2';
import SiteProductsField from 'components/form2/site/products-field';
import PromoField from 'components/form2/unit/promo-field';
import PrepayPeriodsField from 'components/form2/unit/prepay-periods-field';

const cms = wurd.block('jobs.unit_moveIn.create');


export default function UnitMoveInForm({ siteId, ...rest }) {
  const { settings } = store.get();
  const { item: site } = useItemLoader('sites', siteId);

  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode="create"
      className="form2"
      {...rest}
    >
      {({ fieldProps, formValue }) => {
        return (
          <div>
            <Field {...fieldProps('userId')} required className={null}>
              <UserSelectAdd />
            </Field>

            <Field {...fieldProps('startDate')}>
              <DatePicker
                minDate={null}
                required
              />
            </Field>

            <Field {...fieldProps('unitDeposit')} required>
              <CurrencyInput min="0" />
            </Field>

            <Field {...fieldProps('unitPrice')} required>
              <CurrencyInput min="0" />
            </Field>

            <PrepayPeriodsField {...fieldProps('prepayPeriods')} wurdKeys="label,help,1,multiple" cms={cms.block('prepayPeriods')} />

            <PromoField
              {...fieldProps('promoCode')}
              promos={settings.unitPromos.filter(promo => helpers.business.isPromoValid(promo, siteId, formValue.startDate))}
              help={!settings.unitPromos.length && <WurdMarkdown id="common.forms.noUnitPromos" />}
            />

            {site.products?.length > 0 && (
              <details>
                <summary><cms.Text id="products.label" type="h5" style={{ display: 'inline-block', fontWeight: 500 }} /></summary>
                <SiteProductsField products={site.products.sort(helpers.ui.sortByCode)} {...fieldProps('products')} />
              </details>
            )}

          </div>
        );
      }}
    </ModalForm2>
  );
}
