import debounce from 'lodash/debounce';


export default function debouncePromise(fn, ms, opts) {
  const debounced = debounce((resolve, reject, args) => {
    fn(...args).then(resolve).catch(reject);
  }, ms, opts);
  return (...args) => new Promise((resolve, reject) => {
    debounced(resolve, reject, args);
  });
}