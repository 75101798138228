import styled from '@emotion/styled';


const Table = styled('table')({
  fontSize: 10,

  td: {
    display: 'table-cell',
    verticalAlign: 'middle !important',
    height: '4em',
  },

  '.fitContent': {
    whiteSpace: 'nowrap',
    width: '1%',
  },

  '.secondary': {
    color: '#bbb',
  },
});


export default Table;
