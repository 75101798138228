import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { WurdText } from 'wurd-react';

import store from '../../store';
import * as actions from '../../actions';

import BillingPlugin from '../../plugins/billing';
import UserSelect from '../user-select';
import UserForm from './user/custom';


export default class UserSelectAdd extends Component {
  state = {
    userId: this.props.value,
    modal: null,
  };

  showNewUserModal = () => {
    const { name, onChange } = this.props;

    this.setState({
      modal: (
        <UserForm
          onSubmit={actions.users.create}
          onSuccess={user => {
            this.setState({ userId: user.id, modal: null });
            onChange({ target: { name, value: user.id } });
          }}
          closeModal={() => this.setState({ modal: null })}
          className="form2"
        />
      ),
    });
  };

  onSelect = userId => {
    const { name, onChange } = this.props;

    this.setState({ userId });

    onChange({ target: { name, value: userId } });
  };

  render() {
    const { name, error, ...inputProps } = this.props;
    const { userId, modal } = this.state;

    const { users_byId = {}, settings } = store.get();
    const user = users_byId[userId];

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UserSelect
            autoFocus
            {...inputProps}
            value={userId}
            onChange={this.onSelect}
          />
          <button type="button" className="btn btn-link" title="Add user" onClick={this.showNewUserModal}>
            <i className="fa fa-plus" />
          </button>
          <Modal show={!!modal} onHide={() => this.setState({ modal: null })}>
            {modal}
          </Modal>
        </div>
        {user && (
          <>
            <WurdText id="user.modal.sections.billing" type="h6" style={{ fontWeight: 600 }} />
            <BillingPlugin
              user={user}
              method={settings.billing}
              onChange={() => { }}
            />
          </>
        )}
      </div>
    );
  }
}
