import wurd from 'wurd-react';

import * as actions from '../../../../actions';

import JobStep from '../../job-step2';
import ApiButton from '../../../api-button';
import { Section, Heading } from '../../components';
import OrderSummary from '../summary';
import OperationsBar from '../operations-bar';
import OperationsBar2 from '../operations-bar2';

const cms = wurd.block('unitOrder.steps.order_confirm');


export default function UnitMoveInJobSteps_OrderConfirm({
  site,
  job,
  step,
  owner,
  unit,
  rental,
  onChange,
  modal,
}) {
  async function completeStep() {
    await actions.jobs.updateStep(job.id, step.id, {
      state: 'completed',
    });

    onChange();
  }

  function render_ready() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="ready.title" />}
      >
        <Section>
          <Heading><cms.Text id="summary" /></Heading>
  
          <OrderSummary
            job={job}
            owner={owner}
            unit={unit}
            rental={rental}
            modal={modal}
            onChange={onChange}
          />

          <OperationsBar
            job={job}
            modal={modal}
            onChange={onChange}
            buttons={['previewInvoice', 'viewAgreement', 'cancelJob']}
          />

          {/* <OperationsBar2
            job={job}
            modal={modal}
            onChange={onChange}
          /> */}
        </Section>
  
        <div className="btn-group btn-group-justified">
          <div className="btn-group">
            <ApiButton
              bsType="success"
              onClick={completeStep}
            >
              <i className="fas fa-check" />
              &nbsp;
              <cms.Text id="requiresInput.completeBtn" />
            </ApiButton>
          </div>
        </div>
      </JobStep>
    );
  }

  function render_completed() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" />}
      />
    );
  }

  if (step.state === 'ready') return render_ready();
  if (step.state === 'completed') return render_completed();

  return null;
}
