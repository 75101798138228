/**
 * A super simple store for global data
 * such as user info etc.
 */
const storeData = {
  sites_byId: {},
  sites_queries: {},
  users_byId: {},
  users_queries: {},
  jobs_byId: {},
  jobs_queries: {},
  invoices_byId: {},
  invoices_queries: {},
  units_byId: {},
  units_queries: {},
  'unit-rentals_byId': {},
  'unit-rentals_queries': {},
  'unit-types_byId': {},
  'unit-types_queries': {},
  items_byId: {},
  items_queries: {},
  'valet-orders_byId': {},
  'valet-orders_queries': {},
  visits_byId: {},
  visits_queries: {},
  'site-reports_byId': {},
  'site-reports_queries': {},
  'invoices/payments_queries': {},
  'invoices/payments_byId': {},
  addons_byId: {},
  addons_queries: {},
  'addon-configs_byId': {},
  'addon-configs_queries': {},
};

const listeners = new Set();


/**
 * Get store data
 *
 * Usage:
 * const key = store.get('key')
 * const { key } = store.get()
 *
 * @param {String} key
 *
 * @return {Mixed}
 */
function get(key) {
  if (key) return storeData[key];

  return storeData;
}


/**
 * Set store data and trigger onChange listeners.
 * Merges given object data into the existing store data.
 *
 * Usage:
 * store.set({ foo: 'bar' });
 *
 * @param {String} key
 *
 * @return {Mixed}
 */
function set(newData) {
  Object.assign(storeData, newData);

  listeners.forEach((fn) => fn.call(undefined, get()));
}


function onChange(fn) {
  listeners.add(fn);

  return function unsubscribe() {
    listeners.delete(fn);
  };
}


export default {
  get,
  set,
  onChange,
};
