import { useState, useRef } from 'react';
import { Overlay, Popover, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import { WurdMarkdown } from 'wurd-react';

import Label from './item';


const LabelsContainer = styled('span')({
  marginRight: 2,
  fontSize: '1.4rem',
  display: 'inline-flex',
  alignItems: 'stretch',
  verticalAlign: 'middle',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: 'calc(100vw - 250px)',
});

const ListGroupStyled = styled(ListGroup)({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,

  '& > *': { // cancel .list-group-item styling
    border: 'none',
    textAlign: 'left',
    marginBottom: 0,
    outline: 'none',
  },
});

const PopoverStyled = styled(Popover)({
  padding: 0,
  '& .popover-content': {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

/* const ButtonStyled = styled(Button)({
  display: 'inline-flex',
  marginLeft: 2,
  marginBottom: '0!important', // fuck off main.css
  transform: 'scale(.7)',
  padding: 2,
  cursor: 'auto',
  '&:hover, &:focus': {
    textDecoration: 'none'
  },
}); */

function LabelsSelect({ labels, settingsLabels, onChange, disabled }) {
  const [show, setShow] = useState(false);
  const [localLabels, setLocalLabels] = useState(labels);
  const buttonRef = useRef();

  function onToggle(e) {
    if (e && !show) {
      setShow(true);
      return;
    }
    const newLabels = settingsLabels.filter(label => localLabels.includes(label.id)).map(label => label.id); // keep labels order in settings
    setShow(false);
    onChange(newLabels);
  }

  return (
    <>
      <Button
        bsStyle="link"
        onClick={onToggle}
        ref={buttonRef}
        disabled={disabled}
        className="hidden-print"
      >
        <i className="fas fa-tags" />
      </Button>
      <Overlay
        show={show}
        placement="bottom"
        target={buttonRef.current}
        onHide={() => onToggle(null)}
        rootClose
      >
        <PopoverStyled id="edit-labels-menu">
          <ListGroupStyled>
            {settingsLabels?.map(label => {
              const isActive = localLabels.includes(label.id);

              return (
                <ListGroupItem
                  key={label.id}
                  onClick={() => setLocalLabels(isActive ? localLabels.filter(id => id !== label.id) : [...localLabels, label.id])}
                  active={isActive}
                >
                  <Label {...label} />
                </ListGroupItem>
              );
            })}
            {!settingsLabels?.length && (
              <ListGroupItem>
                <WurdMarkdown id="common.filters.labels.noLabels" style={{ maxWidth: 140 }} />
              </ListGroupItem>
            )}
          </ListGroupStyled>
        </PopoverStyled>
      </Overlay>
    </>
  );
}

export default function LabelsListDetail({ labels: labelIds, settingsLabels, onChange, disabled, ...props }) {
  const labels = labelIds
    .map(labelId => {
      const label = settingsLabels.find(l => l.id === labelId);
      if (!label) {
        console.warn(`Label ${labelId} not found`);
        return null;
      }
      return label;
    })
      .filter(Boolean);

  return (
    <>
      <LabelsContainer {...props}>
        {labels.map(label => (
          <Label key={label.id} style={{ fontSize: '85%' }} {...label} />
        ))}
      </LabelsContainer>
      {onChange && <LabelsSelect labels={labelIds} settingsLabels={settingsLabels} onChange={onChange} disabled={disabled} />}
    </>
  );
}
