import wurd from 'wurd-react';

import * as helpers from '../../helpers';

import LabelsFilter from 'plugins/labels/filter';
import SearchFilter from '../filter/search';


export default function SiteListFilters({ listQuery }) {
  const { params } = listQuery;

  function onChange(key, value) {
    listQuery.update({
      offset: 0,
      [key]: value,
    });
  }

  return (
    <>
      <LabelsFilter
        active={params.labels}
        onSelect={(val) => onChange('labels', val)}
        labels={helpers.settings.get().siteLabels}
      />

      <SearchFilter
        name="site-search"
        cms={wurd.block('siteList.search')}
        active={params.search}
        onSelect={(val) => onChange('search', val)}
        modelName="site"
      />
    </>
  );
}
