const buttonStyle = {
  marginTop: 0,
};

const iconStyle = {
  float: 'left',
  marginTop: 3,
  marginLeft: -5,
};

const headerStyle = {
  marginLeft: 25,
};

const descStyle = {
  marginLeft: 25,
};


export default function BulkUpdateModalItem({
  cms,
  errKey,
  onClick,
  faIcon,
}) {
  const disabled = Boolean(errKey);

  const cmsKeys = ['btn', 'desc', errKey].filter(Boolean).join(',');

  return (
    <cms.Object keys={cmsKeys}>
      <button
        type="button"
        className={`list-group-item btn-block text-left ${disabled && 'disabled'}`}
        disabled={disabled}
        onClick={onClick}
        style={buttonStyle}
      >
        <i className={`fa-fw fa-lg ${faIcon}`} style={iconStyle} />
        <h4 className="list-group-item-heading" style={headerStyle}>
          {cms.text('btn')}
        </h4>
        <p className="list-group-item-text text-muted" style={descStyle}>
          {cms.text(errKey || 'desc')}
        </p>
      </button>
    </cms.Object>
  );
}