import { Component } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import JobStep from '../../job-step2';
import UnitLoader from '../../../loaders/unit';

const cms = wurd.block('unitOrder.steps.unit_change');


export default class UniveMoveInJobSteps_unit_change extends Component {

  static propTypes = {
    job: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { step } = this.props;

    const renderFn = this[`render_${step.state}`];
    if (!renderFn) return null;

    return renderFn.call(this);
  };

  render_completed() {
    const { step } = this.props;
    const { unitId } = step.result;

    return (
      <UnitLoader id={unitId}>
        {unit => (
          <JobStep
            step={step}
            title={<cms.Text id="completed.title" vars={{ name: unit.name }} />}
          />
        )}
      </UnitLoader>
    );
  };

};
