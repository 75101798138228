import { apiUrl } from 'config';
import api from 'utils/api';
import { get as getLang } from 'utils/language';


export function previewChanges(templateKey, lang, { subject, text }, validate) {
  return api.post(`/_email/${templateKey.replace(/_/g, '.')}/preview?lang=${lang}`, { subject, text })
    .then(res => {
      const html = res.data;
      
      if (validate) return null;

      const popup = window.open('', 'Preview email', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,left=${window.screenX + Math.max(0, (window.innerWidth - 800) / 2)},top=${window.screenY + Math.max(0, (window.innerHeight - 800) / 2)}`);
      popup.document.body.innerHTML = html;
    })
    .catch(err => {
      if (validate) {
        const r = err.response.data;

        return `${r.error.message} ${Object.entries(r.error.errors || {}).map(([k, v]) => `'${k}': ${v.message || v}`).join(', ')}`
      }
    });
}


export function send(templateKey, id) {
  return api.post(`/_email/${templateKey.replace(/_/g, '.')}/send/${id}`)
    .then(res => res.data);
}


export function previewUrl(templateKey, lang = getLang()) {
  return `${apiUrl}/_email/${templateKey.replace(/_/g, '.')}?edit=no&lang=${lang}`;
}
