import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import wurd from 'wurd-react';

import * as actions from '../../actions';
import store from '../../store';
import * as secsys from 'plugins/security-systems';

import Spinner from '../spinner';
import Sitemap from './component';
import objects from './objects.json';

import {
  TYPES,
  DEFAULT_UNIT_SIZE,
  SIDE_BAR_WIDTH,
} from './component/Constants';


/** @typedef {{position: {x: number, y: number}, zoom: number}} FloorData */

const objectsByType = objects.reduce((memo, o) => ({ ...memo, [o.objectType]: o }), {});

const generateObjectId = (objectType) => `${objectType}_${Date.now()}`;


export default class SiteMapContainer extends Component {

  static propTypes = {
    site: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
    fullSize: PropTypes.bool,
  }

  state = {
    width: null,
    height: null,
    floor: '1',
    zoom: 1, // zoom for this floor
    position: {}, // position of this floor center
    positions: this.getPositions()
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    this.changeFloor();

    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.site.positions !== this.props.site.positions) { // triggered when saving site.positions
      this.setState({
        positions: this.getPositions(),
      });
    }
  }

  getPositions() {
    return (this.props.site.positions || []).map(p => ({ ...p, floor: `${p.floor}`, id: p.id || generateObjectId(p.objectType) }));
  }

  /**
   * Retrieves DB or objects.json metadata corresponding to this position item
   * @param {import("../../../actions/sites").SitePosition} item 
   */
  getSitemapData = (item) => {
    const { site } = this.props;
    const object = objectsByType[item.objectType];
    if (object) return { ...object, id: item.id };

    const { units_byId } = store.get();
    const unit = units_byId[item.id];

    return {
      ...unit,
      ...unit && { measure: unit.measure /* deprecated */ || site.measure },
      hasSecuritySystem: unit?.customFields?.[secsys.getCfKey(site)]
    };
  };

  updateDimensions = () => {
    const width = this.containerEl.offsetWidth;
    const height = this.containerEl.offsetHeight;

    this.setState({
      width: this.props.fullSize ? window.innerWidth : width,
      height: this.props.fullSize ? window.innerHeight : height,
    })
  };

  addPosition = item => {
    const { floor, positions } = this.state;

    this.setState({
      positions: [
        ...positions,
        {
          id: item.id || generateObjectId(item.objectType),
          type: TYPES[item.type] || TYPES.unit,
          floor,
          objectType: item.objectType,
          x: SIDE_BAR_WIDTH + Math.floor(100 * Math.random()),
          y: Math.floor(100 * Math.random()),
          rotation: 0,
          width: item.width || DEFAULT_UNIT_SIZE,
          length: item.length || DEFAULT_UNIT_SIZE,
          scaleX: 1,
          scaleY: 1,
        }
      ]
    });
  };

  removePosition = selectedItem => {
    const { positions } = this.state;

    this.setState({
      positions: positions.filter(item => selectedItem && item.id !== selectedItem.id),
    })
  };

  updatePosition = item => {
    const { positions } = this.state;

    this.setState({
      positions: positions.map(p => {
        if (p.id !== item.id) return p;

        return {
          id: p.id,
          type: TYPES[p.type] || TYPES.unit,
          floor: item.floor ?? p.floor,
          objectType: p.objectType,
          x: item.x ?? p.x,
          y: item.y ?? p.y,
          rotation: item.rotation ?? p.rotation,
          width: item.width ?? p.width,
          length: item.length ?? p.length,
          scaleX: item.scaleX ?? p.scaleX,
          scaleY: item.scaleY ?? p.scaleY,
          name: item.name ?? p.name, // renamed object
        };
      })
    });
  };

  /**
   * Set new floor, and restore data (zoom, position) from this floor
   * @param {number} newFloor currentFloor, if undefined use the last viewed floor from localStorage
   */
  changeFloor = (newFloor) => {
    const { site } = this.props;

    /** @type {{[siteId: string]: {floor: number, floors: {[floor: string]: FloorData}}}} */
    const sitemap = JSON.parse(window.localStorage.getItem('sitemap'));

    const { floors = {}, floor: lastViewedFloor = '1' } = sitemap && sitemap[site.id] || {};

    const floor = newFloor !== undefined ? newFloor : lastViewedFloor;

    const { position = { x: 0, y: 0 }, zoom = 1 } = floors[floor] || {};

    this.setState({ floor: `${floor || 1}`, zoom, position });
  };

  /**
   * Save site's floors data in localStorage, to be done when changing floor or unmounting
   * @param {string} floor
   * @param {FloorData} floorData
   */
  saveToLocalStorage = (floor, floorData) => {
    const { site } = this.props;
    let sitemap = JSON.parse(window.localStorage.getItem('sitemap'));

    const { floors } = sitemap && sitemap[site.id] || {};
    sitemap = {
      ...sitemap,
      [site.id]: {
        floor, // last visited floor is current
        floors: {
          ...floors,
          [floor]: floorData || floors[floor]
        }
      }
    };

    window.localStorage.setItem('sitemap', JSON.stringify(sitemap));
  };

  handleSave = async () => {
    const { site } = this.props;
    const { positions } = this.state;

    if (location.hash === '#edit') location.hash = '';

    if (_.isEqual(positions, site.positions)) return; // nothing to do

    if (!window.confirm(wurd.text('siteView.sitemap.confirmSave') || 'Save changes?')) return;

    await actions.sites.updatePositions(site.id, positions.map(p => ({ ...p, type: TYPES[p.type] || TYPES.unit })));
  };

  handleReset = () => {
    const { site } = this.props;
    const { positions } = this.state;

    if (location.hash === '#edit') location.hash = '';

    if (_.isEqual(positions, site.positions)) return; // nothing to do

    if (!window.confirm(wurd.text('siteView.sitemap.confirmCancel') || `Cancel changes?`)) return;

    this.setState({ positions: this.getPositions() });
  };

  render() {
    const { site, units } = this.props;
    const { positions } = site;

    return (
      <div ref={el => this.containerEl = el} style={{ height: 'calc(100vh - 214px)' }}>
        {(units && positions)
          ? this.renderMain()
          : <Spinner />
        }
      </div>
    );
  }

  renderMain() {
    const { units, site, highlightUnitIds, onClickUnit } = this.props;

    const { width, height, floor, zoom, position, positions } = this.state;

    if (!width || !height) return null;

    return (
      <>
        <Sitemap
          units={units}
          width={width}
          height={height}
          editMode={location.hash === '#edit'}
          objects={objects}
          positions={positions}
          floor={floor}
          zoom={zoom} // initial zoom in this floor
          position={position} // initial position in this floor
          onChangeFloor={this.changeFloor}
          onAddPosition={this.addPosition}
          onRemovePosition={this.removePosition}
          onUpdatePosition={this.updatePosition}
          onSave={this.handleSave}
          onReset={this.handleReset}
          onSaveToLocalStorage={this.saveToLocalStorage}
          getSitemapData={this.getSitemapData}
          highlightUnitIds={highlightUnitIds}
          onClickUnit={onClickUnit}
        />
      </>
    )
  }

}
