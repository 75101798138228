import createClass from 'create-react-class';

import * as actions from '../../actions';
import * as helpers from '../../helpers';

import Page from '../page';
import NotFound from '../not-found';
import Spinner from '../spinner';
import Menu from './menu';
import Warning from './warning';
import Steps from './steps';
import Details from './details';
import Items from './items';
import Charges from './charges';
import Activity from '../activity';

const { k } = helpers.cms.getters('visitView');


const VisitDetail = createClass({

  getInitialState() {
    return {
      visit: undefined,
      relatedVisit: null
    };
  },

  componentDidMount() {
    this.fetch();
  },

  componentDidUpdate(prevProps) {
    var oldId = prevProps.params.id;
    var newId = this.props.params.id;

    if (newId !== oldId) {
      this.fetch();
      window.scrollTo(0, 0);
    }
  },

  render() {
    const { visit } = this.state;

    if (visit === null) return <NotFound />;
    if (visit === undefined) return <Spinner />;

    return (
      <Page
        titleKey={k('title')}
        titleVars={{ visitId: visit.sid.toUpperCase() }}
        subtitle={visit.owner.name}
        userRole={['driver', 'operations']}
        modal={this.state.modal}
        closeModal={this.closeModal}
        extra={this.renderExtra(visit)}
      >
        {this.renderMain(visit)}
      </Page>
    )
  },

  renderExtra(visit) {
    return (
      <Menu
        visit={visit}
        onChange={this.fetch}
      />
    );
  },

  renderMain(visit) {
    const { relatedVisit } = this.state;

    const childProps = {
      visit: visit,
      relatedVisit: relatedVisit,
      onChange: this.fetch
    }

    //Check if the visit is not cancelled or failed
    const isActive = !(['cancelled', 'failed-delivery', 'failed-collection'].includes(visit.state));

    return (
      <div>
        <div className="row">
          <Warning visit={visit} />

          <div className="col-md-offset-3 col-md-6">
            {isActive &&
              <Steps {...childProps} />
            }
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <Details {...childProps} />
          </div>

          <div className="col-md-4">
            <Items {...childProps} />

            <Charges {...childProps} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <Activity context="visit" endpoint={`/visits/${visit.id}/actions`} />
          </div>
        </div>
      </div>
    );
  },

  fetch() {
    const id = this.props.params.id;

    actions.visits.fetchOne(id)
      .then(visit => {
        this.setState({ visit: visit })
      })
      .then(this.fetchRelatedVisit)
      .catch(err => this.setState({ visit: null }));
  },

  fetchRelatedVisit() {
    const { visit } = this.state;

    if (!visit || !visit.relatedVisit) return;

    actions.visits.fetchOne(visit.relatedVisit.id)
      .then(visit => {
        this.setState({ relatedVisit: visit })
      });
  }

});


export default VisitDetail;
