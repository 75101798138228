import * as actions from '../../actions';

import CustomSystemCharges from '../custom-system-charges';


export default function UnitDetailSystemCharges({
  modal,
  cms,
  unitRental,
  onChange,
}) {
  async function onSubmit(data) {
    await actions.update('unit-rentals', unitRental.id, {
      systemCharges: data,
    });
  }

  return (
    <CustomSystemCharges
      modal={modal}
      cms={cms}
      systemCharges={unitRental?.systemCharges}
      onSubmit={onSubmit}
      onSuccess={onChange}
    />
  );
}
