import PropTypes from 'prop-types';

import * as actions from '../../actions';

import ReactHoverObserver from 'react-hover-observer';
import ImageForm from 'components/form2/image-form';
import ImagePreview from '../image-preview';


const styles = {
  main: {
    position: 'relative',
  },
  editOverlay: {
    position: 'absolute',
    display: 'inline-block',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    cursor: 'pointer',
  },
  editText: {
    position: 'absolute',
    display: 'inline-block',
    verticalAlign: 'middle',
    color: 'rgba(255,255,255,0.75)',
    left: 0,
    right: 0,
    top: '50%',
    fontSize: 36,
    marginTop: -18,
  }
}


export default function SiteDetailSidebarSummary({ site, modal }) {
  function editImage() {
    modal.open(
      <ImageForm
        initialValue={site}
        wurdSection="site"
        onSubmit={formData => actions.sites.update(site.id, formData)}
        onSuccess={modal.close}
        closeModal={modal.close}
        mode="update"
      />
    );
  }

  return (
    <ReactHoverObserver>
      {({ isHovering }) => (
        <div style={styles.main}>
          <ImagePreview
            doc={site}
            style={{ borderBottom: 0 }}
          />

          {isHovering && (
            <div style={styles.editOverlay} onClick={editImage}>
              <span style={styles.editText}><i className="fas fa-edit" /></span>
            </div>
          )}
        </div>
      )}
    </ReactHoverObserver>
  );
}


SiteDetailSidebarSummary.propTypes = {
  site: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
};
