import { Fragment, useState } from 'react';
import _ from 'lodash';
import wurd from 'wurd-react';

import * as helpers from '../../../helpers';

import JobStep from '../../jobs/job-step2';
import LargeButtonGroup from '../../large-button-group';
import ResultsItemList from '../shared/results-item-list';
import Item from '../shared/item';
import ScanReceiver from '../shared/scan-receiver';
import ScanItemsButton from '../shared/scan-items-button';
import CompleteStepButton from '../shared/complete-step-button';
import ItemTypeHeader from '../shared/item-type-header';

const cms = wurd.block('valetOrder.steps.items_pickEmptyBoxes');


export default function Step_items_pickEmptyBoxes(props) {
  const {
    valetOrder,
    step,
    items,
    updateItems,
    modal,
  } = props;

  const boxItems = items.filter((item) => !item.isBulky);
  const itemConfigs = helpers.item.byType(true);

  const { pickedItems = [], otherItems = [] } = _.groupBy(boxItems, (item) => (
    item.state === 'unassigned' ? 'pickedItems' : 'otherItems'
  ));

  const pickedTypes = pickedItems.map((item) => item.type);
  const orderedTypes = Object.entries(valetOrder.boxCounts).reduce((memo, [type, quantity]) => (
    (quantity > 0) ? memo.concat(type) : memo
  ), []);
  const allTypes = _.uniqBy(pickedTypes.concat(orderedTypes)).sort();

  const emptyBoxCount = helpers.valetOrder.countEmpties(valetOrder);

  const itemsByType = _.groupBy(pickedItems, 'type');

  const typesSummary = _.sortBy(allTypes, 'type').reduce((memo, type) => {
    const itemConfig = itemConfigs[type] || {};
    const numOrdered = valetOrder.boxCounts[type] || 0;

    const itemsOfType = itemsByType[type] || [];
    const numPicked = itemsOfType.length;

    memo.push({
      type,
      numOrdered,
      numPicked,
      items: itemsOfType,
      hasPickedAll: numPicked >= numOrdered,
      title: itemConfig.title || type,
      image: itemConfig.image,
    });

    return memo;
  }, []);

  const hasPickedAll = typesSummary.every((summaryLine) => summaryLine.hasPickedAll);

  const [updateErrs, setUpdateErrs] = useState(null);

  async function pickItems(itemSids) {
    const { errors, updatedSids } = await updateItems('valetOrder_add', itemSids);

    if (errors) setUpdateErrs(errors);
  }

  async function unpickItems(itemSids) {
    const { errors, updatedSids } = await updateItems('valetOrder_remove', itemSids);

    if (errors) setUpdateErrs(errors);
  }

  function onItemRemove({ currentTarget }) {
    const { sid } = currentTarget.dataset;
    unpickItems([sid]);
  }

  function renderActions() {
    return (
      <JobStep
        step={step}
        faIcon="fas fa-box-open"
        title={<cms.Text id="requiresInput.title" vars={{ numItems: emptyBoxCount }} />}
        helpWurdId={cms.id('instructions.helpUrl')}
      >
        <ScanReceiver onScan={pickItems} />

        {typesSummary.map((summary) => (
          <Fragment key={summary.type}>
            <ItemTypeHeader
              summary={summary}
            />

            {summary.items.map((item) => (
              <Item
                key={item.sid}
                item={item}
                scanned
                onDelete={onItemRemove}
                hideImage
              />
            ))}
          </Fragment>
        ))}

        <cms.Object keys="completeBtn,notFinished_confirm">
          <LargeButtonGroup style={{ marginTop: 20 }}>
            <ScanItemsButton
              modal={modal}
              receiveScannedSids={pickItems}
            />
            <CompleteStepButton
              valetOrder={valetOrder}
              step={step}
              cms={cms}
              hasProcessedAll={hasPickedAll}
            />
          </LargeButtonGroup>
        </cms.Object>
      </JobStep>
    );
  }

  function renderResults() {
    const pickedItemIds = step.result.picked;
    const numItems = pickedItemIds.length;

    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" vars={{ numItems }} />}
      >
        <h4><cms.Text id="pickedItems" vars={{ numItems }} /></h4>
        <ResultsItemList itemIds={pickedItemIds} />
      </JobStep>
    );
  }


  return (step.state === 'completed') ? renderResults() : renderActions();
}
