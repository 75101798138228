import wurd from 'wurd-react';
import { useParams } from 'react-router-dom';

import { useModal, useItemLoader } from 'hooks';
import Spinner from 'components/spinner';
import NotFound from 'components/not-found';

import AddonConfigDetailPage from 'components/addons/config-detail';

const cms = wurd.block('addons');

export default function AddonConfigDetailsPage() {
  const { id, section = 'settings' } = useParams();

  const modal = useModal();

  const { item: addonConfig, isLoading, refetch } = useItemLoader('addon-configs', id, {
    maxAge: 0,
    include: [],
  });

  if (!addonConfig) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  return (
    <AddonConfigDetailPage
      section={section}
      modal={modal}
      cms={cms}
      refetch={refetch}
      addonConfig={addonConfig}
    />
  );
}
