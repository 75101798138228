import * as helpers from '../helpers';

const styles = {
  image: {
    width: 50,
    height: 50,
    marginRight: 10,
    objectFit: 'cover'
  },

  title: {
    fontSize: 16
  },

  type: {
    color: '#666'
  }
};


const ProductType = ({ id, quantity, ...rest }) => {

  const productType = helpers.product.get(id, true);

  return (
    <div {...rest}>
      <img src={productType.image} alt="" style={styles.image} />

      <div className="pull-right">
        <div style={styles.displayPrice}>{productType.displayPrice}</div>
        <div style={styles.price}>{helpers.ui.currency(productType.price)}</div>
      </div>

      <span style={styles.title}>
        {productType.title}
        {quantity && ` × ${quantity}`}
      </span>
    </div>
  );

};


export default ProductType;
