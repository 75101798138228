import wurd from 'wurd-react';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import SectionCrudList from '../../section_crud-list';
import LangText from '../../lang-text';
import UserChargeForm from './user-charge-form';


export default function UserChargesSection({ settings }) {
  return (
    <SectionCrudList
      cms={wurd.block('settings.userCharges')}
      items={settings.userCharges}
      Form={UserChargeForm}
      actions={actions.business.userCharges}
      onChange={actions.business.fetch}
    >
      {item => {
        const accountingCode = helpers.business.getAccountingCodeById(item.accountingCodeId);

        return (
          <>
            <div className="pull-right"><strong>{helpers.ui.currency(item.amount)}</strong></div>
            <div><strong><LangText obj={item.title} /></strong></div>
            <div className="text-muted">
              {accountingCode && (
                <div title={wurd.text('business.unitCharge.accountingCodeId.label')}>
                  <i className="fa-fw far fa-calculator" /> {accountingCode.desc}
                </div>
              )}
            </div>
          </>
        );
      }}
    </SectionCrudList>
  );
}
