import ChargesPlugin from '../../plugins/charges';


export default function UnitDetailCharges({
  modal,
  cms,
  rental,
  onChange,
}) {
  return (
    <ChargesPlugin
      cms={cms.block('charges')}
      endpoint={`/v1/admin/unit-rentals/${rental.id}/charges`}
      charges={rental.charges}
      modal={modal}
      onChange={() => {
        modal.close();
        onChange();
      }}
    />
  );
}
