import PropTypes from 'prop-types';
import set from 'lodash/set';
import get from 'lodash/get';


export default function FormObject({ children, name, value, onChange, ...rest }) {
  function handleChange(event) {
    const changedKey = event.target.name;
    const changedValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    const newObjectValue = set({ ...value }, changedKey, changedValue);

    onChange({
      target: {
        name,
        value: newObjectValue
      }
    });
  }

  function getFieldProps(name) {
    return {
      // ...fieldProps(name)
      name: name,
      value: get(value, name) ?? '',
      onChange: handleChange,
      // error: errors && errors[name],
    };
  }

  return children({
    getProps: getFieldProps,
    formValue: value,
    ...rest
  });
}


FormObject.propTypes = {
  children: PropTypes.func.isRequired,
};
