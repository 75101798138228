import { Component } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import { DropdownButton, MenuItem } from 'react-bootstrap';
import Modal from '../../modal';

const cms = wurd.block('unitJobs.moveOut.menu');


export default class UnitMoveOutJobMenu extends Component {

  static propTypes = {
    job: PropTypes.object.isRequired,
    site: PropTypes.object.isRequired,
    owner: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    modal: PropTypes.object.isRequired,
    unit: PropTypes.object,
  }

  render() {
    const menuItems = this.getMenuItems();
    if (!menuItems.length) return null;

    return (
      <cms.Object keys="cancelJob">
        <DropdownButton
          title={<i className="fa fa-ellipsis-v" />}
          id="menu"
          bsStyle="link"
          pullRight
          noCaret
        >
          {menuItems}
        </DropdownButton>
      </cms.Object>
    );
  }

  getMenuItems() {
    const items = [];

    if (this.canCancelJob()) {
      items.push(
        <MenuItem key="cancelJob" onSelect={this.cancelJob}>
          <i className="fas fa-fw fa-times" /> {cms.text('cancelJob')}
        </MenuItem>
      );
    }

    if (this.canViewJobData()) {
      items.push(
        <MenuItem key="viewJobData" onSelect={this.viewJobData}>
          <em className="text-muted">
            <i className="fas fa-fw fa-code" /> View job data
          </em>
        </MenuItem>
      );
    }

    return items;
  }

  canViewJobData() {
    return helpers.auth.hasRole('_support');
  }

  viewJobData = () => {
    const { job, modal } = this.props;

    modal.open(
      <Modal title={job.type} subtitle={job.id}>
        <textarea
          style={{
            display: 'block',
            width: '100%',
            height: 400,
            fontFamily: 'monospace'
          }}
          value={JSON.stringify(job, null, 2)}
          readOnly
        />
      </Modal>
    );
  }

  canCancelJob = () => {
    //const { job } = this.props;

    //return job.result.orderState === 'submitted';
    return true;
  }

  cancelJob = () => {
    const { job } = this.props;

    const confirmed = window.confirm('Are you sure you want to cancel this order?');
    if (!confirmed) return;

    actions.jobs.cancel(job.id)
      //.then(job => onChange());
  }

};
