import wurd from 'wurd-react';
import { useParams } from 'react-router-dom';

import { useModal, useItemLoader } from 'hooks';
import { useTasksPlugin } from 'plugins/tasks';
import Spinner from 'components/spinner';
import NotFound from 'components/not-found';

import AddonDetailPage from 'components/addons/detail';

const cms = wurd.block('addons');

export default function AddonDetailController() {
  const { id, section = 'overview' } = useParams();

  const modal = useModal();

  const { item: addon, isLoading: isLoadingAddon, refetch: refetchAddon } = useItemLoader('addons', id, {
    maxAge: 0,
    include: [],
  });

  const { item: addonConfig, isLoading: isLoadingAddonConfig, refetch: refetchAddonConfig } = useItemLoader('addon-configs', addon && addon.addonConfigId, {
    maxAge: 0,
    include: [],
  });

  const isLoading = isLoadingAddon || isLoadingAddonConfig;
  
  const tasksPlugin = useTasksPlugin({
    modal,
    targetType: 'addon',
    targetId: addon?.id,
  });

  function refetch() {
    refetchAddonConfig();
    refetchAddon();
  }

  if (!addon || !addonConfig) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  return (
    <AddonDetailPage
      section={section}
      modal={modal}
      cms={cms}
      refetch={refetch}
      addonConfig={addonConfig}
      addon={addon}
      tasksPlugin={tasksPlugin}
    />
  );
}
