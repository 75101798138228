import moment from 'moment-timezone';

import * as helpers from '../../helpers';
import * as actions from '../../actions';

import Section from '../section';
import ChargesMenuButton from '../menu-button_charges';
import AddChargeForm from './shared/add-charge-form';

const { date, currency, getLangText } = helpers.ui;


export default function ValetOrderCharges({
  modal,
  cms,
  valetOrder,
  fetchValetOrder,
}) {
  const { charges } = valetOrder;

  function addCharge(chargeType = null) {
    const initialValue = {
      date: moment().format('YYYY-MM-DD'),
      quantity: 1,
      desc: chargeType && getLangText(chargeType.title),
      price: chargeType?.amount,
    };

    modal.open(
      <AddChargeForm
        initialValue={initialValue}
        onSubmit={async (charge) => {
          await actions.valetOrders.charges(valetOrder.id).create({
            ...charge,
            date: moment().format('YYYY-MM-DD'),
          });

          fetchValetOrder();
        }}
        onSuccess={() => {
          modal.close();
          fetchValetOrder();
        }}
        closeModal={modal.close}
      />,
    );
  }

  async function removeCharge(event) {
    const charge = charges.find(({ id }) => id === event.currentTarget.id);
    if (!charge) return;

    const confirmed = window.confirm(`Are you sure you want to delete the charge '${charge.desc}'?`);
    if (!confirmed) return;

    await actions.valetOrders.charges(valetOrder.id).delete(charge.id);

    fetchValetOrder();
  }

  function getTotal() {
    return charges.reduce((memo, charge) => {
      return memo + (charge.price * charge.quantity);
    }, 0);
  }


  return (
    <Section
      cms={cms.block('charges')}
      extra={<ChargesMenuButton onSelect={addCharge} />}
    >
      {charges.length > 0 && (
        <table className="table no-border" style={{ fontSize: 10 }}>
          <thead>
            <tr>
              <td>Date</td>
              <td>Description</td>
              <td>Price</td>
              <td>Subtotal</td>
            </tr>
          </thead>
          <tbody>
            {charges.map((item) => {
              const subtotal = item.price * item.quantity;

              return (
                <tr
                  key={item.id}
                  id={item.id}
                  style={{ cursor: 'pointer' }}
                  onClick={removeCharge}
                >
                  <td>{date(item.date)}</td>
                  <td>
                    {item.desc}
                    {item.quantity > 1 && (
                      <span className="text-muted"> &times; {item.quantity}</span>
                    )}
                  </td>
                  <td className="text-right">{currency(item.price)}</td>
                  <td className="text-right">{currency(subtotal)}</td>
                </tr>
              );
            })}

            <tr>
              <td colSpan="3" className="text-right">Total:</td>
              <td className="text-right">{currency(getTotal())}</td>
            </tr>
          </tbody>
        </table>
      )}
    </Section>
  );
}
