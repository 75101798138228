import wurd from 'wurd-react';
import { Modal } from 'react-bootstrap';

import { useModal } from '../../../hooks';
import * as actions from '../../../actions';
import { getAccountingCodeById } from '../../../helpers/business';

import Alert from '../../alert';
import BusinessChargeForm from '../../form2/business/charge';

const cms = wurd.block('settings.accounting');

const chargeTypes = {
  unitDeposit: {},
  unitRent: {},
  unitPrepayment: { hideTax: true },
};


export default function BusinessCharges({
  settings,
}) {
  const { charges } = settings;
  const modal = useModal();

  function editCharge(event) {
    event.preventDefault();

    const { id: chargeName } = event.currentTarget;
    const charge = charges[chargeName];

    const intro = (chargeName === 'unitPrepayment') && (
      <Alert><cms.Markdown id="prepaymentWarning" /></Alert>
    );

    modal.open(
      <BusinessChargeForm
        mode="update"
        intro={intro}
        initialValue={charge}
        closeModal={modal.close}
        onSubmit={(data) => {
          const updateObj = {
            charges: {
              [chargeName]: data,
            },
          };

          return actions.business.update(updateObj)
            .then(() => {
              actions.business.fetch();
              modal.close();
            });
        }}
      />,
    );
  }

  return (
    <>
      <div>
        <table className="table no-border table-hover no-margin">
          <thead className="no-border">
            <tr>
              <cms.Text type="th" id="type" />
              <cms.Text type="th" id="code" />
              <cms.Text type="th" id="taxDesc" />
              <cms.Text type="th" id="taxPct" />
            </tr>
          </thead>
          <tbody className="no-border-x no-border-y">
            {Object.entries(chargeTypes).map(([chargeType, { hideTax }]) => {
              const charge = charges[chargeType] || {};
              const accountingCode = getAccountingCodeById(charge.accountingCodeId) || {};

              return (
                <tr
                  key={chargeType}
                  id={chargeType}
                  onClick={editCharge}
                  style={{ cursor: 'pointer' }}
                >
                  <td><strong><cms.Text id={chargeType} /></strong></td>
                  <td>{accountingCode.code}</td>
                  <td>{!hideTax && accountingCode.taxDesc}</td>
                  <td>{!hideTax && accountingCode.taxPct && `${accountingCode.taxPct}%`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Modal show={!!modal.content} onHide={modal.close}>
        {modal.content}
      </Modal>
    </>
  );
}
