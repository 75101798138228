import wurd from 'wurd-react';
import { round } from 'lodash';

import * as actions from 'actions';
import { useApiForm, useItemLoader, useListLoader } from 'hooks';
import { currency, date, href } from 'helpers/ui';

import Alert from 'components/alert';
import { Field, CurrencyInput } from 'components/form2';
import InputGroup from 'components/form2/input-group';
import DualInputField from 'components/form2/dual-input-field';
import StaticInvoiceList from 'components/invoice-list/static-list';
import JobStep from '../../job-step2';
import ConfirmFutureStep from '../../confirm-future-step';
import LinkButtonGroup, { LinkButton } from 'components/link-button-group';

const cms = wurd.block('unitJobs.moveOut.steps.confirmMovedOut');
const formWurdSection = 'jobs.unit_moveOut.confirmMovedOut';


export default function UnitMoveInJobSteps_ConfirmMovedOut({
  job,
  step,
  owner,
  site,
  unit,
  rental,
  onChange,
  modal,
}) {
  const rentalLoader = useItemLoader('unit-rentals', rental ? null : job.result.unitRentalId); // make sure rental is loaded
  rental = rental || rentalLoader.item;

  // Check for unpaid invoices
  const { items: unpaidInvoices } = useListLoader('invoices', rental && {
    unitRentalId: rental.id,
    state: 'active',
  }, { maxAge: 0 });

  // Create the form
  const { fieldProps, inputProps, submit, formValue, setFormValue, loading } = useApiForm({
    wurdSection: formWurdSection,
    onSubmit: data => actions.jobs.updateCurrentStep(job, data),
    onSuccess: onChange,
    initialValue: {
      depositRefundAmount: rental?.depositBalance || 0,
      prepaymentRefundAmount: 0,
      revenueRefundAmount: 0,
      _unusedRentDays: 0
    },
  });

  async function cancelJob() {
    const confirmed = window.confirm(cms.text('cancelJob.confirm'));
    if (!confirmed) return;
  
    await actions.jobs.cancel(job.id);

    onChange();
  }

  function render_ready() {
    const { unusedRent } = step.data;
    const { endDate } = job.result;

    const hasUnpaidInvoices = Boolean(unpaidInvoices.length);
    const hasDepositBalance = Boolean(rental?.depositBalance > 0);
    const hasPrepaymentBalance = Boolean(rental?.prepaymentBalance > 0);
    const hasUnusedRent = Boolean(unusedRent?.totalAmount > 0);

    function unusedRentAmountHandler(event) {
      const { value: revenueRefundAmount } = event.target;
      
      setFormValue({
        revenueRefundAmount,
        _unusedRentDays: round(revenueRefundAmount / unusedRent.dayAmount), //round(((newPrice / oldPrice) * 100) - 100, 2),
      });
    }
  
    function unusedRentDaysHandler(event) {
      const { value: _unusedRentDays } = event.target;
  
      setFormValue({
        _unusedRentDays,
        revenueRefundAmount: round(_unusedRentDays * unusedRent.dayAmount, 2), //getAmountForPercentIncrease(oldPrice, _pctIncrease),
      });
    }

    return (
      <JobStep
        step={step}
        title={<cms.Text id="ready.title" />}
      >
        <ConfirmFutureStep date={endDate}>
          <form onSubmit={submit}>
            {hasUnpaidInvoices && (
              <>
                <Alert type="warning" icon="far fa-file-invoice-dollar">
                  <cms.Markdown id="hasUnpaidInvoices" vars={{ numUnpaidInvoices: unpaidInvoices.length }} />
                </Alert>

                <StaticInvoiceList
                  invoices={unpaidInvoices}
                  hideOwner
                  hideUnit
                />

                <hr />
              </>
            )}

            {(hasDepositBalance || hasPrepaymentBalance) && (
              <>
                <Alert type="info" icon="far fa-hand-holding-usd">
                  <cms.Markdown id="hasAccountBalanceWarning" />
                </Alert>

                {hasDepositBalance && (
                  <Field {...fieldProps('depositRefundAmount')}>
                    <CurrencyInput
                      min="0"
                      max={rental.depositBalance}
                      after={`/ ${currency(rental.depositBalance)}`}
                    />
                  </Field>
                )}

                {hasPrepaymentBalance && (
                  <Field {...fieldProps('prepaymentRefundAmount')}>
                    <CurrencyInput
                      min="0"
                      max={rental.prepaymentBalance}
                      after={`/ ${currency(rental.prepaymentBalance)}`}
                    />
                  </Field>
                )}

                <hr />
              </>
            )}

            {hasUnusedRent && (
              <>
                <Alert type="info" icon="far fa-hand-holding-usd">
                  <cms.Markdown id="hasUnusedRentWarning" vars={{
                    startDate: date(unusedRent.startDate),
                    endDate: date(unusedRent.endDate),
                    numDays: unusedRent.numDays,
                    totalAmount: currency(unusedRent.totalAmount),
                    dayAmount: currency(unusedRent.dayAmount),
                    invoiceUrl: href(`invoices/${unusedRent.invoiceId}`),
                  }} />
                </Alert>

                <DualInputField {...fieldProps('revenueRefundAmount')}>
                  <div>
                    <CurrencyInput
                      {...inputProps('revenueRefundAmount')}
                      className="form-control"
                      required
                      min={0}
                      max={unusedRent.totalAmount}
                      after={`/ ${currency(unusedRent.totalAmount)}`}
                      onChange={unusedRentAmountHandler}
                    />
    
                    <span className="help-block">{wurd.text(`${formWurdSection}.revenueRefundAmount.amount.help`)}</span>
                  </div>
    
                  <div>
                    <InputGroup
                      {...inputProps('_unusedRentDays')}
                      className="form-control"
                      type="number"
                      step="any"
                      after="days"
                      style={{ textAlign: 'right' }}
                      min={0}
                      max={unusedRent.numDays}
                      onChange={unusedRentDaysHandler}
                    />
    
                    <span className="help-block">{wurd.text(`${formWurdSection}.revenueRefundAmount.days.help`)}</span>
                  </div>
                </DualInputField>

                <hr />
              </>
            )}
            
            <Field {...fieldProps('nextUnitState')} required>
              <select>
                <option value="">{cms.text('selectNewState')}</option>
                <option value="available">{cms.text('available')}</option>
                <option value="blocked">{cms.text('blocked')}</option>
              </select>
            </Field>

            {formValue.nextUnitState === 'blocked' &&
              <Field {...fieldProps('blockedReason')} required />
            }

            <button
              className="btn btn-block btn-success"
              onClick={submit}
              disabled={loading}
            >
              <i className={`fa ${loading ? 'fa-spinner fa-spin' : 'fa-check'}`} /> <cms.Text id="confirmJob.btn" />
            </button>

            <cms.Object id="cancelJob" keys="btn,confirm">
              <LinkButtonGroup align="center">
                <LinkButton
                  faIcon="far fa-times"
                  text={cms.text('cancelJob.btn')}
                  onClick={cancelJob}
                />
              </LinkButtonGroup>
            </cms.Object>
          </form>
        </ConfirmFutureStep>
      </JobStep>
    );
  }

  function render_completed() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" />}
      />
    );
  }

  if (step.state === 'ready') return render_ready();
  if (step.state === 'completed') return render_completed();

  return null;
}
