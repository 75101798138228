import * as helpers from '../../../helpers';

import ModalForm from '../../form2/modal-form';
import Field from '../../form2/horizontal-field';


export default (props) => (

  <ModalForm
    wurdSection="valetOrder.addBulky"
    mode="create"
    {...props}
  >
    {(getProps) => (
      <div>

        <Field {...getProps('type')} required>
          <select>
            <option value="">Select a type</option>
            {helpers.item.listBulky(true).map(itemConfig =>
              <option key={itemConfig.type} value={itemConfig.type}>{itemConfig.title}</option>
            )}
          </select>
        </Field>

        <Field {...getProps('opsTitle')} />

      </div>
    )}
  </ModalForm>
);
