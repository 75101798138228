import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import _ from 'lodash';
import classnames from 'classnames';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';


const PickStep = createClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  },

  render() {
    return (
      <ul className="list-group">
        {this.renderEmptyBoxes()}
        {this.renderStoreItems()}
      </ul>
    );
  },

  renderEmptyBoxes() {
    const { visit, t } = this.props;
    const order = this.getEmptyBoxOrder();

    return order.map(({ item, quantity }) => {
      const numLeft = helpers.visit.countEmptyBoxesLeftToAdd(visit, item.type);

      return (
        <li
          key={item.type}
          className={classnames('list-group-item clearfix', {
            'list-group-item-warning': numLeft,
            'list-group-item-success': !numLeft
          })}
          style={{ fontSize: '1.3em' }}
        >
          <img className="pull-left visits__itemtype-image" src={item.image} alt="" />
          {item.title} &times; {quantity}

          {!!numLeft &&
            <button
              type="button"
              className="btn btn-success pull-right"
              style={{ position: 'absolute', top: 8, right: 10 }}
              onClick={this.addEmptyBox}
            >
              <i className="fa fa-plus"></i>
              {t('addBtn')} ({numLeft})
            </button>
          }
        </li>
      );
    });
  },

  renderStoreItems() {
    const { visit, t } = this.props;
    const order = this.getProductOrder();

    return order.map(({ product, quantity }) => {
      const numLeft = helpers.visit.countProductsLeftToAdd(visit, product.id);

      return (
        <li
          key={product.id}
          className={classnames('list-group-item clearfix', {
            'list-group-item-warning': numLeft,
            'list-group-item-success': !numLeft
          })}
          style={{ fontSize: '1.3em' }}
        >
          <img className="pull-left visits__itemtype-image" src={product.image} alt="" />
          {product.title} &times; {quantity}

          {!!numLeft &&
            <button
              type="button"
              className="btn btn-success pull-right"
              style={{ position: 'absolute', top: 8, right: 10 }}
              onClick={this.addProduct.bind(null, product.id)}
            >
              <i className="fa fa-plus"></i>
              {t('addBtn')} ({numLeft})
            </button>
          }
        </li>
      );
    });
  },

  /**
   * Get itemConfigs and quantities for each empty box type ordered
   *
   * @return {Object[]}
   */
  getEmptyBoxOrder() {
    return _.reduce(this.props.visit.orderedItems, (memo, quantity, type) => {
      if (quantity > 0 && !helpers.item.isBulky(type)) {
        memo.push({
          item: helpers.item.get(type, true),
          quantity: quantity
        });
      }

      return memo;
    }, []);
  },

  addEmptyBox() {
    const { visit, onChange, t } = this.props;

    const itemId = prompt(t('enterBoxId'));
    if (!itemId) return;

    actions.visits.addNewBox(visit.id, itemId)
      .then(onChange);
  },

  /**
   * Get itemConfigs and quantities for each empty box type ordered
   *
   * @return {Object[]}
   */
  getProductOrder() {
    const { visit } = this.props;

    const order = visit.order || {};
    const productOrder = order.storeItems || [];

    return _.reduce(productOrder, (memo, product) => {
      if (product.quantity > 0) {
        memo.push({
          product: helpers.product.get(product.id, true),
          quantity: product.quantity
        });
      }

      return memo;
    }, []);
  },

  addProduct(productId) {
    const { visit, onChange } = this.props;

    actions.visits.addProduct(visit.id, productId)
      .then(onChange);
  }

});

export default PickStep;
