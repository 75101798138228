import wurd from 'wurd-react';

import { useState } from 'react';

import AddonConfigList from './list';
import AddonConfigDetail from './detail';

const cms = wurd.block('addons.addonConfigSelector');


export default function AddonConfigSelector({
  modal,
  site,
  addonConfigs,
  addons,
  onSelectAddonConfig,
}) {
  const [selectedAddonConfig, setSelectedAddonConfig] = useState(null);

  // Tie the installed addons to their addonConfig
  addonConfigs.forEach(addonConfig => {
    addonConfig._addon = addons.find(addon => addon.addonConfigId === addonConfig.id);
  });

  return (
    <>
      {!selectedAddonConfig && (
        <AddonConfigList
          modal={modal}
          cms={cms}
          site={site}
          addonConfigs={addonConfigs}
          onSelectItem={setSelectedAddonConfig}
        />
      )}

      {selectedAddonConfig && (
        <AddonConfigDetail
          modal={modal}
          cms={cms}
          site={site}
          addonConfig={selectedAddonConfig}
          onClickBack={() => setSelectedAddonConfig(null)}
          onClickInstall={() => onSelectAddonConfig(selectedAddonConfig)}
        />
      )}
    </>
  );
}