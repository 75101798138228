import { useModal, useHistoryListQuery, useListLoader } from '../hooks';

import SiteList from '../components/site-list';


export default function SiteListPage() {
  const modal = useModal();

  const listQuery = useHistoryListQuery();
  const listLoader = useListLoader('sites', listQuery.params, { maxAge: 0 });

  return (
    <SiteList
      modal={modal}
      listQuery={listQuery}
      listLoader={listLoader}
    />
  );
}
