import { useRef } from 'react';

import * as helpers from '../../../helpers';

import Tabs from '../../tabs';


export default function ValetOrderDetailTabs({
  items = [],
  onChange,
}) {
  const tabTopRef = useRef(null);

  function handleChange(tabId) {
    helpers.ui.scrollToEl(tabTopRef.current);

    if (onChange) onChange(tabId);
  }

  return (
    <>
      <div ref={tabTopRef} />

      <Tabs
        onChange={handleChange}
        items={items}
      />
    </>
  );
}
