import { useState } from 'react';
import wurd from 'wurd-react';
import styled from '@emotion/styled';

import { useShortcut } from '../hooks';

import SimpleTableList from './simple-table-list';
import SimpleNavList from './simple-nav-list';
import Pagination from './pagination';
import Spinner from './spinner';
import NoResults from './no-results';
import Link from 'components/nested-link';

const componentCms = wurd.block('common.tableList');

const styles = {
  selectAllCheckbox: {
    fontSize: 16,
    marginRight: 8,
  },
};

const Container = styled.div(({ stickyTop }) => ({
  '.sgc-table-header': {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    padding: '10px 10px 7px 10px',
    position: 'sticky',
    top: stickyTop,
    zIndex: 99,
    background: '#fff',
    borderBottom: '1px solid #eee',
    minHeight: 50, // for when no filters are shown

    '> .filters': {
      display: 'flex',
      alignItems: 'baseline',
      overflowY: 'overlay',
    },

    '> .btn-group': {
      marginBottom: -2,
      '.btn-group': {
        marginLeft: 0,
        marginRight: 0,
      }
    }
  },

  '.active': {
    outline: '1px auto var(--bs-gray-dark)'
  }
}));


export default function TableList({
  cms,
  listQuery,
  loader,
  getItemLink,
  onClickItem,
  renderItem,
  filters,
  onClickAdd,
  menu,
  renderBulkActions,
  getTableRowClassName,
  initialSelectedIds,
  stickyTop = 50, // default to 50 to account for the height of the main nav bar
  onCancelSelection, // optional callback for when selection mode is cancelled
  className,
}) {
  const { params } = listQuery;
  const { items, refetch, isLoading } = loader;

  const [isSelecting, setIsSelecting] = useState(initialSelectedIds ? true : false);
  const [selectedIds, setSelectedIds] = useState(new Set(initialSelectedIds));
  useShortcut(isSelecting ? 'Escape' : null, () => toggleSelectMode(false));

  function toggleSelectMode(forceValue) {
    const newValue = typeof forceValue === 'boolean' ? forceValue : !isSelecting;

    setSelectedIds(new Set());
    setIsSelecting(newValue);
  }

  function toggleSelectedId(id) {
    const set = new Set(selectedIds);

    if (set.has(id)) {
      set.delete(id);
    } else {
      set.add(id);
    }

    setSelectedIds(set);
  }

  function toggleSelectAll() {
    if (selectedIds.size !== items.length) {
      setSelectedIds(new Set(items.map((item) => item.id)));
    } else {
      setSelectedIds(new Set());
    }
  }

  function getItemById(id) {
    return items.find((i) => i.id === id);
  }

  function getSelectedItems() {
    return [...selectedIds].map(getItemById).filter((item) => item); // .filter because when updating pagination, we reload items, they're temporarily empty, and the selectedIds aren't found
  }

  function handleItemClick(event) {
    if (event.target.matches('button, button *')) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (onClickItem) {
      const { id } = event.currentTarget;

      const item = getItemById(id);
      if (!item) throw new Error(`Item ${id} not found`);
      // if a click handler is passed, prevent default link navigation
      event.preventDefault();
      onClickItem(item);
    }
  }

  function handleItemSelect(event) {
    const { id } = event.currentTarget.closest('[role=row][id]');

    toggleSelectedId(id);
  }

  function handleCancelSelection() {
    if (onCancelSelection) {
      onCancelSelection();
    } else {
      toggleSelectMode();
    }
  }

  function onActionComplete() {
    toggleSelectMode(false);
    refetch();
  }

  function proxyClickAdd() {
    onClickAdd({ refetch });
  }

  const selectedItems = isSelecting && getSelectedItems();
  const selectionInfo = {
    count: selectedIds.size,
  };

  const showPagination = Boolean(Number(params.offset) !== 0 || (items.length >= params.limit));

  const [Table, Row, Cell] = getItemLink
    ? [SimpleNavList, isSelecting ? 'label' : Link, 'div']
    : [SimpleTableList, 'tr', 'td'];

  return (
    <Container stickyTop={stickyTop} className="tab-content no-padding">
      <div className="sgc-table-header">
        {!isSelecting
          ? (
            <>
              <div className="filters">{filters}</div>      

              <div className="btn-group" style={{ marginRight: menu ? -10 : -4 }}>
                {selectedItems && renderBulkActions({ selectedItems, onActionComplete })}

                {onClickAdd && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={proxyClickAdd}
                    title={componentCms.text('addBtn')}
                  >
                    <i className="fas fa-plus" />
                  </button>
                )}

                {renderBulkActions && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={toggleSelectMode}
                    title={componentCms.text('multiSelectBtn')}
                  >
                    <i className={`${isSelecting ? 'fas' : 'far'} fa-check-square`} />
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-link"
                  onClick={refetch}
                  disabled={isLoading}
                  title={componentCms.text('refreshBtn')}
                >
                  <i className={`fas fa-sync-alt ${isLoading ? 'fa-spin' : ''}`} />
                </button>

                {menu}
              </div>
            </>
          ) : (
            <>
              <div className="filters">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedIds.size === items.length}
                  onChange={toggleSelectAll}
                  style={styles.selectAllCheckbox}
                />

                &nbsp;&nbsp;
                <componentCms.Text id="multiSelect.numSelected" vars={selectionInfo} />

                <button
                  type="button"
                  className="btn btn-link"
                  onClick={handleCancelSelection}
                >
                  <componentCms.Text id="multiSelect.cancel" vars={selectionInfo} />
                </button>
              </div>

              <div className="btn-group">
                {selectedItems && renderBulkActions({ selectedItems, onActionComplete })}
              </div>
            </>
          )}
      </div>

      {!items.length
        ? (isLoading ? <Spinner /> : <NoResults cms={cms} onClickAdd={onClickAdd} />)
        : (
          <>
            <Table className={className}>
              {items.map((item) => {
                const isSelected = isSelecting && selectedIds.has(item.id);
                const className = isSelected ? 'info' : (getTableRowClassName && getTableRowClassName(item));

                return (
                  <Row
                    key={item.id}
                    role="row"
                    id={item.id}
                    to={getItemLink?.(item)}
                    onClick={handleItemClick}
                    className={className}
                  >
                    {isSelecting && (
                      <Cell role="cell" className="selectCheckboxCol">
                        <input type="checkbox" checked={isSelected} onChange={handleItemSelect} />
                      </Cell>
                    )}
                    {renderItem(item)}
                  </Row>
                );
              })}
            </Table>

            {showPagination && (
              <Pagination
                limit={params.limit || 50}
                offset={params.offset || 0}
                count={items.length}
                onChange={listQuery.update}
              />
            )}
          </>
        )
      }
    </Container>
  );
}
