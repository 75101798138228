import { pick } from 'lodash';
import { Fragment } from 'react';
import wurd, { WurdText } from 'wurd-react';

import store from '../../store';
import * as actions from '../../actions';
import { useApiForm } from '../../hooks';

import Section from '../section';
import { Checklist } from '../form2';


export default function UserAdminEmails({
  user,
}) {
  const { modes } = store.get('settings');

  const emails = {
    general: [
      'general_admin_newUser',
      'general_admin_invoiceFailed',
    ],
    valet: modes.includes('valetStorage') && [
      'valetOrder_admin_orderCreated',
      'valetOrder_admin_orderUpdated',
      'valetOrder_admin_orderCancelled',
      'valetOrder_admin_failedDelivery',
      'valetOrder_admin_failedCollection',
      'valetOrder_admin_assigned',
    ],
    units: modes.includes('selfStorage') && [
      'unitMoveIn_admin_unitReserved',
      'unitMoveIn_admin_unitOccupied',
    ],
    comments: [
      'general_admin_comment_user',
      'general_admin_comment_item',
      'general_admin_comment_invoice',
      'general_admin_comment_valetOrder',
      'general_admin_comment_unit',
    ],
  }

  const cms = wurd.block('userView.adminEmails')

  const { fieldProps, submit, loading, dirty } = useApiForm({
    initialValue: pick(user, ['adminEmails']),
    onSubmit: data => actions.users.update(user.id, data),
  });


  return (
    <Section cms={cms}>
      {Object.entries(emails).map(([section, emails]) => {
        if (!emails) return null;

        return (
          <Fragment key={section}>
            <h4><cms.Text id={section} /></h4>
            <Checklist {...fieldProps('adminEmails')}>
              {emails.map(email => (
                <cms.Text key={email} id={email} />
              ))}
            </Checklist>
          </Fragment>
        );
      })}

      <button
        className="btn btn-primary pull-right"
        type="button"
        disabled={loading || !dirty}
        onClick={submit}
      >
        {loading
          ? <i className="fa fa-spinner fa-spin" />
          : <WurdText id="common.saveBtn" />
        }
      </button>
    </Section>
  );
}
