import { useEffect } from 'react';
import DynamicLoader from 'components/dynamic-loader';
import { useRoutes, useNavigate, useLocation, useParams } from 'react-router-dom';

import store from './store';
import { hasRole } from 'helpers/auth';

import App_LoggedIn from './components/app_logged-in';

import Login from './components/login';
import ForgotPassword from './components/forgot-password';
import ResetPassword from './components/reset-password';
import Dashboard from './components/dashboard';
import Analytics, { showAnalytics } from './components/analytics';
import VisitList from './components/visit-list';
import VisitDetail from './components/visit-detail';
import UserListPage from './pages/user-list';
import UserDetailPage from './pages/user-detail';
import ItemListPage from './pages/item-list';
import ItemDetailPage from './pages/item-detail';
import InvoiceListPage from './pages/invoice-list';
import InvoiceDetailPage from './pages/invoice-detail';
import UnitListPage from './pages/unit-list';
import UnitDetailPage from './pages/unit-detail';
import UnitRentalListPage from './pages/unit-rental-list';
import UnitRentalDetailPage from './pages/unit-rental-detail';
import UnitTypeListPage from './pages/unit-type-list';
import UnitTypeDetailPage from './pages/unit-type-detail';
import SiteListPage from './pages/site-list';
import SiteDetailPage from './pages/site-detail';
import SitemapPage from './pages/sitemap';
import MasterJobListPage from './pages/master-job-list';
import MasterJobDetailPage from './pages/master-job-detail';
import UnitJobDetailPage from './pages/unit-job-detail';
import ValetOrderListPage from './pages/valet-order-list';
import ValetOrderDetailPage from './pages/valet-order-detail';
import MainSettingsPage from './pages/settings';
import MarketSettingsPage from './pages/settings-market';
import PaymentListPage from 'pages/payment-list';
import AddonConfigListPage from 'pages/addon-config-list';
import AddonConfigDetailPage from 'pages/addon-config-detail';
import AddonDetailPage from 'pages/addon-detail';


function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const { settings } = store.get();
  const modes = settings.modes || [];
  useEffect(() => {
    // Handle old hash-routing
    if (location.hash.startsWith('#/')) {
      return navigate(location.hash.slice(1));
    }

    // Redirect for selfStorage businesses
    if (modes.includes('selfStorage') && hasRole('admin')) {
      return navigate('sites', { replace: true });
    }

    // Redirect for valet businesses
    if (modes.includes('valetStorage')) {
      if (hasRole('admin')) {
        navigate('dashboard', { replace: true });
      } else {
        navigate('valet-orders', { replace: true });
      }
    }
  }, [navigate]);

  return null;
}


export default function AppRouter() {
  const element = useRoutes([
    {
      path: '/', element: <App_LoggedIn />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'analytics/:id', element: <Analytics /> },
        { path: 'visits', element: <VisitList /> },
        { path: 'visits/:id', element: <VisitDetail /> },
        { path: 'users', element: <UserListPage /> },
        { path: 'users/:id', element: <UserDetailPage /> },
        { path: 'users/:id/:section', element: <UserDetailPage /> },
        { path: 'items', element: <ItemListPage /> },
        { path: 'items/:idOrSid', element: <ItemDetailPage /> },
        { path: 'invoices', element: <InvoiceListPage /> },
        { path: 'invoices/:idOrSid', element: <InvoiceDetailPage /> },
        { path: 'payments', element: <PaymentListPage /> },
        { path: 'units', element: <UnitListPage /> },
        { path: 'unit-rentals', element: <UnitRentalListPage /> },
        { path: 'unit-rentals/:id', element: <UnitRentalDetailPage /> },
        { path: 'unit-rentals/:id/:section', element: <UnitRentalDetailPage /> },
        { path: 'unit-types', element: <UnitTypeListPage /> },
        { path: 'unit-types/:id', element: <UnitTypeDetailPage /> },
        { path: 'unit-types/:id/:section', element: <UnitTypeDetailPage /> },
        { path: 'units/:id', element: <UnitDetailPage /> },
        { path: 'units/:id/:section', element: <UnitDetailPage /> },
        { path: 'sites', element: <SiteListPage /> },
        { path: 'sites/:idOrCode/jobs/:idOrSid', element: <UnitJobDetailPage /> },
        { path: 'sites/:idOrCode/jobs/:idOrSid/:section', element: <UnitJobDetailPage /> },
        { path: 'sites/:idOrCode/units/:id', element: <UnitDetailPage /> },
        { path: 'sites/:idOrCode/units/:id/:section', element: <UnitDetailPage /> },
        { path: 'sites/:idOrCode/unit-types/:id', element: <UnitTypeDetailPage /> },
        { path: 'sites/:idOrCode/unit-types/:id/:section', element: <UnitTypeDetailPage /> },
        { path: 'sites/:idOrCode', element: <SiteDetailPage /> },
        { path: 'sites/:idOrCode/:section', element: <SiteDetailPage /> },
        { path: 'sites/:idOrCode/:section/:sectionId', element: <SiteDetailPage /> },
        { path: 'sites/:idOrCode/sitemap', element: <SitemapPage /> },
        { path: 'jobs', element: <MasterJobListPage /> },
        { path: 'jobs/:id', element: <MasterJobDetailPage /> },
        { path: 'valet-orders', element: <ValetOrderListPage /> },
        { path: 'valet-orders/:id', element: <ValetOrderDetailPage /> },
        { path: 'valet-orders/:id/:section', element: <ValetOrderDetailPage /> },
        { path: 'settings/markets/:marketName', element: <MarketSettingsPage /> },
        { path: 'settings/markets/:marketName/:section', element: <MarketSettingsPage /> },
        { path: 'settings', element: <MainSettingsPage /> },
        { path: 'settings/:section', element: <MainSettingsPage /> },
        { path: 'settings/:section/:sectionId', element: <MainSettingsPage /> },
        { path: 'addon-configs', element: <AddonConfigListPage /> },
        { path: 'addon-configs/:id', element: <AddonConfigDetailPage /> },
        { path: 'addon-configs/:id/:section', element: <AddonConfigDetailPage /> },
        { path: 'addons/:id', element: <AddonDetailPage /> },
        { path: 'addons/:id/:section', element: <AddonDetailPage /> },
        { path: '', element: <Home /> },
      ]
    },
    {
      path: '/',
      children: [
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        ...process.env.REACT_APP_DEV_STORIES ? [{ path: 'stories/*', element: <Stories /> }] : [],
        { path: '*', element: <Login />  }
      ]
    }
  ]);
  return element;
}


function Stories(props) {
  const params = useParams();

  const Component = DynamicLoader(() => import('./components/' + params['*'] + '.stories.js'));
  return <Component />;
}