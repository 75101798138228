import wurd from 'wurd-react';
import _ from 'lodash';

import * as actions from 'actions';

import Section from 'components/section';
import SectionCrudList from 'components/section_crud-list';
import SettingsForm2 from '../form2';
import Input from '../input';
import WebhookForm from './webhook-form';
import ApiKeyForm from './api-key-form';

const cms = wurd.block('settings');


export default function DeveloperSettings({ settings }) {
  return (
    <>
      <SectionCrudList
        cms={cms.block('apiKeys')}
        items={settings.apiKeys}
        Form={ApiKeyForm}
        actions={actions.business.apiKeys}
        onChange={actions.business.fetch}
      >
        {apiKey => (
          <>
            <div><strong>{apiKey.name}</strong></div>
            <div className="text-muted">{apiKey.roles?.join(', ')} {apiKey.permissions?.join(', ')}</div>
          </>
        )}
      </SectionCrudList>

      <SectionCrudList
        cms={cms.block('webhooks')}
        items={settings.webhooks}
        Form={WebhookForm}
        actions={actions.business.webhooks}
        onChange={actions.business.fetch}
      >
        {webhook => {
          return (
            <>
              <div><strong>{webhook.url}</strong></div>
              <div className="text-muted">{webhook.events.join(', ')}</div>
            </>
          );
        }}
      </SectionCrudList>

      <Section cms={cms.block('userApp')}>
        <SettingsForm2
          wurdSection="settings.inputs"
          initialValue={settings}
          onSubmit={(data, dataPatches) => actions.business.update(dataPatches)}
        >
          {({ fieldProps }) => (
            <>
              <Input {...fieldProps('segment.id')} type="secret" />

              <Input {...fieldProps('gtm.id')} type="secret" />

              <Input {...fieldProps('ga.id')} type="secret" />

              <Input {...fieldProps('apple.verification')} type="textarea" />{/*we'd need to combine secret & texrea*/}
            </>
          )}
        </SettingsForm2>
      </Section>

      <Section cms={cms.block('customCsp')}>
        <SettingsForm2
          wurdSection="settings.inputs"
          initialValue={_.mapValues(settings.customCsp, v => v.join('\n'))}
          onSubmit={(data) => actions.business.update({ customCsp: _.mapValues(data, v => v.split('\n').map(s => s.trim()).filter(Boolean)) })}
        >
          {({ fieldProps }) => (
            <>
              <Input {...fieldProps('connectSrc')} label="connect-src" type="textarea" />
              <Input {...fieldProps('fontSrc')} label="font-src" type="textarea" />
              <Input {...fieldProps('frameSrc')} label="frame-src" type="textarea" />
              <Input {...fieldProps('scriptSrc')} label="script-src" type="textarea" />
              <Input {...fieldProps('styleSrc')} label="style-src" type="textarea" />
            </>
          )}
        </SettingsForm2>
      </Section>
    </>
  );
}
