import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import { useModal, useItemLoader } from '../hooks';

import NotFound from '../components/not-found';
import Spinner from '../components/spinner';
import UnitDetailInnerPage from '../components/unit-detail/page';


export default function UnitRentalDetailPage() {
  const { id, section } = useParams();

  const modal = useModal();

  const { item: unitRental, isLoading, refetch } = useItemLoader('unit-rentals', id, {
    include: ['unit', 'customFields', 'agreementUrl', 'activeJobs'],
    maxAge: 0,
  });
  
  if (!unitRental) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  const cachedUnit = actions.fetchOneCached('units', unitRental.unit?.id)?.data;

  const unit = unitRental.unit && {
    ...cachedUnit || unitRental.unit, // read first from cache because if the unit is updated only the cache is updated
    customFields: cachedUnit?.customFields || unitRental.unit.customFields,
    state: unitRental.unit.state,
  };

  return (
    <UnitDetailInnerPage
      unit={unit}
      rental={unitRental}
      fetchUnit={refetch}
      modal={modal}
      section={section}
    />
  );
}
