/**
 * An input with labels, for a horizontal form
 */
import Input from './input';


const HorizontalInput = props => (

  <Input
    labelClassName="col-sm-3"
    wrapperClassName="col-sm-9"
    {...props}
  />

);

export default HorizontalInput;
