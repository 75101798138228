import _ from 'lodash';

import * as settingsHelpers from './settings';
import * as language from '../utils/language';
import { getLangText } from './ui';


function doTranslate(itemConfig, lang) {
  const obj = Object.assign({}, itemConfig);

  ['title'].forEach(prop => {
    obj[prop] = getLangText(obj[prop], lang);
  });

  return obj;
}


/**
 * Returns the list of types
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object[]}
 */
export const list = function (translate) {
  const configs = settingsHelpers.get().unitTags;

  if (translate) {
    const lang = language.get();

    return configs.map(config => doTranslate(config, lang));
  } else {
    return configs;
  }
};


/**
 * Returns an object of item types, keyed by type
 *
 * @param {Boolean} [translate]   Whether to process the multilang text attributes, setting to the current user's language
 *
 * @return {Object}
 */
export const byId = function (translate) {
  return _.keyBy(list(translate), 'id');
};
