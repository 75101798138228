import { useState } from 'react';
import { Dropdown, MenuItem, Modal } from 'react-bootstrap';
import wurd, { WurdText, WurdObject } from 'wurd-react';
import { useModal } from 'hooks';
import UnitPromoForm from '../business/unit-promo';

import * as helpers from '../../../helpers';
import LangText from 'components/lang-text';
import { getLangText } from 'helpers/ui';


const buttonStyles = {
  width: '100%',
  height: '100%',
  textAlign: 'left',
  display: 'inline-flex',
  alignItems: 'center',
  padding: '0 6px',
  margin: 0,
  border: 'none',
  boxShadow: 'none',
  backgroundImage: 'none',
  backgroundColor: 'transparent',
  cursor: 'default',
};

export default function UnitPromoField({
  name,
  value,
  customPromo: customPromoInitialValue,
  onChange,
  promos,
  field = 'code', /*'code'|'id'*/
  cms = wurd.block('unit.promoField'),
}) {
  const settings = helpers.settings.get();

  const modal = useModal();
  const [promoCodeOrId, _setPromoCodeOrId] = useState(value);
  const [customPromo, _setCustomPromo] = useState(customPromoInitialValue);

  const selectedPromo = settings.unitPromos.find(promo => promo[field] === promoCodeOrId);

  function setPromoCode(promoCodeOrId) {
    _setPromoCodeOrId(promoCodeOrId);
    onChange({ target: { name, value: promoCodeOrId } });
  }

  async function setCustomPromo(customPromo) {
    _setCustomPromo(customPromo);
    onChange({ target: { name: 'customPromo', value: customPromo } });
  }

  function showCustomPromoModal() {
    modal.open(
      <UnitPromoForm
        initialValue={customPromo}
        onSubmit={setCustomPromo}
        onSuccess={modal.close}
        closeModal={modal.close}
        mode="create"
        title={<WurdText id="unit.customPromo.modal.title_create" vars={customPromo} type="h4" className="modal-title" />}
        extraFields={[]}
        settings={settings}
      />
    )
  }

  return (
    <cms.Object
      type="div"
      id={name}
      keys="label,noPromos,createCustomPromo"
      className="form-group form-horizontal"
    >
      <label className="control-label col-sm-3" htmlFor={name}>
        {cms.text(`${name}.label`)}
      </label>
      <div className="col-sm-9" style={{ display: 'flex' }}>
        <Dropdown id="unit-promo-picker" className="form-control" style={{ width: '100%', margin: 0, padding: 0 }}>
          <Dropdown.Toggle style={buttonStyles} noCaret className='mb-0'>
            <LangText obj={selectedPromo?.title || null} />
            <i className="far fa-angle-down" style={{ fontSize: 15, position: 'absolute', right: 0 }} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem eventKey="none" onClick={() => setPromoCode(null)}><span className="text-muted">{cms.text(`${name}.noPromos`)}</span></MenuItem>
            {promos.map(promo => (
              <MenuItem
                key={promo.id}
                eventKey={promo.id}
                active={promoCodeOrId === promo[field]}
                onClick={() => setPromoCode(promo[field])}
              >
                {getLangText(promo.title)} [{promo.code}]
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 16, border: '1px solid #ccc' }}>
          {customPromo
            ? (
              <>
                <button type="button" className="btn-link" style={{ color: 'inherit', whiteSpace: 'nowrap', outline: 'none' }} onClick={showCustomPromoModal}>
                  <LangText obj={customPromo.title} />
                </button>
                <button type="button" className="btn-link" onClick={() => setCustomPromo(null)}>
                  <i className="far fa-times" />
                </button>
              </>
            ) : (
              <button type="button" className="btn-link" style={{ whiteSpace: 'nowrap', outline: 'none' }} onClick={showCustomPromoModal}>
                <cms.Text id={`${name}.createCustomPromo`} className="text-muted" /> <i className="far fa-plus" />
              </button>
            )}
        </div>
      </div>

      {modal && (
        <Modal show={!!modal.content} onHide={modal.close}>
          {modal.content}
        </Modal>
      )}
    </cms.Object>
  );
}
