import { useState } from 'react';

import wurd from 'wurd-react';
import styled from '@emotion/styled';

import ItemLoader from '../../loaders/item';
import Item from './item';
import DynamicLoader from 'components/dynamic-loader';
import Spinner from 'components/spinner'
import ErrorMessage from 'components/error-message';

const cms = wurd.block('valetOrder.scanItems');

const QrReader = DynamicLoader(() => import('react-qr-reader'), <Spinner />);

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 8,
});

const List = styled('div')({
  overflow: 'auto',
  whiteSpace: 'nowrap',
});

export default function ScanItemsForm({
  scanProcessButtons,
  receiveScannedSids,
  closeModal,
}) {
  const [codes, setCodes] = useState([]);
  const [err, setErr] = useState('');

  async function handleAddItem(scannedCode) {
    if (!scannedCode) return; // Exit if no code detected

    scannedCode = scannedCode.toLowerCase(); // Normalise

    if (codes.some((code) => code === scannedCode)) return; // Exit if it is already in the list

    setCodes((codes) => [scannedCode, ...codes]);
    setErr(null);

    if (navigator.vibrate) navigator.vibrate(300);
  }

  function handleError(err) {
    setErr(err.message);
  }

  async function handleRemove(event) {
    const { sid } = event.currentTarget.dataset;

    setCodes((codes) => codes.filter((code) => code !== sid));
  }

  function addItem() {
    const itemSids = window.prompt(cms.text('enterItemId'));
    if (!itemSids) return;

    itemSids.split(',').forEach(handleAddItem);
  }

  function onClickFinish() {
    receiveScannedSids(codes);
    closeModal();
  }

  return (
    <>
      <div className="modal-header">
        <button type="button" className="close" onClick={closeModal}>&times;</button>
      </div>

      <div style={{ padding: '16px 24px' }}>
        <QrReader style={{ marginBottom: 20 }} onError={handleError} onScan={handleAddItem} />

        {err && (
          <ErrorMessage>{err}</ErrorMessage>
        )}

        <Container>
          <h4 style={{ margin: 0, textAlign: 'left', marginBottom: 8, fontWeight: 500 }}>
            <cms.Text id="items" vars={{ count: codes.length }} />
          </h4>
          <button
            type="button"
            className="btn btn-link"
            onClick={addItem}
          >
            <i className="fa fa-plus" />
          </button>
        </Container>

        <Container>
          <List>
            {codes.length === 0 && <cms.Text id="noItems" />}

            {codes.map((code) => (
              <ItemLoader
                key={code}
                id={code}
                loading={<Item item={{ sid: code }} onDelete={handleRemove} />}
                fallback={<Item item={{ sid: code }} onDelete={handleRemove} />}
              >
                {(item) => (
                  <Item item={item} onDelete={handleRemove} scanned />
                )}
              </ItemLoader>
            ))}

          </List>
        </Container>

        <button
          type="submit"
          className="btn btn-success"
          style={{ marginTop: 12, backgroundColor: '#60C060', color: '#FFF', width: '100%' }}
          onClick={onClickFinish}
        >
          <i className="fa fa-fw fa-check" /> Finish
        </button>

        {scanProcessButtons}
      </div>
    </>
  );
}
