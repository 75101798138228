import wurd from 'wurd-react';

import ModalForm2 from '../../form2/modal-form2';
import { Field, UserSelect } from '../../form2';


const cms = wurd.block('itemView.forms.assign');

export default function ItemStoreForm({ initialValue, fields, ...rest }) {
  return (
    <ModalForm2
      wurdSection={cms.id()}
      mode="create"
      className="form2"
      initialValue={{ state: 'storage', ...initialValue }}
      {...rest}
    >
      {({ fieldProps, formValue }) => (
        <>
          <Field {...fieldProps('ownerId')} required autoFocus>
            <UserSelect />
          </Field>

          <Field {...fieldProps('state')} required>
            <select>
              {['storage', 'user'].map((state) => (
                <option key={state} value={state}>{wurd.text(`item._states.${state}`)}</option>
              ))}
            </select>
          </Field>

          <Field
            {...fieldProps('location')}
            required={formValue.state === 'storage'}
          />
        </>
      )}
    </ModalForm2>
  );
}
