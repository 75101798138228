import styled from '@emotion/styled';

const Wrapper = styled.div({
  '@media (max-width: 768px)': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#f6f6f6',
    padding: '5px 4px 0 4px',
    borderTop: '1px solid #eee',
    zIndex: 100,
  },
});


export default function MobileBottomButtons({
  children,
}) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}
