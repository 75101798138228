import wurd from 'wurd-react';

import { useModal, useHistoryListQuery } from '../hooks';

import Page from '../components/page';
import ValetOrderList from '../components/valet-order-list';

const cms = wurd.block('valetOrderList');

const initialParams = {
  state: 'submitted,started',
};


export default function ValetOrderListPage() {
  const modal = useModal();
  const listQuery = useHistoryListQuery({ initialParams });

  return (
    <Page
      titleKey={cms.id('title')}
      userRole="driver"
      modal={modal}
    >
      <ValetOrderList
        listQuery={listQuery}
        modal={modal}
      />
    </Page>
  );
}
