import { useEffect } from 'react';
import wurd, { WurdText } from 'wurd-react';
import { useQuery } from 'react-query';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import * as helpers from 'helpers';
import * as actions from '../../actions';
import * as config from '../../config';
import Spinner from '../spinner';
import Form from './form';


const cms = wurd.block('auth.login');

export default function LoginPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const userQuery = useQuery('user', actions.auth.fetchUser);

  useEffect(() => {
    if (userQuery.data) navigate('/', { replace: true });
  }, [!!userQuery.data]);

  useEffect(() => {
    if (searchParams.get('email-confirmed')) {
      helpers.ui.notify({
        text: <><i className="fa fa-check" /> <WurdText id="auth.login.emailConfirmed" /></>,
        bsType: 'success',
        duration: wurd.editMode ? 0 : 5000,
        onHide: () => setSearchParams([...searchParams].filter(([k]) => k !== 'email-confirmed')),
      });
    }
  }, [searchParams]);

  function onLogin(user) {
    navigate('/');
  }

  return (
    <div className="sg-auth-login login-container logged-out-page">
      <div className="middle-login">
        <section className="block-flat">
          <div className="header text-center">
            <h3 className="brand"><img src={`${config.publicUrl}/logo.png`} alt="Storeganise" /></h3>
          </div>
          <div className="content" style={{ paddingBottom: 20 }}>
            <Form onLogin={onLogin} err={userQuery.error} />
          </div>
        </section>
      </div>
    </div>
  );
}