import wurd from 'wurd-react';
import moment from 'moment-timezone';

import * as helpers from '../../helpers';

import ListFilter from '../filter/list';
import UserFilter from '../filter/user';
import StateLabel from '../state-label';
import SiteFilter from '../filter/site';
import SearchFilter from '../filter/search';
import UnitFilter from '../filter/unit';
import DateFilter from '../filter/date';

const cms = wurd.block('unitView.rentals.filters');

const { stateLabelTypes } = helpers.unit;


export default function UnitListFilters({
  listQuery,
  hideSite,
  hideUnit,
  hideState,
  hideOwner,
}) {
  const { params } = listQuery;

  function onChange(key, value) {
    listQuery.update({
      offset: 0,
      [key]: value?.start
        ? [value.start, value.end].map(v => moment(v).format('YYYY-MM-DD')).join(',')
        : value,
    });
  }

  const setSiteId = (val) => onChange('siteId', val);
  const setUnitId = (val) => onChange('unitId', val);
  const setState = (val) => onChange('state', val);
  const setOwnerId = (val) => onChange('ownerId', val);
  const setStartDate = (val) => onChange('start', val);
  const setEndDate = (val) => onChange('end', val);

  const startDate = params.start
    ? { start: params.start.split(',')[0], end: params.start.split(',')[1] }
    : null;

  const endDate = params.end
    ? { start: params.end.split(',')[0], end: params.end.split(',')[1] }
    : null;

  return (
    <>
      {!hideSite && helpers.settings.get().sites?.length > 0 && (
        <SiteFilter
          active={params.siteId}
          onSelect={setSiteId}
        />
      )}

      {!hideUnit && (
        <UnitFilter
          active={params.unitId}
          onSelect={setUnitId}
          siteId={params.siteId}
        />
      )}

      {!hideState && (
        <cms.Object id="state" keys={`title,all,${helpers.unitRental.states.join(',')}`}>
          <ListFilter
            title={cms.text('state.title') || 'State'}
            active={params.state}
            onSelect={setState}
            multi
          >
            {helpers.unitRental.states.map((state) => (
              <li key={state} value={state}>
                <StateLabel type={stateLabelTypes[state]}>{cms.text(`state.${state}`) || wurd.text(`unitRental._states.${state}`)}</StateLabel>
              </li>
            ))}
          </ListFilter>
        </cms.Object>
      )}

      {!hideOwner && (
        <UserFilter
          active={params.ownerId}
          onSelect={setOwnerId}
        />
      )}

      <cms.Object id="startDate" keys="title">
        <DateFilter
          title={cms.text('startDate.title')}
          range={startDate}
          onSelect={setStartDate}
        />
      </cms.Object>

      <cms.Object id="endDate" keys="title">
        <DateFilter
          title={cms.text('endDate.title')}
          range={endDate}
          onSelect={setEndDate}
        />
      </cms.Object>

      <SearchFilter
        name="unit-rental-search"
        cms={wurd.block('unitView.rentals.search')}
        active={params.search}
        onSelect={(val) => onChange('search', val)}
        modelName="unitRental"
      />
    </>
  );
}
