import wurd, { WurdText } from 'wurd-react';

import { ModalForm, Field, MultiLangText } from '../../form2';


const cms = wurd.block('settings.customFields');

const gridStyles = columns => ({
  display: 'grid',
  gridTemplateColumns: Array.from({ length: columns }, () => '1fr').join(' '),
  gridGap: '0 1rem',
});


const Input = ({ wurdSection, include, ...props }) => <input {...props} />;

export default function CustomFieldsForm(props) {
  const TYPES = ['string', 'email', 'tel', 'url', 'date', ...props.allowFiles ? ['file'] : [], 'number', 'integer', 'boolean'];

  return (
    <ModalForm
      wurdSection={cms.id()}
      {...props}
      initialValue={{
        type: 'string',
        ...props.initialValue,
        ...Array.isArray(props.initialValue?.enum) && { enum: props.initialValue.enum.join('\n') },
      }}
      submitButton={<WurdText id={`common.modalForm.${props.mode}`} />}
    >
      {(getProps, formValue) => (
        <>

          <Field {...getProps('title')} required>
            <MultiLangText />
          </Field>

          <Field {...getProps('code')} required />

          <Field {...getProps('type')} required wurdKeys={`label,help,${TYPES}`}>
            <select>
              {TYPES.map(type => <option key={type} value={type}>{cms.text(`type.${type}`)}</option>)}
            </select>
          </Field>

          {['string', 'tel'].includes(formValue.type) && (
            <>
              <Field wurdKeys="label,placeholder" {...getProps('enum', true)}>
                <textarea rows="5" />
              </Field>

              <cms.Object
                type="div"
                keys="stringParams.label,minLength.placeholder,maxLength.placeholder,pattern.placeholder"
                className="form-group form-horizontal clearfix"
              >
                <label className="control-label col-sm-3">{cms.text('stringParams.label')}</label>
                <div className="col-sm-9" style={gridStyles(3)}>
                  <Input className="form-control" {...getProps('minLength', true)} name="minLength" type="number" min="0" />
                  <Input className="form-control" {...getProps('maxLength', true)} name="maxLength" type="number" min="0" />
                  <Input className="form-control" {...getProps('pattern', true)} name="pattern" />
                </div>
              </cms.Object> 
            </>
          )}

          {['number', 'integer'].includes(formValue.type) && (
            <cms.Object
              type="div"
              keys="numberParams.label,minimum.placeholder,maximum.placeholder"
              className="form-group form-horizontal clearfix"
            >
              <label className="control-label col-sm-3">{cms.text('numberParams.label')}</label>
              <div className="col-sm-9" style={gridStyles(2)}>
                <Input className="form-control" {...getProps('minimum', true)} name="minimum" type="number" />
                <Input className="form-control" {...getProps('maximum', true)} name="maximum" type="number" />
              </div>
            </cms.Object> 
          )}

          <hr />

          <Field {...getProps('help')}>
            <MultiLangText />
          </Field>
          <Field {...getProps('placeholder')} />
          <Field {...getProps('ownerAcl')} wurdKeys="label,help,options.null,options.read,options.readCreate,options.readCreateUpdate">
            <select>
              <option value="">{cms.text('ownerAcl.options.null')}</option>
              <option value="read">{cms.text('ownerAcl.options.read')}</option>
              <option value="read,create">{cms.text('ownerAcl.options.readCreate')}</option>
              <option value="read,create,update">{cms.text('ownerAcl.options.readCreateUpdate')}</option>
            </select>
          </Field>

          {formValue.ownerAcl?.includes('create') && (
            <Field {...getProps('required')} type="checkbox" />
          )}

          {formValue.type !== 'file' && <Field {...getProps('secret')} type="checkbox" />}
          <Field {...getProps('readOnly')} type="checkbox" />
          <Field {...getProps('order')} type="number" step="1" />
        </>
      )}
    </ModalForm>
  );
}
