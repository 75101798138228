import wurd from 'wurd-react';
import styled from '@emotion/styled';
import Field from '../field';

const Dimensions = styled.div({
  display: 'flex',
  alignItems: 'center',
  'input': {
    width: 70
  },
});

export default function ({ site, inputProps, getProps, errs }) {
  const dimensionProps = name => ({
    ...inputProps(name), 
    placeholder: name,
    type: 'number',
    step: .1,
    className: 'form-control',
    required: true
  });

  return (
    <>
      <Field name="width" required error={['width', 'length', 'height'].map(k => errs[k]).find(Boolean)} cms={wurd.block('unit')}>
        <Dimensions>
          <input {...dimensionProps('width')} autoFocus />&nbsp;&nbsp;&times;&nbsp;&nbsp;
          <input {...dimensionProps('length')} />&nbsp;&nbsp;&times;&nbsp;&nbsp;
          <input {...dimensionProps('height')} />&nbsp;&nbsp;
          <span className="text-muted">{site.measure}</span>
        </Dimensions>
      </Field>
      <Field {...getProps('floor')} />

      <Field {...getProps('names')} required />
    </>
  );
}