import { cloneElement } from 'react';
import wurd from 'wurd-react';

export default function Field({
  name,
  id = name,
  error,
  children = <input />,
  required,
  cms,
  ...props
}) {
  return (
    <div className={`form-group ${error ? 'has-error' : ''}`}>
      <label htmlFor={id} className={`control-label ${children.props.required || required ? 'required' : ''}`}>
        <cms.Text id={`${name}.label`} />
        {error && <span style={{ marginLeft: '1rem' }}>({error})</span>}
      </label>
      {!props.onChange
        ? children
        : cloneElement(
          children, {
            id,
            name,
            ...props,
            className: children.props.className || 'form-control',
            placeholder: children.props.placeholder || cms.text(`${name}.placeholder`) || null,
          }
        )
      }
      {wurd.editMode && <cms.Text id={`${name}.placeholder`} />}
      <cms.Markdown id={`${name}.help`} className="help-block" />
    </div>
  );
}