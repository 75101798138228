import crudList from '../../actions/plugins/crud-list';


const strToNumOrNull = str => typeof str !== 'string' ? str : str === '' ? null : +str;

function formatData(data) {
  if (data.order && typeof data.order === 'string') {
    data.order = Number(data.order);
  }

  switch (data.type) {
    case 'number':
    case 'integer':
      return {
        ...data,
        minimum: strToNumOrNull(data.minimum),
        maximum: strToNumOrNull(data.maximum),
      };
    case 'string':
      return {
        ...data,
        enum: data.type === 'string' && typeof data.enum === 'string'
          ? (data.enum === '' ? null : data.enum.split('\n').filter(Boolean))
          : undefined,
        minLength: strToNumOrNull(data.minLength),
        maxLength: strToNumOrNull(data.maxLength),
      };
    default:
      return data;
  }
}


export default function (endpoint) {
  const handlers = crudList(endpoint);

  return {
    ...handlers,

    create(data) {
      return handlers.create(formatData(data));
    },

    update(itemId, data) {
      return handlers.update(itemId, formatData(data));
    }
  }
}
