import crudList from './plugins/crud-list';


const url = `/v1/admin/addon-configs`;

const actions = {};

actions.webhooks = function (addonConfigId) {
  return crudList(`${url}/${addonConfigId}/webhooks`);
};

actions.webtasks = function (addonConfigId) {
  return crudList(`${url}/${addonConfigId}/webtasks`);
};

actions.apiKeys = function (addonConfigId) {
  return crudList(`${url}/${addonConfigId}/api-keys`);
};

export default actions;
