import { Component } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import Summary from './summary';

const cms = wurd.block('unitOrder.details');


export default class UnitMoveInJobDetails extends Component {

  static propTypes = {
    job: PropTypes.object.isRequired,
    owner: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    modal: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <div className="block-flat">
          <div className="header">
            <h3><cms.Text id="title" /></h3>
          </div>

          <div className="content clearfix">
            <Summary {...this.props} />
          </div>
        </div>
      </div>
    );
  }

};
