import { cloneElement } from 'react';
import wurd, { WurdText } from 'wurd-react';
import * as helpers from 'helpers';
import ModalForm from 'components/form2/modal-form';
import VariablePicker from './variable-picker';


const cms = wurd.block('business.emailTemplates');

export default function EmailTemplateForm({
  locals,
  children,
  ...props
}) {
  const settings = helpers.settings.get();
  const templateKey = props.initialValue?.templateKey;

  const partials = [
    .../invoice|receipt/.test(templateKey) ? ['invoiceHeader', 'invoiceItems'] : [],
    ...settings.modes?.includes('selfStorage') && /invoice|receipt|^unit/.test(templateKey) ? ['siteCard', 'unitCard'] : [],
    'button',
    'spacer'
  ]; 

  return (
    <ModalForm
      {...props}
      title={(
        <h4 className="modal-title">
          <WurdText id={`${props.wurdSection}.modal.title_${props.mode}`} vars={props.initialValue} />
          <cms.Object
            type="a"
            keys="helpUrl"
            className="btn btn-link"
            href={cms.text('helpUrl')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="far fa-fw fa-question-circle" title={wurd.text('common.phrases.help')} />
          </cms.Object>
        </h4>
      )}
    >
      {(getProps, formValue, { clear, setFormValue }) => {
        function getProps2(name, lang) {
          const { value, onChange } = getProps(name);

          return {
            name,
            value: lang ? value?.[lang] : value,
            onChange: lang ? e => onChange({
              target: {
                name,
                value: { ...value, [lang]: e.target.value },
              }
            }) : onChange,
          };
        }

        const childrenProps = {
          getProps: getProps2,
          formValue,
          initialValue: props.initialValue,
          clear,
          setFormValue,
          VariablePicker: props => <VariablePicker locals={locals} partials={partials} {...props} />,
        };

        return typeof children === 'function' ? children(childrenProps) : cloneElement(children, childrenProps);
      }}
    </ModalForm>
  );
}
