/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import classnames from 'classnames';

import * as actions from '../../../actions';
import * as helpers from '../../../helpers';

import { WurdObject } from 'wurd-react';
import Location from './location';
import Item from './item';
import Product from './product';

const { k, t } = helpers.cms.getters('visitView.deliver');


const DeliverStep = createClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired
  },

  render() {
    var { visit } = this.props;

    if (!helpers.visit.needsDeliverStep(visit)) return null;

    var step = 'picked';

    return (
      <section className="panel">
        <div
          className={classnames('panel-heading', {
            success: helpers.visit.isStepComplete(visit, step)
          })}
        >
          <h4 className="panel-title">
            <a className={classnames({ collapsed: helpers.visit.isFutureStep(visit, step) })}>
              <WurdObject id={k()} keys="title,failedBtn,nextBtn">
                {t('title')}
              </WurdObject>
            </a>
          </h4>
        </div>
        {helpers.visit.isActiveStep(visit, step) && this.renderMain()}
      </section>
    );
  },

  renderMain() {
    var { props } = this;
    var { visit } = props;

    var itemConfigs = helpers.item.byType(true);
    var productConfigs = helpers.product.byId(true);

    return (
      <div className="panel-body">
        <Location visit={visit} />

        <ul className="list-group">
          {/* Empty boxes */}
          {visit.deliverBoxes.map(item => {
            var itemConfig = itemConfigs[item.type] || {};

            return <Item key={item.id} item={item} itemConfig={itemConfig} />
          })}

          {/* Owner's items to deliver */}
          {visit.deliverItems.map(item => {
            var itemConfig = itemConfigs[item.type] || {};

            return <Item key={item.id} item={item} itemConfig={itemConfig} />
          })}

          {/* Store items */}
          {visit.storeItems.map(product => {
            var productConfig = productConfigs[product.type] || {};

            return <Product key={product.id} product={product} productConfig={productConfig} />
          })}
        </ul>

        <div className="btn-group btn-group-justified">
          <div className="btn-group">
            <button type="button"
              className="btn btn-danger btn-flat"
              onClick={this.setFailed}
            >
              <i className="fa fa-times" />
              {t('failedBtn')}
            </button>
          </div>
          <div className="btn-group">
            <button type="button"
              className="btn btn-success"
              onClick={this.completeStep}
            >
              <i className="fa fa-check" />
              {t('nextBtn')}
            </button>
          </div>
        </div>
      </div>
    );
  },

  /*isActive() {
    return helpers.visit.isActiveStep(this.props.visit, 'picked');
  },

  isComplete() {
    return helpers.visit.isStepComplete(this.props.visit, 'picked');
  },*/

  setFailed() {
    var { visit, onChange } = this.props;

    actions.visits.update(visit.id, { state: 'failed-delivery' })
      .then(onChange);
  },

  completeStep() {
    var { visit, onChange } = this.props;

    actions.visits.update(visit.id, { state: 'delivered' })
      .then(onChange);
  }

});

export default DeliverStep;
