import { getLangText } from 'helpers/ui';

import DetailPage from 'components/detail-page';
import NotFound from 'components/not-found';

import SidebarSummary from './sidebar-summary';
import Menu from './menu';
import Settings from './settings';

const sectionComponents = {
  settings: Settings,
};

export default function AddonConfigDetailPage({
  section,
  modal,
  cms,
  refetch,
  addonConfig,
}) {
  // If webtasks are present, then the currentUser is the addonConfig developer
  const isAddonDeveloper = Boolean(addonConfig.webtasks);
  
  const CurrentSection = sectionComponents[section];
  if (!CurrentSection) return <NotFound />;

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="manager"
      documentTitle={getLangText(addonConfig.title)}
      title={getLangText(addonConfig.title)}
      subtitle={getLangText(addonConfig.subtitle)}
      ancestors={[
        { text: cms.text('addonConfigs.title'), url: '/addon-configs' },
      ]}
      sections={[
        { name: 'settings', icon: 'far fa-code' },
      ]}
      currentSection={section}
      baseUrl={`/addon-configs/${addonConfig.id}`}
      sidebar={(
        <div>
          <SidebarSummary
            cms={cms}
            modal={modal}
            addonConfig={addonConfig}
            refetch={refetch}
            isAddonDeveloper={isAddonDeveloper}
          />
        </div>
      )}
      extra={(
        <Menu
          modal={modal}
          cms={cms}
          addonConfig={addonConfig}
          refetch={refetch}
        />
      )}
    >
      <CurrentSection
        cms={cms}
        modal={modal}
        refetch={refetch}
        addonConfig={addonConfig}
        isAddonDeveloper={isAddonDeveloper}
      />
    </DetailPage>
  );
}
