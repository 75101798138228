import * as actions from 'actions';

import CustomSystemCharges from 'components/custom-system-charges';


export default function UnitTypeSystemCharges({
  modal,
  cms,
  unitType,
  onChange,
}) {
  async function onSubmit(data) {
    await actions.update('unit-types', unitType.id, { systemCharges: data });
  }

  return (
    <CustomSystemCharges
      modal={modal}
      cms={cms.block('systemCharges')}
      systemCharges={unitType.systemCharges}
      onSubmit={onSubmit}
      onSuccess={onChange}
    />
  );
}
