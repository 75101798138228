import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import wurd from 'wurd-react';

import Label from './item';

import ListFilter from '../../components/filter/list';


const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 12px 4px',
  borderBottom: '1px solid #ccc',

  '& button': {
    opacity: 1,
    transition: 'all .3s',
    '&:disabled': {
      opacity: '0.2!important',
    },
    '&:first-child': {
      marginRight: 4,
    }
  }
});


const cms = wurd.block('common.filters.labels');

export default function LabelsFilter({ labels, active, onSelect, ...props }) {
  const [delim, setDelim] = useState(active?.includes('*') ? '*' : ' '); // ' ' (for OR) or '*' (for AND)

  const values = active ? active.split(/[ +*]/) : [];

  function selectWithDelim(sep) {
    setDelim(sep);
    onSelect(values.join(sep));
  }

  return (
    <cms.Object keys="title,OR,AND,noLabels">
      <ListFilter
        title={cms.text('title')}
        active={values}
        onSelect={values => onSelect(Array.isArray(values) ? values.join(delim) : values)}
        multi
        header={(
          <Header>
            <Button style={delim === ' ' ? { outline: 'auto' } : { opacity: .8 }} onClick={() => selectWithDelim(' ')} bsSize="xsmall">{cms.text('OR') || 'OR'}</Button>
            <Button disabled={values.length <= 1} style={delim === '*' ? { outline: 'auto' } : { opacity: .8 }} onClick={() => selectWithDelim('*')} bsSize="xsmall" >{cms.text('AND') || 'AND'}</Button>
          </Header>
        )}
        {...props}
      >
        {labels?.map(label => (
          <li key={label.id} value={label.id}>
            <Label {...label} />
          </li>
        ))}

        {!labels?.length && <li><cms.Markdown id="noLabels" style={{ maxWidth: 100 }} /></li>}
      </ListFilter>
    </cms.Object >
  );
}


LabelsFilter.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.objectOf(PropTypes.string).isRequired,
      color: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.string,
};
