import wurd, { WurdText } from 'wurd-react';
import { Link } from 'react-router-dom';
import LangText from 'components/lang-text';
import { useItemLoader } from 'hooks';

import SidebarItem from './index';


export default function SidebarUnitType({ id, title }) {
  const { item: unitType } = useItemLoader('unit-types', id, { include: ['customFields'] });

  return (
    <SidebarItem
      image={unitType?.image}
      typeTitle={title || <WurdText id="common.sidebarItems.unitType" />}
      itemTitle={!unitType
        ? wurd.text('common.phrases.deleted') || '[deleted]'
        : (
          <Link to={`/unit-types/${unitType.id}`}><LangText>{unitType.title}</LangText></Link>
        )
      }
    />
  );

};
