import { ModalForm, Field } from '../../form2';

export default function CustomDomainForm(props) {
  return (
    <ModalForm
      wurdSection="business.customDomains"
      {...props}
    >
      {getProps => (
        <>
          <Field {...getProps('host')} required autoFocus />

          <Field {...getProps('app')}>
            <select>
              <option value="units">Self storage</option>
              <option value="valet">Valet storage</option>
              <option value="userapp_sites">Self storage (NEW)</option>
              <option value="userapp_valet">Valet storage (NEW)</option>
            </select>
          </Field>
        </>
      )}
    </ModalForm>
  );
}
