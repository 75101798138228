import * as helpers from '../../../helpers';

import { Line } from 'react-chartjs-2';

const { stateColors } = helpers.unit;

const types = {
  'available': {
    title: 'Available area',
    color: stateColors.available,
  },
  'occupied': {
    title: 'Occupied area',
    color: stateColors.occupied,
  },
};


export default function SiteRevenueChart({
  site,
  reports,
}) {
  const data = reports.reduce((memo, report) => {
    memo.dates.push(report.date);
    
    Object.keys(types).forEach(type => memo[type].push(report.area[type]));

    return memo;
  }, {
    dates: [],
    available: [],
    occupied: [],
  });

  const datasets = Object.entries(types).map(([type, config]) => ({
    label: config.title,
    data: data[type],
    borderColor: config.color,
    backgroundColor: config.color,
  }));
  
  return (
    <Line
      data={{
        labels: data.dates,
        datasets,
      }}
    />
  );
}
