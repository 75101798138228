import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WurdText } from 'wurd-react';

import { useApiForm } from '../../hooks';
import ErrorMessage from 'components/error-message';


export default function SettingsForm2({ children, ...props }) {
  const [{ hasChanged, success }, setState] = useState({ hasChanged: false, success: undefined });

  const { fieldProps, inputProps, submit, errMsg, errors, loading, formValue } = useApiForm({
    ...props,
    onChange: handleChange,
    onSuccess: handleSuccess,
  });

  function handleChange(data) {
    // const { validate = {} } = this.props;

    // const isValid = !_.find(formData, (value, name) => validate[name] && validate[name](value));

    setState({
      hasChanged: true,
      success: undefined,
    });

    if (props.onChange) props.onChange(data);
  }

  function handleSuccess(data) {
    setState({
      success: true,
      error: null,
    });
    if (props.onSuccess) props.onSuccess(data);
  }

  return (
    <form className="form-horizontal clearfix">
      {children({ fieldProps, inputProps, formValue })}

      {errMsg && (
        <div className="col-sm-offset-4">
          <ErrorMessage>
            {errMsg}

            {errors &&
              <ul>
                {_.map(errors, (msg, key) =>
                  <li key={key}>{key}: {msg}</li>
                )}
              </ul>
            }
          </ErrorMessage>
        </div>
      )}

      <button
        type="button"
        style={{ marginTop: 20 }}
        className={`btn btn-primary pull-right ${success ? 'btn-success' : ''}`}
        onClick={submit}
        disabled={!hasChanged}
      >
        {success
          ? <WurdText id="common.savedBtn" />
          : <Fragment>{loading && <i className="fa fa-spinner fa-spin" />} <WurdText id="common.saveBtn" /></Fragment>
        }
      </button>
    </form>
  );
}

SettingsForm2.propTypes = {
  wurdSection: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // validate: PropTypes.object, // fieldName => validateFn
}
