import Page from '../components/page';
import { useModal, useHistoryListQuery } from '../hooks';
import UnitRentalList from '../components/unit-rental-list';


export default function UnitListPage() {
  const modal = useModal();
  const listQuery = useHistoryListQuery({
    implicitParams: {
      include: ['unit'],
    },
  });

  return (
    <Page
      titleKey="unitView.rentals.title"
      userRole="admin"
      modal={modal}
    >
      <UnitRentalList
        modal={modal}
        listQuery={listQuery}
        hideFilters={false}
      />
    </Page>
  );
}
