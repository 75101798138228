
/**
 * A text box that converts comma-separated text into an array
 * Useful for things like email lists, tag lists etc.
 */
 export default function CommaList({
  name,
  value,
  onChange,
  ...rest
}) {
  function handleChange(event) {
    const array = event.target.value?.split?.(',').map(s => s.trim()).filter(Boolean);
    
    onChange({
      target: {
        name,
        value: array,
      },
    });
  }

  const string = value?.join(', ');

  return (
    <input
      defaultValue={string}
      {...rest}
      onChange={handleChange}
    />
  )
}
