import { pick } from 'lodash';

import ModalForm from './modal-form';
import Field from './horizontal-field';
import ImageInput from './image-input';

const fields = [
  'image',
  'imageFit',
];


export default function ImageForm({ initialValue, ...rest }) {

  return (
    <ModalForm
      {...rest}
      initialValue={pick(initialValue, fields)}
    >
      {fieldProps =>
        <>
          <Field {...fieldProps('image')}>
            <ImageInput
              imageFitProps={fieldProps('imageFit')}
            />
          </Field>
        </>
      }
    </ModalForm>
  );
}