import { useState, useEffect } from 'react';
import _ from 'lodash';
import wurd from 'wurd-react';

import * as actions from '../../actions';
import * as helpers from '../../helpers';
import errHandler from '../../actions/_err-handler';
import { useHistoryListQuery, useListLoader, useModal } from 'hooks';

import TransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Page from '../page';
import Filters from './filters';
import Pagination from '../pagination';
import ExportButton from '../export-button';
import RefreshButton from '../refresh-button';
import SelectDriverModal from '../select-driver-modal';
import SelectionBar from '../selection-bar/selection-bar';

const { hasRole, hasRoleStrict } = helpers.auth;

const cms = wurd.block('visitList');

const jsonParseSafe = str => {
  try {
    return JSON.parse(str);
  } catch { }
}


export default function VisitList() {
  const currentUser = helpers.auth.getCurrentUser();
  const listQuery = useHistoryListQuery({
    initialParams: {
      limit: 50,
      offset: 0,

      state: 'active',
      assignee: hasRoleStrict('driver') ? currentUser.id : null,
      ...jsonParseSafe(localStorage.getItem('lscache-visitList.query')),
    }
  });

  const { items: visits, refetch, isLoading } = useListLoader('visits', listQuery.params, { maxAge: 0 });

  const modal = useModal();

  const [{ selectMode, selected }, setSelection] = useState({ selectMode: false, selected: [] });

  const [drivers, setDrivers] = useState(null);

  useEffect(() => {
    if (hasRole('admin')) {
      actions.users.fetchAdmins()
        .then(adminUsers => {
          setDrivers(adminUsers.filter(user => _.includes(user.roles, 'driver')));
        })
        .catch(err => {
          setDrivers([]);
          errHandler(err);
        });
    }
  }, []);

  const listQueryUpdate = params => {
    localStorage.setItem('lscache-visitList.query', JSON.stringify(params));
    listQuery.update(params);
  }


  function renderExtra() {
    return (
      <div>
        <a className="btn btn-link" href={helpers.ui.href('/valet-orders')}><cms.Text id="viewValetOrders" /></a>
        {hasRole('admin') &&
          <ExportButton
            filename="jobs.csv"
            fetch={params => actions.visits.fetch({
              ...listQuery.params,
              ...params,
              include: 'deliverBoxes,deliverItems,collectItems',
            })}
            toCsv={helpers.visit.toCsv}
          />
        }
        &nbsp;&nbsp;&nbsp;
        <RefreshButton onClick={refetch} />
      </div>
    );
  }

  function renderMain() {
    const filteredVisits = getFilteredVisits();

    return (
      <div className="row clearfix">
        <div className="tab-content no-padding">
          <Filters
            query={listQuery.params}
            onChange={listQueryUpdate}
            currentUser={currentUser}
            drivers={drivers}
          />

          <table className="table no-border table-hover">
            <thead className="no-border"></thead>
            <tbody className="no-border-x no-border-y">
              {filteredVisits.length
                ? filteredVisits.map(renderVisit)
                : !isLoading && renderNoResults()
              }
            </tbody>
          </table>

          <Pagination
            limit={listQuery.params.limit}
            offset={listQuery.params.offset}
            count={filteredVisits.length}
            onChange={listQueryUpdate}
          />
        </div>

        <TransitionGroup transitionName="slide-down" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
          {selectMode &&
            <SelectionBar
              count={selected.length}
              onCancel={toggleSelectMode}
              onAssign={selectDriver}
              assignClassName="fa-truck"
              cms={wurd.block('visitList.selectionBar')}
            />
          }
        </TransitionGroup>
      </div>
    );
  }

  function renderVisit(visit) {
    const emptyBoxCount = helpers.visit.countEmptyBoxes(visit);
    const deliverCount = _.uniqBy(visit.deliverItems).length;
    const collectCount = _.uniqBy(visit.collectItems).length;

    const isSelected = isVisitSelected(visit);

    return (
      <tr
        key={visit.id}
        onClick={() => onClickVisit(visit)}
        style={{ cursor: 'pointer' }}
        className={isSelected ? 'success' : null}
      >
        {hasRole('admin') &&
          <td className="text-center" style={{ cursor: 'default' }}>
            <input type="checkbox" checked={isSelected} onChange={() => toggleVisitSelected(visit)} onClick={e => e.stopPropagation()} />
          </td>
        }
        <td>
          <strong>{visit.sid.toUpperCase()}</strong><br />
          {visit.owner.name}
        </td>
        <td>
          {helpers.ui.date(visit.date)}<br />
          {helpers.visit.formatTimeslot(visit.timeslot)}
        </td>
        <td>{helpers.area.getTitle(visit.area)}</td>
        <td className="hidden-xs hidden-sm">
          {!!emptyBoxCount &&
            <div>Empty boxes: {emptyBoxCount}</div>
          }
          {!!deliverCount &&
            <div>Deliver: {deliverCount}</div>
          }
          {!!collectCount &&
            <div>Collect: {collectCount}</div>
          }
        </td>
        {hasRole('operations') &&
          <td className="hidden-xs">
            {visit.assignee
              ? visit.assignee.name
              : <span style={{ color: '#ccc' }}>{cms.text('filters.assignee.unassigned')}</span>
            }
          </td>
        }
        <td>
          <i className={helpers.visit.getStateIcon(visit.state)} title={cms.text('visit:_states.' + visit.state)} />
        </td>
      </tr>
    );
  }

  function renderNoResults() {
    return <tr><td colSpan="7" className="text-center" style={{ padding: 40 }}><cms.Text id="noResults" /></td></tr>
  }

  /**
   * Returns visits that have been filtered by the LOCAL filters (i.e. those that are not handled by the API)
   *
   * @return {Object[]} visits
   */
  function getFilteredVisits() {
    let filteredVisits = visits;

    if (listQuery.params.type) {
      filteredVisits = filteredVisits.filter(visit => helpers.visit.isType(visit, listQuery.params.type));
    }

    /*if (query.daterange) {
      var range = query.daterange;

      visits = visits.filter(visit => helpers.visit.isWithin(visit, query.start, query.end));
    }*/

    return filteredVisits;
  }

  function onClickVisit(visit) {
    if (selectMode) {
      toggleVisitSelected(visit);
    } else {
      openVisit(visit);
    }
  }

  function toggleVisitSelected(visit, event) {
    const newSelected = isVisitSelected(visit)
      ? selected.filter(v => v === visit.id)
      : [...selected, visit.id];

    setSelection({
      selected: newSelected,
      selectMode: true
    });
  }

  function isVisitSelected(visit) {
    return _.includes(selected, visit.id);
  }

  function openVisit(visit) {
    helpers.ui.redirect(`/visits/${visit.id}`);
  }

  function toggleSelectMode() {
    if (selectMode) {
      setSelection({
        selectMode: false,
        selected: []
      });
    } else {
      setSelection({ selectMode: true });
    }
  }

  function selectDriver() {
    modal.open(
      <SelectDriverModal
        drivers={drivers}
        onHide={modal.close}
        onSelect={assignToDriver}
      />
    );
  }

  /**
   * Assigns selected visits to the given driver
   *
   * @param {Object} user
   */
  async function assignToDriver(user) {
    modal.close();

    const userId = user ? user.id : null;

    await Promise.all(
      selected.map(visitId => actions.visits.assignToDriver(visitId, userId))
    );

    toggleSelectMode();
    refetch();
  }


  return (
    <Page
      titleKey={cms.id('title')}
      userRole={['driver', 'operations']}
      extra={renderExtra()}
      modal={modal}
    >
      {visits && renderMain()}
    </Page>
  );
}
