import { useSimpleListQuery, useListLoader } from '../../hooks';
import * as helpers from '../../helpers';

import Section from '../section';
import CurrentOccupancyChart from './charts/current-occupancy';
import OccupancyChart from './charts/occupancy';
import AreaChart from './charts/area';
import RevenueChart from './charts/revenue';
import JobsChart from './charts/jobs';
import SiteOnboarding from 'components/site-onboarding'; // could lazy-load


function ChartHeader({ children }) {
  return (
    <div className="block-transparent" style={{ marginTop: 20, marginBottom: 0 }}>
      <div className="header">
        <h4>{children}</h4>
      </div>
    </div>
  );
}

export default function SiteOverview({
  site,
  cms,
  ...props
}) {
  const listQuery = useSimpleListQuery({
    initialParams: {
      siteId: site.id,
    },
  });

  const nUnits = helpers.site.getOccupancy(site).total;

  const { items: reports } = useListLoader('site-reports', listQuery.params);

  if (!nUnits || !site.area_gross || !site.area_net) {
    return <SiteOnboarding site={site} cms={cms} {...props} />;
  }

  return (
    <>
      <Section cms={cms.block('overview')}>
        <div className="text-center">
          <CurrentOccupancyChart
            site={site}
          />
        </div>

        <ChartHeader><cms.Text id="overview.occupancy" /></ChartHeader>
        <OccupancyChart
          site={site}
          reports={reports}
        />

        <ChartHeader><cms.Text id="overview.revenue" /></ChartHeader>
        <RevenueChart
          site={site}
          reports={reports}
        />

        <ChartHeader><cms.Text id="overview.area" /></ChartHeader>
        <AreaChart
          site={site}
          reports={reports}
        />

        <ChartHeader><cms.Text id="overview.jobs" /></ChartHeader>
        <JobsChart
          site={site}
          reports={reports}
        />
      </Section>
    </>
  );
}
