import moment from 'moment-timezone';
import wurd from 'wurd-react';

import store from '../../store';
import { useListLoader } from '../../hooks';
import * as helpers from '../../helpers';

import ListFilter from '../filter/list';
import DateFilter from '../filter/date';
import UserFilter from '../filter/user';
import LabelsFilter from 'plugins/labels/filter';
import SearchFilter from '../filter/search';

const cms = wurd.block('valetOrderList.filters');

const { hasRoleStrict } = helpers.auth;
const states = ['submitted', 'started', 'completed', 'failed', 'cancelled'];

const driversQuery = {
  roles: 'driver',
};


export default function ValetOrderListFilters({
  listQuery,
  hideOwner,
  hideDriver,
}) {
  const { params } = listQuery;

  const { currentUser } = store.get();
  const { items: drivers } = useListLoader('users', driversQuery);

  function onChange(key, value) {
    const newParams = {};

    switch (key) {
      case 'daterange':
        newParams.start = value ? moment(value.start).format('YYYY-MM-DD') : null;
        newParams.end = value ? moment(value.end).format('YYYY-MM-DD') : null;
        break;

      default:
        newParams[key] = value;
    }

    listQuery.update({
      offset: 0,
      ...newParams,
    });
  }

  const setState = (val) => onChange('state', val);
  const setDateRange = (val) => onChange('daterange', val);
  const setArea = (val) => onChange('area', val);
  const setDriverId = (val) => onChange('driverId', val);
  const setOwnerId = (val) => onChange('ownerId', val);

  const daterange = (params.start && params.end)
    ? { start: params.start, end: params.end }
    : null;


  return (
    <>
      <cms.Object id="state" keys={['title', 'all'].concat(states)}>
        <ListFilter
          title={cms.text('state.title')}
          active={params.state}
          onSelect={setState}
          multi
        >
          {states.map((state) => (
            <li key={state} value={state}>{cms.text(`state.${state}`)}</li>
          ))}
        </ListFilter>
      </cms.Object>

      <cms.Object id="daterange" keys="title">
        <DateFilter
          title={cms.text('daterange.title')}
          range={daterange}
          onSelect={setDateRange}
        />
      </cms.Object>

      <cms.Object id="area" keys="title,all">
        <ListFilter
          title={cms.text('area.title')}
          active={params.area}
          onSelect={setArea}
        >
          <li value={null}>{cms.text('area.all')}</li>
          {helpers.area.list(true).map((area) => (
            <li key={area.id} value={area.id}>{area.title}</li>
          ))}
        </ListFilter>
      </cms.Object>

      {!hideDriver && drivers && (
        <cms.Object id="driver" keys="title,all,me.unassigned">
          <ListFilter
            title={cms.text('driver.title')}
            active={params.driverId}
            onSelect={setDriverId}
          >
            {hasRoleStrict('driver') && (
              <li value={currentUser.id}>{cms.text('driver.me')}</li>
            )}
            <li value={null}>{cms.text('driver.all')}</li>
            <li value="unassigned">{cms.text('driver.unassigned')}</li>
            {drivers.map((user) => (
              <li key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</li>
            ))}
          </ListFilter>
        </cms.Object>
      )}

      {!hideOwner && (
        <UserFilter
          active={params.ownerId}
          onSelect={setOwnerId}
        />
      )}

      <LabelsFilter
        active={params.labels}
        onSelect={(val) => onChange('labels', val)}
        labels={helpers.settings.get().valetOrderLabels}
      />

      <SearchFilter
        name="valetOrder-search"
        cms={wurd.block('valetOrderList.search')}
        active={params.search}
        onSelect={(val) => onChange('search', val)}
        modelName="valetOrder"
      />
    </>
  );
}
