import { pick } from 'lodash';
import { WurdText } from 'wurd-react';

import { notify } from 'helpers/ui';
import * as actions from 'actions';
import * as helpers from 'helpers';

import Modal from '../../modal';
import UnitList from '../../unit-list';
import UnitMoveInJobForm from './form';
import { useSimpleListQuery } from 'hooks';
import SiteLoader from '../../loaders/site';


function UnitListWrapper({ siteId, unitTypeId, onClickItem }) {
  const listQuery = useSimpleListQuery({
    initialParams: {
      // offset: 0,
      // limit: 50,
      siteId,
      typeId: unitTypeId,
      state: 'available',
    },
  });

  return (
    <UnitList
      listQuery={listQuery}
      hideSite
      hideState
      hideOwner
      onClickItem={onClickItem}
    />
  );
}


export function changeUnit({
  job,
  modal,
  onSuccess,
}) {
  const { siteId, unitTypeId } = job.result;

  const onClickItem = async (unit) => {
    await actions.jobs.runOperation(job.id, 'editUnitId', {
      unitId: unit.id,
    });

    modal.close();
    onSuccess && onSuccess();

    notify({
      bsType: 'success',
      text: `Changed to unit ${unit.name}`,
    });
  };

  return modal.open(
    <Modal
      title={<WurdText id="unitJobs.moveIn.changeUnit.title" />}
    >
      <UnitListWrapper
        siteId={siteId}
        unitTypeId={unitTypeId}
        onClickItem={onClickItem}
      />
    </Modal>,
  );
}


export function changeStartDate({
  job,
  modal,
  onSuccess,
}) {
  const order = job.result;
  const fields = ['startDate'];

  modal.open(
    <UnitMoveInJobForm
      initialValue={pick(order, fields)}
      onSubmit={async (formData) => {
        await actions.jobs.runOperation(job.id, 'editStartDate', pick(formData, fields));

        onSuccess && onSuccess();

        notify({
          bsType: 'success',
          text: `Changed move-in date`,
        });
      }}
      onSuccess={modal.close}
      closeModal={modal.close}
      mode="update"
      fields={fields}
      siteId={order.siteId}
    />,
  );
}


export function editOrder({
  job,
  modal,
  onSuccess,
}) {
  const order = job.result;
  const fields = ['unitDeposit', 'unitPrice', 'prepayPeriods', 'promoCodeId', 'customPromo', 'products'];

  modal.open(
    <SiteLoader id={order.siteId}>
      {site => (
        <UnitMoveInJobForm
          initialValue={{
            ...pick(order, [...fields, 'startDate']), // startDate is used for filtering promos
            products: Object.fromEntries(Object.entries(order.products || {}).filter(([id]) => site.products.some(p => p.id === id)))
          }} 
          onSubmit={async (formData, filledData, patchData) => {
            // do a shallow merge instead of deep merge to allow removing products
            await actions.jobs.runOperation(job.id, 'update', pick({ ...formData, ...patchData }, fields));

            onSuccess && onSuccess();

            notify({
              bsType: 'success',
              text: `Updated move-in`,
            });
          }}
          onSuccess={modal.close}
          closeModal={modal.close}
          mode="update"
          fields={fields}
          siteId={order.siteId}
        />
      )}
    </SiteLoader>
  );
}
