import { useQuery } from 'react-query';
import loadScript from 'load-script2';
import { nonce } from 'config';
import styled from '@emotion/styled';


const HideBadge = styled.div({
  '.grecaptcha-badge': { visibility: 'hidden' },
});

export default function Recaptcha({ hideBadge }) {
  const recaptchaQuery = useQuery('recaptcha', () => loadScript('https://www.google.com/recaptcha/api.js', {
    nonce,
  }));

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  if (!siteKey) throw new Error('Missing REACT_APP_RECAPTCHA_SITE_KEY environment variable');

  const Container = hideBadge ? HideBadge : 'div';

  return (
    <>
      {hideBadge && <div className="text-muted" style={{ marginTop: 8 }}>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</div>}
      <Container
        id="recaptcha"
        className="g-recaptcha"
        data-sitekey={siteKey}
        data-size="invisible"
      />
    </>
  );
} 