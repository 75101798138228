import store from '../store';


/**
 * Returns all settings
 *
 * @return {Object}
 */
export const get = function () {
  return store.get('settings');
};


/*
 * Returns the languages configured for the operator
 *
 * @return {String[]}
 */
export const langs = function () {
  return store.get('settings')?.languages ?? ['en'];
}


const modelAliases = {
  order: 'valetOrder',
  rental: 'unitRental',
  type: 'unitType',
};

/**
 * @param {String} name (full name, e.g. user.customFields.account_type)
 * @return {Object}
 */
export const getCf = function (name) {
  const [modelName, mid, code] = name.split('.');
  if (mid !== 'customFields') return undefined;
  const { settings } = store.get();
  return settings[`${modelAliases[modelName] || modelName}CustomFields`].find(s => s.code === code);
}