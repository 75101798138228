import styled from '@emotion/styled';


const Wrapper = styled.div({
  position: 'relative',
  display: 'inline-block',
  opacity: 0.5,
  margin: '0 8px 4px 0',
  border: '1px solid #eee',
  overflow: 'hidden',
  borderRadius: 2,
  cursor: 'pointer',
  fontSize: 16,

  '&.scanned': {
    opacity: 1,
  },

  '.image': {
    width: 36,
    height: 36,
    objectFit: 'cover',
    borderRight: '1px solid #eee',
  },

  '.sid': {
    lineHeight: '36px',
    marginLeft: 8,
  },

  '.deleteButton': {
    background: 'none',
    border: 'none',
    color: '#ccc',
    lineHeight: '36px',
  },
});


export default ({
  item,
  onClick,
  selected,
  scanned = false,
  onDelete,
  hideImage = false,
}) => {
  return (
    <Wrapper
      id={item.id}
      data-id={item.id}
      data-sid={item.sid}
      className={`${scanned && 'scanned'}`}
    >
      {!hideImage && (
        <img
          className="image"
          src={item.image}
          alt=""
        />
      )}

      <span className="sid">{item.sid.toUpperCase()}</span>

      {onDelete && (
        <button
          type="button"
          className="deleteButton"
          id={item.id}
          data-sid={item.sid}
          onClick={onDelete}
        >
          <i className="fas fa-times" />
        </button>
      )}
    </Wrapper>
  );
}
