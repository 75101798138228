import wurd from 'wurd-react';

import { useListLoader } from '../../hooks';
import * as helpers from '../../helpers';

import TableList from '../table-list';
import Filters from './filters';
import Item from './item';
import Menu from './menu';
import BulkActions from './bulk-actions';

const cms = wurd.block('valetOrderList');
const { sid } = helpers.ui;

function viewItem(valetOrder) {
  return `/valet-orders/${sid(valetOrder)}`;
}


export default function ValetOrderList({
  listQuery,
  modal,
  hideOwner = false,
  hideArea = false,
  hideDriver = false,
  onClickAdd = null,
}) {
  const listLoader = useListLoader('valet-orders', listQuery.params, { maxAge: 0 });

  const renderItem = (valetOrder) => (
    <Item
      valetOrder={valetOrder}
      hideOwner={hideOwner}
      hideArea={hideArea}
      hideDriver={hideDriver}
    />
  );

  const renderBulkActions = ({ selectedItems, onActionComplete }) => (
    <BulkActions
      modal={modal}
      selectedItems={selectedItems}
      onActionComplete={onActionComplete}
    />
  );

  const getTableRowClassName = ({ state }) => {
    if (state === 'completed' || state === 'cancelled') return 'fade';
  };


  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={(
        <Filters
          listQuery={listQuery}
          hideOwner={hideOwner}
          hideArea={hideArea}
          hideDriver={hideDriver}
        />
      )}
      menu={(
        <Menu
          modal={modal}
          cms={cms}
          listQuery={listQuery}
        />
      )}
      renderItem={renderItem}
      getItemLink={viewItem}
      renderBulkActions={!hideDriver && renderBulkActions}
      onClickAdd={onClickAdd}
      getTableRowClassName={getTableRowClassName}
    />
  );
}
