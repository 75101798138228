import wurd from 'wurd-react';

import { useListLoader } from '../../hooks';
import * as helpers from '../../helpers';

import TableList from '../table-list';
import Filters from './filters';
import Item from './item';
import Menu from './menu';
import BulkActions from './bulk-actions';

const cms = wurd.block('invoiceList');

function viewInvoice(invoice) {
  return `/invoices/${invoice.sid.toUpperCase()}`;
}

export default function InvoiceList({
  listQuery,
  modal,
  hideSite = false,
  hideOwner = false,
  hideUnit = false,
  onClickAdd = null,
  onChange = () => {},
}) {
  const listLoader = useListLoader('invoices', listQuery.params, { maxAge: 0 });

  const stateTitles = helpers.invoice.getStateTitles();

  const renderItem = (invoice) => (
    <Item
      invoice={invoice}
      stateTitles={stateTitles}
      hideSite={hideSite}
      hideOwner={hideOwner}
      hideUnit={hideUnit}
    />
  );

  const renderBulkActions = ({ selectedItems, onActionComplete }) => (
    <BulkActions
      modal={modal}
      selectedItems={selectedItems}
      onActionComplete={onActionComplete}
    />
  );

  const getTableRowClassName = invoice => {
    if (invoice.deleted) return 'fade';
  };

  const refetch = () => {
    onChange(); // update unit sidebar in background
    history.pushState('', null, location.pathname + location.search);
    return listLoader.refetch();
  };

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={{ ...listLoader, refetch }}
      filters={(
        <Filters
          listQuery={listQuery}
          hideOwner={hideOwner}
          hideSite={hideSite}
        />
      )}
      menu={(
        <Menu
          modal={modal}
          cms={cms}
          listQuery={listQuery}
          fetchInvoices={refetch}
          // hideBulkPay={listLoader.items?.every(inv => inv.state === 'paid')}
        />
      )}
      renderItem={renderItem}
      getItemLink={viewInvoice}
      renderBulkActions={renderBulkActions}
      onClickAdd={onClickAdd}
      getTableRowClassName={getTableRowClassName}
    />
  );
}
