import wurd from 'wurd-react';
import * as helpers from '../../../helpers';
import { Checklist, MultiLangText, ImageInput } from 'components/form2';
import Field from '../field';

export default function SiteOnboardingUnitDetailsForm({ getProps }) {
  const unitFeatures = helpers.unit.tag.list(true);

  return (
    <>
      <Field {...getProps('image')}>
        <ImageInput
          imageFitProps={getProps('imageFit')}
        />
      </Field>
      
      <Field {...getProps('title')} required>
        <MultiLangText required autoFocus />
      </Field>

      <Field {...getProps('subtitle')}>
        <MultiLangText />
      </Field>

      {unitFeatures.length > 0 && (
        <Field {...getProps('tagIds')} cms={wurd.block('unitType.features')}>
          <Checklist className="cl-sm">
            {unitFeatures.map(tagConfig =>
              <span key={tagConfig.id} id={tagConfig.id}>
                <i className={`fa fa-fw ${tagConfig.icon}`} />&nbsp;&nbsp;
                {tagConfig.title}
              </span>
            )}
          </Checklist>
        </Field>
      )}
    </>
  )
}
