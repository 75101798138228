import { DropdownButton, MenuItem } from 'react-bootstrap';
import omit from 'lodash/omit';

import * as helpers from '../../helpers';
import * as actions from '../../actions';

import { ModalDialog } from '../modal';
import ExportButton from '../export-button';
import ImportButton from 'components/import-button';


export default function ItemListMenu({
  modal,
  cms,
  listQuery,
  fetchItems,
}) {
  if (!modal) return null;

  function openExportModal() {
    modal.open(
      <ModalDialog cms={cms.block('exportModal')}>
        <ExportButton
          filename="items.csv"
          toCsv={helpers.item.toCsv}
          fetch={params => helpers.item.fetchDetailed({ ...listQuery.params, ...params, include: 'customFields' })}
          sections={{
            main: ['sid', 'type', 'state', 'location', 'opsTitle'],
            owner: ['firstName', 'lastName', 'email', 'companyName'],
          }}
        />
      </ModalDialog>
    );
  }

  function openImportModal() {
    modal.open(
      <ModalDialog cms={cms.block('importModal')}>
        <ImportButton
          processRow={async row => actions.create('items', await helpers.item.fromCsv(row), { include: 'customFields' })}
          onComplete={fetchItems}
          mode="create"
        />
      </ModalDialog>
    );
  }

  function openUpdateModal() {
    modal.open(
      <ModalDialog cms={cms.block('updateModal')}>
        <ImportButton
          processRow={async row => actions.update('items', row.id, omit(await helpers.item.fromCsv(row), 'id'), { include: 'customFields' })}
          onComplete={fetchItems}
          mode="update"
        />
      </ModalDialog>
    );
  }

  return (
    <cms.Object id="menu" keys="export,import,update,helpUrl">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        <MenuItem
          key="export"
          onSelect={openExportModal}
        >
          <i className="fas fa-fw fa-download" /> {cms.text('menu.export')}
        </MenuItem>

        {helpers.auth.hasRole('manager') && (
          <>
            <MenuItem
              key="import"
              onSelect={openImportModal}
            >
              <i className="fas fa-fw fa-upload" /> {cms.text('menu.import')}
            </MenuItem>
            <MenuItem
              key="update"
              onSelect={openUpdateModal}
            >
              <i className="fal fa-fw fa-upload" /> {cms.text('menu.update')}
            </MenuItem>
          </>
        )}
        {cms.text('menu.helpUrl') && (
          <MenuItem href={cms.text('menu.helpUrl')} target="_blank">
            <i className="fal fa-fw fa-external-link" /> {cms.wurd.text('common.filters.helpText') || 'Help'}
          </MenuItem>
        )}
      </DropdownButton>
    </cms.Object>
  );
}
