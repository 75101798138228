/**
 * Main filter button, which places the filter itself within a popup.
 * Used by specific filter classes such as ListFilter, DateFilter.
 */

import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Button, Overlay, Popover } from 'react-bootstrap';


export const Filter = styled.span({
  display: 'inline-flex',

  // clear button
  '& > button:nth-of-type(2)': {
    cursor: 'default',
    padding: 1,
    marginLeft: -6,
  }
})

export default function FilterButton({ active, title, className = '', onSelect = () => { }, children }) {
  const [showMenu, setShowMenu] = useState(false);
  const buttonRef = useRef(null);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }
  function hideMenu() {
    setShowMenu(false);
  }

  const titleStr = `${title?.props?.children[0] || title || ''}`.replace(/ /g, '-');

  return (
    <Filter className={`filter ${active ? 'filter--active' : ''} ${className}`}>
      <Button
        ref={buttonRef}
        bsStyle="link"
        onClick={toggleMenu}
      >
        {title}
        <span className="caret"></span>
      </Button>
      {active && (
        <Button bsStyle="link" onClick={() => onSelect(null)}>
          <i className="fal fa-times" />
        </Button>
      )}

      <Overlay
        show={showMenu}
        onHide={hideMenu}
        placement="bottom"
        target={buttonRef.current}
        rootClose
      >
        <Popover id={`${titleStr}-newItem`} className={`filter__popover ${className + '__popover'}`}>
          {children}
        </Popover>
      </Overlay>
    </Filter>
  )
}


FilterButton.propTypes = {
  title: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.any // The current filter value (null if not set)
};
