import { useState } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';
import { useMutation } from 'react-query';
import { Modal } from 'react-bootstrap';
import ModalContainer from '../modal';
import UnitList from '../unit-list';
import { useSimpleListQuery, useItemLoader } from 'hooks';

const cms = wurd.block('unitOrder.form.unitSelector');


export default function UnitSelector({ siteId, unitTypeId, name, value, onChange, bsStyle = 'default', defaultState = 'available' }) {
  const [open, setOpen] = useState(false);

  const listQuery = useSimpleListQuery({
    initialParams: {
      siteId,
      typeId: unitTypeId,
      state: !defaultState ? undefined : defaultState,
    },
  });

  const { item: unit } = useItemLoader('units', value);

  const handleChange = useMutation(unit => {
    setOpen(false);
    return onChange({
      target: {
        name,
        value: unit.id,
      },
    });
  });

  const isDisabled = Boolean(!siteId || !unitTypeId);

  return (
    <div onClick={e => e.stopPropagation()}>
      <button
        type="button"
        className={`btn btn-${bsStyle}`}
        onClick={() => setOpen(true)}
        disabled={isDisabled || handleChange.isLoading}
        title={unit && `Unit ${unit.name}`}
      >
        {handleChange.isLoading
          ? <i className="fa fa-spinner fa-spin" />
          : isDisabled
            ? <cms.Text id="disabled" />
            : unit ? <cms.Text id="selected" vars={{ name: unit.name }} /> : <cms.Text id="enabled" />
        }
      </button>

      <Modal show={open} onHide={() => setOpen(false)}>
        <ModalContainer>
          <UnitList
            listQuery={listQuery}
            onClickItem={handleChange.mutate}
            hideSite
            hideState
            hideOwner
          />
        </ModalContainer>
      </Modal>
    </div>
  );
}

UnitSelector.propTypes = {
  siteId: PropTypes.string,
  unitTypeId: PropTypes.string,

  //Passed automatically by Form
  //name: PropTypes.string,
  //value: PropTypes.string,
  //onChange: PropTypes.func,
};