import wurd from 'wurd-react';

import * as helpers from '../../helpers';

import SiteLoader from 'components/loaders/site';
import ListFilter from '../filter/list';
import SiteFilter from '../filter/site';
import SearchFilter from '../filter/search';

const cms = wurd.block('unitView.unitTypes.filters');


export default function UnitListFilters({
  listQuery,
  hideSite = false,
  site = null,
}) {
  const { params } = listQuery;

  function onChange(key, value) {
    listQuery.update({
      offset: 0,
      [key]: value,
    });
  }

  const setSiteId = (val) => onChange('siteId', val);
  const setGroupId = (val) => onChange('groupId', val);

  // siteId is not always available (e.g. on the overall unitTypes page)
  const siteId = site?.id || params.siteId || null;

  return (
    <>
      {!hideSite && helpers.settings.get().sites?.length > 0 && (
        <SiteFilter
          active={params.siteId}
          onSelect={setSiteId}
        />
      )}

      <SiteLoader id={siteId}>
        {site => {
          if (!site?.unitTypeGroups?.length) return null;

          return (
            <cms.Object id="groupId" keys={['title']}>
              <ListFilter
                title={cms.text('groupId.title')}
                active={params.groupId}
                onSelect={setGroupId}
                multi
              >
                {site.unitTypeGroups.map((unitTypeGroup) => (
                  <li key={unitTypeGroup.id} value={unitTypeGroup.id}>
                    {helpers.ui.getLangText(unitTypeGroup.title)}
                  </li>
                ))}
              </ListFilter>
            </cms.Object>
          );
        }}
      </SiteLoader>

      <SearchFilter
        name="unit-type-search"
        cms={wurd.block('unitTypeList.search')}
        active={params.search}
        onSelect={(val) => onChange('search', val)}
        modelName="unitType"
      />
    </>
  );
}
