import wurd from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';
import { currency, datePeriod } from 'helpers/ui';
import { isCreditNote } from 'helpers/invoice';

import Section from '../section';
import AddButton from './entries_add-button';
import InvoiceEntryForm from './forms/entry';
import Table from './table';


const cms = wurd.block('invoiceView.items');

export default function InvoiceEntries({
  invoice,
  unitRental,
  site,
  modal,
  onChange,
  showAddButton = !invoice.deleted,
}) {
  if (invoice.type === 'valet' && !invoice.entries?.length) return null;

  const canEdit = invoice.state === 'draft';

  function deleteEntry(entry) {
    if (!window.confirm(cms.text('editModal.confirmDelete'))) return;

    actions.invoices.entries(invoice.id).delete(entry.id)
      .then(modal.close);
  }

  function editEntry(event) {
    const { id } = event.currentTarget;
    const entry = invoice.entries.find((e) => e.id === id);
    if (!entry) throw new Error('AccountEntry not found');

    modal.open(
      <InvoiceEntryForm
        initialValue={entry}
        mode="update"
        onSubmit={(data) => actions.invoices.entries(invoice.id).update(entry.id, data)}
        onSuccess={modal.close}
        closeModal={modal.close}
        onDelete={() => deleteEntry(entry)}
        deleteButton={<cms.Object id="editModal" keys="delete,confirmDelete">{cms.text('editModal.delete')}</cms.Object>}
      />,
    );
  }


  return (
    <Section
      cms={cms}
      extra={canEdit && showAddButton && site && (
        <>
          <AddButton
            invoice={invoice}
            unitRental={unitRental}
            site={site}
            modal={modal}
            onChange={onChange}
          />
        </>
      )}
    >
      <Table className="table no-border">
        <thead>
          <tr>
            <cms.Text type="th" id="date" />
            <cms.Text type="th" id="desc" />
            <cms.Text type="th" id="price" className="secondary hidden-xs hidden-sm" />
            <cms.Text type="th" id="tax" className="secondary hidden-xs hidden-sm" />
            <cms.Text type="th" id="subtotal" />
          </tr>
        </thead>
        <tbody>
          {invoice.entries.map((entry) => {
            const [startDate, endDate] = datePeriod(entry.date, entry.endDate);

            return (
              <tr
                key={entry.id}
                id={entry.id}
                onClick={canEdit ? editEntry : undefined}
                style={{ cursor: canEdit ? 'pointer' : '' }}
              >
                <td className="fitContent">
                  <div>{startDate}</div>
                  {endDate && (
                    <div className="secondary"> → {endDate}</div>
                  )}
                </td>
                <td>
                  {entry.desc}
                  {entry.qty > 1 && (
                    <span className="secondary"> &times; {entry.qty}</span>
                  )}
                  {entry.type === 'deposit' && (
                    <span className="secondary"> <cms.Text id={`accountTypes.${entry.type}`} /></span>
                  )}
                  {entry.type === 'prepayment' && (
                    <span className="secondary"> <cms.Text id={`accountTypes.${entry.type}`} /></span>
                  )}
                  <div className="secondary">
                    <span title="Accounting code" style={{ marginRight: 10 }}>
                      <i className="fa-fw far fa-sm fa-calculator" />{entry.code}
                    </span>
                    {helpers.auth.hasRole('_support') && Boolean(entry.tags?.length) && (
                      <span title="System tags">
                        <i className="fa-fw far fa-sm fa-tag" />{entry.tags.join(',')}
                      </span>
                    )}
                  </div>
                </td>
                <td className="fitContent hidden-xs hidden-sm text-right secondary">{currency(entry.amount)}</td>
                <td className="fitContent hidden-xs hidden-sm text-right secondary">{entry.taxPct && currency(entry.tax)}</td>
                <td className="fitContent text-right">
                  {currency(entry.total)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div style={{ fontSize: 16, textAlign: 'right' }}>
        <cms.Text id="subtotal" />: {currency(invoice.subtotal)}
      </div>

      {!!invoice.tax && invoice.tax > 0 && (
        <div style={{ fontSize: 16, textAlign: 'right' }}>
          <cms.Text id="tax" />: {currency(invoice.tax)}
        </div>
      )}

      <hr />
      <div style={{ fontSize: 18, textAlign: 'right', fontWeight: 600 }}>
        <cms.Text id="total" />: <span className={isCreditNote(invoice) ? 'text-danger' : ''}>{currency(invoice.total)}</span>
      </div>
    </Section>
  );
}
