import { useItemLoader } from '../../hooks';
import { throttle_fetchOne } from '../../actions';

const fetch = throttle_fetchOne();

export default function UserLoader({
  id,
  include,
  maxAge,
  loading = <i className="fas fa-spinner fa-spin" />,
  fallback = <i className="fas fa-question-circle" title="Not found" />,
  children: renderFn,
}) {
  const { item: user, isLoading, refetch } = useItemLoader('users', id, {
    altIdKey: 'email',
    include,
    maxAge,
    fetch,
  });

  if (!user) {
    return isLoading ? loading : fallback;
  }

  // Add empty include lists to prevent errors when they have not yet been fetched
  ['units', 'items', 'visits'].forEach(item => {
    user[item] = user[item] || [];
  });

  return renderFn(user, { isLoading, refetch });
}
