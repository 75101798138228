import Page from '../components/page';
import { useModal, useHistoryListQuery } from '../hooks';
import UnitList from '../components/unit-list';


export default function UnitListPage() {
  const modal = useModal();
  const listQuery = useHistoryListQuery({
    implicitParams: {
      include: 'rental',
    }
  });

  return (
    <Page
      titleKey="unitList.title"
      userRole="admin"
      modal={modal}
    >
      <UnitList
        modal={modal}
        listQuery={listQuery}
      />
    </Page>
  );
}
