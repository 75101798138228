import wurd from 'wurd-react';
import { MenuItem } from 'react-bootstrap';

import * as helpers from '../helpers';

import LangText from './lang-text';
import MenuButton from './menu-button';

const cms = wurd.block('userView.charges.menu');


export default function MenuButton_Charges({
  onSelect,
  title = null,
  faIcon = 'far fa-plus',
}) {
  const availableCharges = helpers.settings.get().userCharges;

  return (
    <MenuButton
      title={title}
      faIcon={faIcon}
      cms={cms}
      cmsKeys="custom"
    >
      <MenuItem onSelect={onSelect}>{cms.text('custom')}</MenuItem>

      {availableCharges.length > 0 && (
        <MenuItem divider />
      )}

      {availableCharges.map(chargeType => (
        <MenuItem
          key={chargeType.id}
          onSelect={() => onSelect(chargeType)}
        >
          <LangText obj={chargeType.title} />
        </MenuItem>
      ))}
    </MenuButton>
  );
}
