import wurd from 'wurd-react';

import * as helpers from 'helpers';
import * as actions from 'actions';

import Section from 'components/section';
import DetailList from 'components/detail-list';
import MarkdownEditor from 'components/markdown-editor';
import SectionCrudList from 'components/section_crud-list';
import StateLabel from 'components/state-label';
import CustomFieldsForm from 'components/settings/custom-fields/form';
import CustomFieldsItem from 'components/settings/custom-fields/item';
import WebhookForm from 'components/settings/developer/webhook-form';
import WebtaskForm from 'components/settings/advanced/webtask-form';
import ApiKeyForm from 'components/settings/developer/api-key-form';
import customFieldsCrudList from 'plugins/custom-fields/settings-crud-list';
import { ModalForm, Field, CurrencyInput } from 'components/form2';


export default function AddonConfigDetailSettings({
  cms,
  modal,
  refetch,
  addonConfig,
}) {
  const Label = ({ id }) => (
    <cms.Text type="dt" id={`config.${id}.label`} />
  );

  async function editGeneral() {
    modal.open(
      <ModalForm
        mode="update"
        initialValue={{ price: addonConfig.price }}
        onSubmit={async (data) => {
          return actions.update('addon-configs', addonConfig.id, data);
        }}
        onSuccess={async () => {
          modal.close();
          refetch();
        }}
        closeModal={modal.close}
        wurdSection={cms.id('config.general')}
      >
        {(fieldProps) => (
          <>
            <Field {...fieldProps('price')} required autoFocus>
              <CurrencyInput min={0} type="number" before="$" />
            </Field>
          </>
        )}
      </ModalForm>
    );
  }


  return (
    <>
      <MarkdownEditor
        cms={cms.block('info')}
        content={addonConfig.info}
        onChange={(info) => actions.update('addon-configs', addonConfig.id, { info })}
        canEdit
      />

      <Section
        cms={cms.block('config.general')}
        onClickEdit={editGeneral}
      >
        <DetailList>
          <Label id="price" />
          <dd>
            {helpers.ui.currency(addonConfig.price, { currency: '$' })}
          </dd>
        </DetailList>
      </Section>

      <MarkdownEditor
        cms={cms.block('instructions')}
        content={addonConfig.instructions}
        onChange={(instructions) => actions.update('addon-configs', addonConfig.id, { instructions })}
        canEdit
      />

      {addonConfig.addonCustomFields && (
        <SectionCrudList
          cms={cms.block('customFieldsConfig')}
          items={addonConfig.addonCustomFields.sort(helpers.ui.sortByCode)}
          Form={CustomFieldsForm}
          formProps={{ allowFiles: false }}
          actions={customFieldsCrudList(`/v1/admin/addon-configs/${addonConfig.id}/addonCustomFields`)}
          onChange={refetch}
        >
          {item => <CustomFieldsItem item={item} />}
        </SectionCrudList>
      )}

      {addonConfig.apiKeys && (
        <SectionCrudList
          cms={cms.block('apiKeys')}
          items={addonConfig.apiKeys}
          Form={ApiKeyForm}
          actions={actions.addonConfigs.apiKeys(addonConfig.id)}
          onChange={refetch}
        >
          {apiKey => (
            <>
              <div className="pull-right">
                {apiKey.roles?.map((role) => (
                  <StateLabel key={role} type="warning">
                    {wurd.text(`user._roles.${role}`)}
                  </StateLabel>
                ))}
                {apiKey.permissions?.map((permission) => (
                  <StateLabel key={permission} type="warning">
                    {permission}
                  </StateLabel>
                ))}
              </div>

              <div><strong>{apiKey.name}</strong></div>
            </>
          )}
        </SectionCrudList>
      )}

      {addonConfig.webhooks && (
        <SectionCrudList
          cms={cms.block('webhooks')}
          items={addonConfig.webhooks}
          Form={WebhookForm}
          actions={actions.addonConfigs.webhooks(addonConfig.id)}
          onChange={refetch}
        >
          {webhook => {
            return (
              <>
                <div><strong>{webhook.url}</strong></div>
                <div className="text-muted">{webhook.events.sort((a, b) => a.localeCompare(b)).join(', ')}</div>
              </>
            );
          }}
        </SectionCrudList>
      )}

      {addonConfig.webtasks && (
        <SectionCrudList
          cms={cms.block('webtasks')}
          items={addonConfig.webtasks}
          Form={WebtaskForm}
          actions={actions.addonConfigs.webtasks(addonConfig.id)}
          onChange={refetch}
          //title={<h4>{wurd.text('settings.webtasks.title')}</h4>}
          modalProps={{ bsSize: 'lg' }}
        >
          {webtask => (
            <>
              <div className="pull-right">
                {webtask.roles?.map((role) => (
                  <StateLabel key={role} type="warning">
                    {wurd.text(`user._roles.${role}`)}
                  </StateLabel>
                ))}
                {webtask.permissions?.map((permission) => (
                  <StateLabel key={permission} type="warning">
                    {permission}
                  </StateLabel>
                ))}
              </div>

              <div><strong>{webtask.name}</strong></div>
              <div className="text-muted">{webtask.events.sort((a, b) => a.localeCompare(b)).join(', ')}</div>
            </>
          )}
        </SectionCrudList>
      )}
    </>
  );
}
