import ActiveJob from './active-job';
import UnitDetails from './details';


export default function UnitOverview({
  cms,
  site,
  unit,
  rental,
  modal,
  fetchUnit,
  tasksPlugin,
}) {
  return (
    <>
      <tasksPlugin.TaskList />

      <ActiveJob
        cms={cms}
        unit={unit}
        site={site}
        rental={rental}
        fetchUnit={fetchUnit}
        modal={modal}
      />
      
      <UnitDetails
        site={site}
        unit={unit}
        rental={rental}
        modal={modal}
        fetchUnit={fetchUnit}
      />
    </>
  );
}
