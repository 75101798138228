import wurd from 'wurd-react';

import * as actions from '../../../../actions';

import Section from '../../../section';
import ChargesForm from '../../../form/market/charges';


export default function MarketChargesSettings({ market }) {
  function onSubmit(data) {
    return actions.markets.update(market.id, {
      charges: data,
    });
  }

  return (
    <Section cms={wurd.block('marketSettings.charges')}>
      <ChargesForm
        defaultValue={market.charges || {}}
        onSubmit={onSubmit}
      />
    </Section>
  );
}
