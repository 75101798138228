import * as helpers from '../../../helpers';
import schema2form from '../schema2form';

import Checklist from '../checklist';
import ItemType from '../../settings/item-type';


const wurdSection = 'market.plans';

const schema = [
  {
    name: 'plans',
    custom: props => {
      var planConfigs = helpers.plan.list();

      return (
        <Checklist {...props}>
          {planConfigs.map(planConfig =>
            <ItemType key={planConfig.id} id={planConfig.id} item={planConfig} />
          )}
        </Checklist>
      )
    }
  }
];


export default schema2form(schema, { wurdSection });
