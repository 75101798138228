const styles = {
  main: {
    marginTop: 6,
    marginBottom: 6,
  },

  image: {
    float: 'left',
    marginRight: 10,
    width: 50,
    height: 50,
    objectFit: 'cover',
  },

  thumbnail: {
    float: 'left',
    marginRight: 10,
    width: 50,
    height: 50,
  },

  icon: {
    float: 'left',
    marginTop: 2,
    marginRight: 10,
    width: 50,
    height: 50,
    color: '#bbb',
    fontSize: 44,
  },

  type: {
    textTransform: 'uppercase',
    color: '#aaa',
    fontSize: 12,
    marginTop: 3,
  },

  title: {
    fontSize: 14,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  editBtn: {
    float: 'right',
    marginTop: -4,
    marginRight: -10,
  },
};


export default function SidebarItem({
  thumbnail,
  image,
  icon,
  typeTitle,
  itemTitle,
  onClickEdit,
}) {
  return (
    <div className="list-group-item clearfix">
      {onClickEdit && (
        <button
          type="button"
          className="btn btn-link"
          style={styles.editBtn}
          onClick={onClickEdit}
        >
          <i className="far fa-pencil" />
        </button>
      )}

      {thumbnail && (
        <div style={styles.thumbnail}>{thumbnail}</div>
      )}
      {image && (
        <img src={image} alt="" style={styles.image} />
      )}
      {icon && (
        <div style={styles.icon}>
          <i className={`${icon} fa-fw`} />
        </div>
      )}

      <div style={styles.type}>{typeTitle}</div>
      <div style={styles.title}>{itemTitle}</div>
    </div>
  );
}
