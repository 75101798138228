import ModalForm from '../../components/form2/modal-form';
import Field from '../../components/form2/horizontal-field';
import DatePicker from '../../components/form2/datepicker';

const wurdSection = 'accountPlugin.form';


export default function AccountPluginEntryForm({ fields, mode = 'create', ...rest }) {

  return (
    <ModalForm
      wurdSection={wurdSection}
      mode={mode}
      {...rest}
    >
      {(fieldProps) => (
        <>
          <Field {...fieldProps('desc')} required />

          <Field {...fieldProps('date')} required>
            <DatePicker
              minDate={null}
            />
          </Field>

          <Field {...fieldProps('endDate')}>
            <DatePicker
              minDate={null}
            />
          </Field>

          <Field {...fieldProps('amount')} required type="number" />
        </>
      )}
    </ModalForm>
  );

}
