import { useHistoryListQuery } from '../../hooks';
import * as actions from '../../actions';
import * as helpers from '../../helpers';

import ItemList from '../item-list';
import ItemForm from '../form2/item';


export default function UserItems({ modal, user }) {
  const listQuery = useHistoryListQuery({
    implicitParams: {
      ownerId: user.id,
      sort: 'state',
    },
    initialParams: {},
  });

  const market = helpers.market.get(user.market.id);

  function addItem({ refetch }) {
    const itemConfigs = helpers.item.listBulky();

    const item = {
      type: itemConfigs && itemConfigs[0] && itemConfigs[0].type,
      state: 'storage',
    };

    modal.open(
      <ItemForm
        initialValue={item}
        onSubmit={(data) => actions.items.createForUser(user.id, data)}
        onSuccess={() => {
          refetch();
          modal.close();
        }}
        closeModal={modal.close}
        mode="create"
      />,
    );
  }

  return (
    <ItemList
      listQuery={listQuery}
      modal={modal}
      market={market}
      hideOwner
      onClickAdd={addItem}
    />
  );
}
