import wurd from 'wurd-react';
import SiteLoader from 'components/loaders/site';
import * as helpers from 'helpers';


const cms = wurd.block('actions');

const actions = helpers.action;
const { date, href, currency } = helpers.ui;


function crudListActions(
  modelName,
  propName,
  icon,
  extra,
  vars = data => {
    // we want data[propNameSingular] ex: data.product for crudListActions('business', 'products'), so we ignore modelName, oldAttrs, newAttrs and merge the rest
    const { [modelName]: modelData, oldAttrs, newAttrs, ...rest } = data || {};

    // return localized fields (use getLangText for multilang field values like "title")
    return Object.fromEntries(
      Object.values(rest).flatMap(Object.entries).map(([k, v]) => [k, v && typeof v === 'object' ? helpers.ui.getLangText(v) : v])
    );
  }) {
  return {
    [`${modelName}_${propName}_create`]: {
      icon: `${icon} green`,
      vars,
      extra,
    },
    [`${modelName}_${propName}_update`]: {
      icon: `${icon}`,
      vars,
      extra: (data) => actions.formatChanges(data, `${modelName}.${propName}`),
    },
    [`${modelName}_${propName}_delete`]: {
      icon: `${icon} red`,
      vars,
      extra,
    },
  };
}

function makeTargetLink(context = '') {
  const capitalizedContext = context.charAt(0).toUpperCase() + context.slice(1);
  return actions[`make${capitalizedContext}Link`]?.(null, context);
}


const actionsMap = {
  invoice_entries_create: {
    icon: 'fa-money-bill-alt green',
    vars: (data) => ({ title: data.entry.desc }),
    extra: (data) => `${data.entry.desc}`,
  },
  invoice_entries_update: {
    icon: 'fa-money-bill-alt',
    vars: (data) => ({ title: data.entry.desc }),
    extra: (data) => actions.formatChanges(data),
  },
  invoice_entries_delete: {
    icon: 'fa-money-bill-alt red',
    vars: (data) => ({ title: data.entry.desc }),
    extra: (data) => `${data.entry.desc}`,
  },
  accountEntry_create: {
    icon: 'fa-money-bill-alt green',
    vars: (data) => ({ title: data.accountEntry.desc }),
  },
  updateUser: {
    icon: 'fa-user',
    extra: (data) => actions.formatChanges(data, 'user')
  },
  updateUserMarket: {
    icon: 'fa-map-marker',
    extra(data) {
      return helpers.market.getTitle(data.market.id);
    }
  },
  updateUserPlan: {
    icon: 'fa-certificate',
    extra(data) {
      const plan = helpers.plan.get(data.planId, true) || {};
      return `${plan.title}: ${plan.price}`;
    }
  },
  removeUserPlan: {
    icon: 'fa-certificate red'
  },
  addUserComment: {
    icon: 'fa-comment',
    extra: (data) => data.text
  },
  disableUserAccount: {
    icon: 'fa-user red',
    extra: (data) => data.reason
  },
  enableUserAccount: {
    icon: 'fa-user green'
  },
  createBulkyItem: {
    icon: 'fa-suitcase green'
  },
  deleteItem: {
    icon: 'fa-suitcase red'
  },
  updateItem: {
    icon: 'fa-archive',
    extra: (data) => actions.formatChanges(data, 'item')
  },
  updateItemLocation: {
    icon: 'fa-archive',
    extra: (data) => data.newVal
  },
  updateItemState: {
    icon: 'fa-archive orange',
    extra: (data) => {
      const oldState = wurd.text(`item._states.${data.oldVal}`);
      const newState = wurd.text(`item._states.${data.newVal}`);

      return `<del>${oldState}</del> __${newState}__`;
    }
  },
  unassignItem: {
    icon: 'fa-archive red'
  },
  updateItemContents: {
    icon: 'fa-archive'
  },
  addItemImage: {
    icon: 'fa-camera green'
  },
  addItemComment: {
    icon: 'fa-comment',
    extra: (data) => data.text
  },
  pickItems: {
    icon: 'fa-building'
  },
  deliverItems: {
    icon: 'fa-truck'
  },
  collectItems: {
    icon: 'fa-truck'
  },
  storeItems: {
    icon: 'fa-building green'
  },
  createVisit: {
    icon: 'fa-truck green'
  },
  updateVisit: {
    icon: 'fa-truck',
    extra: (data) => actions.formatChanges(data, 'visit')
  },
  updateVisitOrderedItems: {
    icon: 'fa-truck',
    extra: (data) => actions.formatChanges(data, 'visit')
  },
  createRelatedVisit: {
    icon: 'fa-truck',
    extra: (data) => data.reason,
    vars(data) {
      return {
        childVisit: actions.makeVisitLink(data.visit, null),
        parentVisit: actions.makeVisitLink(data.relatedVisit, null)
      }
    }
  },
  completeVisit: {
    icon: 'fa-truck green'
  },
  cancelVisit: {
    icon: 'fa-truck red'
  },
  failVisitDelivery: {
    icon: 'fa-truck red'
  },
  failVisitCollection: {
    icon: 'fa-truck red'
  },
  addVisitCharge: {
    icon: 'fa-money-bill green',
    extra(data) {
      return `${data.charge.note}: ${data.charge.amount}`;
    }
  },
  removeVisitCharge: {
    icon: 'fa-money-bill red',
    extra(data) {
      return `${data.charge.note}: ${data.charge.amount}`;
    }
  },
  addVisitComment: {
    icon: 'fa-comment',
    extra: (data) => data.text
  },
  assignVisit: {
    icon: 'fa-users',
    vars(data) {
      return {
        assignee: actions.makeUserLink(data.assignee)
      }
    }
  },
  unassignVisit: {
    icon: 'fa-users red'
  },
  createInvoice: {
    icon: 'fas fa-file-invoice-dollar green',
  },
  markInvoiceDraft: {
    icon: 'fas fa-file-invoice-dollar orange',
  },
  markInvoiceSent: {
    icon: 'fas fa-file-invoice-dollar orange',
  },
  markInvoicePaid: {
    icon: 'fas fa-file-invoice-dollar green',
  },
  deleteInvoice: {
    icon: 'fas fa-file-invoice-dollar red',
  },
  chargeWithGmopg: {
    icon: 'fa-landmark orange',
    vars: (data) => data,
  },
  chargeInvoice: {
    icon: 'fa-check green',
  },
  chargeInvoiceFailed: {
    icon: 'fa-times red',
    extra: (data) => data.reason,
  },
  sendInvoice: {
    icon: 'fa-paper-plane green'
  },
  sendReceipt: {
    icon: 'fa-credit-card green'
  },
  addInvoiceComment: {
    icon: 'fa-comment',
    extra: (data) => data.text
  },

  ...crudListActions('invoice', 'items', 'fa-money-bill-alt', data => {
    const item = data.invoiceItem;
    return `${item.desc}: ${item.total}`;
  }),

  invoice_payments_create: {
    icon: 'fa-money-bill green',
    extra: actions.formatInvoicePayment
  },
  invoice_payments_update: {
    icon: 'fa-money',
    extra: actions.formatInvoicePayment
  },
  invoice_payments_delete: {
    icon: 'fa-money-bill red',
    extra: actions.formatInvoicePayment
  },
  updateMarket: {
    icon: 'fa-map-marker',
    vars(data) {
      return { market: actions.makeMarketLink(data.market, null) }
    },
    extra: (data) => actions.formatChanges(data, 'market'),
  },

  updateBusiness: {
    icon: 'fa-store-alt',
    extra: (data) => actions.formatChanges(data, 'business'),
  },

  'business.comment': {
    icon: 'fa-comment',
    extra: (data) => data.text
  },

  business_plans_create: {
    icon: 'fa-certificate green',
    vars(data) {
      return {
        plan: actions.makePlanLink(data.plan, null)
      }
    }
  },
  business_plans_update: {
    icon: 'fa-certificate',
    vars(data) {
      return {
        plan: actions.makePlanLink(data.plan, null)
      }
    }
  },
  business_plans_delete: {
    icon: 'fa-certificate red',
    vars(data) {
      return {
        plan: actions.makePlanLink(data.plan, null)
      }
    }
  },

  ...crudListActions('business', 'userCharges', 'fa-money-bill-alt'),

  ...crudListActions('business', 'invoiceLabels', 'fa-tags'),
  ...crudListActions('business', 'itemLabels', 'fa-tags'),
  ...crudListActions('business', 'jobLabels', 'fa-tags'),
  ...crudListActions('business', 'siteLabels', 'fa-tags'),
  ...crudListActions('business', 'unitLabels', 'fa-tags'),
  ...crudListActions('business', 'userLabels', 'fa-tags'),
  ...crudListActions('business', 'valetOrderLabels', 'fa-tags'),

  ...crudListActions('business', 'unitPromos', 'fa-star'),

  ...crudListActions('business', 'apiKeys', 'fa-key'),

  ...crudListActions('business', 'promos', 'fa-star'),

  ...crudListActions('business', 'unitTags', 'fa-warehouse'),

  ...crudListActions('business', 'accountingCodes', 'fa-calculator'),

  ...crudListActions('business', 'items', 'fa-archive'),
  ...crudListActions('business', 'products', 'fa-archive'),
  ...crudListActions('market', 'items', 'fa-archive'),

  ...crudListActions('business', 'userCustomFields', 'fa-brackets-curly'),
  ...crudListActions('business', 'businessCustomFields', 'fa-brackets-curly'),
  ...crudListActions('business', 'valetOrderCustomFields', 'fa-brackets-curly'),
  ...crudListActions('business', 'invoiceCustomFields', 'fa-brackets-curly'),
  ...crudListActions('business', 'itemCustomFields', 'fa-brackets-curly'),
  ...crudListActions('business', 'siteCustomFields', 'fa-brackets-curly'),
  ...crudListActions('business', 'unitCustomFields', 'fa-brackets-curly'),
  ...crudListActions('business', 'unitRentalCustomFields', 'fa-brackets-curly'),

  ...crudListActions('unitRental', 'charges', 'fa-money-bill-wave'),

  user_charges_create: {
    icon: 'fa-money-bill green',
    extra: actions.makeUserChargeExtra
  },
  user_charges_update: {
    icon: 'fa-money',
    extra: actions.makeUserChargeExtra
  },
  user_charges_delete: {
    icon: 'fa-money-bill red',
    extra: actions.makeUserChargeExtra
  },

  user_credits_create: {
    icon: 'fa-money-bill green',
    extra: actions.makeUserCreditExtra
  },
  user_credits_update: {
    icon: 'fa-money',
    extra: actions.makeUserCreditExtra
  },
  user_credits_delete: {
    icon: 'fa-money-bill red',
    extra: actions.makeUserCreditExtra
  },

  user_files_create: {
    icon: 'fa-file-alt green',
    extra: actions.makeUserFileExtra
  },
  user_files_delete: {
    icon: 'fa-file-alt red',
    extra: actions.makeUserFileExtra
  },

  item_opsImages_create: {
    icon: 'fa-camera green',
    extra(data) {
      return `<img src="${data.image.url}" />`
    }
  },
  item_opsImages_delete: {
    icon: 'fa-camera red',
    extra(data) {
      return `<img src="${data.image.url}" style="opacity: .3" />`
    }
  },

  item_owner_update: {
    icon: 'fa-user orange',
    extra(data) {
      const { oldOwner, newOwner } = data;

      let str = '';

      if (oldOwner) {
        str += `From: <a href="${href('/users/' + oldOwner.id)}">${oldOwner.name}</a><br />`;
      }

      if (newOwner) {
        str += `To: <a href="${href('/users/' + newOwner.id)}">${newOwner.name}</a>`;
      }

      return str;
    }
  },

  'item.setState': {
    extra: (data) => {
      let text = wurd.text(`item._states.${data.state}`);
      if (data.location) text += `: ${data.location}`;
      return text;
    },
  },

  'item.setLocation': {
    extra: (data) => data.location,
  },

  'site.create': {
    icon: 'fa-building green'
  },

  'site.update': {
    icon: 'fa-building orange',
    extra: (data) => actions.formatChanges(data, 'site')
  },

  'site_products_create': {
    icon: 'fa-boxes green',
    extra: (data) => actions.formatSiteProduct(data)
  },
  'site_products_update': {
    icon: 'fa-boxes orange',
    vars: (data) => ({ title: helpers.ui.getLangText(data.product.title) }),
    extra: (data) => actions.formatSiteProduct(data)
  },
  'site_products_delete': {
    icon: 'fa-boxes red',
    extra: (data) => actions.formatSiteProduct(data)
  },

  'site.comment': {
    icon: 'fa-comment',
    extra: (data) => data.text
  },

  'unitType.create': {
    icon: 'fa-box-alt green',
    extra: (data) => actions.formatUnitType(data)
  },
  'unitType.update': {
    icon: 'fa-box-alt orange',
    vars: (data) => ({ title: helpers.ui.getLangText(data.unitType.title) }),
    extra: (data) => actions.formatUnitType(data)
  },
  'unitType.delete': {
    icon: 'fa-box-alt red',
    extra: (data) => actions.formatUnitType(data)
  },
  'unitType.comment': {
    icon: 'fa-comment',
    extra: (data) => data.text
  },

  'securitySystem.moveIn': {
    icon: 'fa-sign-in orange',
  },
  'securitySystem.moveOut': {
    icon: 'fa-sign-out orange',
  },
  'securitySystem.setAccessCode': {
    icon: 'fa-shield-alt orange',
  },
  'securitySystem.overlock': {
    icon: data => `${data.overlocked ? 'fa-lock-alt' : 'fa-unlock-alt'} orange`,
  },

  'unit.create': {
    icon: 'fa-key green'
  },

  'unit.createRental': {
    icon: 'fa-key blue'
  },

  'unit.update': {
    icon: 'fa-key orange',
    extra: (data) => actions.formatChanges(data, 'unit')
  },

  'unit.occupy': {
    icon: 'fa-key green',
  },

  'unit.reserve': {
    icon: 'fa-key blue',
  },

  'unit.unassign': {
    icon: 'fa-key red'
  },

  'unit.block': {
    icon: 'fa-ban orange',
    extra: (data) => data.reason
  },

  'unit.unblock': {
    icon: 'fa-ban green'
  },

  'unit.scheduleMoveOut': {
    icon: 'fa-key orange',
    extra: (data) => date(data.date)
  },

  'unit.comment': {
    icon: 'fa-comment',
    extra: (data) => data.text
  },

  'unit.archive': {
    icon: 'far fa-warehouse red',
  },

  unit_charges_create: {
    icon: 'fa-money-bill-alt green',
    extra: (data) => actions.formatUnitCharge(data)
  },

  unit_charges_update: {
    icon: 'fa-money-bill-alt',
    extra: (data) => actions.formatUnitCharge(data)
  },

  unit_charges_delete: {
    icon: 'fa-money-bill-alt red',
    extra: (data) => actions.formatUnitCharge(data)
  },

  'unitRental.update': {
    icon: 'fa-key orange',
    extra: (data) => actions.formatChanges(data, 'unitRental')
  },

  'unitRental.setAccessCode': {
    icon: 'fa-shield-alt orange',
  },

  'unitRental.createChargesFromCustomPromo': {
    icon: 'fa-star green',
    vars: (data) => ({ title: helpers.ui.getLangText(data.customPromo.title) }),
  },

  'job.comments.add': {
    icon: 'fa-comment',
    extra: (data) => data.text
  },

  'valetOrder.create': {
    icon: 'fa-truck green'
  },

  'valetOrder.update': {
    icon: 'fa-truck',
    extra: (data) => actions.formatChanges(data, 'valetOrder')
  },


  'valetOrder.addItem': 'fa-archive green',
  'valetOrder.removeItem': 'fa-archive red',

  'valetOrder.confirm': 'fa-check green',
  'valetOrder.pickEmptyBoxes': 'fa-check green',
  'valetOrder.pickStoredItems': 'fa-check green',
  'valetOrder.pickProducts': 'fa-check green',
  'valetOrder.itemsDelivered': 'fa-check green',
  'valetOrder.itemsCollected': 'fa-check green',
  'valetOrder.stored': 'fa-check green',

  'valetOrder.comments.add': {
    icon: 'fa-comment',
    extra: (data) => data.text
  },

  'valetOrder_charges_create': {
    icon: 'fa-money-check-alt',
    extra: data => data.charge?.desc,
  },

  'valetOrder_charges_delete': {
    icon: 'fa-money-check-alt orange',
    extra: data => data.charge?.desc,
  },

  'valetOrders.assignDriver': {
    icon: 'fa-user-plus',
    vars(data) {
      return {
        driver: actions.makeUserLink(data.driver),
        count: data.valetOrders?.length
      }
    },
    extra(data) {
      const { valetOrders } = data;
      if (!valetOrders) return '';

      return valetOrders.map(order => {
        const sid = order.sid?.toUpperCase();

        return `<a href="${href('/valet-orders/' + sid)}">${sid}</a>`;
      }).join(', ');
    }
  },

  'valetOrders.unassignDriver': {
    icon: 'fa-user-times',
    vars(data) {
      return {
        count: data.valetOrders?.length
      }
    },
    extra(data) {
      const { valetOrders } = data;
      if (!valetOrders) return '';

      return valetOrders.map(order => {
        const sid = order.sid?.toUpperCase();

        return `<a href="${href('/valet-orders/' + sid)}">${sid}</a>`;
      }).join(', ');
    }
  },

  'valetOrder.cancelled': 'fa-truck red',
  'valetOrder.createBulkyItems': 'fa-suitcase',

  'unitOrder.update': {
    icon: 'fa-warehouse',
    extra: (data) => actions.formatChanges(data, 'unitOrder')
  },

  'unitOrder.comments.add': {
    icon: 'fa-comment',
    extra: (data) => data.text,
  },

  'user.signup': {
    icon: 'fa-user-plus green',
  },
  'user.signin': {
    icon: (data) => `fa-sign-in ${data.isNew ? 'green' : ''}`,
    vars: (data) => ({ source: data.source || 'password' }),
  },
  'user.confirmEmailSent': {
    icon: 'fa-user-check orange',
  },
  'user.confirmEmailSuccess': {
    icon: 'fa-user-check green',
  },

  'user.billing.create': {
    icon: 'fa-file-invoice-dollar green',
    vars: (data) => data,
  },
  'user.billing.update': {
    icon: 'fa-file-invoice-dollar blue',
    extra: (data) => actions.formatChanges(data),
  },
  'user.billing.updatePaymentType': {
    icon: (data) => `${data.current === 'directDebit' ? 'fa-landmark' : data.current === 'creditCard' ? 'fa-credit-card' : 'fa-exclamation'} blue`,
    vars: (data) => data,
  },
  'user.billing.addCard': {
    icon: 'fa-credit-card green',
    vars: (data) => ({ ...data, cardNo: data.cardNo ?? `…${data.last4}` }),
  },
  'user.billing.addBankAccount': {
    icon: 'fa-landmark green',
    vars: (data) => ({ ...data, bankName: helpers.ui.getLangText(data.bankName) })
  },
  'markOverdue': {
    icon: 'fa-clock red',
    vars: (data, context) => ({ target: makeTargetLink(context) }),
  },
  'unmarkOverdue': {
    icon: 'fa-clock green',
    vars: (data, context) => ({ target: makeTargetLink(context) }),
  },

  'item.valetOrder_add': 'fa-boxes-alt',
  'item.valetOrder_remove': 'fa-boxes-alt orange',
  'items.valetOrder_add': 'fa-boxes-alt',
  'items.valetOrder_remove': 'fa-boxes-alt orange',
  'items.valetOrder_deliver': 'fa-hand-holding',
  'items.valetOrder_undeliver': 'fa-hand-holding orange',
  'items.valetOrder_collect': 'fa-hand-holding-box',
  'items.valetOrder_uncollect': 'fa-hand-holding-box orange',
  'items.valetOrder_store': 'fa-box green',
  'items.valetOrder_unassign': 'fa-user-times orange',

  'webhook.failed.autoSkip': {
    icon: 'fa-code orange',
    extra: (data) => `Event: \`${data.event}\`\r\nWebhook: \`${data.webhookUrl}\`\r\nJob: \`${data.jobType} ${data.jobId}\``,
  },
  'webhook.failed.autoDisable': {
    icon: 'fa-code red',
    extra: (data) => `Webhook: \`${data.webhookUrl}\``,
  },
  
  'unitRental.jobs.updatePrice.create': {
    icon: 'fa-dollar-sign',
    extra: (data) => `${date(data.startDate)}\r\n${currency(data.oldPrice)} → ${currency(data.newPrice)}`,
  },
  'unitRental.jobs.updatePrice.complete': {
    icon: 'fa-dollar-sign green',
    extra: (data) => `${currency(data.oldPrice)} → ${currency(data.newPrice)}`,
  },
  'unitRental.jobs.updatePrice.cancel': {
    icon: 'fa-dollar-sign red',
    extra: (data) => `${date(data.startDate)}\r\n${currency(data.oldPrice)} → ${currency(data.newPrice)}`,
  },

  'unit.jobs.moveIn.cancel': {
    extra: (data) => data.cancelledType,
  },

  'user.inviteToAdmin': {
    icon: 'fa-user-plus green',
    vars: (data, context) => ({
      inviterUser: actions.makeUserLink(data.inviterUser, context),
      inviteeUser: actions.makeUserLink(data.inviteeUser, context),
    }),
  },
  
  'addon.install': { icon: 'fa-cubes green' },
  'addon.uninstall': { icon: 'fa-cubes red' },
  'addon.enable': { icon: 'fa-cubes green' },
  'addon.disable': { icon: 'fa-cubes red' },
  'addon.setSite': { icon: 'fa-cubes orange', extra: (data) => data.siteId && <SiteLoader id={data.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader> },
  'addon.setCustomFields': { icon: 'fa-cubes' },
};


export default function getAction(currentUser, action, context) {
  const data = action.data || {};

  const actionConfig = actionsMap[action.type] || {};

  // Create actionVars, which are used in the locale string template
  const actionVars = {
    ...data,
    user: actions.makeUserLink(action.user),
  };

  if (data.user) {
    actionVars.userTarget = actions.makeUserLink(data.user);
  }

  if (data.visit) {
    actionVars.visit = actions.makeVisitLink(data.visit, context);
  }

  if (data.item) {
    actionVars.item = actions.makeItemLink(data.item, context);
  }

  if (data.site) {
    actionVars.site = actions.makeSiteLink(data.site, context);
  }

  if (data.unit) {
    actionVars.unit = actions.makeUnitLink(data.unit, context);
  }

  if (data.unitType) {
    actionVars.unitType = actions.makeUnitTypeLink(data.unitType, context);
  }

  if (data.unitRental) {
    actionVars.unitRental = actions.makeUnitRentalLink(data.unitRental, context);
  }

  if (data.oldUnit) {
    actionVars.oldUnit = actions.makeUnitLink(data.oldUnit);
  }

  if (data.newUnit) {
    actionVars.newUnit = actions.makeUnitLink(data.newUnit);
  }

  if (data.owner) {
    actionVars.owner = actions.makeUserLink(data.owner, context);
  }

  if (data.invoice) {
    actionVars.invoice = actions.makeInvoiceLink(data.invoice);
  }

  if (data.job) {
    actionVars.job = actions.makeJobLink(data.job);
  }

  if (data.valetOrder) {
    actionVars.valetOrder = actions.makeValetOrderLink(data.valetOrder, context);
  }

  if (data.unitOrder) {
    actionVars.unitOrder = actions.makeUnitOrderLink(data.unitOrder);
  }

  if (data.business) {
    actionVars.business = cms.text('_links.business_businessContext');
  }

  if (data.addon) {
    actionVars.addon = actions.makeAddonLink(data.addon, context);
  }

  // If a vars() method is defined it can be used to add extra vars
  if (actionConfig.vars) {
    Object.assign(actionVars, actionConfig.vars(data, context));
  }

  const faIcon = actions.getIcon(actionConfig, data, context) || 'fa-empty';

  // Create the action object sent to the template
  return {
    icon: `fa fa-fw ${faIcon}`,
    text: cms.text(action.type, actionVars) || `${actionVars.user} – ${action.type}`,
    extra: actionConfig.extra?.(data) || data.newAttrs && actions.formatChanges(data), // Add extra text if configured
  };
}
