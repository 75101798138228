import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as helpers from '../../helpers';
import * as actions from '../../actions';

import { ModalDialog } from '../modal';
import ExportButton from '../export-button';
import ImportButton from '../import-button';


export default function UnitListMenu({
  modal,
  site,
  cms,
  listQuery,
  refetch,
}) {
  const params = useParams();

  if (!modal) return null;

  const handleComplete = (items) => Promise.all(
    [...new Set(items.filter(Boolean).map(o => o.siteId))]
      .map(siteId => actions.update('sites', siteId, { updateAvailability: true }))
  )
    .then(refetch);

  function openExportModal() {
    const settings = helpers.settings.get();

    modal.open(
      <ModalDialog cms={cms.block('exportModal')}>
        <ExportButton
          filename="unit-rentals.csv"
          fetch={params => helpers.unitRental.fetchDetailed({ ...listQuery.params, ...params, include: 'unit,agreementUrl,customFields' })}
          toCsv={helpers.unitRental.toCsv}
          sections={{
            main: ['price', 'deposit', 'startDate', 'endDate', 'depositBalance', 'prepaymentBalance', 'revenueBalance', 'invoiceDate', 'agreementUrl'],
            owner: ['firstName', 'lastName', 'email', 'companyName'],
            unit: ['name', 'floor', 'width', 'height', 'length', 'measure', 'area', 'volume', 'state', 'defaultPrice', 'defaultDeposit'],
          }}
        />
      </ModalDialog>,
    );
  }

  function openImportModal() {
    modal.open(
      <ModalDialog cms={cms.block('importModal')}>
        <ImportButton
          processRow={async row => actions.create('unit-rentals', await helpers.unitRental.fromCsv(row, params), { include: 'customFields' })}
          onComplete={handleComplete}
          mode="create"
        />
      </ModalDialog>,
    );
  }

  function openUpdateModal() {
    modal.open(
      <ModalDialog cms={cms.block('updateModal')}>
        <ImportButton
          processRow={async row => actions.update('unit-rentals', row.id, await helpers.unitRental.fromCsvUpdate(row), { include: 'customFields' })}
          onComplete={handleComplete}
          mode="update"
        />
      </ModalDialog>,
    );
  }

  return (
    <cms.Object id="menu" keys="export,import,update,helpUrl">
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="menu"
        bsStyle="link"
        pullRight
        noCaret
      >
        <MenuItem
          key="export"
          onSelect={openExportModal}
        >
          <i className="fas fa-fw fa-download" /> {cms.text('menu.export')}
        </MenuItem>

        {helpers.auth.hasRole('manager') && (
          <>
            <MenuItem
              key="import"
              onSelect={openImportModal}
            >
              <i className="fas fa-fw fa-upload" /> {cms.text('menu.import')}
            </MenuItem>
            <MenuItem
              key="update"
              onSelect={openUpdateModal}
            >
              <i className="fal fa-fw fa-upload" /> {cms.text('menu.update')}
            </MenuItem>
          </>
        )}
        {cms.text('menu.helpUrl') && (
          <MenuItem href={cms.text('menu.helpUrl')} target="_blank">
            <i className="fal fa-fw fa-external-link" /> {cms.wurd.text('common.filters.helpText') || 'Help'}
          </MenuItem>
        )}
      </DropdownButton>
    </cms.Object>
  );
}
