import { useParams } from 'react-router-dom';
import * as helpers from '../../helpers';
import { useItemLoader } from '../../hooks';

import NotFound from '../../components/not-found';
import Spinner from '../../components/spinner';
import ErrorBoundary from '../../components/error-boundary';

import UnitMoveInJobPage from './unit_move-in';
import UnitMoveOutJobPage from './unit_move-out';
import UnitTransferPage from './unit_transfer';

const pagesByType = {
  unit_moveIn: UnitMoveInJobPage,
  unit_moveOut: UnitMoveOutJobPage,
  unit_transfer: UnitTransferPage,
};


export default function UnitJobDetailPage() {
  const { idOrSid } = useParams();

  const { item: job, isLoading, refetch } = useItemLoader('jobs', idOrSid, {
    altIdKey: 'sid',
    maxAge: 0,
  });
  const { item: site } = useItemLoader('sites', job?.result.siteId);

  if (!job) {
    return isLoading ? <Spinner /> : <NotFound />;
  }

  const InnerPage = pagesByType[job.type];
  if (!InnerPage) return <NotFound />;

  return (
    <ErrorBoundary>
      <InnerPage
        site={site || {}}
        job={job}
        fetchJob={refetch}
      />
    </ErrorBoundary>
  );
}
