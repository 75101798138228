import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import moment from 'moment-timezone';
import wurd, { WurdText } from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';

import Section from '../section';
import Table from './table';
import InvoicePaymentForm from '../form/invoice/payment';


const cms = wurd.block('invoiceView.payments');

export default function InvoicePayments({
  modal,
  invoice,
  onChange,
}) {
  function handleChange() {
    modal.close();
    onChange();
  }

  function renderPayment(payment) {
    return (
      <tr key={payment.id}>
        <td className="fitContent">{helpers.ui.date(payment.date || payment.created)}</td>

        {renderDetails(payment)}

        <td className="text-right fitContent">{helpers.ui.currency(payment.amount)}</td>
      </tr>
    );
  }

  function renderDetails(payment) {
    const transactionId = helpers.invoice.getTransactionId(payment);

    switch (payment.type) {
      case 'manual':
        return (
          <td
            style={{ cursor: 'pointer' }}
            onClick={() => editItem(payment)}
          >
            <div style={{ fontWeight: 400 }}>{helpers.payment.method_display(payment)}</div>
            {payment.notes && (
              <div className="text-muted" style={{ wordBreak: 'break-all' }}>{payment.notes}</div>
            )}
          </td>
        );

      case 'credit':
        return (
          <td
            style={{ cursor: 'pointer' }}
            onClick={() => editItem(payment)}
          >
            <div style={{ fontWeight: 400 }}><cms.Text id="credit" /></div>
          </td>
        );

      default:
        return transactionId
          ? (
            <td>
              <div style={{ fontWeight: 400 }}>{helpers.payment.type_display(payment)}</div>
              <div className="text-muted" style={{ wordBreak: 'break-all' }}>{transactionId}</div>
            </td>
          ) : (
            <td>
              {payment.type}
            </td>
          );
    }
  }

  function addItem() {
    const payment = {
      type: 'manual',
      date: moment().format('YYYY-MM-DD'),
      amount: invoice.balance,
    };

    modal.open(
      <InvoicePaymentForm
        defaultValue={payment}
        onSubmit={actions.invoices.payments(invoice.id).create}
        onSuccess={handleChange}
        title={<cms.Text id="addModal.title" vars={{ invoiceId: invoice.sid.toUpperCase() }} />}
        submitButton={<cms.Text id="addModal.ok" />}
        modal
        onHideModal={modal.close}
      />,
    );
  }

  function editItem(payment) {
    modal.open(
      <>
        <div className="modal-body">
          <table className="table no-border">
            <tbody className="no-border-x no-border-y">
              {renderPayment(payment)}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger pull-left"
            onClick={() => deleteItem(payment)}
          >
            <cms.Object id="editModal" keys="delete,confirmDelete">
              {cms.text('editModal.delete')}
            </cms.Object>
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={modal.close}
          >
            <WurdText id="common.okBtn" />
          </button>
        </div>
      </>,
    );
  }

  function deleteItem(payment) {
    if (!window.confirm(cms.text('editModal.confirmDelete'))) return;

    actions.invoices.payments(invoice.id).delete(payment.id)
      .then(handleChange);
  }


  return (
    <Section
      cms={cms}
      onClickAdd={invoice.state !== 'paid' && !invoice.deleted && addItem}
    >
      {invoice.payments.length > 0 && (
        <>
          <Table className="table no-border">
            <thead>
              <tr>
                <cms.Text type="th" id="date" />
                <cms.Text type="th" id="details" />
                <cms.Text type="th" id="amount" />
              </tr>
            </thead>
            <tbody>
              {invoice.payments.map(renderPayment)}
            </tbody>
          </Table>

          <hr />
          <div className="pull-right" style={{ fontSize: '1.3em', textAlign: 'right', fontWeight: 600 }}>
            <cms.Text id="balance" />: {helpers.ui.currency(invoice.balance)}
          </div>
        </>
      )}
    </Section>
  );
}
