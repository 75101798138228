import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import * as helpers from '../../helpers';

import ListFilter from '../filter/list';
import TextFilter from '../filter/text';
import UserFilter from '../filter/user';
import StateLabel from '../state-label';
import LabelsFilter from 'plugins/labels/filter';
import SearchFilter from '../filter/search';

const cms = wurd.block('itemList.filters');

const { states, stateLabelTypes } = helpers.item;


export default function ItemListFilters({
  listQuery,
  hideOwner,
}) {
  const { params } = listQuery;

  function onChange(key, value) {
    listQuery.update({
      offset: 0,
      [key]: value,
    });
  }

  const setState = (val) => onChange('state', val);
  const setType = (val) => onChange('type', val);
  const setLocation = (val) => onChange('location', val);
  const setOwner = (val) => onChange('owner', val);

  return (
    <>
      <cms.Object id="state" keys={`title,all,${states.join(',')}`}>
        <ListFilter
          title={cms.text('state.title')}
          active={params.state}
          onSelect={setState}
          multi
        >
          {states.map((state) => (
            <li key={state} value={state}><StateLabel type={stateLabelTypes[state]}>{cms.text(`state.${state}`)}</StateLabel></li>
          ))}
        </ListFilter>
      </cms.Object>

      <cms.Object id="type" keys="title,all">
        <ListFilter
          title={cms.text('type.title')}
          active={params.type}
          onSelect={setType}
        >
          <li value={null}>{cms.text('type.all')}</li>
          {helpers.item.list(true).map((itemConfig) => (
            <li key={itemConfig.type} value={itemConfig.type}>
              {itemConfig.title}
            </li>
          ))}
        </ListFilter>
      </cms.Object>

      <cms.Object id="location" keys="title">
        <TextFilter
          title={cms.text('location.title')}
          active={params.location}
          onSelect={setLocation}
        />
      </cms.Object>

      {!hideOwner && (
        <UserFilter
          active={params.owner}
          onSelect={setOwner}
        />
      )}

      <LabelsFilter
        active={params.labels}
        onSelect={(val) => onChange('labels', val)}
        labels={helpers.settings.get().itemLabels}
      />

      <SearchFilter
        name="item-search"
        cms={wurd.block('itemList.lookup')}
        active={params.search}
        onSelect={(val) => onChange('search', val)}
        modelName="item"
        searchables={['location']}
      />
    </>
  );
}
