import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import { sid } from '../../helpers/ui';
import * as actions from '../../actions';
import { useHistoryListQuery } from '../../hooks';

import InvoiceList from '../invoice-list';
import UserInvoiceForm from './forms/invoice';


export default function UserInvoices({
  settings,
  modal,
  user,
}) {
  const navigate = useNavigate();
  const listQuery = useHistoryListQuery({
    implicitParams: {
      ownerId: user.id,
    },
    initialParams: {
      state: null,
    },
  });

  function addInvoice({ refetch }) {
    const formData = {
      invoiceDate: moment().format('YYYY-MM-DD'),
    };

    modal.open(
      <UserInvoiceForm
        initialValue={formData}
        onSubmit={(data) => actions.invoices.create(user.id, data)}
        onSuccess={(invoice) => {
          refetch();
          modal.close();
          navigate(`/invoices/${sid(invoice)}`);
        }}
        closeModal={modal.close}
        mode="create"
      />,
    );
  }

  return (
    <InvoiceList
      listQuery={listQuery}
      modal={modal}
      hideOwner
      onClickAdd={settings.modes.includes('valetStorage') && addInvoice}
    />
  );
}
