import styled from '@emotion/styled';
import wurd from 'wurd-react';

import LangText from '../../lang-text';

const ownerAclIcon = {
  'read': 'far fa-user',
  'read,create': 'far fa-user-plus',
  'read,create,update': 'far fa-user-edit',
};

function minmax(min, max) {
  if (min == null && max == null) return '';

  if (min === max) return min;

  return `${min ?? ''}…${max ?? ''}`;
} 


function Item({ item, ...props }) {
  return (
    <div {...props}>
      <span className="main">
        <strong data-required={item.required}><LangText obj={item.title} /></strong>
        <small style={{ marginLeft: 12, marginRight: 12 }}>{item.code}</small>
        {item.ownerAcl && <i style={{ marginLeft: 6 }} className={ownerAclIcon[item.ownerAcl]} title={item.ownerAcl} />}
        {item.secret && <i style={{ marginLeft: 6, fontSize: '90%', filter: 'opacity(.5)' }} className="far fa-eye-slash" title="Hidden secret" />}
      </span>

      <span className="info text-muted">
        {wurd.text(`settings.customFields.type.${item.type}`) || item.type}

        <span className="extra">
          {item.enum && wurd.text('settings.customFields.enum.label')?.toLowerCase()}

          {minmax(item.minLength, item.maxLength)}

          {minmax(item.minimum, item.maximum)}
        </span>
      </span>
    </div>
  );
}


export default styled(Item)({
  display: 'flex',

  '& [data-required=true]::after': {
    content: '" *"',
    color: 'red',
    opacity: '.4',
  },

  '.main': {
    flex: 2,
  },

  '.info': {
    flex: 1,
  },

  '.extra': {
    marginLeft: 4,
  },
});
