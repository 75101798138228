/**
 * A simple list of items without their current state etc.
 * Used in the history/results section of steps
 */
import ItemsLoader from '../../loaders/items';
import Item from './item';


export default ({ itemIds }) => (
  <ItemsLoader ids={itemIds}>
    {items => (
      <div>
        {items.map(item => <Item key={item.id} item={item} />)}
      </div>
    )}
  </ItemsLoader>
);
