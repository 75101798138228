import styled from '@emotion/styled';
import memoize from 'lodash/memoize';

import store from '../../store';

import Label from './item';
import { getLangText } from 'helpers/ui';


const getLabelsById = memoize((settings) => {
  // console.info('labelsById called'); // only called when settings changes
  return new Map(
    [
      ...settings.invoiceLabels || [],
      ...settings.itemLabels || [],
      ...settings.jobLabels || [],
      ...settings.siteLabels || [],
      ...settings.unitLabels || [],
      ...settings.userLabels || [],
      ...settings.valetOrderLabels || [],
    ]
      .map(label => [label.id, label])
  );
});


const LabelsContainerCell = styled('span')({
  fontSize: '1.2rem',
  display: 'flex',
  alignItems: 'stretch',
  verticalAlign: 'middle',
  position: 'absolute',
  right: 8,
  bottom: 0,
});


export default function LabelsListCell({ labels: labelIds = [], ...props }) {
  const labelsById = getLabelsById(store.get().settings);
  const labels = labelIds
    .map(labelId => {
      const label = labelsById.get(labelId);
      if (!label) {
        console.warn(`Label ${labelId} not found`);
        return null;
      }
      return label;
    })
      .filter(Boolean);

  return (
    <LabelsContainerCell {...props}>
      {labels
        .slice(0, 5)
        .map(label => (
          <Label key={label.id} style={{ margin: 0, borderRadius: 0 }} {...label} />
        ))}
      {labels.length > 5 && (
        <span
          className="label"
          style={{ color: '#000b', padding: '.2em' }}
          title={labels.slice(5).map(label => getLangText(label.title))}
        >
          {`+${labels.length - 5}`}
        </span>
      )}
    </LabelsContainerCell>
  );
}
