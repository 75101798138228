import { getLangText, date as formatDate } from 'helpers/ui';
import { currentLang } from 'utils/language';
import LangText from 'components/lang-text';
import { Editable } from 'components/markdown';
import { Field, MultiLangText, FormObject, DatePicker, ModalForm2 } from 'components/form2';
import ImageUploader from 'components/settings/image-uploader';
import MultiLangEditor from 'components/form2/multi-lang-editor';
import { VariantField } from './hero';


export function edit({ block, setBlock, modal }) {
  modal.open(
    <ModalForm2
      wurdSection="website.page.block"
      mode="update"
      initialValue={block}
      onSubmit={async data => setBlock(data)}
      onSuccess={modal.close}
      closeModal={modal.close}
    >
      {({ fieldProps }) => (
        <>
          <Field {...fieldProps('title')} autoFocus><MultiLangText /></Field>
          <Field {...fieldProps('text')}><MultiLangEditor /></Field>
          <Field {...fieldProps('date')}><DatePicker minDate={null}/></Field>
          <ImageUploader {...fieldProps('img.url')} showUrlButton style={{ maxWidth: '100%', height: 50 }} />
          <Field {...fieldProps('img.alt')} />
          <Field {...fieldProps('id')} />
          <VariantField {...fieldProps('variant')} />
        </>
      )}
    </ModalForm2>,
    { bsSize: 'lg' }
  )
}


export default ({ block, setBlock, modal }) => {
  return (
    <div style={{ padding: '.5rem 1rem' }}>
      <h4 className="text-center" style={{ fontWeight: 400, marginBottom: '.5rem' }} onDoubleClick={e => { if (e.currentTarget === e.target) edit({ block, setBlock, modal }); }}>
        <Editable inline placeholder="[Title]" onChange={e => setBlock({ title: { [currentLang]: e.target.value } })}>{getLangText(block.title)}</Editable>
      </h4>
      {block.date && <small className="text-muted text-center" style={{ display: 'block' }}>{formatDate(block.date, 'long')}</small>}
      {block.img?.url && <img src={block.img.url} style={{ height: '10em', display: 'block', margin: '0 auto 2rem' }} />}
      
      <Editable placeholder="[Text]" onChange={e => setBlock({ text: { [currentLang]: e.target.value } })}>{getLangText(block.text)}</Editable>
    </div>
  );
}
