import { useEffect, useRef } from 'react';
import { apiUrl, nonce } from '../../config';


export default ({ clientId }) => {
  const ref = useRef();

  // for some reaon it only works like this, not in jsx
  useEffect(() => {
    if (ref.current) {
      const s = document.createElement('script');
      s.src = 'https://accounts.google.com/gsi/client';
      s.nonce = nonce;
      s.async = true;

      const d1 = document.createElement('div');
      d1.id = 'g_id_onload';
      Object.assign(d1.dataset, {
        client_id: clientId,
        login_uri: apiUrl + '/v1/auth/google?scope=admin',
        auto_prompt: 'false',
        // ux_mode: 'redirect',
      });
      const d2 = document.createElement('div');
      d2.className = 'g_id_signin';
      Object.assign(d2.dataset, {
        type: 'standard',
        size: 'medium',
        theme: 'outline',
        text: 'sign_in_with',
        shape: 'rectangular',
        width: 270,
        logo_alignment: 'center',
      });

      ref.current.append(s, d1, d2);
    }
  }, []);

  return <div ref={ref} />;
}
