import React from 'react';


export default function ImagePreview({
  doc, // site, unitType etc.
  style,
  ...rest
}) {
  return (
    <img
      src={doc.image}
      alt=""
      style={{
        width: '100%',
        height: 200,
        background: '#eee',
        border: '1px solid #ddd',
        objectFit: doc.imageFit || 'contain',
        ...style
      }}
      {...rest}
    />
  );
}
