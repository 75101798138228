import JobStepList2 from '../jobs/step-list2';
import Spinner from 'components/spinner';

import * as stepComponents from './steps';


export default function StepList(props) {
  const { job } = props;

  if (!job) return <Spinner />;

  return (
    <JobStepList2
      stepComponents={stepComponents}
      {...props}
    />
  );
}
