import styled from '@emotion/styled';

import * as config from 'config';
import * as helpers from 'helpers';

import Modal from 'components/modal';
import ApiButton from 'components/api-button';
import Markdown from 'components/markdown';
import StateLabel from 'components/state-label';
import SiteLoader from 'components/loaders/site';

const Image = styled.img`
  max-height: 150px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
`;


export default function AddonConfigDetail({
  cms,
  modal,
  addonConfig,
  onClickBack,
  onClickInstall,
}) {
  const addon = addonConfig._addon;

  return (
    <Modal
      title={`Addons: ${helpers.ui.getLangText(addonConfig.title)}`}
      closeModal={modal.close}
      footer={(
        <>
          <ApiButton
            bsType="secondary"
            className="pull-left"
            onClick={onClickBack}
          >
            <i className="fa-fw far fa-chevron-left" /> <cms.Text id="back" />
          </ApiButton>

          {/* Addon is NOT installed */}
          {!addon && helpers.auth.hasRole('_support') && (
            <ApiButton
              onClick={onClickInstall}
            >
              <i className="fa-fw far fa-plus" /> <cms.Text id="install" />
            </ApiButton>
          )}
          {!addon && !helpers.auth.hasRole('_support') && (
            <ApiButton
              disabled
            >
              <i className="fa-fw far fa-ban" /> <cms.Text id="install_betaRestricted" />
            </ApiButton>
          )}

          {/* Addon IS installed */}
          {addon && (
            <button
              className="btn btn-default"
              disabled
            >
              <i className="fa-fw far fa-ban" />
              
              {!addon.siteId && (
                <cms.Text id="install_alreadyInstalledOnBusiness" />
              )}

              {addon.siteId && (
                <SiteLoader id={addon.siteId}>
                  {(site) => (
                    <cms.Text id="install_alreadyInstalledOnSite" vars={{ site: helpers.ui.getLangText(site.title) }} />
                  )}
                </SiteLoader>
              )}
            </button>
          )}
        </>
      )}
    >
      <div className="row">
        <div className="col-md-3">
          <Image src={addonConfig.image || `${config.publicUrl}/addon.png`} />
        </div>

        <div className="col-md-9">
          <Markdown>{helpers.ui.getLangText(addonConfig.info)}</Markdown>
          <hr />
          {addonConfig.price > 0
            ? (
              <StateLabel><cms.Text id="pricePerMonth" vars={{ price: addonConfig.price }} /></StateLabel>
            ) : (
              <StateLabel><cms.Text id="priceFree" /></StateLabel>
            )
          }
        </div>
      </div>
    </Modal>
  );
}