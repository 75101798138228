import wurd from 'wurd-react';

import * as actions from 'actions';
import * as helpers from 'helpers';

import SectionCrudList from '../../section_crud-list';
import CustomFieldsForm from './form';
import CustomFieldsItem from './item';


export default function CustomFieldsSection({ settings }) {
  return [
    ['user', true],
    ['site', settings.modes.includes('selfStorage')],
    ['unit', settings.modes.includes('selfStorage')],
    ['unitType', settings.modes.includes('selfStorage')],
    ['unitRental', settings.modes.includes('selfStorage')],
    ['invoice', true],
    ['valetOrder', settings.modes.includes('valetStorage')],
    ['item', settings.modes.includes('valetStorage')],
    ['business', true],
  ]
    .map(([model, show]) => show && (
      <SectionCrudList
        key={model}
        cms={wurd.block(`settings.${model}CustomFields`)}
        items={(settings[`${model}CustomFields`] || []).sort(helpers.ui.sortByCode)}
        Form={CustomFieldsForm}
        formProps={{ allowFiles: model !== 'item' }}
        actions={actions.business[`${model}CustomFields`]}
        onChange={actions.business.fetch}
      >
        
        {item => <CustomFieldsItem item={item} />}
      </SectionCrudList>
    ));
}
