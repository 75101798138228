import wurd, { WurdObject } from 'wurd-react';
import styled from '@emotion/styled';

const Cols = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '0 1rem',
});


export default function FormDualInputField({
  wurdSection,
  name,
  wurdId = [wurdSection, name].join('.'),
  wurdKeys = "label,help",
  htmlFor,
  required,
  error,
  children,
}) {
  const wurdContent = wurd.get(wurdId) || {};

  const label = wurdContent.label || name;
  const help = wurdContent.help;

  return (
    <WurdObject
      id={wurdId}
      keys={wurdKeys}
      type="div"
      className={`form-group form-horizontal ${error ? 'has-error' : ''} clearfix`}
    >
      <label
        htmlFor={htmlFor || name}
        className={`control-label col-sm-3 ${required ? 'required' : ''}`}
      >
        {label}
      </label>

      <div className="col-sm-9">
        <Cols>
          {children}
        </Cols>
        
        {help && (
          <span className="help-block">{help}</span>
        )}
        {error && (
          <p className="help-block">{error}</p>
        )}
      </div>
    </WurdObject>
  );
}
