import styled from '@emotion/styled';

import UploadImageButton from '../uploader';
import ImagePreview from 'components/image-preview';

const Container = styled.div({
  '.sgc-image': {
    position: 'relative',
  },
  
  '.sgc-fit': {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '2px 8px',
    border: '1px solid #ddd',
    background: 'rgba(255, 255, 255, 0.5)',
    verticalAlign: 'baseline',

    'span': {
      display: 'inline-block',
      marginLeft: 4,
    }
  }
});


export default function ImageInput({
  value: url,
  name,
  onChange,
  imageFitProps = null, // Optional fieldProps('imageFit') for deciding if image should be objectFit: 'contain' or 'cover'
}) {
  function handleImageFitChange(event) {
    imageFitProps.onChange({
      target: {
        name: imageFitProps.name,
        value: event.target.checked === false ? 'cover' : 'contain',
      },
    });
  }
  
  return (
    <Container>
      {url &&
        <div className="sgc-image">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <ImagePreview
              doc={{
                image: url,
                imageFit: imageFitProps?.value,
              }}
              style={{
                borderBottom: 0,
              }}
            />
          </a>

          {imageFitProps && (
            <label className="sgc-fit">
              <input
                type="checkbox"
                checked={imageFitProps.value !== 'cover'}
                onChange={handleImageFitChange}
              />
              <span>Fit</span>
            </label>
          )}
        </div>
      }

      <UploadImageButton
        name={name}
        onChange={onChange}
        text="Select new image"
        className="btn-block"
        // showUrlButton
      />
    </Container>
  );
}
