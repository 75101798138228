import schema2form from '../schema2form';

import DatePicker from '../datepicker';


const wurdSection = 'invoice.item';

const schema = [
  {
    name: 'desc',
    required: true,
  },
  {
    name: 'price',
    type: 'number',
    required: true,
  },
  {
    name: 'quantity',
    type: 'number',
    required: true,
  },
  {
    name: 'startDate',
    custom: props => <DatePicker {...props} />,
    required: true,
  },
  {
    name: 'endDate',
    custom: props => <DatePicker {...props} />
  },
  {
    name: 'accountingCode',
  },
];


export default schema2form(schema, { wurdSection });
