import Avatar from '../avatar';


export default function UserDetailSidebarSummary({
  user,
}) {
  return (
    <div className="text-center hidden-xs" style={{ marginBottom: 20 }}>
      <Avatar user={user} size={150} round />
    </div>
  );
}
