import { useQuery } from 'react-query';
import wurd from 'wurd-react';

import * as actions from '../../actions';

import Alert from 'components/alert';
import Spinner from 'components/spinner';

const cms = wurd.block('auth.login.verifyEmail');


export default function VerifyEmail({ user }) {
  const { status, data } = useQuery(`send-confirm-email-${user.email}`, () => actions.auth.sendConfirmEmail(user.email));

  switch (status) {
    case 'loading': return <Spinner />;

    case 'error': return <cms.Markdown id="error" vars={{ reason: data.message || data.error.message }} />;

    case 'success': return (
      <Alert
        type="success"
        icon="far fa-envelope-open"
        helpUrl={cms.text('helpUrl')}
      >
        <cms.Markdown id="sent" vars={{ email: user.email }} />
      </Alert>
    );

    default: return null;
  }
}