import { useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as config from '../../config';
import * as actions from '../../actions';

import Modal from '../modal';
import Spinner from '../spinner';


export default function ShareModal({ cms, invoice, modal }) {
  const [copied, setCopied] = useState(false);

  const { data: shareId = invoice.shareId, isLoading } = useQuery(
    `inv-share-${invoice.id}`,
    () => actions.invoices.enableSharing(invoice.id).then(r => r.shareId),
    { enabled: !invoice.shareId }
  );

  if (isLoading) {
    return (
      <Modal
        title={<cms.Text id="title" />}
      >
        <Spinner />
      </Modal>
    )
  }

  const url = `${config.apiUrl}/invoices/${shareId}`;

  return (
    <Modal
      title={<cms.Text id="title" />}
      closeModal={modal.close}
      footer={
        <CopyToClipboard
          text={url}
          onCopy={() => setCopied(true)}
        >
          {copied
            ? <button type="button" className="btn btn-success">
              <i className="fa fa-check" /> <cms.Text id="copied" />
            </button>
            : <button type="button" className="btn btn-primary">
              <i className="fa fa-copy" /> <cms.Text id="copy" />
            </button>
          }
        </CopyToClipboard>
      }
    >
      <div className="clearfix">
        <cms.Markdown id="intro" />

        <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
      </div>
    </Modal>
  );
}


ShareModal.propTypes = {
  invoice: PropTypes.object.isRequired,
  cms: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
};
