const styles = {
  main: {
    marginTop: 6,
    marginBottom: 6
  },

  image: {
    float: 'left',
    marginRight: 10,
    width: 50,
    height: 50,
    objectFit: 'cover'
  },

  thumbnail: {
    float: 'left',
    marginRight: 10,
    width: 50,
    height: 50,
  },

  type: {
    textTransform: 'uppercase',
    color: '#aaa',
    fontSize: 12,
    marginTop: 3
  },

  title: {
    fontSize: 14,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
};


const SidebarSummaryRow = ({ thumbnail, image, typeTitle, itemTitle, ...rest }) => (
  <div {...rest}>
    {thumbnail &&
      <div style={styles.thumbnail}>{thumbnail}</div>
    }
    {image &&
      <img src={image} alt="" style={styles.image} />
    }

    <div style={styles.type}>{typeTitle}</div>
    <div style={styles.title}>{itemTitle}</div>
  </div>
);


export default SidebarSummaryRow;
