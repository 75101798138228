import { Fragment } from 'react';

import store from 'store';
import * as helpers from 'helpers';

import DetailList from 'components/detail-list';
import LangText from 'components/lang-text';
import CustomFieldValue from './detail';


export default function CustomFieldsList({ modelName, model, children }) {
  const settings = store.get('settings');

  return (
    <>
      {settings[`${modelName}CustomFields`].length > 0 ? children : undefined}
      <DetailList>
        {settings[`${modelName}CustomFields`].sort(helpers.ui.sortByCode).map(({ code, title, ...props }) => {
          if (model?.customFields?.[code] == null) return null;

          return (
            <Fragment key={code}>
              <dt><LangText obj={title} /></dt>
              <dd><CustomFieldValue value={model.customFields?.[code]} {...props} /></dd>
            </Fragment>
          );
        })}
      </DetailList>
    </>
  );
}
