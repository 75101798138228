import AccountPlugin from '../../plugins/account';
import { hasRole } from 'helpers/auth';


export default function UnitDetailRevenue({
  modal,
  cms,
  unit,
  rental,
  onChange,
}) {
  return (
    <AccountPlugin
      cms={cms.block('revenue')}
      endpoint={`/v1/admin/unit-rentals/${rental.id}/revenue`}
      modal={modal}
      onChange={onChange}
      canAdd={hasRole('__superadmin')}
    />
  );
}
