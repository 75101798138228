import { useListLoader } from '../../hooks';


export default function ItemsLoader({
  ids,
  maxAge,
  children: renderFn,
}) {
  const queryParams = {
    ids: ids.join(','),
  };

  const { items, isLoading, refetch } = useListLoader('items', queryParams, { maxAge });

  return renderFn(items, { isLoading, refetch });
}
