import wurd from 'wurd-react';

import * as helpers from '../../../helpers';
import schema2form from '../schema2form';

import MultiLangText from '../multi-lang-text';

const cms = wurd.block('market.item');

const schema = [
  {
    name: 'type',
    type: 'select',
    options: function () {
      return [
        { value: '', text: cms.text('selectOption') },
        ...helpers.item.list(true).map(item => ({
          value: item.type,
          text: `${item.title} (${item.type})`
        }))
      ];
    },
    required: true
  },
  {
    name: 'price',
    type: 'number',
    required: true
  },
  {
    name: 'displayPrice',
    custom: props => <MultiLangText {...props} />,
    required: true
  },
  {
    name: 'order',
    type: 'number',
    step: 1,
  },
];

export default schema2form(schema, { wurdSection: cms.id() });
