import wurd, { WurdText } from 'wurd-react';

import StateLabel from 'components/state-label';


export default function JobStateLabel({ job }) {
  const { state: jobState, requiresInput } = job;
  const { orderState, cancelledType } = job.result;

  if (orderState === 'notSubmitted') {
    return (
      <StateLabel type="secondary">{wurd.text('common.phrases.pending')}</StateLabel>
    );
  }

  if (orderState === 'abandoned' || cancelledType === 'abandoned'/*deprecated*/) {
    return (
      <StateLabel type="secondary">{wurd.text('common.phrases.abandoned')}</StateLabel>
    );
  }

  if (orderState === 'cancelled') {
    return (
      <StateLabel type="secondary">{wurd.text('jobList.filters.state.cancelled')}</StateLabel>
    );
  }

  if (jobState === 'completed') {
    return (
      <StateLabel type="success">{wurd.text('jobList.filters.state.completed')}</StateLabel>
    );
  }

  if (jobState === 'failed') {
    return (
      <StateLabel type="danger">{wurd.text('jobList.filters.state.failed')}</StateLabel>
    );
  }

  return (
    <StateLabel type="info"><WurdText id={`unitJobs.${job.type}.steps.${job.step}.ready.title`} /></StateLabel>
  );
}
