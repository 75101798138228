import wurd from 'wurd-react';

import SidebarState from '../../sidebar-items/state';
import SidebarUser from '../../sidebar-items/user';
import SidebarDate from '../../sidebar-items/date';
import SidebarUnit from '../../sidebar-items/unit';

const cms = wurd.block('unitJobs.moveOut.sidebar');


function renderOrderState(state) {
  if (state === 'requiresInput') {
    return (
      <SidebarState type="warning">
        <cms.Text id="_states.requiresInput" />
      </SidebarState>
    );
  }

  else if (state === 'cancelled') {
    return (
      <SidebarState type="danger">
        <cms.Text id="_states.cancelled" />
      </SidebarState>
    );
  }

  if (state === 'completed') {
    return (
      <SidebarState type="success">
        <cms.Text id="_states.completed" />
      </SidebarState>
    );
  }

  else {
    return (
      <SidebarState type="info">
        <cms.Text id="_states.submitted" />
      </SidebarState>
    );
  }
}


export default ({ job, owner }) => {
  const { orderState, endDate, unitId } = job.result;

  return (
    <div className="list-group">
      {renderOrderState(orderState)}

      {owner && (
        <SidebarUser user={owner} />
      )}

      <SidebarUnit
        id={unitId}
      />

      <SidebarDate
        date={endDate}
        title={<cms.Text id="date" />}
      />
    </div>
  );

};
