import { iconColors } from '../helpers/ui';
import { stateLabelTypes, stateIcons } from '../helpers/item';


export default function ItemStateIcon({ item }) {
  const { state } = item;

  return (
    <span style={iconColors[stateLabelTypes[state]]}>
      <i className={`fa-fw ${stateIcons[state]}`} />
    </span>
  );
}
