import wurd from 'wurd-react';
import ModalForm from '../modal-form';
import Field from '../horizontal-field';
import MultiLangText from '../multi-lang-text';
import ImageInput from '../image-input';
import AccountingCodeSelect from '../accounting-code-select';


export default function SiteProductsForm(props) {
  return (
    <ModalForm
      wurdSection="site.products"
      {...props}
    >
      {(getProps) => (
        <>
          <Field {...getProps('title')} required>
            <MultiLangText autoFocus />
          </Field>

          <Field {...getProps('description')}>
            <MultiLangText multiline />
          </Field>

          <Field {...getProps('price')} type="number" step="any" required />

          <Field {...getProps('displayPrice')}>
            <MultiLangText />
          </Field>

          <Field {...getProps('image')}>
            <ImageInput
              imageFitProps={getProps('imageFit')}
            />
          </Field>

          <Field {...getProps('type')} wurdKeys="label,help,options.movein,options.recurring">
            <select>
              <option value="movein">{wurd.text('site.products.type.options.movein')}</option>
              <option value="recurring">{wurd.text('site.products.type.options.recurring')}</option>
            </select>
          </Field>

          <Field {...getProps('hidden')} type="checkbox" />

          {getProps('type').value === 'recurring'
            ? null
            : (
              <>
                <Field {...getProps('trackInventory')} type="checkbox" />

                <Field {...getProps('inventory')} type="number" step="1" disabled={!getProps('trackInventory').value} />
              </>
            )}

          <Field {...getProps('maxQuantity')} type="number" step="1" defaultValue={30} />

          <Field {...getProps('accountingCodeId')}>
            <AccountingCodeSelect />
          </Field>

          <Field {...getProps('order')} type="number" step="1" />
        </>
      )}
    </ModalForm>
  );
}
