import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  border-radius: 2px;

  &.warning-alert {
    border: 1px solid var(--bs-gray-light);
    border-left: 5px solid var(--bs-accent-amber);

    .icon-alert {
      background: var(--bs-accent-amber);
    }
  }

  &.danger-alert {
    border: 1px solid var(--bs-gray-light);
    border-left: 5px solid var(--bs-accent-red);

    .icon-alert {
      background: var(--bs-accent-red);
    }
    
  }

  &.success-alert {
    border: 1px solid var(--bs-gray-light);
    border-left: 5px solid var(--bs-accent-green);

    .icon-alert {
      background: var(--bs-accent-green);
    }
    
  }

  &.info-alert {
    border: 1px solid var(--bs-gray-light);
    border-left: 5px solid var(--bs-accent-blue);

    .icon-alert {
      background: var(--bs-accent-blue);
    }
  }
  
  .icon {
    margin-right: 20px;
    font-size: 20px;

    i {
      color: #fff;
      border-radius: 190px;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .main {
    flex-grow: 2;
    align-self: center;

    p:last-child {
      margin-bottom: 0;
    }
  }


  .actions {
    display: flex;
    align-items: center;
    margin-right: 0px;

    .btn {
      margin-bottom: 0 !important;
    }
  }

  .close-button {
    color: #979CA1;
    border-radius: 190px;
    border: 1px solid #979CA1;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bs-gray-bg);

    i {
      font-size: 20px;
    }
  }
`;

const iconDefault = {
  'info': 'fas fa-info',
  'success': 'fas fa-check',
  'danger': 'fas fa-exclamation',
  'warning': 'fas fa-exclamation',
}

export default function Alert({
  type = 'info',
  icon,
  helpUrl,
  actions,
  children,
  onClickClose = null,
}) {
  return (
    <Wrapper className={`alert ${type}-alert`}>
      <div className="icon">
        <i className={`${icon ? icon : iconDefault[type]} icon-alert`} />
      </div>
      <div className="main">
        {children}
      </div>
      <div className="actions">
        {actions}

        {helpUrl && (
          <a
            className="btn btn-link"
            href={helpUrl}
            target="_blank"
            rel="noopener noreferrer">
            <i className="far fa-question-circle" />
          </a>
        )}
        {onClickClose && (
          <button
            className="close-button"
            onClick={onClickClose}
          >
            <i className="fal fa-times" />
          </button>
        )}
      </div>
    </Wrapper>
  );
}
