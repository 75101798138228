import {
  date as formatDate,
  datetime_operator,
  datetime_utc,
  time,
} from '../../helpers/ui';

import * as actions from '../../actions';

import * as previews from './previews';
import LabelsListCell from 'plugins/labels/list';
import Link from 'components/nested-link';
import ApiButton from '../api-button';


const DateTime = ({ date, title }) => (
  <div title={`${title}\n${datetime_operator(date)}\n${datetime_utc(date)}`}>
    {formatDate(date)} <small className="text-muted">{time(date)}</small>
  </div>
);

function renderDelayUntil(job) {
  if (job.delayUntil) {
    return (
      <DateTime date={job.delayUntil} title="Delayed until" />
    );
  }

  if (job.completedAt) {
    return (
      <DateTime date={job.completedAt} title="Completed at" />
    );
  }

  return (
    <DateTime date={job.updated} title="Updated at" />
  );
}

function renderPreview(job, refetch) {
  const Preview = previews[job.type]; // eslint-disable-line import/namespace
  if (!Preview) return null;

  return <Preview job={job} refetch={refetch} />;
}


export default function JobListItem({ job, refetch }) {
  async function retryFailedJob() {
    await actions.jobs.retry(job.id);
    refetch();
  }

  async function skipStep() {
    const fullJob = await actions.fetchOne('jobs', job.id);

    const step = fullJob.steps.find(step => step.state === 'failed');
    if (!step) return alert('No failed step found');

    await actions.jobs.skipStep(job.id, step.id);
    refetch();
  }

  return (
    <>
      <div role="cell" style={{ width: 26 }}>
        {job.requiresInput && (
          <i
            className="far fa-hand-point-up"
            style={{ color: '#f0ad4e' }}
            title="Requires input"
          />
        )}
        {job.delayUntil && (
          <i
            className="far fa-clock"
            style={{ color: '#999' }}
            title="Delayed"
          />
        )}
        {job.state === 'completed' && (
          <i
            className="far fa-check-circle"
            style={{ color: '#60C060' }}
            title="Completed"
          />
        )}
        {job.state === 'failed' && (
          <i
            className="fas fa-exclamation-circle"
            style={{ color: '#d9534f' }}
            title="Failed"
          />
        )}
      </div>

      <div role="cell">{renderDelayUntil(job)}</div>

      <div role="cell">
        <div title="Job type" className="text-nowrap"><strong>{job.type}</strong></div>
        <div title="Next step">{job.step}</div>
      </div>

      <div role="cell">
        {job.error && (
          <div title="Error">
            <i className="fa-fw fas fa-exclamation-circle" style={{ color: '#d9534f' }} /> {job.error}
            <ApiButton bsType="link" onClick={retryFailedJob}><i className="far fa-redo" /> Retry</ApiButton>
            <ApiButton bsType="link" onClick={skipStep}><i className="far fa-forward" /> Skip step</ApiButton>
          </div>
        )}
        {renderPreview(job, refetch)}
      </div>

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={job.labels} />
      </div>
    </>
  );
}
