import { useMutation } from 'react-query';
import styled from '@emotion/styled';


const LinkButtonGroupEl = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginLeft: -8,

  '&.center': {
    justifyContent: 'space-evenly',
    marginLeft: 0,
  },

  '&.right': {
    justifyContent: 'flex-end',
    marginLeft: 0,
    marginRight: -4,
  },
});

const LinkButtonEl = styled.button({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    textDecoration: 'none',
  },

  '.icon': {
    fontSize: 18,
  },

  '.text': {
    marginLeft: 6,
    // '@media (max-width: 768px)': { display: 'none' },
  },
});


export function LinkButton({
  faIcon,
  text,
  onClick,
  disabled = false,
}) {
  const clickMutation = useMutation(onClick);

  return (
    <LinkButtonEl
      type="button"
      className="btn btn-link"
      onClick={clickMutation.mutate}
      disabled={disabled || clickMutation.isLoading}
    >
      <i className={clickMutation.isLoading ? 'icon far fa-spin fa-spinner' : `icon ${faIcon}`} />
      {text && (
        <span className="text">{text}</span>
      )}
    </LinkButtonEl>
  );
}


export default function LinkButtonGroup({
  align = 'left', // left|center|right
  children,
  ...rest
}) {
  return (
    <LinkButtonGroupEl
      className={align}
      {...rest}
    >
      {children}
    </LinkButtonGroupEl>
  );
}
