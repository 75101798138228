import axios from 'axios';
import api from '../utils/api';
import { fetch, fetchOne, create, update, updateCachedData } from './general';
import errHandler from './_err-handler';
import crudList from './plugins/crud-list';
import labelsPlugin from '../plugins/labels/actions';
import processCustomFields from '../plugins/custom-fields/actions';


const endpoint = 'users';
const url = `/v1/admin/${endpoint}`;

const actions = {};

function handleResponse(res) {
  updateCachedData(endpoint, res.data);

  return res.data;
}

let searchCancel; // cancel token actions.fetch https://github.com/axios/axios#cancellation (ideally we should switch to window.fetch and AbortController later, standard solutions)
/**
 * Fetches users
 *
 * @param {Object} [params]          Query parameters
 * @param {String} [params.state]
 */
actions.fetch = function (params = {}) {
  if (searchCancel) {
    searchCancel.cancel();
  }

  searchCancel = axios.CancelToken.source();

  return fetch(endpoint, params, { cancelToken: searchCancel.token });
};


/**
 * Fetches admin users
 */
actions.fetchAdmins = function (roles = '') {
  return fetch(endpoint, { roles });
};


/**
 * Fetches a single user
 *
 * @param {Object} [params]          Query parameters
 * @param {String} [params.include]
 */
actions.fetchOne = function (id, params = {}) {
  return fetchOne(endpoint, id, params);
};


/**
 * Fetches the subscription states for a given user
 *
 * @param {String} userId
 * @param {Object} opts
 */
actions.fetchSubscriptionStates = function (userId, opts) {
  return api.get(`${url}/${userId}/subscription-states`, { params: opts })
    .then(res => res.data)
    .catch(errHandler);
};


/**
 * Create user
 *
 * @param {Object} data
 */
actions.create = async function (data) {
  if (data.customFields) {
    data.customFields = await processCustomFields(data.customFields, 'user');
  }
  return create(endpoint, data, { include: 'customFields' });
};


/**
 * Update a user
 *
 * @param {String} userId
 * @param {Object} data
 */
actions.update = async function (userId, data) {
  return update(endpoint, userId, data, { include: 'customFields' });
};


/**
 * Disables a user account
 *
 * @param {String} userId
 * @param {String} reason
 */
actions.disable = function (userId, reason) {
  return api.post(`${url}/${userId}/disable`, { reason })
    .then(handleResponse)
    .catch(errHandler);
};


/**
 * Re-enables a user account
 *
 * @param {String} userId
 * @param {String} reason
 */
actions.enable = function (userId) {
  return api.post(`${url}/${userId}/enable`)
    .then(handleResponse)
    .catch(errHandler);
};


actions.invite = function (userId) {
  return api.post(`${url}/${userId}/invite`)
    .catch(errHandler);
};


actions.charges = {
  /**
   * Creates a charge
   *
   * @param {String} userId
   * @param {Object} data
   */
  create(userId, data) {
    return api.post(`/v1/admin/users/${userId}/charges`, data)
      .then(res => res.data)
      .catch(errHandler);
  },

  /**
   * Updates a charge
   *
   * @param {String} userId
   * @param {String} chargeId
   * @param {Object} data
   */
  update(userId, chargeId, data) {
    return api.put(`/v1/admin/users/${userId}/charges/${chargeId}`, data)
      .then(res => res.data)
      .catch(errHandler);
  },

  /**
   * Deletes a charge
   *
   * @param {String} userId
   * @param {String} chargeId
   */
  delete(userId, chargeId) {
    return api.delete(`/v1/admin/users/${userId}/charges/${chargeId}`)
      .then(res => res.data)
      .catch(errHandler);
  },
};


actions.credits = function (userId) {
  return crudList(`/v1/admin/users/${userId}/credits`);
};


actions.files = {
  /**
   * @param {string} userId
   * @param {string} fileId
   * @returns {Promise}
   */
  delete: (userId, fileId) => api.delete(`/v1/admin/users/${userId}/files/${fileId}`),
};

actions.labels = labelsPlugin('users');

// actions.customFields = customFieldsPlugin('users');

export default actions;
