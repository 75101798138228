import wurd from 'wurd-react';

import store from '../store';
import errHandler from './_err-handler';
import chatActions from './chat';
import businessActions from './business';
import api from '../utils/api';
import { initLang } from '../utils/language';
import { wurdSections } from '../config';
import btoa from '../utils/btoa';
import captchaPlugin from '../plugins/recaptcha/action';


const includeOpts = 'helpScoutVerification';

const actions = {};

actions.setUser = function (user) {
  store.set({
    currentUser: user,
    users_byId: {
      ...store.get('users_byId'),
      [user.id]: { ...user, _fetched: Date.now() }
    }
  });

  // Start chat
  if (window.location.hostname !== 'localhost' && !wurd.editMode) {
    chatActions.update(user);
  }
};

actions.clearUser = function () {
  store.set({ currentUser: null });

  chatActions.shutdown();
};

actions.fetchUser = function (params = {}) {
  return api.get(`/v1/admin/user?include=${includeOpts}`, { params })
    .then(async res => {
      const user = res.data;

      actions.setUser(user);

      return user;
    });
};

actions.login = async function ({ email, password }) {
  const data = {};
  await captchaPlugin(data);

  const basicToken = btoa(`${email}:${password}`);

  const apiConfig = {
    headers: {
      Authorization: `Basic ${basicToken}`,
      'X-Requested-With': 'XMLHttpRequest', // Prevents Safari displaying BasicAuth popup)
    },
  };

  return api.post('/v1/auth/token?scope=admin', data, apiConfig)
    .then(async res => {
      const { user } = res.data;

      await businessActions.fetch();

      actions.setUser(user);

      return user;
    })
    .catch(errHandler);
};

actions.loginWithAzure = function (token) {
  const apiConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    },
  };

  return api.post('/v1/auth/azure?scope=admin', {}, apiConfig)
    .then(async res => {
      const { user } = res.data;

      await businessActions.fetch();

      actions.setUser(user);

      return user;
    })
    .catch(errHandler);
};

actions.setLanguage = function (language) {
  return api.put(`/v1/admin/user?include=${includeOpts}`, { language })
    .then(async res => {
      const user = res.data;

      localStorage.setItem('lang', language);

      await initLang(user.language);
      wurd.store.rawContent = {};
      actions.setUser(user); // re-render
      wurd.load(wurdSections.join(','));

      return user;
    });
};

actions.logout = async function () {
  return api.post('/v1/auth/logout?scope=admin')
    .then(() => {
      actions.clearUser();
    });
};

actions.sendForgotPasswordEmail = async function (email) {
  const data = { email };
  await captchaPlugin(data);
  return api.post('/v1/auth/forgot-password?scope=admin', data)
    .catch(errHandler);
};

actions.sendConfirmEmail = function (email) {
  return api.post('/v1/auth/confirm-email?scope=admin', { email })
    .catch(errHandler);
};

actions.resetPassword = function (data) {
  return api.post('/v1/auth/reset-password?scope=admin', data)
    .catch(errHandler);
}

export default actions;
