import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import wurd from 'wurd-react'

import * as actions from '../../actions';

import Form from '../form';
import ErrorMessage from 'components/error-message';

const cms = wurd.block('settings')


class SettingsForm extends Component {
  static propTypes = {
    name: PropTypes.string,
    action: PropTypes.func,
  }

  state = {
    hasChanged: false,
    isValid: false,
    isSaving: false,
  };

  onChange = (values, isValid) => {
    this.setState({
      hasChanged: true,
      success: undefined,
      isValid
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(values, isValid);
      }
    });
  }

  save = () => {
    var form = this.form;
    const { action = actions.business.update, name } = this.props;

    if (!form.isValid()) return;

    var values = form.getValue();

    this.setState({ isSaving: true, })

    return action(name ? { [name]: values } : values)
      .then(result => this.setState({
        success: true,
        error: null,
        isSaving: false,
      }))
      .catch(err => this.setState({
        error: err,
        isSaving: false,
      }));
  }

  render() {
    const { hasChanged, isValid, isSaving, success } = this.state;
    const { children, action, ...props } = this.props;

    return (
      <Form
        ref={el => { this.form = el; }}
        className="form-horizontal clearfix"
        {...props}
        onChange={this.onChange}
      >
        {children}

        {this.renderErrors()}

        <button
          type="button"
          style={{ marginTop: 20 }}
          className={`btn btn-primary pull-right ${success ? 'btn-success' : ''}`}
          onClick={this.save}
          disabled={!hasChanged || isSaving}
        >
          {success
            ? <cms.Text id='savedButton' />
            : <Fragment>{isSaving && <i className="fa fa-spinner fa-spin" />} <cms.Text id='saveButton' /></Fragment>
          }
        </button>
      </Form>
    );
  }

  renderErrors() {
    var error = this.state.error;
    if (!error) return null;

    return (
      <div className="col-sm-offset-4">
        <ErrorMessage>
          {error.message}

          {error.errors &&
            <ul>
              {_.map(error.errors, (propErrs, prop) =>
                <li key={prop}>{prop}: {propErrs ? propErrs.message : _.map(propErrs, 'message')}</li>
              )}
            </ul>
          }
        </ErrorMessage>
      </div>
    );
  }
}

export default SettingsForm;
