import ModalForm2 from 'components/form2/modal-form2'
import { Field, MultiLangText } from 'components/form2';


export default function SiteProductsForm({
  mode = 'create',
  ...rest
}) {
  return (
    <ModalForm2
      wurdSection="site.unitTypeGroups"
      mode={mode}
      className="form2"
      dirty
      {...rest}
    >
      {({ fieldProps }) => (
        <>
          <Field {...fieldProps('title')} required>
            <MultiLangText autoFocus />
          </Field>

          <Field {...fieldProps('subtitle')}>
            <MultiLangText />
          </Field>

          <Field {...fieldProps('code')} required />

          <Field {...fieldProps('order')} type="number" step="1" />
        </>
      )}
    </ModalForm2>
  );
}
