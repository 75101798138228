import Activity from '../activity';


export default function UnitHistory({
  unit,
  rental,
}) {
  return (
    <Activity
      context="unit"
      endpoint={unit && `/units/${unit.id}/actions`}
      target={[unit?.id, rental?.id].filter(Boolean).join(',')}
    />
  );
}
