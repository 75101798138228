import { useState } from 'react';
import wurd from 'wurd-react';

import * as helpers from '../../../helpers';
import useSelector from '../hooks/selector';
import { useListLoader } from '../../../hooks';

import NoResults from '../../no-results';
import JobStep from '../../jobs/job-step2';
import LargeButtonGroup from '../../large-button-group';
import Tabs from '../shared/tabs';
import ResultsItemList from '../shared/results-item-list';
import Item2List from '../shared/item2-list';
import ScanReceiver from '../shared/scan-receiver';
import SelectionButton from '../shared/selection-button';
import ScanItemsButton from '../shared/scan-items-button';
import CompleteStepButton from '../shared/complete-step-button';

const cms = wurd.block('valetOrder.steps.items_pickStoredItems');


export default function Step_items_pickStoredItems(props) {
  const {
    valetOrder,
    step,
    items,
    updateItems,
    modal,
  } = props;

  function itemHasBeenPicked(item) {
    return item.valetOrderId === valetOrder.id;
  }

  const { items: requestedItems } = useListLoader('items', {
    ids: valetOrder.deliverItems.join(','),
    limit: 1000,
  });

  const selector = useSelector();

  const [updateErrs, setUpdateErrs] = useState(null);

  const [activeTab, setActiveTab] = useState('todo');
  const [lastAction, setLastAction] = useState();

  function onTabChange(tabId) {
    selector.clearSelection();

    setActiveTab(tabId);
  }

  const pickedItems = items;
  const unpickedItems = requestedItems.filter((item) => !itemHasBeenPicked(item));

  const hasPickedAll = requestedItems.every(itemHasBeenPicked);

  async function pickItems(itemSids) {
    const { errors, updatedSids } = await updateItems('valetOrder_pick', itemSids);

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Picked ${updatedSids.length} items`,
        bsType: 'success',
      });
    }

    setLastAction('pick');
    selector.clearSelection();
  }

  async function unpickItems(itemSids) {
    const location = helpers.ui.prompt('Enter the new location');
    if (!location) return;

    const { errors, updatedSids } = await updateItems('valetOrder_unpick', itemSids, { location });

    if (errors) setUpdateErrs(errors);

    if (updatedSids) {
      helpers.ui.notify({
        text: `Unpicked ${updatedSids.length} items`,
        undo: () => pickItems(updatedSids),
      });
    }

    setLastAction('unpick');
    selector.clearSelection();
  }

  function renderSelectionButtons() {
    return (
      <cms.Object keys="pickBtn,unpickBtn">
        {activeTab === 'todo' && (
          <SelectionButton
            bsType="success"
            faIcon="fas fa-check-circle"
            text={cms.text('pickBtn')}
            onClick={() => pickItems(selector.selectedSids)}
          />
        )}
        {activeTab === 'done' && (
          <SelectionButton
            bsType="danger"
            faIcon="fas fa-times-circle"
            text={cms.text('unpickBtn')}
            onClick={() => unpickItems(selector.selectedSids)}
          />
        )}
      </cms.Object>
    );
  }

  function renderActions() {
    return (
      <JobStep
        step={step}
        faIcon="fas fa-search"
        title={<cms.Text id="requiresInput.title" vars={{ numItems: unpickedItems.length }} />}
        helpWurdId={cms.id('instructions.helpUrl')}
      >
        <ScanReceiver onScan={pickItems} />

        <Tabs
          onChange={onTabChange}
          items={[
            {
              id: 'todo',
              faIcon: 'fa-tasks',
              title: <cms.Text id="todoTab" />,
              badge: unpickedItems.length,
              animateBadge: lastAction === 'unpick',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={unpickedItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  successState="transit-user"
                  fallback={(
                    <NoResults faIcon="fa-check-circle" faColor="success">
                      <cms.Markdown id="todo.noResults" />
                    </NoResults>
                  )}
                  extra={selector.isSelecting && renderSelectionButtons()}
                />
              ),
            },
            {
              id: 'done',
              faIcon: 'fa-check-circle',
              title: <cms.Text id="doneTab" />,
              badge: pickedItems.length,
              animateBadge: lastAction === 'pick',
              renderContent: () => (
                <Item2List
                  modal={modal}
                  items={pickedItems}
                  selector={selector}
                  updateErrs={updateErrs}
                  successState="transit-user"
                  fallback={(
                    <NoResults faIcon="fa-search">
                      <cms.Markdown id="done.noResults" />
                    </NoResults>
                  )}
                  extra={selector.isSelecting && renderSelectionButtons()}
                />
              ),
            },
          ]}
        />

        <div style={{ marginTop: 20 }}>
          <cms.Object keys="completeBtn,notFinished_confirm">
            <LargeButtonGroup>
              <ScanItemsButton
                modal={modal}
                receiveScannedSids={pickItems}
              />
              <CompleteStepButton
                valetOrder={valetOrder}
                step={step}
                cms={cms}
                hasProcessedAll={hasPickedAll}
              />
            </LargeButtonGroup>
          </cms.Object>
        </div>
      </JobStep>
    );
  }

  function renderResults() {
    const pickedItemIds = step.result.picked;
    const numItems = pickedItemIds.length;

    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" vars={{ numItems }} />}
      >
        <h4><cms.Text id="pickedItems" /></h4>
        <ResultsItemList itemIds={pickedItemIds} />
      </JobStep>
    );
  }


  return (step.state === 'completed') ? renderResults() : renderActions();
}
