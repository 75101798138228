import styled from '@emotion/styled';
import Badge from 'react-bootstrap/lib/Badge';

const List = styled.ul({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',

  '.badge': {
    filter: 'brightness(1.25)',
    borderRadius: '.5rem',
    padding: '.5rem .75rem',
    fontWeight: 600,
  }
})

export default function ({ children, ...props }) {
  return (
    <List className="list-unstyled" {...props}>
      {children.map(name => (
        <li key={name}>
          <Badge>{name.toUpperCase()}</Badge>
        </li>
      ))}
    </List>
  );
}