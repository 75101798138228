import { Component } from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import * as helpers from '../../../../helpers';
import * as actions from '../../../../actions';

import JobStep from '../../job-step2';
import Alert from '../../../alert';
import ApiButton from '../../../api-button';
import InvoiceLoader from '../../../loaders/invoice';
import Invoice from '../../../invoice';
import InvoiceItems from '../../../invoice-detail/items';
import { Section, Heading, EditButton } from '../../components';
import ConfirmAction from '../../confirm-action';

const cms = wurd.block('unitOrder.steps.invoice_createInitial');


export default class UniveMoveInJobSteps_InvoiceCreateInitial extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    modal: PropTypes.object.isRequired,
  };

  render_ready() {
    const { step } = this.props;

    if (step.requiresInput) return this.render_requiresInput();

    return (
      <JobStep
        step={step}
        title={<cms.Text id="ready.title" />}
      >
        {step.error && (
          <Alert type="danger">
            {step.error}
          </Alert>
        )}
      </JobStep>
    );
  };

  render_requiresInput() {
    const { step, onChange, modal } = this.props;
    const { invoiceId } = step.result;

    return (
      <InvoiceLoader id={invoiceId}>
        {(invoice, { refetch }) => (
          <JobStep
            step={step}
            title={<cms.Text id="requiresInput.title" />}
          >
            <Invoice invoice={invoice} />

            <Section>
              <EditButton
                onClick={() => this.invoiceItems.addItem()}
              >
                <i className="fas fa-plus" /> <cms.Text id="addInvoiceItem" />
              </EditButton>

              <Heading><cms.Text id="invoiceItems" /></Heading>

              <InvoiceItems
                ref={ref => this.invoiceItems = ref}
                showAddButton={false}
                invoice={invoice}
                onChange={() => {
                  refetch();
                  onChange();
                }}
                modal={modal}
              />
            </Section>


            <div className="btn-group btn-group-justified" style={{ marginTop: 40 }}>
              <div className="btn-group">
                <ConfirmAction
                  mustConfirm={invoice.state !== 'paid'}
                  cms={cms.block('continueUnpaid')}
                >
                  <ApiButton onClick={this.completeStep}>
                    <i className="fas fa-check" />
                    &nbsp;
                    <cms.Text id="requiresInput.completeBtn" />
                  </ApiButton>
                </ConfirmAction>
              </div>
            </div>
          </JobStep>
        )}
      </InvoiceLoader>
    );
  };

  render_completed() {
    const { step } = this.props;
    const { invoiceId } = step.result;

    return (
      <InvoiceLoader id={invoiceId}>
        {invoice =>
          <JobStep
            step={step}
            title={<cms.Text id="completed.title" vars={{ amount: helpers.ui.currency(invoice.total) }} />}
          >
            <cms.Text id="completed.invoice" />
            <Invoice invoice={invoice} />
          </JobStep>
        }
      </InvoiceLoader>
    );
  };

  completeStep = () => {
    const { job, step, onChange } = this.props;

    const data = {
      state: 'completed'
    };

    return actions.jobs.updateStep(job.id, step.id, data)
      .then(onChange);
  };

  render() {
    const { step } = this.props;

    const renderFn = this[`render_${step.state}`];
    if (!renderFn) return null;

    return renderFn.call(this);
  };
}
