import wurd from 'wurd-react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { useListLoader } from '../../hooks';
import * as helpers from '../../helpers';

import TableList from '../table-list';
import Item from './item';
import Filters from './filters';
import Menu from './menu';

const cms = wurd.block('unitView.rentals');

function viewRental(rental) {
  return `/unit-rentals/${rental.id}`;
}


export default function UnitRentalList({
  listQuery,
  modal,
  hideOwner = false,
  hideUnit = false,
  hideSite = false,
  hideState = false,
  hideFilters = true,
  getItemLink = viewRental,
  onClickItem,
}) {
  const listLoader = useListLoader('unit-rentals', listQuery.params, {
    maxAge: 0,
    sortItems: helpers.unitRental.sortByStateAndDate,
  });
  const { id: idInUrl } = useParams();

  const stateTitles = helpers.unit.getStateTitles();

  const getTableRowClassName = ({ id, state }) => cn(id === idInUrl ? 'active' : null, state === 'cancelled' ? 'fade' : null);

  return (
    <TableList
      cms={cms}
      listQuery={listQuery}
      loader={listLoader}
      filters={!hideFilters && (
        <Filters
          listQuery={listQuery}
          hideSite={hideSite}
          hideOwner={hideOwner}
          hideUnit={hideUnit}
          hideState={hideState}
        />
      )}
      menu={(
        <Menu
          modal={modal}
          cms={cms}
          listQuery={listQuery}
          refetch={listLoader.refetch}
        />
      )}
      renderItem={(rental) => (
        <Item
          rental={rental}
          hideOwner={hideOwner}
          hideUnit={hideUnit}
          stateTitles={stateTitles}
        />
      )}
      getItemLink={getItemLink}
      onClickItem={onClickItem}
      getTableRowClassName={getTableRowClassName}
    />
  );
}
