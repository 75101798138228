import wurd, { WurdText } from 'wurd-react';

import * as helpers from '../../helpers';

import Avatar from '../avatar';
import UserLoader from '../loaders/user';
import SiteLoader from '../loaders/site';
import UnitTypeLoader from '../loaders/unit-type';
import StateLabel from '../state-label';
import Link from 'components/nested-link';

const cms = wurd.block('unitList');

const { date } = helpers.ui;
const { stateLabelTypes } = helpers.unit;


export default function UnitRentalListItem({
  rental,
  hideSite,
  hideUnit,
  hideOwner,
  stateTitles,
}) {
  const { unit } = rental;

  const JobLink = ({ jobId, children }) => jobId && jobId !== rental.id
    ? <Link to={`/sites/${rental.siteId}/jobs/${jobId}`} className="date">{children}</Link>
    : children;

  return (
    <>
      {!hideUnit && (
        <div role="cell">
          <div>
            <strong className="text-uppercase">{unit?.name} </strong>
            {!hideSite && (
              <SiteLoader id={rental.siteId}>
                {(site) => (
                  <span className="text-muted">{helpers.ui.getLangText(site?.title)}</span>
                )}
              </SiteLoader>
            )}
          </div>
          
          <UnitTypeLoader id={unit?.typeId}>
            {(unitType) => (
              <div className="text-muted">{helpers.ui.getLangText(unitType?.title)}</div>
            )}
          </UnitTypeLoader>
        </div>
      )}

      {!hideOwner && hideUnit && (
        <div role="cell">
          <UserLoader id={rental.ownerId}>
            {(user) => (
              <div><Avatar user={user} size={24} /> {user.name}</div>
            )}
          </UserLoader>
        </div>
      )}

      <div role="cell">
        <div>
          <StateLabel type={stateLabelTypes[rental.state]}>{wurd.text(`unitRental._states.${rental.state}`)}</StateLabel>
          {rental?.overdue && (
            <StateLabel type={stateLabelTypes.overdue}>{stateTitles.overdue}</StateLabel>
          )}
        </div>
        {!hideUnit && !hideOwner && rental && (
          <UserLoader id={rental.ownerId}>
            {user => user.name}
          </UserLoader>
        )}
      </div>
    
      <div role="cell" className="text-right">
        <JobLink jobId={rental.moveInJobId}>{date(rental.startDate)}</JobLink>
        {rental.state !== 'cancelled' && (
          <>
            <span className="hidden-xs"> -</span>{' '}
            <JobLink jobId={rental.moveOutJobId}>
              {rental.endDate ? date(rental.endDate) : <WurdText id="common.phrases.ongoing" className="text-muted" />}
            </JobLink>
          </>
        )}
      </div>

      <div role="cell" className="text-right">
        {helpers.ui.currency(rental.price)}
        {rental?.unpaidBalance && (
          <div>
            <cms.Text
              id="rental.unpaidBalance"
              vars={{ amount: helpers.ui.currency(rental.unpaidBalance) }}
              className="text-danger"
              style={{ fontSize: '90%' }}
            />
          </div>
        )}
      </div>
    </>
  );
}
