import { useCallback, Fragment } from 'react';
import wurd from 'wurd-react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import store from '../store';
import * as actions from '../actions';
import { updateCachedData } from '../actions/general';
import { apiUrl } from '../config';
import * as helpers from '../helpers';
import SiteLoader from './loaders/site';
import debounce from '../utils/debounce';
import AsyncSelect from 'react-select/async';


const cms = wurd.block('common.quickAccess');

function fetchOneSilentWithCache(endpoint, id, { altIdKey } = {}) {
  return actions.fetchOneCached(endpoint, id, { altIdKey })?.data || window.fetch(`${apiUrl}/v1/admin/${endpoint}/${id}`, { credentials: 'include' }).then(async r => {
      const data = await r.json().catch(() => null);
      if (r.ok) {
        updateCachedData(endpoint, data);
        return data;
      }
    });
}

const AsyncSelectStyled = styled(AsyncSelect)({
  zIndex: 1,
  position: 'relative',
  padding: '6px 12px',
  color: '#0009',
  width: 200,

  '.text-muted': {
    color: '#0006'
  },
});

const userToOption = user => user && {
  value: `/users/${user.id}`,
  label: <>{user.firstName} {user.lastName} <span className="text-muted">({user.email})</span></>
};

const siteToOption = site => site && {
  value: `/sites/${site.code}`,
  label: <>{helpers.ui.getLangText(site.title)} <span className="text-muted">({site.code})</span></>
};

const unitToOption = unit => unit && {
  value: `/units/${unit.id}`,
  label: <><span className="text-uppercase">{unit.name}</span> <span className="text-muted"><SiteLoader id={unit.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader></span></>
};

const selectStyles = {
  menuList: (base) => ({
    ...base,
    maxHeight: 500,
  })
};

export default function QuickAccess() {
  const navigate = useNavigate();
  const { users_byId, units_byId, sites_byId, settings } = store.get();

  const isAdmin = helpers.auth.hasRole('admin'),
    isSS = settings.modes.includes('selfStorage'),
    isVS = settings.modes.includes('valetStorage');

  const defaultOptions = isAdmin && [
    ...isSS ? [
      {
        label: cms.text('sites'),
        options: Object.values(sites_byId).sort((a, b) => new Date(b.updated) - new Date(a.updated)).slice(0, 10).map(siteToOption),
      },
    ] : [],
    {
      label: cms.text('users'),
      options: Object.values(users_byId).sort((a, b) => new Date(b.updated) - new Date(a.updated)).slice(0, 10).map(userToOption),
    },
  ];

  const _loadOptions = async inputValue => {
    const [invoice, item, valetOrder, users, sites, units] = await Promise.all([
        inputValue.length > 1 && fetchOneSilentWithCache('invoices', inputValue, { altIdKey: 'sid' }),
        inputValue.length > 1 && isVS && fetchOneSilentWithCache('items', inputValue, { altIdKey: 'sid' }),
        inputValue.length > 1 && isVS && fetchOneSilentWithCache('valet-orders', inputValue, { altIdKey: 'sid' }),
        isAdmin ? actions.fetch('users', { search: inputValue, limit: 10 }) : [],
        isSS && isAdmin ? actions.fetch('sites', { search: inputValue, limit: 10 }) : [],
        isSS && isAdmin ? actions.fetch('units', { search: inputValue, limit: 10 }) : [],
      ]);

    return [
      {
        options: [
          ...invoice ? [{ value: `/invoices/${invoice.sid.toUpperCase()}`, label: cms.text('invoice', { sid: invoice.sid.toUpperCase() }) }] : [],
          ...item ? [{ value: `/items/${item.sid.toUpperCase()}`, label: cms.text('item', { sid: item.sid.toUpperCase() }) }] : [],
          ...valetOrder ? [{ value: `/valet-orders/${valetOrder.sid.toUpperCase()}`, label: cms.text('valetOrder', { sid: valetOrder.sid.toUpperCase() }) }] : [],
        ],
      },
      {
        label: cms.text('sites'),
        options: sites.map(siteToOption),
      },
      {
        label: cms.text('units'),
        options: units.map(unitToOption),
      },
      {
        label: cms.text('users'),
        options: users.map(userToOption),
      },
    ];
  };

  const loadOptions = useCallback(debounce(_loadOptions, 400), []);

  return (
    <>
      <AsyncSelectStyled
        className="text-muted"
        placeholder={cms.text('placeholder') || 'Jump to'}
        cacheOptions
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onChange={option => navigate(option.value)}
        value={null}
        styles={selectStyles}
      />
      {wurd.editMode && <cms.Object keys="placeholder,invoice,item,valetOrder,sites,units,users" style={{ float: 'right' }}>Edit</cms.Object>}
    </>
  );
}
