import { useState } from 'react';


export default function ItemSelector() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSids, setSelectedSids] = useState([]);

  function startSelecting(item) {
    setSelectedIds([item.id]);
    setSelectedSids([item.sid]);
  }

  function setSelectedItems(items) {
    setSelectedIds(items.map((item) => item.id));
    setSelectedSids(items.map((item) => item.sid));
  }

  function toggleItemSelected(item) {
    let newIds;
    let newSids;

    if (selectedIds.includes(item.id)) {
      newIds = selectedIds.filter(id => id !== item.id);
      newSids = selectedSids.filter(sid => sid !== item.sid);
    } else {
      newIds = [item.id, ...selectedIds];
      newSids = [item.sid, ...selectedSids];
    }

    setSelectedIds(newIds);
    setSelectedSids(newSids);
  }

  function clearSelection() {
    setSelectedIds([]);
    setSelectedSids([]);
  }

  return {
    isSelecting: Boolean(selectedSids.length),
    selectedIds,
    selectedSids,
    setSelectedItems,
    setSelectedSids,
    startSelecting,
    toggleItemSelected,
    clearSelection,
  };
}
