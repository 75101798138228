import wurd, { WurdText } from 'wurd-react';
import * as helpers from 'helpers';
import { throttle_fetchOne } from 'actions';
import JobLoader from '../loaders/job';
import UserLoader from '../loaders/user';
import UnitLoader from '../loaders/unit';
import StateLabel from '../state-label';
import LabelsListCell from 'plugins/labels/list';
import Link from 'components/nested-link';
import * as secsys from 'plugins/security-systems';
import { useItemLoader } from 'hooks';


const cms = wurd.block('unitList');

const { date, currency } = helpers.ui;
const { stateLabelTypes } = helpers.unit;
const fetchSites = throttle_fetchOne();
const fetchUnitTypes = throttle_fetchOne();

const styles = {
  blockedReason: {
    width: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondary: {
    filter: 'opacity(.7)',
  }
};


export default function UnitListItem({
  unit,
  rental = unit?.rental,
  stateTitles,
  hideSite,
  hideOwner,
  hideState,
  hidePrice,
}) {
  const siteId = rental?.siteId || unit?.siteId;
  const { item: site } = useItemLoader('sites', siteId, { fetch: fetchSites });
  const { item: unitType } = useItemLoader('unit-types', unit?.typeId, { fetch: fetchUnitTypes });

  const state = rental?.state || unit?.state;

  return (
    <>
      <div role="cell">
        <div>
          <strong className="text-uppercase">{unit?.name} </strong>
          {!hideSite && (
            <span className="text-muted">{helpers.ui.getLangText(site?.title)}</span>
          )}
          {unit?.customFields?.[secsys.getCfKey(site)] && <i className="fas fa-shield-alt" style={{ fontSize: '80%' }} />}
        </div>

        <div className="text-muted">{helpers.ui.getLangText(unitType?.title)}</div>
      </div>

      {!hideState && (
        <div role="cell">
          <div>
            <StateLabel type={stateLabelTypes[state]}>{wurd.text(`unitRental._states.${state}`) || stateTitles[state]}</StateLabel>
            {unit?.overdue && (
              <StateLabel type={stateLabelTypes.overdue}>{stateTitles.overdue}</StateLabel>
            )}
          </div>

          <div className="text-muted">
            {state === 'blocked' && (
              <div style={styles.blockedReason}>
                {unit.blockedReason}
              </div>
            )}

            {state === 'archived' && (
              <div style={styles.blockedReason}>
                {unit.archivedReason}
              </div>
            )}

            {!hideOwner && rental && (
              <UserLoader id={rental.ownerId}>
                {user => user.name}
              </UserLoader>
            )}
          </div>
        </div>
      )}

      <div role="cell">
        {state === 'reserved' && rental && rental.moveInJobId && (
          <JobLoader id={rental.moveInJobId}>
            {(job) => (
              <Link to={`/sites/${site?.code}/jobs/${job.sid.toUpperCase()}`}>
                {job.type === 'unit_moveIn' && (
                  <>
                    <cms.Text id="movingIn" />
                    <div>{date(job.result.startDate)}</div>
                  </>
                )}

                {job.type === 'unit_transfer' && (
                  <UnitLoader id={job.result.oldUnitId}>
                    {unit => (
                      <>
                        <cms.Text
                          id={rental?.state === 'reserved' ? 'transferringFrom' : 'transferredFrom'}
                          vars={{ unitName: unit.name }}
                        />
                        <div>{date(job.result.transferDate)}</div>
                      </>
                    )}
                  </UnitLoader>
                )}
              </Link>
            )}
          </JobLoader>
        )}
        {rental && rental.moveOutJobId && (
          <JobLoader id={rental.moveOutJobId}>
            {(job) => (
              <Link to={`/sites/${site?.code}/jobs/${job.sid.toUpperCase()}`}>
                {job.type === 'unit_moveOut' && (
                  <>
                    <cms.Text id="movingOut" />
                    <div>{date(job.result.endDate)}</div>
                  </>
                )}

                {job.type === 'unit_transfer' && (
                  <UnitLoader id={job.result.newUnitId}>
                    {unit => (
                      <>
                        <cms.Text
                          id={rental?.state === 'occupied' ? 'transferringTo' : 'transferredTo'}
                          vars={{ unitName: unit.name }}
                        />
                        <div>{date(job.result.transferDate)}</div>
                      </>
                    )}
                  </UnitLoader>
                )}
              </Link>
            )}
          </JobLoader>
        )}
      </div>

      {!hidePrice && (
        <div role="cell" className="text-right">
          {rental
            ? <div title="Price per month">{currency(rental.price)}</div>
            : <div title="Default price per month" className="text-muted" style={styles.secondary}>{currency(unit.defaultPrice)}</div>
          }
          <div className="text-muted" style={styles.secondary}>{unit && helpers.unit.size(unit, site?.measure)}</div>
        </div>
      )}

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={unit?.labels} style={{ fontSize: '1rem' }} />
      </div>
    </>
  );
}
