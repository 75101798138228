import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { usePrevious } from '../hooks';

import AnimatedBadge from './badge_animated';


const Container = styled.div({
  overflowX: 'auto',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  background: '#fff',
  position: 'sticky',
  top: 50,
  zIndex: 99,
  
  // TODO: Add a white fade overlay on the right to show content can scroll, then enable line below to remove scrollbar
  //'&::-webkit-scrollbar': { display: 'none' }, /* hide scrollbar for Chrome, Safari, and Opera */

  '.inner': {
    //margin: '0 16px',
    display: 'flex',
    gap: 12,
  },

  '.tab': {
    border: 0,
    background: 'none',
    padding: '8px',
    outline: 'none',
    '&.active': {
      borderBottom: '2px solid #4D90FD',
    },
    '&:not(.active)': {
      filter: 'opacity(.5) blur(.5px)',
    },

    '&.dirty::after': {
      content: '"*"',
      color: 'var(--bs-warning)',
      filter: 'brightness(1.5)',
    },

    '.icon': {
      marginRight: 4,
    },

    '.badge': {
      marginLeft: 4,
      background: '#ccc',
    },
  },
});


export default function Tabs({
  items = [{
    id: 'tab1',
    faIcon: 'far-archive',
    title: 'Tab 1',
    badge: 2,
    renderContent: null,
  }],
  renderExtra,
  activeTabId: defaultActiveTabId,
  onChange,
  children,
  ...props
}) {
  const [activeIndex, setActiveIndex] = useState(defaultActiveTabId ? items.findIndex(item => item.id === defaultActiveTabId) : 0);

  useEffect(() => {
    if (defaultActiveTabId) setActiveIndex(items.findIndex(item => item.id === defaultActiveTabId));
  }, [defaultActiveTabId]);

  function onClickTab(event) {
    event.preventDefault();
    const tabIndex = Array.prototype.indexOf.call(event.currentTarget.parentNode.children, event.currentTarget)

    setActiveIndex(tabIndex);

    if (onChange) onChange(items[tabIndex]?.id);
  }

  const { renderContent } = items[activeIndex] || {};

  return (
    <>
      <Container {...props}>
        <div className="inner">
          {items.map(({ id, faIcon, title = id, badge, animateBadge, dirty }, i) => (
            <button
              key={id}
              id={id}
              type="button"
              className={`tab ${i === activeIndex ? 'active' : ''} ${dirty ? 'dirty' : ''}`}
              onClick={onClickTab}
            >
              {faIcon && (
                <i className={`icon fa-fw far ${faIcon}`} />
              )}
              {title}
              {animateBadge ? (
                <AnimatedBadge value={badge} />
              ) : (
                  <span className="badge">{badge}</span>
                )}
            </button>
          ))}
          {children}
        </div>
      </Container>

      {renderContent?.()}
      {renderExtra?.(items[activeIndex]?.id)}
    </>
  );
}
