import wurd from 'wurd-react';

import * as helpers from '../../helpers';
import * as actions from '../../actions';

import LabelsList from '../../plugins/labels/detail';
import DetailPage from '../detail-page';
import NotFound from '../not-found';
import Summary from './shared/summary';
import Menu from './menu';
import SidebarSummary from './sidebar-summary';
import StepList from './step-list';
import Items from './items';
import Charges from './charges';
import History from './history';
import MultipleErrorsModal from './shared/multiple-errors-modal';

import './index.css';

const cms = wurd.block('valetOrder');

const sectionComponents = {
  steps: StepList,
  summary: Summary,
  items: Items,
  charges: Charges,
  history: History,
};


export default function ValetOrderDetail({
  modal,
  valetOrder,
  section,
  items,
  fetchValetOrder,
  fetchItems,
}) {
  const { job } = valetOrder;
  const sid = valetOrder.sid.toUpperCase();

  const sections = [
    { name: 'steps', title: 'Steps', icon: 'fas fa-tasks' },
    { name: 'summary', title: 'Summary', icon: 'far fa-info-circle' },
    { name: 'charges', title: 'Charges', icon: 'far fa-money-check-alt', badge: valetOrder.charges.length },
    { name: 'items', title: 'Associated items', icon: 'far fa-archive', badge: items && items.length },
    { name: 'history', title: 'History', icon: 'far fa-history' },
  ];

  const SectionComponent = sectionComponents[section];
  if (!SectionComponent) return <NotFound />;

  async function updateItems(command, itemSids, { location, opsTitle } = {}) {
    if (!Array.isArray(itemSids)) itemSids = [itemSids];

    const result = await actions.items.updateState(itemSids, command, {
      valetOrderId: valetOrder.id,
      location,
      opsTitle,
    });

    fetchItems();

    // Show errors
    if (result.errors) {
      modal.open((
        <MultipleErrorsModal
          errorsBySid={result.errors}
        />
      ));
    }

    return {
      errors: result.errors || null,
      updatedSids: result.updatedItems.length ? result.updatedItems.map(item => item.sid) : null,
    };
  }

  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="driver"
      // className="sg-job-detail"
      documentTitle={cms.text('title', { sid })}
      title={sid}
      ancestors={[
        { text: wurd.text('valetOrderList.title'), url: '/valet-orders' },
      ]}
      sections={sections}
      currentSection={section}
      baseUrl={`/valet-orders/${sid}`}
      extra={(
        <>
          <LabelsList
            labels={valetOrder.labels}
            settingsLabels={helpers.settings.get().valetOrderLabels}
            onChange={labels => actions.valetOrders.labels.update(valetOrder.id, labels)}
          />

          <Menu
            valetOrder={valetOrder}
            modal={modal}
            job={job}
            fetchValetOrder={fetchValetOrder}
          />
        </>
      )}
      sidebar={(
        <SidebarSummary
          valetOrder={valetOrder}
        />
      )}
    >
      <SectionComponent
        valetOrder={valetOrder}
        order={valetOrder}
        job={job}
        items={items}
        onChangeItem={fetchItems}
        fetchValetOrder={fetchValetOrder}
        fetchItems={fetchItems}
        updateItems={updateItems}
        modal={modal}
        cms={cms}
      />
    </DetailPage>
  );
}
